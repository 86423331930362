import { Skeleton } from 'antd';
import styled from 'styled-components';

export const WatchlistDataTableContainer = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  position: relative;
  overflow: auto hidden;

  @media screen and (max-width: 100em) {
    height: 75vh !important;
  }

  .ant-table-tbody > tr.active-row > td {
    background: var(--section-header-color) !important;
  }

  .ant-table .ant-table-body {
    background-color: var(--primary-color);
    overflow: auto !important;

    @media screen and (max-width: 100em) {
      height: calc(75vh - 5.4rem) !important;
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: var(--primary-color);
  }

  .ant-table table .ant-table-thead {
    background-color: var(--section-header-color);
  }

  .ant-table-cell-scrollbar {
    box-shadow: unset !important;
  }

  .ant-table-measure-row {
    background-color: var(--section-header-color);
  }

  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .ant-table table .ant-table-thead > tr > th {
    background-color: var(--section-header-color) !important;
    color: var(--white-color) !important;
    font-size: 13px !important;
    line-height: 20px;
    font-weight: 500 !important;
    border-bottom: 1px solid transparent;
    height: 4.4rem;
    padding: 1.2rem;
    user-select: none;
    white-space: nowrap;
  }

  .ant-table {
    cursor: pointer;
  }

  .ant-table .ant-table-tbody {
    background-color: var(--primary-color);
  }

  .ant-table .ant-table-tbody > tr > td {
    color: var(--white-color);
    border-bottom: 1px solid var(--border-color);
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding: 1.2rem;

    &:first-child > span {
      position: relative;
      bottom: 2px;
    }
  }

  .ant-table table .ant-table-tbody > tr:hover > td {
    background-color: var(--section-header-color) !important;
  }

  .ant-table table .ant-table-column-sorters {
    justify-content: unset;
  }

  .ant-table table .ant-table-column-title {
    flex: unset;
  }

  .ant-table table td.ant-table-column-sort {
    background-color: unset;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: var(--section-header-color);
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  height: calc(
    100vh - ${(props) => (props.closeAnnouncement ? '15.4rem' : '22.6rem')}
  );

  @media (max-width: 100em) {
    height: 75vh;
  }
`;

export const SkeletonStyled = styled(Skeleton)`
  .ant-skeleton-content ul li {
    height: 4rem;
    margin-top: 1rem;
    width: 100% !important;
  }
`;

export const StyledCurrentWatchlistName = styled.div`
  /* padding: 1rem; */
  font-weight: 700;
`;

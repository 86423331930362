import { Checkbox, Modal, Tabs, Tooltip } from 'antd';
import StarIcon from 'assets/icons/StarIcon';
import StarIconFill from 'assets/icons/StarIconFill';
import EditWatchlistModal from 'components/WatchlistScreenComponents/Watchlist/WatchlistModal';
import { timeParse } from 'd3-time-format';
import {
  addStockToWatchlists,
  createWatchlist,
  getWatchlists,
  handleResetNewWatchlist,
  handleResetState,
} from 'features/watchlist/watchlistSlice';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledHeaderIcon,
  StyledHeaderText,
  StyledSectionHeader,
} from 'screens/RatingsScreen/styles';
import PrimaryButton from '../GlobalButton/PrimaryButton';
import SecondaryButton from '../GlobalButton/SecondaryButton';
import Loader from '../UIHandler/Loader';
import DATXRatingPointTab from './DATXRatingPointTab';
import FormCreateWatchlist from './FormCreateWatchlist';
import NewsByStock from './NewsByStockTab';
import OverviewTab from './OverviewTab';
import {
  AddBtn,
  StockDetailContainer,
  StyledNumberMiddle,
  StyledTabs,
  WatchlistModalContainer,
} from './styles';

const StockDetail = () => {
  const utc_to_vntime = (_date) => {
    const __date = new Date(_date);
    const _dateValue = __date.valueOf();
    const _vntimeValue = _dateValue + 7 * 60 * 60 * 1000; // vntime = utc + 7

    const _vnDatetime = new Date(_vntimeValue);

    return _vnDatetime.toISOString();
  };

  const parseDate = timeParse('%Y-%m-%e');

  const { dataOverview } = useSelector((state) => state.stockDetails);
  const { closeAnnouncement } = useSelector((state) => state.uiHandler);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { t } = useTranslation('ratingsScreen');

  // eslint-disable-next-line no-unused-vars
  const dataChart = [
    {
      start: '2022-04-20T03:00:00.055Z',
      end: '2022-04-25T04:00:00.055Z',
      price: 57.4,
      volume: 960300,
    },
    {
      start: '2022-04-21T04:00:00.055Z',
      end: '2022-04-25T05:00:00.055Z',
      price: 58.4,
      volume: 165606,
    },
    {
      start: '2022-04-22T05:00:00.055Z',
      end: '2022-04-25T06:00:00.055Z',
      price: 58.4,
      volume: 103006,
    },
    {
      start: '2022-04-23T06:00:00.055Z',
      end: '2022-04-25T07:00:00.055Z',
      price: 59.0,
      volume: 1163300,
    },
    {
      start: '2022-04-24T07:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 58.7,
      volume: 1264300,
    },

    {
      start: '2022-04-25T03:00:00.055Z',
      end: '2022-04-25T04:00:00.055Z',
      price: 57.9,
      volume: 960300,
    },
    {
      start: '2022-04-26T04:00:00.055Z',
      end: '2022-04-25T05:00:00.055Z',
      price: 58.4,
      volume: 165606,
    },
    {
      start: '2022-04-27T05:00:00.055Z',
      end: '2022-04-25T06:00:00.055Z',
      price: 57.4,
      volume: 103006,
    },
    {
      start: '2022-04-28T06:00:00.055Z',
      end: '2022-04-25T07:00:00.055Z',
      price: 58.2,
      volume: 1163300,
    },
    {
      start: '2022-04-29T07:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.8,
      volume: 1264300,
    },

    {
      start: '2022-04-30T08:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.4,
      volume: 1001300,
    },

    {
      start: '2022-05-01T09:00:00.055Z',
      end: '2022-04-26T09:00:00.055Z',
      price: 55.4,
      volume: 1316300,
    },
    {
      start: '2022-05-02T10:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.4,
      volume: 1260300,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWatchlists());
  }, [dispatch]);

  const watchList = useSelector((state) => state.watchlist);

  var stockDetailOverview = [];

  dataOverview?.forEach((_row, _index) => {
    if (_row.start) {
      var _newRow = {};
      var _date = utc_to_vntime(new Date(_row.start));
      _newRow.date = parseDate(_date.slice(0, 10));

      _newRow.price = _row.price;
      _newRow.volume = _row.volume;

      stockDetailOverview.push(_newRow);
    }
  });

  const { TabPane } = Tabs;

  function callback(key) {
    if (key === '1' || key === '2') {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1);
    }
  }

  const { data, currentSelectedStock } = useSelector(
    (state) => state.stockDetails
  );

  let b;

  const filteredWatchlist = (stockCode) => {
    const a = watchList?.list?.data?.filter((el) =>
      el.stocks.includes(stockCode)
    );
    b = a.map((el) => el._id);

    return b;
  };

  filteredWatchlist(currentSelectedStock);

  const checkIdIncludedInArray = (id) => {
    return b.includes(id);
  };

  const [arrayWatchlist, setArrayWatchlist] = useState([...b]);

  const handleClick = (e, el) => {
    const value = el._id;

    setArrayWatchlist((prev) =>
      arrayWatchlist.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, el._id]
    );
  };

  useEffect(() => {
    setArrayWatchlist([...b]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStock]);

  const [modalVisible, setModalVisible] = useState(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (watchList.isSuccessCreate) {
      setArrayWatchlist([...b, watchList.newWatchlistId]);
      setIsFormVisible(false);
    }

    setTimeout(() => {
      dispatch(handleResetState());
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, watchList.newWatchlistId, watchList.isSuccessCreate]);

  /* -------------------------------------------------------------------------- */
  /*                             MODAL ADD WATCHLIST                            */
  /* -------------------------------------------------------------------------- */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ******                                   */
  /* -------------------------------------------------------------------------- */

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsFormVisible(false);
    setArrayWatchlist([...b]);
    dispatch(handleResetNewWatchlist());
  };

  function onChange(e, id) {
    console.log(`checked = ${e.target.checked}`);
  }

  const [color, setColor] = useState('');
  const [watchlistName, setWatchlistName] = useState('');

  const handleChange = (e) => {
    setWatchlistName(e.target.value);
  };

  const handleClickBtn = () => {
    if (isFormVisible) {
      dispatch(createWatchlist({ watchlist_name: watchlistName }));
    } else {
      dispatch(
        addStockToWatchlists({
          watchListId: arrayWatchlist,
          stocks: currentSelectedStock,
        })
      );
    }
  };

  useEffect(() => {
    const color =
      data?.overall?.mov_price > 0 || data?.overall?.mov_percent > 0
        ? 'var(--light-green-color)'
        : 'var(--light-red-color)';

    setColor(color);
  }, [data?.overall?.mov_percent, data?.overall?.mov_price]);

  useEffect(() => {
    if (watchList.isSuccesAddToWatchlist) {
      handleCancel();

      dispatch(handleResetState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchList.isSuccesAddToWatchlist]);

  const inputRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        const { input } = inputRef.current;
        input.focus();
      }
    }, 1);
  });

  return (
    <StockDetailContainer>
      <StyledSectionHeader>
        <StyledHeaderText>{currentSelectedStock}</StyledHeaderText>

        <StyledNumberMiddle>
          <span style={{ color: color }}>{data?.overall?.last_price} </span>

          <span style={{ color: color }}>{data?.overall?.mov_price}</span>
          <span style={{ color: color }}>/</span>
          <span style={{ color: color }}>{data?.overall?.mov_percent}%</span>
        </StyledNumberMiddle>

        <div style={{ display: 'flex' }}>
          <Tooltip
            title={
              <>
                <div>
                  {t('stockDetailsSection.addToWatchlistModal.toolTip1')}
                </div>
                <div>
                  {t('stockDetailsSection.addToWatchlistModal.toolTip2')}
                </div>
              </>
            }
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <StyledHeaderIcon onClick={showModal} className="star-icon">
              {b.length > 0 ? <StarIconFill /> : <StarIcon />}
            </StyledHeaderIcon>
          </Tooltip>
        </div>
      </StyledSectionHeader>

      <StyledTabs closeAnnouncement={closeAnnouncement}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab={t('stockDetailsSection.header.tab1')} key="1">
            <OverviewTab isLoading={isLoading} />
          </TabPane>
          <TabPane tab={t('stockDetailsSection.header.tab2')} key="2">
            <DATXRatingPointTab isLoading={isLoading} />
          </TabPane>
          <TabPane
            tab={t('stockDetailsSection.header.tab3')}
            key="4"
            className="news-by-stock"
          >
            <NewsByStock />
          </TabPane>
        </Tabs>
      </StyledTabs>

      <EditWatchlistModal
        visible={modalVisible}
        onCancel={handleCloseModal}
        type="create"
      />

      <Modal
        title={t('stockDetailsSection.header.tooltip')}
        visible={isModalVisible}
        onOk={handleOk}
        zIndex={2001}
        onCancel={handleCancel}
        destroyOnClose
        centered
        inputRef={inputRef}
        footer={
          <div>
            <span style={{ paddingRight: '2rem' }}>
              <SecondaryButton
                size="large"
                textBtn={t(
                  'stockDetailsSection.addToWatchlistModal.footerBtn1'
                )}
                onHandleClick={handleCancel}
              />
            </span>
            <PrimaryButton
              large
              textBtn={
                isFormVisible
                  ? `${t('stockDetailsSection.addToWatchlistModal.footerBtn3')}`
                  : `${t('stockDetailsSection.addToWatchlistModal.footerBtn2')}`
              }
              onHandleClick={handleClickBtn}
            />
          </div>
        }
      >
        {watchList.loading ? <Loader /> : null}
        <WatchlistModalContainer>
          {watchList?.list?.data.map((el, index) => {
            return (
              <Checkbox
                onChange={onChange}
                key={el.name}
                defaultChecked={
                  checkIdIncludedInArray(el._id) ||
                  el._id === watchList.newWatchlistId
                }
                onClick={(e) => {
                  onChange(e);
                  handleClick(e, el);
                }}
              >
                {el.name}
              </Checkbox>
            );
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <AddBtn
              onClick={() => {
                setIsFormVisible(!isFormVisible);
              }}
            >
              <span>+</span>
              {t('stockDetailsSection.addToWatchlistModal.addBtn')}
            </AddBtn>

            {isFormVisible ? (
              <div
                style={{
                  cursor: 'pointer',
                  color: 'var(--light-blue-color-2)',
                }}
                onClick={() => {
                  setIsFormVisible(false);
                }}
              >
                x
              </div>
            ) : null}
          </div>

          {isFormVisible ? (
            <>
              <FormCreateWatchlist
                inputRef={inputRef}
                watchlistName={watchlistName}
                setWatchlistName={setWatchlistName}
                handleChange={handleChange}
              />
            </>
          ) : null}
        </WatchlistModalContainer>
      </Modal>
    </StockDetailContainer>
  );
};

export default StockDetail;

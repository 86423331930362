import { Col, Form, Select, Slider } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import { DATE_FORMAT } from 'constants/date';
import { formatCurrency } from 'utils';
import {
  StyledInput,
  StyledRangePicker,
  StyledSelect,
} from 'components/GlobalComponents/styled';

dayjs.locale('vi');

export const ABSelect = ({ label, options, value }) => (
  <Col xs={24} lg={12}>
    <Form.Item name={value} label={label} labelCol={24}>
      <StyledSelect
        suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
        placeholder={label}
      >
        {options.map((item, index) => (
          <Select.Option key={index} value={item?.value}>
            {item?.label}
          </Select.Option>
        ))}
      </StyledSelect>
    </Form.Item>
  </Col>
);

export const ABInput = ({ label, value }) => (
  <Col xs={24} lg={12}>
    <Form.Item name={value} label={label}>
      <StyledInput placeholder={label} />
    </Form.Item>
  </Col>
);

export const ABRangePicker = ({ label, value }) => (
  <Col span={24}>
    <Form.Item name={value} label={label}>
      <StyledRangePicker
        locale={locale}
        className="w-full !rounded-md"
        format={DATE_FORMAT}
      />
    </Form.Item>
  </Col>
);

export const ABSlider = ({ label, value, max = 1 }) => (
  <Col span={24}>
    <Form.Item name={value} label={label}>
      <Slider
        range
        defaultValue={[0, 100]}
        tooltip={{
          formatter: (value) => `${formatCurrency(value * 1e7 * max)} VNĐ`,
        }}
        handleStyle={{ borderColor: 'var(--primary-blue)' }}
        trackStyle={{ backgroundColor: 'var(--primary-blue)' }}
      />
    </Form.Item>
  </Col>
);

import { ClearOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Empty } from 'antd';
import NotifyIcon from 'assets/icons/NotifyIcon';
import { useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import NotifyItem from './NotifyItem';
import { NotifyIconWrapper, StyledClearButton, StyledMenu } from './styles';

const fetchNotificationData = async () => {
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_URL}/api/notifications/list`,
    {
      limit: 10,
    }
  );
  const { data } = response;
  if (!data || data.status === false) throw new Error(JSON.stringify(data));
  return data;
};

const updateNotificationMark = async ({ _id }) => {
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_URL}/api/notifications/edit`,
    {
      notification_id: _id,
      is_mark: true,
    }
  );
  const { data } = response;

  if (!data || data.status === false) throw new Error(JSON.stringify(data));
  return data;
};

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [visible, setVisible] = useState(false);

  //check dot
  const isEmpty = notificationData.every(
    (item) => item.notifications.length === 0
  );
  const isMarkAll =
    isEmpty ||
    notificationData.every((list) => {
      return list.notifications.every((notify) => notify.is_mark);
    });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchNotificationData();

      setNotificationData(response.data);
    } catch (error) {
      console.log('fetchNotificationData: ', JSON.parse(error.message));
    }
  };

  //handle update mark
  const handleNotifyClick = async (notification) => {
    const { is_mark, _id } = notification;
    if (is_mark) return;
    try {
      const response = await updateNotificationMark({ _id });
      console.log('updateNotificationMark: ', response);
      fetchData();
    } catch (error) {
      console.log('updateNotificationMark', JSON.parse(error.message));
    }
  };

  const handleClear = () => {
    setNotificationData([]);
  };

  //empty components
  const empty = (
    <StyledMenu>
      <Empty style={{ padding: '10rem 0' }} />
    </StyledMenu>
  );

  //list notify components
  const list = (
    <StyledMenu className="noti-menu">
      <StyledClearButton>
        <ClearOutlined
          onClick={handleClear}
          style={{ fontSize: '2.4rem', cursor: 'pointer' }}
        />
      </StyledClearButton>
      {notificationData
        .filter((list) => list.notifications.length !== 0)
        .map((list) => (
          <StyledMenu.ItemGroup key={list.name} title={list.name}>
            {list.notifications.map((item, index) => (
              <NotifyItem
                key={index}
                data={item}
                onClick={() => handleNotifyClick(item)}
              />
            ))}
          </StyledMenu.ItemGroup>
        ))}
    </StyledMenu>
  );

  return (
    <Dropdown
      overlay={isEmpty ? empty : list}
      trigger={['click']}
      onVisibleChange={() => setVisible(!visible)}
      visible={visible}
    >
      <NotifyIconWrapper>
        <Badge dot={!isMarkAll}>
          <NotifyIcon />
        </Badge>
      </NotifyIconWrapper>
    </Dropdown>
  );
};

export default Notification;

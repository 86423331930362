import ShowChart from '@mui/icons-material/ShowChart';
import { Button, Layout } from 'antd';
import Select from 'components/GlobalComponents/Select';
import { TradingHistory } from 'components/TradingComponents/TradingHistory';
import TradingOrder from 'components/TradingComponents/TradingOrder';
import { closeAnnouncementSelector } from 'features/uiHandler/uiHandlerSlice';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledMenu } from 'screens/CopyTrade/styles';

const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [getItem('Đặt lệnh', 'nav1'), getItem('Sổ lệnh', 'nav2')];

const TradingScreen = () => {
  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const [openTradingView, setOpenTradingView] = useState(false);
  const [active, setActive] = useState('nav1');

  const renderContent = useMemo(
    () => (
      <div>
        {active === 'nav1' && (
          <TradingOrder
            openTradingView={openTradingView}
            setOpenTradingView={setOpenTradingView}
          />
        )}
        {active === 'nav2' && <TradingHistory />}
      </div>
    ),
    [active, openTradingView, setOpenTradingView]
  );

  return (
    <Layout
      className="bg-transparent h-86 md:gap-4"
      closeAnnouncement={closeAnnouncement}
    >
      <Sider
        breakpoint="md"
        collapsible
        collapsedWidth={0}
        zeroWidthTriggerStyle={{
          display: 'none', // Not showing collapse button
        }}
        className="!bg-custom-darkBlue600 rounded-xl !h-[calc(100vh_-_158px)]"
      >
        {/* Desktop sidebar */}
        <StyledMenu
          className="bg-transparent border-0 py-4"
          onClick={(e) => setActive(e.key)}
          defaultSelectedKeys={active}
          defaultOpenKeys={active}
          selectedKeys={active}
          mode="inline"
          items={items}
        />
      </Sider>
      <Content>
        {/* Mobile sidenav */}
        <div className="md:hidden flex gap-4 items-center">
          <Select active={active} setActive={setActive} options={items} />
          {active === 'nav1' && (
            <Button
              type="ghost"
              className="!h-[40px] border-0 !rounded-lg !bg-custom-darkBlue600"
              icon={<ShowChart className="!h-10 !w-10 mr-3" />}
              onClick={() => setOpenTradingView(true)}
            >
              Biểu đồ
            </Button>
          )}
        </div>
        {renderContent}
      </Content>
    </Layout>
  );
};

export default TradingScreen;

import styled from 'styled-components';
import { Row } from 'antd';

export const StyledTitle = styled.h2`
  background: #1b3c7b;
  border-radius: 5px;
  height: 72px;
  color: #ffffff;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Filter = styled(Row)`
  margin-bottom: 12px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    background: rgba(33, 37, 59, 0.7);
    border-radius: 5px;
    padding: 0 8px;
    label {
      font-size: 13px;
      color: #fff;
      font-weight: 600;
    }
  }

  .ant-typography {
    font-size: 13px;
    color: #fff;
  }

  .ant-picker {
    width: 100%;
    background: transparent;
    border: none;
    color: #fff;
    border-bottom: 1px solid rgba(131, 131, 131, 0.5);
    box-shadow: none !important;

    .ant-picker-input {
      input {
        color: #fff;
        font-size: 13px;
      }
    }

    .ant-picker-clear {
      background: var(--primary-color);
    }

    .anticon {
      color: white;
    }
  }
`;

import React, { useImperativeHandle, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Wrapper, StyledModal, ModalHeader } from './styled';

const TradingViewPopUp = ({ symbol, path }, ref) => {
  const [isFull, setShowFull] = useState(false);

  useImperativeHandle(ref, () => ({
    fullScreen: () => {
      setShowFull(true);
    },
  }));

  return (
    <>
      <StyledModal visible={isFull} closable={false} footer={null}>
        <ModalHeader>
          <CloseCircleOutlined
            style={{ fontSize: 25, cursor: 'pointer' }}
            onClick={() => setShowFull(false)}
          />
        </ModalHeader>
        <iframe
          title="tradingViewStockDetail"
          src={`${process.env.REACT_APP_TRADING_VIEW_URL}${
            path || ''
          }?symbol=${symbol}&language=vi&interval=D&theme=classic&target=tradingViewStockDetail&autosave=1`}
        />
      </StyledModal>
      <Wrapper isFull={isFull}>
        {symbol && (
          <iframe
            title="tradingViewStockDetail"
            src={`${process.env.REACT_APP_TRADING_VIEW_URL}${
              path || ''
            }?symbol=${symbol}&language=vi&interval=D&theme=classic&target=tradingViewStockDetail&autosave=1&custom-fullscreen=true`}
          />
        )}
      </Wrapper>
    </>
  );
};

export default React.forwardRef(TradingViewPopUp);

import React, { useMemo, useState } from 'react';
import FollowerFilter from './FollowerFilter';
import { getFollowers } from 'utils/api';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { DATE_FORMAT, DATE_FORMAT_SERVER } from 'constants/date';
import { renderFollowerStatus } from 'utils';
import { StyledTable } from 'components/GlobalComponents/styled';

export default function FollowerList() {
  const [filter, setFilter] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ['getFollowers', filter],
    queryFn: () =>
      getFollowers({
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.startFollowDate && {
          startFollowDateFrom: moment(filter?.startFollowDate[0]).format(DATE_FORMAT_SERVER),
        }),
        ...(filter?.startFollowDate && {
          startFollowDateTo: moment(filter?.startFollowDate[1]).format(DATE_FORMAT_SERVER),
        }),
        ...(filter?.stopFollowDate && {
          stopFollowDateFrom: moment(filter?.stopFollowDate[0]).format(DATE_FORMAT_SERVER),
        }),
        ...(filter?.stopFollowDate && {
          stopFollowDateTo: moment(filter?.stopFollowDate[1]).format(DATE_FORMAT_SERVER),
        }),
      }),
  });

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'name',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Tài khoản',
        dataIndex: 'email',
        render: (data) => data && `***${data.slice(3)}`,
      },
      {
        title: 'Ngày follow',
        dataIndex: 'startSubscribeDate',
        render: (data) => moment(data).isValid() && moment(data).format(DATE_FORMAT),
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) => renderFollowerStatus(data),
      },
      {
        title: 'Ngày dừng follow',
        dataIndex: 'endSubscribeDate',
        render: (data) => moment(data).isValid() && moment(data).format(DATE_FORMAT),
      },
    ],
    [],
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };

  return (
    <div>
      <FollowerFilter onFilter={(values) => setFilter(values)} />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        pagination={{
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={data?.data}
        scroll={{ x: 680 }}
      />
    </div>
  );
}

import { UpCircleOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import GlobalTooltip from 'components/GlobalComponents/GlobalTooltip';
import InfiniteScroll from 'components/GlobalComponents/InfiniteScroll';
// import AbsoluteLoader from "components/GlobalComponents/UIHandler/AbsoluteLoader";
import { LoadingWrapper } from 'components/PortfolioScreenComponents/NewsScreenComponents/News/styles';
import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { getNewsByStock } from 'features/ratings/stockDetailsSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { NewsByStockContainer } from './styles';

const NewsByStock = () => {
  const {
    dataNews,
    pagination,
    currentSelectedStock,
    // loading
  } = useSelector((state) => state.stockDetails);
  const { closeAnnouncement } = useSelector((state) => state.uiHandler);
  const { t } = useTranslation(['common']);

  const _loader = (
    <LoadingWrapper>
      <Spin size="large" />
    </LoadingWrapper>
  );

  const dispatch = useDispatch();

  const handleNextPage = () => {
    dispatch(
      getNewsByStock({
        stockCode: currentSelectedStock,
        pagination: {
          ...pagination,
          page: pagination.page + 1,
        },
      })
    );
  };

  const handleScroll = () => {
    scrollIntoView(document.querySelector('.scroll-row-news'), {
      align: {
        top: 10,
      },
    });
  };

  return (
    <NewsByStockContainer closeAnnouncement={closeAnnouncement}>
      <>
        {/* {loading &&
          pagination.page === 1 &&
          pagination.isFirstMounted === true && <AbsoluteLoader />} */}

        {dataNews.length > 0 ? (
          <>
            <InfiniteScroll
              next={handleNextPage}
              isNext={pagination.isNext}
              loader={_loader}
            >
              {dataNews?.map((post, index) => (
                <PostItem key={index} data={post} index={index} />
              ))}
            </InfiniteScroll>

            <button
              className="back-to-top-btn"
              onClick={handleScroll}
              style={{
                position: 'absolute',
                bottom: '30px',
                zIndex: '100',
                right: '25px',
              }}
            >
              <GlobalTooltip title={t('newsBox.backToTopBtn')} placement="left">
                <span>
                  <UpCircleOutlined
                    style={{
                      fontSize: '30px',
                      color: 'var(--white-color)',
                    }}
                  />
                </span>
              </GlobalTooltip>
            </button>
          </>
        ) : (
          <Empty
            style={{ padding: '100px 0' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </>
    </NewsByStockContainer>
  );
};

export default NewsByStock;

import { useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin, Button, Popover } from 'antd';
import ArrowDownTable from 'assets/icons/ArrowDownTable';
import ArrowUpTable from 'assets/icons/ArrowUpTable';
import BotIcon from 'assets/icons/BotIcon';
import RankIcon from 'assets/icons/RankIcon';
import moment from 'moment';

import LogList from 'components/RecommendationsScreenComponents/LogList';
import TableMarket from 'components/RecommendationsScreenComponents/TableMarket';
import TradingView from 'components/TradingView';
import {
  Container,
  Heading,
  Detail,
  Rating,
  Market,
  CategoryRating,
  TradingViewNotice,
  HelpIcon,
  Actions,
  StatusSent,
} from './styles';
import { formatPrice } from 'utils';
import { Call } from 'constants/broker';
import { ReactComponent as HelpSvg } from 'assets/images/help.svg';
import { useSelector } from 'react-redux';

const DetailComponent = ({ data, onClickEdit, onClickSend }) => {
  const { t } = useTranslation(['recommendationsScreen']);
  const tradingViewRef = useRef();
  const sectorTradingViewRef = useRef();
  const { listSectors } = useSelector((state) => state.listSectors);
  const [logsFullScreen, setLogsFullScreen] = useState(false);

  const sectorName = useMemo(() => {
    const sector = listSectors?.find(
      (i) => i.sector === data.rating?.sector_name
    );
    return sector ? sector.name : data.rating?.sector_name;
  }, [data.rating, listSectors]);

  const displayDateTime = (value) => {
    return moment(value).format('HH:mm DD-MM-YYYY');
  };

  if (!data) {
    return null;
  }

  const showSectorChange = () => {
    if (data.rating?.sector_prev_rank) {
      const changed = data.rating?.sector_prev_rank - data.rating?.sector_rank;
      if (changed < 0) {
        return (
          <>
            <ArrowDownTable />{' '}
            <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
              {changed}
            </b>
          </>
        );
      }

      if (changed > 0) {
        return (
          <>
            <ArrowUpTable />{' '}
            <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
              {changed}
            </b>
          </>
        );
      }
    } else {
      if (data.rating?.grade_rank > 0) {
        return (
          <>
            <ArrowUpTable />{' '}
            <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
              {data.rating?.grade_rank}
            </b>
          </>
        );
      }

      if (data.rating?.grade_rank < 0) {
        return (
          <>
            <ArrowDownTable />{' '}
            <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
              {data.rating?.grade_rank}
            </b>
          </>
        );
      }
    }
  };

  return (
    <Container style={{ height: 'calc(100vh - 39rem - 274px)' }}>
      <Heading>{t('recommendationDetails')}</Heading>
      <Detail>
        <Row align="middle">
          <BotIcon width={15} height={18} />
          <div className="datetime">{displayDateTime(data.updatedAt)}</div>
        </Row>
        <Row
          style={{
            marginTop: '24px',
          }}
        >
          <Col span={5} className="info">
            <span>{t('recommendationForm.price')}</span>
            <strong>{formatPrice(data.recommendation?.price)}</strong>
          </Col>
          <Col span={5} className="info">
            {(data.recommendation?.tradingType === Call.BUY ||
              data.recommendation?.tradingType === Call.BUY_MORE ||
              data.recommendation?.tradingType === Call.BUY_TEST) && (
              <>
                <span>{t('recommendationForm.buyMore')}</span>
                <strong className="green">
                  {data.recommendation?.percentageProportion
                    ? `+${data.recommendation.percentageProportion}%`
                    : ''}
                </strong>
              </>
            )}
            {data.recommendation?.tradingType === Call.SELL && (
              <>
                <span>{t('recommendationForm.sell')}</span>
                <strong className="red">
                  {data.recommendation?.percentageProportion
                    ? `-${data.recommendation.percentageProportion}%`
                    : ''}
                </strong>
              </>
            )}
          </Col>
          <Col span={7} className="info">
            <span>
              {data.recommendation.isSend
                ? t('recommendationForm.actualHold')
                : t('recommendationForm.forecastHold')}
            </span>
            <strong>{data.recommendation.percentageHold || 0}%</strong>
          </Col>
          <Col span={7}>
            {(data.recommendation?.tradingType === Call.BUY ||
              data.recommendation?.tradingType === Call.BUY_MORE) && (
              <div className="chip green">{t('buyMore')}</div>
            )}
            {data.recommendation?.tradingType === Call.BUY_TEST && (
              <div className="chip green">{t('buyTest')}</div>
            )}
            {data.recommendation?.tradingType === Call.SELL && (
              <div className="chip red">{t('sell')}</div>
            )}
          </Col>
        </Row>
        {data.recommendation?.isSend ? (
          <Row>
            <Actions>
              <StatusSent>Đã gửi khuyến nghị</StatusSent>
            </Actions>
          </Row>
        ) : (
          <Actions>
            {onClickEdit && (
              <Button onClick={() => onClickEdit(data.recommendation)}>
                Sửa khuyến nghị
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => onClickSend(data.recommendation)}
            >
              Gửi khuyến nghị
            </Button>
          </Actions>
        )}
      </Detail>

      <Heading>
        {t('stockRating')} <strong>{data.recommendation?.symbol}</strong>
      </Heading>
      <Rating>
        <div className="datxRating">
          <Row style={{ alignItems: 'center' }} gutter={12}>
            <Col span={8}>
              <RankIcon /> Rank:{' '}
              <b style={{ fontWeight: 700, fontSize: 13 }}>
                {data.rating?.stock_rank}/{data.rating?.total_stock}
              </b>
            </Col>
            <Col
              span={8}
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {data.rating?.grade_rank > 0 && (
                <>
                  <ArrowUpTable />{' '}
                  <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
                    {data.rating?.grade_rank}
                  </b>
                </>
              )}
              {data.rating?.grade_rank < 0 && (
                <>
                  <ArrowDownTable />{' '}
                  <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
                    {data.rating?.grade_rank}
                  </b>
                </>
              )}
            </Col>
            {data.rating?.total_points && (
              <Col span={8} style={{ textAlign: 'right' }}>
                Điểm:{' '}
                <b style={{ fontWeight: 700, fontSize: 13 }}>
                  {Math.round(data.rating.total_points)}
                </b>
              </Col>
            )}
          </Row>
        </div>
        <div className="dashboard">
          <div className="tradingChart">
            <TradingView
              symbol={data.recommendation?.symbol}
              ref={tradingViewRef}
            />
          </div>
          <TradingViewNotice
            onClick={() => tradingViewRef.current?.fullScreen()}
          >
            Mở rộng biểu đồ
          </TradingViewNotice>
        </div>
      </Rating>

      <Heading>{t('market')}</Heading>
      <Market>
        <TableMarket data={data.market?.symbols} />
      </Market>

      <Heading>
        {t('categoryRating')} <strong>{sectorName}</strong>
        <Popover
          content={
            <>
              <strong>Dead Cross</strong>: xu hướng dòng tiền rút ra khỏi ngành.
              Lưu ý điểm giảm tỷ trọng ngành này
              <br />
              <strong>Golden Cross</strong>: xu hướng dòng tiền đang vào ngành.
              Hãy chú ý Gia tăng tỷ trọng nắm giữ
            </>
          }
        >
          <HelpIcon>
            <HelpSvg />
          </HelpIcon>
        </Popover>
      </Heading>
      <CategoryRating>
        <Row align="middle" gutter={12}>
          <Col span={7}>
            <RankIcon /> Rank:{' '}
            <b style={{ fontWeight: 700, fontSize: 13 }}>
              {data.rating?.sector_rank}/{data.rating?.total_sector}
            </b>
          </Col>
          <Col
            span={8}
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {showSectorChange()}
          </Col>
          {data.rating?.sector_points && (
            <Col span={8} style={{ textAlign: 'right' }}>
              Điểm:{' '}
              <b style={{ fontWeight: 700, fontSize: 13 }}>
                {Math.round(data.rating.sector_points)}
              </b>
            </Col>
          )}
        </Row>

        <div className="stockChart">
          <TradingView
            symbol={data.rating?.sector_name}
            ref={sectorTradingViewRef}
            path="golden-cross"
          />
          <TradingViewNotice
            style={{ paddingBottom: 20 }}
            onClick={() => sectorTradingViewRef.current?.fullScreen()}
          >
            Mở rộng biểu đồ
          </TradingViewNotice>
        </div>
      </CategoryRating>

      <Heading>{t('logs')}</Heading>
      <LogList
        symbol={data.recommendation?.symbol}
        fullScreen={logsFullScreen}
        onCloseFullScreen={() => setLogsFullScreen(false)}
      />
      <TradingViewNotice onClick={() => setLogsFullScreen(true)}>
        Mở rộng {t('logs')}
      </TradingViewNotice>
    </Container>
  );
};

const HistoryComponent = ({ data, onClickEdit, onClickSend }) => {
  const { t } = useTranslation(['recommendationsScreen']);
  const tradingViewRef = useRef();
  const { recommendation, rating } = data;

  const displayDateTime = (value) => {
    return moment(value).format('HH:mm DD-MM-YYYY');
  };

  return (
    <Container style={{ height: 'calc(100vh - 39rem - 274px)' }}>
      <Heading>{t('recommendationHistory')}</Heading>
      <Detail>
        <Row align="middle">
          <BotIcon width={15} height={18} />
          <div className="datetime">
            {displayDateTime(recommendation?.createdAt)}
          </div>
        </Row>
        <Row
          style={{
            marginTop: '24px',
          }}
        >
          <Col span={5} className="info">
            <span>{t('recommendationForm.price')}</span>
            <strong>{formatPrice(recommendation?.price)}</strong>
          </Col>
          <Col span={5} className="info">
            {(recommendation?.tradingType === Call.BUY ||
              recommendation?.tradingType === Call.BUY_MORE ||
              recommendation?.tradingType === Call.BUY_TEST) && (
              <>
                <span>{t('recommendationForm.buyMore')}</span>
                <strong className="green">
                  {recommendation?.percentageProportion
                    ? `+${recommendation.percentageProportion}%`
                    : ''}
                </strong>
              </>
            )}
            {recommendation?.tradingType === Call.SELL && (
              <>
                <span>{t('recommendationForm.sell')}</span>
                <strong className="red">
                  {recommendation?.percentageProportion
                    ? `-${recommendation.percentageProportion}%`
                    : ''}
                </strong>
              </>
            )}
          </Col>
          <Col span={7} className="info">
            <span>
              {recommendation?.isSend
                ? t('recommendationForm.actualHold')
                : t('recommendationForm.forecastHold')}
            </span>
            <strong>{recommendation?.percentageHold || 0}%</strong>
          </Col>
          <Col span={7}>
            {(recommendation?.tradingType === Call.BUY ||
              recommendation?.tradingType === Call.BUY_MORE) && (
              <div className="chip green">{t('buyMore')}</div>
            )}
            {recommendation?.tradingType === Call.BUY_TEST && (
              <div className="chip green">{t('buyTest')}</div>
            )}
            {recommendation?.tradingType === Call.SELL && (
              <div className="chip red">{t('sell')}</div>
            )}
          </Col>
        </Row>
        {recommendation?.isSend ? (
          <Row>
            <StatusSent>
              <span>Đã gửi khuyến nghị</span>
            </StatusSent>
          </Row>
        ) : (
          <Actions>
            {onClickEdit && (
              <Button onClick={() => onClickEdit(data.recommendation)}>
                Sửa khuyến nghị
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => onClickSend(data.recommendation)}
            >
              Gửi khuyến nghị
            </Button>
          </Actions>
        )}
      </Detail>

      <Heading>
        {t('stockRating')} <strong>{recommendation?.symbol}</strong>
      </Heading>
      <Rating>
        <div className="datxRating">
          <Row style={{ alignItems: 'center' }} gutter={12}>
            <Col span={8}>
              <RankIcon /> Rank:{' '}
              <b style={{ fontWeight: 700, fontSize: 13 }}>
                {rating?.stock_rank}
              </b>
            </Col>
            <Col
              span={8}
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {rating?.grade_rank > 0 && (
                <>
                  <ArrowUpTable />{' '}
                  <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
                    {rating?.grade_rank}
                  </b>
                </>
              )}
              {rating?.grade_rank < 0 && (
                <>
                  <ArrowDownTable />{' '}
                  <b style={{ fontWeight: 700, marginLeft: 4, fontSize: 13 }}>
                    {rating?.grade_rank}
                  </b>
                </>
              )}
            </Col>
            {rating?.total_points && (
              <Col span={8} style={{ textAlign: 'right' }}>
                Điểm:{' '}
                <b style={{ fontWeight: 700, fontSize: 13 }}>
                  {rating?.total_points}
                </b>
              </Col>
            )}
          </Row>
        </div>
        <div className="dashboard">
          <div className="tradingChart">
            <TradingView symbol={recommendation?.symbol} ref={tradingViewRef} />
          </div>
          <TradingViewNotice
            onClick={() => tradingViewRef.current?.fullScreen()}
          >
            Mở rộng biểu đồ
          </TradingViewNotice>
        </div>
      </Rating>
    </Container>
  );
};

const Loader = () => {
  return (
    <div
      className="fetch-post-loading"
      style={{
        textAlign: 'center',
        padding: '40px 0 20px',
      }}
    >
      <Spin size="large" />
    </div>
  );
};

const RecommendationDetails = ({
  loading,
  data,
  showingHistory,
  onClickEdit,
  onClickSend,
}) => {
  const handleEdit = (item) => {
    onClickEdit({
      symbol: item.symbol,
      key: item.id,
      hold: item.percentageHold,
      price: item.price,
      tradingType: item.tradingType,
      proportion: item.percentageProportion,
      type: item.type,
    });
  };

  const handleSend = (item) => {
    onClickSend({
      symbol: item.symbol,
      key: item.id,
      hold: item.percentageHold,
      price: item.price,
      tradingType: item.tradingType,
      proportion: item.percentageProportion,
      type: item.type,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {data && showingHistory && (
        <HistoryComponent
          data={data}
          onClickEdit={onClickEdit ? handleEdit : null}
          onClickSend={handleSend}
        />
      )}
      {data && !showingHistory && (
        <DetailComponent
          data={data}
          onClickEdit={onClickEdit ? handleEdit : null}
          onClickSend={handleSend}
        />
      )}
    </>
  );
};

export default RecommendationDetails;

import { useSelector } from 'react-redux';
import SmallLoader from '../../UIHandler/SmallLoader';
import BasicIndex from './BasicIndex';
import DATXPoint from './DATXPoint';
import DATXRatingPoint from './DATXRatingPoint';
import Overview from './Overview';
import { StyledWrapper } from './styles';
import Transactions from './Transactions';

const OverviewTab = ({ isLoading }) => {
  const { loadingStockDetails } = useSelector((state) => state.stockDetails);
  const { closeAnnouncement } = useSelector((state) => state.uiHandler);

  return (
    <div>
      {loadingStockDetails ? (
        <SmallLoader />
      ) : (
        <StyledWrapper
          className="stock-detail"
          closeAnnouncement={closeAnnouncement}
        >
          <div>
            <Overview isLoading={isLoading} />
            <DATXRatingPoint isLoading={isLoading} />
            <DATXPoint />
          </div>

          <div>
            <Transactions />
            <BasicIndex />
            {/* <RecommendStatus /> */}
            {/* <TransactionBottom /> */}
          </div>
        </StyledWrapper>
      )}
    </div>
  );
};

export default OverviewTab;

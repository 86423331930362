import { Slider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledSectionTitle } from '../../styles';
import {
  StyledItem,
  StyledSlideWrapper,
  StyledTransactionsWrapper,
} from './styles';

const Transactions = () => {
  const { data } = useSelector((state) => state.stockDetails);
  const { t } = useTranslation('ratingsScreen');

  const marksDayVol = {
    [data?.transaction?.day_vol.current]: {
      style: {
        color: 'var(--light-blue-color-2)',
      },
      label: (
        <strong style={{ position: 'absolute', top: '-4rem', left: '-1.4rem' }}>
          {data?.transaction?.day_vol.current}
        </strong>
      ),
    },
  };

  const marksV52wVol = {
    [data?.transaction?.v52w_vol.current]: {
      style: {
        color: 'var(--light-blue-color-2)',
      },
      label: (
        <strong style={{ position: 'absolute', top: '-4rem', left: '-1.4rem' }}>
          {data?.transaction?.v52w_vol.current}
        </strong>
      ),
    },
  };

  return (
    <StyledTransactionsWrapper>
      <StyledSectionTitle>
        {t('stockDetailsSection.tab1.transaction.title')}
      </StyledSectionTitle>
      <StyledItem>
        <div>
          {t('stockDetailsSection.tab1.transaction.mainContent.dayVol')}
        </div>

        <StyledSlideWrapper>
          <span>{data?.transaction?.day_vol.lowest}</span>
          <Slider
            value={data?.transaction?.day_vol.current}
            marks={marksDayVol}
            disabled={true}
            max={data?.transaction?.day_vol.highest}
            min={data?.transaction?.day_vol.lowest}
            step={null}
            tooltipVisible={false}
          />
          <span>{data?.transaction?.day_vol.highest}</span>
        </StyledSlideWrapper>
      </StyledItem>

      <StyledItem>
        <div>
          {t('stockDetailsSection.tab1.transaction.mainContent.v52wVol')}
        </div>
        <StyledSlideWrapper>
          <span>{data?.transaction?.v52w_vol.lowest}</span>
          <Slider
            value={data?.transaction?.v52w_vol.current}
            disabled={true}
            max={data?.transaction?.v52w_vol.highest}
            min={data?.transaction?.v52w_vol.lowest}
            marks={marksV52wVol}
            tooltipVisible={false}
            step={null}
          />
          <span>{data?.transaction?.v52w_vol.highest}</span>
        </StyledSlideWrapper>
      </StyledItem>

      <StyledItem>
        <div>{t('stockDetailsSection.tab1.transaction.mainContent.rs')}</div>
        <div>{data?.transaction?.rs.value}</div>
      </StyledItem>
    </StyledTransactionsWrapper>
  );
};

export default Transactions;

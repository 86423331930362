import React from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/date';
import { DatePicker, Tooltip, Typography } from 'antd';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

const { RangePicker } = DatePicker;
dayjs.locale('vi');

const StyledRangePicker = styled(RangePicker)`
  height: 40px !important;
  border: 0 !important;
  padding: 4px 8px !important;
  color: white;
  font-size: 1.6rem !important;

  .ant-picker-active-bar {
    display: none;
  }

  &:focus-visible {
    border: 0 !important;
    box-shadow: none;
  }
`;

export default function Date({ value, onChange }) {
  return (
    <div className="bg-custom-darkBlue600 rounded-lg p-3">
      <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">
        Thời gian
      </Typography.Text>
      <Tooltip
        className="cursor-pointer"
        destroyTooltipOnHide
        title="Nếu ngày hiệu lực là ngày hiện tại: Thời gian hiệu lực bắt đầu từ thời điểm hệ thống ghi nhận thành công yêu cầu đặt lệnh. Nếu ngày hiệu lực lớn hơn ngày hiện tại thời gian hiệu lực bắt đầu từ khi bắt đầu phiên giao dịch gần nhất với ngày hiệu lực."
        color="#212335"
      >
        <InfoIcon className="!w-8 !h-8 text-custom-darkBlue200" />
      </Tooltip>
      <StyledRangePicker
        value={value}
        locale={locale}
        className="w-full !border-none mt-2"
        format={DATE_FORMAT}
        onChange={onChange}
        placeholder={['Từ ngày', 'Đến ngày']}
        disabledDate={(date) => date && date <= dayjs().startOf('day')}
      />
    </div>
  );
}

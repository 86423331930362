const SendIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.459501 5.98628C0.0680007 5.85578 0.0642507 5.64503 0.467001 5.51078L14.7822 0.73928C15.179 0.60728 15.4062 0.82928 15.2952 1.21778L11.2048 15.5323C11.0923 15.929 10.8635 15.9425 10.6955 15.566L8 9.50003L12.5 3.50003L6.5 8.00003L0.459501 5.98628Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;

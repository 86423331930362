import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'components/GlobalComponents/Select';
import LeaderStockList from './LeaderStockList';
import Price from './Price';
import Proportion from './Proportion';
import { Button, Checkbox, Tooltip, Typography } from 'antd';
import { formatCurrency } from 'utils';
import InfoIcon from '@mui/icons-material/Info';
import { TradingOrderContext } from '../TradingOrderContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getTradingLeaderList, tradingOrderLeader, tradingOrderNewCopier } from 'utils/api';
import { toast } from 'react-toastify';
import AutoCompleteStock from 'components/GlobalComponents/AutocompleteStock';
import clsx from 'clsx';
import Date from './Date';
import Volume from './Volume';
import Confirmation from './Confirmation';
import pick from 'lodash/pick';
import { DATE_FORMAT_SERVER } from 'constants/date';
import Password from './Password';
import { COOKIES_KEY } from 'constants/commons';
import Cookies from 'js-cookie';
import moment from 'moment';

export const TRADE_TYPE = new Map([
  ['buy', 'BUY'],
  ['sell', 'SELL'],
  ['buyCopier', 'buy'],
]);

export const TRADE_TYPE_TRANSLATE = new Map([
  ['BUY', 'Mua'],
  ['SELL', 'Bán'],
  ['buy', 'Mua'],
]);

const ORDER_TYPE = new Map([
  ['normal', 'NORMAL'],
  ['sltp', 'SLTP'],
]);

const INITIAL_DATA = {
  tradeType: TRADE_TYPE.get('buyCopier'),
  price: 0,
  minPrice: 0,
  maxPrice: 0,
  proportionPercentage: 0,
  isSplitOrder: false,
  volume: 0,
  orderType: ORDER_TYPE.get('normal'),
  date: [moment(), moment()],
};

const ERRORS = {
  prices: '',
  proportionPercentage: '',
  date: '',
  volume: '',
};

export default function TradingFunction({ copierContext }) {
  const { symbol, symbolData, setSymbol } = useContext(TradingOrderContext);
  const [type, setType] = useState('copier');
  const [data, setData] = useState(INITIAL_DATA);
  const [keyword, setKeyword] = useState('');
  const [errors, setErrors] = useState(ERRORS);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const commonStockRef = useRef(null);

  const isCopier = type === 'copier';
  const isLeader = type === 'leader';

  const { data: leaderContext } = useQuery({
    queryKey: ['getTradingLeaderContext'],
    queryFn: () => getTradingLeaderList(symbol),
    enabled: isLeader,
  });

  const mutationCopier = useMutation({
    mutationFn: () =>
      tradingOrderNewCopier({
        ...data,
        symbol,
      }),
    onSuccess: async () => {
      toast.success('Đặt lệnh cho Copier mới thành công!');
      setOpenConfirmation(false);
      setData({
        ...INITIAL_DATA,
        price: symbolData?.refPrice / 1000 || 0,
      });
    },
    onError: async (error) => {
      toast.error('Đặt lệnh cho Copier mới thất bại', error);
    },
  });

  const mutationLeader = useMutation({
    mutationFn: () =>
      tradingOrderLeader({
        ...pick(data, ['tradeType', 'price', 'minPrice', 'maxPrice', 'proportionPercentage', 'orderType']),
        symbol,
        volume: data.volume.toString(),
        from: data.date[0].format(DATE_FORMAT_SERVER),
        to: data.date[1].format(DATE_FORMAT_SERVER),
        isSplitOrder: data.isSplitOrder,
      }),
    onSuccess: async () => {
      toast.success('Đặt lệnh cho Leader thành công!');
      openConfirmation && setOpenConfirmation(false);
      openPassword && setOpenPassword(false);
      setData({
        ...INITIAL_DATA,
        tradeType: TRADE_TYPE.get('buy'),
        price: symbolData?.refPrice / 1000 || 0,
      });
    },
    onError: async (error) => {
      toast.error('Đặt lệnh cho Leader thất bại', error);
    },
  });

  const leaderProportion = useMemo(() => {
    if (symbol) {
      const leaderAllocation = copierContext?.followerAllocations?.find((item) => item.symbol === symbol);
      return leaderAllocation ? leaderAllocation?.leaderProportion : 0;
    }
    return 0;
  }, [copierContext, symbol]);

  const validateCopier = useCallback(() => {
    if (data.price > symbolData.ceiling / 1000 || data.maxPrice > symbolData.ceiling / 1000) {
      setErrors((one) => ({ ...one, prices: 'Giá nhập vượt quá giá trần' }));
      return false;
    }

    if (data.minPrice === 0 && data.price < symbolData.floor / 1000) {
      setErrors((one) => ({ ...one, prices: 'Giá nhập nhỏ hơn giá sàn' }));
      return false;
    }

    if (data.minPrice > 0 && data.minPrice < symbolData.floor / 1000) {
      setErrors((one) => ({ ...one, prices: 'Giá nhập nhỏ hơn giá sàn' }));
      return false;
    }

    if (data.minPrice > data.maxPrice) {
      setErrors((one) => ({ ...one, prices: 'Nhập sai khoảng giá' }));
      return false;
    }

    if (data.proportionPercentage === 0) {
      setErrors((one) => ({
        ...one,
        proportionPercentage: 'Khối lượng nhập phải lớn hơn 0',
      }));
      return false;
    }

    if (data.proportionPercentage > (leaderProportion * 100).toFixed(2)) {
      setErrors((one) => ({
        ...one,
        proportionPercentage: 'Khối lượng nhập vượt quá giới hạn %',
      }));
      return false;
    }

    return true;
  }, [data, symbolData, leaderProportion]);

  const validateLeader = useCallback(() => {
    if (data.price === 0 && data.minPrice === 0 && data.maxPrice === 0) {
      setErrors((one) => ({ ...one, prices: 'Vui lòng nhập giá' }));
      return false;
    }

    if (data.minPrice > data.maxPrice) {
      setErrors((one) => ({ ...one, prices: 'Nhập sai khoảng giá' }));
      return false;
    }

    if (data.volume === 0) {
      setErrors((one) => ({
        ...one,
        volume: 'Khối lượng nhập phải lớn hơn 0',
      }));
      return false;
    }

    if (data.volume > 100 && data.volume % 100 !== 0) {
      setErrors((one) => ({
        ...one,
        volume: 'Khối lượng nhập chưa chính xác',
      }));
      return false;
    }

    if (!data.date) {
      setErrors((one) => ({ ...one, date: 'Vui lòng nhập thời gian' }));
      return false;
    }

    return true;
  }, [data]);

  useEffect(() => {
    setErrors(ERRORS);
  }, [data]);

  useEffect(() => {
    setData({
      ...INITIAL_DATA,
      tradeType: isCopier && copierContext ? TRADE_TYPE.get('buyCopier') : TRADE_TYPE.get('buy'),
      price: symbolData?.refPrice / 1000 || 0,
    });
  }, [symbolData?.refPrice, type, isCopier, copierContext]);

  useEffect(() => {
    symbol && setKeyword(symbol);
  }, [type, symbol]);

  return (
    <div className="w-full shrink-0 md:w-[328px] overflow-hidden !overflow-y-auto h-[calc(100vh_-_238px)] flex flex-col gap-4">
      <Select
        title="Đối tượng"
        active={type}
        setActive={setType}
        options={[
          { key: 'copier', label: 'Copier mới' },
          { key: 'leader', label: 'Leader' },
        ]}
      />

      {isCopier && <LeaderStockList />}
      {isLeader && (
        <div className="flex items-center gap-28 bg-custom-darkBlue600 rounded-lg pl-4 py-1.5">
          <Typography.Text className="!text-custom-gray300 whitespace-nowrap">Chọn mã CP</Typography.Text>
          <AutoCompleteStock
            setKeyword={(keyword) => {
              setKeyword(keyword);
            }}
            keyword={keyword}
            onChange={(keyword) => {
              if (keyword) {
                setSymbol(keyword);
              }
            }}
            inputRef={commonStockRef}
          />
        </div>
      )}

      {isLeader && (
        <div className="flex p-2 gap-1 bg-custom-darkBlue600 rounded-lg">
          <Button
            type="ghost"
            className={clsx(
              'flex-1 font-bold !h-[32px] !rounded-lg !border-none !text-custom-darkBlue200',
              data.tradeType === TRADE_TYPE.get('buy') &&
                '!text-black hover:!text-black !bg-green !border-green hover:!bg-green-600',
            )}
            onClick={() =>
              setData((one) => ({
                ...one,
                tradeType: TRADE_TYPE.get('buy'),
              }))
            }
          >
            Mua
          </Button>
          <Button
            type="ghost"
            className={clsx(
              'flex-1 font-bold !h-[32px] !rounded-lg !border-none !text-custom-darkBlue200',
              data.tradeType === TRADE_TYPE.get('sell') &&
                '!text-white hover:!text-white !bg-red !border-red hover:!bg-red-600',
            )}
            onClick={() =>
              setData((one) => ({
                ...one,
                tradeType: TRADE_TYPE.get('sell'),
              }))
            }
          >
            Bán
          </Button>
        </div>
      )}

      {isLeader && data.tradeType === TRADE_TYPE.get('sell') && (
        <div className="flex flex-col">
          <Typography.Text className="text-white font-bold m-2">Loại lệnh</Typography.Text>
          <div className="flex p-2 gap-1 bg-custom-darkBlue600 rounded-lg">
            <Button
              type="ghost"
              className={clsx(
                'flex-1 font-bold !h-[32px] !rounded-lg !border-none !text-custom-darkBlue200',
                data.orderType === ORDER_TYPE.get('normal') &&
                  '!text-white hover:!text-white !bg-[#293244] !border-[#293244] hover:!bg-custom-darkBlue400',
              )}
              onClick={() =>
                setData((one) => ({
                  ...one,
                  orderType: ORDER_TYPE.get('normal'),
                }))
              }
            >
              Thường
            </Button>
            <Button
              type="ghost"
              className={clsx(
                'flex-1 font-bold !h-[32px] !rounded-lg !border-none !text-custom-darkBlue200',
                data.orderType === ORDER_TYPE.get('sltp') &&
                  '!text-white hover:!text-white !bg-[#293244] !border-[#293244] hover:!bg-custom-darkBlue400',
              )}
              onClick={() =>
                setData((one) => ({
                  ...one,
                  orderType: ORDER_TYPE.get('sltp'),
                }))
              }
            >
              SL/TP
            </Button>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-1">
        <Price data={data} setData={setData} base={symbolData} />
        {errors.prices && <Typography.Text type="danger">{errors.prices}</Typography.Text>}
      </div>

      {isCopier && (
        <div className="flex flex-col gap-1">
          <Proportion
            proportionPercentage={data.proportionPercentage}
            setProportionPercentage={(value) => setData((one) => ({ ...one, proportionPercentage: value }))}
          />
          {errors.proportionPercentage && (
            <Typography.Text type="danger">{errors.proportionPercentage}</Typography.Text>
          )}

          <div className="flex justify-between gap-3 mt-1">
            <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">Tối đa</Typography.Text>
            <Typography.Text
              className="mr-3 whitespace-nowrap cursor-pointer"
              onClick={() =>
                setData((one) => ({
                  ...one,
                  proportionPercentage: (leaderProportion * 100).toFixed(2),
                }))
              }
            >
              {(leaderProportion * 100).toFixed(2)} %
            </Typography.Text>
          </div>
        </div>
      )}

      {isLeader && (
        <div className="flex flex-col gap-2">
          <Volume vol={data.volume} setVol={(value) => setData((one) => ({ ...one, volume: value }))} />
          {errors.volume && <Typography.Text type="danger">{errors.volume}</Typography.Text>}
        </div>
      )}

      {isCopier && (
        <div className="flex justify-between gap-3">
          <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">Nav mới</Typography.Text>
          <Typography.Text className="!text-lg mr-3 whitespace-nowrap">
            {formatCurrency(copierContext?.totalNewFollowerNav || 0)} VND
          </Typography.Text>
        </div>
      )}

      {isLeader && (
        <div>
          {data.tradeType === TRADE_TYPE.get('buy') && (
            <div className="flex justify-between gap-3 ml-1">
              <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">Sức mua</Typography.Text>
              <Typography.Text className="!text-lg mr-3 whitespace-nowrap">
                {formatCurrency(leaderContext?.maxBuyableVolume || 0)} đ
              </Typography.Text>
            </div>
          )}
          <div className="flex justify-between gap-3 ml-1">
            <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">
              {`KL ${TRADE_TYPE_TRANSLATE.get(data.tradeType).toLowerCase()} tối đa`}
            </Typography.Text>
            {/* Note: BE wrong name field but still use */}
            <Typography.Text className="!text-lg mr-3 whitespace-nowrap">
              {formatCurrency(leaderContext?.purchaseAbility || leaderContext?.maxSellableVolume || 0)}
            </Typography.Text>
          </div>
        </div>
      )}

      {isLeader && (
        <div className="flex flex-col gap-2">
          <Date
            value={data.date}
            onChange={(value) =>
              setData((one) => ({
                ...one,
                date: value,
              }))
            }
          />
          {errors.date && <Typography.Text type="danger">{errors.date}</Typography.Text>}
        </div>
      )}

      <div>
        <Checkbox
          onChange={(e) => setData((one) => ({ ...one, isSplitOrder: e.target.checked }))}
          checked={data.isSplitOrder}
        >
          <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">Chia nhỏ lệnh</Typography.Text>
        </Checkbox>
        <Tooltip
          className="cursor-pointer"
          destroyTooltipOnHide
          title={'Hệ thống thực hiện chia lệnh thành nhiều lệnh nhỏ và đảm bảo tổng KL các lệnh = KL Leader đặt.'}
          color="#212335"
        >
          <InfoIcon className="!w-8 !h-8 text-custom-darkBlue200" />
        </Tooltip>
      </div>

      <Button
        type="ghost"
        className={clsx(
          '!font-bold !h-[40px] !rounded-lg ',
          (data.tradeType === TRADE_TYPE.get('buy') || data.tradeType === TRADE_TYPE.get('buyCopier')) &&
            '!text-black !bg-green !border-green hover:!bg-green-600',
          data.tradeType === TRADE_TYPE.get('sell') && '!text-white !bg-red !border-red hover:!bg-red-600',
        )}
        onClick={() =>
          (isLeader && validateLeader()) || (isCopier && validateCopier()) ? setOpenConfirmation(true) : null
        }
      >
        {TRADE_TYPE_TRANSLATE.get(data.tradeType)}
      </Button>

      <Confirmation
        loading={mutationCopier.isLoading || mutationLeader.isLoading}
        data={{
          type,
          symbol,
          tradeType: data.tradeType,
          ...(data.volume > 0 && { volume: data.volume }),
          ...(data.proportionPercentage > 0 && {
            proportionPercentage: data.proportionPercentage,
          }),
          price: data.price,
          minPrice: data.minPrice,
          maxPrice: data.maxPrice,
          isSplitOrder: data.isSplitOrder,
        }}
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onConfirm={() => {
          if (isCopier && validateCopier()) {
            mutationCopier.mutate();
          }

          if (isLeader && validateLeader()) {
            if (Cookies.get(COOKIES_KEY.ctRememberPassword)) {
              mutationLeader.mutate();
            } else {
              setOpenConfirmation(false);
              setOpenPassword(true);
            }
          }
        }}
      />

      <Password
        open={openPassword}
        setOpen={setOpenPassword}
        onConfirm={() => {
          mutationLeader.mutate();
        }}
      />
    </div>
  );
}

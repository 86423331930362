import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import axiosInstance from 'utils/axiosInstance';

let controller;

const { query } = queryString.parseUrl(window.location.href, {
  parseNumbers: true,
});

export const defaultFilter = {
  keyword: query.keyword || '',
  sector: query.sector || '',
};

export const defaultPagination = {
  page: 1,
  limit: 10,
  isNext: true,
};

const initialState = {
  loading: false,
  posts: [],
  sectors: [],
  keyword: '',
  isFirstMount: null,
  pagination: defaultPagination,
  filter: defaultFilter,
};

const { REACT_APP_URL } = process.env;

export const getNewsInit = createAsyncThunk(
  'news/get-posts-int',
  async (
    { thunkAPI, filter = defaultFilter, pagination = defaultPagination },
    { rejectWithValue, getState }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/rating/sector-news`,
      {
        sectors:
          localStorage.getItem('newsSreenSectorList') !== ''
            ? localStorage.getItem('newsSreenSectorList').split(',')
            : [],
        ...pagination,
        keyword: getState().news.keyword,
      },
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('getNews');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const getNews = createAsyncThunk(
  'news/get-posts',
  async (
    { thunkAPI, filter = defaultFilter, pagination = defaultPagination },
    { rejectWithValue, getState }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/rating/sector-news`,
      {
        sectors:
          localStorage.getItem('newsSreenSectorList') !== ''
            ? localStorage.getItem('newsSreenSectorList').split(',')
            : [],
        ...pagination,
        keyword: getState().news.keyword,
      },
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('getNews');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = true;
      state.posts = [];
      state.pagination = defaultPagination;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setFirstMount: (state, { payload }) => {
      state.isFirstMount = payload;
    },

    handleSelectSectors: (state, action) => {
      state.sectors = action.payload;
    },

    handleResetPagination: (state, { payload }) => {
      state.pagination = defaultPagination;
    },

    handleSetKeyword: (state, { payload }) => {
      state.keyword = payload;
    },
  },
  extraReducers: {
    // ------------------- getNews ------------------- //

    [getNewsInit.pending]: (state) => {
      state.loading = true;
    },
    [getNewsInit.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.posts = payload.data;
    },
    [getNewsInit.rejected]: (state) => {
      state.loading = false;
    },

    [getNews.pending]: (state) => {
      // state.loading = true
    },
    [getNews.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.posts = [...state.posts, ...payload.data];
      state.pagination = payload.pagination;
      state.filter = payload.filter;
    },
    [getNews.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  reset,
  setLoading,
  handleSelectSectors,
  handleSetKeyword,
  setFirstMount,
  handleResetPagination,
} = newsSlice.actions;

export default newsSlice.reducer;

import FailureIconInNotiDialog from 'assets/icons/FailureIconInNotiDialog';

const PermissionDenied = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FailureIconInNotiDialog />
      <span>PERMISSION_DENIED</span>
    </div>
  );
};

export default PermissionDenied;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 10px;
  min-height: calc(100vh - 60px - 165px);
  height: calc(100vh - 60px - 165px);
`;

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  color: #49c2f2;
  text-transform: uppercase;
  strong {
    color: #f24b78;
  }
`;

export const Detail = styled.div`
  background: #023b53;
  border-radius: 4px;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 30px;

  svg {
    path {
      fill: #c3cbdb;
    }
  }

  .datetime {
    font-weight: 700;
    font-size: 11px;
    color: #dff2fb;
    margin-left: 10px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #ffffff;
    }
    strong {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      &.green {
        color: #bff249;
      }
      &.red {
        color: rgb(242, 75, 120);
      }
    }
  }

  .chip {
    background: #1f2336;
    border-radius: 4px;
    padding: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #1ed994;
  }
  .chip.green {
    color: #1ed994;
  }
  .chip.red {
    color: rgb(242, 75, 120);
  }
`;

export const Rating = styled.div`
  margin-bottom: 30px;
  .datxRating {
    margin-top: 10px;
    h3 {
      font-weight: 700;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }

    .score {
      width: 46px;
      height: 46px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dashboard {
    margin-top: 20px;
    h3 {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
    }
    .tradingChart {
      margin-top: 10px;
      background: #1f2336;
      border-radius: 4px;
      height: 274px;
    }
  }
`;

export const Market = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
`;

export const CategoryRating = styled.div`
  margin-bottom: 45px;
  .datxRating {
    margin-top: 20px;
    h3 {
      font-weight: 700;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
    .rank {
      display: flex;
      flex-direction: column;
    }
    .score {
      width: 64px;
      height: 64px;
      border: 3px solid #ffffff;
      border-radius: 50%;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .stockChart {
    margin-top: 20px;
    background: #1f2336;
    border-radius: 4px;
    height: 295px;

    .ant-image,
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TradingViewNotice = styled.div`
  font-size: 12px;
  color: #f24b78;
  margin-top: 10px;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`;

export const HelpIcon = styled.span`
  margin-left: 8px;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    margin-left: 10px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const StatusSent = styled.div`
  background: #12316f;
  border-radius: 4px;
  height: 44px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  color: #989898;
  font-weight: 700;
`;

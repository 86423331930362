import { Spin } from 'antd';
import { SpinContainer } from './styles';

const SmallLoader = () => {
  return (
    <SpinContainer>
      <Spin
        size="large"
        style={{
          margin: '0 auto',
          display: 'block',
          lineHeight: '40rem',
        }}
      />
    </SpinContainer>
  );
};

export default SmallLoader;

import styled from 'styled-components';

export const WatchlistScreenContainer = styled.div`
  display: grid;
  grid-template-columns: 18em 1fr 32.625em;
  grid-gap: 2rem;
  height: 100%;

  @media (max-width: 100em) {
    grid-template-columns: 28.8rem 1fr;

    & > div:nth-child(3) {
      grid-area: 2/1/2/3;
    }
  }

  @media (max-width: 56.25em) {
    grid-template-columns: 1fr;

    & > div:nth-child(3) {
      grid-area: unset;
    }
  }
`;

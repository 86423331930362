import { Row, Col, Form, Select, DatePicker, Button, Typography } from 'antd';
import {
  SearchOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ExpandIcon from 'assets/icons/RowExpandIcon';

import { StyledTitle } from '../styles';
import { Filter, StyledTable, CircleStat, StyledForm } from './styles';
import { getRecommendationPerformance } from 'features/recommendation/performanceSlice';
import { hsxCompanyList } from 'data/hsxCompanyList';
import ExpandedRow from '../ExpandedRow';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';

const { Text } = Typography;

const columns = (expandIndex) => [
  {
    title: 'STT',
    key: 'stt',
    align: 'center',
    width: '9%',
    render: (_, __, index) => index + 1,
  },
  {
    title: 'Mã CK',
    key: 'symbol',
    align: 'center',
    width: '7%',
    dataIndex: 'symbol',
  },
  {
    title: (
      <Text strong>
        Khuyến nghị <span className="buy">MUA</span>
      </Text>
    ),
    className: 'no-border',
    align: 'center',
    children: [
      {
        title: 'Tổng số lượng',
        key: 'number_buy',
        className: 'border-right',
        align: 'center',
        dataIndex: 'number_buy',
        width: '7%',
      },
      {
        title: 'Tổng tỷ trọng',
        key: 'total_buy',
        // dataIndex: "total_buy",
        align: 'center',
        width: '7%',
        // render: (value) => <span className="buy">{`${value}%`}</span>,
      },
    ],
  },
  {
    title: (
      <Text strong>
        Khuyến nghị <span className="sell">BÁN</span>
      </Text>
    ),
    className: 'no-border',
    align: 'center',
    children: [
      {
        title: 'Tổng số lượng',
        key: 'number_sell',
        className: 'border-right',
        dataIndex: 'number_sell',
        align: 'center',
        width: '7%',
      },
      {
        title: 'Tổng tỷ trọng',
        key: 'total_sell',
        dataIndex: 'total_sell',
        align: 'center',
        width: '7%',
        render: (value) => <span className="sell">{`${value}%`}</span>,
      },
    ],
  },
  {
    title: 'Tỷ trọng đang nắm giữ',
    key: 'total_remain',
    align: 'center',
    width: '7%',
    dataIndex: 'total_remain',
    render: (value) => (
      <span className="buy">{value >= 0 ? `${value}%` : ''}</span>
    ),
  },
  {
    title: 'Tỷ trọng khả dụng',
    key: 'avaiable_volume',
    align: 'center',
    width: '7%',
    dataIndex: 'avaiable_volume',
    render: (value) => (
      <span className="buy">{value >= 0 ? `${value}%` : ''}</span>
    ),
  },
  {
    title: 'Giá mua chi tiết từng lệnh',
    key: 'price',
    align: 'center',
    width: '7%',
  },
  {
    title: 'Giá mua Trung bình',
    key: 'avg_price',
    align: 'center',
    dataIndex: 'avg_price',
    width: '7%',
    render: (value) => value && (value / 1000).toFixed(2),
  },
  {
    title: (
      <Text strong>
        Giá thị trường / <span style={{ color: '#7C7C7C' }}>Giá bán</span>
      </Text>
    ),
    width: '7%',
    key: 'current_price',
    align: 'center',
    dataIndex: 'current_price',
    render: (value) => value && (value / 1000).toFixed(2),
  },
  {
    title: 'Hiệu suất TB tạm tính',
    key: 'efficiency_temporary',
    align: 'center',
    dataIndex: 'efficiency_temporary',
    width: '7%',
    render: (value) =>
      value ? (
        value > 0 ? (
          <span className="buy">{`+${(value * 100).toFixed(2)}%`}</span>
        ) : (
          <span className="sell">{`${(value * 100).toFixed(2)}%`}</span>
        )
      ) : (
        ''
      ),
  },
  {
    title: 'Hiệu suất TB đã chốt',
    key: 'efficiency_closed',
    dataIndex: 'efficiency_closed',
    align: 'center',
    width: '7%',
    render: (value) =>
      value ? (
        value > 0 ? (
          <span className="buy">{`+${(value * 100).toFixed(2)}%`}</span>
        ) : (
          <span className="sell">{`${(value * 100).toFixed(2)}%`}</span>
        )
      ) : (
        ''
      ),
  },
  {
    title: <ExpandIcon />,
    key: 'expanded',
    align: 'center',
    width: '7%',
    render: (record) => {
      return expandIndex.includes(record.key) ? (
        <CaretUpOutlined style={{ color: '#3EA4CD' }} />
      ) : (
        <CaretDownOutlined style={{ color: '#3EA4CD' }} />
      );
    },
  },
];

const PerformanceTable = () => {
  const [expandIndex, setExpandIndex] = useState([]);
  const { data, summary, paging, loading } = useSelector(
    (state) => state.recommendationPerformance
  );
  const [filter, setFilter] = useState({
    page: 1,
    limit: 30,
    symbol: '',
    startDate: moment().subtract(1, 'M').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const interval = useRef();

  const execFilter = (values) => {
    setFilter({
      ...filter,
      symbol: values.symbol || '',
      startDate: values.startDate?.format('DD-MM-YYYY'),
      endDate: values.endDate?.format('DD-MM-YYYY'),
    });
  };

  useEffect(() => {
    dispatch(getRecommendationPerformance(filter));
    interval.current = setInterval(() => {
      if (!loading) {
        dispatch(getRecommendationPerformance({ ...filter, polling: true }));
      }
    }, 10000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [filter]);

  return (
    <>
      <Row gutter={[24, 12]} align="middle" justify="center">
        <Col xl={16}>
          <Col span={24}>
            <StyledTitle level={4}>Hiệu Quả Khuyến Nghị</StyledTitle>
          </Col>
          <Col span={24}>
            <StyledForm
              form={form}
              layout="horizontal"
              initialValues={{
                startDate: moment().subtract(1, 'M'),
                endDate: moment(),
              }}
              onFinish={execFilter}
            >
              <Filter gutter={24}>
                <Col xl={8} style={{ padding: '0 5px', minWidth: 170 }}>
                  <Form.Item label="Mã cổ phiếu" name="symbol">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.value ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) => {
                        return (optionA?.value ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.value ?? '').toLowerCase());
                      }}
                      allowClear
                    >
                      <Select.Option value="">Tất cả</Select.Option>
                      {hsxCompanyList.map((a) => (
                        <Select.Option key={a.Code} value={a.Code}>
                          {a.Code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ padding: '0 5px', minWidth: 180 }} xl={6}>
                  <Form.Item label="Từ ngày" name="startDate">
                    <DatePicker format="DD-MM-YYYY" />
                  </Form.Item>
                </Col>
                <Col style={{ padding: '0 5px', minWidth: 190 }} xl={6}>
                  <Form.Item label="Đến ngày" name="endDate">
                    <DatePicker format="DD-MM-YYYY" />
                  </Form.Item>
                </Col>
                <Col xl={4}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="primary"
                      icon={<SearchOutlined />}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => form.submit()}
                    >
                      Tìm kiếm
                    </Button>
                  </div>
                </Col>
              </Filter>
            </StyledForm>
          </Col>
        </Col>
        <Col xl={4}>
          <CircleStat>
            <h3>
              Hiệu suất <br /> khuyến nghị tạm tính
            </h3>
            {summary.efficiency_temporary < 0 ? (
              <div style={{ color: '#D00000' }}>
                {summary.efficiency_temporary}%
              </div>
            ) : (
              <div style={{ color: '#05FF00' }}>
                {summary.efficiency_temporary}%
              </div>
            )}
          </CircleStat>
        </Col>
        <Col xl={4}>
          <CircleStat>
            <h3>
              Hiệu suất <br /> khuyến nghị đã chốt
            </h3>
            {summary.efficiency_closed < 0 ? (
              <div style={{ color: '#D00000' }}>
                {summary.efficiency_closed}%
              </div>
            ) : (
              <div style={{ color: '#05FF00' }}>
                {summary.efficiency_closed}%
              </div>
            )}
          </CircleStat>
        </Col>
      </Row>
      <Row gutter={[24, 12]} align="middle" justify="center">
        <Col span={24}>
          <StyledTable
            loading={{
              spinning: loading,
              indicator: <AbsoluteLoader size="default" />,
            }}
            className="modal-table"
            columns={columns(expandIndex)}
            dataSource={data.map((a, index) => ({ ...a, key: index }))}
            expandable={{
              expandedRowRender: (record) => {
                return <ExpandedRow record={record} />;
              },
              expandIcon: () => null,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandIndex((state) => [...state, record.key]);
                } else {
                  setExpandIndex((state) =>
                    state.filter((a) => a !== record.key)
                  );
                }
              },
              columnWidth: 0,
              expandRowByClick: true,
            }}
            pagination={{
              position: ['bottomCenter'],
              total: paging.total,
              pageSize: paging.page_size,
              current: paging.page,
              onChange: (page) => setFilter({ ...filter, page }),
            }}
            scroll={{
              y: 'calc(100vh - 340px)',
              x: 'auto',
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PerformanceTable;

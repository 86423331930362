import { Modal } from 'antd';
import styled from 'styled-components';

export const StockConnectModalStyled = styled(Modal)`
  width: 100%;
  height: fit-content;
  padding: 0;

  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1f2336 !important;
    border-radius: 8px;
    padding: 0;
  }

  .ant-modal-body {
    width: 100%;
    height: 100%;
    background: #1c1f2f;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ant-form-item {
    margin: 0;
  }

  input {
    background: #1f2336 !important;
    height: 32px;
  }

  .ant-radio-wrapper {
    color: white;
  }
`;

export const StyledInput = styled.div`
  .ant-input-affix-wrapper,
  .ant-input {
    /* padding-bottom: 0.5rem;
    padding-top: 0.5rem; */
  }
  .ant-input-affix-wrapper.ant-input-password,
  .ant-select-selector {
    padding-left: 0;
    border-color: #6b697a;
    height: 38px !important;
    border-radius: 8px !important;
  }
  .ant-input-affix-wrapper-disabled,
  .ant-input-disabled {
    color: #aaaaaa !important;
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    border: 1px solid #6b697a !important;
  }
  .ant-input {
    border-color: #6b697a;
    color: white;
  }

  .ant-input-affix-wrapper input {
    padding-left: 11px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none !important;
  }
`;

export const FormContainer = styled.div`
  .ant-input-group-addon {
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: transparent;
    border: 1px solid #fff;
  }

  form .ant-row.ant-form-item {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-form-item-label > label::after {
    content: ' ';
  }

  .ant-form-item {
    font-size: 0.9375rem;
    margin-bottom: 1rem;
  }

  .ant-form-item-has-error.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item-has-success.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 0;
    color: transparent;
    font-size: 0;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .ant-form-item-label > label {
    font-size: 1.7rem;
    background: transparent;
    color: #fff;
  }

  .ant-radio-group {
    justify-content: space-between;
    gap: 2rem;
    display: flex;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px transparent;
  }

  .ant-radio-wrapper {
    margin: 0;
  }

  .ant-radio-group.ant-radio-group-outline > label > span {
    color: #fff;
  }

  .ant-radio-inner {
    background-color: transparent !important;
  }

  .ant-radio-inner::after {
    background-color: #005dfb !important;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 1.7rem;
  }

  .ant-form-item-explain-error {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: #fff;
  }

  .ant-select-selection-item {
    line-height: 30px !important;
  }

  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    font-size: 1.7rem;
    display: flex;
    justify-content: flex-start;
  }

  .ant-form-item .ant-picker-calendar-year-select,
  .ant-form-item .ant-picker-calendar-month-select,
  .ant-form-item .ant-input-group .ant-select,
  .ant-form-item .ant-input-group .ant-cascader-picker {
    width: 9rem;
  }

  .ant-select-item {
    font-size: 1.7rem;
  }

  // .ant-select-single.ant-select-open .ant-select-selection-item {
  //   font-size: 1.7rem;
  // }

  .ant-select-item-option-content {
    text-overflow: ellipsis;
    font-size: 1.7rem;
  }

  .ant-input::placeholder {
    color: #bfbfbf;
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-input-password-icon {
    color: #fff;
  }

  .ant-form-item-has-error.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    color: #f5f5f5;
  }

  .ant-form-item-has-success.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    color: #1492e6;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 4.4rem 0;
  }

  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: transparent;
    border-color: #fff;
  }

  .ant-input-affix-wrapper {
    /* padding-top: 4px !important;
    padding-bottom: 4px !important; */
    /* height: 2.5rem; */
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #fff;
    width: 38rem;
  }

  .custom-helper
    .ant-col.ant-form-item-control
    > div:nth-child(2)
    .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: -46%;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    transition: none;
  }

  & > .custom-helper > .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: -48% !important;
  }
`;

export const ConnectAccountFormContainer = styled.section`
  width: 100%;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item-control-input-content a.login-form-forgot {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: end;
  }

  .ant-tabs {
    overflow: visible !important;
  }
`;

export const RegisterScreenContainer = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  overflow-y: auto;
  padding-top: 13.6%;

  @media only screen and (max-width: 500px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ant-tabs {
    width: 25.75rem;
    overflow: visible;
  }

  .ant-tabs-nav-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ant-tabs-content-holder {
    margin-top: 2.625rem;
  }

  .ant-tabs-content {
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 32rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media only screen and (min-width: 90.0625em) {
  }

  @media only screen and (max-width: 37.5em) {
    padding-top: 55%;
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    color: #fff;
    background-color: transparent;
    line-height: 1.25rem;
    font-size: 0.9375rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .ant-form-item-label > label {
    font-size: 1.214rem;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn {
    font-size: 1.38rem;
    line-height: 3.7rem;
    color: rgba(255, 255, 255, 0.7);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    text-shadow: 0 0 0.25px currentColor;
    font-size: 2.125rem;
    line-height: 2.875rem;
    font-weight: 700;
  }

  .ant-tabs-ink-bar {
    background: #1492e6;
    height: 4px !important;
  }

  .ant-tabs-tab-btn {
    outline: none;
    transition: none;
  }

  @media only screen and (max-width: 1024px) {
    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
      text-shadow: 0 0 0.25px currentColor;
      font-size: 1.38rem;
      font-weight: 700;
    }
  }
`;

import React from 'react';

const DATXLogoIcon = () => {
  return (
    <svg
      width="88"
      height="33"
      viewBox="0 0 88 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1281_1622)">
        <path
          d="M37.2021 1.46069L39.0365 5.2055C39.7259 6.44976 40.3733 6.88856 41.9978 6.88856C42.0158 6.88856 47.7466 6.90058 47.7466 6.90058L47.7645 19.9864C47.7645 21.441 49.7487 25.0656 53.2915 25.0656V24.9334V6.88856H63.3624L60.1733 2.9514C59.5738 2.02572 58.5427 1.4667 57.4398 1.4667H37.2021V1.46069Z"
          fill="white"
        />
        <path
          d="M69.9625 2.68692C69.3031 1.91151 68.332 1.46069 67.3129 1.46069H62.0557L79.3559 23.8033C80.0093 24.5787 80.9745 25.0235 81.9875 25.0235H87.2028L69.9625 2.68692Z"
          fill="white"
        />
        <path
          d="M88 1.43058C88 1.43058 86.2736 1.3885 83.5101 1.44861C81.7537 1.48468 80.7046 1.88741 79.7275 3.05353C78.4747 4.54424 76.3286 6.92457 76.3286 6.92457L81.0104 6.91254C82.8567 6.90653 85.0986 6.02293 86.0098 4.56227L88 1.43058Z"
          fill="white"
        />
        <path
          d="M11.7313 1.46072L0 1.43066L0.0179837 24.9875H11.7553C18.9847 24.9875 23.4687 18.9525 23.4687 13.2061C23.4687 7.5017 19.0387 1.46072 11.7313 1.46072ZM11.9352 19.5055H5.46703V6.91864H11.9352C15.448 6.91864 17.9657 10.1345 17.9657 13.2481C17.9657 16.44 15.4959 19.5055 11.9352 19.5055Z"
          fill="white"
        />
        <path
          d="M82.4071 0.577049C81.4839 0.703279 79.9613 1.08197 78.8943 2.81913C77.8812 4.47213 76.3286 6.91858 76.3286 6.91858L80.9504 6.22131C82.7728 5.94481 84.5351 5.04918 85.3384 3.39618L86.8131 0C86.8131 0 85.2485 0.198361 82.4071 0.577049Z"
          fill="url(#paint0_linear_1281_1622)"
        />
        <path
          d="M72.2582 21.8676C76.5383 16.1633 78.5405 13.5966 81.8255 8.88403C80.8544 9.40698 80.2729 9.28677 79.3557 9.28677L74.9917 9.26272C74.9917 9.26272 71.1072 14.4862 68.1579 18.3032C62.1993 26.0152 58.6026 27.554 53.6571 27.554C52.2244 27.554 49.2691 27.1212 46.7993 24.284C49.323 27.3376 56.0129 33.4627 66.0778 28.5338C68.1219 26.8507 70.1661 24.6567 72.2582 21.8676Z"
          fill="url(#paint1_linear_1281_1622)"
        />
        <path
          d="M46.7994 24.2842C46.4817 23.9176 46.17 23.5148 45.8763 23.064C45.7384 22.8657 45.6725 22.7515 45.6725 22.7515L45.6365 22.6793C45.5825 22.5892 45.5226 22.511 45.4686 22.4148L38.3891 9.1367L34.2588 1.40063L22.8752 22.3367C21.934 24.4586 19.9438 25.0657 19.9438 25.0657L26.1302 24.9875C27.2212 24.9875 27.9466 24.5006 28.4801 23.5509L34.2349 12.8815C34.9122 14.0717 40.8049 25.3482 40.8049 25.3482C43.1547 29.3094 48.4659 33.0001 54.2806 33.0001C58.4289 33.0001 62.2594 31.6657 66.0779 28.528C56.0131 33.4629 49.3291 27.3318 46.7994 24.2842Z"
          fill="url(#paint2_linear_1281_1622)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1281_1622"
          x1="59.9355"
          y1="29.7335"
          x2="90.5899"
          y2="-6.78921"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3598" stop-color="#0064FA" />
          <stop offset="0.4622" stop-color="#2E45E8" />
          <stop offset="0.5598" stop-color="#532CDA" />
          <stop offset="0.6386" stop-color="#6A1DD1" />
          <stop offset="0.6885" stop-color="#7217CE" />
          <stop offset="0.7837" stop-color="#A70EC0" />
          <stop offset="0.882" stop-color="#D607B4" />
          <stop offset="0.9573" stop-color="#F402AC" />
          <stop offset="1" stop-color="#FF00A9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1281_1622"
          x1="55.2442"
          y1="27.6161"
          x2="84.6929"
          y2="-0.550914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFFF" />
          <stop offset="0.0310381" stop-color="#00DBFE" />
          <stop offset="0.0795926" stop-color="#00A8FC" />
          <stop offset="0.122" stop-color="#0083FB" />
          <stop offset="0.1562" stop-color="#006CFA" />
          <stop offset="0.1779" stop-color="#0064FA" />
          <stop offset="0.2" stop-color="#0C5CF5" />
          <stop offset="0.2739" stop-color="#3143E7" />
          <stop offset="0.3482" stop-color="#4D30DC" />
          <stop offset="0.422" stop-color="#6222D4" />
          <stop offset="0.4952" stop-color="#6E1AD0" />
          <stop offset="0.5672" stop-color="#7217CE" />
          <stop offset="0.6351" stop-color="#7616CD" />
          <stop offset="0.7089" stop-color="#8314C9" />
          <stop offset="0.7855" stop-color="#9911C4" />
          <stop offset="0.8639" stop-color="#B80CBC" />
          <stop offset="0.9431" stop-color="#DE05B2" />
          <stop offset="1" stop-color="#FF00A9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1281_1622"
          x1="63.8395"
          y1="16.6979"
          x2="15.5933"
          y2="17.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0D0083" />
          <stop offset="0.0960161" stop-color="#0C098F" />
          <stop offset="0.2701" stop-color="#0822AF" />
          <stop offset="0.5008" stop-color="#034AE3" />
          <stop offset="0.5989" stop-color="#005DFB" />
          <stop offset="0.6393" stop-color="#0886DF" />
          <stop offset="0.6794" stop-color="#0FA7C8" />
          <stop offset="0.7176" stop-color="#14BFB8" />
          <stop offset="0.7531" stop-color="#17CDAE" />
          <stop offset="0.7834" stop-color="#18D2AB" />
          <stop offset="0.8512" stop-color="#69DD6F" />
          <stop offset="0.9234" stop-color="#B9E834" />
          <stop offset="0.9748" stop-color="#ECEE0E" />
          <stop offset="1" stop-color="#FFF100" />
        </linearGradient>
        <clipPath id="clip0_1281_1622">
          <rect width="88" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DATXLogoIcon;

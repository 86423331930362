import { useQuery } from '@tanstack/react-query';
import { DATE_FORMAT, DATE_FORMAT_SERVER } from 'constants/date';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { formatCurrency, renderCopierStatus } from 'utils';
import { getCopiers } from 'utils/api';
import CopierFilter from './CopierFilter';
import { StyledTable, TableButton } from 'components/GlobalComponents/styled';
import EyeIcon from 'assets/icons/EyeIcon';
import InvestmentHold from './InvestmentHold';

const tradeType = {
  buy: 'Mua',
  sell: 'Bán',
  both: 'Cả hai',
};
export default function CopierList() {
  const [filter, setFilter] = useState({});
  const [openInvestment, setOpenInvestment] = useState(false);
  const [itemCopier, setCopier] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
  });
  const { data, isLoading } = useQuery({
    queryKey: ['getCopiers', filter, pagination],
    queryFn: () =>
      getCopiers({
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.subAccountId && { subAccountId: filter?.subAccountId }),
        ...(filter?.registCopyDate && {
          registCopyDateFrom: moment(filter?.registCopyDate[0]).format(
            DATE_FORMAT_SERVER
          ),
        }),
        ...(filter?.registCopyDate && {
          registCopyDateTo: moment(filter?.registCopyDate[1]).format(
            DATE_FORMAT_SERVER
          ),
        }),
        ...(filter?.registeredNav && {
          registeredNavFrom: filter?.registeredNav[0] * 1e7,
        }),
        ...(filter?.registeredNav && {
          registeredNavTo: filter?.registeredNav[1] * 1e7,
        }),
        ...(filter?.totalCopyFee && {
          totalCopyFeeFrom: filter?.totalCopyFee[0] * 1e7,
        }),
        ...(filter?.totalCopyFee && {
          totalCopyFeeTo: filter?.totalCopyFee[1] * 1e7,
        }),
        ...(filter?.totalInvestmentPerformanceFee && {
          totalInvestmentPerformanceFeeFrom:
            filter?.totalInvestmentPerformanceFee[0] * 1e7,
        }),
        ...(filter?.totalInvestmentPerformanceFee && {
          totalInvestmentPerformanceFeeTo:
            filter?.totalInvestmentPerformanceFee[1] * 1e7,
        }),
        page: pagination.current,
        size: pagination.pageSize,
      }),
  });

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'name',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Số TKCK',
        dataIndex: 'subAccountId',
        render: (data) => data?.slice(0, -3) + '***',
      },
      {
        title: 'Ngày ĐK copy',
        dataIndex: 'registCopyDate',
        render: (data) => moment(data).format(DATE_FORMAT),
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) => renderCopierStatus(data),
      },
      {
        title: 'NAV đăng ký',
        render: ({ currentFund, registedNav }) => (
          <p style={{ 'text-wrap': 'nowrap' }}>
            {formatCurrency(currentFund || registedNav || 0) + ' VNĐ'}
          </p>
        ),
      },
      {
        title: 'NAV hiện tại',
        dataIndex: 'currentNav',
        render: (data) => (
          <p style={{ 'text-wrap': 'nowrap' }}>
            {formatCurrency(data || 0) + ' VNĐ'}
          </p>
        ),
      },
      {
        title: 'Margin',
        dataIndex: 'useMargin',
        render: (data) => (data ? 'Có' : 'Không'),
      },
      {
        title: 'Chiều copy',
        dataIndex: 'tradeType',
        render: (data) => tradeType[data] || '',
      },
      {
        title: 'Hiệu suất ĐT',
        dataIndex: 'investmentPerformance',
      },
      {
        title: 'Tổng phí copy',
        dataIndex: 'totalCopyFee',
        render: (data) => formatCurrency(data),
      },
      {
        title: 'Tổng phí hiệu quả ĐT',
        dataIndex: 'totalInvestmentPerformanceFee',
        render: (data) => formatCurrency(data),
      },
      {
        title: 'Tỷ trọng nắm giữ',
        dataIndex: 'status',
        render: (sts, row) => (
          <TableButton onClick={(event) => handleClickView(event, row)}>
            <EyeIcon />
          </TableButton>
        ),
      },
    ],
    []
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };

  const handleClickView = (event, item) => {
    console.log('handleClickView', item);
    setCopier(item);
    setOpenInvestment(true);
  };

  return (
    <div>
      <InvestmentHold
        open={openInvestment}
        item={itemCopier}
        onClose={() => setOpenInvestment(null)}
      />
      <CopierFilter onFilter={(values) => setFilter(values)} />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        pagination={{
          total: data?.pagination.totalPage * data?.pagination.size || 0,
          current: data?.pagination.page || 0,
          pageSize: data?.pagination.size || 0,
          position: ['bottomCenter'],
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
        columns={columns}
        dataSource={data?.data}
        scroll={{ x: 1600 }}
      />
    </div>
  );
}

import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Col, Dropdown, Menu, Row, Tooltip } from 'antd';
import ThreeDotsIcon from 'assets/icons/ThreeDotsIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  GradeIndexStyled,
  StatusColorStyled,
  ThreeDotsButton,
  WatchlistItemHeader,
  WatchlistItemStyled,
  WatchlistNameStyled,
} from './styles';

const WatchlistItem = ({
  onOpenEditModal,
  onOpenDeleteModal,
  data,
  onClick,
}) => {
  const { t } = useTranslation(['watchlistScreen']);
  const { currentSelectedWatchlist } = useSelector((state) => state.watchlist);

  if (!data) return null;

  const ActionsDropwdown = (
    <Menu onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <Menu.Item onClick={onOpenDeleteModal.bind(this, data)} key="2">
        <DeleteOutlined style={{ color: '#8E8E93' }} /> &nbsp;
        {t('watchlistSelectorSection.watchlistManagement.item2')}
      </Menu.Item>
    </Menu>
  );

  return (
    <WatchlistItemStyled
      onClick={onClick}
      active={currentSelectedWatchlist === data._id}
    >
      <WatchlistItemHeader>
        <Tooltip
          destroyTooltipOnHide
          title={data.name}
          color="var(--light-blue-color)"
        >
          <WatchlistNameStyled
            className="watchlist-name"
            active={currentSelectedWatchlist === data._id}
          >
            {data.name}
          </WatchlistNameStyled>
        </Tooltip>

        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={(e) => e.stopPropagation()}
        >
          <span
            style={{
              paddingRight: '0.6rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={onOpenEditModal.bind(this, data)}
          >
            <EditOutlined style={{ color: '#8E8E93' }} />
          </span>

          <Dropdown
            destroyPopupOnHide
            overlay={ActionsDropwdown}
            trigger={['click']}
          >
            <ThreeDotsButton onClick={(e) => e.stopPropagation()}>
              <ThreeDotsIcon />
            </ThreeDotsButton>
          </Dropdown>
        </div>
      </WatchlistItemHeader>
      <Row style={{ fontSize: '13px' }}>
        <Col xs={6}>
          <StatusColorStyled status={data.total_points}>
            <Tooltip
              placement="bottom"
              title={t(
                'watchlistSelectorSection.header.tooltipHelper.tooltip1'
              )}
              color="var(--light-blue-color)"
            >
              {data.total_points}
            </Tooltip>
          </StatusColorStyled>
        </Col>
        <Col xs={6}>
          <StatusColorStyled
            status={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip
              placement="bottom"
              title={t(
                'watchlistSelectorSection.header.tooltipHelper.tooltip2'
              )}
              color="var(--light-blue-color)"
            >
              <GradeIndexStyled>
                <CaretUpOutlined />
                <span>{data.highest_return}</span>
              </GradeIndexStyled>
            </Tooltip>
          </StatusColorStyled>
        </Col>
        <Col xs={6}>
          <StatusColorStyled
            status={-1}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Tooltip
              placement="bottom"
              title={t(
                'watchlistSelectorSection.header.tooltipHelper.tooltip3'
              )}
              color="var(--light-blue-color)"
            >
              <GradeIndexStyled style={{ justifyContent: 'end' }} status={-1}>
                <CaretDownOutlined />
                <span>{data.lowest_return}</span>
              </GradeIndexStyled>
            </Tooltip>
          </StatusColorStyled>
        </Col>
        <Col xs={6}>
          <StatusColorStyled status={0} style={{ textAlign: 'right' }}>
            <Tooltip
              placement="bottom"
              title={t(
                'watchlistSelectorSection.header.tooltipHelper.tooltip4'
              )}
              color="var(--light-blue-color)"
            >
              <span style={{ position: 'relative', bottom: '1px' }}></span>
              <span>{data.percent}</span>
            </Tooltip>
          </StatusColorStyled>
        </Col>
      </Row>
    </WatchlistItemStyled>
  );
};

export default WatchlistItem;

import { FullscreenExitOutlined } from '@ant-design/icons';
import { Empty, Modal, Tooltip } from 'antd';
import ExpandFourAnglesIcon from 'assets/icons/ExpandFourAnglesIcon';
import AreaChartWithEdge from 'components/ChartComponents/StockDetailOverview';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';
import { getStockDetailsOverview } from 'features/ratings/stockDetailsSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSectionTitle } from '../../styles';
import {
  StyledHeaderIcon,
  StyledOverviewSection,
  StyledTopTab,
  StyledTopTabItem,
} from './styles';

const Overview = ({ isLoading }) => {
  const { data, dataOverview, currentSelectedStock, loadingChart } =
    useSelector((state) => state.stockDetails);
  const { areaChartWithEdge } = useSelector((state) => state.uiHandler);
  const { t } = useTranslation('ratingsScreen');
  const [tickChart, setTickChart] = useState(10);
  const [dateFormatState, setDateFormatState] = useState('days');

  // eslint-disable-next-line no-unused-vars
  const data1 = [
    {
      start: '2022-04-20T03:00:00.055Z',
      end: '2022-04-25T04:00:00.055Z',
      price: 57.4,
      volume: 960300,
    },
    {
      start: '2022-04-21T04:00:00.055Z',
      end: '2022-04-25T05:00:00.055Z',
      price: 58.4,
      volume: 165606,
    },
    {
      start: '2022-04-22T05:00:00.055Z',
      end: '2022-04-25T06:00:00.055Z',
      price: 58.4,
      volume: 103006,
    },
    {
      start: '2022-04-23T06:00:00.055Z',
      end: '2022-04-25T07:00:00.055Z',
      price: 59.0,
      volume: 1163300,
    },
    {
      start: '2022-04-24T07:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 58.7,
      volume: 1264300,
    },

    {
      start: '2022-04-25T03:00:00.055Z',
      end: '2022-04-25T04:00:00.055Z',
      price: 57.9,
      volume: 960300,
    },
    {
      start: '2022-04-26T04:00:00.055Z',
      end: '2022-04-25T05:00:00.055Z',
      price: 58.4,
      volume: 165606,
    },
    {
      start: '2022-04-27T05:00:00.055Z',
      end: '2022-04-25T06:00:00.055Z',
      price: 57.4,
      volume: 103006,
    },
    {
      start: '2022-04-28T06:00:00.055Z',
      end: '2022-04-25T07:00:00.055Z',
      price: 58.2,
      volume: 1163300,
    },
    {
      start: '2022-04-29T07:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.8,
      volume: 1264300,
    },

    {
      start: '2022-04-30T08:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.4,
      volume: 1001300,
    },

    {
      start: '2022-05-01T09:00:00.055Z',
      end: '2022-04-26T09:00:00.055Z',
      price: 55.4,
      volume: 1316300,
    },
    {
      start: '2022-05-02T10:00:00.055Z',
      end: '2022-04-25T07:00:00.746Z',
      price: 56.4,
      volume: 1260300,
    },
  ];

  var stockDetailOverview = [];

  dataOverview?.forEach((_row, _index) => {
    if (_row.date) {
      var _newRow = {};

      _newRow.date = new Date(_row.date);

      _newRow.price = _row.price;
      _newRow.sector_val = _row.sector_val;

      stockDetailOverview.push(_newRow);
    }
  });

  const [activeIndex, setActiveIndex] = useState(3);
  const dispatch = useDispatch();

  const boxsOverview = ['5m', '15m', '30m', '1D', '5D', '1M'];

  const handleOnClickOverview = (index) => {
    setActiveIndex(index);

    if (activeIndex !== index) {
      switch (index) {
        case 0:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 300,
            })
          );

          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 1:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 900,
            })
          );

          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 2:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 1800,
            })
          );

          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 3:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 86400,
            })
          );

          setTickChart(10);
          setDateFormatState('days');

          break;

        case 4:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 86400 * 5,
            })
          );

          setTickChart(3);
          setDateFormatState('days');

          break;

        case 5:
          dispatch(
            getStockDetailsOverview({
              stockCode: currentSelectedStock,
              timeDistance: 86400 * 30,
            })
          );

          setTickChart(10);
          setDateFormatState('days');

          break;

        default:
          break;
      }
    }
  };

  const boxOverview = boxsOverview.map((el, index) => {
    return (
      <StyledTopTabItem
        key={index}
        onClick={() => handleOnClickOverview(index)}
        className={activeIndex === index ? 'active' : 'unactive'}
      >
        {el}
      </StyledTopTabItem>
    );
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <StyledOverviewSection>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledSectionTitle>
          {t('stockDetailsSection.tab1.overview.title')}
        </StyledSectionTitle>
        <div
          style={{ paddingTop: '3.2rem', paddingBottom: '1.2rem' }}
          onClick={showModal}
        >
          <Tooltip
            title={t('helper.toolTip1')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <StyledHeaderIcon>
              <ExpandFourAnglesIcon />
            </StyledHeaderIcon>
          </Tooltip>
        </div>
      </div>

      {/* <StyledIconGroup>
        <RefreshIcon />
        <span>VOL</span>
        <CandleStickIcon />
      </StyledIconGroup> */}
      <StyledTopTab style={{ width: '90%' }}>{boxOverview}</StyledTopTab>

      <div style={{ position: 'relative' }}>
        {loadingChart && <AbsoluteLoader noBg z999 />}

        {stockDetailOverview.length > 1 && !isLoading ? (
          <AreaChartWithEdge
            type="hybrid"
            data={stockDetailOverview}
            chartHeight={202}
            marginRight={40}
            tickStroke={areaChartWithEdge.tickStroke}
            gradientColor1={areaChartWithEdge.gradientColor1}
            gradientColor2={areaChartWithEdge.gradientColor2}
            lastPrice={data?.overall?.last_price}
            xAxisTick={activeIndex === 4 ? 3 : 6}
            dateFormatState={dateFormatState}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>

      <Modal
        centered
        destroyOnClose
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <Tooltip
            title={t('helper.toolTip1')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                lineHeight: '4.4rem',
                justifyContent: 'center',
              }}
              onClick={handleCancel}
            >
              <FullscreenExitOutlined
                style={{
                  fontSize: 20,
                  color: 'var(--border-color)',
                  display: 'flex',
                  alignItems: 'center',
                  height: '4.4rem',
                }}
              />
            </span>
          </Tooltip>
        }
        width="100rem"
      >
        <>
          <div
            style={{
              padding: '1.6rem',
              background: 'var(--section-header-color)',
            }}
          >
            {t('stockDetailsSection.tab1.overview.title')}
          </div>

          {/* <StyledIconGroup
            style={{ paddingLeft: "1.6rem", paddingTop: "1.6rem" }}
          >
            <RefreshIcon />
            <span>VOL</span>
            <CandleStickIcon />
          </StyledIconGroup> */}
          <StyledTopTab
            className="chart-modal"
            style={{ padding: '0 24rem', margin: '1rem auto' }}
          >
            {boxOverview}
          </StyledTopTab>

          {loadingChart && <AbsoluteLoader noBg />}

          {stockDetailOverview.length > 1 && !isLoading ? (
            <AreaChartWithEdge
              type="hybrid"
              data={stockDetailOverview}
              chartHeight={400}
              marginRight={60}
              tickStroke={areaChartWithEdge.tickStroke}
              gradientColor1={areaChartWithEdge.gradientColor1}
              gradientColor2={areaChartWithEdge.gradientColor2}
              lastPrice={data?.overall?.last_price}
              xAxisTick={tickChart}
              dateFormatState={dateFormatState}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ padding: '100px 0' }}
            />
          )}
        </>
      </Modal>
    </StyledOverviewSection>
  );
};

export default Overview;

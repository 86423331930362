import { Avatar, Menu, Popover, Tooltip } from 'antd';
import BotIcon from 'assets/icons/BotIcon';
import CollapseIcon from 'assets/icons/CollapseIcon';
import DashboardIcon from 'assets/icons/DashboardIcon';
import ExpandIcon from 'assets/icons/ExpandIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import NewsIcon from 'assets/icons/NewsIcon';
import PersonIcon from 'assets/icons/PersonIcon';
import PieChartIcon from 'assets/icons/PieChartIcon';
import PortfoliosIcon from 'assets/icons/PortfoliosIcon';
import SettingIcon from 'assets/icons/SettingIcon';
import GuideIcon from 'assets/icons/GuideIcon';
import SupportIcon from 'assets/icons/SupportIcon';
import WatchlistIcon from 'assets/icons/WatchlistIcon';
import {
  expandSidebarSelector,
  handleCollapsePortfolio,
  handleCollapseSidebar,
  handleExpandSidebar,
} from 'features/uiHandler/uiHandlerSlice';
import { getAllUserInfo } from 'features/user/userSlice';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CollapseButton,
  ProfileContainer,
  SidebarBottom,
  StyledLink,
  StyledProfileContent,
  StyledProfileInfo,
  StyledUsername,
} from './styles';
import SetupAutoRecommendation from 'components/RecommendationsScreenComponents/SetupAutoRecommendation';
import ReportIcon from 'assets/icons/ReportIcon';
import styled from 'styled-components';
import CopyTradeIcon from 'assets/icons/CopyTradeIcon';
import GavelIcon from '@mui/icons-material/Gavel';
import { removeAuthCookieData } from 'constants/commons';

const TextWhenClosed = styled.p`
  font-size: 1rem;
  text-align: center;
  display: block;
  font-weight: 500;
  letter-spacing: 0em;
  color: white;
  width: 9.2rem;
  white-space: nowrap;
  line-height: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 5px;

  &:hover {
    color: var(--light-blue-color-2);
  }
`;

function TitleCollaped({ title, expandSidebar }) {
  if (expandSidebar) return null;
  return <TextWhenClosed>{title}</TextWhenClosed>;
}
const GUIDE_LINK = 'https://s3-hn-2.cloud.cmctelecom.vn/aibroker/documents/AiBroker%2BUser%2BManual.pdf';

const Sidebar = ({ type, onClose }) => {
  /* -------------------------------------------------------------------------- */
  /*                                    DATA                                    */
  /* -------------------------------------------------------------------------- */
  const { t } = useTranslation(['common']);
  const [visibleSetupAutoRecommendation, setVisibleAutoSetupRecommendation] = useState(false);

  const submenus = [
    {
      key: 'portfolios',
      title: t('sideBar.subMenu1.title'),
      icon: <PortfoliosIcon />,
      router: '/',
      items: [
        {
          key: '/',
          title: t('sideBar.subMenu1.item1'),
          icon: <DashboardIcon />,
          router: '/',
        },
        {
          key: '/news',
          title: 'News',
          icon: <NewsIcon />,
          router: '/news',
        },
      ],
    },
  ];

  const bottomData = [
    {
      title: t('sideBar.subMenu5.item0'),
      icon: <BotIcon />,
      onClick: () => {
        setVisibleAutoSetupRecommendation(true);
      },
    },
    {
      title: t('sideBar.subMenu5.item1'),
      icon: <SupportIcon />,
      router: '/support',
    },
    {
      title: t('sideBar.subMenu5.item2'),
      icon: <SettingIcon />,
      router: '/settings',
    },
    {
      title: t('sideBar.subMenu5.item3'),
      icon: <LogoutIcon />,
      router: '/logout',
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                   ******                                   */
  /* -------------------------------------------------------------------------- */

  const { REACT_APP_SERVICE_CODE, REACT_APP_AUTH_URL } = process.env;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile = type === 'mobile';

  const isExpandSidebar = useSelector(expandSidebarSelector);
  const expandSidebar = isMobile || isExpandSidebar;
  const userInfo = useSelector(getAllUserInfo);
  const isShowBrokerSideBar = userInfo?.role?.permissions.includes('recommendation_stock.get');

  const isShowDerivativeSideBar = userInfo?.role?.permissions.includes('derivative_recommendation_stock.get');
  const isShowCopytrade = userInfo?.role?.permissions.includes('broker_copytrade.leader');
  const hasBrokerManagerPermission =
    userInfo?.role?.permissions.includes('broker.manager.get') ||
    userInfo?.role?.permissions.includes('broker.manager.admin');

  const sidebarBottomRef = useRef();

  const [menuBottomVisible, setMenuBottomVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState(() => {
    for (let i = 0; i < submenus.length; i++) {
      const submenuRoutes = submenus[i].items.map((item) => item.router);
      if (submenuRoutes.includes(location.pathname)) {
        return [submenus[i].key];
      }
    }
    return [];
  });

  const onOpenChange = (keys) => {
    const openKey = keys.pop();
    setOpenKeys(openKey ? [openKey] : []);
  };

  const handleSideBottomClick = (url) => {
    if (url === '/logout') {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expiredTime');
      localStorage.removeItem('isAuthed');
      removeAuthCookieData();

      window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
      return;
    }

    setMenuBottomVisible(false);
    history.push(url);
    setOpenKeys([]);
    dispatch(handleCollapsePortfolio());
    isMobile && onClose();
  };

  const handleCollapse = () => {
    dispatch(handleCollapseSidebar());
    localStorage.setItem('expandSidebar', false);
  };

  const handleExpand = () => {
    dispatch(handleExpandSidebar());
    localStorage.setItem('expandSidebar', true);
  };

  const checkMatchUrl = (url) => {
    return location.pathname.startsWith(url);
  };

  const BottomMenu = bottomData.map((item) => (
    <StyledLink
      key={item.router}
      active={checkMatchUrl(item.router)}
      expandSidebar={expandSidebar}
      onClick={item.onClick ? item.onClick : handleSideBottomClick.bind(this, item.router)}
    >
      {expandSidebar ? (
        item.icon
      ) : (
        <Tooltip color="var(--light-blue-color)" placement="right" title={item.title} trigger={['hover']}>
          {item.icon}{' '}
        </Tooltip>
      )}
      <span>{item.title}</span>
    </StyledLink>
  ));

  return (
    <>
      <Menu
        theme="dark"
        defaultSelectedKeys={['/']}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[location.pathname]}
        onSelect={() => isMobile && onClose()}
        style={!isMobile ? { marginTop: '14px' } : {}}
      >
        {isShowBrokerSideBar && (
          <>
            <Menu.Item
              key="/"
              style={{ paddingLeft: '1.25rem !important' }}
              onClick={() => {
                history.push('/');
                setOpenKeys([]);
              }}
              icon={
                expandSidebar ? (
                  <BotIcon />
                ) : (
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="right"
                    title={t('sideBar.botRecommendations')}
                    trigger={['hover']}
                  >
                    <BotIcon />
                  </Tooltip>
                )
              }
            >
              {t('sideBar.botRecommendations')}
            </Menu.Item>
            <TitleCollaped title={t('sideBar.botRecommendations')} expandSidebar={expandSidebar} />
            <Menu.Item
              key="/recommendation-performance"
              style={{ paddingLeft: '1.2rem' }}
              onClick={() => {
                history.push('/recommendation-performance');
              }}
              icon={
                expandSidebar ? (
                  <PieChartIcon />
                ) : (
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="right"
                    title={t('sideBar.recommendationPerformance')}
                    trigger={['hover']}
                  >
                    <PieChartIcon />
                  </Tooltip>
                )
              }
            >
              {t('sideBar.recommendationPerformance')}
            </Menu.Item>
            <TitleCollaped title={t('sideBar.recommendationPerformance')} expandSidebar={expandSidebar} />
            <Menu.Item
              key="/watchlist"
              onClick={() => {
                history.push('/watchlist');
                setOpenKeys([]);
              }}
              icon={
                expandSidebar ? (
                  <WatchlistIcon />
                ) : (
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="right"
                    title={t('sideBar.subMenu4')}
                    trigger={['hover']}
                  >
                    <span>
                      <WatchlistIcon />
                    </span>
                  </Tooltip>
                )
              }
            >
              {t('sideBar.subMenu4')}
            </Menu.Item>
            <TitleCollaped title={t('sideBar.subMenu4')} expandSidebar={expandSidebar} />
            <Menu.Item
              key="/manage-customer-files"
              style={{ paddingLeft: '1.2rem' }}
              onClick={() => {
                history.push('/manage-customer-files');
              }}
              icon={
                expandSidebar ? (
                  <PersonIcon />
                ) : (
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="right"
                    title={t('sideBar.manageCustomerFiles')}
                    trigger={['hover']}
                  >
                    <PersonIcon />
                  </Tooltip>
                )
              }
            >
              {t('sideBar.manageCustomerFiles')}
            </Menu.Item>
            <TitleCollaped title={t('sideBar.manageCustomerFiles')} expandSidebar={expandSidebar} />
            {hasBrokerManagerPermission && (
              <>
                <Menu.Item
                  key="/report-recommendation-performance"
                  style={{ paddingLeft: '1.2rem' }}
                  onClick={() => {
                    history.push('/report-recommendation-performance');
                  }}
                  icon={
                    expandSidebar ? (
                      <ReportIcon />
                    ) : (
                      <Tooltip
                        color="var(--light-blue-color)"
                        placement="right"
                        title={t('sideBar.reportRecommemdationPerformance')}
                        trigger={['hover']}
                      >
                        <ReportIcon />
                      </Tooltip>
                    )
                  }
                >
                  {t('sideBar.reportRecommemdationPerformance')}
                </Menu.Item>
                <TitleCollaped title={t('sideBar.reportRecommemdationPerformance')} expandSidebar={expandSidebar} />
              </>
            )}

            {isShowDerivativeSideBar && (
              <>
                <Menu.Item
                  key="/derivative-recommendation"
                  style={{ paddingLeft: '1.2rem' }}
                  onClick={() => {
                    history.push('/derivative-recommendation');
                  }}
                  icon={
                    expandSidebar ? (
                      <BotIcon />
                    ) : (
                      <Tooltip
                        color="var(--light-blue-color)"
                        placement="right"
                        title={t('sideBar.derivativeRecommendation')}
                        trigger={['hover']}
                      >
                        <BotIcon />
                      </Tooltip>
                    )
                  }
                >
                  {t('sideBar.derivativeRecommendation')}
                </Menu.Item>
                <TitleCollaped title={t('sideBar.derivativeRecommendation')} expandSidebar={expandSidebar} />
              </>
            )}
          </>
        )}

        {isShowCopytrade && (
          <>
            <Menu.Item
              key="/copyTrade"
              style={{ paddingLeft: '1.2rem' }}
              onClick={() => {
                history.push('/copyTrade');
              }}
              icon={
                expandSidebar ? (
                  <CopyTradeIcon />
                ) : (
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="right"
                    title={t('sideBar.copyTrade')}
                    trigger={['hover']}
                  >
                    <CopyTradeIcon />
                  </Tooltip>
                )
              }
            >
              {t('sideBar.copyTrade')}
            </Menu.Item>
            <TitleCollaped title={t('sideBar.copyTrade')} expandSidebar={expandSidebar} />
          </>
        )}

        <>
          <Menu.Item
            key="/trading"
            style={{ paddingLeft: '1.2rem' }}
            onClick={() => {
              history.push('/trading');
            }}
            icon={
              expandSidebar ? (
                <GavelIcon className="!w-10 !h-10" />
              ) : (
                <Tooltip
                  color="var(--light-blue-color)"
                  placement="right"
                  title={t('sideBar.trading')}
                  trigger={['hover']}
                >
                  <GavelIcon className="!w-10 !h-10" />
                </Tooltip>
              )
            }
          >
            {t('sideBar.trading')}
          </Menu.Item>
          <TitleCollaped title={t('sideBar.trading')} expandSidebar={expandSidebar} />

          <Menu.Item
            key="/how-to-use"
            style={{ paddingLeft: '1.2rem' }}
            onClick={() => window.open(GUIDE_LINK, '_blank')}
            icon={
              expandSidebar ? (
                <GuideIcon />
              ) : (
                <Tooltip
                  color="var(--light-blue-color)"
                  placement="right"
                  title={t('sideBar.howtoUse')}
                  trigger={['hover']}
                >
                  <GuideIcon />
                </Tooltip>
              )
            }
          >
            {t('sideBar.howtoUse')}
          </Menu.Item>
          <TitleCollaped title={t('sideBar.howtoUse')} expandSidebar={expandSidebar} />
        </>
      </Menu>

      <SidebarBottom ref={sidebarBottomRef} expandSidebar={expandSidebar} isMobile={isMobile}>
        <Popover
          content={BottomMenu}
          trigger={['click', 'hover']}
          placement="top"
          getPopupContainer={() => sidebarBottomRef.current}
          open={menuBottomVisible}
          onVisibleChange={() => setMenuBottomVisible(!menuBottomVisible)}
        >
          <ProfileContainer expandSidebar={expandSidebar}>
            <StyledProfileInfo expandSidebar={expandSidebar}>
              <Avatar size={32} src={userInfo?.profile_photo} alt="Profile Img" />
              <StyledProfileContent>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledUsername>{userInfo?.full_name}</StyledUsername>
                  <div
                    style={{
                      fontSize: '1.1rem',
                      color: 'var(--white-color)',
                    }}
                  >
                    {userInfo?.email}
                  </div>
                </div>
              </StyledProfileContent>
            </StyledProfileInfo>

            {!isMobile && (
              <CollapseButton>
                {expandSidebar ? (
                  <span onClick={handleCollapse}>
                    <Tooltip
                      color="var(--light-blue-color)"
                      placement="right"
                      title={t('sideBar.helper.toolTip2')}
                      trigger={['hover', 'click']}
                    >
                      <CollapseIcon />{' '}
                    </Tooltip>
                  </span>
                ) : (
                  <span onClick={handleExpand}>
                    <Tooltip
                      color="var(--light-blue-color)"
                      placement="right"
                      title={t('sideBar.helper.toolTip1')}
                      trigger={['hover', 'click']}
                    >
                      <ExpandIcon />{' '}
                    </Tooltip>
                  </span>
                )}
              </CollapseButton>
            )}
          </ProfileContainer>
        </Popover>
      </SidebarBottom>

      {visibleSetupAutoRecommendation && (
        <SetupAutoRecommendation
          visible={visibleSetupAutoRecommendation}
          onCancel={() => {
            setVisibleAutoSetupRecommendation(false);
          }}
          onOk={() => {
            setVisibleAutoSetupRecommendation(false);
          }}
        />
      )}
    </>
  );
};

export default Sidebar;

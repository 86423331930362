import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from 'features/user/userSlice';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  isChangedLanguage: false,
};

export const postUpdateUserInfo = createAsyncThunk(
  'setting/auth/updater',
  async ({ body }, { rejectWithValue, dispatch, getState }) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/auth/update`,
      body
    );

    const data = response.data;

    if (data.status) {
      localStorage.setItem('language', data?.data.language);
      localStorage.setItem('autoLogin', data?.data.auto_login);
    }

    if (!data || !data.status) {
      if (data.error_code === 130) {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Mã OTP không hợp lệ!', settingsError);
        } else {
          toast.error('OTP code has been expired!', settingsError);
        }
      } else {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Đã có lỗi xảy ra, vui lòng thử lại!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      }

      return rejectWithValue(data);
    }

    dispatch(getUserInfo());
    return data;
  }
);

export const postResentVerify = createAsyncThunk(
  'setting/auth/re-sent-verify',
  async ({ body }, { rejectWithValue, getState }) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/auth/resend-verify`,
      body
    );
    const data = response.data;

    if (data.status) {
      if (getState().uiHandler.language === 'vi') {
        toast.success('Gửi mã xác nhận thành công!', settingsSuccess);
      } else {
        toast(data.message, settingsSuccess);
      }
    }

    if (!data || !data.status) {
      if (data.error_code === 154) {
        if (getState().uiHandler.language === 'vi') {
          toast.error(
            'Quá nhiều request được gửi, vui lòng thử lại sau!',
            settingsError
          );
        } else {
          toast.error(data.message, settingsError);
        }
      } else {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Đã có lỗi xảy ra, vui lòng thử lại!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      }

      return rejectWithValue(data);
    }
    return data;
  }
);

export const postCheckMail = createAsyncThunk(
  'setting/auth/check-mail',
  async ({ body }, { rejectWithValue, getState }) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/auth/check-email`,
      body
    );
    const data = response.data;
    if (!data || !data.status) {
      if (data.error_code === 170) {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Địa chỉ Email đã tồn tại!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      } else {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Đã có lỗi xảy ra, vui lòng thử lại!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      }

      return rejectWithValue(data);
    }
    return data;
  }
);

export const postChangePassword = createAsyncThunk(
  'setting/auth/change-password',
  async ({ new_password, old_password }, { rejectWithValue, getState }) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_URL}/api/auth/change-password`,
      { new_password, old_password }
    );
    const data = response.data;

    if (!data || !data.status) {
      if (data.error_code === 195) {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Mật khẩu không chính xác!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      } else {
        if (getState().uiHandler.language === 'vi') {
          toast.error('Đã có lỗi xảy ra, vui lòng thử lại!', settingsError);
        } else {
          toast.error(data.message, settingsError);
        }
      }

      return rejectWithValue(data);
    }
    return data;
  }
);

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    handleStateChangedLanguage: (state, { payload }) => {
      state.isChangedLanguage = true;
    },

    handleResetStateChangedLanguage: (state, { payload }) => {
      state.isChangedLanguage = false;
    },
  },
  extraReducers: {
    [postUpdateUserInfo.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [postUpdateUserInfo.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [postUpdateUserInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },

    [postResentVerify.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [postResentVerify.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [postResentVerify.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },

    [postCheckMail.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [postCheckMail.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [postCheckMail.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },

    [postChangePassword.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [postChangePassword.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [postChangePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
  },
});

export const loadingSelector = (state) => state.setting.loading;
export const errorSelector = (state) => state.setting.error;
export const errorMessageSelector = (state) => state.setting.errorMessage;

export const { handleStateChangedLanguage, handleResetStateChangedLanguage } =
  settingSlice.actions;
export const settingReducer = settingSlice.reducer;

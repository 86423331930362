import React from 'react';

const HotNewsIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6 3.3L5.65733 4.24267L3.96667 2.552V11.3333H2.63333V2.552L0.943333 4.24267L0 3.3L3.3 0L6.6 3.3ZM13.2667 8.7L9.96667 12L6.66667 8.7L7.60933 7.75733L9.30067 9.448L9.3 0.666667H10.6333V9.448L12.324 7.75733L13.2667 8.7Z"
        fill="#3EA4CD"
      />
    </svg>
  );
};

export default HotNewsIcon;

import { Empty } from 'antd';
import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { getPortfolioNewsInit } from 'features/portfolio/portfolioNewsSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostListStyled } from './Modal/styles';

const PostItemContainer = ({ scrollTop, isClickedBtn, setIsClickedBtn }) => {
  const filter = useSelector((state) => state.portfolioNews.sectors);
  const { stockCodes } = useSelector((state) => state.portfolioNews);

  const { posts, pagination, loadingInit } = useSelector(
    (state) => state.portfolioNews
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isClickedBtn) {
      setTimeout(() => {
        setIsClickedBtn(false);
      }, 1000);
    }
  }, [isClickedBtn, setIsClickedBtn]);

  useEffect(() => {
    scrollTop();

    let delayed;
    delayed = setTimeout(() => {
      if (!isClickedBtn) {
        dispatch(
          getPortfolioNewsInit({
            pagination: {
              ...pagination,
              page: 1,
            },
          })
        );
      }
    }, 1000);

    return () => clearTimeout(delayed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, stockCodes]);

  return (
    <>
      {loadingInit && (
        <PostListStyled>
          {new Array(10).fill(null).map((_, index) => (
            <PostItem.Loading key={index} type="full" />
          ))}
        </PostListStyled>
      )}

      {posts.length === 0 ? (
        <div style={{ marginTop: '30vh' }}>
          <Empty />
        </div>
      ) : (
        <>
          {posts?.map((post, index) => (
            <PostItem key={index} data={post} type="full" index={index} />
          ))}
        </>
      )}
    </>
  );
};

export default PostItemContainer;

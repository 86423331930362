import { useQuery, useSubscription } from '@apollo/client';
import { Typography } from 'antd';
import clsx from 'clsx';
import { STOCK_PRICE } from 'graphQL/queries/stockPrice';
import { SUB_STOCK_PRICE } from 'graphQL/subscriptions/stockPrice';
import React, { memo, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  comparePriceClass,
  formatCurrency,
  formatNumberToBillions,
  priceClass,
} from 'utils';
import { TradingOrderContext } from './TradingOrderContext';
import moment from 'moment';
import { EXCHANGES } from 'constants/commons';
import useWebSocket from 'react-use-websocket';
import map from 'lodash/map';

const format = 'HH:mm:ss';
const nineHour = moment('09:00:00', format);
const nineFifteenHour = moment('09:15:00', format);
const twoThirtyHour = moment('14:30:00', format);
const fifteenHour = moment('15:00:00', format);

const StockPriceSubscription = ({ symbol, setData }) => {
  useSubscription(SUB_STOCK_PRICE, {
    variables: {
      symbols: [symbol],
    },
    shouldResubscribe: true,
    onData: ({
      data: {
        data: { stockPrice },
      },
    }) => {
      // Logic to cancel subscription based on the time - XWealth
      if (
        stockPrice.exchange?.toUpperCase() === EXCHANGES.UPCOM &&
        !moment().isBetween(nineHour, fifteenHour)
      )
        return;

      if (
        stockPrice.exchange?.toUpperCase() === EXCHANGES.HNX &&
        !moment().isBetween(nineHour, twoThirtyHour)
      )
        return;

      if (
        stockPrice.exchange?.toUpperCase() === EXCHANGES.HOSE ||
        stockPrice.exchange?.toUpperCase() === EXCHANGES.HSX ||
        stockPrice.exchange?.toUpperCase() === EXCHANGES.HSX30
      ) {
        if (!moment().isBetween(nineFifteenHour, twoThirtyHour)) return;
      }

      setData(stockPrice);
    },
  });
  return null;
};

const StockPriceSubscriptionV2 = memo(
  ({ lastJsonMessage, sendMessage, subscribeParams, symbol, setData }) => {
    const onUnsubscribeSocket = () => {
      if (subscribeParams.current['stock'])
        sendMessage?.({
          action: 'unsubscribe',
          params: subscribeParams.current['stock'],
        });
    };

    useEffect(() => {
      if (lastJsonMessage) {
        setData(
          JSON.stringify({
            symbol: lastJsonMessage?.sym,
            time: lastJsonMessage?.t,
            lastPrice: lastJsonMessage?.p,
            refPrice: lastJsonMessage?.ref,
            exchange: lastJsonMessage?.x,
            ceiling: lastJsonMessage?.cei,
            floor: lastJsonMessage?.flo,
          })
        );
      }
    }, [lastJsonMessage, setData]);

    useEffect(() => {
      if (symbol) {
        // subscribeParams.current['stock'] = JSON.parse(symbol)
        //   ?.map((a) => `T.${a}`)
        //   ?.join(',');
        subscribeParams.current['stock'] = symbol;
        sendMessage?.({
          action: 'subscribe',
          params: subscribeParams.current['stock'],
        });
      } else {
        onUnsubscribeSocket();
      }

      return () => {
        onUnsubscribeSocket();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMessage, subscribeParams, symbol]);

    return null;
  }
);

function TradingPrices() {
  const { symbol, symbolData, setSymbolData } = useContext(TradingOrderContext);
  const { t } = useTranslation(['common']);
  const subscribeParams = useRef({});

  const { loading } = useQuery(STOCK_PRICE, {
    variables: {
      symbol: symbol,
    },
    onCompleted: ({ stockPrice }) => {
      setSymbolData(stockPrice);
    },
  });

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    `${process.env.REACT_APP_MARKET_SOCKET}/v1alpha/market`,
    {
      onOpen: (event) => {
        console.log(event);
        map(subscribeParams.current, (value) => {
          if (value)
            sendJsonMessage({
              action: 'subscribe',
              params: value,
            });
        });
      },
      shouldReconnect: () => true,
    }
  );

  const renderStyle = (key) => {
    switch (key) {
      case 'lastPrice':
      case 'floor':
      case 'ceiling':
      case 'refPrice':
      case 'low':
      case 'high':
        return comparePriceClass(symbolData[key], symbolData);

      default:
        return priceClass(key);
    }
  };

  const renderValue = (key) => {
    switch (key) {
      case 'lastPrice':
      case 'floor':
      case 'ceiling':
      case 'refPrice':
      case 'low':
      case 'high':
        return formatCurrency(symbolData[key] / 1000, 2, false);

      case 'totalVol':
      case 'totalVal':
        return formatNumberToBillions(symbolData[key]);

      default:
        return symbolData[key];
    }
  };

  if (loading)
    return (
      <div className="w-full h-[66px] md:bg-custom-darkBlue800 !rounded-lg" />
    );

  if (!symbolData && !loading)
    return (
      <div className="w-full h-[66px] md:bg-custom-darkBlue800 !rounded-lg flex items-center p-4 text-lg text-custom-gray300">
        Không có dữ liệu
      </div>
    );

  return (
    <div className="w-full h-[66px] px-6 md:px-8 md:bg-custom-darkBlue800 !rounded-lg flex items-center overflow-y-hidden overflow-x-auto gap-3 justify-between">
      {/* <StockPriceSubscription symbol={symbol} setData={setSymbolData} /> */}
      <StockPriceSubscriptionV2
        lastJsonMessage={lastJsonMessage?.ev === 'T' ? lastJsonMessage : null}
        sendMessage={sendJsonMessage}
        subscribeParams={subscribeParams}
        symbol={symbol}
        setData={setSymbolData}
      />
      <div>
        <div
          className={clsx(
            'flex gap-2 items-center',
            comparePriceClass(symbolData.lastPrice, symbolData)
          )}
        >
          <Typography.Text strong className={clsx('text-5xl !text-inherit')}>
            {formatCurrency(symbolData.lastPrice / 1000, 2, false)}
          </Typography.Text>
          <div className="flex flex-col">
            <Typography.Text className="!text-inherit leading-none">
              {symbolData.change / 1000}
            </Typography.Text>
            <Typography.Text className="!text-inherit">
              ({symbolData.ratioChange}%)
            </Typography.Text>
          </div>
        </div>
      </div>
      {Object.keys(symbolData).map((key) => (
        <div
          key={key}
          className={clsx(
            'flex min-w-[80px] grow flex-col items-center',
            key === 'lastPrice' && 'hidden',
            key === 'ratioChange' && 'hidden',
            key === 'change' && 'hidden',
            key === 'exchange' && 'hidden'
          )}
        >
          <Typography.Text className="!text-custom-gray300 whitespace-nowrap leading-none">
            {t(`common.${key}`)}
          </Typography.Text>
          <Typography.Text strong className={clsx('text-lg', renderStyle(key))}>
            {renderValue(key)}
          </Typography.Text>
        </div>
      ))}
    </div>
  );
}

export default TradingPrices;

import { Button, Form, Typography } from 'antd';
import clsx from 'clsx';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import EditPrice from '../EditPrice';
import { useQuery } from '@apollo/client';
import { STOCK_PRICE } from 'graphQL/queries/stockPrice';

const CopierOrderEdit = ({ data, setData, onEdit }) => {
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState(null);
  const [errors, setErrors] = useState(null);

  const onReset = () => {
    setData(null);
    setErrors(null);
    form.resetFields();
  };

  const { refetch } = useQuery(STOCK_PRICE, {
    skip: isEmpty(data),
    variables: {
      symbol: data?.symbol,
    },
    onCompleted: ({ stockPrice }) => {
      setSymbolData(stockPrice);
    },
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const { price, minPrice, maxPrice } = data;

      if (price > 0) {
        setErrors(null);
        price > symbolData?.ceiling / 1000 &&
          setErrors('Giá nhập vượt quá giá trần');
        price < symbolData?.floor / 1000 &&
          setErrors('Giá nhập nhỏ hơn giá sàn');
      } else if (price === 0 && minPrice > 0) {
        setErrors(null);
        maxPrice > symbolData?.ceiling / 1000 &&
          setErrors('Giá nhập vượt quá giá trần');
        minPrice < symbolData?.floor / 1000 &&
          setErrors('Giá nhập nhỏ hơn giá sàn');
        maxPrice < minPrice && setErrors('Giá nhập không hợp lệ');
      } else {
        setErrors(null);
      }
    }
  }, [data, symbolData]);

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  return (
    <StyledModal
      open={!isEmpty(data)}
      destroyOnClose={true}
      maskClosable={true}
      className="!w-[420px]"
      title={<span className="text-xl">{`Sửa lệnh ${data?.symbol}`}</span>}
      footer={null}
      onCancel={() => setData(null)}
      onOk={() => null}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="vertical"
        onFieldsChange={() => {}}
        onFinish={() => onEdit(data)}
      >
        {symbolData && (
          <EditPrice data={data} setData={setData} base={symbolData} />
        )}
        {errors && <Typography.Text type="danger">{errors}</Typography.Text>}
        <div className="flex justify-end py-4 mt-5">
          <Button
            className={clsx(
              '!h-16 !px-16 !rounded-lg ',
              '!text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500'
            )}
            onClick={onReset}
          >
            Từ chối
          </Button>
          <Button
            disabled={!isEmpty(errors)}
            type="primary"
            className={clsx(
              'ml-6 !h-16 !px-16 !rounded-lg border-0',
              '!bg-custom-blue500 border-0 hover:!bg-blue-500'
            )}
            onClick={() => {
              form.submit();
              setData(null);
            }}
          >
            Xác nhận
          </Button>
        </div>
      </Form>
    </StyledModal>
  );
};

export default CopierOrderEdit;

import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled.div`
  background: #1f2336;
  border-radius: 4px;
  padding: 30px 20px;
  margin-top: 12px;
`;

export const Group = styled.div``;

export const GroupTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #8e8e93;
`;

export const GroupList = styled.div``;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 14px;
  padding: 12px 0;
  font-size: 11px;
`;

export const GroupDescription = styled.div`
  display: flex;
  flex-flow: wrap row;
  .line {
    margin: 0 5px;
    font-weight: 200;
  }

  .note {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #f24b78;
    margin-top: 2px;
    font-style: italic;
  }
`;

export const GroupText = styled.div`
  font-weight: 200;
  display: flex;

  .red {
    color: #f24b78;
  }

  .green {
    color: #1ed994;
  }
`;

export const StyledModal = styled(Modal)`
  && {
    width: 400px !important;
    top: 20px;
    padding: 0;

    iframe {
      width: 100%;
      height: 100%;
    }

    .ant-modal-body,
    .ant-modal-content {
      height: calc(100vh - 80px);
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
`;

import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Chart, ChartCanvas } from 'react-stockcharts';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';
import { fitWidth } from 'react-stockcharts/lib/helper';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import {
  AreaSeries,
  BarSeries,
  LineSeries,
} from 'react-stockcharts/lib/series';
import { HoverTooltip } from 'react-stockcharts/lib/tooltip';
import {
  createVerticalLinearGradient,
  hexToRGBA,
  last,
} from 'react-stockcharts/lib/utils';

class AreaChartWithEdge extends React.Component {
  constructor(props) {
    super(props);
    this.saveNode = this.saveNode.bind(this);
    this.resetYDomain = this.resetYDomain.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.chartHeight = props.chartHeight;
    this.marginRight = props.marginRight;
    this.gradientColor1 = props.gradientColor1;
    this.gradientColor2 = props.gradientColor2;
  }

  state = {
    suffix: '',
  };

  componentWillMount() {
    this.setState({
      suffix: 1,
    });
  }
  saveNode(node) {
    this.node = node;
  }
  resetYDomain() {
    this.node.resetYDomain();
  }

  handleReset() {
    this.setState({
      suffix: this.state.suffix + 1,
    });
  }

  render() {
    const {
      type,
      data: initialData,
      width,
      ratio,
      chartHeight,
      marginRight,
      tickStroke,
      gradientColor1,
      gradientColor2,
      lastPrice,
      xAxisTick,
      dateFormatState,
    } = this.props;

    const canvasGradient = createVerticalLinearGradient([
      { stop: 0, color: hexToRGBA(gradientColor1, 1) },
      { stop: 0.1, color: hexToRGBA(gradientColor1, 0.7) },
      { stop: 0.35, color: hexToRGBA(gradientColor1, 0.9) },
      { stop: 0.6, color: hexToRGBA(gradientColor2, 0.8) },
      { stop: 1, color: hexToRGBA(gradientColor2, 0.8) },
    ]);

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      (d) => d.date
    );
    const { data, xScale, xAccessor, displayXAccessor } =
      xScaleProvider(initialData);

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    const numberFormat = format(',.2r');

    const tooltipContent1 = this.props.t(
      'stockDetailsSection.tab1.overview.chart.hoverTooltipText1'
    );

    const tooltipContent2 = this.props.t(
      'stockDetailsSection.tab1.overview.chart.hoverTooltipText2'
    );

    let dateFormat;

    if (dateFormatState === 'days') {
      dateFormat = timeFormat('%Y/%m/%d');
    } else {
      dateFormat = timeFormat('%m/%d %H:%M:%S');
    }

    function tooltipContent(ys) {
      return ({ currentItem, xAccessor }) => {
        return {
          x: dateFormat(xAccessor(currentItem)),
          y: [
            {
              label: tooltipContent1,
              value: currentItem.price && numberFormat(currentItem.price),
            },

            {
              label: tooltipContent2,
              value:
                currentItem.sector_val && numberFormat(currentItem.sector_val),
            },
          ],
        };
      };
    }

    return (
      <ChartCanvas
        height={chartHeight}
        ref={this.saveNode}
        ratio={ratio}
        width={width}
        zoomEvent={false}
        panEvent={false}
        margin={{ left: 40, right: marginRight, top: 20, bottom: 30 }}
        type={type}
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart id={1} yExtents={(d) => [d.price, 0, lastPrice]}>
          <XAxis
            axisAt="bottom"
            orient="bottom"
            tickStroke={tickStroke}
            ticks={window.innerWidth > 600 ? xAxisTick : 4}
          />
          <YAxis
            axisAt="right"
            orient="right"
            ticks={xAxisTick}
            tickStroke={tickStroke}
            tickFormat={format('.2s')}
          />

          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat('%Y-%m-%d')}
          />

          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format('.2s')}
            tickStroke={tickStroke}
          />

          <AreaSeries
            yAccessor={(d) => d.price}
            canvasGradient={canvasGradient}
          />

          <LineSeries
            yAccessor={(d) => [lastPrice]}
            strokeWidth={2}
            stroke="#1ED994"
            strokeDasharray="ShortDot"
          />

          <EdgeIndicator
            itemType="last"
            orient="right"
            edgeAt="right"
            yAccessor={(d) => [lastPrice]}
            displayFormat={format('.2s')}
          />

          {/* <ZoomButtons onReset={this.handleReset} style={{cursor: 'pointer'}} /> */}
        </Chart>

        <Chart
          id={2}
          yExtents={(d) => [d.sector_val]}
          height={chartHeight}
          origin={(w, h) => [0, h - chartHeight]}
        >
          <YAxis
            axisAt="left"
            orient="left"
            tickStroke={tickStroke}
            ticks={5}
            tickFormat={format('.2s')}
          />

          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format('.2s')}
          />

          <BarSeries
            yAccessor={(d) => d.sector_val}
            stroke
            fill="#F2ED49"
            opacity={0.8}
            widthRatio={1}
          />

          <HoverTooltip
            yAccessor=""
            tooltipContent={tooltipContent()}
            fontSize={15}
          />
        </Chart>

        <CrossHairCursor
          stroke={tickStroke === '#ffffff' ? 'white' : 'black'}
        />
      </ChartCanvas>
    );
  }
}

AreaChartWithEdge.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
};

AreaChartWithEdge.defaultProps = {
  type: 'svg',
};
AreaChartWithEdge = fitWidth(AreaChartWithEdge);

export default withTranslation('ratingsScreen')(AreaChartWithEdge);

import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import { Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import * as modal from 'components/GlobalComponents/NotificationModal/NotiModal';
import {
  postResentVerify,
  postUpdateUserInfo,
} from 'features/setting/settingSlice';
import {
  closeAnnouncementSelector,
  handleChangeColorDarkTheme,
  handleChangeColorLightTheme,
} from 'features/uiHandler/uiHandlerSlice';
import { getAllUserInfo } from 'features/user/userSlice';
import i18next from 'i18next';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SettingWrapper } from 'screens/SettingScreen/styles';
import { settingsError, settingsSuccess } from 'utils';
import EditMailModal from './Modals/EditMailModal';
import OTPModal from './Modals/OTPModal';
import { ReactComponent as TickSvg } from 'assets/images/tick.svg';
import {
  ButtonGroup,
  FormItemCustom,
  GeneralContainer,
  PhoneInputStyled,
  StyledGeneralContent,
  StyledGeneralHeader,
} from './styles';
import axiosInstance from 'utils/axiosInstance';
const { Option } = Select;

const General = () => {
  const { REACT_APP_SERVICE_CODE, REACT_APP_AUTH_URL } = process.env;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const userInfo = useSelector(getAllUserInfo);
  const closeAnnouncement = useSelector(closeAnnouncementSelector);

  const [isFormChange, setIsFormChange] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [otpModalVisible, setOTPModalVisible] = useState(false);
  const [cacheBody, setCacheBody] = useState({});
  const [visibleManagerTick, setVisibleManagerTick] = useState(false);
  const { t } = useTranslation(['settingsScreen']);

  const setDefaultValue = useCallback(() => {
    if (!userInfo) return;
    form.setFieldsValue({
      fullname: userInfo.full_name,
      phonenumber: userInfo.phone,
      dob: userInfo.dob && moment(userInfo.dob, 'DD-MM-YYYY')._d,
      email: userInfo.email,
      address: userInfo.address,
      gender: userInfo.gender,
      language: userInfo.language,
      currency: userInfo.currency,
      country_code: userInfo.country_code,
      phone: userInfo.phone,
      manager: userInfo.broker_manager_email,
    });
    setIsFormChange(false);
  }, [userInfo, form]);

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue]);

  const handleOkEditModal = async ({ values }) => {
    try {
      const response = await dispatch(
        postResentVerify({ body: values })
      ).unwrap();

      setCacheBody(values);
      console.log('response: ', response);

      setEditModalVisible(false);
      setOTPModalVisible(true);
    } catch (error) {
      console.log('postResentVerify: ', { error });
    }
  };

  const handleOkOTPModal = async (body) => {
    try {
      console.log('body: ', body);
      const response = await dispatch(postUpdateUserInfo({ body })).unwrap();
      console.log('response: ', response);
      setOTPModalVisible(false);
      modal.success({
        message: `${t('notification.updateEmail.reloginInfo')}`,
        onOk: () => {
          window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
        },
        onCancel: () => {
          window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
        },
      });
    } catch (error) {
      console.log('postUpdateUserInfo: ', { error });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleUpdateTheme = async (value) => {
    try {
      const response = await dispatch(
        postUpdateUserInfo({
          body: {
            theme: value ? 'light' : 'dark',
          },
        })
      ).unwrap();
      console.log('postUpdateUserInfo: ', response);

      localStorage.setItem('theme', response.data.theme);

      if (response.data.theme === 'dark') {
        dispatch(handleChangeColorDarkTheme());
      } else {
        dispatch(handleChangeColorLightTheme());
      }

      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', response.data.theme);
    } catch (error) {
      form.setFieldsValue({
        theme: !value,
      });
      console.log('postUpdateUserInfo: ', { error });
    }
  };

  const handleUpdateUserInfo = async (values) => {
    try {
      values.dob = values.dob && moment(values.dob).format('DD-MM-YYYY');

      const managerResponse = await axiosInstance.post(
        `${process.env.REACT_APP_URL}/api/customer/verify-manager`,
        {
          email: values.manager,
        }
      );
      values.broker_manager_id = managerResponse.data?.data?.broker_manager_id;
      delete values.manager;

      i18next.changeLanguage(values.language);

      const { email, theme, ...body } = values;
      console.log('body: ', body);

      const response = await dispatch(postUpdateUserInfo({ body })).unwrap();
      console.log('postUpdateUserInfo: ', response);

      toast.success(
        `${t('notification.updateUserInfo.success')}`,
        settingsSuccess
      );

      setIsFormChange(false);
    } catch (error) {
      toast.error(`${t('notification.updateUserInfo.error')}`, settingsError);

      console.log('postUpdateUserInfo: ', { error });
    }
  };

  const handleChangeSelect = (value) => {
    console.log('LANGUAGE VALUE', value);
  };

  const handleChangeManagerInput = async (rule, value) => {
    setVisibleManagerTick(false);
    if (value) {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_URL}/api/customer/verify-manager`,
        {
          email: value,
        }
      );
      const valid = response.data?.data?.broker_manager_id ? true : false;
      if (valid) {
        setVisibleManagerTick(true);
      } else {
        throw new Error(t('tab1.form1.field7.error'));
      }
    }
  };

  return (
    <GeneralContainer closeAnnouncement={closeAnnouncement}>
      <SettingWrapper>
        {editModalVisible && (
          <EditMailModal
            visible={editModalVisible}
            onCancel={setEditModalVisible.bind(this, false)}
            onOk={handleOkEditModal}
          />
        )}
        {otpModalVisible && (
          <OTPModal
            onCancel={setOTPModalVisible.bind(this, false)}
            onOk={handleOkOTPModal}
            visible={otpModalVisible}
            body={cacheBody}
          />
        )}
        <StyledGeneralHeader>{t('tab1.subTitle1')}</StyledGeneralHeader>

        <StyledGeneralContent>
          <Form
            onFieldsChange={setIsFormChange.bind(this, true)}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleUpdateUserInfo}
            // autoComplete="off"
          >
            <Form.Item label={t('tab1.form1.field1.label')} name="fullname">
              <Input placeholder={t('tab1.form1.field1.placeholder')} />
            </Form.Item>

            <Form.Item
              getValueProps={(i) =>
                form.getFieldValue('dob') && { value: moment(i) }
              }
              name="dob"
              label={t('tab1.form1.field2.label')}
            >
              <DatePicker
                showToday={false}
                inputReadOnly
                allowClear={false}
                format="DD/MM/YYYY"
                placeholder={t('tab1.form1.field2.placeholder')}
                disabledDate={(current) => {
                  return current && current > moment().endOf('day');
                }}
              />
            </Form.Item>

            <FormItemCustom label={t('tab1.form1.field3.label')}>
              <Form.Item name="country_code">
                <PhoneInput
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    top: -1,
                    left: 0,
                  }}
                  enableSearch
                  disableSearchIcon
                  searchPlaceholder={t(
                    'tab1.form1.field3.phoneInputSelector.searchPlaceholder'
                  )}
                  inputProps={{
                    style: { pointerEvents: 'none' },
                  }}
                  country={'vn'}
                  searchNotFound={t(
                    'tab1.form1.field3.phoneInputSelector.searchNotFound'
                  )}
                />
              </Form.Item>

              <Form.Item
                name="phone"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    message: `${t('tab1.form1.field3.validateHelper1')}`,
                  },
                ]}
              >
                <PhoneInputStyled
                  placeholder={t('tab1.form1.field3.placeholder')}
                />
              </Form.Item>
            </FormItemCustom>

            <Form.Item name="email" label="Email">
              <Input
                placeholder={t('tab1.form1.field4.placeholder')}
                readOnly
                onClick={setEditModalVisible.bind(this, true)}
                suffix={
                  <EditOutlined
                    onClick={setEditModalVisible.bind(this, true)}
                  />
                }
              />
            </Form.Item>

            <Form.Item label={t('tab1.form1.field5.label')} name="address">
              <Input placeholder={t('tab1.form1.field5.placeholder')} />
            </Form.Item>

            <Form.Item name="gender" label={t('tab1.form1.field6.label')}>
              <Select
                suffixIcon={
                  <CaretDownOutlined style={{ color: 'var(--border-color)' }} />
                }
                placeholder={t('tab1.form1.field6.selectPlaceholder')}
              >
                <Option value="male">
                  {t('tab1.form1.field6.selector.option1')}
                </Option>
                <Option value="female">
                  {t('tab1.form1.field6.selector.option2')}
                </Option>
                <Option value="other">
                  {' '}
                  {t('tab1.form1.field6.selector.option3')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t('tab1.form1.field7.label')}
              name="manager"
              rules={[{ validator: handleChangeManagerInput }]}
            >
              <Input placeholder={t('tab1.form1.field7.placeholder')} />
            </Form.Item>
            {visibleManagerTick && (
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <TickSvg />
                </Col>
              </Row>
            )}

            <Divider style={{ margin: '12px 0' }} />

            <StyledGeneralHeader>{t('tab1.subTitle2')}</StyledGeneralHeader>

            <Form.Item label={t('tab1.form2.field1.label')} name="language">
              <Select
                suffixIcon={
                  <CaretDownOutlined style={{ color: 'var(--border-color)' }} />
                }
                onChange={handleChangeSelect}
                placeholder={t('tab1.form2.field1.selectPlaceholder')}
              >
                <Option value="vi">
                  {t('tab1.form2.field1.selector.option1')}
                </Option>
                <Option value="en" disabled>
                  {t('tab1.form2.field1.selector.option2')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item label={t('tab1.form2.field2.label')} name="currency">
              <Select
                suffixIcon={
                  <CaretDownOutlined style={{ color: 'var(--border-color)' }} />
                }
              >
                <Option value="VND">
                  {t('tab1.form2.field2.selector.option1')}
                </Option>
                {/* <Option value="USD">
                  {t("tab1.form2.field2.selector.option2")}
                </Option> */}
              </Select>
            </Form.Item>

            <Divider style={{ margin: '12px 0' }} />

            {/* <Form.Item
              label={
                <label style={{ fontWeight: "bold" }}>
                  {t("tab1.subTitle3")}
                </label>
              }
              valuePropName="checked"
              name="theme"
            >
              <Switch
                onChange={handleUpdateTheme}
                defaultChecked={userInfo?.theme === "dark" ? false : true}
              />
            </Form.Item> */}

            <ButtonGroup>
              {isFormChange && (
                <SecondaryButton
                  size="large"
                  onClick={setDefaultValue}
                  textBtn={t('textBtn2')}
                  large
                />
              )}
              <PrimaryButton
                size="large"
                large
                disabled={!isFormChange}
                htmlType="submit"
                textBtn={t('textBtn1')}
              />
            </ButtonGroup>
          </Form>
        </StyledGeneralContent>
      </SettingWrapper>
    </GeneralContainer>
  );
};

export default General;

import axiosInstance from './axiosInstance';

export const TypeSend = {
  'BSC-OTP': 'BSC',
  BSC: 'BSC',
  CTS: 'CTS',
  VIX: 'VIX',
  DNSE: 'DNSE',
};

export const CredentialType = {
  CREDENTIAL: 'CREDENTIAL',
  OAUTH: 'OAUTH',
};

export const getConnectList = async () => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/leader/stock-account/my-stock-accounts`
    )
    .then((res) => res.data?.data);
};

export const getSubAccounts = async (account) => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/leader/stock-account/${account}/trading-accounts`
    )
    .then((res) => res.data);
};

export const linkLeaderStockAccount = async (data) => {
  return await axiosInstance
    .post(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/leader/link-leader-stock-account`,
      data
    )
    .then((res) => res.data);
};

export const linkStockAccount = async (data) => {
  return await axiosInstance
    .post(
      `${process.env.REACT_APP_STOCK_MANAGEMENT_URL}/stock-account/${
        TypeSend[data.type]
      }/link`,
      data.params
    )
    .then((res) => res.data);
};

export const generateOTPCTS = async (data) => {
  return await axiosInstance
    .post(
      `${process.env.REACT_APP_STOCK_MANAGEMENT_URL}/brokerage/cts/generate-otp-without-password`,
      data
    )
    .then((res) => res.data);
};

export const verifyOTPCTS = async (data) => {
  return await axiosInstance
    .post(
      `${process.env.REACT_APP_STOCK_MANAGEMENT_URL}/brokerage/cts/verify-otp-without-password`,
      data
    )
    .then((res) => res.data);
};

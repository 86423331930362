import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import RecommendationDetails from 'components/RecommendationsScreenComponents/RecommendationDetails';
import RecommendationToolbar from 'components/RecommendationsScreenComponents/RecommendationToolbar';
import TableRecommendations from 'components/RecommendationsScreenComponents/TableRecommendations';
import {
  getRecommendations,
  recommendationSelector,
} from 'features/recommendation/recommendationSlice';
import { Row, Col } from 'antd';
import { Container, Main, Side } from './styles';
import axiosInstance from 'utils/axiosInstance';
import AddEditRecommendation from 'components/RecommendationsScreenComponents/AddEditRecommendation';
import { toast } from 'react-toastify';
import { getWatchlists } from 'features/watchlist/watchlistSlice';
import { formatPrice, settingsError, settingsSuccess } from 'utils';
import { ERROR } from 'constants/code_status';

const RecommendationsBotScreen = () => {
  const dispatch = useDispatch();
  const { recommendations, total, loading } = useSelector(
    recommendationSelector
  );
  const store = useStore();
  const [activeKey, setActiveKey] = useState();
  const [recommendationDetails, setRecommendationDetails] = useState();
  const [showingModal, setShowingModal] = useState(false);
  const [editingRecommendation, setEditingRecommendation] = useState(null);
  const interval = useRef();
  const [savingRecommendation, setSavingRecommendation] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [sendingRecommendation, setSendingRecommendation] = useState(false);

  const handleRowClick = async (record) => {
    setSelectedHistory(null);
    setLoadingDetails(true);
    try {
      const selectedKey = activeKey === record.key ? undefined : record.key;
      setActiveKey(selectedKey);
      if (selectedKey) {
        const profileResponse = await axiosInstance.get(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock/${record.key}?type=${record.type}`
        );
        const ratingResponse = await axiosInstance.post(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/holding-ratio/stock-detail/datx-rating`,
          {
            stock_code: record.symbol,
          }
        );
        const marketSummaryResponse = await axiosInstance.get(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/market/summary`
        );
        if (profileResponse.data?.data) {
          profileResponse.data.data.type = record.type;
        }
        setRecommendationDetails({
          recommendation: profileResponse.data?.data,
          rating: ratingResponse.data?.data?.data_return?.data_rating,
          market: marketSummaryResponse.data.data,
        });
      } else {
        setRecommendationDetails(undefined);
      }
    } catch (e) {
      setRecommendationDetails(undefined);
      toast.error('Lỗi xem chi tiết khuyến nghị!', settingsError);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleSendClick = async (item) => {
    setEditingRecommendation(item);
    setSendingRecommendation(true);
    setShowingModal(true);
  };

  const handleEditClick = (item) => {
    setShowingModal(true);
    setEditingRecommendation(item);
  };

  const handleCloseModal = () => {
    setEditingRecommendation(null);
    setSendingRecommendation(false);
    setShowingModal(false);
  };

  const handleClickAdd = () => {
    setShowingModal(true);
  };

  const handleSaveRecommendation = async (data) => {
    try {
      setSavingRecommendation(true);
      if (data.key) {
        if (sendingRecommendation) {
          await axiosInstance.post(
            `${process.env.REACT_APP_RECOMMENDATION_API_URL}/holding-ratio`,
            {
              symbol: data.symbol,
              state: 'approve',
              type: data.type,
              id: data.key,
              customerset_ids: data.customerSetIds,
              comment: data.comment || '',
            }
          );
          setRecommendationDetails(undefined);
          toast.success('Gửi khuyến nghị thành công!', settingsSuccess);
        } else {
          const response = await axiosInstance.put(
            `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock`,
            {
              id: data.key,
              type: data.type,
              symbol: data.symbol,
              percentageProportion: data.proportion,
              customerset_ids: data.customerSetIds,
              comment: data.comment || '',
            }
          );
          if (!response.data.status) {
            throw new Error(response.data.message_translated);
          }
          toast.success('Cập nhật khuyến nghị thành công!', settingsSuccess);
        }
      } else {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock`,
          {
            type: 'manual',
            symbol: data.symbol,
            callType: data.tradingType,
            price: data.price,
            percentageProportion: data.proportion,
            customerset_ids: data.customerSetIds,
            comment: data.comment || '',
          }
        );
        if (!response.data.status) {
          throw new Error(response.data.message_translated);
        }
        toast.success('Tạo khuyến nghị thành công!', settingsSuccess);
      }
      dispatch(
        getRecommendations({
          page: 1,
          limit: 20,
        })
      );
      handleCloseModal();
      setRecommendationDetails(undefined);
    } catch (e) {
      if (
        e.response?.data?.message ===
        'price_must_be_less_than_the_ceiling_price'
      ) {
        toast.error(
          'Giá khuyến nghị không được cao hơn giá trần',
          settingsError
        );
        return;
      }
      if (
        e.response?.data?.message ===
        'price_must_be_higher_than_the_floor_price'
      ) {
        toast.error(
          'Giá khuyến nghị không được thấp hơn giá sàn',
          settingsError
        );
        return;
      }
      if (Object.keys(ERROR).includes(e.response?.data?.message)) {
        toast.error(ERROR[e.response.data.message], settingsError);
        return;
      }
      if (data.key) {
        if (sendingRecommendation) {
          toast.error('Gửi khuyến nghị không thành công!', settingsError);
        } else {
          toast.error('Cập nhật khuyến nghị không thành công!', settingsError);
        }
      } else {
        toast.error('Tạo khuyến nghị không thành công!', settingsError);
      }
    } finally {
      setSavingRecommendation(false);
    }
  };

  const mapData = () => {
    return recommendations.map((item) => ({
      symbol: item.symbol,
      key: item.currentRecommendationStock?.id,
      type: item.currentRecommendationStock?.type,
      tradingType: item.currentRecommendationStock?.tradingType,
      price: item.currentRecommendationStock?.price,
      proportion: item.currentRecommendationStock?.percentageProportion,
      comment: item.currentRecommendationStock?.comment,
      hold: item.percentageHold,
      updatedAt: moment(item.createdAt).format('HH:mm DD-MM'),
      histories: item.histories?.map((h) => ({
        key: h._id || h.id,
        type: h.type,
        tradingType: h.tradingType,
        symbol: item.symbol,
        price: h.price,
        updatedAt: moment(h.createdAt).format('HH:mm DD-MM'),
        proportion: h.percentageProportion,
        hold: h.percentageHold,
        comment: h.comment,
      })),
      sent: item.isSend,
      color: item.color,
    }));
  };

  const handleChangeFilter = async (params) => {
    dispatch(
      getRecommendations({
        page: 1,
        limit: 20,
        search: params.search,
        sortBy: params.sortBy,
        watchListId: params.watchListId,
        session: params.session,
      })
    );
  };

  const handleHistoryClick = async (history) => {
    setSelectedHistory(history);
    setLoadingDetails(true);
    try {
      const { data: recommendationResponse } = await axiosInstance.get(
        `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock/${history.key}?type=${history.type}`
      );
      const { data: ratingResponse } = await axiosInstance.post(
        `${process.env.REACT_APP_RECOMMENDATION_API_URL}/holding-ratio/stock-detail/datx-rating`,
        {
          stock_code: recommendationResponse.data.symbol,
        }
      );
      if (recommendationResponse.data) {
        recommendationResponse.data.type = history.type;
      }
      setRecommendationDetails({
        recommendation: recommendationResponse.data,
        rating: ratingResponse?.data?.data_return?.data_rating || {},
      });
    } catch (e) {
      setRecommendationDetails(undefined);
      toast.error('Lỗi xem lịch sử khuyến nghị!', settingsError);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleChange = (pagination) => {
    dispatch(
      getRecommendations({
        page: pagination.current,
        limit: pagination.pageSize,
        search: store.getState().recommendation.search,
      })
    );
  };

  useEffect(() => {
    dispatch(getWatchlists());
    dispatch(
      getRecommendations({
        page: 1,
        limit: 20,
        session: 'today',
        watchListId: 'all',
        search: '',
      })
    );
    interval.current = setInterval(() => {
      if (!loading) {
        dispatch(
          getRecommendations({
            polling: true,
            search: store.getState().recommendation.search,
          })
        );
      }
    }, 10000);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [dispatch]);

  return (
    <Container>
      <RecommendationToolbar
        onClickAdd={handleClickAdd}
        onChangeFilter={handleChangeFilter}
      />

      {loadingDetails || recommendationDetails ? (
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={15} xxl={15}>
            <Main>
              <TableRecommendations
                data={mapData()}
                total={total}
                activeKey={activeKey}
                selectedHistory={selectedHistory}
                onRowClick={handleRowClick}
                onSendClick={handleSendClick}
                onEditClick={handleEditClick}
                onHistoryClick={handleHistoryClick}
                onChange={handleChange}
                loading={loading}
              />
            </Main>
          </Col>
          <Col xs={24} lg={9} xxl={9}>
            <Side>
              <RecommendationDetails
                loading={loadingDetails}
                data={recommendationDetails}
                showingHistory={selectedHistory !== null}
                onClickEdit={handleEditClick}
                onClickSend={handleSendClick}
              />
            </Side>
          </Col>
        </Row>
      ) : (
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={24} xxl={24}>
            <Main>
              <TableRecommendations
                loading={loading}
                data={mapData()}
                total={total}
                activeKey={activeKey}
                selectedHistory={selectedHistory}
                onRowClick={handleRowClick}
                onSendClick={handleSendClick}
                onEditClick={handleEditClick}
                onHistoryClick={handleHistoryClick}
                onChange={handleChange}
              />
            </Main>
          </Col>
        </Row>
      )}
      {showingModal && (
        <AddEditRecommendation
          loading={savingRecommendation}
          data={editingRecommendation}
          visible={showingModal}
          sending={sendingRecommendation}
          onCancel={handleCloseModal}
          onOk={handleSaveRecommendation}
        />
      )}
    </Container>
  );
};

export default RecommendationsBotScreen;

import { FullscreenExitOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import DATXLogoIcon from 'assets/icons/DATXLogoIcon';
import ExpandFourAnglesIcon from 'assets/icons/ExpandFourAnglesIcon';

import Notification from 'components/GlobalComponents/SiteHeader/Notification';
import DATXIndexPerformance from 'components/RatingsScreenComponents/DATXIndexPerformance';
import { timeParse } from 'd3-time-format';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SidebarMobile from '../Sidebar/mobile';
import {
  DataContainer,
  SidebarMobileButton,
  SiteHeaderContainer,
  StyledBetaText,
  StyledContainer,
  StyledDATXIndexData,
  StyledLogoImage,
  StyledNotifyIcon,
} from './styles';

const SiteHeader = () => {
  const history = useHistory();
  const { expandSidebar } = useSelector((state) => state.uiHandler);

  /* -------------------------------------------------------------------------- */
  /*                                DISPLAY MODAL                               */
  /* -------------------------------------------------------------------------- */

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 HANDLE DATA                                */
  /* -------------------------------------------------------------------------- */
  const { dataDATXIndex } = useSelector((state) => state.DATXIndex);

  const { t } = useTranslation('ratingsScreen');

  const utc_to_vntime = (_date) => {
    const __date = new Date(_date);
    const _dateValue = __date.valueOf();
    const _vntimeValue = _dateValue + 7 * 60 * 60 * 1000;

    const _vnDatetime = new Date(_vntimeValue);

    return _vnDatetime.toISOString();
  };

  var __portfolioPerformance = [];
  var DATIndex = 0;
  var VNIndex = 0;

  var performanceData = dataDATXIndex;

  const parseDate = timeParse('%Y-%m-%e');

  performanceData?.forEach((_row, _index) => {
    if (_row.Date) {
      var _newRow = {};
      var _date = utc_to_vntime(new Date(_row.Date));
      _newRow.date = parseDate(_date.slice(0, 10));
      // for default portfolio, column name = byRatePortfolio
      // for all other portfolio, column name = portfolio
      _newRow.profit = parseFloat(_row.portfolioReturn);
      _newRow.hsxIndex = parseFloat(_row.allOrdIndexReturn);
      __portfolioPerformance.push(_newRow);
    }
  });

  DATIndex = __portfolioPerformance[__portfolioPerformance.length - 1]?.profit;

  VNIndex = __portfolioPerformance[__portfolioPerformance.length - 1]?.hsxIndex;

  return (
    <SiteHeaderContainer>
      <StyledContainer expandSidebar={expandSidebar}>
        <SidebarMobileButton>
          <SidebarMobile />
        </SidebarMobileButton>

        <StyledLogoImage>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'end' }}
            onClick={() => {
              history.push('/');
            }}
          >
            <DATXLogoIcon />
            <StyledBetaText>AI BROKER</StyledBetaText>
          </div>
        </StyledLogoImage>

        <DataContainer expandSidebar={expandSidebar}>
          <div onClick={showModal}>
            <StyledDATXIndexData>
              <div>
                <Tooltip
                  title={t('helper.toolTip4')}
                  placement="bottom"
                  color="var(--light-blue-color)"
                >
                  <div className="block mr-3">
                    <ExpandFourAnglesIcon />
                  </div>
                </Tooltip>
              </div>

              <div>
                <div>
                  <span>DAT Index: </span>
                  <span
                    style={{ color: 'rgb(70, 169, 222)', fontWeight: '700' }}
                  >
                    {DATIndex}%
                  </span>
                </div>

                <div>&nbsp;/&nbsp;</div>

                <div>
                  <span> VN-Index: </span>
                  <span style={{ color: '#EFD618', fontWeight: '700' }}>
                    {VNIndex}%
                  </span>
                </div>
              </div>
            </StyledDATXIndexData>
          </div>
        </DataContainer>
      </StyledContainer>

      <StyledNotifyIcon>
        <Notification />
      </StyledNotifyIcon>

      <Modal
        centered
        destroyOnClose
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="90rem"
        closeIcon={
          <Tooltip
            title={t('helper.toolTip2')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                lineHeight: '4.4rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleCancel}
            >
              <FullscreenExitOutlined
                style={{
                  fontSize: 20,
                  color: 'var(--border-color)',
                  display: 'flex',
                  alignItems: 'center',
                  height: '4.4rem',
                }}
              />
            </span>
          </Tooltip>
        }
      >
        <DATXIndexPerformance portfolioPerformance={__portfolioPerformance} />
      </Modal>
    </SiteHeaderContainer>
  );
};

export default SiteHeader;

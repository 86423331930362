import { FullscreenExitOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StockDetail from '..';

const GlobalModalStockDetails = ({
  isModalVisible,
  handleOk,
  handleCancel,
}) => {
  const { t } = useTranslation(['ratingsScreen']);

  return (
    <Modal
      centered
      footer={null}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="table-with-stock-detail-modal stock-detail-modal"
      width="120rem"
      zIndex={998}
      closeIcon={
        <Tooltip
          title={t('helper.toolTip2')}
          placement="bottom"
          color="var(--light-blue-color)"
        >
          <span
            style={{
              cursor: 'pointer',
              display: 'flex',
              padding: '1.6rem 1rem',
            }}
            onClick={handleCancel}
          >
            <FullscreenExitOutlined
              style={{ fontSize: 20, color: 'var(--border-color)' }}
            />
          </span>
        </Tooltip>
      }
    >
      <StockDetail />
    </Modal>
  );
};

export default GlobalModalStockDetails;

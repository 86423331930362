import styled from 'styled-components';
import { Row, Table, Form } from 'antd';

export const Filter = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--primary-color);
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 !important;

  .ant-typography {
    font-size: 13px;
    color: #fff;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-selection-item {
    font-size: 13px;
  }

  .ant-form-item-label {
    label {
      font-size: 13px;
      color: #fff;
      font-weight: 600;
    }
  }

  .ant-picker {
    padding: 0 5px;
    width: 100%;
    background: transparent;
    border: none;
    color: #fff;
    border-bottom: 1px solid var(--border-color) !important;
    box-shadow: none !important;

    .ant-picker-input {
      input {
        color: #fff;
        font-size: 13px;
      }
    }

    .ant-picker-clear {
      background: var(--primary-color);
    }

    .anticon {
      color: white;
    }
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-row.active,
  .ant-table-row.active:hover {
    td {
      background: #454d75 !important;
    }
  }

  .ant-table-cell {
    padding: 6px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    vertical-align: middle;
  }

  .ant-typography {
    font-size: 13px;
    color: #fff;
  }

  .ant-table-thead > tr > th {
    color: var(--white-color) !important;
    border-bottom: 1px solid var(--border-color) !important;

    &.no-border {
      border-bottom: none !important;
    }

    &.border-right {
      border-right: 1px solid var(--border-color) !important;
    }
  }

  .ant-table-row-expand-icon-cell {
    padding: 0 !important;
  }

  .buy {
    color: rgb(58, 214, 5);
  }

  .sell {
    color: #ff0000;
  }
  tr.ant-table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td {
    background: transparent;
    padding: 0 !important;
  }

  .ant-spin-blur {
    opacity: 0.2;
  }
`;

export const StyledForm = styled(Form)`
  .ant-select-clear {
    margin-top: -8px !important;

    .anticon {
      vertical-align: 0;
      color: #fff;
    }
  }
`;

export const CenterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleStat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #3ea4cd;
  margin-bottom: 16px;
  h3 {
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }
  div {
    color: #00ff29;
    font-weight: 700;
    font-size: 30px;
  }
`;

import axios from 'axios';
import { infoRelogin } from '../components/GlobalComponents/NotificationModal/NotiModal';

const { REACT_APP_URL } = process.env;

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

let accessToken;
let refreshToken;

if (window.location.href.includes('callback?')) {
  localStorage.setItem('isAuthed', true);

  // eslint-disable-next-line no-unused-vars
  let [url, callbackStr] = window.location.href.split('callback?');

  let [accessToken, refreshToken, time1, time2, language] =
    callbackStr?.split('&');

  // eslint-disable-next-line no-unused-vars
  let [a, accessTokenStr] = accessToken?.split('access_token=');
  // eslint-disable-next-line no-unused-vars
  let [b, refreshTokenStr] = refreshToken?.split('refresh_token=');
  // eslint-disable-next-line no-unused-vars
  let [c, accessTimeStr] = time1?.split('access_expired=');

  // eslint-disable-next-line no-unused-vars
  let [d, expiredTimeStr] = time2?.split('expired_time=');

  // eslint-disable-next-line no-unused-vars
  let [e, languageStr] = language?.split('language=');

  localStorage.setItem('accessToken', accessTokenStr);
  localStorage.setItem('refreshToken', refreshTokenStr);
  localStorage.setItem('expiredTime', expiredTimeStr);
  localStorage.setItem('language', 'vi');
  localStorage.setItem('theme', 'dark');
  localStorage.setItem('expandTablePortfolio', false);
}

const axiosInstance = axios.create({
  baseURL: REACT_APP_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

accessToken = localStorage.getItem('accessToken');
let autoLogin = localStorage.getItem('autoLogin');

console.log('AUTO LOGIN', JSON.parse(autoLogin));

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return err;
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      if (JSON.parse(autoLogin)) {
        refreshToken = localStorage.getItem('refreshToken');
      } else {
        refreshToken = null;
      }

      return new Promise(function (resolve, reject) {
        axios
          .post(`${REACT_APP_URL}/api/auth/relogin`, {
            language: 'vi',
            device: {
              name: '',
              platform: '',
              device_token: '',
            },
            refresh_token: `${refreshToken}`,
          })

          .then(({ data }) => {
            window.localStorage.setItem('accessToken', data.data.access_token);
            window.localStorage.setItem(
              'refreshToken',
              data.data.refresh_token
            );
            axiosInstance.defaults.headers.common['Authorization'] =
              'Bearer ' + data.data.refresh_token;
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + data.data.access_token;
            originalRequest.headers['Authorization'] =
              'Bearer ' + data.data.access_token;
            processQueue(null, data.data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);

            localStorage.removeItem('accessToken');
            // if (localStorage.getItem("language") === "vi") {
            //   infoRelogin(
            //     "Vui lòng đăng nhập lại để tiếp tục sử dụng!",
            //     "Xác nhận"
            //   );
            // } else {
            //   infoRelogin(
            //     "Please re-login to continue using our service!",
            //     "Confirm"
            //   );
            // }

            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import FailureIconInNotiDialog from 'assets/icons/FailureIconInNotiDialog';
import SuccessIconInNotiDialog from 'assets/icons/SuccessIconInNotiDialog';
import { StyledDialogContainer, StyledMessage, StyledMessage2 } from './styles';

const { REACT_APP_SERVICE_CODE, REACT_APP_AUTH_URL } = process.env;

export function infoRelogin(content, textBtn) {
  Modal.info({
    centered() {},
    wrapClassName: 'relogin-modal',
    icon: '',
    width: '550px',
    zIndex: '10000000000000000',
    okText: textBtn,
    closable: false,
    content: (
      <StyledDialogContainer
        style={{ padding: '2rem 0 2rem 0', borderRadius: '4px' }}
      >
        <InfoCircleOutlined
          style={{
            fontSize: '38px',
            color: 'var(--light-blue-color)',
            marginBottom: '1.2rem',
          }}
        />
        <StyledMessage>{content}</StyledMessage>
      </StyledDialogContainer>
    ),
    onOk() {
      window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
    },
  });
}

export function info({ message = '', ...options }) {
  Modal.info({
    closeIcon: <CloseIcon size={24} />,
    zIndex: 2002,
    content: (
      <StyledDialogContainer>
        <SuccessIconInNotiDialog />
        <StyledMessage>{message}</StyledMessage>
      </StyledDialogContainer>
    ),
    ...options,
  });
}

export function success({ message = '', ...options }) {
  Modal.success({
    icon: '',
    centered() {},
    width: '309px',
    zIndex: 2002,
    closable: true,
    closeIcon: <CloseIcon size={24} />,
    content: (
      <StyledDialogContainer>
        <SuccessIconInNotiDialog />
        <StyledMessage
          dangerouslySetInnerHTML={{ __html: message }}
        ></StyledMessage>
      </StyledDialogContainer>
    ),
    okText: 'OK',
    ...options,
  });
}

export function error({
  message = '',
  description = 'Vui lòng thử lại',
  ...options
}) {
  Modal.error({
    icon: '',
    centered() {},
    width: '309px',
    closable: true,
    zIndex: 2002,
    closeIcon: <CloseIcon size={24} />,
    content: (
      <StyledDialogContainer>
        <FailureIconInNotiDialog />
        <StyledMessage>{message}</StyledMessage>
        {description && <StyledMessage2>{description}</StyledMessage2>}
      </StyledDialogContainer>
    ),
    okText: 'Thử lại',
    ...options,
  });
}

export function warning({ message = '', description = '', ...options }) {
  Modal.warning({
    icon: '',
    centered() {},
    width: '309px',
    closable: true,
    closeIcon: <CloseIcon size={24} />,
    content: (
      <StyledDialogContainer>
        <FailureIconInNotiDialog />
        <StyledMessage>{message}</StyledMessage>
        {description && <StyledMessage2>{description}</StyledMessage2>}
      </StyledDialogContainer>
    ),
    okText: 'Đóng',
    ...options,
  });
}

export function confirm({ message = '', ...options }) {
  Modal.confirm({
    icon: '',
    centered() {},
    width: '309px',
    closable: true,
    content: (
      <StyledDialogContainer>
        <FailureIconInNotiDialog />
        <StyledMessage>{message}</StyledMessage>
      </StyledDialogContainer>
    ),
    closeIcon: <CloseIcon size={24} />,
    okText: 'Xác nhận',
    zIndex: 2002,
    cancelText: 'Hủy bỏ',
    ...options,
  });
}

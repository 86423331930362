const PersonIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="person-icon"
    >
      <g clipPath="url(#clip0_271_1577)">
        <path
          d="M11 14.062V20H13V14.062C16.946 14.554 20 17.92 20 22H4C4.00003 20.0514 4.71119 18.1698 6 16.7083C7.28882 15.2467 9.0667 14.3058 11 14.062ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_271_1577">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonIcon;

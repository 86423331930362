import { Button, Form } from 'antd';
import clsx from 'clsx';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { StyledTable } from 'components/GlobalComponents/styled';
import { getIssuedRootOrder } from 'utils/api';
import { DATE_TIME_FORMAT } from 'constants/date';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatCurrency } from 'utils';
import moment from 'moment';
import { OPTIONS } from './OrderFilter';

const OrderDetail = ({ orderId, onClose }) => {
  const [form] = Form.useForm();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getIssuedRootOrder', orderId],
    queryFn: () => getIssuedRootOrder(orderId),
    retry: 3,
    enabled: !!orderId,
  });

  useEffect(() => {
    if (orderId) {
      refetch();
    }
  }, [orderId, refetch]);

  const columns = useMemo(
    () => [
      {
        title: 'Thời gian',
        dataIndex: 'createdAt',
        width: 130,
        render: (data) => moment(data).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Mã',
        className: 'text-center',
        dataIndex: 'code',
        width: 70,
      },
      {
        title: 'Số hiệu lệnh',
        dataIndex: 'id',
        className: 'text-center',
        width: 120,
      },
      {
        title: 'Kiểu lệnh',
        dataIndex: 'orderType',
        className: 'text-center',
        render: (data) =>
          data === 'BUY' ? (
            <span className="text-green">Mua</span>
          ) : (
            <span className="text-red">Bán</span>
          ),
        width: 100,
      },
      {
        title: 'Giá đặt',
        width: 100,
        className: '!text-right',
        render: (value) =>
          value?.orderedPrice ? (
            formatCurrency(value?.orderedPrice)
          ) : (
            <span>
              {formatCurrency(value?.orderedPriceFrom)}
              <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
              {formatCurrency(value?.orderedPriceTo)}
            </span>
          ),
      },
      {
        title: 'KL đặt',
        dataIndex: 'orderedVolume',
        className: '!text-right',
        width: 120,
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'KL khớp',
        dataIndex: 'matchedVolume',
        className: '!text-right',
        width: 120,
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Giá khớp TB',
        dataIndex: 'avgMatchedPrice',
        className: '!text-right',
        width: 120,
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) =>
          OPTIONS.find((item) => item.value === data)?.label || (
            <span className="text-red">Chưa kích hoạt</span>
          ),
        width: 120,
      },
    ],
    []
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
    pagination: false,
  };

  return (
    <StyledModal
      open={!!orderId}
      destroyOnClose={true}
      maskClosable={true}
      className="!w-[620px]"
      title={<span className="text-xl">{`Chi tiết các lệnh phát sinh`}</span>}
      footer={null}
      onCancel={onClose}
      onOk={() => null}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="vertical"
        onFieldsChange={() => {}}
        onFinish={onClose}
      >
        <StyledTable
          {...tableProps}
          loading={isLoading}
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1030,
          }}
        />
        <div className="flex justify-end py-4 mt-5">
          <Button
            type="primary"
            className={clsx(
              'ml-6 !h-16 !px-16 !rounded-lg border-0',
              '!bg-custom-blue500 border-0 hover:!bg-blue-500'
            )}
            onClick={() => onClose()}
          >
            Đóng
          </Button>
        </div>
      </Form>
    </StyledModal>
  );
};

export default OrderDetail;

import ArrowDownTable from 'assets/icons/ArrowDownTable';
import ArrowUpTable from 'assets/icons/ArrowUpTable';
import clsx from 'clsx';
import { useMemo } from 'react';

const TextStatus = ({
  value,
  className,
  children,
  hideIcon = false,
  ...props
}) => {
  const getColor = useMemo(() => {
    if (Number(value)) {
      if (Number(value) > 0) {
        return `text-custom-green500`;
      }
      return `text-custom-red500`;
    } else {
      return `text-custom-yellow500`;
    }
  }, [value]);

  const getIcon = useMemo(() => {
    if (hideIcon || value === 0 || !value) return '';
    if (value < 0) {
      return <ArrowDownTable />;
    } else {
      return <ArrowUpTable />;
    }
  }, [value, hideIcon]);

  return (
    <p
      className={clsx(className, 'flex items-center gap-2 m-1', getColor)}
      {...props}
    >
      {getIcon}
      {value || 0}
      {children}
    </p>
  );
};
export default TextStatus;

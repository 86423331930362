import { Popover, Progress, Table, Modal } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { toast } from 'react-toastify';

import ArrowDownTable from 'assets/icons/ArrowDownTable';
import ArrowUpTable from 'assets/icons/ArrowUpTable';
import GreenTickIcon from 'assets/icons/GreenTickIcon';
import SendIcon from 'assets/icons/SendIcon';

import axiosInstance from 'utils/axiosInstance';
import { formatPrice, settingsError, settingsSuccess } from 'utils';
import {
  Container,
  HistoryCell,
  HistoryArrow,
  HistoryRecord,
  Actions,
  SentButton,
  TableButton,
} from './styles';
import { Call, STATUSES } from 'constants/broker';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';

const TableRecommendations = (props) => {
  const {
    loading,
    data,
    total,
    selectedHistory,
    activeKey,
    onRowClick,
    onHistoryClick,
    onChange,
  } = props;
  const { t } = useTranslation(['recommendationsScreen']);
  const [saveChange, setChange] = useState({
    filters: {},
    pagination: {
      total,
      pageSize: 20,
    },
    sorter: {},
  });

  const handleClickSend = async (event, item) => {
    event.stopPropagation();
    if (item.Status !== STATUSES.CREATED)
      return toast.error(
        'Trạng thái bản ghi không hợp lệ để phê duyệt!',
        settingsError
      );
    Modal.confirm({
      title: 'Vui lòng xác nhận',
      content: `Bạn có chắc chắn phê duyệt khuyến nghị phái sinh ${item.Code} này?`,
      okText: 'Đồng ý',
      cancelText: 'Thoát',
      bodyStyle: { padding: 0 },
      className: 'approve-derivative',
      okButtonProps: {
        type: 'primary',
      },
      async onOk() {
        try {
          const response = await axiosInstance.post(
            `${process.env.REACT_APP_RECOMMENDATION_API_URL}/derivative/engage-recommendation-stock`,
            { Item: item.Item }
          );
          // if (!response.data.status) {
          //   throw new Error(response.data.message_translated);
          // }
          toast.success('Phê duyệt khuyến nghị thành công!', settingsSuccess);
          const { pagination, filters, sorter } = saveChange;
          onChange(pagination, filters, sorter);
        } catch (e) {
          console.log('handleSendClick error', e);
          toast.error('Phê duyệt khuyến nghị không thành công!', settingsError);
        }
      },
    });
  };

  const handleClickRow = (event, record) => {
    onRowClick(record);
  };

  const handleClickHistory = (event, record) => {
    event.stopPropagation();
    if (onHistoryClick) {
      onHistoryClick(record);
    }
  };

  const renderCall = (value) => {
    let text = value;
    let color = '#1ED994';
    if (text === Call.BUY) {
      text = t('derivativeRecommendationTable.buy');
      color = '#1ED994';
    } else if (text === Call.SELL) {
      text = t('derivativeRecommendationTable.sell');
      color = '#F24B78';
    }
    return (
      <div
        style={{
          color,
          fontWeight: '700',
          fontSize: '12px',
          lineHeight: '18px',
        }}
      >
        {text}
      </div>
    );
  };

  const renderStatus = (value) => {
    let text = value;
    let color = '#1ED994';
    switch (text) {
      case STATUSES.APPROVED:
        text = t('derivativeRecommendationTable.approved');
        break;
      case STATUSES.CREATED:
        text = t('derivativeRecommendationTable.created');
        color = '#fff';
        break;
      case STATUSES.ORDERED:
        text = t('derivativeRecommendationTable.ordered');
        color = '#b900ff';
        break;
      default:
        break;
    }
    return (
      <div
        style={{
          color,
          fontWeight: '700',
          fontSize: '12px',
          lineHeight: '18px',
        }}
      >
        {text}
      </div>
    );
  };

  const renderRecord = (record) => {
    return (
      <HistoryRecord>
        <div>
          <span>{record.updatedAt}</span>
          <strong>{renderCall(record.tradingType)}</strong>
        </div>
        <div>
          {t('ratio')}: {record.proportion}%
        </div>
        <div>
          {t('holding')}: {record.hold || 0}%
        </div>
      </HistoryRecord>
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    if (onChange) {
      setChange({ pagination, filters, sorter });
      onChange(pagination, filters, sorter);
    }
  };

  const columns = [
    {
      title: t('derivativeRecommendationTable.col1'),
      key: 'Code',
      dataIndex: 'Code',
      align: 'left',
    },
    {
      title: t('derivativeRecommendationTable.col3'),
      dataIndex: 'Time',
      key: 'Time',
      align: 'left',
      render: (data) => (
        <Popover
          content={
            <HistoryRecord>
              <span>{data}</span>
            </HistoryRecord>
          }
        >
          {moment(data).format('yyyy-MM-DD HH:mm:ss')}
        </Popover>
      ),
    },
    {
      title: t('derivativeRecommendationTable.col4'),
      dataIndex: 'Type',
      key: 'Type',
      align: 'left',
      render: (value) => renderCall(value),
    },
    {
      title: t('derivativeRecommendationTable.col2'),
      dataIndex: 'Price',
      key: 'Price',
      align: 'left',
      render: (value) => formatPrice(value),
    },
    {
      title: t('derivativeRecommendationTable.col5'),
      dataIndex: 'Quantity',
      key: 'Quantity',
      align: 'left',
    },
    {
      title: t('derivativeRecommendationTable.col6'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'left',
      render: (value) => renderStatus(value),
    },
    {
      title: t('derivativeRecommendationTable.col8'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      width: 90,
      render: (text, row) => (
        <Actions
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {!row.sent ? (
            <TableButton onClick={(event) => handleClickSend(event, row)}>
              <SendIcon />
            </TableButton>
          ) : (
            <SentButton>
              <SendIcon />
              <GreenTickIcon />
            </SentButton>
          )}
        </Actions>
      ),
    },
  ];

  return (
    <Container>
      <Table
        loading={{
          spinning: loading,
          indicator: <AbsoluteLoader size="default" />,
        }}
        size="small"
        className="table-global list-sector-table empty-data-table modal-table"
        scroll={{ y: 'calc(100vh - 39rem - 274px)', x: 1000 }}
        columns={columns}
        dataSource={data}
        pagination={{
          total,
          pageSize: 20,
        }}
        onRow={(record) => {
          return {
            onClick: (event) => handleClickRow(event, record),
          };
        }}
        rowClassName={(record) =>
          record.key === activeKey && !selectedHistory ? 'active' : ''
        }
        onChange={handleChange}
      />
    </Container>
  );
};

export default TableRecommendations;

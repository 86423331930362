import { Spin } from 'antd';
import { SpinContainer } from './styles';

const AbsoluteLoader = ({ noBg, z999, size = 'large' }) => {
  return (
    <SpinContainer noBg={noBg} z999={z999}>
      <Spin size={size} />
    </SpinContainer>
  );
};

export default AbsoluteLoader;

/* -------------------------------------------------------------------------- */
/*                          GET DATA ALLOCATION CHART                         */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  dataOnAllocationChartByStock: [],
  dataOnAllocationChartBySector: [],
  dataOnAllocationChartByIndex: [],
  value: 0,
};

const { REACT_APP_URL } = process.env;

export const getDataAllocationByStock = createAsyncThunk(
  'allocationChart/getDataAllocationByStock',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/allocation`,
        {
          portfolio_id: portfolioId,
        }
      );

      const { data } = await response;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAllocationBySector = createAsyncThunk(
  'allocationChart/getDataAllocationBySector',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/sector-allocation`,
        {
          portfolio_id: portfolioId,
        }
      );

      const { data } = await response;
      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAllocationByIndex = createAsyncThunk(
  'allocationChart/getDataAllocationByIndex',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/index-allocation`,
        {
          portfolio_id: portfolioId,
        }
      );

      const { data } = await response;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const allocationChartSlice = createSlice({
  name: 'allocationChart',
  initialState,
  reducers: {
    handleForceUpdate: (state) => {
      state.value = state.value + 1;
    },
  },
  extraReducers: {
    /* ------------------ GET DATA ON ALLOCATION CHART BY STOCK ----------------- */

    [getDataAllocationByStock.pending]: (state) => {
      state.loading = true;
    },
    [getDataAllocationByStock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataOnAllocationChartByStock = payload?.data;
    },
    [getDataAllocationByStock.rejected]: (state) => {
      state.loading = false;
    },

    /* ----------------- GET DATA ON ALLOCATION CHART BY SECTOR ----------------- */

    [getDataAllocationBySector.pending]: (state) => {
      state.loading = true;
    },
    [getDataAllocationBySector.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataOnAllocationChartBySector = payload?.data;
    },
    [getDataAllocationBySector.rejected]: (state) => {
      state.loading = false;
    },

    /* ------------------ GET DATA ON ALLOCATION CHART BY INDEX ----------------- */
    [getDataAllocationByIndex.pending]: (state) => {
      state.loading = true;
    },
    [getDataAllocationByIndex.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataOnAllocationChartByIndex = payload?.data;
    },
    [getDataAllocationByIndex.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getAllDataAllocationChartByStock = (state) =>
  state.allocationChart.dataOnAllocationChartByStock;

export const getAllDataAllocationChartBySector = (state) =>
  state.allocationChart.dataOnAllocationChartBySector;

export const getAllDataAllocationChartByIndex = (state) =>
  state.allocationChart.dataOnAllocationChartByIndex;

export const { handleForceUpdate } = allocationChartSlice.actions;
export const allocationChartReducer = allocationChartSlice.reducer;

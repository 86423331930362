/* -------------------------------------------------------------------------- */
/*                         GET PORTFOLIO SUMMARY SLICE                        */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  portfolioSummary: [],
  portfolioTop: [],
  portfolioPerformance: [],
  isRejected: false,
};

const { REACT_APP_URL } = process.env;

export const getPortfolioSummary = createAsyncThunk(
  'portfolioSummary/getPortfolioSummary',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/summary`,
        {
          portfolio_id: portfolioId,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        console.log('getPortfolioSummary', data.message);
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const portfolioSummarySlice = createSlice({
  name: 'portfolioSummary',
  initialState,
  reducers: {},
  extraReducers: {
    /* ------------------ GET DATA ON ALLOCATION CHART BY STOCK ----------------- */

    [getPortfolioSummary.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioSummary.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioSummary = payload?.data.portfolioSum;
      state.portfolioTop = payload?.data.portfolioTop;
      state.portfolioPerformance = payload?.data.portfolioPerformance;
    },
    [getPortfolioSummary.rejected]: (state) => {
      state.loading = false;
      state.isRejected = true;
    },
  },
});

export const getPortfolioSummarySelector = (state) =>
  state.portfolioSummary.portfolioSummary;

export const PortfolioSummaryReducer = portfolioSummarySlice.reducer;

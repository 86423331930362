import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';
import _orderBy from 'lodash/orderBy';

let controller;

const initialState = {
  loading: false,
  data: [],
};

export const getRecommendationPerformanceReport = createAsyncThunk(
  'recommendationPerformanceReport/get-data',
  async ({ startDate, endDate, polling }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    let url = `${process.env.REACT_APP_RECOMMENDATION_API_URL}/summary-efficiency-member?`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    const response = await axiosInstance.get(url, {
      signal: controller.signal,
    });

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data.broker_members,
      polling: polling,
    };
  }
);

export const slice = createSlice({
  name: 'recommendationPerformanceReport',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
  },
  extraReducers: {
    [getRecommendationPerformanceReport.pending]: (state, action) => {
      if (!action?.meta?.arg?.polling) {
        state.loading = true;
      }
    },
    [getRecommendationPerformanceReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = _orderBy(payload.data, ['full_name'], ['asc']);
    },
    [getRecommendationPerformanceReport.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoading } = slice.actions;

export const recommendationPerformanceReportReducer = slice.reducer;

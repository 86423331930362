import postImageThumbnail from 'assets/images/thumbnail.png';
import { hsxCompanyList } from 'data/hsxCompanyList';

export const listSectors = [
  {
    name: 'Tất cả',
    sector_id: 0,
  },
  {
    name: 'Nông nghiệp, Thực phẩm, Đồ uống',
    sector_id: 1,
  },
  {
    name: 'Ngân hàng',
    sector_id: 2,
  },
  {
    name: 'Hóa chất',
    sector_id: 3,
  },
  {
    name: 'Công thức',
    sector_id: 4,
  },
  {
    name: 'Người tiêu dùng, Bán lẻ',
    sector_id: 5,
  },
  {
    name: 'Năng lượng',
    sector_id: 6,
  },
  {
    name: 'Dịch vụ tài chính',
    sector_id: 7,
  },
  {
    name: 'Chăm sóc sức khỏe',
    sector_id: 8,
  },
  {
    name: 'Công nghệ thông tin',
    sector_id: 10,
  },
  {
    name: 'Dịch vụ bảo hiểm',
    sector_id: 11,
  },
  {
    name: 'Tài nguyên cơ bản',
    sector_id: 12,
  },
  {
    name: 'Bất động sản',
    sector_id: 13,
  },
  {
    name: 'Tiện ích',
    sector_id: 14,
  },
];

export const postFakeData = new Array(500).fill(null).map((_, index) => {
  const randomSector = Math.floor(Math.random() * listSectors.length);
  const randomCode = Math.floor(Math.random() * hsxCompanyList.length);
  const randomPercents = Math.floor(Math.random() * 10) - 5;
  const randomProfit = Math.floor(Math.random() * 100);

  return {
    _id: index,
    code: hsxCompanyList[randomCode].Code,
    title: `${listSectors[randomSector].name} - Cổ phiếu ngành Thép bước vào thời hoàng kim?`,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula praesent est semper velit id. Semper lacus sed duis non pharetra, scelerisque non. Volutpat cras purus at in laoreet tellus elit purus porta. Nisl, sit non sed at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula praesent est semper velit id. Semper lacus sed duis non pharetra, scelerisque non. Volutpat cras purus at in laoreet tellus elit purus porta. Nisl, sit non sed at. ',
    source: 'CafeF',
    date: '30/09/2021 2:59:53',
    profit_Percent: randomPercents,
    current_profit: randomProfit,
    featured_image: postImageThumbnail,
    sector: listSectors[randomSector].sector_id,
    link: '#!',
  };
});

import { Form, Switch } from 'antd';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import {
  loadingSelector,
  postUpdateUserInfo,
} from 'features/setting/settingSlice';
import { getAllUserInfo } from 'features/user/userSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SettingWrapper } from 'screens/SettingScreen/styles';
import {
  SecurityContainer,
  StyledSecurityContent,
  StyledSecurityHeader,
} from './styles';

const Security = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userInfo = useSelector(getAllUserInfo);
  const loading = useSelector(loadingSelector);
  const { t } = useTranslation(['settingsScreen']);

  useEffect(() => {
    if (!userInfo) return;
    form.setFieldsValue({
      auto_login: userInfo.auto_login,
    });

    localStorage.setItem('autoLogin', userInfo.auto_login);
  }, [userInfo, form]);

  const handleAutoLoginChange = async (e) => {
    try {
      const body = { auto_login: e };
      await dispatch(postUpdateUserInfo({ body })).unwrap();
    } catch (error) {
      console.log('postUpdateUserInfo: ', { error });
      form.setFieldsValue({
        auto_login: !e,
      });
    }
  };

  return (
    <SecurityContainer>
      {loading && <Loader />}
      <SettingWrapper>
        <StyledSecurityHeader>{t('tab3.title')}</StyledSecurityHeader>

        <StyledSecurityContent>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            form={form}
          >
            {/* <Form.Item
              label="Tin nhắn văn bản (SMS)"
              name="sms"
              rules={[{ message: `${t("tab3.form1.validateHelper1")}` }]}
            >
              <Input />
            </Form.Item>

            <Divider /> */}

            {/* <Form.Item
              label={
                <label style={{ fontWeight: "bold" }}>{t("tab3.title2")}</label>
              }
              name="pin"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Divider /> */}

            <Form.Item
              label={
                <label style={{ fontWeight: 'bold' }}>{t('tab3.item1')}</label>
              }
              name="auto_login"
              valuePropName="checked"
            >
              <Switch onChange={handleAutoLoginChange} />
            </Form.Item>

            {/* <div style={{ display: "flex", justifyContent: "end" }}>
              <Form.Item>
                <Space size={20}>
                  <SecondaryButton textBtn="Hủy"  />
                  <PrimaryButton textBtn="Lưu thay đổi"  />
                </Space>
              </Form.Item>
            </div> */}
          </Form>
        </StyledSecurityContent>
      </SettingWrapper>
    </SecurityContainer>
  );
};

export default Security;

import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Checkbox } from 'antd';
import { StyledTable } from 'components/GlobalComponents/styled';
import { DATE_TIME_FORMAT } from 'constants/date';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { formatCurrency, settingsError, settingsSuccess } from 'utils';
import {
  cancelOrderNewCopier,
  editOrderNewCopier,
  getTradingNewCopierList,
} from 'utils/api';
import CopierOrderFilter, { OPTIONS } from './OrderFilter';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CopierOrderConfirmation from './OrderConfirmation';
import CopierOrderEdit from './OrderEdit';
import { toast } from 'react-toastify';

export default function CopierOrderList() {
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
  });

  const [confirmation, setConfirmation] = useState({
    type: null,
    data: null,
  });

  const [edit, setEdit] = useState(null);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getTradingNewCopierList', filter, pagination],
    queryFn: () =>
      getTradingNewCopierList({
        ...(filter?.status && { status: filter?.status }),
        ...(filter?.code && { code: filter?.code }),
        page: pagination.current,
        limit: pagination.pageSize,
      }),
  });

  const { mutateAsync: cancelOrder } = useMutation(cancelOrderNewCopier);
  const { mutateAsync: editOrder } = useMutation(editOrderNewCopier);

  const onCancel = async (id) => {
    try {
      await cancelOrder(id);
      toast.success('Hủy lệnh cho Copier mới thành công!', settingsSuccess);
      setConfirmation({ type: null, data: null });
      refetch();
    } catch (error) {
      toast.error('Hủy lệnh cho Copier mới thất bại!', settingsError);
    }
  };

  const onEdit = async (data) => {
    try {
      await editOrder({
        id: data.id,
        data: {
          price: data.price,
          maxPrice: data.maxPrice,
          minPrice: data.minPrice,
        },
      });
      toast.success('Sửa lệnh cho Copier mới thành công!', settingsSuccess);
      setConfirmation({ type: null, data: null });
      refetch();
    } catch (error) {
      toast.error('Sửa lệnh cho Copier mới thất bại!', settingsError);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Số hiệu lệnh',
        dataIndex: 'id',
        className: 'text-center',
        width: 110,
      },
      {
        title: 'Thời gian',
        dataIndex: 'createdAt',
        width: 130,
        render: (data) => moment(data).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Mã',
        className: 'text-center',
        dataIndex: 'symbol',
        width: 70,
      },
      {
        title: 'Loại lệnh',
        dataIndex: 'tradeType',
        className: 'text-center',
        render: (data) =>
          data === 'buy' ? (
            <span className="text-green">Mua</span>
          ) : (
            <span className="text-red">Bán</span>
          ),
        width: 100,
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) =>
          OPTIONS.find((item) => item.value === data)?.label || (
            <span className="text-red">Không xác định</span>
          ),
        width: 120,
      },
      {
        title: 'Giá đặt',
        width: 90,
        className: '!text-right',
        render: (value) =>
          value?.price > 0 ? (
            formatCurrency(value.price)
          ) : (
            <span>
              {formatCurrency(value?.minPrice)}
              <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
              {formatCurrency(value?.maxPrice)}
            </span>
          ),
      },
      {
        title: 'Tỷ trọng',
        dataIndex: 'proportion',
        className: '!text-right',
        width: 100,
        render: (data) => (data * 100).toFixed(2) + '%',
      },
      {
        title: 'Khối lượng',
        dataIndex: 'followerQuantity',
        className: '!text-right',
        width: 150,
        render: (data) => formatCurrency(data),
      },
      // {
      //   title: 'Chia nhỏ',
      //   dataIndex: 'splitOrder',
      //   width: 100,
      //   render: (data) => <Checkbox disabled checked={data} />,
      // },
      {
        title: 'Giá khớp TB',
        dataIndex: 'followerMatchedAvgPrice',
        width: 120,
        render: (data) => formatCurrency(data),
      },
      {
        title: 'Hủy/Sửa lệnh',
        width: 120,
        render: (value) => (
          <div className={clsx(value.status === 'processing' && '!flex gap-2')}>
            <Button
              onClick={() => setConfirmation({ type: 'CANCEL', data: value })}
              type="text"
              disabled={value.status !== 'processing'}
              className="mr-2"
              icon={
                <DeleteIcon
                  className={clsx(
                    '!w-9 !h-9 ',
                    value.status === 'processing'
                      ? '!text-red'
                      : '!text-custom-darkBlue400'
                  )}
                />
              }
            />
            <Button
              disabled={value.status !== 'processing'}
              onClick={() => setEdit(value)}
              type="text"
              className=""
              icon={
                <EditIcon
                  className={clsx(
                    '!w-9 !h-9 ',
                    value.status === 'processing'
                      ? '!text-white'
                      : '!text-custom-darkBlue400'
                  )}
                />
              }
            />
          </div>
        ),
        fixed: 'right',
      },
    ],
    [setConfirmation]
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };

  return (
    <div>
      <CopierOrderFilter
        onFilter={(values) => setFilter(values)}
        filter={filter}
      />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        pagination={{
          total: data?.totalDocs || 0,
          current: data?.page || 1,
          pageSize: data?.limit || 10,
          position: ['bottomCenter'],
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
        columns={columns}
        dataSource={data?.data}
        scroll={{
          y: 'calc(100vh - 460px)',
          x: 780,
        }}
        rowClassName={(record) =>
          record.status === 'cancelled' ? 'text-custom-darkBlue50' : ''
        }
      />
      <CopierOrderEdit
        data={edit}
        setData={setEdit}
        onEdit={(values) => setConfirmation({ type: 'EDIT', data: values })}
      />
      <CopierOrderConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        onConfirm={(confirmation) => {
          console.log('confirmation :', confirmation);
          confirmation.type === 'CANCEL'
            ? onCancel(confirmation.data.id)
            : onEdit(confirmation.data);
        }}
      />
    </div>
  );
}

import styled from 'styled-components';
import { Form, Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: #363953;
    border-radius: 18px;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    padding: 0;
    margin-bottom: -3px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control-input-content {
    flex-direction: column;
  }

  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #f24b78 !important;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    padding: 4px 0;
    color: #f24b78;
  }

  .ant-input,
  .ant-select-selector,
  .ant-input-affix-wrapper {
    border: 1px solid #363953 !important;
    border-radius: 8px;
  }
`;

export const Container = styled.div`
  label {
    color: white;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .ant-row {
    padding: 10px 0;
  }
  .ant-input-group-addon {
    background: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid var(--border-color) !important;
  }
  .ant-form-item-explain {
    font-size: 12px;
    padding: 4px 0;
  }

  .label-wrapper {
    margin-bottom: -5px;
  }

  .ant-input,
  .ant-select-selection-item {
    font-weight: 700;
  }
  .ant-select-disabled {
    background: rgb(0 0 0 / 30%);
  }
  .ant-input-disabled {
    background: rgb(0 0 0 / 30%) !important;
  }

  .reference-price {
    color: rgb(255, 204, 0) !important;
  }
  .ceiling-price {
    color: rgb(185 0 255) !important;
  }
  .floor-price {
    color: #03d7ff !important;
  }
`;

export const Heading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 30px;
`;

export const Actions = styled.div`
  text-align: right;
  width: 100%;
  button {
    height: 44px;
    margin-right: 16px;
  }
  button:last-of-type {
    margin-right: 0;
  }
`;

export const PreviewSection = styled.div``;

export const PreviewHeading = styled.div`
  display: flex;
  align-items: center;
  span {
    font-style: italic;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 10px;
  }
  height: 34px;
`;

export const PreviewContent = styled.div`
  background: #ffffff;
  border: 1px solid #b5cadd;
  border-radius: 10px;
  padding: 12px;
  margin: 0;
  width: 300px;
  color: #000000;
  min-height: 20vh;
  p {
    margin: 0;
  }
`;

import { Form, Input, Modal, Space } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import { postCheckMail } from 'features/setting/settingSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EditModalContainer } from './styles';

const EditEmailModal = ({ visible, onCancel, onOk }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation(['settingsScreen']);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(postCheckMail({ body: values })).unwrap();
      onOk({ values });
    } catch (err) {
      if (err.status === false) {
        form.setFields([
          {
            name: 'email',
            // errors: [err.message],
          },
        ]);
      }
      console.log({ err });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      centered
      maskClosable={false}
      width="56.8rem"
      visible={visible}
      onCancel={handleCancel}
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
      footer={
        <Space>
          <SecondaryButton
            size="large"
            onHandleClick={handleCancel}
            textBtn={t('tab1.form1.field4.modal.textBtn1')}
            large
          />
          <PrimaryButton
            size="large"
            onHandleClick={handleOk}
            textBtn={t('tab1.form1.field4.modal.textBtn2')}
            large
          />
        </Space>
      }
    >
      <EditModalContainer>
        <Form
          form={form}
          autoComplete="off"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: `${t('tab1.form1.field4.modal.validateHelper1')}`,
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: `${t('tab1.form1.field4.modal.validateHelper2')}`,
              },
            ]}
          >
            <Input name="email" />
          </Form.Item>
        </Form>
      </EditModalContainer>
    </Modal>
  );
};

export default EditEmailModal;

const SearchIcon = ({ color = 'var(--border-color)', size = 20 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default SearchIcon;

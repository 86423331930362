import React from 'react';

const NotifyIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6.50248 5.97519C6.78492 3.15083 9.16156 1 12 1V1C14.8384 1 17.2151 3.15083 17.4975 5.97519L17.7841 8.84133C17.8016 9.01562 17.8103 9.10277 17.8207 9.18855C17.9649 10.3717 18.3717 11.5077 19.0113 12.5135C19.0576 12.5865 19.1062 12.6593 19.2034 12.8051L20.0645 14.0968C20.8508 15.2763 21.244 15.866 21.0715 16.3412C21.0388 16.4311 20.9935 16.5158 20.9368 16.5928C20.6371 17 19.9283 17 18.5108 17H5.48923C4.07168 17 3.36291 17 3.06318 16.5928C3.00651 16.5158 2.96117 16.4311 2.92854 16.3412C2.75601 15.866 3.14916 15.2763 3.93548 14.0968L4.79661 12.8051C4.89378 12.6593 4.94236 12.5865 4.98873 12.5135C5.62832 11.5077 6.03508 10.3717 6.17927 9.18855C6.18972 9.10277 6.19844 9.01562 6.21587 8.84133L6.50248 5.97519Z"
          fill="var(--white-color)"
        />
        <path
          d="M10.0681 20.6294C10.1821 20.7357 10.4332 20.8297 10.7825 20.8967C11.1318 20.9637 11.5597 21 12 21C12.4403 21 12.8682 20.9637 13.2175 20.8967C13.5668 20.8297 13.8179 20.7357 13.9319 20.6294"
          stroke="var(--white-color)"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default NotifyIcon;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

let controller;

export const defaultFilter = {
  sector: [],
  stockCodeList: [],
};

export const defaultPagination = {
  page: 1,
  limit: 10,
  isNext: true,
  isFirstMounted: true,
};

const initialState = {
  loading: false,
  posts: [],
  postsOnMainScreen: [],
  pagination: defaultPagination,
  filter: defaultFilter,
  selectedSector: '',
  sectors: [],
  stockCodes: [],
  isLoadingSkeleton: false,
};

const { REACT_APP_URL } = process.env;

export const getPortfolioNewsInit = createAsyncThunk(
  'portfolioNews/newsInit',
  async (
    { thunkAPI, pagination = defaultPagination },
    { rejectWithValue, getState, dispatch }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getPortfolioNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/portfolio/news`,
      {
        portfolio_id: getState().portfolioList.portfolioId,
        ...pagination,
        sector: getState().portfolioNews.sectors,
        stock_code: getState().portfolioNews.stockCodes,
        language: getState().uiHandler.language,
      },
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('getPortfolioNews');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const getPortfolioNews = createAsyncThunk(
  'portfolioNews/news',
  async (
    { thunkAPI, pagination = defaultPagination },
    { rejectWithValue, getState, dispatch }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getPortfolioNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/portfolio/news`,
      {
        portfolio_id: getState().portfolioList.portfolioId,
        ...pagination,
        sector: getState().portfolioNews.sectors,
        stock_code: getState().portfolioNews.stockCodes,
        language: getState().uiHandler.language,
      },
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('getPortfolioNews');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data,
      // filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const getPortfolioNewsFake = createAsyncThunk(
  'portfolio/news_fake',
  async (
    {
      portfolio_id,
      filter = defaultFilter,
      pagination = defaultPagination,
      sector,
    },
    { rejectWithValue, getState }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getPortfolioNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/portfolio/news`,
      {
        portfolio_id: getState().portfolioList.portfolioId,
        ...pagination,
        sector: filter.sector,
        stock_code: filter.stockCodeList,
        language: getState().uiHandler.language,
      },
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('getPortfolioNews');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const portfolioNewsSlice = createSlice({
  name: 'portfolioNews',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearPosts: (state) => {
      state.posts = [];
      state.pagination = defaultPagination;
    },
    reset: (state) => {
      state.loading = true;
      state.posts = [];
      state.filter = defaultFilter;
      state.pagination = defaultPagination;
      state.sectors = [];
    },

    handleSelectFilterPortfolioNews: (state, { payload }) => {
      state.sectors = payload.sectors;
      state.stockCodes = payload.stockCodes;
    },

    handleSelectStockCode: (state, { payload }) => {
      state.sectors = payload;
    },

    handleSkeletonPortfolioNews: (state, { payload }) => {
      state.isFirstTimeFetchPortfolioNews = true;
    },
  },
  extraReducers: {
    // ------------------- getPortfolioNews ------------------- //

    [getPortfolioNewsInit.pending]: (state) => {
      state.loadingInit = true;
      // state.isLoadingSkeleton = true;
    },
    [getPortfolioNewsInit.fulfilled]: (state, { payload }) => {
      state.loadingInit = false;
      state.posts = payload.data;
      state.pagination = payload.pagination;
      state.filter = payload.filter;
      // state.isLoadingSkeleton = false;
      state.isFirstTimeFetchPortfolioNews = false;
    },
    [getPortfolioNewsInit.rejected]: (state) => {
      state.loadingInit = false;
      // state.isLoadingSkeleton = false;
      state.isFirstTimeFetchPortfolioNews = false;
    },

    [getPortfolioNews.pending]: (state) => {
      state.loading = true;
      // state.isLoadingSkeleton = true;
    },
    [getPortfolioNews.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.postsOnMainScreen = payload.data;
      state.posts = [...state.posts, ...payload.data];
      state.pagination = payload.pagination;
      state.filter = payload.filter;
      // state.isLoadingSkeleton = false;
      state.isFirstTimeFetchPortfolioNews = false;
    },
    [getPortfolioNews.rejected]: (state) => {
      state.loading = false;
      state.isLoadingSkeleton = false;
      state.isFirstTimeFetchPortfolioNews = false;
    },

    [getPortfolioNewsFake.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioNewsFake.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.posts = [...state.posts, ...payload.data];
      state.pagination = payload.pagination;
    },
    [getPortfolioNewsFake.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearPosts,
  setLoading,
  reset,
  handleSelectFilterPortfolioNews,
  handleSkeletonPortfolioNews,
  handleSelectStockCode,
} = portfolioNewsSlice.actions;

export default portfolioNewsSlice.reducer;

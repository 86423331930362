const TelegramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      space="preserve"
      viewBox="0 0 512 512"
    >
      <circle
        cx="255.997"
        cy="256"
        r="255.997"
        fill="#41b4e6"
        data-original="#41b4e6"
      />
      <path
        fill="#0091c8"
        d="M512 256.003c0-6.238-.235-12.419-.673-18.546L405.228 131.36 106.772 248.759l114.191 114.192 1.498 5.392 1.939-1.955.008.008-1.947 1.947L348.778 494.66C444.298 457.5 512 364.663 512 256.003z"
        data-original="#0091c8"
      />
      <path
        fill="#fff"
        d="m231.138 293.3 115.691 87.347 58.399-249.287-298.457 117.399 90.817 30.081 165.743-111.176z"
        data-original="#ffffff"
      />
      <path
        fill="#d2d2d7"
        d="m197.588 278.84 24.873 89.504 8.677-75.044 132.193-125.636z"
        data-original="#d2d2d7"
      />
      <path
        fill="#b9b9be"
        d="m268.738 321.688-46.277 46.656 8.677-75.044z"
        data-original="#b9b9be"
      />
    </svg>
  );
};

export default TelegramIcon;

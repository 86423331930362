import Select from 'components/GlobalComponents/Select';
import React, { useContext } from 'react';
import { TradingOrderContext } from '../TradingOrderContext';

export default function LeaderStockList() {
  const { symbol, setSymbol, stocks } = useContext(TradingOrderContext);

  if (!stocks || !symbol) return null;

  return (
    <Select
      title="Chọn mã cổ phiếu"
      active={symbol}
      setActive={setSymbol}
      options={stocks?.map((item) => ({
        key: item.ticker,
        label: item.ticker,
      }))}
    />
  );
}

import { Skeleton } from 'antd';
import styled from 'styled-components';

export const PortfolioSearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.5rem;
  background: var(--section-header-color);
  border-radius: 4px 4px 0 0;
  height: 4.4rem;

  @media only screen and (max-width: 30em) {
    background: var(--section-header-color);
  }

  & > div {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    .ant-dropdown-trigger.ant-dropdown-link {
      display: flex;
      align-items: center;
    }
  }

  .ant-input-search-button {
    display: none;
  }

  .ant-input-group-addon {
    display: none;
  }

  .ant-input {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--white-color);
  }

  .ant-input-search .ant-input:focus,
  .ant-input-search .ant-input:hover {
    border-color: transparent;
  }

  .ant-input-focused,
  .ant-input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px transparent;
    border-right-width: 0 !important;
    outline: 0;
  }
`;

export const WatchlistDrawerContainer = styled.div`
  display: none;

  @media screen and (max-width: 56.25em) {
    display: block;

    .watchlist {
      display: block !important;

      & > div:nth-child(2) {
        height: calc(100% - 4.4rem) !important;
      }
    }

    .ant-drawer-body {
      padding: 0 !important;
    }
  }
`;

export const WatchlistWrapperStyled = styled.div`
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 56.25em) {
    overflow: unset;
  }
`;

export const WatchlistContainerStyled = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  height: 100%;
  position: relative;

  @media screen and (max-width: 56.25em) {
    display: none;
  }

  @media screen and (min-width: 56.25em) and(max-width: 100em) {
    height: 75vh !important;
  }
`;

export const WatchlistHeaderStyled = styled.div`
  padding: 1.2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--section-header-color);
  height: 4.4rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  svg {
    fill: var(--border-color);
  }
`;

export const SearchInputStyled = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  caret-color: var(--light-blue-color-2);
  width: 80%;
`;

export const WatchlistBodyStyled = styled.div`
  height: calc(100% - 4.4rem);
  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 100em) {
    height: calc(75vh - 4.4rem) !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
  }
`;

export const WatchlistItemStyled = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-color);
  height: 70px;
  cursor: pointer;
  background: ${(props) => (props.active ? 'var(--section-header-color)' : '')};
`;

export const WatchlistItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WatchlistNameStyled = styled.div`
  font-weight: bold;
  margin-bottom: 0.6rem;
  max-width: 20rem;
  font-size: 13px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) =>
    props.active ? 'var(--light-blue-color-2);' : 'var(--white-color)'};
`;

export const GradeIndexStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const StatusColorStyled = styled.div`
  position: relative;
  color: ${(props) =>
    props.status > 0
      ? 'var(--light-green-color)'
      : props.status < 0
      ? 'var(--light-red-color)'
      : 'var(--dark-yellow-color)'};
`;

export const ThreeDotsButton = styled.button`
  border-radius: 100%;
  margin-right: -4px;
`;

export const WatchlistModalContainer = styled.div`
  padding: 20px 40px 0 40px;
  max-width: 100%;

  .ant-form-item-label {
    text-align: left;
    label {
      color: var(--white-color);
    }
  }

  .ant-select-multiple .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-bottom: none !important;
  }

  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const SkeletonStyled = styled(Skeleton)`
  .ant-skeleton-content ul li {
    height: 7rem;
    margin: 1rem;
    width: calc(100% - 2rem) !important;
  }
`;

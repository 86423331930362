import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

let activeSocket, timedOut;

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: String(process.env.REACT_APP_SOCKET_GRAPHQL_URL),
          keepAlive: 5000,
          retryAttempts: Infinity,
          shouldRetry: () => true,
          on: {
            closed: (socket) => {
              console.log('onClose', socket);
            },
            ping: (received) => {
              if (!received)
                timedOut = setTimeout(() => {
                  if (activeSocket?.readyState === WebSocket?.OPEN)
                    activeSocket?.close(4408, 'Request Timeout');
                }, 3000);
            },
            pong: (received) => {
              if (received) clearTimeout(timedOut);
            },
          },
        })
      )
    : null;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const link =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const def = getMainDefinition(query);
          return (
            def.kind === 'OperationDefinition' &&
            def.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

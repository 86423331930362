import { Menu, Typography } from 'antd';
import FailureIcon from 'assets/icons/FailureIcon';
import MaintenanceIcon from 'assets/icons/MaintenanceIcon';
import SuccessIcon from 'assets/icons/SuccessIcon';
import ErrorImage from 'assets/images/notify-error-icon.png';
import { useState } from 'react';
import {
  NotifyContainer,
  StyledNotifyContent,
  StyledNotifyTime,
  StyledNotifyTitle,
} from './styles';

const { Paragraph } = Typography;

const icons = {
  info: <MaintenanceIcon />,
  success: <SuccessIcon />,
  error: <FailureIcon />,
  warning: <img style={{ width: 20, height: 20 }} src={ErrorImage} alt="" />,
};

const NotifyItem = ({ data, onClick }) => {
  const [ellipsis, setEllipsis] = useState(true);

  if (!data) return null;

  const { is_mark, title, messages, time_ago, type } = data;

  const handleClick = () => {
    setEllipsis(!ellipsis);
    onClick();
  };

  const bg = !is_mark
    ? type === 'error' || type === 'warning'
      ? 'rgba(242, 112, 73, 0.08)'
      : 'rgba(73, 194, 242, 0.08)'
    : 'transparent';

  return (
    <Menu.Item onClick={handleClick} style={{ backgroundColor: bg }}>
      <NotifyContainer>
        <div>{icons[type]}</div>

        <StyledNotifyContent>
          <StyledNotifyTitle>
            <div>{title}</div>
          </StyledNotifyTitle>
          <Paragraph
            ellipsis={
              ellipsis && {
                rows: 3,
                expandable: true,
                symbol: 'Xem thêm',
              }
            }
          >
            {messages}
          </Paragraph>
          <StyledNotifyTime>{time_ago}</StyledNotifyTime>
        </StyledNotifyContent>
      </NotifyContainer>
    </Menu.Item>
  );
};

export default NotifyItem;

/* -------------------------------------------------------------------------- */
/*                         GET PORTFOLIO EXCHANGE FUND                        */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  portfolioExchangeFund: [],
};

const { REACT_APP_URL } = process.env;

export const getPortfolioExchangeFund = createAsyncThunk(
  'portfolioExchangeFund/getPortfolioExchangeFund',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/exchange-fund/list`,
        {
          portfolio_id: portfolioId,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const portfolioExchangeFundSlice = createSlice({
  name: 'getPortfolioExchangeFund',
  initialState,
  reducers: {},
  extraReducers: {
    /* ------------------ GET DATA ON ALLOCATION CHART BY STOCK ----------------- */

    [getPortfolioExchangeFund.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioExchangeFund.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioExchangeFund = payload?.data;
    },
    [getPortfolioExchangeFund.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getPortfolioExchangeFundSelector = (state) =>
  state.portfolioExchangeFund.portfolioExchangeFund;

export const portfolioExchangeFundReducer = portfolioExchangeFundSlice.reducer;

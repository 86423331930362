import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import TableRecommendations from './TableRecommendations';
import {
  getRecommendations,
  recommendationSelector,
} from 'features/recommendation/derivativeRecommendationSlice';
import { Row, Col, Form, DatePicker } from 'antd';
import { Container, Main, Side } from './styles';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';
import { getWatchlists } from 'features/watchlist/watchlistSlice';
import { settingsError } from 'utils';

const DerivativeRecommendationsScreen = () => {
  const dispatch = useDispatch();
  const { derivativeRecommendations, total, loading } = useSelector(
    recommendationSelector
  );
  const store = useStore();
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState();
  const [filter, setFilter] = useState({
    startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const [recommendationDetails, setRecommendationDetails] = useState();
  const interval = useRef();
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const execFilterDate = (values) => {
    console.log('execFilter', values);
    if (values.startDate && values.endDate) {
      if (values.startDate.isAfter(values.endDate))
        return toast.error('Thiết lập thời gian không đúng!', settingsError);
    }
    setFilter({
      startDate: values.startDate
        ? values.startDate.format('YYYY-MM-DD HH:mm:ss')
        : values.startDate,
      endDate: values.endDate
        ? values.endDate.format('YYYY-MM-DD HH:mm:ss')
        : values.endDate,
    });
  };

  const handleRowClick = async (record) => {
    console.log('handleRowClick', record);
  };

  const mapData = () => {
    return derivativeRecommendations.map((item) => ({
      symbol: item.symbol,
      key: item.currentRecommendationStock?.id,
      type: item.currentRecommendationStock?.type,
      tradingType: item.currentRecommendationStock?.tradingType,
      price: item.currentRecommendationStock?.price,
      proportion: item.currentRecommendationStock?.percentageProportion,
      comment: item.currentRecommendationStock?.comment,
      hold: item.percentageHold,
      updatedAt: moment(item.createdAt).format('HH:mm DD-MM'),
      histories: item.histories?.map((h) => ({
        key: h._id || h.id,
        type: h.type,
        tradingType: h.tradingType,
        symbol: item.symbol,
        price: h.price,
        updatedAt: moment(h.createdAt).format('HH:mm DD-MM'),
        proportion: h.percentageProportion,
        hold: h.percentageHold,
        comment: h.comment,
      })),
      sent: item.isSend,
      color: item.color,
    }));
  };

  const handleHistoryClick = async (history) => {
    setSelectedHistory(history);
    setLoadingDetails(true);
    try {
      const { data: recommendationResponse } = await axiosInstance.get(
        `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock/${history.key}?type=${history.type}`
      );
      const { data: ratingResponse } = await axiosInstance.post(
        `${process.env.REACT_APP_RECOMMENDATION_API_URL}/holding-ratio/stock-detail/datx-rating`,
        {
          stock_code: recommendationResponse.data.symbol,
        }
      );
      if (recommendationResponse.data) {
        recommendationResponse.data.type = history.type;
      }
      setRecommendationDetails({
        recommendation: recommendationResponse.data,
        rating: ratingResponse?.data?.data_return?.data_rating || {},
      });
    } catch (e) {
      setRecommendationDetails(undefined);
      toast.error('Lỗi xem lịch sử khuyến nghị!', settingsError);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleChange = (pagination) => {
    dispatch(
      getRecommendations({
        page: pagination.current,
        limit: pagination.pageSize,
        search: store.getState().recommendation.search,
        ...filter,
      })
    );
  };

  useEffect(() => {
    dispatch(getWatchlists());
    dispatch(
      getRecommendations({
        page: 1,
        limit: 20,
        session: 'today',
        watchListId: 'all',
        search: '',
        ...filter,
      })
    );
    interval.current = setInterval(() => {
      if (!loading) {
        dispatch(
          getRecommendations({
            polling: true,
            search: store.getState().recommendation.search,
            ...filter,
          })
        );
      }
    }, 10000);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [dispatch, filter]);

  return (
    <Container>
      <Row align="middle" gutter={[20, 20]}>
        <Col xs={24} lg={24} xxl={24}>
          <Form
            form={form}
            layout="horizontal"
            // initialValues={{ startDate: moment().startOf('day'), endDate: moment().endOf('day') }}
          >
            <Row>
              <Col style={{ width: 230, marginRight: 10 }}>
                <Form.Item label="Từ">
                  <DatePicker
                    format="DD-MM-YYYY HH:mm:ss"
                    showTime={true}
                    onChange={(date, string) =>
                      execFilterDate({
                        endDate: filter.endDate
                          ? moment(filter.endDate, 'YYYY-MM-DD HH:mm:ss')
                          : null,
                        startDate: string,
                      })
                    }
                    value={
                      filter.startDate
                        ? moment(filter.startDate, 'YYYY-MM-DD HH:mm:ss')
                        : ''
                    }
                    onSelect={(date) =>
                      execFilterDate({
                        endDate: filter.endDate
                          ? moment(filter.endDate, 'YYYY-MM-DD HH:mm:ss')
                          : null,
                        startDate: date,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col style={{ width: 230 }}>
                <Form.Item label="Đến">
                  <DatePicker
                    format="DD-MM-YYYY HH:mm:ss"
                    showTime={true}
                    onChange={(date, string) =>
                      execFilterDate({
                        startDate: filter.startDate
                          ? moment(filter.startDate, 'YYYY-MM-DD HH:mm:ss')
                          : null,
                        endDate: string,
                      })
                    }
                    value={
                      filter.endDate
                        ? moment(filter.endDate, 'YYYY-MM-DD HH:mm:ss')
                        : ''
                    }
                    onSelect={(date) =>
                      execFilterDate({
                        startDate: filter.startDate
                          ? moment(filter.startDate, 'YYYY-MM-DD HH:mm:ss')
                          : null,
                        endDate: date,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={24} xxl={24}>
          <Main>
            <TableRecommendations
              loading={loading}
              data={derivativeRecommendations}
              total={total}
              activeKey={activeKey}
              selectedHistory={selectedHistory}
              onRowClick={handleRowClick}
              onHistoryClick={handleHistoryClick}
              onChange={handleChange}
            />
          </Main>
        </Col>
      </Row>
    </Container>
  );
};

export default DerivativeRecommendationsScreen;

import { Card, Switch, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

export const LeaderPublish = ({ data, onEdit, loading = false }) => {
  return (
    <Card
      className={clsx('w-full !bg-custom-darkBlue600 border-0 !rounded-xl')}
    >
      <div className="flex items-center justify-between">
        <Typography.Text className="text-white font-bold text-[16px]">
          Hiển thị Leader
        </Typography.Text>
        <Switch
          loading={loading}
          checked={data}
          onChange={onEdit}
          className="!bg-custom-green500"
        />
      </div>
    </Card>
  );
};

/* -------------------------------------------------------------------------- */
/*                              DATX INDEX SLICE                              */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  dataDATXIndex: [],
};

const { REACT_APP_DATX_INDEX_API_URL } = process.env;

export const getDATXIndex = createAsyncThunk(
  'DATXIndex/getDATXIndex',

  async (timeDistance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance(`${REACT_APP_DATX_INDEX_API_URL}`);

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDATXIndexSlice = createSlice({
  name: 'DATXIndex',
  initialState,
  reducers: {},
  extraReducers: {
    [getDATXIndex.pending]: (state) => {
      state.loading = true;
    },
    [getDATXIndex.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataDATXIndex = payload?.ratingTable;
    },
    [getDATXIndex.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const DATXIndexReducer = getDATXIndexSlice.reducer;

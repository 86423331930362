import styled from 'styled-components';

export const NewsByStockContainer = styled.div`
  min-height: ${(props) =>
    props.closeAnnouncement
      ? 'calc(100vh - 34rem + var(--announcement-height) - 274px) !important'
      : 'calc(100vh - 36rem - 274px) !important'};

  .ant-tabs-content {
    max-height: calc(100vh - 274px - 34rem) !important;
    overflow-y: scroll;
  }

  .ant-pagination {
    position: absolute;
    right: 1rem;
    bottom: 1.6rem;

    @media only screen and (max-width: 56.25em) {
      position: static;
    }
  }

  .ant-empty-description {
    color: var(--white-color);
  }
`;

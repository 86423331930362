import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Form, Row } from 'antd';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React, { useState } from 'react';
import { ABRangePicker, ABSelect } from 'components/GlobalComponents/FormItem';

const Filter = ({ onFilter }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="flex items-center justify-between bg-custom-darkBlue700 p-4 my-3 rounded-xl">
      <h1 className="text-2xl m-0">Danh sách Follower</h1>
      <FilterAltIcon
        onClick={() => setOpen(true)}
        className="!w-10 !h-10 cursor-pointer hover:!text-custom-darkBlue500"
      />

      <StyledModal
        open={open}
        destroyOnClose={true}
        maskClosable={true}
        className="!w-[620px]"
        title="Bộ lọc"
        footer={null}
        onCancel={() => setOpen(true)}
        onOk={() => null}
      >
        <Form
          initialValues={{
            status: null,
          }}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={() => {}}
          onFinish={(values) => onFilter(values)}
        >
          <Row gutter={[8, 12]}>
            <ABRangePicker label="Ngày theo dõi" value="startFollowDate" />
          </Row>
          <Row gutter={[8, 12]}>
            <ABRangePicker label="Ngày dừng theo dõi" value="stopFollowDate" />
          </Row>
          <Row gutter={[8, 12]}>
            <ABSelect
              label="Chọn trạng thái"
              options={[
                {
                  value: null,
                  label: 'Tất cả',
                },
                {
                  value: 'ACTIVE',
                  label: 'Đang theo dõi',
                },
                {
                  value: 'INACTIVE',
                  label: 'Đã dừng',
                },
              ]}
              value="status"
            />
          </Row>

          <div className="flex justify-end pt-2 pb-4">
            <Button
              className="!h-16 !px-16 !rounded-lg !text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500"
              onClick={onReset}
            >
              Đặt lại
            </Button>
            <Button
              type="primary"
              className="ml-6 !h-16 !px-16 !rounded-lg !bg-custom-blue500 border-0 hover:!bg-blue-500"
              onClick={() => {
                form.submit();
                setOpen(false);
              }}
            >
              Áp dụng
            </Button>
          </div>
        </Form>
      </StyledModal>
    </div>
  );
};

export default Filter;

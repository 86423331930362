const EditOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="inherit"
      height="inherit"
      fill="inherit"
      viewBox="0 0 16 16"
    >
      <path
        fill="inherit"
        d="M8.83333.5c.2124.000236.4167.081566.57114.227374.15445.145807.24739.345086.25984.557126.01245.21203-.05654.42082-.19286.58369-.13633.16288-.3297.26756-.54062.29264l-.0975.00584H2.16667V13.8333H13.8333V7.16667c.0003-.2124.0816-.4167.2274-.57114.1458-.15445.3451-.24739.5571-.25984.2121-.01245.4208.05654.5837.19286.1629.13633.2676.3297.2927.54062l.0058.0975v6.66663c.0001.4205-.1587.8255-.4446 1.1338-.2859.3083-.6778.4972-1.0971.5287l-.125.0042H2.16667c-.42048.0001-.82548-.1587-1.13379-.4446-.308321-.2859-.497177-.6778-.528713-1.0971L.5 13.8333V2.16667c-.000133-.42048.158672-.82548.444581-1.13379C1.23049.724559 1.62237.535703 2.04167.504167L2.16667.5h6.66666Zm5.20247.285833c.15-.149456.3512-.236228.5629-.24269.2116-.006462.4177.06787.5765.207898.1588.140028.2584.335249.2785.546019.02.21077-.0409.42128-.1704.58877l-.0691.07917-8.25003 8.2492c-.14997.1494-.3512.2362-.56282.2427-.21163.0064-.41778-.0679-.57658-.2079-.15881-.1401-.25836-.33529-.27844-.54606-.02007-.21077.04083-.42128.17034-.58877l.06916-.07834L14.0358.785833Z"
      />
    </svg>
  );
};

export default EditOutlineIcon;

import { Button, Col, Row, Select, Input } from 'antd';
import BlueSearchIcon from 'assets/icons/BlueSearchIcon';
import SortByIcon from 'assets/icons/SortByIcon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Container, FilterTab, FilterTabItem } from './styles';

const RecommendationToolbar = (props) => {
  const { onClickAdd, onChangeFilter } = props;
  const { t } = useTranslation(['recommendationsScreen']);
  const sessions = [
    { label: t('today'), value: 'today' },
    { label: '3D', value: '3d' },
    { label: '1W', value: '1w' },
    { label: '1M', value: '1m' },
    { label: '3M', value: '3m' },
  ];
  const [activeSessionValue, setActiveSessionValue] = useState(
    sessions[0].value
  );
  const [activeWatchlist, setActiveWatchlist] = useState('all');
  const { list } = useSelector((state) => state.watchlist);
  const [sortBy, setSortBy] = useState('new');
  const [search, setSearch] = useState('');

  const handleChangeActiveWatchlist = (e) => {
    setActiveWatchlist(e);
    if (onChangeFilter) {
      onChangeFilter({
        watchListId: e,
        session: activeSessionValue,
        search: search,
        sortBy: sortBy,
      });
    }
  };

  const handleChangeSortBy = (e) => {
    setSortBy(e);
    if (onChangeFilter) {
      onChangeFilter({
        watchListId: activeWatchlist,
        session: activeSessionValue,
        search: search,
        sortBy: e,
      });
    }
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    if (onChangeFilter) {
      onChangeFilter({
        watchListId: activeWatchlist,
        session: activeSessionValue,
        search: e.target.value,
        sortBy: sortBy,
      });
    }
  };

  const handleChangeSession = (session) => {
    setActiveSessionValue(session.value);
    if (onChangeFilter) {
      onChangeFilter({
        watchListId: activeWatchlist,
        session: session.value,
        search: search,
        sortBy: sortBy,
      });
    }
  };

  const handleClickAdd = () => {
    if (onClickAdd) {
      onClickAdd();
    }
  };

  return (
    <Container>
      <Row align="middle" gutter={[20, 20]}>
        <Col xs={24} lg={3} xxl={2}>
          <Select
            style={{ width: '100%' }}
            value={activeWatchlist}
            onChange={handleChangeActiveWatchlist}
          >
            <Select.Option value="all">{t('all')}</Select.Option>
            {list.data?.map((item) => (
              <Select.Option value={item._id} key={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} lg={9} xxl={13}>
          <div className="sessions">
            <FilterTab>
              {sessions.map((session) => (
                <FilterTabItem
                  key={session.value}
                  onClick={() => handleChangeSession(session)}
                  className={
                    session.value === activeSessionValue ? 'active' : 'unactive'
                  }
                >
                  {session.label}
                </FilterTabItem>
              ))}
            </FilterTab>
          </div>
        </Col>
        <Col
          xs={24}
          lg={3}
          xxl={2}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Select
            style={{ width: '100%' }}
            value={sortBy}
            onChange={handleChangeSortBy}
          >
            <Select.Option value="new">
              <SortByIcon />
              <span style={{ marginLeft: 8 }}>{t('latest')}</span>
            </Select.Option>
            <Select.Option value="top">
              <SortByIcon /> <span style={{ marginLeft: 8 }}>{t('top')}</span>
            </Select.Option>
          </Select>
        </Col>
        <Col
          xs={24}
          lg={9}
          xxl={7}
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Input
            style={{ width: 200 }}
            prefix={<BlueSearchIcon />}
            placeholder={t('searchByStockCode')}
            value={search}
            onChange={handleChangeSearch}
          />
          <Button
            type="primary"
            onClick={handleClickAdd}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            {t('addRecommendationManually')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default RecommendationToolbar;

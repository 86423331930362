import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Form, DatePicker, Button } from 'antd';
import TableRecommendationPerformance from 'components/RecommendationsScreenComponents/Table\bRecommendationPerformance';
import { getRecommendationPerformanceReport } from 'features/recommendation/performanceReportSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, StyledTitle } from './styles';

const RecommendationPerformanceReport = () => {
  const { data, loading } = useSelector(
    (state) => state.recommendationPerformanceReport
  );
  const [filter, setFilter] = useState({
    startDate: moment().subtract(1, 'M').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const interval = useRef();

  const execFilter = (values) => {
    setFilter({
      startDate: values.startDate?.format('DD-MM-YYYY'),
      endDate: values.endDate?.format('DD-MM-YYYY'),
    });
  };

  useEffect(() => {
    dispatch(getRecommendationPerformanceReport(filter));
    interval.current = setInterval(() => {
      if (!loading) {
        dispatch(
          getRecommendationPerformanceReport({ ...filter, polling: true })
        );
      }
    }, 10000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [filter]);

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={24} xxl={24}>
        <Row gutter={[24, 12]} align="middle" justify="center">
          <Col span={24}>
            <StyledTitle level={4}>
              Thống kê Hiệu suất Khuyến nghị của team Môi giới
            </StyledTitle>
          </Col>
        </Row>
        <Form
          form={form}
          layout="horizontal"
          initialValues={{
            startDate: moment().subtract(1, 'M'),
            endDate: moment(),
          }}
          onFinish={execFilter}
        >
          <Filter gutter={50}>
            <Col xl={5}>
              <Form.Item label="Từ ngày" name="startDate">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item label="Đến ngày" name="endDate">
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col xl={4}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => form.submit()}
                >
                  Tìm kiếm
                </Button>
              </div>
            </Col>
          </Filter>
        </Form>
        <TableRecommendationPerformance loading={loading} data={data} />
      </Col>
    </Row>
  );
};

export default RecommendationPerformanceReport;

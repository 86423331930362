import styled from 'styled-components';
import { Modal } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const StyledModal = styled(Modal)`
  && {
    width: 98% !important;
    top: 20px;
    padding: 0;

    iframe {
      width: 100%;
      height: 100%;
    }

    .ant-modal-body,
    .ant-modal-content {
      height: calc(100vh - 80px);
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
`;

import { InputNumber, Modal, Space, Typography } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import { postResentVerify } from 'features/setting/settingSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthModalContainer } from './styles';

const AuthModal = ({ visible, onOk, onCancel, body }) => {
  const dispatch = useDispatch();

  const intervalRef = useRef();
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();
  const input5Ref = useRef();
  const input6Ref = useRef();
  const inputsRef = [
    input1Ref,
    input2Ref,
    input3Ref,
    input4Ref,
    input5Ref,
    input6Ref,
  ];

  const AWAIT_TIME = 30;
  const [getCodeError, setGetCodeError] = useState(false);
  const [timing, setTiming] = useState(AWAIT_TIME);
  const [values, setValues] = useState(new Array(6).fill(null));

  const countdown = useMemo(
    () => ({
      enable: () => {
        intervalRef.current = setInterval(() => {
          setTiming((prevState) => {
            if (prevState === 0) {
              clearInterval(intervalRef.current);
              setGetCodeError(true);
              return 0;
            }
            return prevState - 1;
          });
        }, 1000);
      },
      disabled: () => {
        clearInterval(intervalRef.current);
      },
    }),
    []
  );

  const handleGetCodeAgain = async () => {
    try {
      const response = await dispatch(postResentVerify({ body })).unwrap();
      console.log('postResentVerify: ', response);

      countdown.enable();
      setGetCodeError(false);
      setTiming(AWAIT_TIME);
      setValues(new Array(6).fill(null));

      // modal.success({
      //   message: response.message,
      // });
    } catch (error) {
      console.log('postResentVerify: ', { error });
    }
  };

  const handleInputChange = (value, index) => {
    //handle auto focus next input
    if ((value || value === 0) && index < values.length - 1) {
      inputsRef[index + 1]?.current?.focus();
    }
    //end

    setValues((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  //handle remove input value
  const handleInputKeyDown = (e, index) => {
    if (e.code === 'Backspace' && !e.target.value && index !== 0) {
      setValues((prevState) => {
        const newState = [...prevState];
        newState[index - 1] = null;
        return newState;
      });
      inputsRef[index - 1]?.current?.focus();
    }
  };
  //end

  //handle focus first input not value
  const handleInputClick = () => {
    const positon = values.findIndex((value) => value === null);
    inputsRef[positon]?.current?.focus();
  };
  //end

  const handleCancel = () => {
    setTiming(AWAIT_TIME);
    countdown.disabled();
    onCancel();
  };

  useEffect(() => {
    countdown.enable();

    return () => {
      countdown.disabled();
      setValues(new Array(6).fill(null));
    };
  }, [countdown]);

  const { t } = useTranslation('settingsScreen');

  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      width="56.8rem"
      visible={visible}
      onCancel={handleCancel}
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
      footer={
        <Space>
          <SecondaryButton
            size="large"
            onClick={handleCancel}
            textBtn={t('textBtn2')}
          />
          <PrimaryButton
            size="large"
            large
            disabledInput={!values.every((value) => value || value === 0)}
            onHandleClick={() =>
              onOk({ code: values.join(''), email: body.email })
            }
            textBtn={t('textBtn3')}
          />
        </Space>
      }
    >
      <AuthModalContainer>
        <Typography.Text style={{ fontWeight: '300' }}>
          {t('notification.updateEmail.text1')}
        </Typography.Text>
        <Typography.Title style={{ marginTop: 0 }} level={5}>
          {body.email}
        </Typography.Title>
        <Space
          size={20}
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '24px 0',
          }}
        >
          {values.map((value, index) => (
            <InputNumber
              key={index}
              ref={inputsRef[index]}
              value={value}
              maxLength={1}
              controls={false}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleInputKeyDown(e, index)}
              onClick={handleInputClick}
            />
          ))}
        </Space>

        {getCodeError ? (
          <Typography.Text>
            {t('notification.updateEmail.text2')} {'  '}
            <span
              onClick={handleGetCodeAgain}
              style={{
                color: 'var(--light-blue-color)',
                fontWeight: '500',
                cursor: 'pointer',
              }}
            >
              {t('notification.updateEmail.text3')}
            </span>
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t('notification.updateEmail.text4')}{' '}
            <span
              style={{ color: 'var(--light-blue-color)', fontWeight: '500' }}
            >
              {timing}
            </span>{' '}
            {t('notification.updateEmail.text5')}
          </Typography.Text>
        )}
      </AuthModalContainer>
    </Modal>
  );
};

export default AuthModal;

import styled, { css } from 'styled-components';

export const StyledGlobalWrapper = styled.div`
  grid-template-columns: ${(props) =>
    props.expandSidebar ? '27rem auto' : '9.2rem auto'};
  display: grid;
  background: var(--background-color);
  color: var(--white-color);
  overflow: hidden;
  height: 100vh;

  .ant-menu-dark
    .ant-menu-submenu-open
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow::before,
  .ant-menu-dark
    .ant-menu-submenu-open
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before,
  .ant-menu.ant-menu-dark
    .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after {
    background: var(--white-color);
  }

  &::-webkit-scrollbar-track {
    background: var(--background-color);
  }

  @media only screen and (max-width: 81.1875em) {
    height: auto;
  }

  @media only screen and (min-height: 1081px) and (max-width: 81.1875em) {
    height: 100vh;
  }

  > div:nth-child(3) {
    width: ${(props) =>
      props.expandSidebar ? 'calc(100vw - 27rem)' : 'calc(100vw - 9.2rem)'};
    padding: 2rem;
    /* overflow: auto; */
    &::-webkit-scrollbar-track {
      background-color: var(--background-color);
    }

    @media only screen and (max-width: 81.1875em) {
      width: 100vw;
    }
  }

  @media only screen and (max-width: 81.1875em) {
    grid-template-columns: 2rem auto;
    grid-gap: 0;
  }

  @media only screen and (max-width: 37.5em) {
    height: auto;
  }
`;

export const MainWrapper = styled.div`
  margin-top: var(--header-height);
  padding-top: 2rem;
  height: calc(100vh - 6rem);
  overflow: auto;

  @media screen and (max-width: 56.25em) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ScreenWrapper = styled.div`
  height: calc(
    100% - ${(props) => (props.closeAnnouncement ? '0rem' : '7.2rem')}
  );
`;

/* -------------------------------------------------------------------------- */
/*                            INFO CONTAINER CHART                            */
/* -------------------------------------------------------------------------- */

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  color: var(--white-color);
  font-size: 1.2rem;
  line-height: 2rem;
  /* padding-bottom: 3rem; */
  padding-top: 1.6rem;

  > div:nth-child(1) {
    margin-right: 3rem;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;

  span:nth-child(1) {
    height: 1.8rem;
    width: 1.8rem;
    display: inline-block;
    margin-right: 1rem;
  }
`;

import { closeAnnouncementSelector } from 'features/uiHandler/uiHandlerSlice';
import { useSelector } from 'react-redux';
import { Layout, Typography } from 'antd';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useMemo, useState } from 'react';
import { LeaderDetail } from 'components/CopyTradeComponents/LeaderDetail';
import { StyledMenu } from './styles';
import clsx from 'clsx';
import { CopierManagement } from 'components/CopyTradeComponents/CopierManagement';
import { ProductManagement } from 'components/CopyTradeComponents/Product';

const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    'Thông tin Leader',
    'info',
    <SupervisorAccountIcon className="!w-10 !h-10" />
  ),
  getItem(
    'Quản lý Copier',
    'copiers',
    <AssessmentIcon className="!w-10 !h-10" />
  ),
  getItem('Sản phẩm', 'products', <LocalOfferIcon className="!w-10 !h-10" />),
];

const CopyTradeScreen = () => {
  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const [active, setActive] = useState('info');
  const [collapsed, setCollapsed] = useState(false);

  const renderContent = useMemo(
    () => (
      <div>
        {active === 'info' && <LeaderDetail />}
        {active === 'copiers' && <CopierManagement />}
        {active === 'products' && <ProductManagement />}
      </div>
    ),
    [active]
  );

  return (
    <Layout
      className="bg-transparent h-86 gap-4"
      closeAnnouncement={closeAnnouncement}
    >
      <Sider
        breakpoint="md"
        collapsible
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        className="!bg-custom-darkBlue600 rounded-xl !h-screen"
      >
        <Typography.Text
          className={clsx(
            'text-3xl font-bold p-4 block',
            collapsed && 'text-center'
          )}
          strong
        >
          {!collapsed ? 'Copytrade' : 'Ct'}
        </Typography.Text>
        <StyledMenu
          className="bg-transparent border-0"
          onClick={(e) => setActive(e.key)}
          defaultSelectedKeys={active}
          defaultOpenKeys={active}
          mode="inline"
          items={items}
        />
      </Sider>
      <Content>{renderContent}</Content>
    </Layout>
  );
};

export default CopyTradeScreen;

import { Divider, Slider, Typography } from 'antd';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

const StyledSlider = styled(Slider)`
  .ant-slider-rail {
    background: #151c2b;
  }
  .ant-slider-track,
  .ant-slider-handle {
    background: white;
    border-color: white;
  }

  .ant-slider-dot {
    background: #484848;
    border-color: #484848;

    &.ant-slider-dot-active {
      background: white;
      border-color: white;
    }
  }

  &:hover {
    .ant-slider-track,
    .ant-slider-handle {
      background: white;
      border-color: white !important;
    }
  }
`;

export default function Proportion({
  proportionPercentage,
  setProportionPercentage,
}) {
  const marks = {
    0: 0,
    20: 20,
    40: 40,
    60: 60,
    80: 80,
    100: {
      style: {
        transform: 'translateX(-80%)',
      },
      label: 100,
    },
  };

  return (
    <div className="w-full flex flex-col rounded-lg bg-custom-darkBlue600">
      <div className="w-full h-[40px] flex items-center justify-between pl-5 rounded-lg bg-custom-darkBlue600">
        <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">
          Khối lượng
        </Typography.Text>
        <div className="w-[86px] flex justify-between items-center mr-6">
          <NumericFormat
            className="w-[48px] text-lg text-center rounded-none border-none bg-transparent text-white focus:outline-none relative"
            value={(Math.round(proportionPercentage * 100) / 100).toFixed(2)}
            isAllowed={(values) => {
              const { value } = values;
              return Number(value) <= 100 && Number(value) >= 0;
            }}
            onValueChange={(values) => {
              setProportionPercentage(Number(values.value));
            }}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator=","
            onFocus={(e) => e.target.select()}
          />
          %
        </div>
      </div>
      <div>
        <Divider className="my-2 !border-custom-darkBlue500" />
        <StyledSlider
          className="!mx-[18px] !mb-16"
          marks={marks}
          step={0.1}
          value={proportionPercentage}
          onChange={setProportionPercentage}
        />
      </div>
    </div>
  );
}

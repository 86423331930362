import { Button, Typography } from 'antd';
import clsx from 'clsx';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRADE_TYPE } from '.';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Confirmation = ({ data, open, setOpen, onConfirm, loading }) => {
  const { t } = useTranslation(['common']);

  const renderData = (key) => {
    switch (key) {
      case 'type':
        return data[key] === 'copier' ? 'Copier mới' : 'Leader';
      case 'isSplitOrder':
        return data[key] ? 'Chia nhỏ lệnh' : 'Không';
      case 'tradeType':
        return data[key] === TRADE_TYPE.get('buy') ||
          data[key] === TRADE_TYPE.get('buyCopier') ? (
          <span className="font-bold !text-green">Mua</span>
        ) : (
          <span className="font-bold !text-red">Bán</span>
        );
      case 'proportionPercentage':
        return `${data[key]} %NAV`;
      case 'price':
        return data[key] > 0 ? (
          data[key]?.toFixed(2)
        ) : (
          <span>
            <span>{data?.minPrice.toFixed(2)}</span>
            <ArrowForwardIcon className="!w-8 !h-8 mx-2" />
            <span>{data?.maxPrice.toFixed(2)}</span>
          </span>
        );

      default:
        return data[key];
    }
  };

  if (!data) return null;

  return (
    <span>
      <StyledModal
        open={open}
        destroyOnClose={true}
        maskClosable={true}
        className="!w-[480px]"
        title={
          <Typography.Text className="text-xl">
            Xác nhận đặt lệnh
          </Typography.Text>
        }
        footer={null}
        onCancel={() => setOpen(false)}
      >
        {data &&
          Object.keys(data)?.map(
            (item, index) =>
              item !== 'minPrice' &&
              item !== 'maxPrice' && (
                <div key={index} className="flex justify-between py-2">
                  <Typography.Text className="!text-custom-gray300 text-lg">
                    {t(`copytrade.${item}`)}
                  </Typography.Text>
                  <Typography.Text className="!text-white font-bold text-lg">
                    {renderData(item)}
                  </Typography.Text>
                </div>
              )
          )}
        <div className="flex justify-end py-4">
          <Button
            className={clsx('!h-16 !px-16 !rounded-lg !border-0 !bg-[#151C2B]')}
            onClick={() => setOpen(false)}
          >
            Đóng
          </Button>
          <Button
            loading={loading}
            type="primary"
            className={clsx(
              'ml-6 !h-16 !px-16 !rounded-lg border-0',
              '!bg-custom-blue500 border-0 hover:!bg-blue-500'
            )}
            onClick={() => {
              onConfirm();
            }}
          >
            Xác nhận
          </Button>
        </div>
      </StyledModal>
    </span>
  );
};

export default Confirmation;

import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
  background: var(--primary-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  width: 37.4rem;
  max-height: calc(100vh - 50px);
  overflow: auto;
  border: 1px solid var(--border-color);

  .ant-empty-description {
    color: white;
  }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: rgba(73, 194, 242, 0.08) !important;

      .ant-typography-expand {
        text-decoration: underline;
        color: #40a9ff;
      }
    }
  }

  .ant-dropdown-menu-item-group-title {
    color: white;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }
  .ant-dropdown-menu-item-only-child {
    padding: 0 1.8rem;
  }
  .ant-dropdown-menu-title-content {
    width: 100%;

    &:hover {
      background-color: transparent !important;
      color: var(--white-color) !important;
    }
  }
  .ant-dropdown-menu-item-only-child:last-child div {
    border-bottom: none;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(242, 112, 73, 0.12);
  }

  .ant-dropdown-menu-item-group-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
  }
`;

export const NotifyContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid var(--border-color);
  padding: 1.2rem 0;

  .ant-typography {
    color: var(--white-color);
    white-space: normal;
  }

  .ant-typography-expand {
    pointer-events: none;
  }
`;

export const StyledNotifyTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 1rem;
  padding-top: 2px;

  display: flex;
  flex-flow: row;

  svg {
    margin-right: 1rem;
  }
`;

export const StyledNotifyContent = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
`;

export const StyledNotifyTime = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: var(--light-blue-color-2);
`;

export const StyledClearButton = styled.button`
  position: absolute;
  color: var(--white-color);
  top: 0.6rem;
  right: 0.6rem;
  background: transparent;
  border: none;
`;

export const NotifyIconWrapper = styled.div`
  .ant-scroll-number.ant-badge-dot {
    box-shadow: unset;
    top: 3px;
    right: 8px;
    background-color: var(--light-red-color);
    border: 1px solid #21253b;
  }
`;

import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Modal, Row } from 'antd';
import {
  Container,
  Heading,
  Actions,
  ErrorMessage,
  SubHeading,
  AddNewGroupButton,
  ConnectionName,
} from './styles';
import CloseIcon from 'assets/icons/CloseIcon';
import { ReactComponent as TickSvg } from 'assets/images/tick.svg';
import { ReactComponent as ClaimSvg } from 'assets/images/claim.svg';
import { useTranslation } from 'react-i18next';

const AddEditCustomerFile = (props) => {
  const { data, visible, loading, onCancel, onOk } = props;
  const { t } = useTranslation(['recommendationsScreen']);
  const [error, setError] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [connections, setConnections] = useState([]);

  const availablePlatforms = [
    { label: 'Telegram', value: 'telegram' },
    // { label: "Zalo", value: "zalo" },
  ];

  const handleChangeConnection = (event, index) => {
    const newConnections = [...connections];
    newConnections[index].url = event.target.value;
    setConnections(newConnections);
  };

  const handleAddNewConnection = () => {
    setConnections([
      ...connections,
      {
        code: 'telegram',
        url: '',
        name: '',
      },
    ]);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = () => {
    const values = {
      id: data?.id,
      name,
      description,
      platforms,
      connections,
    };
    let hasError = false;
    let keys = ['name', 'description', 'platforms', 'connections'];
    const newError = { ...error };
    for (const key of keys) {
      if (!values[key] || values[key].length === 0) {
        newError[key] = t('customerFileForm.requiredMessage');
        hasError = true;
      } else {
        newError[key] = undefined;
      }
      setError(newError);
    }
    if (!hasError && onOk) {
      onOk(values);
    }
  };

  useEffect(() => {
    setError({});
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setConnections(data.connections ?? []);
      setPlatforms(data.connections.map((i) => i.code));
    } else {
      setName('');
      setDescription('');
      setConnections([]);
      setPlatforms([]);
    }
  }, [data]);

  return (
    <Modal
      centered
      destroyOnClose={true}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="80rem"
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
    >
      <Container>
        <Heading>
          {data ? t('editCustomerFile') : t('createCustomerFile')}
        </Heading>
        <Row gutter={20}>
          <Col span={4}>
            <label>{t('customerFileForm.name')}</label>
          </Col>
          <Col span={20}>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
            <ErrorMessage>{error.name}</ErrorMessage>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={4}>
            <label>{t('customerFileForm.description')}</label>
          </Col>
          <Col span={20}>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <ErrorMessage>{error.description}</ErrorMessage>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={4}>
            <label>{t('customerFileForm.platform')}</label>
          </Col>
          <Col span={20}>
            <Checkbox.Group
              options={availablePlatforms}
              value={platforms}
              onChange={(checkedValues) => setPlatforms(checkedValues)}
            />
            <ErrorMessage>{error.platforms}</ErrorMessage>
          </Col>
        </Row>
        <SubHeading>{t('customerFileForm.setupConnections')}</SubHeading>
        {platforms.includes('telegram') && (
          <Row gutter={20}>
            <Col span={4}>
              <label>Telegram</label>
            </Col>
            <Col span={20}>
              {connections.map((item, index) => (
                <Row gutter={20} style={{ padding: 0, marginBottom: '16px' }}>
                  <Col span={12}>
                    <Input
                      placeholder={t(
                        'customerFileForm.updateInvitationLinkHere'
                      )}
                      value={item.url}
                      onChange={(event) => handleChangeConnection(event, index)}
                      suffix={
                        <>
                          {item.state === 'Succeed' && <TickSvg />}
                          {item.state === 'Failed' && <ClaimSvg />}
                        </>
                      }
                    />
                  </Col>
                  <Col span={12}>
                    {item.state === 'Succeed' && (
                      <ConnectionName>
                        {t('customerFileForm.group')}:&nbsp;{' '}
                        <span>{item.name}</span>
                      </ConnectionName>
                    )}
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={12}>
                  <AddNewGroupButton onClick={handleAddNewConnection}>
                    + {t('customerFileForm.addNewGroup')}
                  </AddNewGroupButton>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <ErrorMessage>{error.connections}</ErrorMessage>
        <Row>
          <Actions>
            <Button onClick={handleCancel}>
              {t('customerFileForm.cancel')}
            </Button>
            <Button type="primary" disabled={loading} onClick={handleSubmit}>
              {data
                ? t('customerFileForm.update')
                : t('customerFileForm.create')}
            </Button>
          </Actions>
        </Row>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(AddEditCustomerFile);

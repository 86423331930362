import { useMutation } from '@tanstack/react-query';
import { leadersSelector } from 'features/copyTrade/copyTradeSlice';
import { CaretDownOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Row, Col, Spin, Descriptions, Divider, Select, Checkbox, Tooltip } from 'antd';
import { getProductDetail, updateProductPackages } from 'utils/api';
import { settingsError, settingsSuccess } from 'utils';
import { useSelector } from 'react-redux';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React, { useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { DATE_FORMAT } from 'constants/date';
import {
  ProductFormStyled,
  StyledInput,
  StyledSelect,
  StyledRangePicker,
  TableButton,
  StyledInputNumber,
} from './styled';
import moment from 'moment';
import { toast } from 'react-toastify';

const ProductForm = ({ open, onClose }) => {
  const { mutateAsync: getProduct, isLoading } = useMutation(getProductDetail);
  const { mutateAsync: updateProduct, isLoading: isLoadingUpdate } = useMutation(updateProductPackages);
  const { data: leaderData } = useSelector(leadersSelector);
  const [product, setProduct] = useState(null);
  const [deletedPack, setDeleted] = useState([]);
  const [form] = Form.useForm();

  const loadDetail = async () => {
    try {
      const { data } = await getProduct(open);
      console.log('data Product', data);

      setProduct({
        ...data,
        packages: data.packages
          .filter((i) => i.leaderSubAccountId === `${leaderData[0]?.source}_${leaderData[0]?.subAccountId}`)
          .map((i) => ({
            ...i,
            status: i.status === 'suggest' ? 'active' : i.status,
            name: i.name.split(`- ${leaderData[0]?.name}`)[0],
          })),
      });
    } catch (error) {}
  };

  const onFinishForm = async () => {
    try {
      await updateProduct({
        id: product._id.toString(),
        data: product.packages.concat(deletedPack.map((i) => ({ _id: i, isDelete: true }))).map((i) => ({
          ...i,
          packageId: i._id?.toString() || '',
          leaderName: leaderData[0].nickname || leaderData[0].name,
          leaderSubAccountId: `${leaderData[0]?.source}_${leaderData[0]?.subAccountId}`,
        })),
      });
      toast.success('Cập nhật gói bán thành công!', settingsSuccess);
      onClose();
    } catch (error) {
      toast.error('Cập nhật gói bán lỗi!', settingsError);
      console.log('[updateProduct] error', error);
    }
  };

  const onAddPackages = () => {
    setProduct((state) => ({
      ...state,
      packages: [
        ...state.packages,
        {
          priority: 1,
          name: '',
          price: 0,
          timeQuantity: 1,
          timeUnit: 'month',
          startAt: moment().startOf('day').toISOString(),
          endAt: moment().add(30, 'days').endOf('day').toISOString(),
          status: 'active',
          isTrial: false,
          isDelete: false,
          packageId: '',
        },
      ],
    }));
  };

  const removePackage = (idx) => {
    const item = product.packages[idx];
    if (item._id) setDeleted((state) => [...state, item._id.toString()]);

    setProduct((state) => ({
      ...state,
      packages: [...state.packages.slice(0, idx), ...state.packages.slice(idx + 1)],
    }));
  };

  const updateValue = (idx, key, value) => {
    setProduct((state) => ({
      ...state,
      packages: [
        ...state.packages.slice(0, idx),
        { ...state.packages[idx], [key]: value },
        ...state.packages.slice(idx + 1),
      ],
    }));
  };

  useEffect(() => {
    if (open) {
      loadDetail();
      setDeleted([]);
    }
  }, [open]);

  return (
    <StyledModal
      open={open}
      destroyOnClose={true}
      maskClosable={true}
      className="!w-[1200px]"
      title="Thiết lập gói bán"
      footer={null}
      onCancel={() => null}
      onOk={() => null}
      style={{ top: 25 }}
    >
      <Spin tip="Loading..." spinning={isLoading || isLoadingUpdate}>
        <Descriptions>
          <Descriptions.Item label="Tên sản phẩm" className="font-bold">
            {product?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Mã sản phẩm" className="font-bold">
            {product?.code}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">
          Danh sách gói{' '}
          <Tooltip title="Thêm gói">
            <Button shape="circle" icon={<PlusOutlined />} onClick={onAddPackages} />
          </Tooltip>
        </Divider>
        {product?.packages.map((i, idx) => (
          <ProductFormStyled
            key={i._id}
            initialValues={{
              status: null,
            }}
            form={form}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            layout="vertical"
            onFieldsChange={() => {}}
          >
            <Row gutter={[4, 6]}>
              <Col xs={24} lg={1}>
                <Form.Item label="STT">
                  <StyledInput
                    placeholder="STT"
                    value={i.priority}
                    onChange={(ev) => updateValue(idx, 'priority', ev.target.value && Number(ev.target.value))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={3}>
                <Form.Item label="Tên gói">
                  <StyledInput
                    placeholder="Tên gói"
                    value={i.name}
                    onChange={(ev) => updateValue(idx, 'name', ev.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={3}>
                <Form.Item label="Giá">
                  <StyledInputNumber
                    placeholder="Giá"
                    addonAfter="đ"
                    value={i.price}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={(val) => updateValue(idx, 'price', val)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={2}>
                <Form.Item label="Thời hạn">
                  <StyledInput
                    placeholder="Thời hạn"
                    value={i.timeQuantity}
                    onChange={(ev) => updateValue(idx, 'timeQuantity', ev.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={3}>
                <Form.Item label="Thời gian" labelCol={24}>
                  <StyledSelect
                    suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                    placeholder="Thời gian"
                    value={i.timeUnit}
                    onChange={(val) => updateValue(idx, 'timeUnit', val)}
                  >
                    {[
                      {
                        value: 'day',
                        label: 'Ngày',
                      },
                      {
                        value: 'week',
                        label: 'Tuần',
                      },
                      {
                        value: 'month',
                        label: 'Tháng',
                      },
                    ].map((item, index) => (
                      <Select.Option key={index} value={item?.value}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Bán từ">
                  <StyledRangePicker
                    locale={locale}
                    className="w-full !rounded-md"
                    format={DATE_FORMAT}
                    defaultValue={[moment(i.startAt), moment(i.endAt)]}
                    onChange={(dates, dateStrings) => {
                      updateValue(idx, 'startAt', dates[0].toISOString());
                      updateValue(idx, 'endAt', dates[1].toISOString());
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={3}>
                <Form.Item label="Trạng thái" labelCol={24}>
                  <StyledSelect
                    suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                    placeholder="Trạng thái"
                    value={i.status}
                    onChange={(val) => updateValue(idx, 'status', val)}
                  >
                    {[
                      {
                        value: 'active',
                        label: 'Bán',
                      },
                      {
                        value: 'pending',
                        label: 'Dừng bán',
                      },
                    ].map((item, index) => (
                      <Select.Option key={index} value={item?.value}>
                        {item?.label}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                </Form.Item>
              </Col>

              <Col xs={4} lg={2}>
                <Form.Item label=" ">
                  <Checkbox checked={i.isTrial} onChange={(ev) => updateValue(idx, 'isTrial', ev.target.checked)}>
                    Là trial
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={4} lg={1}>
                <Form.Item label=" ">
                  <TableButton onClick={() => removePackage(idx)}>
                    <CloseOutlined style={{ color: 'red' }} />
                  </TableButton>
                </Form.Item>
              </Col>
            </Row>
          </ProductFormStyled>
        ))}

        <div className="flex justify-end pt-2 pb-4">
          <Button
            className="!h-16 !px-16 !rounded-lg !text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500"
            onClick={onClose}
          >
            Huỷ
          </Button>
          <Button
            type="primary"
            className="ml-6 !h-16 !px-16 !rounded-lg !bg-custom-blue500 border-0 hover:!bg-blue-500"
            onClick={() => onFinishForm()}
          >
            Lưu thay đổi
          </Button>
        </div>
      </Spin>
    </StyledModal>
  );
};

export default ProductForm;

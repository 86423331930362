import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-selector,
  .ant-select-selector-search {
    background: #212335;
    border-radius: 6px;
    padding: 4px 10px !important;
    border-bottom: 0 !important;
  }

  .ant-input {
    text-align: center;
    font-size: 16px;
  }
`;

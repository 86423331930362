import { Collapse, Pagination, Tag } from 'antd';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import moment from 'moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IssueListContainer, StyledTitle } from './styles';

const IssueList = ({
  fetchData,
  totalPage,
  loading,
  issueList,
  defaultId,
  currentPage,
  setCurrentPage,
}) => {
  const { Panel } = Collapse;

  function callback(key) {
    console.log(key);
  }

  const handleClickPaginationItem = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const { t } = useTranslation(['supportScreen']);

  return (
    <>
      {loading ? (
        <>
          <Loader />
          <div></div>
        </>
      ) : (
        <IssueListContainer>
          <StyledTitle>Danh sách vấn đề</StyledTitle>

          <div style={{ overflowY: 'scroll' }}>
            <Collapse
              accordion
              defaultActiveKey={defaultId}
              onChange={callback}
              destroyInactivePanel
            >
              {issueList?.map((item, index) => {
                let color, textColor;

                switch (item.process_status) {
                  case 'open':
                    color = 'rgba(0, 140, 207, 0.2)';
                    textColor = 'rgba(0, 140, 207, 1)';

                    break;

                  case 'in_progress':
                    color = 'rgba(242, 237, 73, 0.2)';
                    textColor = 'rgba(242, 237, 73, 1)';

                    break;

                  case 'resolved':
                    color = 'rgba(30, 217, 148, 0.2)';
                    textColor = '#1ed994';
                    break;

                  case 'cancel':
                    color = 'rgba(242, 75, 120, 0.2)';
                    textColor = 'rgba(242, 75, 120, 1)';

                    break;

                  default:
                    break;
                }

                return (
                  <Fragment key={index}>
                    <Panel
                      header={
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                color: 'var(--border-color)',
                                fontWeight: '700',
                              }}
                            >
                              {moment(item.created_at).format(
                                'YYYY/MM/DD hh:mm:ss'
                              )}
                            </div>

                            <Tag color={color} style={{ color: textColor }}>
                              {t(
                                `issueList.progressStatus.${item.process_status}`
                              )}
                            </Tag>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      }
                      key={item._id}
                    >
                      <p>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      </p>
                    </Panel>
                  </Fragment>
                );
              })}
            </Collapse>
          </div>

          <Pagination
            current={currentPage}
            className="modal-table"
            total={totalPage}
            onChange={(page) => handleClickPaginationItem(page)}
          />
        </IssueListContainer>
      )}
    </>
  );
};

export default IssueList;

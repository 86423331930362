import { Empty, Tooltip } from 'antd';
import ExpandFourAnglesIcon from 'assets/icons/ExpandFourAnglesIcon';
import FailureIconInNotiDialog from 'assets/icons/FailureIconInNotiDialog';
import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NewsModal from './Modal';
import {
  EmptyWrapper,
  NewsBoxBodyStyled,
  NewsBoxHeaderStyled,
  NewsBoxHeaderTitle,
  NewsBoxWrapper,
  PermissionDeniedWrapper,
  ViewAllButton,
} from './styles';

const NewsBox = ({
  stockCodes,
  sectorList,
  modalLoading,
  setModalLoading,
  permission = true,
  next,
  isNext,
  onChangeSector,
  onSelectStockCode,
  onRemoveFilter,
  filter,
  posts,
  setModalVisible,
  modalVisible,
  isClickedBtn,
  setIsClickedBtn,
}) => {
  const handleOpenModal = () => {
    setModalVisible(true);
    setIsClickedBtn(true);
  };

  const { isLoadingSkeletonWatchlistNews } = useSelector(
    (state) => state.watchlist
  );

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const { t } = useTranslation(['common']);

  const { loading } = useSelector((state) => state.watchlist);

  return (
    <NewsBoxWrapper>
      <NewsModal
        stockCodes={stockCodes || []}
        sectorList={sectorList || []}
        visible={modalVisible}
        onClose={handleCloseModal}
        next={next}
        isNext={isNext}
        posts={posts}
        filter={filter}
        loading={loading}
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        onChangeSector={onChangeSector}
        onSelectStockCode={onSelectStockCode}
        onRemoveFilter={onRemoveFilter}
        isClickedBtn={isClickedBtn}
        setIsClickedBtn={setIsClickedBtn}
      />

      <NewsBoxHeaderStyled>
        <NewsBoxHeaderTitle>{t('newsBox.title')}</NewsBoxHeaderTitle>
        {permission && posts && posts.length !== 0 && !loading && (
          <Tooltip
            title={t('newsBox.toolTip1')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <button onClick={handleOpenModal}>
              <ExpandFourAnglesIcon />
            </button>
          </Tooltip>
        )}
      </NewsBoxHeaderStyled>

      <NewsBoxBodyStyled className="newsbox-body">
        {(() => {
          switch (true) {
            case !permission:
              return (
                <PermissionDeniedWrapper>
                  <FailureIconInNotiDialog />
                  <span>PERMISSION_DENIED</span>
                </PermissionDeniedWrapper>
              );

            case isLoadingSkeletonWatchlistNews:
              return new Array(10)
                .fill(null)
                .map((_, index) => <PostItem.Loading key={index} />);

            case !Array.isArray(posts):
            case posts.length === 0:
              return (
                <EmptyWrapper className="empty">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ margin: 'auto' }}
                  />
                </EmptyWrapper>
              );

            default:
              return (
                <>
                  {posts.slice(0, 10).map((post, index) => (
                    <PostItem key={index} data={post} />
                  ))}
                  <ViewAllButton onClick={handleOpenModal}>
                    {t('newsBox.btnReadAll')} &#8250;
                  </ViewAllButton>
                </>
              );
          }
        })()}
      </NewsBoxBodyStyled>
    </NewsBoxWrapper>
  );
};

export default NewsBox;

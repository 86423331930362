import { Empty, Modal, Space } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import FailureIconInNotiDialog from 'assets/icons/FailureIconInNotiDialog';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import { watchlist_permission } from 'constants/permission_key';
import { closeAnnouncementSelector } from 'features/uiHandler/uiHandlerSlice';
import {
  getWatchlistDetail,
  handleSelectSectorWatchlistNews,
  handleSelectWatchlist,
  loadingSelector,
  watchlistDetailSelector,
  watchlistsSelector,
} from 'features/watchlist/watchlistSlice';
import usePermission from 'hooks/usePermission';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeAccents } from 'utils';
import {
  EmptyWrapper,
  SkeletonStyled,
  WatchlistBodyStyled,
  WatchlistContainerStyled,
} from './styles';
import WatchlistHeader from './WatchlistHeader';
import WatchlistItem from './WatchlistItem';
import WatchlistModal from './WatchlistModal';

/**
 * @param {string} [type]
 * @param {function} [onCloseDrawer]
 */

const Watchlist = ({
  type,
  onCloseDrawer,
  setModalVisible,
  setWatchlistEditting,
  watchlistEditting,
  modalVisible,
  setModalType,
  modalType,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handleCloseDeleteModal,
  deleteModalVisible,
  handleDeleteWatchlist,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['watchlistScreen']);

  const [, list_permission] = usePermission({ key: watchlist_permission.list });

  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const loading = useSelector(loadingSelector);

  const { loading: listLoading, data: watchlists } =
    useSelector(watchlistsSelector);
  const { data: currentWatchlist } = useSelector(watchlistDetailSelector);

  const [filter, setFilter] = useState({
    name: '',
    sort: 'default',
  });

  const watchlistsRemaining = useMemo(() => {
    const data = watchlists.filter((item) => {
      let checkFilterName = true;
      if (filter.name) {
        checkFilterName =
          item.name
            .toLowerCase()
            .includes(
              filter.name.toLowerCase().trim().replace(/\s\s+/g, ' ')
            ) ||
          removeAccents(item.name)
            .toLowerCase()
            .includes(
              removeAccents(
                filter.name.toLowerCase().trim().replace(/\s\s+/g, ' ')
              )
            );
      }
      return checkFilterName;
    });

    switch (filter.sort) {
      case 'name':
        return data.sort((a, b) => a.name.localeCompare(b.name));
      case 'total_points':
        return data.sort((a, b) => b.total_points - a.total_points);
      case 'highest_return':
        return data.sort((a, b) => b.highest_return - a.highest_return);
      case 'lowest_return':
        return data.sort((a, b) => b.lowest_return - a.lowest_return);
      case 'percent':
        return data.sort((a, b) => b.percent - a.percent);
      default:
        return data;
    }
  }, [watchlists, filter]);

  //get watchlist detail from url when first load
  useEffect(() => {
    if (
      currentWatchlist ||
      !Array.isArray(watchlists) ||
      watchlists.length === 0
    )
      return;
    dispatch(getWatchlistDetail({ watchlist_id: watchlists[0]._id }));
    //eslint-disable-next-line
  }, [watchlists, currentWatchlist]);
  //end

  const [handleOpenCreateModal] = usePermission({
    key: watchlist_permission.create,
    handle: () => {
      setModalVisible(true);
      setModalType('create');
      type === 'drawer' && onCloseDrawer();
    },
  });

  const handleGetWatchlistDetail = ({ _id: watchlist_id, name }) => {
    dispatch(handleSelectWatchlist({ _id: watchlist_id, name }));
    dispatch(handleSelectSectorWatchlistNews({ sectors: [], stockCodes: [] }));
    // dispatch(getWatchlistNewsInit({ watchlist_id: currentWatchlist._id }));

    if (currentWatchlist?._id === watchlist_id) return;
    dispatch(getWatchlistDetail({ watchlist_id }));
    type === 'drawer' && onCloseDrawer();
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setWatchlistEditting(null);
  };

  return (
    <>
      {loading && <Loader />}
      {modalVisible && (
        <WatchlistModal
          visible={modalVisible}
          onCancel={handleCloseModal}
          watchlist={watchlistEditting}
          type={modalType}
        />
      )}

      {deleteModalVisible && (
        <Modal
          centered
          maskClosable={false}
          width="50rem"
          visible={deleteModalVisible}
          zIndex={999}
          closeIcon={<CloseIcon size={24} />}
          title=" "
          onCancel={handleCloseDeleteModal}
          footer={
            <Space>
              <SecondaryButton
                size="large"
                large
                onClick={handleCloseDeleteModal}
                textBtn={t('watchlistSelectorSection.confirmModal.btn1Text')}
              />
              <PrimaryButton
                large
                onClick={handleDeleteWatchlist}
                textBtn={t('watchlistSelectorSection.confirmModal.btn2Text')}
              />
            </Space>
          }
        >
          <div style={{ padding: '10px 40px' }}>
            <span style={{ fontSize: '1.6rem' }}>
              {t('watchlistSelectorSection.confirmModal.text')} "
              {watchlistEditting.name}"
            </span>
          </div>
        </Modal>
      )}
      <WatchlistContainerStyled className="watchlist">
        <WatchlistHeader
          filter={filter}
          setFilter={setFilter}
          onOpenModal={handleOpenCreateModal}
        />
        <WatchlistBodyStyled closeAnnouncement={closeAnnouncement}>
          {(() => {
            switch (true) {
              case listLoading:
                return (
                  <SkeletonStyled
                    title={false}
                    paragraph={{ rows: 12 }}
                    active
                  />
                );
              case !list_permission:
                return (
                  <EmptyWrapper>
                    <FailureIconInNotiDialog />
                    <span>PERMISSION_DENIED</span>
                  </EmptyWrapper>
                );

              case !Array.isArray(watchlistsRemaining):
              case watchlistsRemaining.length === 0:
                return (
                  <EmptyWrapper>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </EmptyWrapper>
                );

              default:
                return watchlistsRemaining.map((item) => (
                  <WatchlistItem
                    data={item}
                    key={item._id}
                    onOpenEditModal={handleOpenEditModal}
                    onOpenDeleteModal={handleOpenDeleteModal}
                    onClick={handleGetWatchlistDetail.bind(this, item)}
                  />
                ));
            }
          })()}
        </WatchlistBodyStyled>
      </WatchlistContainerStyled>
    </>
  );
};

export default Watchlist;

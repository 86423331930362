import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledDATXPoint, StyledWrapper } from './styles';

const DATXPoint = () => {
  const { dataDATXRating } = useSelector((state) => state.DATXRating);
  const { t } = useTranslation(['ratingsScreen']);

  // eslint-disable-next-line no-unused-vars
  const data = [
    {
      name: 'DATX POINT',
      value: 352,
    },

    {
      name: 'Xếp hạng ngành',
      value: '1/12',
    },

    {
      name: 'Xếp hạng trên thị trường',
      value: '1/12',
    },

    {
      name: 'Media',
      value: 'Tốt',
    },

    {
      name: 'Tài chính',
      value: 'Tốt',
    },
  ];

  function upperCaseFirst(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  return (
    <StyledDATXPoint>
      <StyledWrapper>
        <div>
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.datxPoint')}
        </div>
        <div
          style={{
            color:
              dataDATXRating?.datx_point.value > 0
                ? 'var(--light-green-color)'
                : dataDATXRating?.datx_point.value < 0
                ? 'var(--light-red-color)'
                : 'var(--dark-yellow-color)',
          }}
        >
          {dataDATXRating?.datx_point.value}
        </div>
      </StyledWrapper>
      <StyledWrapper>
        <div>
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.sectorName')}
        </div>
        <div>{dataDATXRating?.sector_name.value}</div>
      </StyledWrapper>
      <StyledWrapper>
        <div>
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.sectorRank')}
        </div>
        <div>
          {dataDATXRating?.sector_rank.value}/
          {dataDATXRating?.total_sector_rank.value}
        </div>
      </StyledWrapper>

      <StyledWrapper>
        <div>
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.stockRank')}
        </div>
        <div>
          {dataDATXRating?.stock_rank.value}/
          {dataDATXRating?.total_stock_rank.value}
        </div>
      </StyledWrapper>

      <StyledWrapper>
        <div>
          {' '}
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.media')}
        </div>
        <div
          style={{
            color:
              dataDATXRating?.media.value > 0
                ? 'var(--light-green-color)'
                : dataDATXRating?.media.value < 0
                ? 'var(--light-red-color)'
                : 'var(--dark-yellow-color)',
          }}
        >
          {Number(dataDATXRating?.media.value).toFixed(2)}
        </div>
      </StyledWrapper>

      <StyledWrapper>
        <div>
          {t('stockDetailsSection.tab1.DATXRatingPoint.mainContent.finance')}
        </div>
        <div style={{ color: 'var(--light-green-color)' }}>
          {dataDATXRating?.finance?.value &&
            upperCaseFirst(dataDATXRating?.finance?.value)}
        </div>
      </StyledWrapper>
    </StyledDATXPoint>
  );
};

export default DATXPoint;

import HotNewsIcon from 'assets/icons/HotNewsIcon';
import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  MenuItemStyled,
  MenuStyled,
  PostListStyled,
  SiderbarRightStyled,
  StyledImagePost,
  StyledItemWrapper,
} from './styles';

const SidebarRight = ({ data }) => {
  const { t } = useTranslation('common');
  const { isLoadingSkeletonWatchlistNews } = useSelector(
    (state) => state.watchlist
  );

  const { loadingInit } = useSelector((state) => state.portfolioNews);

  if (!data) return null;

  return (
    <>
      {loadingInit && (
        <PostListStyled>
          {new Array(4).fill(null).map((_, index) => (
            <PostItem.Loading key={index} type="full" />
          ))}
        </PostListStyled>
      )}

      {isLoadingSkeletonWatchlistNews && (
        <PostListStyled>
          {new Array(4).fill(null).map((_, index) => (
            <PostItem.Loading key={index} type="full" />
          ))}
        </PostListStyled>
      )}

      {!isLoadingSkeletonWatchlistNews || !loadingInit ? (
        <SiderbarRightStyled>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <HotNewsIcon />
            <span
              style={{
                fontWeight: 700,
                fontSize: '1.5rem',
                color: 'var(--white-color)',
              }}
            >
              {t('newsBox.newsBoxModal.rightSection')}
            </span>
          </div>
          <MenuStyled>
            {data.map((item, index) => (
              <a key={index} href={item.link} target="_blank" rel="noreferrer">
                <StyledItemWrapper>
                  <StyledImagePost>
                    <img src={item.featured_image} alt="" />
                  </StyledImagePost>
                  <MenuItemStyled> {item.title}</MenuItemStyled>
                </StyledItemWrapper>
              </a>
            ))}
          </MenuStyled>
        </SiderbarRightStyled>
      ) : null}
    </>
  );
};

export default SidebarRight;

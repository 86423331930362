import styled from 'styled-components';

export const StockDetailContainer = styled.div``;

export const StyledTabs = styled.div`
  margin: 0 auto;

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    color: var(--white-color);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 6rem;

    @media only screen and (max-width: 25em) {
      margin: 0 0 0 4rem;
    }
  }

  .ant-tabs-content {
    position: initial;
    width: 100%;
  }

  .ant-tabs-content-holder {
    padding: 0;
    height: 85vh;
    overflow-y: scroll;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--light-blue-color-2);
  }

  .ant-tabs-tab:hover {
    color: var(--light-blue-color-2);
  }

  .ant-tabs-ink-bar {
    background: var(--light-blue-color-2);
  }

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0 auto;
    height: 5.2rem;
  }

  /* .news-by-stock.ant-tabs-tabpane {
    max-height: ${(props) =>
    props.closeAnnouncement
      ? 'calc(100vh - 274px - 21rem) !important'
      : 'calc(100vh - 274px - 27rem) !important'};
    overflow-y: scroll;

    @media only screen and (max-width: 93.75em) {
      max-height: calc(100vh - 14rem) !important;
    }
  } */
`;

export const StyledSectionTitle = styled.div`
  font-weight: 700;
  letter-spacing: -0.408px;
  color: var(--white-color);
  padding-top: 3.2rem;
  padding-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;

  span {
    color: rgb(255, 255, 255, 0.4);
    font-weight: 500;
  }
`;

export const WatchlistModalContainer = styled.div`
  padding: 2rem 2rem 4rem 2rem;

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0.9rem 0;
    border-bottom: 1px solid var(--border-color);
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox + span {
    padding-right: 0;
    padding-left: 0;
    color: var(--white-color);
  }

  span:nth-child(2) {
    position: absolute;
    left: 2rem;
  }

  .ant-checkbox {
    border-radius: 50%;
  }

  .ant-checkbox-inner {
    border-radius: 50%;
    background-color: transparent;
    top: -1.5rem;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--light-blue-color-2);
    border-radius: 50%;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--light-blue-color-2);
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid var(--light-blue-color-2);
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-checkbox {
    left: -4rem;
  }

  .ant-checkbox-checked::after {
    display: none;
  }
`;

export const StyledNumberMiddle = styled.div`
  color: var(--light-green-color);
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;

  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-left: 4px;
  }
`;

export const AddBtn = styled.div`
  padding-top: 1.2rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  color: var(--light-blue-color-2);
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding-right: 1rem;
    font-size: 20px;
  }
`;

export const FormWrapper = styled.div`
  .ant-form-item-label > label {
    position: relative;
    align-items: end;
    font-size: 15px;
    line-height: 22px;
    color: var(--white-color);
  }
`;

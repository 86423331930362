import WatchlistDataTable from 'components/WatchlistScreenComponents/Datatable';
import WatchlistNews from 'components/WatchlistScreenComponents/News';
import Watchlist from 'components/WatchlistScreenComponents/Watchlist/index';
import { watchlist_permission } from 'constants/permission_key';
import {
  closeAnnouncementSelector,
  handleSetFirstTimeRenderApp,
} from 'features/uiHandler/uiHandlerSlice';
import {
  getWatchlistDetail,
  getWatchlists,
  removeWatchlist,
  watchlistDetailSelector,
} from 'features/watchlist/watchlistSlice';
import usePermission from 'hooks/usePermission';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WatchlistScreenContainer } from './styles';

const WatchlistScreen = () => {
  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const [modalVisible, setModalVisible] = useState(false);
  const [watchlistEditting, setWatchlistEditting] = useState(null);
  const [modalType, setModalType] = useState(null); // edit or create
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { data: currentWatchlist } = useSelector(watchlistDetailSelector);
  const { isFirstTimeRender } = useSelector((state) => state.uiHandler);

  const [visible, setVisible] = useState(false);

  const handleOpenDrawer = () => {
    setVisible(true);
  };

  const handleCloseDrawer = () => {
    setVisible(false);
  };

  const [handleOpenEditModal] = usePermission({
    key: watchlist_permission.edit,
    handle: (watchlist) => {
      setModalVisible(true);
      setWatchlistEditting(watchlist);
      setModalType('edit');
      modalType === 'drawer' && handleCloseDrawer();
    },
  });

  const [handleOpenDeleteModal] = usePermission({
    key: watchlist_permission.remove,
    handle: (watchlist) => {
      setWatchlistEditting(watchlist);
      setDeleteModalVisible(true);
      modalType === 'drawer' && handleCloseDrawer();
    },
  });

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
    setWatchlistEditting(null);
  };

  const dispatch = useDispatch();

  const [handleDeleteWatchlist] = usePermission({
    key: watchlist_permission.remove,
    handle: async () => {
      try {
        await dispatch(
          removeWatchlist({ watchlist_id: watchlistEditting._id })
        ).unwrap();

        handleCloseDeleteModal();
        setWatchlistEditting(null);

        const { data: _watchlists } = await dispatch(getWatchlists()).unwrap();

        if (_watchlists.length === 0) {
          dispatch(getWatchlistDetail({ watchlist_id: null }));
        } else if (currentWatchlist._id === watchlistEditting._id) {
          dispatch(getWatchlistDetail({ watchlist_id: _watchlists[0]._id }));
        }
      } catch (error) {
        console.log('removeWatchlist: ', { error });
      }
    },
  });

  useEffect(() => {
    if (isFirstTimeRender) {
      dispatch(handleSetFirstTimeRenderApp(false));
    }
  }, [dispatch, isFirstTimeRender]);

  return (
    <WatchlistScreenContainer closeAnnouncement={closeAnnouncement}>
      <Watchlist
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setWatchlistEditting={setWatchlistEditting}
        watchlistEditting={watchlistEditting}
        setModalType={setModalType}
        modalType={modalType}
        visible={visible}
        setVisible={setVisible}
        onCloseDrawer={handleCloseDrawer}
        onOpenDrawer={handleOpenDrawer}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        deleteModalVisible={deleteModalVisible}
        handleDeleteWatchlist={handleDeleteWatchlist}
      />
      <WatchlistDataTable
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <WatchlistNews />
    </WatchlistScreenContainer>
  );
};

export default WatchlistScreen;

import styled from 'styled-components';

export const SecurityContainer = styled.div`
  display: flex;
  flex-flow: column;
  background: var(--primary-color);
  border-radius: 4px;
`;

export const StyledSecurityHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--white-color);
  margin-bottom: 10px;
`;

export const StyledSecurityContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;

  .ant-switch {
    background-color: var(--border-color) !important;
    &.ant-switch-checked {
      background-color: var(--light-green-color) !important;
      .ant-switch-handle::before {
        background-color: var(--white-color);
      }
    }
  }
`;

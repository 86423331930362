export const Call = {
  BUY: 'buy',
  BUY_MORE: 'buy-more',
  BUY_TEST: 'buy-test',
  SELL: 'sell',
};

export const STATUSES = {
  CREATED: 'created',
  APPROVED: 'approved',
  ORDERED: 'ordered',
};

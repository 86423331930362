import Image from './Image';

const ShowImage = ({ images }) => {
  const show = (image) => {
    return <Image image={image} />;
  };

  return <div className="mx-auto">{images.map(show)}</div>;
};

export default ShowImage;

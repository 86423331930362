import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  loading: false,
  data: [],
};

const { REACT_APP_COPY_TRADE_URL } = process.env;

export const getStocks = createAsyncThunk(
  'stocks/get-list',
  async (__, { rejectWithValue }) => {
    let url = `${REACT_APP_COPY_TRADE_URL}/api/brokerage/stock-companies`;
    const response = await axiosInstance.get(url);

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const slice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
  },
  extraReducers: {
    [getStocks.pending]: (state) => {
      state.loading = true;
    },
    [getStocks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = sortBy(payload.data, 'ticker');
    },
    [getStocks.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const stocksSelector = (state) => state.stocks;

export const { setLoading } = slice.actions;

export const stockReducer = slice.reducer;

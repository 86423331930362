import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: #1c1f2f;
    border-radius: 8px;
    border: 1px solid #363953;
  }

  .ant-modal-header {
    border: 0;
    border-radius: 8px;
    background: #1c1f2f;
    padding: 24px;
  }

  .ant-modal-title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ant-modal-footer {
    border: 0;
    padding: 24px;

    .ant-btn {
      border-radius: 4px;
      min-width: 122px;
    }

    .ant-btn.ant-btn-primary {
      background: #005dfb;
      border-color: #005dfb;
      margin-left: 16px;
    }

    .ant-btn.ant-btn-default {
      border: 1px solid #005dfb;
      background: #151c2b !important;
      color: #005dfb;
    }
  }
`;

export const ERROR = {
  price_must_be_higher_than_the_floor_price:
    'Giá khuyến nghị phải lớn hơn hoặc bằng giá sàn của Mã cổ phiếu!',
  price_must_be_less_than_the_ceiling_price:
    'Giá khuyến nghị phải nhỏ hơn hoặc bằng giá trần của Mã cổ phiếu!',
  unknow_error: 'Có lỗi xảy ra, xin vui lòng thử lại sau!',
  not_found_recommendation_stock:
    'Cổ phiếu nằm ngoài danh mục khuyến nghị của DATX rating!',
  volumne_buy_must_be_less_than_the_remain_vol:
    'Tỷ trọng khuyến nghị MUA vượt quá mức phân bổ (100%)!',
  volumne_sell_must_be_less_than_the_available_vol:
    'Tỷ trọng khuyến nghị BÁN vượt quá tỷ trọng khả dụng!',
  invalid_recommendationStock: 'Invalid recommendation stock!',
  invalid_bot: 'Invalid bot',
  recommendation_approve: 'Khuyến nghị đã tồn tại!',
  invalid_volume: 'Tỷ trọng phải nằm trong khoảng từ 1 đến 100',
};

import { Tabs } from 'antd';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import General from 'components/SettingsScreenComponents/General';
import Password from 'components/SettingsScreenComponents/Password';
import Security from 'components/SettingsScreenComponents/Security';
import { loadingSelector } from 'features/setting/settingSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SettingScreenContainer } from './styles';

const { TabPane } = Tabs;

const SettingScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const loading = useSelector(loadingSelector);

  const { t } = useTranslation(['settingsScreen']);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(
  //       language === "vi"
  //         ? errorMessage.message_translated
  //         : errorMessage.message,
  //       settingsError
  //     );
  //   }
  // }, [error, errorMessage, language]);

  //save current tab to url
  const handleTabsChange = (key) => {
    history.push(`${location.pathname}${key}`);
  };
  //end

  return (
    <SettingScreenContainer>
      {loading && <Loader />}
      <Tabs
        defaultActiveKey={location.hash || '#general'}
        onChange={handleTabsChange}
      >
        <TabPane tab={t('tab1.title')} key="#general">
          <General />
        </TabPane>
        <TabPane tab={t('tab2.title')} key="#password">
          <Password />
        </TabPane>
        <TabPane tab={t('tab3.title')} key="#security">
          <Security />
        </TabPane>
      </Tabs>
    </SettingScreenContainer>
  );
};

export default SettingScreen;

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  overflow-y: scroll;
  padding: 0 1.6rem;
  max-height: ${(props) =>
    props.closeAnnouncement
      ? 'calc(100vh - 27.6rem + var( --announcement-height) - 274px) !important'
      : 'calc(100vh - 27.6rem - 274px) !important'};

  @media only screen and (max-width: 93.75em) {
    max-height: calc(100vh - 8rem) !important;
    overflow-y: scroll;
  }
`;

export const TabItemTitle = styled.div`
  padding-left: 1.6rem;
  padding-bottom: 2rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.408px;
  color: var(--white-color);
`;

export const StyledTopTabItem = styled.div`
  margin: 1.6rem;
  width: calc(100% / 8);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid var(--light-blue-color-2);
    transition: cubic-bezier(0.1, 0.7, 1, 0.1);
  }
`;

export const MarketStatusWrapper = styled.div`
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
`;

export const StyledRightText = styled.div`
  display: flex;
  text-align: center;
  height: 14rem;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #1ed994;
  }

  & > div:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f2ed49;
  }

  & > div:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f2ed49;
  }

  & > div:nth-child(3) {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f24b78;
  }
`;

export const StyledTopTab = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledOverviewSection = styled.div`
  padding-top: 3.2rem;
`;

export const StyledIconGroup = styled.div`
  padding-left: 1.6rem;
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: -0.408px;
    color: var(--light-blue-color-2);
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
`;

export const StyledDATXRatingPointSection = styled.div`
  padding-top: 3.2rem;
`;

export const StyledDATXPointDescription = styled.div`
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
`;

import { Button, Form, Input } from 'antd';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';
import axiosInstance from 'utils/axiosInstance';
import { FormCreateIssueContainer, FormWrapper, StyledTitle } from './styles';

const FormCreateIssue = ({ fetchData, currentPage, setCurrentPage }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;

  const onFinish = (values) => {
    console.log('Success:', values);

    const { REACT_APP_URL } = process.env;

    const submitForm = async (values) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_URL}/api/issues/create`,
          values
        );

        const { data } = response;

        setLoading(false);

        form.resetFields();
        fetchData();
        toast.success('Gửi thành công', settingsSuccess);
        setCurrentPage(1);

        if (!data || !data.status) {
          toast.error(data.message_translated, settingsError);
          throw new Error(JSON.stringify(data));
        }
      } catch (error) {
        setLoading(false);
        console.log({ error });
        toast.error(error, settingsError);
      }
    };

    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <FormCreateIssueContainer>
      {loading && <Loader />}
      <StyledTitle>Báo cáo vấn đề</StyledTitle>

      <FormWrapper>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tiêu đề!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nội dung"
            name="content"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập nội dung!',
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Gửi
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </FormCreateIssueContainer>
  );
};

export default FormCreateIssue;

import styled from 'styled-components';

export const StyledOverviewSection = styled.div`
  .react-stockchart {
    margin-left: 0 !important;
  }
`;

export const StyledIconGroup = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: -0.408px;
    color: var(--light-blue-color-2);
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
`;

export const StyledTopTab = styled.div`
  display: flex;
  justify-content: space-between;
  height: 5.6rem;

  &.chart-modal {
    @media only screen and (max-width: 30em) {
      padding: 0 2rem !important;
    }
  }
`;

export const StyledTopTabItem = styled.div`
  margin: 1.6rem 0;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  &.active {
    border-bottom: 2px solid var(--light-blue-color-2);
    transition: cubic-bezier(0.1, 0.7, 1, 0.1);
  }
`;

export const StyledHeaderIcon = styled.div`
  cursor: pointer;
`;

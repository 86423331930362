import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 16px;

  .ant-btn {
    height: 40px;
    margin-right: 10px;
  }

  .ant-segmented-item-label {
    color: #545454;
  }

  .ant-select {
    height: 36px;
    background: #21253b;
    border-radius: 4px;
    overflow: hidden;
  }

  .ant-select-selector {
    height: 100% !important;
    line-height: 36px !important;
    margin-top: 3px;
  }

  .ant-select-selection-item {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    padding: 0 16px !important;
    display: flex;
    align-items: center;
  }

  .ant-btn {
    border: none !important;
  }
  .ant-btn-primary {
    background: #f24b78 !important;
  }

  .sessions {
    margin-left: 40px;
  }
`;

export const FilterTab = styled.div`
  display: flex;
`;

export const FilterTabItem = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #545454;
  margin-right: 30px;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &.active {
    color: #ffffff;
  }
`;

export const portfolio_permission = {
  create: 'portfolio.create',
  edit: 'portfolio.edit',
  list: 'portfolio.list',
  detail: 'portfolio.detail',
  remove: 'portfolio.remove',
  summary: 'portfolio.summary',
  allocation: {
    stock: 'portfolio.allocation',
    index: 'portfolio.allocation.index',
    sector: 'portfolio.allocation.sector',
  },
  transactions: 'portfolio.transactions',
  orders: 'portfolio.orders',
  news: 'portfolio.news',
  changepass: 'portfolio.changepass',
  changepin: 'portfolio.changepin',
  broker_authentication: 'portfolio.broker_authentication',
  stock_company: 'portfolio.stock_company',
};

export const watchlist_permission = {
  news: 'watchlist.news',
  create: 'watchlist.create',
  edit: 'watchlist.edit',
  remove: 'watchlist.remove',
  list: 'watchlist.list',
  detail: 'watchlist.detail',
};

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { Select, Typography, Button } from 'antd';
import { getBSCData, getBSCLink } from 'utils/api';
import { StockConnectModalStyled, StyledInput } from './styled';

import BSCLogo from 'assets/images/bsc_logo.svg';
import VIXLogo from 'assets/images/vix_logo.svg';

import Cookies from 'js-cookie';
import { Radio, notification } from 'antd';
import BSCConnect from './Bsc';
import CTSConnect from './Cts';
import VIXConnect from './Vix';
import Connected from './Connected';
import { useMutation } from '@tanstack/react-query';
import {
  CredentialType,
  TypeSend,
  getConnectList,
  linkStockAccount,
} from 'utils/stock-management';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { COOKIE_OPTIONS } from 'constants/commons';
import { StyledSelect } from 'components/GlobalComponents/styled';
import { settingsError, settingsSuccess } from 'utils';
import { toast } from 'react-toastify';

const PageType = {
  FIRST: 'first',
  GUIDE_OPEN: 'guide_open',
  CONNECT: 'connect',
  CONNECTED: 'connected',
  CTS_WARNING: 'cts_warning',
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const StockConnectModal = (
  { onSuccess, typeModal = 'X-AI Trading', leaderId = '' },
  ref
) => {
  let query = useQuery();
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showOptionConnect, setShowOptionConnect] = useState(false);
  const [connectType, selectConnectType] = useState('exists'); // new, exists
  const [connectBSCType, selectConnectBSCType] = useState(
    CredentialType.CREDENTIAL
  );
  const [stockCompany, selectStockCompany] = useState(''); // BSC, VIX, CTS
  const [reconnectAccount, setReconnectAccount] = useState('');
  const [page, setPage] = useState(PageType.FIRST);

  const { mutateAsync: getLink, isLoading } = useMutation(getBSCLink, {
    onSuccess: ({ data }) => {
      console.log('getBSCLink data', data);
      if (typeModal === 'X-Copy') {
        Cookies.set('sso_copytrade', leaderId, COOKIE_OPTIONS);
      } else {
        Cookies.set(
          'sso_autotrade',
          reconnectAccount || String(showOptionConnect),
          COOKIE_OPTIONS
        );
      }
      window.location.replace(data);
    },
  });

  const { mutateAsync: getData, isLoading: isLoadingGetData } = useMutation(
    getBSCData,
    {
      onSuccess: async ({ data }) => {
        console.log('getBSCData data', data);
        if (data.access_token) {
          const haveSSOCopytrade = Cookies.get('sso_copytrade');
          const showOptions = Cookies.get('sso_autotrade');
          try {
            await submitConnect({
              type: TypeSend.BSC,
              params: {
                authType: CredentialType.OAUTH,
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
                isUpdateSession:
                  showOptions !== 'true' && showOptions !== 'false'
                    ? true
                    : false,
              },
            });
            toast.success(
              'Bạn đã kết nối tài khoản BSC thành công!',
              settingsSuccess
            );
            if (showOptions === 'true' || haveSSOCopytrade) {
              setPage(PageType.CONNECTED);
            } else if (onSuccess) {
              onSuccess();
              handleCancel();
            }
            Cookies.remove('sso_autotrade', COOKIE_OPTIONS);
            Cookies.remove('sso_copytrade', COOKIE_OPTIONS);
          } catch (error) {
            toast.error('Kết nối tài khoản lỗi!', settingsError);
          }
        }
        if (data.error_description) {
          toast.error('Đăng nhập tài khoản BSC lỗi!', settingsError);
        }
      },
    }
  );

  const { mutateAsync: submitConnect, isLoading: isLoadingSubmit } =
    useMutation(linkStockAccount);

  const handleCancel = () => {
    setIsOpenModal(false);
    setPage(PageType.FIRST);
    selectConnectType('exists');
    selectStockCompany('');
    setReconnectAccount('');
  };

  const onFinishConnect = (params) => {
    handleCancel();
    if (onSuccess) {
      onSuccess(params);
    }
  };

  const onNextFirst = () => {
    if (showOptionConnect && connectType === 'new' && stockCompany === 'CTS') {
      setPage(PageType.CTS_WARNING);
    } else if (connectType === 'exists') {
      setPage(PageType.CONNECTED);
    } else if (
      stockCompany === 'BSC' &&
      connectBSCType === CredentialType.OAUTH
    ) {
      getLink();
    } else {
      setPage(PageType.CONNECT);
    }
  };

  useImperativeHandle(ref, () => ({
    setModalState: async ({
      openModal,
      showOption = false,
      reconnect = '',
    }) => {
      setIsOpenModal(openModal);
      setShowOptionConnect(showOption);
      if (reconnect) {
        const splitQuery = reconnect.split('_');
        if (splitQuery.length > 2) {
          const listStockConnected = await getConnectList();

          if (listStockConnected && listStockConnected.length) {
            const expiredAccount = listStockConnected.find(
              (i) =>
                i.isSessionExpired &&
                i.username === reconnect.split(`${splitQuery[0]}_`)[1]
            );
            if (expiredAccount && expiredAccount.bscLinkType === 'SSO') {
              selectStockCompany(splitQuery[0]);
              setReconnectAccount(splitQuery[splitQuery.length - 1]);
              setShowOptionConnect(false);
              selectConnectBSCType(CredentialType.OAUTH);
            } else {
              selectStockCompany(splitQuery[0]);
              setReconnectAccount(splitQuery[splitQuery.length - 1]);
              setPage(PageType.CONNECT);
            }
          } else {
            selectStockCompany(splitQuery[0]);
            setReconnectAccount(splitQuery[splitQuery.length - 1]);
            setPage(PageType.CONNECT);
          }
        }
      }
    },
  }));

  useEffect(() => {
    if (query?.code) {
      selectConnectBSCType(CredentialType.OAUTH);
      selectStockCompany('BSC');
      getData(String(query?.code));
    }
  }, []);

  return (
    <StockConnectModalStyled
      className="custom-modal"
      footer={null}
      open={isOpenModal}
      onCancel={handleCancel}
      destroyOnClose
      centered
      closable={false}
      maskClosable={false}
    >
      <div className="p-6">
        {page === PageType.FIRST && (
          <div className="flex flex-col gap-6">
            <Typography.Text className="font-bold text-xl">
              Kết nối tài khoản chứng khoán
            </Typography.Text>
            <Typography.Text className="font-normal !text-custom-gray300">
              Kết nối tài khoản chứng khoán của bạn để hoàn tất trở thành leader
            </Typography.Text>

            {/* {showOptionConnect && (
              <Radio.Group
                onChange={(e) => selectConnectType(e.target.value)}
                value={connectType}
              >
                <Radio value={'new'}>Kết nối mới</Radio>
                <Radio value={'exists'}>Đã kết nối</Radio>
              </Radio.Group>
            )} */}

            <StyledInput>
              <Typography.Text className="text-[14px] mb-3">
                Chọn CTCK
              </Typography.Text>
              <StyledSelect
                value={stockCompany}
                style={{ width: '100%' }}
                options={[
                  { value: 'BSC', label: 'BSC' },
                  { value: 'VIX', label: 'VIX' },
                  { value: 'CTS', label: 'CTS' },
                  { value: 'DNSE', label: 'DNSE' },
                ]}
                onChange={(e) => selectStockCompany(String(e))}
                disabled={!!reconnectAccount}
              />
            </StyledInput>

            {stockCompany === 'BSC' &&
              connectType === 'new' &&
              !reconnectAccount && (
                <div className="flex flex-col">
                  <Typography.Text className="text-[14px] mb-2">
                    Chọn hình thức đăng nhập
                  </Typography.Text>
                  <Radio.Group
                    onChange={(e) => selectConnectBSCType(e.target.value)}
                    value={connectBSCType}
                  >
                    <Radio value={CredentialType.CREDENTIAL}>PIN</Radio>
                    <Radio value={CredentialType.OAUTH}>SmartOTP</Radio>
                  </Radio.Group>
                </div>
              )}

            <div className="flex justify-end gap-2 mb-2">
              <Button
                ghost
                type="primary"
                className="!m-0 !mr-2 !border-none !bg-custom-darkBlue100 px-8 rounded-lg"
                onClick={handleCancel}
              >
                <span className="!text-custom-blue500">Hủy</span>
              </Button>
              <Button
                disabled={
                  !stockCompany ||
                  isLoading ||
                  isLoadingGetData ||
                  isLoadingSubmit
                }
                loading={isLoading || isLoadingGetData || isLoadingSubmit}
                type="primary"
                className="!m-0 !text-white !bg-custom-blue500 px-8 !rounded-lg !border-none"
                onClick={onNextFirst}
              >
                Tiếp tục
              </Button>
            </div>

            <Typography.Text level={3} className="text-gray300">
              Bạn chưa có TKCK đối tác của DATX?
              <Typography.Text
                className="!text-custom-blue500 ml-2 font-semibold cursor-pointer inline-block"
                onClick={() => setPage(PageType.GUIDE_OPEN)}
              >
                Mở tại đây.
              </Typography.Text>
            </Typography.Text>
          </div>
        )}

        {page === PageType.GUIDE_OPEN && (
          <div className="flex flex-col gap-5 p-2">
            <Typography.Text className="font-bold text-xl">
              Mở tài khoản chứng khoán
            </Typography.Text>
            <Typography.Text className="font-normal !text-custom-gray300">
              Chọn công ty chứng khoán bạn muốn mở tài khoản
            </Typography.Text>
            <div className="flex flex-col gap-[16px] w-full">
              <div
                className="bg-[#262A3E] text-gray150 px-[16px] py-[12px] rounded-[12px] flex items-center gap-3 cursor-pointer"
                onClick={() =>
                  window.open(
                    'https://dangky.bsc.com.vn/home/OpenAccountPartner?refid=95080006028',
                    '_blank'
                  )
                }
              >
                <span className="bg-white p-[4px] rounded-[8px]">
                  <img
                    className="cursor-pointer"
                    src={BSCLogo}
                    alt="BSC Logo"
                  />
                </span>
                <span>
                  <b>(BSC)</b> Công ty cổ phần chứng khoán BIDV
                </span>
              </div>
              <div
                className="bg-[#262A3E] text-gray150 px-[16px] py-[12px] rounded-[12px] flex items-center gap-3 cursor-pointer"
                onClick={() =>
                  window.open(
                    'https://online.vixs.vn/mo-tai-khoan/?c=AI-broker',
                    '_blank'
                  )
                }
              >
                <span className="bg-white p-[4px] rounded-[8px]">
                  <img
                    className="cursor-pointer"
                    src={VIXLogo}
                    alt="VIX Logo"
                  />
                </span>
                <span>
                  <b>(VIX)</b> Công ty Cổ phần Chứng khoán VIX
                </span>
              </div>
            </div>

            <div className="flex justify-end gap-2 mb-2">
              <Button
                ghost
                type="primary"
                className="!m-0 !mr-2 border-blue700 !bg-darkBlue100 px-8"
                onClick={() => setPage(PageType.FIRST)}
              >
                Quay lại
              </Button>
            </div>
          </div>
        )}

        {page === PageType.CTS_WARNING && (
          <div className="flex flex-col gap-6 p-2">
            <Typography.Text level={4} className="font-bold">
              <WarningOutlined style={{ color: '#FFCD1D', marginRight: 10 }} />
              Chưa kết nối tài khoản chứng khoán
            </Typography.Text>
            <Typography.Text className="text-gray-400">
              Để đăng ký X-AI trading sử dụng tài khoản chứng khoán CTS vui lòng
              thực hiện các bước sau:
            </Typography.Text>
            <div>
              <Typography.Text className="text-gray-400">
                1. Kết nối tài khoản chứng khoán CTS
              </Typography.Text>
              <Typography.Text className="text-gray-400">
                2. Hoàn thiện hồ sơ đăng ký API với CTS và xác thực kết nối, xem
                hướng dẫn{' '}
                <a
                  className="text-blue700"
                  href="https://datx.vn/huong-dan-su-dung/xwealth-faq-pc/cts-otp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tại đây
                </a>
                .
              </Typography.Text>
              <Typography.Text className="text-gray-400">
                3. Đăng ký X-AI trading với tài khoản CTS đã kết nối
              </Typography.Text>
            </div>

            <div className="flex justify-end gap-2 mb-2">
              <Button
                ghost
                type="primary"
                className="!m-0 !mr-2 !border-none !bg-custom-darkBlue100 px-8 rounded-lg"
                onClick={handleCancel}
              >
                <span className="!text-custom-blue500">Hủy</span>
              </Button>
              <Button
                type="primary"
                className="!m-0 !text-white !bg-custom-blue500 px-8 !rounded-lg !border-none"
                onClick={() => {
                  handleCancel();
                  history.push('/settings');
                }}
              >
                Kết nối
              </Button>
            </div>
          </div>
        )}

        {page === PageType.CONNECT && stockCompany === 'BSC' && (
          <BSCConnect
            handleCancel={handleCancel}
            onSuccess={onFinishConnect}
            nextStep={showOptionConnect}
            reAcc={reconnectAccount}
          />
        )}
        {page === PageType.CONNECT && stockCompany === 'CTS' && (
          <CTSConnect
            handleCancel={handleCancel}
            onSuccess={onFinishConnect}
            nextStep={showOptionConnect}
            reAcc={reconnectAccount}
          />
        )}
        {page === PageType.CONNECT && stockCompany === 'VIX' && (
          <VIXConnect
            handleCancel={handleCancel}
            onSuccess={onFinishConnect}
            nextStep={showOptionConnect}
            reAcc={reconnectAccount}
          />
        )}
        {page === PageType.CONNECTED && (
          <Connected
            handleCancel={handleCancel}
            company={stockCompany}
            onSuccess={onFinishConnect}
            typeModal={typeModal}
          />
        )}
      </div>
    </StockConnectModalStyled>
  );
};

export default forwardRef(StockConnectModal);

import styled from 'styled-components';

export const FormCreateIssueContainer = styled.div`
  background: var(--primary-color);
`;

export const FormWrapper = styled.div`
  margin: 2rem;
  margin-top: 4rem;

  label {
    color: var(--white-color);
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }

  .ant-input {
    height: 4.4rem;
  }

  .ant-btn.ant-btn-primary {
    height: 4.4rem;
    background: var(--light-blue-color);
  }

  .ant-row.ant-form-item {
    margin-top: 2rem;
  }

  textarea.ant-input {
    height: fit-content;
  }
`;

export const StyledTitle = styled.div`
  background: var(--section-header-color);
  padding: 2rem;
  font-weight: 700;
`;

import React, { useContext, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { TradingOrderContext } from './TradingOrderContext';
import clsx from 'clsx';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #1c1f2f;
  border-radius: 0.5rem;
  height: calc(100vh - 238px);
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const TradingView = ({ className = '' }, ref) => {
  const { symbol } = useContext(TradingOrderContext);

  useImperativeHandle(ref, () => ({
    open: (symbol) => {},
  }));

  return (
    <Wrapper className={clsx('rounded', className)}>
      {symbol && (
        <iframe
          title={`TradingView for ${symbol}`}
          src={`${process.env.REACT_APP_TRADING_VIEW_URL}recommendation?symbol=${symbol}&recommendationType=short&hide-fullscreen=true`}
        />
      )}
    </Wrapper>
  );
};

export default React.forwardRef(TradingView);

/* -------------------------------------------------------------------------- */
/*                            RECOMMENDED CASH RATE                           */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  cashPercent: 0,
};

const { REACT_APP_URL } = process.env;

export const getRecommendedCashRate = createAsyncThunk(
  'recommendedCashRate/getRecommendedCashRate',

  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/recommend-rate`
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const recommendedCashRate = createSlice({
  name: 'recommendedCashRate',
  initialState,
  reducers: {},
  extraReducers: {
    [getRecommendedCashRate.pending]: (state) => {
      state.loading = true;
    },
    [getRecommendedCashRate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cashPercent = payload?.data.cash_percent;
    },
    [getRecommendedCashRate.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const recommendedCashRateReducer = recommendedCashRate.reducer;

import React from 'react';

const RatingsIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ratings-icon"
      >
        <path
          d="M18 6C18 5.05719 18 4.58579 18.2929 4.29289C18.5858 4 19.0572 4 20 4C20.9428 4 21.4142 4 21.7071 4.29289C22 4.58579 22 5.05719 22 6V18C22 18.9428 22 19.4142 21.7071 19.7071C21.4142 20 20.9428 20 20 20C19.0572 20 18.5858 20 18.2929 19.7071C18 19.4142 18 18.9428 18 18V6Z"
          fill="var(--white-color)"
          stroke="var(--white-color)"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M2 14C2 13.0572 2 12.5858 2.29289 12.2929C2.58579 12 3.05719 12 4 12C4.94281 12 5.41421 12 5.70711 12.2929C6 12.5858 6 13.0572 6 14V18C6 18.9428 6 19.4142 5.70711 19.7071C5.41421 20 4.94281 20 4 20C3.05719 20 2.58579 20 2.29289 19.7071C2 19.4142 2 18.9428 2 18V14Z"
          fill="var(--white-color)"
          stroke="var(--white-color)"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M10 10C10 9.05719 10 8.58579 10.2929 8.29289C10.5858 8 11.0572 8 12 8C12.9428 8 13.4142 8 13.7071 8.29289C14 8.58579 14 9.05719 14 10V18C14 18.9428 14 19.4142 13.7071 19.7071C13.4142 20 12.9428 20 12 20C11.0572 20 10.5858 20 10.2929 19.7071C10 19.4142 10 18.9428 10 18V10Z"
          fill="var(--white-color)"
          stroke="var(--white-color)"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default RatingsIcon;

import { useDropzone } from 'react-dropzone';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#6B697A';
};

const Container = styled.div`
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  transition: border 0.24s ease-in-out;
`;

function DropBox({
  onDrop,
  text = 'Tải ảnh lên',
  icon = <CameraAltIcon className="mt-3 text-custom-darkBlue200 !w-12 !h-12" />,
  fileName = '',
  accept = 'image/*',
}) {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 20971520,
  });

  return (
    <>
      <section>
        <Container
          className="mx-auto w-full h-full max-w-[400px] max-h-[400px] text-center flex flex-col justify-center items-center bg-transparent rounded-xl"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          <input {...getInputProps()} />
          <div
            className="flex flex-col items-center text-custom-darkBlue200 cursor-pointer"
            onClick={open}
          >
            {icon}
            {text}
          </div>
          <p className="text-white mt-2">{fileName}</p>
          <p className="text-custom-darkBlue200 mt-2">
            Hoặc kéo thả vào khu vực này
          </p>
        </Container>
      </section>
    </>
  );
}

export default DropBox;

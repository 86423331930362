import { WatchlistWrapperStyled } from './styles';
import Watchlist from './Watchlist';
import WatchlistDrawer from './WatchlistDrawer';

const Watchlists = ({
  modalVisible,
  setModalVisible,
  setWatchlistEditting,
  watchlistEditting,
  setModalType,
  modalType,
  visible,
  setVisible,
  onCloseDrawer,
  onOpenDrawer,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handleCloseDeleteModal,
  deleteModalVisible,
  handleDeleteWatchlist,
}) => {
  return (
    <WatchlistWrapperStyled>
      <Watchlist
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setWatchlistEditting={setWatchlistEditting}
        watchlistEditting={watchlistEditting}
        setModalType={setModalType}
        modalType={modalType}
        onOpenDrawer={onOpenDrawer}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        deleteModalVisible={deleteModalVisible}
        handleDeleteWatchlist={handleDeleteWatchlist}
      />
      <WatchlistDrawer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setWatchlistEditting={setWatchlistEditting}
        watchlistEditting={watchlistEditting}
        setModalType={setModalType}
        modalType={modalType}
        visible={visible}
        setVisible={setVisible}
        onOpenDrawer={onOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        deleteModalVisible={deleteModalVisible}
        handleDeleteWatchlist={handleDeleteWatchlist}
      />
    </WatchlistWrapperStyled>
  );
};

export default Watchlists;

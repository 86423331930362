import styled from 'styled-components';

export const SpinContainer = styled.div`
  text-align: center;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  padding: 3rem 5rem;
  margin: 2rem 0;
  left: 50%;
  top: 50%;
`;

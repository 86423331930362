/* -------------------------------------------------------------------------- */
/*                             STOCK DETAIL SLICE                             */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { defaultPagination } from 'features/portfolio/portfolioNewsSlice';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  data: [],
  dataOverview: [],
  currentSelectedStock: '',
  dataNews: [],
  pagination: defaultPagination,
};

const { REACT_APP_URL } = process.env;

export const getStockDetailsOverview = createAsyncThunk(
  'stockDetails/getStockDetailsOverview',

  async ({ stockCode, timeDistance }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stock-detail/overview`,
        {
          stock_code: stockCode,
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStockDetails = createAsyncThunk(
  'stockDetails/getStockDetails',

  async (stockCode, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stock-detail`,
        {
          stock_code: stockCode,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewsByStock = createAsyncThunk(
  'stockDetails/getNewsByStock',

  async (
    { stockCode, page, pagination = defaultPagination },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stock-news`,
        {
          stock_code: stockCode,
          limit: 10,
          page: page,
          ...pagination,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return {
        data: data.data,
        pagination: {
          ...pagination,
          isNext: data.data.length >= pagination.limit,
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStockDetailsSlice = createSlice({
  name: 'stockDetails',
  initialState,
  reducers: {
    handleSelectStockCode: (state, { payload }) => {
      state.currentSelectedStock = payload;
      state.isSelected = true;
    },

    resetStateStockNews: (state) => {
      state.loading = true;
      state.dataNews = [];
      state.pagination = defaultPagination;
    },
  },
  extraReducers: {
    [getStockDetails.pending]: (state) => {
      state.loadingStockDetails = true;
    },
    [getStockDetails.fulfilled]: (state, { payload }) => {
      state.loadingStockDetails = false;
      state.data = payload?.data;
    },
    [getStockDetails.rejected]: (state) => {
      state.loadingStockDetails = false;
    },

    [getStockDetailsOverview.pending]: (state) => {
      state.loadingChart = true;
    },
    [getStockDetailsOverview.fulfilled]: (state, { payload }) => {
      state.loadingChart = false;
      state.dataOverview = payload?.data;
    },
    [getStockDetailsOverview.rejected]: (state) => {
      state.loadingChart = false;
    },

    [getNewsByStock.pending]: (state) => {
      state.loading = true;
    },
    [getNewsByStock.fulfilled]: (state, { payload }) => {
      state.dataNews = [...state.dataNews, ...payload?.data];
      state.pagination = payload.pagination;
      state.loading = false;
    },
    [getNewsByStock.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { handleSelectStockCode, resetStateStockNews } =
  getStockDetailsSlice.actions;

export const stockDetailsReducer = getStockDetailsSlice.reducer;

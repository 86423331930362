import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import {
  Container,
  Heading,
  Actions,
  PreviewSection,
  PreviewHeading,
  PreviewContent,
  StyledForm,
} from './styles';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { hsxCompanyList } from 'data/hsxCompanyList';
import EyeIcon from 'assets/icons/EyeIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import moment from 'moment';
import { Call } from 'constants/broker';
import { removeNumericFormat } from 'utils';
import axiosInstance from 'utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFiles } from 'features/recommendation/customerFileSlice';

const AddEditRecommendation = ({
  data,
  visible,
  loading,
  sending,
  onCancel,
  onOk,
}) => {
  const dispatch = useDispatch();
  const { customerFiles } = useSelector((state) => state.customerFile);
  const { t } = useTranslation(['recommendationsScreen']);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    symbol: '',
    tradingType: '',
    price: null,
    proportion: null,
    comment: '',
    customerSetIds: [],
  });
  const [forecastHold, setForecastHold] = useState(0);
  const [prices, setPrices] = useState({
    reference: null,
    ceiling: null,
    floor: null,
  });

  const formRule = {
    required: {
      required: true,
      message: t('recommendationForm.requiredMessage'),
    },
  };

  const validatePercentage = () => ({
    validator(_, value) {
      if (Number(value) < 1 || Number(value) > 100) {
        return Promise.reject(
          t('recommendationForm.numberErrorMessage', {
            min: 1,
            max: 100,
          })
        );
      }
      return Promise.resolve();
    },
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    form.resetFields();
  };

  const calculateForecastHold = async (values) => {
    if (values.symbol && values.tradingType && values.proportion) {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/calculate-percentage-hold`,
          {
            symbol: values.symbol,
            tradingType: values.tradingType,
            percentageProportion: values.proportion,
          }
        );
        setForecastHold(response.data.data?.percentageHold);
      } catch (e) {
        console.log(e);
      }
    } else {
      setForecastHold(null);
    }
  };

  const handleFormValuesChange = (_, allValue) => {
    setFormData((state) => ({ ...state, ...allValue }));
    calculateForecastHold(allValue);
  };

  const onSubmit = (values) => {
    if (sending) {
      if (onOk)
        onOk({
          key: data.key,
          type: data.type,
          symbol: data.symbol,
          tradingType: data.tradingType,
          price: data.price,
          proportion: data.proportion,
          comment: values.comment,
          customerSetIds: values.customerSetIds,
        });
    } else {
      values.price = removeNumericFormat(values.price);
      values.proportion = Number(values.proportion);
      if (data) {
        values.key = data.key;
        values.type = data.type;
      }
      if (onOk) onOk(values);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
      setFormData({ ...data });
      setForecastHold(data.hold);
    } else {
      setFormData({
        symbol: '',
        tradingType: '',
        price: null,
        proportion: null,
        comment: '',
        customerSetIds: [],
      });
      setForecastHold(null);
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      getCustomerFiles({
        page: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (formData.symbol) {
        // const priceResponse = await axiosInstance.post(`${process.env.REACT_APP_PORTFOLIO_API_V2_URL}/hsx/stocks/intradayprice/by-codes`, {
        //   startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        //   endDate: moment().format('YYYY-MM-DD'),
        //   codes: [formData.symbol]
        // });
        // const price = priceResponse.data.stockIntradayPrice?.[formData.symbol.toLowerCase()]?.[0];
        // if (price) {
        //   const ceiling = ((Number(price.Open) * (100 + 7)) / 100);
        //   const floor = (Number(price.Open) * (100 - 7)) / 100;
        //   const reference = price.Open;
        //   setPrices({
        //     reference: reference.toFixed(2),
        //     ceiling: ceiling.toFixed(2),
        //     floor: floor.toFixed(2),
        //   });
        // }
        const priceResponse = await axiosInstance.get(
          `${process.env.REACT_APP_PORTFOLIO_API_V2_URL}/hsx/vision/rating/ssi/${formData.symbol}/stock-prices`
        );
        console.log('priceResponse', priceResponse.data.stocks?.[0]);
        const price = priceResponse.data.stocks?.[0];
        if (price) {
          const ceiling = price.Ceiling;
          const floor = price.Floor;
          const reference = price.Reference;
          setPrices({
            reference: reference.toFixed(2),
            ceiling: ceiling.toFixed(2),
            floor: floor.toFixed(2),
          });
        } else {
          setPrices({
            reference: 0,
            ceiling: 0,
            floor: 0,
          });
        }
      }
    })();
  }, [formData.symbol]);

  return (
    <Modal
      centered
      destroyOnClose={true}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="105rem"
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
      bodyStyle={{ background: '#1C1F2F', borderRadius: 18 }}
    >
      <Container>
        <Heading>
          {data
            ? sending
              ? t('sendRecommendation')
              : t('editRecommendation')
            : t('createRecommendation')}
        </Heading>
        <StyledForm
          onValuesChange={handleFormValuesChange}
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          name="createRecommendation"
          scrollToFirstError
        >
          <Row gutter={20}>
            {!sending && (
              <Col span={12}>
                <Form.Item
                  name="symbol"
                  label={<label>{t('recommendationForm.symbol')}</label>}
                  rules={[formRule.required]}
                >
                  <Select showSearch disabled={data ? true : false}>
                    {hsxCompanyList.map((company) => (
                      <Select.Option key={company.Code} value={company.Code}>
                        {company.Code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!sending && (
              <Col span={12}>
                <Form.Item
                  name="tradingType"
                  label={<label>{t('recommendationForm.call')}</label>}
                  rules={[formRule.required]}
                >
                  <Select disabled={data ? true : false}>
                    {[
                      { label: t('buyMore'), value: Call.BUY },
                      { label: t('buyTest'), value: Call.BUY_TEST },
                      { label: t('sell'), value: Call.SELL },
                    ].map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!sending && (
              <>
                <Col span={6}>
                  <Form.Item
                    name="price"
                    label={<label>{t('recommendationForm.price')}</label>}
                    rules={[formRule.required]}
                  >
                    <NumericFormat
                      customInput={Input}
                      decimalSeparator="."
                      disabled={data ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label={<label>{t('recommendationForm.reference')}</label>}
                  >
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      decimalSeparator="."
                      disabled={true}
                      value={prices.reference}
                      className="reference-price text-center"
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label={<label>{t('recommendationForm.ceiling')}</label>}
                  >
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      decimalSeparator="."
                      disabled={true}
                      value={prices.ceiling}
                      className="ceiling-price text-center"
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label={<label>{t('recommendationForm.floor')}</label>}
                  >
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      decimalSeparator="."
                      disabled={true}
                      value={prices.floor}
                      className="floor-price text-center"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {!sending && (
              <Col span={9}>
                <Form.Item
                  name="proportion"
                  label={<label>{t('recommendationForm.proportion')}</label>}
                  rules={[formRule.required, validatePercentage]}
                >
                  <NumericFormat customInput={Input} addonAfter="%" />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="customerSetIds"
                label={
                  <label>{t('recommendationForm.sendToCustomerSets')}</label>
                }
                rules={[formRule.required]}
              >
                <Select mode="multiple" allowClear style={{ width: '100%' }}>
                  {customerFiles.map((customerFile) => (
                    <Select.Option value={customerFile._id}>
                      {customerFile.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="comment"
                label={<label>{t('recommendationForm.comment')}</label>}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>

          <PreviewSection>
            <PreviewHeading>
              <EyeIcon />
              <span>{t('recommendationForm.preview')}</span>
            </PreviewHeading>
            <PreviewContent>
              <div>{moment().format('HH:mm DD-MM')}</div>
              <div>
                <strong>{formData.symbol}</strong>
              </div>
              <div>
                &#128201; &nbsp;
                {t('recommendationForm.price')}:
                {formData.price && (
                  <>
                    &nbsp;
                    {formData.price && (
                      <strong>{Number(formData.price).toFixed(2)}</strong>
                    )}
                  </>
                )}
              </div>
              <div>
                &#128176; &nbsp;
                {formData.tradingType === Call.BUY && `${t('buyMore')}:`}
                {formData.tradingType === Call.BUY_TEST && `${t('buyTest')}:`}
                {formData.tradingType === Call.SELL && `${t('sell')}:`}
                &nbsp;
                {formData.proportion && <strong>{formData.proportion}%</strong>}
              </div>
              <div>
                {t('recommendationForm.forecastHold')}: &nbsp;
                {forecastHold && <strong>{forecastHold}%</strong>}
              </div>
              <div>{formData.comment}</div>
            </PreviewContent>
            <Form.Item
              name="inforgraphic"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox>{t('recommendationForm.inforgraphic')}</Checkbox>
            </Form.Item>
          </PreviewSection>
        </StyledForm>
        <Row>
          <Actions>
            <Button onClick={handleCancel}>
              {t('recommendationForm.cancel')}
            </Button>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => form.submit()}
            >
              {sending
                ? t('recommendationForm.send')
                : t('recommendationForm.save')}
            </Button>
          </Actions>
        </Row>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(AddEditRecommendation);

import styled from 'styled-components';

export const SpinContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100000000000000;
  background: var(--loader-mask);
  display: flex;
  align-items: center;
  width: 100% !important;

  .ant-spin.ant-spin-lg.ant-spin-spinning {
    position: absolute;
    z-index: 1000000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

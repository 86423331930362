import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import queryString from 'query-string';
import axiosInstance from 'utils/axiosInstance';

let controller;

const { query } = queryString.parseUrl(window.location.href, {
  parseNumbers: true,
});

const initialState = {
  loading: false,
  recommendations: [],
  page: 1,
  limit: 20,
  total: 0,
  sortBy: query.sortBy || 'new',
  search: query.search || '',
  watchListId: query.watchListId || 'all',
  session: query.session || 'today',
};

const { REACT_APP_RECOMMENDATION_API_URL, REACT_APP_PORTFOLIO_API_V2_URL } =
  process.env;

export const getRecommendations = createAsyncThunk(
  'recommendation/get-recommendations',
  async (
    { search, page, limit, sortBy, watchListId, session, polling },
    { rejectWithValue, getState }
  ) => {
    const state = getState();
    const finalPage = page || state.recommendation.page;
    const finalLimit = limit || state.recommendation.limit;
    const finalSortBy = sortBy || state.recommendation.sortBy;
    const finalWatchListId = watchListId || state.recommendation.watchListId;
    const finalSession = session || state.recommendation.session;

    controller && controller.abort();
    controller = new AbortController();

    console.time('getRecommendations');

    let url = `${REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock?page=${finalPage}&limit=${finalLimit}&sortBy=${finalSortBy}`;
    if (search) {
      url = url + `&search=${search.toUpperCase()}`;
    }
    if (finalWatchListId && finalWatchListId !== 'all') {
      url = url + `&watchListId=${finalWatchListId}`;
    }
    if (finalSession === 'today') {
      url = url + `&startDate=${moment().format('DD-MM-YYYY')}`;
    } else if (finalSession === '3d') {
      url =
        url + `&startDate=${moment().subtract(3, 'd').format('DD-MM-YYYY')}`;
    } else if (finalSession === '1w') {
      url =
        url + `&startDate=${moment().subtract(1, 'w').format('DD-MM-YYYY')}`;
    } else if (finalSession === '1m') {
      url =
        url + `&startDate=${moment().subtract(1, 'M').format('DD-MM-YYYY')}`;
    } else if (finalSession === '3m') {
      url =
        url + `&startDate=${moment().subtract(3, 'M').format('DD-MM-YYYY')}`;
    }

    const response = await axiosInstance.get(url, {
      signal: controller.signal,
    });

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    const recommendations = data.data.docs;

    try {
      const priceResponse = await axiosInstance.post(
        `${REACT_APP_PORTFOLIO_API_V2_URL}/hsx/stocks/intradayprice/by-codes`,
        {
          startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
          codes: data.data.docs.map((i) => i.symbol).slice(0, 20),
        }
      );

      const prices = priceResponse.data.stockIntradayPrice;
      if (!prices) {
        console.log('Không thể lấy dữ liệu giá cổ phiếu');
      } else {
        for (const recommendation of recommendations) {
          const price = prices[recommendation.symbol.toLowerCase()]?.[0];
          if (price) {
            const stockPrice = recommendation.price;
            const ceiling = (Number(price.Open) * (100 + 7)) / 100;
            const floor = (Number(price.Open) * (100 - 7)) / 100;
            const reference = price.Open;
            if (stockPrice === ceiling) {
              recommendation.color = 'rgb(243, 13, 243)';
            } else if (stockPrice === floor) {
              recommendation.color = 'rgb(10, 254, 255)';
            } else if (stockPrice === reference) {
              recommendation.color = 'rgb(255, 204, 0)';
            } else if (stockPrice > reference) {
              recommendation.color = 'rgb(58, 214, 5)';
            } else {
              recommendation.color = 'rgb(255, 1, 0)';
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    console.timeEnd('getRecommendations');

    return {
      recommendations,
      total: data.data.totalDocs,
      page: finalPage,
      limit: finalLimit,
      sortBy: finalSortBy,
      search,
      watchListId: finalWatchListId,
      session: finalSession,
      polling: polling,
    };
  }
);

export const sendRecommendation = createAsyncThunk(
  'recommendation/send-recommendation',
  async ({ params }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('sendRecommendation');

    const response = await axiosInstance.post(
      `${REACT_APP_RECOMMENDATION_API_URL}/holding-ratio`,
      params,
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('sendRecommendation');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.recommendations = [];
      state.limit = 20;
      state.page = 1;
      state.total = 0;
      state.sortBy = 'new';
      state.watchListId = 'all';
      state.session = 'today';
      state.search = '';
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getRecommendations.pending]: (state, action) => {
      if (!action?.meta?.arg?.polling) {
        state.loading = true;
      }
    },
    [getRecommendations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.recommendations = payload.recommendations;
      state.page = payload.page;
      state.limit = payload.limit;
      state.total = payload.total;
      state.watchListId = payload.watchListId;
      state.search = payload.search;
      state.session = payload.session;
      state.sortBy = payload.sortBy;
    },
    [getRecommendations.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { reset, setLoading } = recommendationSlice.actions;

export const recommendationSelector = (state) => state.recommendation;

export const recommendationReducer = recommendationSlice.reducer;

import React from 'react';
import IndayOrderList from './Inday/OrderList';
import CopierOrderList from './Copier/OrderList';
import RootOrderList from './Root/OrderList';
import { StyledTabs } from 'components/GlobalComponents/styled';

export const TradingHistory = () => {
  const items = [
    {
      label: 'Lệnh trong ngày',
      key: 'item-0',
      children: <IndayOrderList />,
    },
    {
      label: 'Lệnh gốc',
      key: 'item-1',
      children: <RootOrderList />,
    },
    {
      label: 'Lệnh cho Copiers mới',
      key: 'item-3',
      children: <CopierOrderList />,
    },
  ];

  return (
    <section>
      <h1 className="text-2xl">Sổ lệnh</h1>
      <StyledTabs items={items} />
    </section>
  );
};

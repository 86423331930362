const ExpandFourAnglesIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 4V3.5H3.5V4H4ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM4.5 10V4H3.5V10H4.5ZM4 4.5H10V3.5H4V4.5ZM3.64645 4.35355L11.6464 12.3536L12.3536 11.6464L4.35355 3.64645L3.64645 4.35355Z"
          fill="var(--border-color)"
        />
        <path
          d="M4 20V20.5H3.5V20H4ZM11.6464 11.6464C11.8417 11.4512 12.1583 11.4512 12.3536 11.6464C12.5488 11.8417 12.5488 12.1583 12.3536 12.3536L11.6464 11.6464ZM4.5 14V20H3.5V14H4.5ZM4 19.5H10V20.5H4V19.5ZM3.64645 19.6464L11.6464 11.6464L12.3536 12.3536L4.35355 20.3536L3.64645 19.6464Z"
          fill="var(--border-color)"
        />
        <path
          d="M20 4V3.5H20.5V4H20ZM12.3536 12.3536C12.1583 12.5488 11.8417 12.5488 11.6464 12.3536C11.4512 12.1583 11.4512 11.8417 11.6464 11.6464L12.3536 12.3536ZM19.5 10V4H20.5V10H19.5ZM20 4.5H14V3.5H20V4.5ZM20.3536 4.35355L12.3536 12.3536L11.6464 11.6464L19.6464 3.64645L20.3536 4.35355Z"
          fill="var(--border-color)"
        />
        <path
          d="M20 20V20.5H20.5V20H20ZM12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536L12.3536 11.6464ZM19.5 14V20H20.5V14H19.5ZM20 19.5H14V20.5H20V19.5ZM20.3536 19.6464L12.3536 11.6464L11.6464 12.3536L19.6464 20.3536L20.3536 19.6464Z"
          fill="var(--border-color)"
        />
      </svg>
    </>
  );
};

export default ExpandFourAnglesIcon;

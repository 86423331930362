import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import {
  StyledModal,
  Container,
  Heading,
  Actions,
  PreviewSection,
  PreviewHeading,
  PreviewContent,
  StyledForm,
} from './styles';
import { useTranslation } from 'react-i18next';
import EyeIcon from 'assets/icons/EyeIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFiles } from 'features/recommendation/customerFileSlice';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';

import {
  postPresignUrl,
  postUpload,
  getAvatarUrl,
} from 'features/copyTrade/copyTradeSlice';

import { useMutation } from '@tanstack/react-query';
import { sendMessage } from 'utils/api';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DropBox from 'components/GlobalComponents/UploadImage/DropBox';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const SendMessageCustomer = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const { customerFiles } = useSelector((state) => state.customerFile);
  const { t } = useTranslation(['recommendationsScreen']);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    content: '',
    customerSetIds: [],
  });
  const [file, setFile] = useState(null);

  const { mutateAsync, isLoading } = useMutation(sendMessage, {
    onSuccess: (data) => {
      console.log('send Message', data);
      toast.success('Gửi tin thành công!', settingsSuccess);
      handleCancel();
    },
    onError: (error) => {
      console.log('send Message error', error);
      toast.error('Lỗi gửi tin!', settingsError);
    },
  });

  const formRule = {
    required: {
      required: true,
      message: t('recommendationForm.requiredMessage'),
    },
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    form.resetFields();
    setFormData({
      content: '',
      customerSetIds: [],
    });
    setFile(null);
  };

  const handleFormValuesChange = (_, allValue) => {
    setFormData((state) => ({ ...state, ...allValue }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.name.split('.')[0].length < 20) {
        if (file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          if (file.size / 1024 ** 2 > 5) {
            return toast.error('File ảnh vượt quá 5MB', settingsError);
          }
        } else {
          if (file.size / 1024 ** 2 > 20) {
            return toast.error('File vượt quá 20MB', settingsError);
          }
        }
        setFile(file);
      } else {
        toast.error('Tên file vượt quá 20 ký tự!', settingsError);
      }
      console.log('file', file);
    }
  }, []);

  const handleUpload = async (values) => {
    console.log('file', file, values);
    try {
      const res = await dispatch(
        postPresignUrl({ filename: file.name })
      ).unwrap();
      dispatch(postUpload({ presignedUrl: res.data.presignedUrl, file }));
      console.log('handleUpload', res.data);
      const res2 = await dispatch(
        getAvatarUrl({ filename: res.data.s3Filename })
      ).unwrap();
      console.log('res2', res2.data);
      if (res2) {
        mutateAsync({
          customerSetIds: values.customerSetIds,
          document: res2.data,
          content: formData.content
            .replaceAll('<p>', '')
            .replaceAll('<br/>', '')
            .replaceAll('</p>', ''),
        });
      }
    } catch (err) {
      console.log('err :', err);
      // handle error here
    }
  };

  const onSubmit = (values) => {
    if (file) {
      handleUpload(values);
    } else {
      mutateAsync({
        customerSetIds: values.customerSetIds,
        content: formData.content
          .replaceAll('<p>', '')
          .replaceAll('<br/>', '')
          .replaceAll('</p>', ''),
      });
    }
  };

  useEffect(() => {
    dispatch(
      getCustomerFiles({
        page: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  return (
    <StyledModal
      centered
      destroyOnClose={true}
      open={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="105rem"
      zIndex={999}
      closeIcon={null}
      bodyStyle={{ background: '#1C1F2F', borderRadius: 18 }}
    >
      <Container>
        <Heading>Gửi tin</Heading>
        <StyledForm
          onValuesChange={handleFormValuesChange}
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          name="createRecommendation"
          scrollToFirstError
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="customerSetIds"
                label={
                  <label>{t('recommendationForm.sendToCustomerSets')}</label>
                }
                rules={[formRule.required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn tệp KH"
                >
                  {customerFiles.map((customerFile) => (
                    <Select.Option value={customerFile._id}>
                      {customerFile.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <label>Nội dung</label>
          <div className="md:flex max-w-full w-full gap-4">
            <div className="w-[300px]">
              <Editor
                placeholder="Nhập nội dung"
                onEditorStateChange={(editorState) => {
                  setFormData((state) => ({
                    ...state,
                    content: draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    ).replaceAll('<p></p>', '<br/>'),
                  }));
                }}
                toolbar={{
                  options: ['inline', 'link', 'emoji'],
                  inline: {
                    options: [
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      'monospace',
                    ],
                  },
                }}
                toolbarClassName="m-0 !bg-transparent !border-none"
                editorClassName="border !border-[#363953] p-[12px] min-h-[20vh] rounded-lg"
              />
            </div>

            <PreviewSection>
              <PreviewHeading>
                <EyeIcon />
                <span>{t('recommendationForm.preview')}</span>
              </PreviewHeading>
              <PreviewContent>
                <div dangerouslySetInnerHTML={{ __html: formData.content }} />
              </PreviewContent>
            </PreviewSection>
            <PreviewSection>
              <PreviewHeading>
                <span>Gửi kèm file</span>
              </PreviewHeading>
              <div className="w-[250px]">
                <DropBox
                  onDrop={onDrop}
                  text={'Gửi kèm file'}
                  icon={
                    <AttachFileIcon className="mt-3 text-custom-darkBlue200 !w-12 !h-12" />
                  }
                  fileName={file?.name}
                  accept="*"
                />
              </div>
            </PreviewSection>
          </div>

          <span className="text-[#AAAAAA]">
            * Dung lượng tối đa 5MB cho ảnh và 20MB cho file khác. Tên file tối
            đa 20 ký tự
          </span>
        </StyledForm>
        <Row>
          <Actions>
            <Button onClick={handleCancel}>
              {t('recommendationForm.cancel')}
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => form.submit()}
            >
              Gửi
            </Button>
          </Actions>
        </Row>
      </Container>
    </StyledModal>
  );
};

export default React.forwardRef(SendMessageCustomer);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils';
import { getRecommendationHistories } from 'features/recommendation/historySlice';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Container,
  Group,
  GroupTitle,
  GroupList,
  Item,
  GroupDescription,
  GroupText,
  StyledModal,
  ModalHeader,
} from './styles';

const LogList = (props) => {
  const { symbol, fullScreen, onCloseFullScreen } = props;
  const { t } = useTranslation(['recommendationsScreen']);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.recommendationHistory);

  const groupHistories = _groupBy(data, (item) =>
    moment(item.createdAt).format('DD.MM.YYYY')
  );

  useEffect(() => {
    dispatch(getRecommendationHistories({ symbol }));
  }, [symbol]);

  const renderContainer = () => {
    return (
      <Container style={{ marginBottom: 10, padding: '15px 8px' }}>
        {_map(groupHistories, (value, key) => (
          <Group key={key}>
            <GroupTitle>{key}</GroupTitle>
            <GroupList>
              {value.map(
                ({
                  _id,
                  createdAt,
                  percentageProportion,
                  percentageHold,
                  price,
                  symbol,
                  isSend,
                  tradingType,
                }) => (
                  <Item key={_id}>
                    <div>{moment(createdAt).format('HH:mm')}</div>
                    <GroupDescription>
                      <b>
                        {symbol}: {formatPrice(price)}
                      </b>
                      <span className="line">|</span>
                      <GroupText>
                        <span>
                          {(tradingType === 'buy' ||
                            tradingType === 'buy-more') &&
                            t('buyMore')}
                          {tradingType === 'buy-test' && t('buyTest')}
                          {tradingType === 'sell' && t('sell')}
                        </span>
                        :{' '}
                        <div
                          className={
                            tradingType === 'buy' ||
                            tradingType === 'buy-more' ||
                            tradingType === 'buy-test'
                              ? 'green'
                              : 'red'
                          }
                          style={{ fontWeight: 'bold', marginLeft: 5 }}
                        >
                          {`${percentageProportion}%`}
                        </div>
                      </GroupText>
                      <span className="line">|</span>
                      <GroupText>
                        {t('history.hold')}:{' '}
                        <div style={{ fontWeight: 'bold', marginLeft: 5 }}>
                          {`${percentageHold}%`}
                        </div>
                      </GroupText>
                      {isSend && (
                        <div className="note">
                          {t('history.sent_recommendation')}
                        </div>
                      )}
                    </GroupDescription>
                  </Item>
                )
              )}
            </GroupList>
          </Group>
        ))}
      </Container>
    );
  };

  return (
    <>
      <StyledModal visible={fullScreen} closable={false} footer={null}>
        <ModalHeader>
          <CloseCircleOutlined
            style={{ fontSize: 25, cursor: 'pointer' }}
            onClick={() => {
              if (onCloseFullScreen) {
                onCloseFullScreen();
              }
            }}
          />
        </ModalHeader>
        {renderContainer()}
      </StyledModal>
      {renderContainer()}
    </>
  );
};

export default LogList;

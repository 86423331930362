const CloseIcon = ({ size = 20, color = 'var(--border-color)' }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
      >
        <path
          d="M10.7835 10.0001L14.8335 5.95006C14.9245 5.84379 14.972 5.70708 14.9666 5.56726C14.9612 5.42744 14.9033 5.2948 14.8043 5.19586C14.7054 5.09692 14.5727 5.03896 14.4329 5.03356C14.2931 5.02816 14.1564 5.07572 14.0501 5.16673L10.0001 9.21673L5.95012 5.16117C5.84384 5.07016 5.70713 5.0226 5.56731 5.028C5.42749 5.0334 5.29486 5.09137 5.19592 5.19031C5.09698 5.28925 5.03902 5.42188 5.03361 5.5617C5.02821 5.70152 5.07577 5.83823 5.16679 5.94451L9.21679 10.0001L5.16123 14.0501C5.10308 14.0999 5.05584 14.1612 5.0225 14.2301C4.98915 14.299 4.97041 14.3741 4.96746 14.4506C4.9645 14.5271 4.97739 14.6034 5.00532 14.6747C5.03325 14.746 5.07562 14.8107 5.12976 14.8649C5.1839 14.919 5.24865 14.9614 5.31994 14.9893C5.39123 15.0172 5.46753 15.0301 5.54404 15.0272C5.62055 15.0242 5.69562 15.0055 5.76455 14.9721C5.83347 14.9388 5.89476 14.8916 5.94457 14.8334L10.0001 10.7834L14.0501 14.8334C14.1564 14.9244 14.2931 14.972 14.4329 14.9666C14.5727 14.9612 14.7054 14.9032 14.8043 14.8043C14.9033 14.7053 14.9612 14.5727 14.9666 14.4329C14.972 14.2931 14.9245 14.1563 14.8335 14.0501L10.7835 10.0001Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default CloseIcon;

import { Row, Col, Typography } from 'antd';
import ExpandIcon from 'assets/icons/RowExpandIcon';
import moment from 'moment';

import { StyledTable } from './styles';

const columns = [
  {
    title: 'STT',
    key: 'time',
    align: 'center',
    width: '9%',
    dataIndex: 'time',
  },
  {
    title: 'Mã CK',
    key: 'symbol',
    align: 'center',
    width: '7%',
    dataIndex: 'symbol',
  },
  {
    title: (
      <Typography.Text strong>
        Khuyến nghị <span className="buy">MUA</span>
      </Typography.Text>
    ),
    className: 'no-border',
    align: 'center',
    children: [
      {
        title: 'Tổng số lượng',
        key: 'buy',
        className: 'border-right',
        align: 'center',
        dataIndex: 'buy',
        width: '7%',
        render: (value) => <span className="buy">{value}</span>,
      },
      {
        title: 'Tổng tỷ trọng',
        key: 'total_buy',
        dataIndex: 'total_buy',
        align: 'center',
        width: '7%',
        render: (value) => <span className="buy">{value}</span>,
      },
    ],
  },
  {
    title: (
      <Typography.Text strong>
        Khuyến nghị <span className="sell">BÁN</span>
      </Typography.Text>
    ),
    className: 'no-border',
    align: 'center',
    children: [
      {
        title: 'Tổng số lượng',
        key: 'sell',
        className: 'border-right',
        dataIndex: 'sell',
        align: 'center',
        width: '7%',
        render: (value) => <span className="sell">{value}</span>,
      },
      {
        title: 'Tổng tỷ trọng',
        key: 'total_sell',
        dataIndex: 'total_sell',
        align: 'center',
        width: '7%',
        render: (value) => <span className="sell">{value}</span>,
      },
    ],
  },
  {
    title: 'Tỷ trọng đang nắm giữ',
    key: 'total_remain',
    align: 'center',
    width: '7%',
    dataIndex: 'total_remain',
    render: (value) => <span className="buy">{value}</span>,
  },
  {
    title: 'Tỷ trọng khả dụng',
    key: 'avaiable_volume',
    align: 'center',
    width: '7%',
    dataIndex: 'avaiable_volume',
    render: (value) => <span className="buy">{value}</span>,
  },
  {
    title: 'Giá mua chi tiết từng lệnh',
    key: 'price',
    align: 'center',
    dataIndex: 'price',
    width: '7%',
  },
  {
    title: 'Giá mua Trung bình',
    key: 'avg_price',
    align: 'center',
    dataIndex: 'avg_price',
    width: '7%',
  },
  {
    title: (
      <Typography.Text strong>
        Giá thị trường / <span style={{ color: '#7C7C7C' }}>Giá bán</span>
      </Typography.Text>
    ),
    width: '7%',
    key: 'current_price',
    align: 'center',
    dataIndex: 'current_price',
    render: (value) => <span className="sell-price">{value}</span>,
  },
  {
    title: 'Hiệu suất TB tạm tính',
    key: 'efficiency_remain',
    align: 'center',
    dataIndex: 'efficiency_remain',
    width: '7%',
    render: (value) =>
      value ? (
        value > 0 ? (
          <span className="buy">{`+${Number(value).toFixed(2)}%`}</span>
        ) : (
          <span className="sell">{`${Number(value).toFixed(2)}%`}</span>
        )
      ) : (
        ''
      ),
  },
  {
    title: 'Hiệu suất TB đã chốt',
    key: 'efficiency_closed',
    dataIndex: 'efficiency_closed',
    align: 'center',
    width: '7%',
    render: (value) =>
      value ? (
        value > 0 ? (
          <span className="buy">{`+${(value * 100).toFixed(2)}%`}</span>
        ) : (
          <span className="sell">{`${(value * 100).toFixed(2)}%`}</span>
        )
      ) : (
        ''
      ),
  },
  {
    title: <ExpandIcon />,
    key: 'expanded',
    align: 'center',
    width: '7%',
    render: () => <div className="keep-space"></div>,
  },
];

const ExpandedRow = (props) => {
  const { record } = props;

  const data = record.orders
    .map((item) => ({
      time: moment(item.createdAt).format('HH:mm DD-MM'),
      symbol: record.symbol,
      buy:
        item.tradingType === 'buy' || item.tradingType === 'buy-test'
          ? 'Mua'
          : '',
      total_buy:
        item.tradingType === 'buy' || item.tradingType === 'buy-test'
          ? item.volume + '%'
          : '',
      sell: item.tradingType === 'sell' ? 'Bán' : '',
      total_sell: item.tradingType === 'sell' ? item.volume + '%' : '',
      total_remain: (item.totalVol || 0) + '%',
      avaiable_volume: (item.avaiable_volume || 0) + '%',
      price:
        item.tradingType === 'buy' || item.tradingType === 'buy-test'
          ? (item.price / 1000).toFixed(2)
          : '',
      avg_price: (item.avg_price / 1000).toFixed(2),
      current_price:
        item.tradingType === 'sell' ? (item.price / 1000).toFixed(2) : '',
      efficiency_remain: '',
      efficiency_closed: item.efficiency,
    }))
    .sort((a, b) =>
      moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1
    );

  return (
    <Row gutter={[24, 12]} align="middle" justify="center">
      <Col span={24}>
        <StyledTable
          className="modal-table"
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
        />
      </Col>
    </Row>
  );
};

export default ExpandedRow;

import { Dropdown, Menu, Tooltip } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import PlusIconPortfolioSelector from 'assets/icons/PlusIconPortfolioSelector';
import SearchIcon from 'assets/icons/SearchIcon';
import SortIcon from 'assets/icons/SortIcon';
import useDebounce2 from 'hooks/useDebounce2';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PortfolioSearchBoxContainer,
  SearchInputStyled,
  WatchlistHeaderStyled,
} from './styles';

const WatchlistHeader = ({ onOpenModal, filter, setFilter }) => {
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState(filter.name || '');
  const [openSearch, setOpenSearch] = useState(Boolean(filter.name));
  const { t } = useTranslation(['watchlistScreen']);

  const debounce = useDebounce2();

  /* -------------------------------------------------------------------------- */
  /*                                   SORTER                                   */
  /* -------------------------------------------------------------------------- */
  const sortKeys = [
    {
      key: 'default',
      title: `${t('watchlistSelectorSection.header.sorter.sorter1')}`,
    },
    {
      key: 'name',
      title: `${t('watchlistSelectorSection.header.sorter.sorter2')}`,
    },
    {
      key: 'total_points',
      title: `${t('watchlistSelectorSection.header.sorter.sorter3')}`,
    },
    {
      key: 'highest_return',
      title: `${t('watchlistSelectorSection.header.sorter.sorter4')}`,
    },
    {
      key: 'lowest_return',
      title: `${t('watchlistSelectorSection.header.sorter.sorter5')}`,
    },
    {
      key: 'percent',
      title: `${t('watchlistSelectorSection.header.sorter.sorter6')}`,
    },
  ];

  //handle focus input when open search
  useEffect(() => {
    if (!inputRef.current) return;
    if (openSearch) {
      inputRef.current.focus();
    }
  }, [openSearch, inputRef]);
  //end

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
    debounce(() => {
      setFilter((prevState) => ({
        ...prevState,
        name: e.target.value,
      }));
    });
  };

  const handleCloseSearch = () => {
    setOpenSearch(false);
    setSearchValue('');
    setFilter((prevState) => ({
      ...prevState,
      name: '',
    }));
  };

  const SortDropdownMenu = (
    <Menu>
      {sortKeys.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => {
            setFilter((prevState) => ({
              ...prevState,
              sort: item.key,
            }));
          }}
        >
          <span
            style={{
              color:
                item.key === filter.sort
                  ? 'var(--light-blue-color-2)'
                  : 'var(--white-color)',
            }}
          >
            {item.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (openSearch) {
    return (
      <PortfolioSearchBoxContainer>
        <div>
          <SearchIcon
            color={
              searchValue ? 'var(--light-blue-color-2)' : 'var(--border-color)'
            }
          />
          &ensp;
          <SearchInputStyled
            ref={inputRef}
            value={searchValue}
            placeholder={t(
              'watchlistSelectorSection.header.searchBoxPlaceholder'
            )}
            onChange={handleSearchInputChange}
          />
        </div>

        <div style={{ gap: '0.5rem', alignItems: 'center' }}>
          <Dropdown overlay={SortDropdownMenu} trigger={['click']}>
            <Tooltip
              destroyTooltipOnHide
              title="Sắp xếp"
              color="var(--light-blue-color)"
            >
              <button>
                <SortIcon
                  color={
                    filter.sort !== 'default'
                      ? 'var(--light-blue-color-2)'
                      : 'var(--border-color)'
                  }
                />
              </button>
            </Tooltip>
          </Dropdown>
          <Tooltip
            destroyTooltipOnHide
            title="Đóng"
            color="var(--light-blue-color)"
          >
            <button onClick={handleCloseSearch}>
              <CloseIcon />
            </button>
          </Tooltip>
        </div>
      </PortfolioSearchBoxContainer>
    );
  }

  return (
    <WatchlistHeaderStyled>
      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
        {' '}
        {t('watchlistSelectorSection.header.title')}
      </span>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
        <Tooltip
          destroyTooltipOnHide
          title="Tạo mới"
          color="var(--light-blue-color)"
        >
          <button onClick={onOpenModal.bind(this, { modalType: 'create' })}>
            <PlusIconPortfolioSelector />
          </button>
        </Tooltip>
        <Tooltip
          destroyTooltipOnHide
          title="Tìm kiếm"
          color="var(--light-blue-color)"
        >
          <button onClick={setOpenSearch.bind(this, true)}>
            <SearchIcon />
          </button>
        </Tooltip>
      </div>
    </WatchlistHeaderStyled>
  );
};

export default WatchlistHeader;

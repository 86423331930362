import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col } from 'antd';
import FilterIcon from 'assets/icons/FilterIcon';
import ListSectorsIcon from 'assets/icons/ListSectorsIcon';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import GlobalTooltip from 'components/GlobalComponents/GlobalTooltip';
import { handleSelectFilterPortfolioNews } from 'features/portfolio/portfolioNewsSlice';
import { handleSelectSectorWatchlistNews } from 'features/watchlist/watchlistSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItemLeftStyled,
  MenuLeftStyled,
  SiderbarLeftHeader,
  SiderbarLeftWrapper,
  StockCodeStyled,
  StyledCheckboxGroupContainer,
} from './styles';

const SidebarLeft = ({ onSelectStockCode, stockCodes, sectorListArr }) => {
  // eslint-disable-next-line no-unused-vars

  const dispatch = useDispatch();

  const portfolioNewsSector = useSelector(
    (state) => state.portfolioNews.sectors
  );

  const portfolioNewsStockCodes = useSelector(
    (state) => state.portfolioNews.stockCodes
  );

  const watchlistNewsSector = useSelector((state) => state.watchlist.sectors);
  const watchlistNewsStockCodes = useSelector(
    (state) => state.watchlist.stockCodes
  );

  const { typeOfNewsModal } = useSelector((state) => state.uiHandler);
  const [stocksSelected, setStocksSelected] = useState(
    typeOfNewsModal === 'portfolioNewsModal'
      ? portfolioNewsStockCodes
      : watchlistNewsStockCodes
  );

  // eslint-disable-next-line no-unused-vars
  const [sector, setSector] = useState('');

  const { t } = useTranslation(['common', 'ratingsScreen']);

  const SectorItem = () => {
    const plainOptions =
      typeOfNewsModal === 'portfolioNewsModal'
        ? portfolioNewsSector
        : watchlistNewsSector;

    const onChange = (list) => {
      setStocksSelected([]);

      if (typeOfNewsModal === 'portfolioNewsModal') {
        dispatch(
          handleSelectFilterPortfolioNews({ sectors: list, stockCodes: [] })
        );
      } else {
        dispatch(
          handleSelectSectorWatchlistNews({ sectors: list, stockCodes: [] })
        );
      }
    };

    return (
      <>
        <StyledCheckboxGroupContainer>
          <Checkbox.Group
            style={{
              width: '100%',
            }}
            onChange={onChange}
            value={plainOptions}
          >
            {sectorListArr.map((el) => {
              return (
                <Col span={24}>
                  <MenuItemLeftStyled>
                    <Checkbox value={el}>
                      {t(`listSectorsSection.sectorNameCell.${el}`, {
                        ns: 'ratingsScreen',
                      })}
                    </Checkbox>
                  </MenuItemLeftStyled>
                </Col>
              );
            })}
          </Checkbox.Group>
        </StyledCheckboxGroupContainer>

        {plainOptions.length > 0 && (
          <SecondaryButton
            size="large"
            className="remove-filter-btn"
            textBtn={t('newsBox.newsBoxModal.leftSection.removeFilterBtn')}
            style={{
              height: '3.4rem',
              fontSize: '13px',
              color: '',
              marginTop: '2rem',
            }}
            onClick={() => {
              if (typeOfNewsModal === 'portfolioNewsModal') {
                dispatch(
                  handleSelectFilterPortfolioNews({
                    sectors: [],
                    stockCodes: [],
                  })
                );
              } else {
                dispatch(
                  handleSelectSectorWatchlistNews({
                    sectors: [],
                    stockCodes: [],
                  })
                );
              }
            }}
          ></SecondaryButton>
        )}
      </>
    );
  };

  const handleSelectStockcode = (stock_code) => {
    setStocksSelected((prevState) => {
      const newState = [...prevState];
      const position = newState.findIndex((code) => code === stock_code);

      if (position !== -1) {
        newState.splice(position, 1);
      } else {
        newState.push(stock_code);
      }

      if (typeOfNewsModal === 'portfolioNewsModal') {
        dispatch(
          handleSelectFilterPortfolioNews({ sectors: [], stockCodes: newState })
        );
      } else {
        dispatch(
          handleSelectSectorWatchlistNews({
            sectors: [],
            stockCodes: newState,
          })
        );
      }

      return newState;
    });
  };

  return (
    <SiderbarLeftWrapper>
      <SiderbarLeftHeader
        style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '95%',
          }}
        >
          <div>
             <ListSectorsIcon />
            <span style={{ paddingLeft: '1rem' }}>
              {t('newsBox.newsBoxModal.leftSection.listSectors')}
            </span>
          </div>
        </div>
      </SiderbarLeftHeader>
      <MenuLeftStyled>
        <SectorItem />
      </MenuLeftStyled>
      <div style={{ marginTop: '3rem' }}>
        <SiderbarLeftHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              cursor: 'pointer',
            }}
          >
            <div>
              <FilterIcon />
              <span>{t('newsBox.newsBoxModal.leftSection.filterSectors')}</span>
            </div>
            <GlobalTooltip
              title={t('newsBox.newsBoxModal.leftSection.infoTooltip')}
              width="88%"
              placement="right"
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <InfoCircleOutlined style={{ fontSize: '16px' }} />
              </span>
            </GlobalTooltip>
          </div>
        </SiderbarLeftHeader>
        <div style={{ textAlign: 'left' }}>
          {Array.isArray(stockCodes) &&
            stockCodes.map((code, index) => (
              <StockCodeStyled
                onClick={() => {
                  setSector(null);
                  handleSelectStockcode(code);
                }}
                key={index}
                active={
                  typeOfNewsModal === 'portfolioNewsModal'
                    ? stocksSelected.includes(code) ||
                      portfolioNewsStockCodes.includes(code)
                    : watchlistNewsStockCodes.includes(code)
                }
              >
                <span>{code}</span>
              </StockCodeStyled>
            ))}
        </div>
      </div>

      {stocksSelected.length > 0 ? (
        <SecondaryButton
          size="large"
          className="remove-filter-btn"
          textBtn={t('newsBox.newsBoxModal.leftSection.removeFilterBtn')}
          style={{ height: '3.4rem', fontSize: '13px', color: '' }}
          onClick={() => {
            setStocksSelected([]);
            if (typeOfNewsModal === 'portfolioNewsModal') {
              dispatch(
                handleSelectFilterPortfolioNews({ sectors: [], stockCodes: [] })
              );
            } else {
              dispatch(
                handleSelectSectorWatchlistNews({
                  sectors: [],
                  stockCodes: [],
                })
              );
            }
          }}
        ></SecondaryButton>
      ) : null}
    </SiderbarLeftWrapper>
  );
};

export default SidebarLeft;

import styled from 'styled-components';

export const PasswordContainer = styled.div`
  display: flex;
  flex-flow: column;
  background: var(--primary-color);
  border-radius: 4px;
`;

export const StyledPasswordHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--white-color);
  margin-bottom: 10px;
`;

export const StyledPasswordContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 2rem;
  gap: 2rem;
`;

import { useState } from 'react';
import { FormContainer, StyledInput } from './styled';
import { Button, Select, Typography, Form, Input, notification } from 'antd';
import { CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { TypeSend, getSubAccounts, linkStockAccount } from 'utils/stock-management';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';

const CTSConnect = ({ handleCancel, onSuccess, nextStep, reAcc = '' }) => {
  const [connectConfig, setConnectConfig] = useState({ username: reAcc });
  const [selectSubAccount, setSubAccount] = useState('');
  const [status, setStatus] = useState('config'); // config, selectSub
  const { mutateAsync, isLoading } = useMutation(linkStockAccount);
  const [subAccounts, setSubAccounts] = useState([]);
  const [subAccountType, setSubAccountType] = useState('');

  const { mutate: querySub, isLoading: isLoadingSubAccount } = useMutation(getSubAccounts, {
    onSuccess: ({ data }) => {
      console.log('subAccounts', data);
      setSubAccounts(
        data?.results.map((i) => ({
          value: i.id,
          label: `${i.id} - ${i.name || ''}`,
          type: i.type,
        })),
      );
    },
  });

  const onSubmitConnect = async () => {
    console.log('connectConfig cts', connectConfig);

    if (status === 'config') {
      try {
        const response = await mutateAsync({
          type: TypeSend.CTS,
          params: {
            ...connectConfig,
            isUpdateSession: !!reAcc,
            authType: 'CREDENTIAL',
          },
        });
        console.log('link CTS Stock res', response);
        if (nextStep) {
          querySub(connectConfig.username);
          setStatus('selectSub');
        } else {
          onSuccess();
          toast.success('Bạn đã kết nối tài khoản CTS thành công!', settingsSuccess);
        }
      } catch (error) {
        toast.error('Kết nối tài khoản lỗi!', settingsError);
      }
    } else if (status === 'selectSub') {
      onSuccess({
        source: TypeSend.CTS,
        username: connectConfig.username,
        subAccountId: selectSubAccount,
        accountType: subAccountType,
      });
    }
  };

  const isValidInput = () => {
    if (status === 'config') {
      return !connectConfig.username || !connectConfig.password;
    } else {
      return !selectSubAccount;
    }
  };

  return (
    <FormContainer>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
      >
        <div className="text-white flex flex-col gap-3 mb-4">
          {status === 'config' && (
            <div className="flex flex-col gap-4">
              <Typography.Text className="font-bold text-xl">Kết nối tài khoản CTS</Typography.Text>
              <StyledInput>
                <Typography.Text className="text-[14px] mb-3">Số tài khoản</Typography.Text>
                <Input
                  value={connectConfig.username}
                  onChange={(e) =>
                    setConnectConfig((state) => ({
                      ...state,
                      username: e.target.value,
                    }))
                  }
                />
              </StyledInput>

              <StyledInput>
                <Typography.Text className="text-[14px] mb-2">Mật khẩu API</Typography.Text>
                <Input.Password
                  autoCapitalize="off"
                  type="password"
                  name="password"
                  placeholder={'Mật khẩu'}
                  value={connectConfig.password}
                  onChange={(e) =>
                    setConnectConfig((state) => ({
                      ...state,
                      password: e.target.value,
                    }))
                  }
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </StyledInput>
            </div>
          )}

          {status === 'selectSub' && (
            <div className="flex flex-col gap-4">
              <Typography.Text level={4} className="font-bold">
                Bạn đã kết nối tài khoản CTS thành công, vui lòng chọn tiểu khoản để tiếp tục
              </Typography.Text>
              <StyledInput>
                <Typography.Text className="text-[14px] mb-2">Chọn tiểu khoản</Typography.Text>
                <Select
                  value={selectSubAccount}
                  style={{ width: '100%' }}
                  loading={isLoadingSubAccount}
                  disabled={isLoadingSubAccount}
                  options={subAccounts}
                  onChange={(e, opt) => {
                    setSubAccount(String(opt.value));
                    setSubAccountType(String(opt.type));
                  }}
                />
              </StyledInput>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 mb-2">
          <Button
            ghost
            type="primary"
            className="!m-0 !mr-2 !border-none !bg-custom-darkBlue100 px-8 !rounded-lg"
            onClick={handleCancel}
          >
            <span className="!text-custom-blue500">Hủy</span>
          </Button>
          <Button
            type="primary"
            className="!m-0 !text-white !bg-custom-blue500 px-8 !rounded-lg !border-none"
            loading={isLoading || isLoadingSubAccount}
            onClick={onSubmitConnect}
            disabled={isValidInput()}
          >
            Tiếp tục
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default CTSConnect;

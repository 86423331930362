import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const ToolbarCustomerFiles = (props) => {
  const { onClickAdd, onClickSend } = props;
  const { t } = useTranslation(['recommendationsScreen']);

  const handleClickAdd = () => {
    if (onClickAdd) {
      onClickAdd();
    }
  };

  const handleClickSend = () => {
    if (onClickSend) {
      onClickSend();
    }
  };

  return (
    <Container>
      <Button type="primary" onClick={handleClickAdd}>
        {t('addCustomerFile')}
      </Button>
      <Button ghost onClick={handleClickSend}>
        {' '}
        Gửi tin
      </Button>
    </Container>
  );
};

export default ToolbarCustomerFiles;

import styled from 'styled-components';
import { Form } from 'antd';

export const StyledForm = styled(Form)`
  .ant-form-item-label {
    padding: 0;
    margin-bottom: -3px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-control-input-content {
    flex-direction: column;
  }

  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #f24b78 !important;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    padding: 4px 0;
    color: #f24b78;
  }

  .ant-picker {
    width: 100%;
    background: transparent;
    border: none;
    color: #fff;
    border-bottom: 1px solid var(--border-color) !important;
    box-shadow: none !important;

    .ant-picker-input {
      input {
        color: #fff;
        font-size: 13px;
      }
    }

    .ant-picker-clear {
      background: var(--primary-color);
    }

    .anticon {
      color: white;
    }
  }
  .ant-picker-disabled {
    background: rgb(0 0 0 / 30%);
  }
`;

export const Container = styled.div`
  padding: 24px;
  label {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    font-size: 11px;
    line-height: 22px;
  }
  .ant-row {
    padding: 10px 0;
  }
  .ant-input-group-addon {
    background: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid var(--border-color) !important;
  }
  .ant-form-item-explain {
    font-size: 12px;
    padding: 4px 0;
  }

  .label-wrapper {
    margin-bottom: -5px;
  }

  .ant-input,
  .ant-select-selection-item {
    font-weight: 700;
  }
  .ant-select-disabled {
    background: rgb(0 0 0 / 30%);
  }
  .ant-input-disabled {
    background: rgb(0 0 0 / 30%) !important;
  }

  .reference-price {
    color: rgb(255, 204, 0) !important;
  }
  .ceiling-price {
    color: rgb(185 0 255) !important;
  }
  .floor-price {
    color: #03d7ff !important;
  }
`;

export const Heading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 30px;
`;

export const Actions = styled.div`
  text-align: right;
  width: 100%;
  button {
    height: 44px;
    margin-right: 16px;
  }
  button:last-of-type {
    margin-right: 0;
  }
`;

import { useMutation } from '@tanstack/react-query';
import { Button } from 'antd';
import { getInvestmentKind, getPortfolioDetail } from 'utils/api';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import { StyledTable } from 'components/GlobalComponents/styled';
import React, { useEffect, useState, useMemo } from 'react';

const InvestmentHold = ({ open, onClose, item }) => {
  const { mutateAsync: getDataInvestKind, isLoading } =
    useMutation(getInvestmentKind);
  const { mutateAsync: getDataPortfolioDetail, isLoading: isLoadingPort } =
    useMutation(getPortfolioDetail);
  const [listData, setList] = useState([]);

  const loadDetail = async () => {
    try {
      const dataMap = {};
      const { data } = await getDataInvestKind({
        source: item.source,
        subAccountId: item.subAccountId,
      });
      const { data: dataPort } = await getDataPortfolioDetail({
        source: item.source,
        subAccountId: item.subAccountId,
      });

      data.stockData.map((i) => {
        dataMap[i.code] = {
          code: i.code,
          percentage: Math.floor(i.percentage * 100) / 100,
        };
      });

      dataPort.map((i) => {
        if (dataMap[i.code]) {
          dataMap[i.code].avgBuyPrice = i.avgBuyPrice;
        }
      });
      console.log('set list', Object.values(dataMap))
      setList(Object.values(dataMap));
    } catch (error) {
      setList([]);
    }
  };

  useEffect(() => {
    if (open && item) {
      loadDetail();
    } else {
      setList([]);
    }
  }, [open, item]);

  const tableProps = {
    bordered: false,
    showHeader: true,
  };

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'name',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Mã CP',
        dataIndex: 'code',
      },
      {
        title: 'Giá mua TB',
        dataIndex: 'avgBuyPrice',
      },
      {
        title: 'Tỷ trọng',
        dataIndex: 'percentage',
        render: (data) => data + ' %',
      },
    ],
    []
  );
  return (
    <StyledModal
      open={open}
      destroyOnClose={true}
      maskClosable={true}
      className="!w-[600px]"
      title={`Tỷ trọng nắm giữ - ${item?.subAccountId?.slice(0, -3) + '***'}`}
      footer={null}
      onCancel={() => null}
      onOk={() => null}
      style={{ top: 25 }}
    >
      <StyledTable
        {...tableProps}
        loading={isLoading || isLoadingPort}
        pagination={{
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={listData}
      />
      <div className="flex justify-end pt-2 pb-4">
        <Button
          className="!h-16 !px-16 !rounded-lg !text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500"
          onClick={onClose}
        >
          Đóng
        </Button>
      </div>
    </StyledModal>
  );
};

export default InvestmentHold;

import {
  CloseOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleCloseAnnouncement } from 'features/uiHandler/uiHandlerSlice';
import axiosInstance from 'utils/axiosInstance';
import AnnouncementItem from './AnnouncementItem';
import AnnouncementList from './AnnouncementList';
import {
  AnnouncementContent,
  AnnouncementNumberSlider,
  AnnouncementWrapper,
  CurrentAnnouncementWrapper,
} from './styles';

const Announcement = () => {
  const dispatch = useDispatch();
  const interval = useRef();
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [announcementData, setAnnouncementData] = useState([]);

  const autoplay = useMemo(
    () => ({
      enabled: () => {
        interval.current = setInterval(() => {
          setCurrentIndex(
            (prevState) => (prevState + 1) % announcementData.length
          );
        }, 10000);
      },
      disable: () => {
        clearInterval(interval.current);
      },
    }),
    [announcementData]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_URL}/api/announcement/list`,
          {
            limit: 10,
            platform: 'web_portfolio',
            filter: {
              publish_status: 'enable',
            },
          }
        );
        const { data } = response.data;

        if (!data || data.status === false) {
          dispatch(handleCloseAnnouncement());
          throw new Error(JSON.stringify(data));
        }
        if (data.announcements.length === 0) {
          dispatch(handleCloseAnnouncement());
          return;
        }

        setAnnouncementData(data.announcements);
      } catch (error) {
        console.log({ error });
      }
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (announcementData.length < 2) return;
    autoplay.enabled();

    return () => autoplay.disable();
  }, [announcementData, autoplay]);

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevState) => (prevState + 1) % announcementData.length);
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentIndex(
      (prevState) =>
        (prevState + announcementData.length - 1) % announcementData.length
    );
  };

  const handleCloseDropdown = (e) => {
    e.stopPropagation();
    setVisible(false);
    dispatch(handleCloseAnnouncement());
  };

  if (announcementData.length === 0) return null;

  return (
    <div>
      <Dropdown
        trigger={['click']}
        overlay={<AnnouncementList data={announcementData} />}
        onVisibleChange={setVisible.bind(this, !visible)}
        visible={visible}
        overlayStyle={{
          width: containerRef?.current?.offsetWidth,
        }}
      >
        <AnnouncementWrapper
          ref={containerRef}
          onClick={setVisible.bind(this, !visible)}
          onMouseEnter={autoplay.disable}
          onMouseLeave={autoplay.enabled}
        >
          <AnnouncementContent>
            <Space size={5} align="center">
              <button onClick={handlePrev}>
                <LeftOutlined />
              </button>
              <AnnouncementNumberSlider>
                <span style={{ color: 'var(--light-blue-color-2)' }}>
                  {currentIndex + 1}
                </span>
                <span>/</span>
                <span>{announcementData.length}</span>
              </AnnouncementNumberSlider>
              <button onClick={handleNext}>
                <RightOutlined />
              </button>
            </Space>
            <CurrentAnnouncementWrapper>
              <AnnouncementItem data={announcementData[currentIndex]} />
            </CurrentAnnouncementWrapper>
          </AnnouncementContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button>{visible ? <UpOutlined /> : <DownOutlined />}</button>
            <button onClick={handleCloseDropdown}>
              <CloseOutlined />
            </button>
          </div>
        </AnnouncementWrapper>
      </Dropdown>
    </div>
  );
};

export default Announcement;

import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Empty, Modal, Table } from 'antd';
import FailureIconInNotiDialog from 'assets/icons/FailureIconInNotiDialog';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import GlobalTooltip from 'components/GlobalComponents/GlobalTooltip';
import GlobalModalStockDetails from 'components/GlobalComponents/StockDetail/GlobalModalStockDetails';
import { watchlist_permission } from 'constants/permission_key';
import { getDATXRating } from 'features/ratings/DATXRatingSlice';
import {
  getNewsByStock,
  getStockDetails,
  getStockDetailsOverview,
  handleSelectStockCode,
  resetStateStockNews,
} from 'features/ratings/stockDetailsSlice';
import { closeAnnouncementSelector } from 'features/uiHandler/uiHandlerSlice';
import {
  editWatchlist,
  watchlistDetailSelector,
} from 'features/watchlist/watchlistSlice';
import usePermission from 'hooks/usePermission';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fixPrice } from 'utils';
import { StatusColorStyled } from '../Watchlist/styles';
import {
  EmptyWrapper,
  SkeletonStyled,
  StyledCurrentWatchlistName,
  WatchlistDataTableContainer,
} from './styles.js';

const WatchlistDataTable = ({ handleOpenEditModal, handleOpenDeleteModal }) => {
  const { data: currentWatchlist, loading } = useSelector(
    watchlistDetailSelector
  );
  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const [isEnterMouse, setIsEnterMouse] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [selectedStock, setSelectedStock] = useState('');

  const [, detail_permission] = usePermission({
    key: watchlist_permission.detail,
  });

  const { currentSelectedWatchlistName, detail, list } = useSelector(
    (state) => state.watchlist
  );

  const { pagination } = useSelector((state) => state.stockDetails);

  const colors = ['#49C2F2'];

  const dataSource = useMemo(() => {
    if (!currentWatchlist) return null;
    return currentWatchlist.status.map((item, index) => ({
      ...item,
      key: index,
      color: colors[Math.floor(Math.random() * colors.length)],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWatchlist]);

  const scroll = {
    x: 600,
    y: `calc(100vh - ${closeAnnouncement ? '19rem' : '26rem'})`,
  };

  /* -------------------------------------------------------------------------- */
  /*                                    MODAL                                   */
  /* -------------------------------------------------------------------------- */
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRow, setActiveRow] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dispatch = useDispatch();

  const { t } = useTranslation(['watchlistScreen']);

  const handleClickCellTable = (record) => {
    showModal();
    dispatch(handleSelectStockCode(record.code));
    dispatch(resetStateStockNews());

    if (record.code !== activeRow) {
      dispatch(getStockDetails(record.code));
      dispatch(
        getStockDetailsOverview({
          stockCode: record.code,
          timeDistance: 86400,
        })
      );
      dispatch(
        getDATXRating({
          stockCode: record.code,
          timeDistance: 86400,
        })
      );

      dispatch(
        getNewsByStock({
          stockCode: record.code,
          page: pagination.page + 1,
        })
      );
    }

    setActiveRow(record.code);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ******                                   */
  /* -------------------------------------------------------------------------- */
  const handleRemoveSingleStock = (stockCode) => {
    setSelectedStock(stockCode);

    dispatch(
      editWatchlist({
        watchlist_id: currentWatchlist._id,
        stocks: currentWatchlist.stocks.filter((el) => el !== selectedStock),
      })
    );

    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModalConfirm = () => {
    setIsModalOpen(true);
  };

  const handleOkConfirm = () => {
    setIsModalOpen(false);
  };

  const handleCancelConfirm = () => {
    setIsModalOpen(false);
  };

  const handleClickBtn = () => {
    handleRemoveSingleStock();
  };

  /* -------------------------------------------------------------------------- */
  /*                                 COLUMN DATA                                */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: '4%',
      fixed: 'left',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      render: (_, record) => {
        return (
          <StatusColorStyled status={record.grade_rank}>
            {(() => {
              switch (true) {
                case record.grade_rank > 0:
                  return <CaretUpOutlined />;
                case record.grade_rank < 0:
                  return <CaretDownOutlined />;
                default:
                  return <div>&nbsp;</div>;
              }
            })()}
          </StatusColorStyled>
        );
      },
    },

    {
      title: t('watchlistDataTableSection.col1'),
      dataIndex: 'code',
      key: 'code',
      width: '13%',
      fixed: 'left',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (text, record) => {
        return (
          <span style={{ fontWeight: '500', color: record.color }}>{text}</span>
        );
      },
    },

    {
      title: () => (
        <GlobalTooltip
          title={t('watchlistDataTableSection.toolTip')}
          placement="bottom"
        >
          {t('watchlistDataTableSection.col2')} (*)
        </GlobalTooltip>
      ),
      dataIndex: 'last_price',
      key: 'last_price',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      sorter: (a, b) => a.last_price - b.last_price,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
      width: '15%',
    },

    {
      title: t('watchlistDataTableSection.col3'),
      dataIndex: 'mov_price',
      key: 'mov_price',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      sorter: (a, b) => a.mov_price - b.mov_price,
      render: (text, record) => {
        const mov_price = fixPrice(text);
        return (
          <StatusColorStyled status={record.mov_price}>
            <span>{mov_price > 0 ? `+${mov_price}` : mov_price}</span>
            <span> / </span>
            <span>
              {record.mov_percent > 0
                ? `+${record.mov_percent}`
                : record.mov_percent}
              %
            </span>
          </StatusColorStyled>
        );
      },
      width: '20%',
    },

    {
      title: t('watchlistDataTableSection.col4'),
      key: 'rank',
      dataIndex: 'rank',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      sorter: (a, b) => a.rank - b.rank,
      width: '14%',
      render: (text, record) => `${text}/${record.total_rank}`,
    },

    {
      title: t('watchlistDataTableSection.col5'),
      dataIndex: 'grade_rank',
      key: 'grade_rank',
      sorter: (a, b) => a.grade_rank - b.grade_rank,
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      width: '14%',
      render: (text, record) => {
        return (
          <StatusColorStyled status={record.grade_rank}>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.7rem' }}
            >
              {(() => {
                if (record.grade_rank > 0) {
                  return (
                    <>
                      <CaretUpOutlined />
                      <span>{text}</span>
                    </>
                  );
                } else if (record.grade_rank < 0) {
                  return (
                    <>
                      <CaretDownOutlined />
                      <span>{text}</span>
                    </>
                  );
                }

                return (
                  <span style={{ left: '20px', position: 'relative' }}>0</span>
                );
              })()}
            </div>
          </StatusColorStyled>
        );
      },
    },

    {
      title: t('watchlistDataTableSection.col6'),
      dataIndex: 'total_points',
      key: 'total_points',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            handleClickCellTable(record);
          },
        };
      },
      sorter: (a, b) => a.total_points - b.total_points,
      width: '13%',
      render: (text) => (
        <StatusColorStyled status={text}>{text}</StatusColorStyled>
      ),
    },

    {
      title: '',
      width: '6%',
      render: (text, record, index) => (
        <div
          onClick={() => {
            showModalConfirm();
          }}
        >
          {currentRowIndex === index && isEnterMouse && (
            <DeleteOutlined style={{ fontSize: '16px' }} />
          )}
        </div>
      ),
    },
  ];

  const onOpenEditModal = () => {
    handleOpenEditModal(detail.data);
  };

  const onOpenDeleteModal = () => {
    handleOpenDeleteModal(detail.data);
  };

  const data = [];

  let paddingStyle;

  if (list?.data.length > 0) {
    paddingStyle = '1rem 3.6rem';
  } else {
    paddingStyle = '0 3.6rem';
  }

  return (
    <WatchlistDataTableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: paddingStyle,
        }}
      >
        <StyledCurrentWatchlistName>
          {currentSelectedWatchlistName}
        </StyledCurrentWatchlistName>

        {list?.data.length > 0 ? (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              style={{
                paddingRight: '1.6rem',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={onOpenEditModal.bind(this, data)}
            >
              <EditOutlined style={{ fontSize: '16px' }} /> &nbsp;{' '}
              {t('watchlistSelectorSection.watchlistManagement.item3')}
            </span>

            <div
              onClick={onOpenDeleteModal.bind(this, data)}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DeleteOutlined style={{ fontSize: '16px' }} /> &nbsp;
              {t('watchlistSelectorSection.watchlistManagement.item2')}
            </div>
          </div>
        ) : null}
      </div>

      <Table
        showSorterTooltip={false}
        pagination={false}
        columns={columns}
        dataSource={detail_permission && !loading ? dataSource : null}
        scroll={scroll}
        locale={{
          emptyText: () => {
            switch (true) {
              case loading:
                return (
                  <SkeletonStyled
                    title={false}
                    paragraph={{ rows: 20 }}
                    active
                  />
                );

              case !detail_permission:
                return (
                  <EmptyWrapper closeAnnouncement={closeAnnouncement}>
                    <FailureIconInNotiDialog />
                    <span>PERMISSION_DENIED</span>
                  </EmptyWrapper>
                );

              default:
                return (
                  <EmptyWrapper closeAnnouncement={closeAnnouncement}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </EmptyWrapper>
                );
            }
          },
        }}
        rowClassName={(record, rowIndex) => {
          if (activeRow === record.code) {
            return 'active-row';
          }

          return null;
        }}
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: (event) => {
              setSelectedStock(record.code);
              setCurrentRowIndex(rowIndex);
              setIsEnterMouse(true);
            },
            onMouseLeave: (event) => {
              setCurrentRowIndex(null);
            },
          };
        }}
      />
      <GlobalModalStockDetails
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />

      <Modal
        centered
        destroyOnClose
        visible={isModalOpen}
        onOk={handleOkConfirm}
        onCancel={handleCancelConfirm}
        footer={
          <div>
            <span style={{ paddingRight: '1rem' }}>
              <SecondaryButton
                size="large"
                textBtn={t('watchlistSelectorSection.confirmModal.btn1Text')}
                onHandleClick={handleCancelConfirm}
              />
            </span>
            <PrimaryButton
              large
              textBtn={t('watchlistSelectorSection.confirmModal.btn2Text')}
              onHandleClick={handleClickBtn}
            />
          </div>
        }
      >
        <p style={{ padding: '4rem 4rem 0 4rem' }}>
          {t('watchlistSelectorSection.confirmModal.text2')}
          <span style={{ fontWeight: '700' }}> "{selectedStock}" </span>
          {t('watchlistSelectorSection.confirmModal.text3')}{' '}
          <span style={{ fontWeight: '700' }}>
            "{currentSelectedWatchlistName}"
          </span>
        </p>
      </Modal>
    </WatchlistDataTableContainer>
  );
};

export default WatchlistDataTable;

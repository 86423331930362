import { Skeleton, Typography } from 'antd';
import postImageThumbnail from 'assets/images/thumbnail.png';
import moment from 'moment';
import { useState } from 'react';
import {
  PostImageWrapper,
  PostInfoWrapper,
  PostItemWrapper,
  PostTagStyled,
  PostTimeStamp,
  PostTitleStyled,
  SkeletonImageStyled,
} from './styles';

const PostItem = ({ data, type, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const checkPercent = (percent) => {
    return Number(percent) === 0
      ? 'var(--dark-yellow-color)'
      : Number(percent) > 0
      ? 'var(--light-green-color)'
      : 'var(--light-red-color)';
  };

  if (!data) return null;

  const {
    featured_image,
    code,
    profit_Percent,
    current_profit,
    last_price,
    link,
    title,
    source,
    date,
    content,
  } = data;

  return (
    <PostItemWrapper className={index === 2 ? 'scroll-row-news' : ''}>
      <PostImageWrapper type={type}>
        <a href={link} target="_blank" rel="noreferrer">
          <img
            onLoad={setImageLoaded.bind(this, true)}
            onError={(e) => {
              e.target.src = postImageThumbnail;
            }}
            src={imageLoaded ? featured_image : postImageThumbnail}
            alt=""
          />
        </a>
      </PostImageWrapper>
      <PostInfoWrapper>
        <div>
          <PostTagStyled>{code}</PostTagStyled>
          <PostTagStyled style={{ color: checkPercent(current_profit) }}>
            {last_price}
          </PostTagStyled>

          <PostTagStyled style={{ color: checkPercent(current_profit) }}>
            {current_profit > 0 ? `+${current_profit}` : `${current_profit}`}
          </PostTagStyled>

          <PostTagStyled style={{ color: checkPercent(profit_Percent) }}>
            {profit_Percent > 0 ? `+${profit_Percent}%` : `${profit_Percent}%`}
          </PostTagStyled>
        </div>

        <a href={link} target="_blank" rel="noreferrer">
          <PostTitleStyled
            title={title}
            ellipsis={{
              rows: 2,
              expandable: false,
            }}
          >
            {title}
          </PostTitleStyled>
        </a>

        {type === 'full' && (
          <Typography.Paragraph
            style={{ color: 'var(--white-color)', marginBottom: '4px' }}
          >
            <p dangerouslySetInnerHTML={{ __html: content }}></p>
          </Typography.Paragraph>
        )}

        <PostTimeStamp>
          {source} | {moment(date).format('YYYY/MM/DD, hh:mm A')}
        </PostTimeStamp>
      </PostInfoWrapper>
    </PostItemWrapper>
  );
};

PostItem.Loading = ({ type }) => (
  <PostItemWrapper>
    <PostImageWrapper type={type}>
      <SkeletonImageStyled type={type} shape="square" active />
    </PostImageWrapper>
    <PostInfoWrapper>
      <Skeleton paragraph={{ rows: type === 'full' ? 4 : 2 }} active />
    </PostInfoWrapper>
  </PostItemWrapper>
);

export default PostItem;

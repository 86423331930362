import { Skeleton, Typography } from 'antd';
import styled from 'styled-components';

export const PostItemWrapper = styled.div`
  padding: 1.6rem 0;
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  gap: 1.6rem;
  position: relative;

  @media screen and (max-width: 37.5em) {
    flex-direction: column;
  }
`;

export const SkeletonImageStyled = styled(Skeleton.Avatar)`
  width: ${(props) => (props.type === 'full' ? '130px' : '74px')};
  height: ${(props) => (props.type === 'full' ? '130px' : '74px')};

  & > span {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 37.5em) {
    width: 100% !important;
    height: auto;
    aspect-ratio: 1;
  }
`;

export const PostImageWrapper = styled.div`
  & img {
    width: ${(props) => (props.type === 'full' ? '130px' : '74px')};
    height: ${(props) => (props.type === 'full' ? '130px' : '74px')};
    object-fit: cover;
    border-radius: 4px;
    aspect-ratio: 1/1;
    @media screen and (max-width: 37.5em) {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

export const PostInfoWrapper = styled.div`
  flex: 1;
  .ant-skeleton-title {
    margin-top: 0;
  }
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 16px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
`;

export const PostTagStyled = styled.span`
  margin-right: 4px;
  padding: 2px 4px;
  background: var(--section-header-color);
  border-radius: 2px;
  font-weight: 500;
  font-size: 13px;
  color: var(--white-color);
`;

export const PostTitleStyled = styled(Typography.Paragraph)`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 4px 0;
  color: var(--white-color);
  margin-bottom: 0 !important;

  &:hover {
    color: var(--light-blue-color-2);
  }
`;

export const PostTimeStamp = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: var(--border-color);
`;

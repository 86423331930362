import { DatePicker, Typography } from 'antd';
import { DATE_FORMAT } from 'constants/date';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const StyledDatePicker = styled(DatePicker)`
  height: 50px !important;
  padding: 12px !important;
  color: white;
  font-size: 1.6rem !important;
  background-color: #212335;
  border-radius: 4px !important;
  border: 1px solid #363953 !important;

  &:focus-visible {
    border: 0 !important;
    box-shadow: none;
    outline: 0;
  }
`;

export default function EditDate({ value, onChange }) {
  return (
    <div className="mt-4">
      <Typography.Text className="!text-custom-darkBlue200 mb-3 whitespace-nowrap">
        Ngày cuối cùng hiệu lực của lệnh
      </Typography.Text>
      <StyledDatePicker
        value={dayjs(value)}
        className="w-full !border-none"
        format={DATE_FORMAT}
        onChange={onChange}
        placeholder={'Chọn ngày'}
        disabledDate={(date) => date && date <= dayjs().startOf('day')}
      />
    </div>
  );
}

import { Typography } from 'antd';
import TextStatus from 'components/GlobalComponents/TextStatus';
import { useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import InvestChart from './InvestChart';
import { useSelector } from 'react-redux';
import { overviewDataSelector } from 'features/copyTrade/copyTradeSlice';
import { leadersSelector } from 'features/copyTrade/copyTradeSlice';

const LeaderChart = ({ categoryId }) => {
  const { ref } = useResizeDetector();
  const overviewData = useSelector(overviewDataSelector);
  const { data: leaders } = useSelector(leadersSelector);

  const investChartRef = useRef(null);

  return (
    <div className="w-full h-full md:w-[calc(100%_-_286px)] flex-grow">
      <div className="w-full min-h-[60vh] !bg-custom-darkBlue600 rounded-xl p-4 h-full">
        <div className="px-3 py-3 bg-dark200 rounded-t-xl">
          <Typography.Text className="text-white text-2xl font-bold text-left">
            Lợi nhuận theo thời gian
          </Typography.Text>

          <div className="rounded-xl bg-custom-darkBlue800 my-4 p-4 flex">
            <div className="flex-1 text-custom-gray300">
              <span className="pl-1">1 tháng</span>
              <br />
              <TextStatus className="text-xl font-bold" value={overviewData?.avgPercentagePerformance?.oneMonth}>
                %
              </TextStatus>
            </div>
            <div className="flex-1 text-custom-gray300">
              <span className="pl-1">3 tháng</span>
              <br />
              <TextStatus className="text-xl font-bold" value={overviewData?.avgPercentagePerformance?.threeMonth}>
                %
              </TextStatus>
            </div>
            <div className="flex-1 text-custom-gray300">
              <span className="pl-1">6 tháng</span>
              <br />
              <TextStatus className="text-xl font-bold" value={overviewData?.avgPercentagePerformance?.sixMonth}>
                %
              </TextStatus>
            </div>
            <div className="flex-1 text-custom-gray300">
              <span className="pl-1">1 năm</span>
              <br />
              <TextStatus className="text-xl font-bold" value={overviewData?.avgPercentagePerformance?.portfolioProfit}>
                %
              </TextStatus>
            </div>
            <div className="flex-1 text-custom-gray300">
              <span className="pl-1">YTD</span>
              <br />
              <TextStatus
                className="text-xl font-bold"
                value={leaders[0]?.stockData?.ytdReturns ? (leaders[0]?.stockData?.ytdReturns * 100).toFixed(2) : 0}
              >
                %
              </TextStatus>
            </div>
          </div>
        </div>
        <InvestChart
          // categoryId={currentCtId as string}
          categoryId={categoryId}
          ref={investChartRef}
          currentCategory={null}
        />
      </div>
    </div>
  );
};

export default LeaderChart;

import React from 'react';

const RatingsIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M11 1L13.6265 2.916L16.878 2.91L17.8765 6.004L20.5105 7.91L19.5 11L20.5105 14.09L17.8765 15.996L16.878 19.09L13.6265 19.084L11 21L8.3735 19.084L5.122 19.09L4.1235 15.996L1.4895 14.09L2.5 11L1.4895 7.91L4.1235 6.004L5.122 2.91L8.3735 2.916L11 1Z"
          fill="var(--light-green-color)"
          stroke="var(--light-green-color)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 11L10 13.5L15 8.5"
          stroke="#1F2336"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default RatingsIcon;

import { useRef } from 'react';
import { Table } from 'antd';
import ArrowDownTable from 'assets/icons/ArrowDownTable';
import ArrowUpTable from 'assets/icons/ArrowUpTable';
import BotIcon from 'assets/icons/BotIcon';
import { LineChartOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatPrice } from 'utils';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import ExchangeTradingView from 'components/TradingView/Exchange';

const TableMarket = (props) => {
  const tradingViewRef = useRef();
  const { data } = props;
  const { t } = useTranslation(['recommendationsScreen']);
  const availableExchange = ['vn-index', 'vn-30'];

  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <BotIcon width={15} height={18} />
          <div
            style={{
              marginLeft: '5px',
              fontSize: '11px',
            }}
          >
            {moment().format('HH:mm DD-MM-YYYY')}
          </div>
        </div>
      ),
      dataIndex: 'IndexId',
      key: 'IndexId',
      align: 'left',
      width: '40%',
      fixed: 'left',
      render: (value) => (
        <div
          style={{
            fontWeight: '700',
            fontSize: '12px',
            textTransform: 'uppercase',
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: <span style={{ fontSize: 11 }}>{t('current')}</span>,
      dataIndex: 'Close',
      key: 'Close',
      align: 'right',
      width: '20%',
      render: (value) => (
        <div
          style={{
            color: '#F2C94C',
            fontWeight: '700',
            fontSize: '12px',
          }}
        >
          {formatPrice(Number(value).toFixed(1) || 0)}
        </div>
      ),
    },
    {
      title: <span style={{ fontSize: 11 }}>{t('forecast')}</span>,
      dataIndex: 'Change',
      key: 'Change',
      align: 'right',
      width: '20%',
      render: (value) => (
        <>
          {value > 0 && (
            <div
              style={{
                color: '#1ED994',
                fontWeight: '700',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <ArrowUpTable />
              <div
                style={{
                  marginLeft: '8px',
                }}
              >
                Tăng
              </div>
            </div>
          )}
          {value < 0 && (
            <div
              style={{
                color: '#F24B78',
                fontWeight: '700',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <ArrowDownTable />
              <div
                style={{
                  marginLeft: '8px',
                }}
              >
                Giảm
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: <span style={{ fontSize: 11 }}>{t('chart')}</span>,
      dataIndex: 'IndexId',
      key: 'chart',
      align: 'right',
      width: '20%',
      render: (value) => (
        <LineChartOutlined style={{ fontSize: 17, cursor: 'pointer' }} />
      ),
    },
  ];

  return (
    <Container>
      <ExchangeTradingView ref={tradingViewRef} />
      <Table
        rowKey="IndexId"
        onRow={(record) => {
          return {
            onClick: () => {
              tradingViewRef.current?.open(record.IndexId.replace('-', ''));
            },
          };
        }}
        className="table-global list-sector-table empty-data-table"
        scroll={{ y: 'calc(100vh - 39rem - 274px)' }}
        columns={columns}
        dataSource={data.filter((a) => availableExchange.includes(a.IndexId))}
        pagination={false}
      />
    </Container>
  );
};

export default TableMarket;

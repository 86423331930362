import React from 'react';

const StarIconFill = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14377 4.62802C8.93037 2.66702 9.32368 1.68652 10 1.68652C10.6763 1.68652 11.0696 2.66702 11.8562 4.62802L11.8929 4.71934C12.3373 5.82721 12.5595 6.38114 13.0123 6.71783C13.4652 7.05452 14.0596 7.10776 15.2485 7.21423L15.4635 7.23348C17.4093 7.40774 18.3822 7.49488 18.5904 8.11386C18.7985 8.73284 18.076 9.39019 16.631 10.7049L16.1487 11.1436C15.4172 11.8092 15.0514 12.1419 14.881 12.5781C14.8492 12.6594 14.8227 12.7428 14.8018 12.8276C14.6898 13.2822 14.7969 13.765 15.0111 14.7304L15.0778 15.031C15.4714 16.8053 15.6683 17.6925 15.3246 18.0751C15.1962 18.2181 15.0293 18.3211 14.8438 18.3717C14.3476 18.5071 13.6432 17.933 12.2342 16.7849C11.309 16.031 10.8464 15.6541 10.3153 15.5693C10.1064 15.5359 9.89356 15.5359 9.68466 15.5693C9.15355 15.6541 8.69097 16.031 7.7658 16.7849C6.35684 17.933 5.65237 18.5071 5.15617 18.3717C4.97075 18.3211 4.80384 18.2181 4.67542 18.0751C4.33174 17.6925 4.52857 16.8053 4.92225 15.031L4.98892 14.7304C5.20313 13.765 5.31024 13.2822 5.19818 12.8276C5.17728 12.7428 5.15084 12.6594 5.11904 12.5781C4.94857 12.1419 4.58282 11.8092 3.85131 11.1436L3.36903 10.7049C1.92398 9.39019 1.20146 8.73284 1.40964 8.11386C1.61782 7.49488 2.59073 7.40774 4.53654 7.23348L4.75148 7.21423C5.94039 7.10775 6.53485 7.05452 6.9877 6.71783C7.44055 6.38114 7.66275 5.8272 8.10714 4.71934L8.14377 4.62802Z"
        fill="#F2ED49"
        stroke="#F2ED49"
        strokeWidth="2"
      />
    </svg>
  );
};

export default StarIconFill;

import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { renderProductStatus } from 'utils';
import { getProducts } from 'utils/api';
import ProductForm from './ProductForm';
import { StyledTable, TableButton } from './styled';
import EditIcon from 'assets/icons/EditIcon';

export default function ProductList() {
  const [openProduct, setOpenProduct] = useState(null);
  const [filter, setFilter] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
  });
  const { data, isLoading } = useQuery({
    queryKey: ['getProducts', pagination],
    queryFn: () => getProducts(pagination.current, pagination.pageSize),
    refetchInterval: false,
  });

  const handleClickEdit = (event, item) => {
    event.stopPropagation();
    console.log('handleClickEdit', item);
    setOpenProduct(item._id);
  };

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'name',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Mã sản phẩm',
        dataIndex: 'code',
      },
      {
        title: 'Tên sản phẩm',
        dataIndex: 'name',
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) => renderProductStatus(data),
      },
      {
        title: 'Tác vụ',
        dataIndex: 'status',
        render: (sts, row) => (
          <TableButton onClick={(event) => handleClickEdit(event, row)} disabled={sts !== 'active'}>
            <EditIcon />
          </TableButton>
        ),
      },
    ],
    [],
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };

  return (
    <div>
      <ProductForm onFilter={(values) => setFilter(values)} open={openProduct} onClose={() => setOpenProduct(null)} />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        pagination={{
          total: data?.totalDocs || 0,
          current: data?.page || 1,
          pageSize: data?.limit || 10,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
        }}
        columns={columns}
        dataSource={data?.products || []}
        scroll={{ x: 680 }}
      />
    </div>
  );
}

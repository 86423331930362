import styled from 'styled-components';
import { Button } from 'antd';

export const StyledPrimaryButton = styled(Button)`
  background: var(--light-blue-color) !important;
  border-radius: 4px !important;
  border-color: var(--light-blue-color) !important;
  height: ${(props) => (props.large ? '4.4rem' : '3.4rem')};
  line-height: ${(props) => (props.large ? '3.4rem' : '2.4rem')};

  span {
    color: #fff !important;
  }

  &:active,
  &:focus,
  &:hover {
    background: rgba(0, 140, 207, 0.8) !important;
    border-radius: 4px !important;
    border-color: rgba(0, 140, 207, 0.8) !important;
  }
`;

export const StyledSecondaryButton = styled(Button)`
  background: transparent !important;
  border-radius: 4px !important;
  border: 1px solid var(--border-color) !important;
  height: ${(props) => (props.size === 'large' ? '4.4rem' : '3.4rem')};
  line-height: ${(props) => (props.large ? '3.4rem' : '2.4rem')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:active,
  &:focus,
  &:hover {
    border-radius: 4px !important;
    opacity: 0.8;
  }
`;

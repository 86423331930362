import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from './styles';

const FormCreateWatchlist = ({ watchlistName, inputRef, handleChange }) => {
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const { t } = useTranslation('ratingsScreen');

  return (
    <FormWrapper>
      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 23, offset: 1 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t('stockDetailsSection.addToWatchlistModal.inputLabel')}
          name="watchlistName"
          rules={[
            {
              required: true,
              message: `${t(
                'stockDetailsSection.addToWatchlistModal.validateHelper1'
              )}`,
            },

            {
              //eslint-disable-next-line
              pattern: /^[^`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]+$/,
              message: `${t(
                'stockDetailsSection.addToWatchlistModal.validateHelper2'
              )}`,
            },
          ]}
        >
          <Input value={watchlistName} onChange={handleChange} ref={inputRef} />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default FormCreateWatchlist;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import queryString from 'query-string';
import axiosInstance from 'utils/axiosInstance';

let controller;

const { query } = queryString.parseUrl(window.location.href, {
  parseNumbers: true,
});

const initialState = {
  loading: false,
  derivativeRecommendations: [],
  page: 1,
  limit: 20,
  total: 0,
  sortBy: query.sortBy || 'new',
  search: query.search || '',
  watchListId: query.watchListId || 'all',
  session: query.session || 'today',
  startDate: '',
  endDate: '',
};

const { REACT_APP_RECOMMENDATION_API_URL, REACT_APP_PORTFOLIO_API_V2_URL } =
  process.env;

export const getRecommendations = createAsyncThunk(
  'recommendation/get-derivative-recommendations',
  async (
    {
      search,
      page,
      limit,
      sortBy,
      watchListId,
      session,
      polling,
      startDate,
      endDate,
    },
    { rejectWithValue, getState }
  ) => {
    console.log('getRecommendations', startDate, endDate);
    const state = getState();
    const finalPage = page || state.derivativeRecommendation.page;
    const finalLimit = limit || state.derivativeRecommendation.limit;
    const finalSortBy = sortBy || state.derivativeRecommendation.sortBy;
    const finalWatchListId =
      watchListId || state.derivativeRecommendation.watchListId;
    const finalSession = session || state.derivativeRecommendation.session;
    const finalStart =
      startDate ||
      '2000-01-01 00:00:00' ||
      state.derivativeRecommendation.startDate;
    const finalEnd =
      endDate ||
      '2100-01-01 00:00:00' ||
      state.derivativeRecommendation.endDate;

    controller && controller.abort();
    controller = new AbortController();

    console.time('getRecommendations');

    let url = `${REACT_APP_RECOMMENDATION_API_URL}/derivative/recommendation-stock`;

    const response = await axiosInstance.post(
      url,
      { page: finalPage, limit: finalLimit, from: finalStart, to: finalEnd },
      { signal: controller.signal }
    );

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    const derivativeRecommendations = data.data.docs;

    // console.timeEnd("getRecommendations");

    return {
      derivativeRecommendations,
      total: data.data.totalDocs,
      page: finalPage,
      limit: finalLimit,
      sortBy: finalSortBy,
      search,
      watchListId: finalWatchListId,
      session: finalSession,
      polling: polling,
      startDate: finalStart,
      endDate: finalEnd,
    };
  }
);

export const sendRecommendation = createAsyncThunk(
  'recommendation/send-recommendation',
  async ({ params }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('sendRecommendation');

    const response = await axiosInstance.post(
      `${REACT_APP_RECOMMENDATION_API_URL}/holding-ratio`,
      params,
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('sendRecommendation');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const recommendationSlice = createSlice({
  name: 'derivativeRecommendation',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.derivativeRecommendations = [];
      state.limit = 20;
      state.page = 1;
      state.total = 0;
      state.sortBy = 'new';
      state.watchListId = 'all';
      state.session = 'today';
      state.search = '';
      state.startDate = '';
      state.endDate = '';
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getRecommendations.pending]: (state, action) => {
      if (!action?.meta?.arg?.polling) {
        state.loading = true;
      }
    },
    [getRecommendations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.derivativeRecommendations = payload.derivativeRecommendations;
      state.page = payload.page;
      state.limit = payload.limit;
      state.total = payload.total;
      state.watchListId = payload.watchListId;
      state.search = payload.search;
      state.session = payload.session;
      state.sortBy = payload.sortBy;
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    [getRecommendations.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { reset, setLoading } = recommendationSlice.actions;

export const recommendationSelector = (state) => state.derivativeRecommendation;

export const derivativeRecommendationReducer = recommendationSlice.reducer;

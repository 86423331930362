import React from 'react';

const ExpandIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2 0H0L0 20H2V11L16.17 11L10.67 16.5L12.08 17.92L20 10L12.08 2.08L10.67 3.5L16.17 9L2 9L2 0Z"
          fill="#8E8E93"
        />
      </svg>
    </>
  );
};

export default ExpandIcon;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  leaders: {
    loading: false,
    data: [],
  },
  avatar: '',
  overviewData: {},
  investmentPerformance: {},
  leaderConfig: {
    loading: false,
    data: [],
  },
  modal: {
    type: '',
    content: '',
  },
};

const { REACT_APP_COPY_TRADE_URL } = process.env;

export const getLeaders = createAsyncThunk(
  'copyTrade/getLeaders',

  async (_, { rejectWithValue, dispatch }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/my-leaders-account`
    );

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const getLeaderConfig = createAsyncThunk(
  'copyTrade/getLeaderConfig',

  async (_, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/config/valid-update-time`
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const getLeaderOverview = createAsyncThunk(
  'copyTrade/getLeaderOverview',

  async ({ id, source }, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/${id}/overview-performance?source=${source}`
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const getInvestmentPerformance = createAsyncThunk(
  'copyTrade/getInvestmentPerformance',

  async ({ id, timeRange, source }, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/${id}/investment-performance?timeRange=${timeRange}&source=${source}`
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const editLeader = createAsyncThunk(
  'copyTrade/editLeader',
  async ({ id, body }, { rejectWithValue, dispatch, getState }) => {
    const response = await axiosInstance.put(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/${id}`,
      {
        ...body,
      }
    );

    const data = response.data;

    if (!data || data.status === false) {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    dispatch(getLeaders());
    dispatch(closeEdit());

    toast.success('Cập nhật leader thành công!', settingsSuccess);
    return data;
  }
);

export const postPresignUrl = createAsyncThunk(
  'copyTrade/postPresignUrl',
  async ({ filename }, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/upload/post-presign-url?filename=${filename}`
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }
    return data;
  }
);

export const postUpload = createAsyncThunk(
  'copyTrade/postUpload',
  async ({ presignedUrl, file }, { rejectWithValue }) => {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'image/*',
      },
    });

    const data = response.data;

    if (!data) {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    return data;
  }
);

export const upload = createAsyncThunk(
  'copyTrade/upload',
  async ({ id, s3Filename }, { rejectWithValue, dispatch, getState }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_COPY_TRADE_URL}/api/upload`,
      {
        refKey: 'LEADER',
        refValue: id,
        filename: s3Filename,
        uploadType: 'AVATAR',
      }
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    dispatch(getLeaders());
    dispatch(closeEdit());

    if (getState().uiHandler.language === 'vi') {
      toast.success('Cập nhật avatar thành công!', settingsSuccess);
    } else {
      toast(data.message, settingsSuccess);
    }
    return true;
  }
);

export const getAvatarUrl = createAsyncThunk(
  'copyTrade/getAvatarUrl',
  async ({ filename }, { rejectWithValue }) => {
    const response = await axiosInstance.get(
      `${REACT_APP_COPY_TRADE_URL}/api/upload/presign-url?filename=${filename}`
    );

    const data = response.data;

    if (!data || data.message !== 'ok') {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    return data;
  }
);

export const copyTradeSlice = createSlice({
  name: 'copyTrade',
  initialState,
  reducers: {
    openEdit: (state, { payload }) => {
      state.modal.type = payload.type;
      state.modal.content = payload.content;
    },
    closeEdit: (state) => {
      state.modal.type = '';
      state.modal.content = '';
    },
    editInfo: (state, { payload }) => {
      state.modal.content = payload;
    },
  },
  extraReducers: {
    // ------------------- getLeaders ------------------- //
    [getLeaders.pending]: (state) => {
      state.leaders.loading = true;
    },
    [getLeaders.fulfilled]: (state, { payload }) => {
      state.leaders = {
        loading: false,
        data: payload.data,
      };
    },
    [getLeaders.rejected]: (state) => {
      state.leaders = {
        loading: false,
        data: [],
      };
    },

    // ------------------- getLeaderConfig ------------------- //
    [getLeaderConfig.pending]: (state) => {
      state.leaderConfig.loading = true;
    },
    [getLeaderConfig.fulfilled]: (state, { payload }) => {
      state.leaderConfig = {
        loading: false,
        data: payload.data,
      };
    },
    [getLeaderConfig.rejected]: (state) => {
      state.leaderConfig = {
        loading: false,
        data: [],
      };
    },

    // ------------------- editLeader ------------------- //
    [editLeader.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [editLeader.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [editLeader.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.error.message;
    },

    // ------------------- getAvatarUrl ------------------- //
    [getAvatarUrl.pending]: (state) => {
      state.loading = true;
    },
    [getAvatarUrl.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.avatar = payload.data;
    },
    [getAvatarUrl.rejected]: (state) => {
      state.loading = false;
      state.avatar = '';
    },

    // ------------------- getLeaderOverview ------------------- //
    [getLeaderOverview.pending]: (state) => {
      state.loading = true;
    },
    [getLeaderOverview.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.overviewData = payload.data;
    },
    [getLeaderOverview.rejected]: (state) => {
      state.loading = false;
      state.overviewData = {};
    },

    // ------------------- getInvestmentPerformance ------------------- //
    [getInvestmentPerformance.pending]: (state) => {
      state.loading = true;
    },
    [getInvestmentPerformance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investmentPerformance = payload.data;
    },
    [getInvestmentPerformance.rejected]: (state) => {
      state.loading = false;
      state.investmentPerformance = {};
    },
  },
});

export const loadingSelector = (state) => state.copyTrade.loading;

export const errorSelector = (state) => state.copyTrade.error;
export const errorMessageSelector = (state) => state.copyTrade.errorMessage;

export const leadersSelector = (state) => state.copyTrade.leaders;
export const leaderConfigSelector = (state) => state.copyTrade.leaderConfig;
export const modalSelector = (state) => state.copyTrade.modal;
export const avatarSelector = (state) => state.copyTrade.avatar;
export const overviewDataSelector = (state) => state.copyTrade.overviewData;
export const investmentPerformanceSelector = (state) =>
  state.copyTrade.investmentPerformance;

export const { openEdit, closeEdit, editInfo } = copyTradeSlice.actions;

export default copyTradeSlice.reducer;

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import { NumberInput } from 'components/GlobalComponents/NumberInput';
import { EXCHANGES } from 'constants/commons';
import React, { useEffect, useState } from 'react';

export default function EditPrice({
  max = 5000,
  min = 0,
  base = {
    refPrice: 0,
    exchange: EXCHANGES.HSX,
  },
  data,
  setData,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [step, setStep] = useState(0);
  const { refPrice, exchange } = base;

  const single = (
    <div className="w-full flex gap-3 justify-between">
      <Button
        icon={<RemoveIcon className="!w-9 !h-9" />}
        onClick={() => {
          !isFocused && setIsFocused(true);
          setData((one) => ({
            ...one,
            price:
              one.price - step >= min
                ? Number((one.price - step).toFixed(2))
                : min,
          }));
        }}
        type="text"
      />
      <NumberInput
        value={data.price}
        setValue={(value) => setData((one) => ({ ...one, price: value }))}
        min={min}
        max={max}
        setIsFocused={setIsFocused}
      />
      <Button
        icon={<AddIcon className="!w-9 !h-9" />}
        onClick={() => {
          !isFocused && setIsFocused(true);
          setData((one) => ({
            ...one,
            price:
              one.price + step <= max
                ? Number((one.price + step).toFixed(2))
                : max,
          }));
        }}
        type="text"
      />
    </div>
  );

  const range = (
    <div className="w-full flex justify-center items-center gap-2">
      <NumberInput
        value={data.minPrice}
        setValue={(value) => setData((one) => ({ ...one, minPrice: value }))}
        min={min}
        max={max}
        setIsFocused={setIsFocused}
      />
      <ArrowForwardIcon className="!w-8 !h-8" />
      <NumberInput
        value={data.maxPrice}
        setValue={(value) => setData((one) => ({ ...one, maxPrice: value }))}
        min={min}
        max={max}
        setIsFocused={setIsFocused}
      />
    </div>
  );

  useEffect(() => {
    switch (exchange.substring(0, 3)) {
      case EXCHANGES.HSX:
      case EXCHANGES.HOSE.substring(0, 3): {
        if (refPrice < 9500) setStep(0.01);
        else if (refPrice >= 50000) setStep(0.1);
        else setStep(0.05);
        break;
      }

      case EXCHANGES.HNX:
      case EXCHANGES.UPCOM.substring(0, 3): {
        setStep(0.1);
        break;
      }
      default:
        setStep(0.01);
        break;
    }
  }, [refPrice, exchange]);

  return (
    <div className="-mt-5">
      <Typography.Text className="!text-custom-darkBlue200 mb-3 whitespace-nowrap">
        Giá đặt lệnh
      </Typography.Text>
      <div
        className={clsx(
          'w-full h-[50px] flex items-center !rounded-lg bg-custom-darkBlue600 px-4 border',
          isFocused ? '!border-custom-blue500' : '!border-custom-darkBlue400'
        )}
      >
        {data?.price !== undefined && data?.price > 0 ? single : range}
      </div>
    </div>
  );
}

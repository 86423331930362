import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axiosInstance from 'utils/axiosInstance';

let controller;

const initialState = {
  loading: false,
  data: [],
};

export const getRecommendationHistories = createAsyncThunk(
  'recommendationHistory/get-history',
  async ({ symbol }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    let url = `${process.env.REACT_APP_RECOMMENDATION_API_URL}/recommendation-stock/history?symbol=${symbol}`;
    const response = await axiosInstance.get(url, {
      signal: controller.signal,
    });

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data.docs,
    };
  }
);

export const slice = createSlice({
  name: 'recommendationHistory',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    getHistory: (state, { payload }) => {
      state.symbol = payload;
    },
  },
  extraReducers: {
    [getRecommendationHistories.pending]: (state) => {
      state.loading = true;
    },
    [getRecommendationHistories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data?.sort((a, b) =>
        moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1
      );
    },
    [getRecommendationHistories.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoading, getHistory } = slice.actions;

export const recommendationHistoryReducer = slice.reducer;

import React from 'react';
import ProductList from './ProductList';
import { StyledTabs } from './styled';

export const ProductManagement = () => {

  return (
    <section>
      <h1 className="text-4xl">Danh sách sản phẩm</h1>
      <ProductList />
    </section>
  );
};

import { useState } from 'react';
// import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { reverse, slice, filter } from 'lodash';
import {
  Col,
  Radio,
  Row,
  Space,
  Upload,
  Form,
  Input,
  Button,
  Typography,
  Select,
  notification,
} from 'antd';
import {
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { FormContainer, StyledInput } from './styled';
import {
  getSubAccounts,
  linkStockAccount,
  TypeSend,
} from 'utils/stock-management';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';

// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.worker.min.js`;
const { Dragger } = Upload;

const VIXConnect = ({ handleCancel, onSuccess, nextStep, reAcc = '' }) => {
  const [caseOpt, setCase] = useState(1);
  const [isErrorFile, setErrorFile] = useState(false);
  const [file, setFile] = useState(null);
  const [isReading, setReading] = useState(false);
  const [connectConfig, setConnectConfig] = useState({
    username: reAcc,
    otpMatrix: Array.from(Array(35)).map(() => ''),
  });

  const [selectSubAccount, setSubAccount] = useState('');
  const [status, setStatus] = useState('config'); // config, selectSub
  const [subAccounts, setSubAccounts] = useState([]);
  const [subAccountType, setSubAccountType] = useState('');

  const { mutateAsync, isLoading } = useMutation(linkStockAccount);

  const { mutate: querySub, isLoading: isLoadingSubAccount } = useMutation(
    getSubAccounts,
    {
      onSuccess: ({ data }) => {
        console.log('subAccounts', data);
        setSubAccounts(
          data?.results.map((i) => ({
            value: i.id,
            label: `${i.id} - ${i.name || ''}`,
            type: i.type,
          }))
        );
      },
    }
  );

  const opts = Array.from(Array(35));

  const onChange = (e) => {
    setCase(e.target.value);
  };

  const extractText = async (data) => {
    // try {
    //   let pdfText = [];
    //   const pdf = await pdfjsLib.getDocument(data).promise;
    //   const pages = pdf.numPages;
    //   for (let i = 1; i <= pages; i++) {
    //     const page = await pdf.getPage(i);
    //     const txt = await page.getTextContent();
    //     const text = txt.items
    //       .map((s) => s.str)
    //       .join(' ')
    //       .replace(/  +/g, ' ');
    //     if (text.length) {
    //       pdfText = reverse(text.split(' '));
    //     }
    //   }
    //   if (pdfText.length === 76) {
    //     const gotData = reverse(
    //       filter(slice(pdfText, 0, 70), (_, idx) => !(idx % 2))
    //     );
    //     if (gotData.length === 35) {
    //       setConnectConfig((state) => ({
    //         ...state,
    //         otpMatrix: gotData,
    //       }));
    //     }
    //   } else {
    //     setErrorFile(true);
    //   }
    // } catch (err) {
    //   console.log('extractText err', err);
    // }
    // setReading(false);
  };

  const uploadProps = {
    name: 'file',
    accept: '.pdf',
    action: '',
    showUploadList: false,
    beforeUpload: (file) => {
      setErrorFile(false);
      setReading(true);
      setFile(file);
      const fr = new FileReader(); // Create a new FileReader object
      fr.readAsDataURL(file); // Read the file as data UR
      fr.onload = () => {
        extractText(fr.result);
      };
      return false;
    },
  };

  const isValidInput = () => {
    if (status === 'config') {
      const findEmptyOtp = connectConfig.otpMatrix.findIndex((i) => !i);
      return (
        !connectConfig.username || !connectConfig.password || findEmptyOtp > -1
      );
    } else {
      return !selectSubAccount;
    }
  };

  const onSubmitConnect = async () => {
    console.log('connectConfig cts', connectConfig);
    if (status === 'config') {
      try {
        const response = await mutateAsync({
          type: TypeSend.VIX,
          params: {
            ...connectConfig,
            isUpdateSession: !!reAcc,
            authType: 'CREDENTIAL',
          },
        });
        console.log('link VIX Stock res', response);
        if (nextStep) {
          querySub(connectConfig.username);
          setStatus('selectSub');
        } else {
          onSuccess();
          toast.success(
            'Bạn đã kết nối tài khoản VIX thành công!',
            settingsSuccess
          );
        }
      } catch (error) {
        toast.error('Kết nối tài khoản lỗi!', settingsError);
      }
    } else if (status === 'selectSub') {
      onSuccess({
        source: TypeSend.VIX,
        username: connectConfig.username,
        subAccountId: selectSubAccount,
        accountType: subAccountType,
      });
    }
  };

  return (
    <FormContainer>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
      >
        <div className="text-white flex flex-col gap-3 mb-4">
          {status === 'config' && (
            <div className="flex flex-col gap-4">
              <Typography.Text className="font-bold text-xl">
                Kết nối tài khoản VIX
              </Typography.Text>
              <StyledInput>
                <Typography.Text className="text-[14px] mb-2">
                  Số tài khoản
                </Typography.Text>
                <Input
                  value={connectConfig.username}
                  prefix="072"
                  className="!border-custom-darkBlue200 border !rounded-xl"
                  onChange={(e) =>
                    setConnectConfig((state) => ({
                      ...state,
                      username: e.target.value,
                    }))
                  }
                />
              </StyledInput>

              <StyledInput>
                <Typography.Text className="text-[14px] mb-2">
                  Mật khẩu
                </Typography.Text>
                <Input.Password
                  className="!border-custom-darkBlue200 border !rounded-xl"
                  autoCapitalize="off"
                  type="password"
                  name="password"
                  placeholder={'Mật khẩu'}
                  value={connectConfig.password}
                  onChange={(e) =>
                    setConnectConfig((state) => ({
                      ...state,
                      password: e.target.value,
                    }))
                  }
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </StyledInput>

              <StyledInput>
                <Typography.Text className="text-[14px]">
                  Thẻ ma trận
                </Typography.Text>
              </StyledInput>
              <Radio.Group
                onChange={onChange}
                value={caseOpt}
                className="w-full !block"
              >
                <Space direction="vertical" className="w-full">
                  <Radio value={1}>
                    <Typography.Text className="text-white">
                      Tải lên file PDF
                    </Typography.Text>
                  </Radio>

                  {caseOpt === 1 && (
                    <div>
                      <Typography.Text className="!text-custom-gray300">
                        Kéo và thả file tại đây
                      </Typography.Text>
                      <Typography.Text className="!text-custom-gray300 mb-2">
                        Vui lòng sử dụng file PDF đúng với định dạng do VIX cung
                        cấp!
                      </Typography.Text>
                      <Dragger {...uploadProps} disabled={isReading}>
                        {file ? (
                          <div className="text-left text-white pl-2">
                            {file.name}
                          </div>
                        ) : (
                          <>
                            <div className="text-center">
                              {/* TODO: Upload Icon */}
                              {/* <Icon
                                name={IconName.Upload}
                                style={{
                                  fontSize: '40px',
                                  color: 'transparent',
                                }}
                              /> */}
                              <p className="text-custom-gray300">PDF</p>
                            </div>
                            <p className="text-white">
                              Kéo thả hoặc bạn có thể tải file bằng cách
                            </p>
                            <Button type="link" block>
                              Bấm vào đây
                            </Button>
                          </>
                        )}
                      </Dragger>
                      {isErrorFile && (
                        <Typography.Text className="text-[#EF1944]">
                          <InfoCircleOutlined /> Sai định dạng file, vui lòng sử
                          dụng file đúng định dạng được cung cấp!
                        </Typography.Text>
                      )}
                    </div>
                  )}
                  <Radio value={2}>
                    <Typography.Text className="text-white">
                      Nhập thủ công
                    </Typography.Text>
                  </Radio>
                  {caseOpt === 2 && (
                    <Row
                      gutter={[8, 8]}
                      className="text-gray300 rounded-lg p-2"
                    >
                      {opts.map((i, idx) => (
                        <Col key={idx} flex="20%">
                          <StyledInput className="justify-around flex items-center">
                            <Typography.Text className="text-sm text-gray300 w-[17px]">
                              {idx + 1}
                            </Typography.Text>
                            <Input
                              value={connectConfig.otpMatrix[idx] || ''}
                              onChange={(ev) => {
                                const nextMatrix = [...connectConfig.otpMatrix];
                                nextMatrix[idx] = ev.target.value;
                                setConnectConfig((state) => ({
                                  ...state,
                                  otpMatrix: nextMatrix,
                                }));
                              }}
                              className="border-gray300 !w-[52px] h-[26px] p-1 !rounded-lg !text-[12px]"
                            />
                          </StyledInput>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Space>
              </Radio.Group>
            </div>
          )}

          {status === 'selectSub' && (
            <div className="flex flex-col gap-4">
              <Typography.Text level={4} className="font-bold">
                Bạn đã kết nối tài khoản VIX thành công, vui lòng chọn tiểu
                khoản để tiếp tục
              </Typography.Text>
              <StyledInput>
                <Typography.Text className="text-[14px] mb-2">
                  Chọn tiểu khoản
                </Typography.Text>
                <Select
                  value={selectSubAccount}
                  style={{ width: '100%' }}
                  loading={isLoadingSubAccount}
                  disabled={isLoadingSubAccount}
                  options={subAccounts}
                  onChange={(e, opt) => {
                    setSubAccount(String(opt.value));
                    setSubAccountType(String(opt.type));
                  }}
                />
              </StyledInput>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 mb-2">
          <Button
            ghost
            type="primary"
            className="!m-0 !mr-2 !border-none !bg-custom-darkBlue100 px-8 !rounded-lg"
            onClick={handleCancel}
          >
            <span className="!text-custom-blue500">Hủy</span>
          </Button>
          <Button
            type="primary"
            className="!m-0 !text-white !bg-custom-blue500 px-8 !rounded-lg !border-none"
            loading={isLoading || isLoadingSubAccount}
            onClick={onSubmitConnect}
            disabled={isValidInput()}
          >
            Tiếp tục
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
};

export default VIXConnect;

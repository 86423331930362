/* -------------------------------------------------------------------------- */
/*                             LIST SECTORS SLICE                             */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  listSectors: [],
};

const { REACT_APP_URL } = process.env;

export const getListSectors = createAsyncThunk(
  'listSectors/getListSectors',

  async (
    { timeDistance, page, sortSector },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sectors`,
        {
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      // if (data.status) {
      //   dispatch(
      //     getPortfolioNews({
      //       portfolio_id: getState().portfolioList.portfolioDefault._id,
      //       sector: "",
      //     })
      //   );
      // }

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListSectorsSlice = createSlice({
  name: 'ListSectors',
  initialState,
  reducers: {},
  extraReducers: {
    [getListSectors.pending]: (state) => {
      state.loading = true;
    },
    [getListSectors.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listSectors = payload?.data;
    },
    [getListSectors.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const listSectorsReducer = getListSectorsSlice.reducer;

import styled from 'styled-components';

export const IssueListContainer = styled.div`
  background: var(--primary-color);

  position: relative;
  overflow-y: scroll;

  .ant-collapse > .ant-collapse-item,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: var(--background-color);
    color: var(--white-color);
  }

  .ant-collapse {
    background-color: var(--light-grey-color);
    border: 1px solid var(--border-color);
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid var(--border-color);
  }

  .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: var(--section-header-color);
    border-top: 1px solid var(--border-color);
  }

  .ant-collapse {
    margin: 2rem;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    color: var(--white-color);
    border: 1px solid transparent;
  }

  .ant-collapse {
    border: 1px solid var(--border-color);
  }

  .ant-tag.ant-tag-has-color {
    height: 24px;
    margin-left: 1.2rem;
  }
`;

export const StyledTitle = styled.div`
  padding: 2rem;
  background: var(--section-header-color);
  font-weight: 700;
`;

export const StyledBody = styled.div``;

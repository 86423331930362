import React from 'react';

const RatingsIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M19.5751 15.4555L15.0011 10.8818C14.0988 9.97949 12.7512 9.80373 11.6654 10.3388L7.50154 6.17518V3.74963L2.50181 0L0.00195299 2.49976L3.75174 7.49927H6.17739L10.3412 11.6629C9.81 12.7488 9.98187 14.0963 10.8842 14.9985L15.4581 19.5723C16.0284 20.1426 16.9502 20.1426 17.5166 19.5723L19.5751 17.5139C20.1415 16.9437 20.1415 16.0219 19.5751 15.4555ZM12.9583 8.7882C14.0637 8.7882 15.1027 9.21785 15.8839 9.99902L16.6417 10.7568C17.2588 10.4873 17.8447 10.1123 18.3525 9.60453C19.8016 8.15545 20.2938 6.11659 19.8329 4.26521C19.7469 3.91368 19.3056 3.7926 19.0478 4.05039L16.1417 6.95635L13.4895 6.51499L13.0481 3.8629L15.9542 0.956938C16.212 0.69915 16.087 0.257787 15.7315 0.167952C13.8801 -0.289034 11.8411 0.203105 10.3959 1.64828C9.28269 2.76145 8.75928 4.23006 8.78662 5.69476L11.9935 8.90147C12.3099 8.82726 12.638 8.7882 12.9583 8.7882ZM8.8999 11.991L6.68518 9.77639L0.732381 15.7328C-0.244127 16.7093 -0.244127 18.2912 0.732381 19.2676C1.70889 20.2441 3.29083 20.2441 4.26734 19.2676L9.0952 14.44C8.79834 13.6627 8.7085 12.8152 8.8999 11.991ZM2.50181 18.4357C1.98622 18.4357 1.56437 18.0139 1.56437 17.4983C1.56437 16.9788 1.98231 16.5609 2.50181 16.5609C3.02132 16.5609 3.43926 16.9788 3.43926 17.4983C3.43926 18.0139 3.02132 18.4357 2.50181 18.4357Z"
          fill="#F27049"
        />
      </svg>
    </>
  );
};

export default RatingsIcon;

import React from 'react';
import DATXPoint from '../OverviewTab/DATXPoint';
import DATXRatingPoint from '../OverviewTab/DATXRatingPoint';
import { StyledWrapper } from './styles';

const DATXRatingPointTab = ({ isLoading }) => {
  return (
    <StyledWrapper>
      <DATXRatingPoint isLoading={isLoading} />
      <DATXPoint />
    </StyledWrapper>
  );
};

export default DATXRatingPointTab;

import styled from 'styled-components';

export const RatingsScreenContainer = styled.div`
  display: grid;
  grid-template-columns: 536px 1fr 1fr;
  grid-gap: 2rem;
  overflow: hidden;

  @media only screen and (min-width: 143.75em) {
    grid-template-columns: 780px auto 780px;
  }

  @media only screen and (max-width: 112.5em) {
    grid-template-columns: 500px 0.45fr 0.55fr;
  }

  @media only screen and (max-width: 106.25em) {
    grid-template-columns: 450px 1.45fr 0.55fr;
  }

  @media only screen and (max-width: 93.75em) {
    grid-template-columns: ${(props) =>
      props.expandSidebar
        ? '40vw calc(60vw - 29.2rem)'
        : '40vw calc(60vw - 15.2rem)'};
    overflow-y: scroll;
  }

  @media only screen and (max-width: 81.1875em) {
    grid-template-columns: calc(50vw - 6rem) 50vw;
  }

  @media only screen and (max-width: 37.5em) {
    grid-template-columns: calc(100vw - 4rem);
    height: auto;
  }

  @media only screen and (min-height: 1081px) and (max-width: 81.1875em) {
    overflow-y: scroll;
    height: auto;
  }

  & > div > div {
    background: var(--primary-color);
    position: relative;
  }

  & > div:nth-child(1) {
    @media only screen and (max-width: 93.75em) {
      grid-area: 1/1/2/2;
    }

    @media only screen and (max-width: 37.5em) {
      grid-area: 1/1/2/2;
    }
  }

  & > div:nth-child(2) {
    @media only screen and (max-width: 93.75em) {
      grid-area: 2/1/2/2;
    }

    @media only screen and (max-width: 37.5em) {
      grid-area: 2/1/3/2;
    }
  }

  & > div:nth-child(3) {
    @media only screen and (max-width: 93.75em) {
      grid-area: 1/2/3/2;
    }

    @media only screen and (max-width: 37.5em) {
      grid-area: 3/1/4/2;
    }
  }

  & > div:nth-child(4) {
    @media only screen and (max-width: 93.75em) {
      grid-area: 1/1/4/2;
    }

    @media only screen and (max-width: 37.5em) {
      grid-area: 4/1/5/2;
    }
  }

  & > div:nth-child(5) {
    @media only screen and (max-width: 56.25em) {
      grid-area: 4/1/5/3;
    }

    @media only screen and (max-width: 37.5em) {
      grid-area: 5/1/6/2;
    }
  }

  & > div:nth-child(6) {
    @media only screen and (max-width: 56.25em) {
      display: none;
    }
  }
`;

export const StyledSectionHeader = styled.div`
  background: var(--section-header-color) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  /* height: 4.4rem; */

  .ant-input-affix-wrapper-focused {
    border-bottom: 1px solid transparent !important;
  }

  .ant-input-affix-wrapper > input.ant-input,
  .ant-input,
  .ant-input-affix-wrapper {
    border-bottom: 1px solid transparent !important;
  }

  .anticon.anticon-close > svg > path {
    fill: var(--border-color);
  }

  .ant-input-group-addon {
    display: none;
  }

  .ant-badge-count {
    box-shadow: 0 0 0 1px transparent;
  }
`;

export const StyledHeaderText = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  padding: 1.6rem 0;
`;

export const StyledHeaderIcon = styled.div`
  cursor: pointer;
`;

export const StyledLeftContainer = styled.div`
  background: var(--primary-color);

  max-height: ${(props) =>
    props.closeAnnouncement
      ? 'calc(100vh - 6rem)'
      : 'calc(100vh - 10rem - var(--announcement-height))'};
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 93.75em) {
    overflow-y: hidden;
    max-height: fit-content !important ;
  }
`;

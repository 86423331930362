import { FullscreenExitOutlined } from '@ant-design/icons';
import { Empty, Modal, Tooltip } from 'antd';
import ExpandFourAnglesIcon from 'assets/icons/ExpandFourAnglesIcon';
import LineAndScatterChartGrid from 'components/ChartComponents/DATXRatingPointChart';
import { getDATXRating } from 'features/ratings/DATXRatingSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';
import { StyledSectionTitle } from '../../styles';
import {
  MarketStatusWrapper,
  StyledHeaderIcon,
  StyledTopTab,
  StyledTopTabItem,
  StyledWrapper,
} from './styles';

const DATXRatingPoint = ({ isLoading }) => {
  const { pointData, consider, loadingChart } = useSelector(
    (state) => state.DATXRating
  );
  const { lineAndScatterChart } = useSelector((state) => state.uiHandler);
  const { currentSelectedStock } = useSelector((state) => state.stockDetails);
  const [dateFormatState, setDateFormatState] = useState('days');
  const dispatch = useDispatch();

  var __DATXRatingPoint = [];

  pointData?.forEach((_row, _index) => {
    if (_row.date) {
      var _newRow = {};
      _newRow.date = new Date(_row.date);

      _newRow.value = _row.total_points;
      __DATXRatingPoint.push(_newRow);
    }
  });

  const [activeIndexDATX, setActiveIndexDATX] = useState(3);
  const [tickChart, setTickChart] = useState(10);

  const boxsDATXRatingPoint = ['5m', '15m', '30m', '1D', '1W', '1M'];

  const handleOnClickDATX = (index) => {
    setActiveIndexDATX(index);

    if (activeIndexDATX !== index) {
      switch (index) {
        case 0:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 300,
            })
          );
          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 1:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 900,
            })
          );
          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 2:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 1800,
            })
          );
          setTickChart(10);
          setDateFormatState('minutes');

          break;

        case 3:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 86400,
            })
          );

          setTickChart(10);
          setDateFormatState('days');

          break;

        case 4:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 86400 * 7,
            })
          );

          setTickChart(3);
          setDateFormatState('days');

          break;

        case 5:
          dispatch(
            getDATXRating({
              stockCode: currentSelectedStock,
              timeDistance: 86400 * 30,
            })
          );
          setTickChart(10);
          setDateFormatState('days');

          break;

        default:
          break;
      }
    }
  };

  const { t } = useTranslation('ratingsScreen');

  const boxDATXRatingPoint = boxsDATXRatingPoint.map((el, index) => {
    return (
      <StyledTopTabItem
        key={index}
        onClick={() => handleOnClickDATX(index)} // pass the index
        className={activeIndexDATX === index ? 'active' : 'unactive'}
      >
        {el}
      </StyledTopTabItem>
    );
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledWrapper>
        <StyledSectionTitle>
          {' '}
          {t('stockDetailsSection.tab1.DATXRatingPoint.title')}
        </StyledSectionTitle>
        <div
          style={{ paddingTop: '3.2rem', paddingBottom: '1.2rem' }}
          onClick={showModal}
        >
          <Tooltip
            title={t('helper.toolTip1')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <StyledHeaderIcon>
              <ExpandFourAnglesIcon />
            </StyledHeaderIcon>
          </Tooltip>
        </div>
      </StyledWrapper>

      <StyledTopTab style={{ width: '90%' }}>{boxDATXRatingPoint}</StyledTopTab>

      <MarketStatusWrapper style={{ position: 'relative' }}>
        {__DATXRatingPoint.length > 1 && !isLoading ? (
          <>
            <LineAndScatterChartGrid
              type="hybrid"
              data={__DATXRatingPoint}
              chartHeight={220}
              marginRight={40}
              tickStroke={lineAndScatterChart.tickStroke}
              buyConsiderValue={consider?.buy}
              sellConsiderValue={consider?.sell}
              tickChart={6}
              dateFormatState={dateFormatState}
            />

            {loadingChart && <AbsoluteLoader noBg />}
          </>
        ) : (
          <Empty
            style={{ padding: '100px 0' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </MarketStatusWrapper>

      <Modal
        centered
        destroyOnClose
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <Tooltip
            title={t('helper.toolTip2')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                lineHeight: '4.4rem',
                justifyContent: 'center',
              }}
              onClick={handleCancel}
            >
              <FullscreenExitOutlined
                style={{
                  fontSize: 20,
                  color: 'var(--border-color)',
                  display: 'flex',
                  alignItems: 'center',
                  height: '4.4rem',
                }}
              />
            </span>
          </Tooltip>
        }
        width="100rem"
      >
        <>
          <StyledWrapper
            style={{
              padding: '1.6rem',
              background: 'var(--section-header-color)',
            }}
          >
            <div>{t('stockDetailsSection.tab1.DATXRatingPoint.title')}</div>
          </StyledWrapper>

          <StyledTopTab
            className="chart-modal"
            style={{ width: '90%', margin: '0 auto', padding: '2rem 12rem' }}
          >
            {boxDATXRatingPoint}
          </StyledTopTab>

          <MarketStatusWrapper style={{ position: 'relative' }}>
            {__DATXRatingPoint.length > 1 ? (
              <>
                <LineAndScatterChartGrid
                  type="hybrid"
                  data={__DATXRatingPoint}
                  chartHeight={400}
                  marginRight={60}
                  tickStroke={lineAndScatterChart.tickStroke}
                  buyConsiderValue={consider.buy}
                  sellConsiderValue={consider.sell}
                  tickChart={tickChart}
                  dateFormatState={dateFormatState}
                />

                {loadingChart && <AbsoluteLoader noBg />}
              </>
            ) : (
              <Empty
                style={{ padding: '100px 0' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </MarketStatusWrapper>
        </>
      </Modal>
    </>
  );
};

export default DATXRatingPoint;

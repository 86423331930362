import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';

const TableRecommendationPerformance = (props) => {
  const { data, loading } = props;
  const { t } = useTranslation(['recommendationsScreen']);

  const columns = [
    {
      title: t('reportRecommendationPerformance.agency'),
      dataIndex: 'full_name',
      key: 'full_name',
      align: 'left',
      width: '20%',
      fixed: 'left',
    },
    {
      title: t('reportRecommendationPerformance.temporaryPerformance'),
      dataIndex: 'efficiency_temporary',
      align: 'left',
      width: '40%',
      render: (_, row) =>
        row.efficiency_temporary < 0 ? (
          <span style={{ color: '#D00000' }}>
            {(row.efficiency_temporary || 0).toFixed(2)}%
          </span>
        ) : (
          <span style={{ color: '#05FF00' }}>
            {(row.efficiency_temporary || 0).toFixed(2)}%
          </span>
        ),
      sorter: (a, b) => a.efficiency_temporary - b.efficiency_temporary,
    },
    {
      title: t('reportRecommendationPerformance.closedPerformance'),
      dataIndex: 'efficiency_closed',
      align: 'left',
      width: '40%',
      render: (_, row) =>
        row.efficiency_closed < 0 ? (
          <span style={{ color: '#D00000' }}>
            {(row.efficiency_closed || 0).toFixed(2)}%
          </span>
        ) : (
          <span style={{ color: '#05FF00' }}>
            {(row.efficiency_closed || 0).toFixed(2)}%
          </span>
        ),
      sorter: (a, b) => a.efficiency_closed - b.efficiency_closed,
    },
  ];

  return (
    <Container>
      <Table
        className="table-global list-sector-table empty-data-table modal-table"
        scroll={{ y: 'calc(100vh - 39rem - 274px)' }}
        loading={{
          spinning: loading,
          indicator: <AbsoluteLoader size="default" />,
        }}
        columns={columns}
        pagination={{ pageSize: 10 }}
        dataSource={data}
      />
    </Container>
  );
};

export default TableRecommendationPerformance;

import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Typography } from 'antd';
import clsx from 'clsx';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import { StyledInput } from 'components/GlobalComponents/styled';
import { COOKIES_KEY } from 'constants/commons';
import Cookies from 'js-cookie';
import React from 'react';
import { toast } from 'react-toastify';
import { verifyTradingLeader } from 'utils/api';

const Password = ({ open, setOpen, onConfirm }) => {
  const [form] = Form.useForm();
  const remember = Form.useWatch('remember', form);

  const mutation = useMutation({
    mutationFn: (data) => verifyTradingLeader(data),
    onSuccess: async (data) => {
      if (data.message.includes('success')) {
        onConfirm();
        form.resetFields();
        setOpen(false);
        if (remember) {
          Cookies.set(COOKIES_KEY.ctRememberPassword, true, { expires: 60 });
        }
      } else {
        toast.error(data.message_translated);
      }
    },
    onError: async (error) => {
      toast.error('Có lỗi xảy ra, vui lòng thử lại', error);
    },
  });

  const handleFinish = (values) => {
    mutation.mutate({
      password: values.password,
      cmd: 'biometric',
      isEnabled: false,
    });
  };

  return (
    <span>
      <StyledModal
        open={open}
        destroyOnClose={true}
        maskClosable={true}
        className="!w-[480px]"
        title={
          <Typography.Text className="text-xl">
            Nhập mật khẩu đặt lệnh
          </Typography.Text>
        }
        footer={null}
        onCancel={() => setOpen(false)}
        style={{ top: 225 }}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={() => {}}
          onFinish={handleFinish}
        >
          <Typography.Text className="!text-custom-gray300 ">
            Vui lòng nhập mật khẩu đăng nhập để tiếp tục tiến hành đặt lệnh.
          </Typography.Text>

          <Form.Item
            name="password"
            className="mt-3 mb-2"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu',
              },
            ]}
          >
            <StyledInput
              type="password"
              maxLength={20}
              placeholder="Nhập mật khẩu"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox className="text-white">
              Lưu mật khẩu cho lần đặt lệnh sau
            </Checkbox>
          </Form.Item>
          <div className="flex justify-end py-4">
            <Button
              className={clsx('!h-16 !px-16 !rounded-lg !border-0')}
              onClick={() => setOpen(false)}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              className={clsx(
                'ml-6 !h-16 !px-16 !rounded-lg border-0',
                '!bg-custom-blue500 border-0 hover:!bg-blue-500'
              )}
              onClick={() => form.submit()}
            >
              Đặt lệnh
            </Button>
          </div>
        </Form>
      </StyledModal>
    </span>
  );
};

export default Password;

import styled from 'styled-components';

export const NewsBoxWrapper = styled.div`
  overflow-y: auto;

  @media (max-width: 100em) {
    overflow-y: unset;
  }

  button.back-to-top-btn {
    display: none;
  }

  .newsbox-body {
    max-height: calc(100% - 4.4rem);
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color);
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--border-color);
      }
    }

    .empty {
      grid-area: 1/1/2/3;
      height: calc(
        100vh - ${(props) => (props.closeAnnouncement ? '12.4rem' : '19.4rem')}
      );

      @media (max-width: 100em) {
        height: 400px;
      }
    }

    @media (max-width: 100em) {
      max-height: unset !important;
      display: grid;
      grid-template-columns: 1fr 1fr;

      *:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    @media (max-width: 56.25em) {
      display: block;
    }
  }
`;

export const PostListStyled = styled.div`
  height: 100%;
  overflow-y: auto;

  /* &::-webkit-scrollbar {
    display: ${(props) => (props.loading === 'true' ? 'none' : 'block')};
  } */

  @media only screen and (max-width: 56.25em) {
    overflow-y: unset;
    margin-top: 2rem;
  }

  .infinite-scroll-loader-wrapper {
    text-align: center;
    padding: 3rem 0 1rem;
  }
`;

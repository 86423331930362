import { Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TelegramSvg } from 'assets/images/telegram.svg';
import { ReactComponent as ZaloSvg } from 'assets/images/zalo.svg';
import { ReactComponent as TickSvg } from 'assets/images/tick.svg';
import { ReactComponent as ClaimSvg } from 'assets/images/claim.svg';
import { Actions, Container, TableButton, ConnectionCount } from './styles';
import EditIcon from 'assets/icons/EditIcon';
import { DeleteFilled } from '@ant-design/icons';

const TableCustomerFiles = (props) => {
  const { data, onClickUpdate, onClickDelete } = props;
  const { t } = useTranslation(['recommendationsScreen']);

  const handleClickUpdate = (event, item) => {
    event.stopPropagation();
    if (onClickUpdate) {
      onClickUpdate(item);
    }
  };

  const handleClickDelete = (event, record) => {
    event.stopPropagation();
    if (onClickDelete) {
      onClickDelete(record);
    }
  };

  const renderState = (_, row) => {
    const failures = row.connections.filter((i) => i.state !== 'Succeed');
    if (failures.length > 0) {
      return (
        <Tag color="#F2994A29">
          {failures.length} {t('failedConnection')}
        </Tag>
      );
    }
    return <Tag color="#1ED99429">{t('working')}</Tag>;
  };

  const columns = [
    {
      title: t('customerFileTable.col1'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '15%',
      fixed: 'left',
    },
    {
      title: t('customerFileTable.col2'),
      dataIndex: 'platforms',
      align: 'left',
      width: '15%',
      render: (_, row) => (
        <>
          {row.connections.some((i) => i.code === 'telegram') && (
            <TelegramSvg />
          )}
          {row.connections.some((i) => i.code === 'zalo') && <ZaloSvg />}
        </>
      ),
    },
    {
      title: t('customerFileTable.col3'),
      dataIndex: 'connections',
      align: 'left',
      width: '15%',
      render: (_, row) => (
        <ConnectionCount>
          {row.connections.length}
          {row.connections.some((i) => i.state !== 'Succeed') ? (
            <ClaimSvg />
          ) : (
            <TickSvg />
          )}
        </ConnectionCount>
      ),
    },
    {
      title: t('customerFileTable.col4'),
      dataIndex: 'state',
      align: 'left',
      width: '40%',
      render: renderState,
    },
    {
      title: '',
      dataIndex: 'actions',
      align: 'left',
      fixed: 'right',
      width: '15%',
      render: (text, row) => (
        <Actions
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TableButton onClick={(event) => handleClickUpdate(event, row)}>
            <EditIcon />
          </TableButton>
          <TableButton onClick={(event) => handleClickDelete(event, row)}>
            <DeleteFilled />
          </TableButton>
        </Actions>
      ),
    },
  ];

  return (
    <Container>
      <Table
        className="table-global list-sector-table empty-data-table modal-table"
        scroll={{ y: 'calc(100vh - 39rem - 274px)', x: 1000 }}
        columns={columns}
        dataSource={data}
      />
    </Container>
  );
};

export default TableCustomerFiles;

import { gql } from '@apollo/client';

export const STOCK_PRICE = gql`
  query StockPrice($symbol: String!) {
    stockPrice(symbol: $symbol) {
      lastPrice
      ratioChange
      change
      refPrice
      ceiling
      floor
      low
      high
      totalVol
      totalVal
      exchange
    }
  }
`;

import { Card, Typography } from 'antd';
import EditOutlineIcon from 'assets/icons/EditOutlineIcon';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import React from 'react';

export const LeaderInfo = ({ title, subTitle, content, onEdit, className }) => {
  return (
    <Card
      className={clsx(
        'w-full !bg-custom-darkBlue600 border-0 !rounded-xl',
        className
      )}
    >
      <div className="flex items-center justify-between">
        <Typography.Text className="text-white font-bold text-[16px]">
          {title}
        </Typography.Text>
        <div className="flex items-center">
          <Typography.Text className="text-white font-bold text-[16px] mr-3">
            {subTitle}
          </Typography.Text>
          <div
            className="w-8 h-8 cursor-pointer fill-[#908F99] hover:fill-gray-500"
            onClick={onEdit}
          >
            <EditOutlineIcon />
          </div>
        </div>
      </div>
      <Typography.Text className="block text-[14px] mt-3 text-justify !text-[#908F99] max-h-[320px] overflow-auto">
        {content ? content : !isNil(subTitle) ? '' : 'Chưa cập nhật'}
      </Typography.Text>
    </Card>
  );
};

import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { expandSidebarSelector } from 'features/uiHandler/uiHandlerSlice';
import { SidebarContainer, SidebarDesktopWrapper } from './styles';
import Sidebar from './index';

const SidebarDesktop = () => {
  const expandSidebar = useSelector(expandSidebarSelector);

  return (
    <SidebarDesktopWrapper>
      <SidebarContainer expandSidebar={expandSidebar}>
        <Layout>
          <Layout.Sider>
            <Sidebar type="desktop" />
          </Layout.Sider>
        </Layout>
      </SidebarContainer>
    </SidebarDesktopWrapper>
  );
};

export default SidebarDesktop;

import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import { StyledTable } from 'components/GlobalComponents/styled';
import { DATE_TIME_FORMAT, DATE_FORMAT, DATE_FORMAT_SERVER } from 'constants/date';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { formatCurrency, settingsError, settingsSuccess } from 'utils';
import { cancelOrderRoot, editOrderRoot, getTradingRootList } from 'utils/api';
import CopierOrderFilter, { OPTIONS } from './OrderFilter';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import clsx from 'clsx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OrderConfirmation from './OrderConfirmation';
import OrderEdit from './OrderEdit';
import OrderDetail from './OrderDetail';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export default function CopierOrderList() {
  const [filter, setFilter] = useState({});

  const [confirmation, setConfirmation] = useState({
    type: null,
    data: null,
  });

  const [edit, setEdit] = useState(null);

  const [orderId, setOrderId] = useState('');

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getTradingRootList', filter],
    queryFn: () =>
      getTradingRootList({
        ...(filter?.code && { code: filter?.code }),
        ...(filter?.statusIn && { statusIn: filter?.statusIn }),
        ...(filter?.typeIn && { typeIn: filter?.typeIn }),
        ...(filter?.kindIn && { kindIn: filter?.kindIn }),
      }),
    retry: 3,
  });

  const { mutateAsync: cancelOrder, isLoading: cancelLoading } = useMutation(cancelOrderRoot);
  const { mutateAsync: editOrder, isLoading: editLoading } = useMutation(editOrderRoot);

  const isEnableEditDelete = (status) => {
    switch (status) {
      case 'MATCHED':
      case 'EXPIRED':
      case 'CANCELLED':
        return true;
      default:
        return true;
    }
  };

  const onCancel = async (id) => {
    try {
      await cancelOrder(id);
      toast.success('Hủy lệnh gốc thành công!', settingsSuccess);
      setConfirmation({ type: null, data: null });
      refetch();
    } catch (error) {
      toast.error('Hủy lệnh gốc thất bại!', settingsError);
    }
  };

  const onEdit = async (data) => {
    console.log('data :', data);
    try {
      await editOrder({
        id: data.id,
        data: {
          price: data.price,
          minPrice: data.minPrice,
          maxPrice: data.maxPrice,
          volume: data.volume,
          from: moment(data.availableDateFrom).format(DATE_FORMAT_SERVER),
          to: dayjs(data.date).format(DATE_FORMAT_SERVER),
          symbol: data.symbol,
          tradeType: data.orderType,
          orderType: data.orderKind,
        },
      });
      toast.success('Sửa lệnh gốc thành công!', settingsSuccess);
      setConfirmation({ type: null, data: null });
      refetch();
    } catch (error) {
      toast.error('Sửa lệnh gốc thất bại!', settingsError);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Số hiệu lệnh',
        dataIndex: 'id',
        className: 'text-center',
        width: 110,
      },
      {
        title: 'Thời gian',
        dataIndex: 'createdAt',
        width: 130,
        render: (data) => moment(data).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Mã',
        className: 'text-center',
        dataIndex: 'code',
        width: 70,
      },
      {
        title: 'Loại lệnh',
        dataIndex: 'orderType',
        className: 'text-center',
        render: (data) =>
          data === 'BUY' ? <span className="text-green">Mua</span> : <span className="text-red">Bán</span>,
        width: 100,
      },
      {
        title: 'Kiểu lệnh',
        className: 'text-center',
        dataIndex: 'orderKind',
        render: (data) => (data === 'SLTP' ? 'SL/TP' : 'Thường'),
        width: 120,
      },
      {
        title: 'Giá đặt',
        width: 100,
        className: '!text-right',
        render: (value) =>
          value?.fixedPrice ? (
            formatCurrency(value?.fixedPrice)
          ) : (
            <span>
              {formatCurrency(value?.orderedPriceFrom)}
              <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
              {formatCurrency(value?.orderedPriceTo)}
            </span>
          ),
      },
      {
        title: 'Khối lượng đặt',
        dataIndex: 'orderedVolume',
        className: '!text-right',
        width: 150,
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Hiệu lực lệnh',
        width: 220,
        className: '!text-right',
        render: (val) => (
          <span>
            {moment(val.availableDateFrom).format(DATE_FORMAT)}
            <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
            {moment(val.availableDateTo).format(DATE_FORMAT)}
          </span>
        ),
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) =>
          OPTIONS.find((item) => item.value === data)?.label || <span className="text-red">Chưa kích hoạt</span>,
        width: 120,
      },
      {
        title: '',
        width: 140,
        render: (value) => (
          <div className={clsx(isEnableEditDelete(value.status) && '!flex gap-2')}>
            <Button onClick={() => setOrderId(value.id)} type="text" className="mr-2" icon={<InfoIcon />} />
            <Button
              onClick={() => setConfirmation({ type: 'CANCEL', data: value })}
              type="text"
              disabled={!isEnableEditDelete(value.status)}
              className="mr-2"
              icon={
                <DeleteIcon
                  className={clsx(
                    '!w-9 !h-9 ',
                    isEnableEditDelete(value.status) ? '!text-red' : '!text-custom-darkBlue400',
                  )}
                />
              }
            />
            <Button
              disabled={!isEnableEditDelete(value.status)}
              onClick={() =>
                setEdit({
                  ...value,
                  price: value.fixedPrice,
                  minPrice: value.orderedPriceFrom,
                  maxPrice: value.orderedPriceTo,
                  symbol: value.code,
                  volume: value.orderedVolume,
                  date: value.availableDateTo,
                })
              }
              type="text"
              className=""
              icon={
                <EditIcon
                  className={clsx(
                    '!w-9 !h-9 ',
                    isEnableEditDelete(value.status) ? '!text-white' : '!text-custom-darkBlue400',
                  )}
                />
              }
            />
          </div>
        ),
        fixed: 'right',
      },
    ],
    [setConfirmation],
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };
  return (
    <div>
      <CopierOrderFilter onFilter={(values) => setFilter(values)} filter={filter} />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{
          y: 'calc(100vh - 390px)',
          x: 780,
        }}
        rowClassName={(record) => (record.status === 'CANCELLED' ? 'text-custom-gray500' : '')}
        // scroll={{ x: 780 }}
      />
      {edit && (
        <OrderEdit
          data={edit}
          setData={setEdit}
          onEdit={(values) =>
            setConfirmation({
              type: 'EDIT',
              data: values,
            })
          }
        />
      )}
      <OrderDetail orderId={orderId} onClose={() => setOrderId('')} />
      <OrderConfirmation
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        onConfirm={(confirmation) => {
          confirmation.type === 'CANCEL' ? onCancel(confirmation.data.id) : onEdit(confirmation.data);
        }}
        loading={cancelLoading || editLoading}
      />
    </div>
  );
}

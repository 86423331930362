/* -------------------------------------------------------------------------- */
/*                           GET STOCK COMPANY LIST                           */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  stockCompanyList: [],
  brokerList: [],
  subAccountList: [],
};

const { REACT_APP_URL } = process.env;

export const getStockCompanyList = createAsyncThunk(
  'stockCompany/getStockCompanyList',

  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/stock-company`
      );

      const data = await response.data;

      if (!data.status) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBrokerList = createAsyncThunk(
  'stockCompany/getBrokerList',

  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/broker`
      );

      const data = await response.data;

      if (!data.status) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSubAccountList = createAsyncThunk(
  'stockCompany/getSubAccountList',

  async (thunkAPI, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/sub-account-type`,
        {
          portfolio_id: getState().portfolioList.portfolioId,
        }
      );

      const data = await response.data;

      if (!data.status) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStockCompanyListSlice = createSlice({
  name: 'stockCompanyList',
  initialState,
  reducers: {},
  extraReducers: {
    [getStockCompanyList.pending]: (state) => {
      state.loading = true;
    },
    [getStockCompanyList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.stockCompanyList = payload?.data;
    },
    [getStockCompanyList.rejected]: (state) => {
      state.loading = false;
    },

    [getBrokerList.fulfilled]: (state, { payload }) => {
      state.brokerList = payload?.data;
    },

    [getSubAccountList.pending]: (state) => {
      state.loading = true;
    },
    [getSubAccountList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.subAccountList = payload?.data;
    },
    [getSubAccountList.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getStockCompanyListSelector = (state) =>
  state.stockCompanyList.stockCompanyList;

export const stockCompanyListReducer = getStockCompanyListSlice.reducer;

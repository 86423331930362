import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledSectionTitle } from '../../styles';
import { StyledBasicIndexWrapper, StyledWrapper } from './styles';

const BasicIndex = () => {
  // eslint-disable-next-line no-unused-vars
  const data1 = [
    {
      name: 'P/E',
      value: 23.01,
    },

    {
      name: 'Beta',
      value: 0.06,
    },

    {
      name: 'Tổng GT',
      value: '216,220,000',
    },

    {
      name: 'Tổng KL',
      value: '9,700',
    },

    {
      name: 'KLTB 2 tuần',
      value: '1,510',
    },

    {
      name: 'SLCP lưu hành',
      value: '1,200,000',
    },

    {
      name: 'Vốn hóa',
      value: '25,200,000,000',
    },
  ];

  const { data } = useSelector((state) => state.stockDetails);
  const { t } = useTranslation('ratingsScreen');

  return (
    <StyledBasicIndexWrapper>
      <StyledSectionTitle>
        <div>{t('stockDetailsSection.tab1.baseIndex.title')}</div>

        <span>{t('stockDetailsSection.tab1.baseIndex.toolTip')}</span>
      </StyledSectionTitle>

      {data?.base_index?.slice(0, data?.base_index?.length - 1).map((el) => {
        return (
          <StyledWrapper>
            <div>
              {t(`stockDetailsSection.tab1.baseIndex.mainContent.${el.key}`)}
            </div>
            <div>{el.value.toLocaleString('en-US')}</div>
          </StyledWrapper>
        );
      })}
    </StyledBasicIndexWrapper>
  );
};

export default BasicIndex;

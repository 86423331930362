import React from 'react';

const PortfoliosIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
    >
      <path
        d="M4.80001 3.0001H0.936007C0.492007 3.0001 0.0480075 3.3841 0.108008 4.0081L1.98001 15.9961C1.98001 15.9961 4.20001 7.1641 4.63201 5.0401C4.76401 4.4041 5.36401 4.2001 5.80801 4.2001H12C12 4.2001 11.16 1.7041 11.076 1.4281C10.932 0.900098 10.668 0.600098 10.14 0.600098H6.16801C5.73601 0.600098 5.32801 0.876098 5.20801 1.3681C5.10001 1.8481 4.80001 3.0001 4.80001 3.0001ZM10.656 3.0001H5.85601C5.85601 3.0001 6.36001 1.8001 6.90001 1.8001H9.45601C10.032 1.8001 10.656 3.0001 10.656 3.0001ZM3.20401 16.5001C2.83201 17.0641 2.29201 17.4001 1.69201 17.4001H20.568C21.216 17.4001 21.672 17.0281 21.804 16.4041C22.332 13.7761 23.82 6.2761 23.82 6.2761C23.904 5.6761 23.46 5.4001 23.076 5.4001H19.2V3.6361C19.2 3.4441 18.888 3.0001 18.408 3.0001H13.896C13.272 3.0001 12.852 3.6961 12.852 3.6961L12 5.4001H6.70801C6.32401 5.4001 5.95201 5.6281 5.88001 6.0001C5.88001 6.0001 3.97201 14.0401 3.81601 14.7961C3.73201 15.2401 3.55201 15.9841 3.20401 16.5001ZM18.456 5.4001H13.2C13.2 5.4001 13.896 4.2001 14.556 4.2001H17.304C18.156 4.2001 18.456 5.4001 18.456 5.4001Z"
        fill="var(--white-color)"
      />
    </svg>
  );
};

export default PortfoliosIcon;

import { Empty } from 'antd';
import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { getWatchlistNewsInit } from 'features/watchlist/watchlistSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostListStyled } from './Modal/styles';

const PostItemWatchlistNews = ({
  scrollTop,
  isClickedBtn,
  setIsClickedBtn,
}) => {
  const filter = useSelector((state) => state.watchlist.sectors);
  const { data, pagination } = useSelector((state) => state.watchlist.posts);
  const { isLoadingSkeletonWatchlistNews, currentSelectedWatchlist } =
    useSelector((state) => state.watchlist);

  const { isFirstTimeRender } = useSelector((state) => state.uiHandler);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isClickedBtn) {
      setTimeout(() => {
        setIsClickedBtn(false);
      }, 1000);
    }
  }, [isClickedBtn, setIsClickedBtn]);

  useEffect(() => {
    scrollTop();

    let delayed;
    delayed = setTimeout(() => {
      if (!isClickedBtn && !isFirstTimeRender) {
        dispatch(
          getWatchlistNewsInit({
            pagination: {
              ...pagination,
              page: 1,
            },
          })
        );
      }
    }, 1000);

    return () => clearTimeout(delayed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, currentSelectedWatchlist]);

  return (
    <>
      {isLoadingSkeletonWatchlistNews && (
        <PostListStyled>
          {new Array(10).fill(null).map((_, index) => (
            <PostItem.Loading key={index} type="full" />
          ))}
        </PostListStyled>
      )}

      {data.length === 0 ? (
        <div style={{ marginTop: '30vh' }}>
          <Empty />
        </div>
      ) : (
        <>
          {data?.map((post, index) => (
            <PostItem key={index} data={post} type="full" index={index} />
          ))}
        </>
      )}
    </>
  );
};

export default PostItemWatchlistNews;

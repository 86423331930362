import styled from 'styled-components';

export const StyledDialogContainer = styled.div`
  padding: 4rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledMessage = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.408px;
  color: var(--white-color);
  padding: 1.5rem 0 0 0;
`;
export const StyledMessage2 = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.408px;
  color: rgba(255, 255, 255, 0.4);
  padding-top: 0.4rem;
`;

export const StyledButton = styled.button`
  padding: 4px 16px;
  background: var(--light-blue-color);
  border-radius: 4px;
  border: none;
  color: var(--always-white-color);
`;

import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Layout } from 'antd';
import { useRef, useState } from 'react';
import DATXLogoIcon from 'assets/icons/DATXLogoIcon';
import Sidebar from './index';
import { DrawerContainer, SidebarContainer } from './styles';

const SidebarMobile = () => {
  const menuBtnRef = useRef();
  const [visible, setVisible] = useState(false);

  const handleOpenDrawer = () => {
    setVisible(true);
  };

  const handleCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <DrawerContainer>
      <span ref={menuBtnRef} onClick={handleOpenDrawer}>
        <MenuOutlined style={{ fontSize: '2.6rem' }} />
      </span>

      <Drawer
        width="260"
        placement="left"
        onClose={handleCloseDrawer}
        getContainer={() => menuBtnRef.current}
        visible={visible}
        title={
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DATXLogoIcon />
            <span
              style={{
                fontWeight: 'bold',
                color: 'var(--white-color)',
                paddingLeft: '1rem',
                paddingBottom: '0.4rem',
                display: 'flex',
              }}
            >
              BETA
            </span>
          </div>
        }
      >
        <SidebarContainer expandSidebar={true}>
          <Layout>
            <Layout.Sider className="sidebar-mobile" width="100%">
              <Sidebar type="mobile" onClose={handleCloseDrawer} />
            </Layout.Sider>
          </Layout>
        </SidebarContainer>
      </Drawer>
    </DrawerContainer>
  );
};

export default SidebarMobile;

/* -------------------------------------------------------------------------- */
/*                              GET MARKET STATUS                             */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  consider: {
    buy: '',
    sell: '',
  },

  cashs: [],
};

const { REACT_APP_URL } = process.env;

export const getMarketStatus = createAsyncThunk(
  'marketStatus/getMarketStatus',

  async (timeDistance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/market-status`,
        {
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMarketStatusSlice = createSlice({
  name: 'marketStatus',
  initialState,
  reducers: {},
  extraReducers: {
    [getMarketStatus.pending]: (state) => {
      state.loading = true;
    },
    [getMarketStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.consider.buy = payload?.data.consider.buy;
      state.consider.sell = payload?.data.consider.sell;
      state.cashs = payload?.data.cashs;
    },
    [getMarketStatus.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const marketStatusReducer = getMarketStatusSlice.reducer;

import React from 'react';
import { StyledPrimaryButton } from './styles';

const PrimaryButton = ({
  textBtn,
  onHandleClick,
  disabledInput,
  size,
  ...props
}) => {
  return (
    <StyledPrimaryButton
      onClick={onHandleClick}
      disabled={disabledInput}
      size={size}
      {...props}
    >
      {textBtn}
    </StyledPrimaryButton>
  );
};

export default PrimaryButton;

/* -------------------------------------------------------------------------- */
/*                             SETOR DETAILS SLICE                            */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { defaultPagination } from 'features/portfolio/portfolioNewsSlice';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  loading: false,
  sector: '',
  sectorStatusChartData: [],
  sectorDATXRatingData: [],
  sectorDATXRatingDataBottom: [],
  sectorDataDetail: [],
  sectorOverviewData: [],
  consider: {
    buy: '',
    sell: '',
  },
};

const { REACT_APP_URL } = process.env;

export const getSectorStatusChart = createAsyncThunk(
  'sectorDetails/getSectorStatusChart',

  async ({ sector, timeDistance }, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sector-status-chart`,
        {
          sector: getState().sectorDetails.sector,
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSectorDATXRating = createAsyncThunk(
  'sectorDetails/getSectorDATXRating',

  async ({ sector, timeDistance }, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sectors/datx-rating-chart`,
        {
          sector: getState().sectorDetails.sector,
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSectorDATXRatingBottom = createAsyncThunk(
  'sectorDetails/getSectorDATXRatingBottom',

  async ({ sector, timeDistance }, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sectors/datx-rating`,
        {
          sector: getState().sectorDetails.sector,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSectorDataDetail = createAsyncThunk(
  'sectorDetails/getSectorDataDetail',

  async ({ sector, timeDistance }, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sectors/data-detail`,
        {
          sector: getState().sectorDetails.sector,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSectorOverviewChart = createAsyncThunk(
  'sectorDetails/getSectorOverviewChart',

  async ({ timeDistance }, { rejectWithValue, getState }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sectors/overview`,
        {
          sector: getState().sectorDetails.sector,
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewsBySector = createAsyncThunk(
  'sectorDetails/getNewsBySector',

  async (
    { stockCode, page, pagination = defaultPagination },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/sector-news`,
        {
          sectors: [getState().sectorDetails.sector],
          limit: 10,
          page: page,
          ...pagination,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return {
        data: data.data,
        pagination: {
          ...pagination,
          isNext: data.data.length >= pagination.limit,
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSectorDetailsSlice = createSlice({
  name: 'sectorDetails',
  initialState,
  reducers: {
    handleSelectSector: (state, { payload }) => {
      state.sector = payload;
    },

    resetStateSectorNews: (state) => {
      state.loading = true;
      state.dataNews = [];
      state.pagination = defaultPagination;
    },
  },
  extraReducers: {
    /* ------------------------- GET SECTOR STATUS CHART ------------------------ */

    [getSectorStatusChart.pending]: (state) => {
      state.loading = true;
    },
    [getSectorStatusChart.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sectorStatusChartData = payload?.data.sector_status;
      state.consider.buy = payload?.data?.consider.buy;
      state.consider.sell = payload?.data?.consider.sell;
    },
    [getSectorStatusChart.rejected]: (state) => {
      state.loading = false;
    },

    /* ------------------------- GET SECTOR DATX RATING ------------------------- */

    [getSectorDATXRating.pending]: (state) => {
      state.loading = true;
    },
    [getSectorDATXRating.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sectorDATXRatingData = payload?.data.chart_data;
      state.consider.buy = payload?.data?.consider.buy;
      state.consider.sell = payload?.data?.consider.sell;
    },
    [getSectorDATXRating.rejected]: (state) => {
      state.loading = false;
    },

    /* ---------------------- GET SECTOR DATX RATING BOTTOM --------------------- */

    [getSectorDATXRatingBottom.pending]: (state) => {
      state.loading = true;
    },
    [getSectorDATXRatingBottom.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sectorDATXRatingDataBottom = payload?.data;
    },
    [getSectorDATXRatingBottom.rejected]: (state) => {
      state.loading = false;
    },

    /* ----------------------------- GET DATA DETAIL ---------------------------- */

    [getSectorDataDetail.pending]: (state) => {
      state.loading = true;
    },
    [getSectorDataDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sectorDataDetail = payload?.data;
    },
    [getSectorDataDetail.rejected]: (state) => {
      state.loading = false;
    },

    /* ------------------------ GET SECTOR OVERVIEW CHART ----------------------- */

    [getSectorOverviewChart.pending]: (state) => {
      state.loadingChart = true;
    },
    [getSectorOverviewChart.fulfilled]: (state, { payload }) => {
      state.loadingChart = false;
      state.sectorOverviewData = payload?.data;
    },
    [getSectorOverviewChart.rejected]: (state) => {
      state.loadingChart = false;
    },

    /* --------------------------- GET NEWS BY SECTOR --------------------------- */

    [getNewsBySector.pending]: (state) => {
      // state.loading = true;
    },
    [getNewsBySector.fulfilled]: (state, { payload }) => {
      state.dataNews = [...state.dataNews, ...payload?.data];
      state.pagination = payload.pagination;
      state.loading = false;
      state.pagination.isFirstMounted = false;
    },
    [getNewsBySector.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { handleSelectSector, resetStateSectorNews } =
  getSectorDetailsSlice.actions;

export const sectorDetailsReducer = getSectorDetailsSlice.reducer;

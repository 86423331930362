import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsError, settingsSuccess } from 'utils';
import ToolbarCustomerFiles from 'components/RecommendationsScreenComponents/ToolbarCustomerFiles';
import TableCustomerFiles from 'components/RecommendationsScreenComponents/TableCustomerFiles';
import AddEditCustomerFile from 'components/RecommendationsScreenComponents/AddEditCustomerFile';
import SendMessageCustomer from 'components/RecommendationsScreenComponents/SendMessageCustomer';
import { toast } from 'react-toastify';
import { getCustomerFiles } from 'features/recommendation/customerFileSlice';
import { Body, Container, Header, Main } from './styles';
import axiosInstance from 'utils/axiosInstance';
import { Modal } from 'antd';

const ManageCustomerFilesScreen = () => {
  const dispatch = useDispatch();
  const { customerFiles } = useSelector((state) => state.customerFile);
  const [showingModal, setShowingModal] = useState(false);
  const [editingCustomerFile, setEditingCustomerFile] = useState(null);
  const [showingModalSend, setShowingModalSend] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleClickDelete = async (item) => {
    Modal.confirm({
      title: 'Vui lòng xác nhận',
      content: 'Bạn có chắc chắn muốn xoá tệp khách hàng này?',
      okText: 'Xoá',
      cancelText: 'Huỷ',
      async onOk() {
        try {
          const response = await axiosInstance.delete(
            `${process.env.REACT_APP_RECOMMENDATION_API_URL}/file-connections/${item.id}`
          );
          if (!response.data.status) {
            throw new Error(response.data.message_translated);
          }
          toast.success('Xoá tệp khách hàng thành công!', settingsSuccess);
          dispatch(
            getCustomerFiles({
              page: 1,
              pageSize: 10,
            })
          );
        } catch (e) {
          toast.error('Xoá tệp khách hàng không thành công!', settingsError);
        }
      },
    });
  };

  const handleClickUpdate = (item) => {
    setEditingCustomerFile(item);
    setShowingModal(true);
  };

  const handleClickSend = (item) => {
    setEditingCustomerFile(item);
    setShowingModalSend(true);
  };

  const handleCloseModal = () => {
    setEditingCustomerFile(null);
    setShowingModal(false);
    setShowingModalSend(false);
  };

  const handleClickAdd = () => {
    setShowingModal(true);
  };

  const handleSave = async (data) => {
    setSaving(true);
    try {
      if (data.id) {
        const response = await axiosInstance.put(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/file-connections`,
          {
            _id: data.id,
            Name: data.name,
            Description: data.description,
            Channels: data.connections.map((i) => ({
              Code: i.code,
              TargetLink: i.url,
            })),
          }
        );
        if (!response.data.status) {
          throw new Error(response.data.message_translated);
        }
        toast.success('Cập nhật khách hàng thành công!', settingsSuccess);
        dispatch(
          getCustomerFiles({
            page: 1,
            pageSize: 10,
          })
        );
        handleCloseModal();
      } else {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/file-connections`,
          {
            Name: data.name,
            Description: data.description,
            Channels: data.connections.map((i) => ({
              Code: i.code,
              TargetLink: i.url,
            })),
          }
        );
        if (!response.data.status) {
          throw new Error(response.data.message_translated);
        }
        toast.success('Tạo tệp khách hàng thành công!', settingsSuccess);
        dispatch(
          getCustomerFiles({
            page: 1,
            pageSize: 10,
          })
        );
        handleCloseModal();
      }
    } catch (e) {
      if (data.id) {
        toast.error(
          `Cập nhật tệp khách hàng không thành công. ${e.toString()}`,
          settingsError
        );
      } else {
        toast.error(
          `Tạo tệp khách hàng không thành công. ${e.toString()}`,
          settingsError
        );
      }
    } finally {
      setSaving(false);
    }
  };

  const mapData = () => {
    return customerFiles.map((item) => ({
      id: item._id,
      name: item.Name,
      description: item.Description,
      connections:
        item.Channels?.map((c) => ({
          code: c.Code,
          url: c.TargetLink,
          name: c.TargetName,
          state: c.State,
        })) ?? [],
    }));
  };

  useEffect(() => {
    dispatch(
      getCustomerFiles({
        page: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  return (
    <Container>
      <Header>
        <ToolbarCustomerFiles
          onClickAdd={handleClickAdd}
          onClickSend={handleClickSend}
        />
      </Header>
      <Body>
        <Main>
          <TableCustomerFiles
            data={mapData()}
            onClickUpdate={handleClickUpdate}
            onClickDelete={handleClickDelete}
          />
        </Main>
      </Body>
      <AddEditCustomerFile
        data={editingCustomerFile}
        visible={showingModal}
        loading={saving}
        onCancel={handleCloseModal}
        onOk={handleSave}
      />
      <SendMessageCustomer
        visible={showingModalSend}
        onCancel={handleCloseModal}
      />
    </Container>
  );
};

export default ManageCustomerFilesScreen;

import React from 'react';

const SuccessIconInNotiDialog = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <path
          d="M22 2L27.253 5.832L33.756 5.82L35.753 12.008L41.021 15.82L39 22L41.021 28.18L35.753 31.992L33.756 38.18L27.253 38.168L22 42L16.747 38.168L10.244 38.18L8.247 31.992L2.979 28.18L5 22L2.979 15.82L8.247 12.008L10.244 5.82L16.747 5.832L22 2Z"
          fill="var(--light-green-color)"
          stroke="var(--light-green-color)"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 22L20 27L30 17"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SuccessIconInNotiDialog;

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Col, Form, Row } from 'antd';
import Badge from '@mui/material/Badge';
import AutoCompleteStock from 'components/GlobalComponents/AutocompleteStock';
import { ABSelect } from 'components/GlobalComponents/FormItem';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React, { useRef, useState } from 'react';

export const OPTIONS = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 'OPEN',
    label: 'Chờ khớp',
  },
  {
    value: 'MATCHED',
    label: 'Đã khớp',
  },
  {
    value: 'EXPIRED',
    label: 'Hết hiệu lực',
  },
  {
    value: 'CANCELLED',
    label: 'Đã hủy',
  },
  {
    value: 'UPDATED',
    label: 'Đã sửa',
  },
];

export const OPTIONS_KIND = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 'NORMAL',
    label: 'Thường',
  },
  {
    value: 'SLTP',
    label: 'SL/TP',
  },
];

export const OPTIONS_TYPE = [
  {
    value: null,
    label: 'Tất cả',
  },
  {
    value: 'BUY',
    label: 'Mua',
  },
  {
    value: 'SELL',
    label: 'Bán',
  },
];

const Filter = ({ onFilter, filter }) => {
  console.log('filter', filter);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const searchInputRef = useRef(null);

  const onReset = () => {
    setKeyword('');
    form.resetFields();
  };

  return (
    <div className="flex items-center justify-between bg-custom-darkBlue700 p-4 my-3 rounded-xl">
      <h1 className="text-xl m-0">Chi tiết</h1>
      <Badge
        color="error"
        variant="dot"
        invisible={
          !filter.code && !filter.statusIn && !filter.typeIn && !filter.kindIn
        }
      >
        <FilterAltIcon
          onClick={() => setOpen(true)}
          className="!w-10 !h-10 cursor-pointer hover:!text-custom-darkBlue500"
        />
      </Badge>

      <StyledModal
        open={open}
        destroyOnClose={true}
        maskClosable={true}
        className="!w-[620px]"
        title="Bộ lọc"
        footer={null}
        onCancel={() => setOpen(false)}
        onOk={() => null}
        style={{ top: 25 }}
      >
        <Form
          initialValues={{
            status: null,
            code: null,
          }}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={() => {}}
          onFinish={(values) => onFilter(values)}
        >
          <Row gutter={[8, 12]}>
            <Col xs={24} lg={12}>
              <Form.Item label="Chọn cổ phiếu" name="code">
                <AutoCompleteStock
                  setKeyword={(keyword) => {
                    setKeyword(keyword);
                    form.setFieldValue('code', keyword);
                  }}
                  keyword={keyword}
                  onChange={(keyword) => form.setFieldValue('code', keyword)}
                  inputRef={searchInputRef}
                />
              </Form.Item>
            </Col>

            <ABSelect
              label="Chọn trạng thái"
              options={OPTIONS}
              value="statusIn"
            />
          </Row>
          <Row gutter={[8, 12]}>
            <ABSelect
              label="Chọn kiểu lệnh"
              options={OPTIONS_TYPE}
              value="typeIn"
            />
            <ABSelect
              label="Chọn loại lệnh"
              options={OPTIONS_KIND}
              value="kindIn"
            />
          </Row>

          <div className="flex justify-end pt-2 pb-4">
            <Button
              className="!h-16 !px-16 !rounded-lg !text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500"
              onClick={onReset}
            >
              Đặt lại
            </Button>
            <Button
              type="primary"
              className="ml-6 !h-16 !px-16 !rounded-lg !bg-custom-blue500 border-0 hover:!bg-blue-500"
              onClick={() => {
                form.submit();
                setOpen(false);
              }}
            >
              Áp dụng
            </Button>
          </div>
        </Form>
      </StyledModal>
    </div>
  );
};

export default Filter;

import styled from 'styled-components';

const collapsedWidth = '9.2rem !important';

export const SidebarDesktopWrapper = styled.div`
  @media only screen and (max-width: 81.1875em) {
    display: none;
  }
`;

export const DrawerContainer = styled.div`
  .ant-drawer-header {
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--border-color);
    height: 6rem;
    padding: 0 2rem;

    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-close {
      margin-right: 0;
    }
  }

  .ant-drawer-body {
    background-color: var(--primary-color);
    padding: 0 2rem;
    height: calc(100vh - 6rem);

    & > div {
      margin-top: 0 !important;
    }

    .ant-layout-sider.ant-layout-sider-dark {
      width: 100% !important;
      overflow: hidden;
    }

    .ant-menu-submenu-arrow {
      right: 0 !important;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      margin-left: 12px;
      padding-left: 0 !important;
    }

    .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
      padding-left: 0 !important;
    }

    .ant-menu-dark.ant-menu-inline .ant-menu-submenu {
      .ant-menu-item {
        padding-left: 2rem !important;
      }
    }

    .ant-menu-item {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }
  }

  @media only screen and (min-width: 81.25em) {
    display: none;
  }
`;

export const SidebarContainer = styled.div`
  height: calc(100vh - 5rem);
  position: sticky;
  margin-top: 6rem;
  top: 6rem;
  background: var(--primary-color);
  width: ${(props) => (props.expandSidebar ? '100%' : collapsedWidth)};
  user-select: none;

  .ant-layout-sider.ant-layout-sider-dark {
    background-color: var(--primary-color) !important;
    flex: unset !important ;
    max-width: unset !important;
    min-width: unset !important;
    width: unset !important;
  }

  .ant-layout {
    display: initial;
    background-color: var(--primary-color) !important;
    overflow: hidden;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5rem);

    & > ul {
      flex: 1;
    }
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    background-color: var(--primary-color) !important;
    width: ${(props) => (props.expandSidebar ? '100%' : collapsedWidth)};
  }

  /* .ant-menu-dark .ant-menu-submenu-active {
    .ant-menu-submenu-title > svg > path {
      transition: fill 0.25s linear;
      fill: var(--primary-blue);
    }
    .ant-menu-title-content {
      color: var(--primary-blue);
    }
  } */

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: transparent;
    margin-left: 30px;
    border-left: 1px solid var(--border-color);
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    padding-left: 2rem !important;
    background-color: transparent;
  }

  .ant-menu-item {
    padding-left: ${(props) => (props.expandSidebar ? '1rem !important' : '2.4rem !important')};
    margin-left: 1rem !important;
    border-radius: 4px;
    font-weight: bold;
    color: var(--white-color);
    background-color: transparent !important;

    &:hover,
    &.ant-menu-item-selected {
      .ant-menu-title-content {
        color: var(--primary-blue) !important;
      }
      D & svg.watchlist-icon circle {
        fill: var(--primary-blue);
        transition: all 0.2s linear;
      }

      & svg.dashboard-icon path:nth-child(2) {
        fill: var(--primary-blue);
        transition: all 0.2s linear;
        stroke: var(--primary-blue);
      }

      & svg.dashboard-icon path:nth-child(1) {
        stroke: var(--primary-blue);
      }

      & svg path {
        fill: var(--primary-blue);
        transition: all 0.2s linear;
        stroke: var(--primary-blue);
      }
    }

    .ant-menu-title-content {
      display: ${(props) => (props.expandSidebar ? 'inline-block' : 'none')};
      white-space: normal;
      line-height: initial;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    &.ant-menu-submenu-open .ant-menu-submenu-title {
      .ant-menu-title-content {
        color: var(--primary-blue);
      }
      svg path {
        fill: var(--primary-blue);
        transition: all 0.2s linear;
        stroke: var(--primary-blue);
      }
    }
  }

  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 0 !important;
  }

  .ant-menu-submenu-title > .ant-menu-title-content,
  .ant-menu-submenu .ant-menu-item > .ant-menu-title-content,
  .ant-menu-title-content {
    padding-left: 1rem !important;
    color: var(--white-color);
    font-weight: bold;
    display: ${(props) => (props.expandSidebar ? 'inline-block' : 'none')};
  }
  .ant-menu-submenu .ant-menu-submenu-title span {
    padding-left: 1rem !important;
    color: var(--white-color);
    font-weight: bold;
    display: ${(props) => (props.expandSidebar ? 'inline-block' : 'none')};
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.expandSidebar ? 'left' : 'center')};
  width: ${(props) => (props.expandSidebar ? '100%' : collapsedWidth)};
  padding-top: 1.2rem;
  border-top: 1px solid var(--border-color);
  padding-bottom: 1.2rem;
  height: 64px;
  align-items: center;
`;

export const StyledProfileInfo = styled.div`
  flex-grow: ${(props) => (props.expandSidebar ? '1' : 'unset')};
  display: ${(props) => (props.expandSidebar ? 'flex' : 'none')};
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
`;

export const CollapseButton = styled.button`
  width: 3rem;
  background-color: transparent;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
`;

export const SidebarBottom = styled.div`
  padding: ${(props) => (props.expandSidebar ? '0 1rem 0 2rem' : '0')};
  background-color: var(--primary-color);

  @media only screen and (max-width: 81.1875em) {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover.ant-popover-placement-top {
    left: 0 !important;
  }

  .ant-popover-inner {
    background-color: var(--primary-color);
    width: ${(props) => (props.expandSidebar ? '27rem' : collapsedWidth)};
    box-shadow: unset;
    border-radius: 0px;
  }

  .ant-popover-inner-content {
    padding: ${(props) => (props.isMobile || !props.expandSidebar ? 0 : '0 16px')};
  }
`;

export const StyledLink = styled.a`
  display: flex;
  width: ${(props) => (props.expandSidebar ? '100%' : collapsedWidth)};
  align-items: center;
  padding-top: 1.2rem;
  text-decoration: none;
  color: white;
  justify-content: ${(props) => (props.expandSidebar ? 'unset' : 'center')};
  font-size: 15px;

  svg {
    margin: 1rem;
  }

  span:nth-child(2) {
    display: ${(props) => (props.expandSidebar ? 'unset' : 'none')};
    font-weight: 700;
    color: ${(props) => (props.active ? 'var(--primary-blue)' : 'var(--white-color)')};
  }

  svg > path {
    fill: ${(props) => (props.active ? 'var(--primary-blue)' : 'var(--white-color)')};
  }

  :hover {
    span:nth-child(2) {
      color: var(--primary-blue);
    }
    svg > path {
      fill: var(--primary-blue);
    }
  }
`;

export const StyledProfileContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
  width: 80%;
  margin-left: 1rem;
  text-align: left;

  & > div > * {
    width: 13rem;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 81.25em) {
      width: 20rem;
    }
  }
`;

export const StyledUsername = styled.a`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-blue);
`;

export const StyledEmail = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

export default function EditVol({ max = 100000000, min = 0, data, setData }) {
  const [isFocused, setIsFocused] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (data.volume < 100) {
      setStep(1);
    } else {
      setStep(100);
    }
  }, [data]);

  return (
    <div className="mt-4">
      <Typography.Text className="!text-custom-darkBlue200 my-3 whitespace-nowrap">Khối lượng đặt lệnh</Typography.Text>
      <div
        className={clsx(
          'w-full h-[50px] flex items-center !rounded-lg bg-custom-darkBlue600 px-4 border',
          isFocused ? '!border-custom-blue500' : '!border-custom-darkBlue400',
        )}
      >
        <div className="w-full flex gap-3 justify-between">
          <Button
            icon={<RemoveIcon className="!w-9 !h-9" />}
            onClick={() => {
              !isFocused && setIsFocused(true);
              setData((one) => ({
                ...one,
                volume: one.volume - step >= min ? Number(one.volume - step) : min,
              }));
            }}
            type="text"
          />
          <NumericFormat
            className="w-[108px] text-lg text-center rounded-none border-none bg-transparent text-white focus:outline-none relative"
            value={data.volume}
            isAllowed={(values) => {
              const { value } = values;
              return Number(value) <= max && Number(value) >= min;
            }}
            onValueChange={(values) => {
              setData((one) => ({
                ...one,
                volume: values.floatValue,
              }));
            }}
            decimalScale={0}
            allowNegative={false}
            thousandSeparator=","
            onFocus={(e) => {
              e.target.select();
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
          />
          <Button
            icon={<AddIcon className="!w-9 !h-9" />}
            onClick={() => {
              !isFocused && setIsFocused(true);
              setData((one) => ({
                ...one,
                volume: one.volume + step <= max ? Number(one.volume + step) : max,
              }));
            }}
            type="text"
          />
        </div>
      </div>
    </div>
  );
}

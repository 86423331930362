import { Row, Col } from 'antd';
import Table from './Table';
// import Chart from "./Chart";

const RecommendationPerformance = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={24} xxl={24}>
        <Table />
      </Col>
      {/* <Col xs={24} lg={15} xxl={16}>
        <Table />
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <Chart />
      </Col> */}
    </Row>
  );
};

export default RecommendationPerformance;

import { configureStore } from '@reduxjs/toolkit';
import { portfolioExchangeFundReducer } from 'features/portfolio/portfolioExchangeFundSlice';
import { sectorDetailsReducer } from 'features/ratings/sectorDetailsSlice';
import newsReducer from '../features/news/newsSlice';
import { allocationChartReducer } from '../features/portfolio/allocationChartSlice';
import { portfolioListReducer } from '../features/portfolio/getPortfolioListSlice';
import { PortfolioSummaryReducer } from '../features/portfolio/getPortfolioSummarySlice';
import { stockCompanyListReducer } from '../features/portfolio/getStockCompanyListSlice';
import { portfolioOrderPlacementsReducer } from '../features/portfolio/orderPlacementsSlice';
import { portfolioConnectionTestingReducer } from '../features/portfolio/portfolioConnectionTestingSlice';
import portfolioNewsReducer from '../features/portfolio/portfolioNewsSlice';
import { portfolioTransactionsReducer } from '../features/portfolio/transactionsSlice';
import { DATXIndexReducer } from '../features/ratings/DATXIndexSlice';
import { DATXRatingReducer } from '../features/ratings/DATXRatingSlice';
import { listSectorsReducer } from '../features/ratings/listSectorsSlice';
import { listStocksReducer } from '../features/ratings/listStocksSlice';
import { marketStatusReducer } from '../features/ratings/marketStatusSlice';
import { recommendedCashRateReducer } from '../features/ratings/recommendedCashRate';
import { stockDetailsReducer } from '../features/ratings/stockDetailsSlice';
import { settingReducer } from '../features/setting/settingSlice';
import { uiHandlerReducer } from '../features/uiHandler/uiHandlerSlice';
import { userReducer } from '../features/user/userSlice';
import watchlistReducer from '../features/watchlist/watchlistSlice';
import { recommendationReducer } from '../features/recommendation/recommendationSlice';
import { derivativeRecommendationReducer } from '../features/recommendation/derivativeRecommendationSlice';
import { recommendationHistoryReducer } from '../features/recommendation/historySlice';
import { customerFileReducer } from '../features/recommendation/customerFileSlice';
import { recommendationPerformanceReducer } from '../features/recommendation/performanceSlice';
import { recommendationPerformanceReportReducer } from '../features/recommendation/performanceReportSlice';
import { stockReducer } from '../features/stocks/stockSlice';
import copyTradeReducer from '../features/copyTrade/copyTradeSlice';

export const store = configureStore({
  reducer: {
    /* --------------------------- UI HANDLER REDUCER --------------------------- */

    uiHandler: uiHandlerReducer,

    /* ------------------------------ USER REDUCER ------------------------------ */
    user: userReducer,

    /* ---------------------------- PORTFOLIO REDUCER --------------------------- */
    stockCompanyList: stockCompanyListReducer,
    portfolioList: portfolioListReducer,
    portfolioExchangeFund: portfolioExchangeFundReducer,
    portfolioSummary: PortfolioSummaryReducer,
    allocationChart: allocationChartReducer,
    portfolioConnectionTesting: portfolioConnectionTestingReducer,
    portfolioOrderPlacements: portfolioOrderPlacementsReducer,
    portfolioTransactions: portfolioTransactionsReducer,
    portfolioNews: portfolioNewsReducer,

    /* ----------------------------- RATINGS REDUCER ---------------------------- */
    marketStatus: marketStatusReducer,
    recommendCashRate: recommendedCashRateReducer,
    DATXIndex: DATXIndexReducer,
    listStocks: listStocksReducer,
    listSectors: listSectorsReducer,
    stockDetails: stockDetailsReducer,
    sectorDetails: sectorDetailsReducer,
    DATXRating: DATXRatingReducer,
    /* ----------------------------- SETTING REDUCER ---------------------------- */
    setting: settingReducer,

    /* ---------------------------- WATCHLIST REDUCER --------------------------- */
    watchlist: watchlistReducer,

    /* ------------------------------ NEWS REDUCER ------------------------------ */
    news: newsReducer,
    /* ------------------------------ RECOMMENDATION REDUCER ------------------------------ */
    recommendation: recommendationReducer,
    derivativeRecommendation: derivativeRecommendationReducer,
    recommendationHistory: recommendationHistoryReducer,
    customerFile: customerFileReducer,

    stocks: stockReducer,
    recommendationPerformance: recommendationPerformanceReducer,
    recommendationPerformanceReport: recommendationPerformanceReportReducer,

    /* ---------------------------- COPYTRADE REDUCER --------------------------- */
    copyTrade: copyTradeReducer,
  },

  devTools: process.env.NODE_ENV !== 'production',
});

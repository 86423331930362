import AnnouncementItem from './AnnouncementItem';
import { AnnouncementListWrapper } from './styles';

const AnnouncementList = ({ data }) => {
  if (!data || !Array.isArray(data)) return null;

  return (
    <AnnouncementListWrapper>
      {data.map((notify, index) => (
        <AnnouncementItem key={index} data={notify} />
      ))}
    </AnnouncementListWrapper>
  );
};

export default AnnouncementList;

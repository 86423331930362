import React from 'react';

function Image({ image }) {
  return (
    <div>
      <img
        className="mx-auto w-[400px] h-[400px] text-center rounded-xl object-cover"
        alt=""
        src={image.src}
      />
    </div>
  );
}

export default Image;

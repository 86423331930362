import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import clsx from 'clsx';
import { StyledTable } from 'components/GlobalComponents/styled';
import { DATE_TIME_FORMAT } from 'constants/date';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { formatCurrency, settingsError, settingsSuccess } from 'utils';
import { cancelOrderInDay, getTradingIndayList } from 'utils/api';
import OrderConfirmation from './OrderConfirmation';
import CopierOrderFilter, { OPTIONS, OPTIONS_KIND } from './OrderFilter';

export default function CopierOrderList() {
  const [filter, setFilter] = useState({});

  const [confirmation, setConfirmation] = useState({
    type: null,
    data: null,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getTradingIndayList', filter],
    queryFn: () =>
      getTradingIndayList({
        ...(filter?.code && { code: filter?.code }),
        ...(filter?.statusIn && { statusIn: filter?.statusIn }),
        ...(filter?.typeIn && { typeIn: filter?.typeIn }),
        ...(filter?.kindIn && { kindIn: filter?.kindIn }),
      }),
    retry: 3,
  });

  const { mutateAsync: cancelOrder, isLoading: cancelLoading } =
    useMutation(cancelOrderInDay);

  const isEnableEditDelete = (status) => {
    switch (status) {
      case 'MATCHED':
      case 'EXPIRED':
      case 'CANCELLED':
        return false;
      default:
        return true;
    }
  };

  const onCancel = async (id) => {
    try {
      await cancelOrder(id);
      toast.success('Hủy lệnh trong ngày thành công!', settingsSuccess);
      setConfirmation({ type: null, data: null });
      refetch();
    } catch (error) {
      toast.error('Hủy lệnh trong ngày thất bại!', settingsError);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Số hiệu lệnh',
        dataIndex: 'orderNo',
        className: 'text-center',
        width: 110,
      },
      {
        title: 'Thời gian',
        dataIndex: 'createdAt',
        width: 130,
        render: (data) => moment(data).format(DATE_TIME_FORMAT),
      },
      {
        title: 'Mã',
        className: 'text-center',
        dataIndex: 'code',
        width: 70,
      },
      {
        title: 'Kiểu lệnh',
        dataIndex: 'orderType',
        className: 'text-center',
        render: (data) =>
          data === 'BUY' ? (
            <span className="text-green">Mua</span>
          ) : (
            <span className="text-red">Bán</span>
          ),
        width: 100,
      },
      {
        title: 'Loại lệnh',
        className: 'text-center',
        dataIndex: 'orderKind',
        render: (data) =>
          OPTIONS_KIND.find((item) => item.value === data)?.label || (
            <span className="text-red">Không xác định</span>
          ),
        width: 120,
      },
      {
        title: 'Giá đặt',
        width: 100,
        className: '!text-right',
        dataIndex: 'orderedPrice',
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Khối lượng đặt',
        dataIndex: 'orderedVolume',
        className: '!text-right',
        width: 150,
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Khối lượng khớp',
        width: 150,
        className: '!text-right',
        dataIndex: 'matchedVolume',
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Giá khớp TB',
        width: 120,
        className: '!text-right',
        dataIndex: 'avgMatchedPrice',
        render: (data) => (data ? formatCurrency(data) : '-'),
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        render: (data) =>
          OPTIONS.find((item) => item.value === data)?.label || (
            <span className="text-red">Chưa kích hoạt</span>
          ),
        width: 120,
      },
      {
        title: 'Hủy/Sửa lệnh',
        width: 120,
        render: (value) => (
          <div
            className={clsx(isEnableEditDelete(value.status) && '!flex gap-2')}
          >
            <Button
              onClick={() => setConfirmation({ type: 'CANCEL', data: value })}
              type="text"
              disabled={!isEnableEditDelete(value.status)}
              className="mr-2"
              icon={
                <DeleteIcon
                  className={clsx(
                    '!w-9 !h-9 ',
                    isEnableEditDelete(value.status)
                      ? '!text-red'
                      : '!text-custom-darkBlue400'
                  )}
                />
              }
            />
          </div>
        ),
        fixed: 'right',
      },
    ],
    [setConfirmation]
  );

  const tableProps = {
    bordered: false,
    showHeader: true,
  };
  return (
    <div>
      <CopierOrderFilter
        onFilter={(values) => setFilter(values)}
        filter={filter}
      />
      <StyledTable
        {...tableProps}
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{
          y: 'calc(100vh - 390px)',
          x: 780,
        }}
        rowClassName={(record) =>
          record.status === 'CANCELLED' ? 'text-custom-gray500' : ''
        }
        // scroll={{ x: 780 }}
      />
      <OrderConfirmation
        loading={cancelLoading}
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        onConfirm={(confirmation) => {
          confirmation.type === 'CANCEL' && onCancel(confirmation.data.id);
        }}
      />
    </div>
  );
}

/* -------------------------------------------------------------------------- */
/*                                 USER SLICE                                 */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleChangeLanguage } from 'features/uiHandler/uiHandlerSlice';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  accessToken: '',
  refreshToken: '',
  isSuccessRelogin: false,
};

const { REACT_APP_URL } = process.env;

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',

  async (thunkAPI, { rejectWithValue, dispatch }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/auth/detail`
    );

    const data = await response.data;

    if (data.status) {
      dispatch(handleChangeLanguage(data?.data?.language));
      localStorage.setItem('autoLogin', data?.data?.auto_login);
    }

    if (!data.status) {
      return rejectWithValue(data.message);
    }

    return data;
  }
);

export const userRelogin = createAsyncThunk(
  'user/userRelogin',

  async (refreshToken, { rejectWithValue }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/auth/relogin`,
      {
        language: 'vi',
        device: {
          name: 'xxx',
          platform: 'xxx',
          device_token: 'xxx',
        },
        refresh_token: refreshToken,
      }
    );

    const data = await response.data;

    if (data.status) {
      localStorage.setItem('accessToken', data.data.access_token);
    }

    if (!data.status) {
      return rejectWithValue(data.message);
    }

    return data;
  }
);

export const userLogout = createAsyncThunk(
  'user/userLogout',

  async (thunkAPI, { rejectWithValue, getState }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/auth/logout`,
      {
        language: getState().uiHandler.language,
      }
    );

    const data = await response.data;

    if (data.status === false) {
      return rejectWithValue(data.message);
    }

    return data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokenState: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
  },
  extraReducers: {
    [getUserInfo.pending]: (state) => {
      state.loading = true;
    },
    [getUserInfo.fulfilled]: (state, { payload }) => {
      // state.loading = false;
      state.userInfo = payload?.data;
      state.isGetInfoSuccess = true;
    },
    [getUserInfo.rejected]: (state) => {
      state.loading = false;
    },

    [userRelogin.pending]: (state) => {
      state.isSuccessRelogin = false;
    },
    [userRelogin.fulfilled]: (state, { payload }) => {
      state.isSuccessRelogin = true;
    },
    [userRelogin.rejected]: (state, { payload }) => {
      state.isSuccessRelogin = false;
    },
  },
});

export const getAllUserInfo = (state) => state.user.userInfo;
export const { setTokenState } = userSlice.actions;

export const userReducer = userSlice.reducer;

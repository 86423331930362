import React from 'react';

const StarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14374 4.62802C8.93034 2.66702 9.32364 1.68652 9.99997 1.68652C10.6763 1.68652 11.0696 2.66702 11.8562 4.62802L11.8928 4.71934C12.3372 5.82721 12.5594 6.38114 13.0123 6.71783C13.4651 7.05452 14.0596 7.10776 15.2485 7.21423L15.4634 7.23348C17.4092 7.40774 18.3822 7.49488 18.5903 8.11386C18.7985 8.73284 18.076 9.39019 16.6309 10.7049L16.1487 11.1436C15.4172 11.8092 15.0514 12.1419 14.8809 12.5781C14.8491 12.6594 14.8227 12.7428 14.8018 12.8276C14.6897 13.2822 14.7968 13.765 15.0111 14.7304L15.0777 15.031C15.4714 16.8053 15.6682 17.6925 15.3246 18.0751C15.1961 18.2181 15.0292 18.3211 14.8438 18.3717C14.3476 18.5071 13.6431 17.933 12.2342 16.7849C11.309 16.031 10.8464 15.6541 10.3153 15.5693C10.1064 15.5359 9.89353 15.5359 9.68463 15.5693C9.15352 15.6541 8.69094 16.031 7.76577 16.7849C6.35681 17.933 5.65234 18.5071 5.15614 18.3717C4.97072 18.3211 4.80381 18.2181 4.67538 18.0751C4.33171 17.6925 4.52854 16.8053 4.92222 15.031L4.98889 14.7304C5.2031 13.765 5.31021 13.2822 5.19815 12.8276C5.17725 12.7428 5.15081 12.6594 5.11901 12.5781C4.94854 12.1419 4.58279 11.8092 3.85128 11.1436L3.369 10.7049C1.92395 9.39019 1.20143 8.73284 1.40961 8.11386C1.61779 7.49488 2.5907 7.40774 4.53651 7.23348L4.75145 7.21423C5.94036 7.10775 6.53482 7.05452 6.98767 6.71783C7.44052 6.38114 7.66272 5.8272 8.10711 4.71934L8.14374 4.62802Z"
        stroke="#F2ED49"
        strokeWidth="2"
      />
    </svg>
  );
};

export default StarIcon;

import { Typography } from 'antd';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export default function Volume({ vol, setVol, maxVol = 10000000 }) {
  return (
    <div className="w-full flex flex-col rounded-lg bg-custom-darkBlue600">
      <div className="w-full h-[40px] flex items-center justify-between pl-5 rounded-lg bg-custom-darkBlue600">
        <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">Khối lượng</Typography.Text>
        <div className="flex justify-between items-center mr-12">
          <NumericFormat
            className="w-[88px] text-lg text-center rounded-none border-none bg-transparent text-white focus:outline-none relative"
            value={vol}
            isAllowed={(values) => {
              const { value } = values;
              return Number(value) <= maxVol && Number(value) >= 0;
            }}
            onValueChange={(values) => setVol(values.floatValue)}
            decimalScale={0}
            allowNegative={false}
            thousandSeparator=","
            onFocus={(e) => e.target.select()}
          />
        </div>
      </div>
    </div>
  );
}

import PostItem from 'components/PortfolioScreenComponents/NewsScreenComponents/PostItem';
import { watchlist_permission } from 'constants/permission_key';
import {
  closeAnnouncementSelector,
  handleSetTypeOfNewsModal,
} from 'features/uiHandler/uiHandlerSlice';
import {
  clearPosts,
  getWatchlistNews,
  watchlistDetailSelector,
  watchlistPostsSelector,
} from 'features/watchlist/watchlistSlice';
import usePermission from 'hooks/usePermission';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewsBox from '../../GlobalComponents/NewsBox';
import { NewsBoxWrapper, PostListStyled } from './styles';

const WatchlistNews = () => {
  const dispatch = useDispatch();
  const [, news_permission] = usePermission({
    key: watchlist_permission.news,
  });

  const { data: currentWatchlist } = useSelector(watchlistDetailSelector);
  const closeAnnouncement = useSelector(closeAnnouncementSelector);
  const {
    data: posts,
    loading,
    pagination,
    filter,
  } = useSelector(watchlistPostsSelector);
  const { isLoadingSkeletonWatchlistNews } = useSelector(
    (state) => state.watchlist
  );

  const [modalLoading, setModalLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const loadPost = async () => {
    dispatch(
      getWatchlistNews({
        watchlist_id: currentWatchlist._id,
        filter,
        pagination: {
          ...pagination,
          page: pagination.page + 1,
        },
      })
    );
  };

  const handleChangeSector = async (sector) => {
    try {
      setModalLoading(true);
      dispatch(clearPosts());
      await dispatch(
        getWatchlistNews({
          watchlist_id: currentWatchlist._id,
          filter: { ...filter, stockCodeList: [], sector },
        })
      ).unwrap();
      setModalLoading(false);
    } catch (error) {
      console.log('getWatchlistNews: ', { error });
    }
  };

  const handleSelectStockCode = async (stockCodeList) => {
    try {
      setModalLoading(true);
      dispatch(clearPosts());
      await dispatch(
        getWatchlistNews({
          watchlist_id: currentWatchlist._id,
          filter: { ...filter, sector: '', stockCodeList },
        })
      ).unwrap();
      setModalLoading(false);
    } catch (error) {
      console.log('getWatchlistNews: ', { error });
    }
  };

  useEffect(() => {
    dispatch(handleSetTypeOfNewsModal('watchlistNewsModal'));
  }, [dispatch]);

  const [isClickedBtn, setIsClickedBtn] = useState(false);

  return (
    <NewsBoxWrapper closeAnnouncement={closeAnnouncement}>
      {isLoadingSkeletonWatchlistNews && (
        <PostListStyled>
          {new Array(10).fill(null).map((_, index) => (
            <PostItem.Loading key={index} type="full" />
          ))}
        </PostListStyled>
      )}

      <NewsBox
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        isClickedBtn={isClickedBtn}
        setIsClickedBtn={setIsClickedBtn}
        loading={loading}
        modalLoading={modalLoading}
        stockCodes={currentWatchlist?.stocks || []}
        sectorList={
          new Set(currentWatchlist?.status?.map((item) => item.sector)) || []
        }
        permission={news_permission}
        posts={posts}
        next={loadPost}
        isNext={pagination.isNext}
        filter={filter}
        onChangeSector={handleChangeSector}
        onSelectStockCode={handleSelectStockCode}
      />
    </NewsBoxWrapper>
  );
};

export default WatchlistNews;

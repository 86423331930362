import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ConfigProvider } from 'antd';
import './utils/darkMode.css';
import './utils/lightMode.css';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import Loader from './components/GlobalComponents/UIHandler/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './graphQL';

/* -------------------------------------------------------------------------- */
/*                              CONFIG LANGUAGES                              */
/* -------------------------------------------------------------------------- */

const { REACT_APP_CURRENT_LANGUAGE } = process.env;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['vi'],
    lng: `${REACT_APP_CURRENT_LANGUAGE}`,
    fallbackLng: `${REACT_APP_CURRENT_LANGUAGE}`,
    detection: {
      order: [
        'cookie',
        'htmlTag',
        'localStorage',
        'subdomain',
        'querystring',
        'sessionStorage',
        'path',
        'navigator',
      ],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   ******                                   */
/* -------------------------------------------------------------------------- */

const loadingMarkup = <Loader />;

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <ConfigProvider>
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

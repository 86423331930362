import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  error,
  success,
} from 'components/GlobalComponents/NotificationModal/NotiModal';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  loading: false,
};

const { REACT_APP_URL } = process.env;

export const portfolioConnectionTesting = createAsyncThunk(
  'connectionTesting/portfolioConnectionTesting',

  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/connection`,
        {
          account_broker: 'xxx',
          account_number: 'xxx',
          account_type: 'xxx',
          account_trading: 'xxx',
          account_pass: 'xxx',
        }
      );

      const data = await response.data;

      if (data.status) {
        success({ message: 'Kết nối thành công' });
      }

      if (!data.status) {
        error({ message: data.message });

        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const portfolioDisconnection = createAsyncThunk(
  'connectionTesting/portfolioDisconnection',

  async (thunkAPI, { rejectWithValue }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/portfolio/disconnection`,
      {
        id: '621c924abb3cdb36c7924ea4',
      }
    );

    const data = await response.data;

    if (data.status === false) {
      error({ message: data.message });
      return rejectWithValue(data.message);
    }

    return data;
  }
);

export const portfolioConnectionTestingSlice = createSlice({
  name: 'portfolioConnectionTesting',
  initialState,
  reducers: {
    resetConnectionState: (state) => {
      state.isSuccessConnect = false;
      state.isFailedConnect = false;
    },
  },
  extraReducers: {
    [portfolioConnectionTesting.pending]: (state) => {
      state.loadingTest = true;
    },
    [portfolioConnectionTesting.fulfilled]: (state, { payload }) => {
      state.loadingTest = false;
      state.isSuccessConnect = true;
    },
    [portfolioConnectionTesting.rejected]: (state) => {
      state.loadingTest = false;
      state.isFailedConnect = true;
    },

    [portfolioDisconnection.pending]: (state) => {
      state.loadingDisconnection = true;
    },
    [portfolioDisconnection.fulfilled]: (state, { payload }) => {
      state.loadingDisconnection = false;
      state.isSuccessConnect = false;
      state.isSuccessDisconnect = true;
    },
    [portfolioDisconnection.rejected]: (state) => {
      state.loadingDisconnection = false;
    },
  },
});

export const { resetConnectionState } = portfolioConnectionTestingSlice.actions;

export const portfolioConnectionTestingReducer =
  portfolioConnectionTestingSlice.reducer;

import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div``;

export const Body = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Main = styled.div`
  background: #24283d;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
`;

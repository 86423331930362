import Cookies from 'js-cookie';

export const EXCHANGES = {
  UPCOM: 'UPCOM',
  HSX: 'HSX',
  HNX: 'HNX',
  HSX30: 'HSX30',
  HOSE: 'HOSE',
};

export const COOKIES_KEY = {
  ctRememberPassword: 'ctRememberPassword',
};

export const removeAuthCookieData = () => {
  Cookies.remove(COOKIES_KEY.ctRememberPassword);
};

export const COOKIE_OPTIONS = {
  domain: process.env.NEXT_PUBLIC_COOKIE_SHARE_DOMAIN,
  path: '/',
};

const RankIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_255_2323)">
        <path
          d="M8.32333 2.08068L13.4953 3.37401C13.792 3.44735 14 3.71401 14 4.02068V4.66668H14.6667C15.4 4.66668 16 5.26668 16 6.00001V8.00001C16 9.10468 15.1047 10 14 10H13.7193C13.3627 10.9 12.6847 11.6573 11.7887 12.1053L8.596 13.702C8.22067 13.8893 7.77933 13.8893 7.404 13.702L4.21067 12.1053C3.316 11.6573 2.638 10.9 2.28133 10H2C0.895333 10 0 9.10468 0 8.00001V6.00001C0 5.26335 0.596667 4.66668 1.33333 4.66668H2V4.02001C2 3.71468 2.208 3.44801 2.50467 3.37335L7.67667 2.08068C7.88867 2.02735 8.11133 2.02735 8.32333 2.08068ZM2 6.00001H1.33333V8.00001C1.33333 8.36801 1.632 8.66668 2 8.66668V6.00001ZM14.6667 6.00001H14V8.66668C14.368 8.66668 14.6667 8.36801 14.6667 8.00001V6.00001Z"
          fill="#F2ED49"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_2323">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RankIcon;

import {
  AnnouncementLinkStyled,
  AnnouncementItemWrapper,
  AnnouncementTitleStyled,
  AnnouncementTimeStyled,
} from './styles';
import './styles.css';

const AnnouncementItem = ({ data }) => {
  if (!data) return null;

  const { content, link, time, name } = data;

  return (
    <AnnouncementItemWrapper>
      <AnnouncementTimeStyled className="announcement-time">
        {time}
      </AnnouncementTimeStyled>

      <AnnouncementTitleStyled
        ellipsis={Boolean(data.link)}
        className="announcement-title"
      >
        <div>{name}</div>

        <div style={{ marginTop: '0.6rem' }}>{content}</div>
        {data.link && (
          <AnnouncementLinkStyled
            className="announcement-link-1"
            onClick={(e) => e.stopPropagation()}
            href={link}
            target="_blank"
          >
            Xem thêm
          </AnnouncementLinkStyled>
        )}
      </AnnouncementTitleStyled>

      {data.link && (
        <AnnouncementLinkStyled
          style={{ display: 'none' }}
          className="announcement-link-2"
          onClick={(e) => e.stopPropagation()}
          href={link}
          target="_blank"
        >
          Xem thêm
        </AnnouncementLinkStyled>
      )}
    </AnnouncementItemWrapper>
  );
};

export default AnnouncementItem;

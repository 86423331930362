import React, { useEffect, useState } from 'react';
import TradingPrices from './TradingPrices';
import TradingView from './TradingView';
import TradingFunction from './TradingFunction';
import { TradingOrderContext } from './TradingOrderContext';
import { getTradingContext } from 'utils/api';
import { useQuery } from '@tanstack/react-query';
import Modal from 'components/GlobalComponents/Modal';
import { withResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';
import { stocksSelector } from 'features/stocks/stockSlice';

const TradingOrder = ({ openTradingView, setOpenTradingView, width }) => {
  const [symbol, setSymbol] = useState('AAA');
  const [stocks, setStocks] = useState([]);
  const [symbolData, setSymbolData] = useState(null);
  const { data: stocksLibrary } = useSelector(stocksSelector);

  const { data } = useQuery({
    queryKey: ['getTradingContext'],
    queryFn: () => getTradingContext(),
  });

  useEffect(() => {
    setStocks(
      data?.followerAllocations.map((item) => ({
        ticker: item.symbol,
        companyName: stocksLibrary?.find(
          (stock) => stock.ticker === item.symbol
        )?.companyName,
        exchange: stocksLibrary?.find((stock) => stock.ticker === item.symbol)
          ?.exchange,
      }))
    );
    data?.followerAllocations[0]?.symbol &&
      setSymbol(data?.followerAllocations[0]?.symbol);
  }, [data, stocksLibrary]);

  useEffect(() => {
    width > 768 && openTradingView && setOpenTradingView(false);
  }, [width, openTradingView, setOpenTradingView]);

  return (
    <TradingOrderContext.Provider
      value={{ symbol, setSymbol, stocks, symbolData, setSymbolData }}
    >
      <section className="w-full flex flex-col gap-4">
        <TradingPrices />
        <div className="flex flex-col md:flex-row gap-4">
          <TradingView className="hidden md:block" />
          <TradingFunction copierContext={data} />
        </div>
      </section>

      <Modal
        open={openTradingView}
        footer={null}
        centered
        width="100%"
        bodyStyle={{ padding: 6 }}
        onCancel={() => setOpenTradingView(false)}
      >
        <TradingView />
      </Modal>
    </TradingOrderContext.Provider>
  );
};

export default withResizeDetector(TradingOrder);

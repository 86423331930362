import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { Container, Heading, Actions, StyledForm } from './styles';
import { useTranslation } from 'react-i18next';
import { hsxCompanyList } from 'data/hsxCompanyList';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFiles } from 'features/recommendation/customerFileSlice';
import { getWatchlists } from 'features/watchlist/watchlistSlice';
import CloseIcon from 'assets/icons/CloseIcon';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';
import moment from 'moment';

const MODES = [
  { value: 'off', label: 'OFF' },
  {
    value: 'mode1',
    label:
      'Cho phép tự động gửi khuyến nghị đối với tất cả các mã cổ phiếu được DATX khuyến nghị',
  },
  {
    value: 'mode2',
    label:
      'Cho phép tự động gửi khuyến nghị đối với các mã cổ phiếu đang nắm giữ',
  },
  {
    value: 'mode3',
    label:
      'Cho phép tự động gửi khuyến nghị đối với các mã cổ phiếu được cấu hình',
  },
  {
    value: 'mode4',
    label:
      'Cho phép tự động gửi khuyến nghị đối với các mã cổ phiếu nằm trong Danh sách theo dõi được cấu hình',
  },
];

const SetupAutoRecommendation = ({ visible, loading, onCancel, onOk }) => {
  const dispatch = useDispatch();
  const { customerFiles } = useSelector((state) => state.customerFile);
  const { list } = useSelector((state) => state.watchlist);
  const { t } = useTranslation(['recommendationsScreen']);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    mode: 'off',
    symbols: [],
    watchlists: [],
    customerSetIds: [],
    comment: '',
    startDate: '',
    endDate: '',
  });

  const formRule = {
    required: {
      required: true,
      message: t('recommendationForm.requiredMessage'),
    },
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    form.resetFields();
  };

  const handleFormValuesChange = (_, allValue) => {
    setFormData((state) => ({ ...state, ...allValue }));
  };

  const getApiMode = (mode) => {
    if (mode === 'mode1' || mode === 'mode2') {
      return 1;
    }
    if (mode === 'mode3') {
      return 2;
    }
    if (mode === 'mode4') {
      return 3;
    }
    return 0;
  };

  const onSubmit = async (values) => {
    let params = {
      Mode: getApiMode(values.mode),
    };
    if (params.Mode === 1) {
      params.Data = {
        Mode1: {
          Scope: values.mode === 'mode1' ? 'all' : 'holding',
          Comment: values.comment,
          CustomersetIds: values.customerSetIds,
          StartTime: moment(values.startDate).format('DD-MM-YYYY'),
          EndTime: moment(values.endDate).format('DD-MM-YYYY'),
        },
      };
    } else if (params.Mode === 2) {
      params.Data = {
        Mode2: {
          Symbols: values.symbols,
          Comment: values.comment,
          CustomersetIds: values.customerSetIds,
          StartTime: moment(values.startDate).format('DD-MM-YYYY'),
          EndTime: moment(values.endDate).format('DD-MM-YYYY'),
        },
      };
    } else if (params.Mode === 3) {
      params.Data = {
        Mode3: {
          WatchlistIds: values.watchlists,
          Comment: values.comment,
          CustomersetIds: values.customerSetIds,
          StartTime: moment(values.startDate).format('DD-MM-YYYY'),
          EndTime: moment(values.endDate).format('DD-MM-YYYY'),
        },
      };
    }
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_RECOMMENDATION_API_URL}/auto-configs`,
        params
      );
      if (onOk) {
        onOk(values);
      }
      toast.success(
        'Lưu thiết lập khuyến nghị tự động thành công',
        settingsSuccess
      );
    } catch (e) {
      toast.error('Lỗi thiết lập khuyến nghị tự động!', settingsError);
    }
  };

  useEffect(() => {
    dispatch(getWatchlists());
    dispatch(
      getCustomerFiles({
        page: 1,
        pageSize: 10,
      })
    );
    (async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_RECOMMENDATION_API_URL}/auto-configs`
        );
        if (response.data?.data?.Mode === 0) {
          const data = {
            mode: 'off',
            symbols: [],
            watchlists: [],
            customerSetIds: [],
            comment: '',
            startDate: '',
            endDate: '',
          };
          form.setFieldsValue(data);
          setFormData(data);
        } else if (response.data?.data?.Mode === 1) {
          console.log(response.data?.data?.Data?.Mode1?.StartTime);
          const data = {
            mode:
              response.data?.data?.Data?.Mode1?.Scope === 'all'
                ? 'mode1'
                : 'mode2',
            symbols: [],
            watchlists: [],
            customerSetIds: response.data?.data?.Data?.Mode1?.CustomersetIds,
            comment: response.data?.data?.Data?.Mode1?.Comment,
            startDate: response.data?.data?.Data?.Mode1?.StartTime
              ? moment(response.data?.data?.Data?.Mode1?.StartTime)
              : '',
            endDate: response.data?.data?.Data?.Mode1?.EndTime
              ? moment(response.data?.data?.Data?.Mode1?.EndTime)
              : '',
          };
          form.setFieldsValue(data);
          setFormData(data);
        } else if (response.data?.data?.Mode === 2) {
          const data = {
            mode: 'mode3',
            symbols: response.data?.data?.Data?.Mode2?.Symbols,
            watchlists: [],
            customerSetIds: response.data?.data?.Data?.Mode2?.CustomersetIds,
            comment: response.data?.data?.Data?.Mode2?.Comment,
            startDate: response.data?.data?.Data?.Mode2?.StartTime
              ? moment(response.data?.data?.Data?.Mode2?.StartTime)
              : '',
            endDate: response.data?.data?.Data?.Mode2?.EndTime
              ? moment(response.data?.data?.Data?.Mode2?.EndTime)
              : '',
          };
          form.setFieldsValue(data);
          setFormData(data);
        } else if (response.data?.data?.Mode === 3) {
          const data = {
            mode: 'mode4',
            symbols: [],
            watchlists: response.data?.data?.Data?.Mode3?.WatchlistIds,
            customerSetIds: response.data?.data?.Data?.Mode3?.CustomersetIds,
            comment: response.data?.data?.Data?.Mode3?.Comment,
            startDate: response.data?.data?.Data?.Mode3?.StartTime
              ? moment(response.data?.data?.Data?.Mode3?.StartTime)
              : '',
            endDate: response.data?.data?.Data?.Mode3?.EndTime
              ? moment(response.data?.data?.Data?.Mode3?.EndTime)
              : '',
          };
          form.setFieldsValue(data);
          setFormData(data);
        }
      } catch (e) {
        toast.error('Lỗi load thiết lập khuyến nghị tự động!', settingsError);
      }
    })();
  }, [dispatch]);

  return (
    <Modal
      centered
      destroyOnClose={true}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="85rem"
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
    >
      <Container>
        <Heading>{t('setupAutoRecommendation.heading')}</Heading>
        <StyledForm
          onValuesChange={handleFormValuesChange}
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          name="createRecommendation"
          scrollToFirstError
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="mode"
                label={
                  <label>
                    {t('setupAutoRecommendation.selectConfiguration')}
                  </label>
                }
                rules={[formRule.required]}
              >
                <Select showSearch>
                  {MODES.map((mode) => (
                    <Select.Option key={mode.value} value={mode.value}>
                      {mode.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="symbols"
                label={
                  <label>{t('setupAutoRecommendation.selectSymbol')}</label>
                }
                rules={formData.mode === 'mode3' ? [formRule.required] : []}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  disabled={formData.mode === 'mode3' ? false : true}
                >
                  {hsxCompanyList.map((company) => (
                    <Select.Option key={company.Code} value={company.Code}>
                      {company.Code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="watchlists"
                label={
                  <label>{t('setupAutoRecommendation.selectWatchlist')}</label>
                }
                rules={formData.mode === 'mode4' ? [formRule.required] : []}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  disabled={formData.mode === 'mode4' ? false : true}
                >
                  {list?.data?.map((watchlist) => (
                    <Select.Option value={watchlist._id}>
                      {watchlist.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="customerSetIds"
                label={
                  <label>
                    {t('setupAutoRecommendation.selectCustomerset')}
                  </label>
                }
                rules={formData.mode === 'off' ? [] : [formRule.required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  disabled={formData.mode === 'off' ? true : false}
                >
                  {customerFiles.map((customerFile) => (
                    <Select.Option value={customerFile._id}>
                      {customerFile.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="comment"
                label={<label>{t('setupAutoRecommendation.comment')}</label>}
              >
                <Input.TextArea
                  rows={2}
                  disabled={formData.mode === 'off' ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label={t('setupAutoRecommendation.effectFrom')}
                name="startDate"
                rules={formData.mode === 'off' ? [] : [formRule.required]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  disabled={formData.mode === 'off' ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label={t('setupAutoRecommendation.effectTo')}
                name="endDate"
                rules={formData.mode === 'off' ? [] : [formRule.required]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  disabled={formData.mode === 'off' ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
        </StyledForm>
        <Row>
          <Actions>
            <Button onClick={handleCancel}>
              {t('setupAutoRecommendation.cancel')}
            </Button>
            <Button
              type="primary"
              disabled={loading}
              onClick={() => form.submit()}
            >
              {t('setupAutoRecommendation.save')}
            </Button>
          </Actions>
        </Row>
      </Container>
    </Modal>
  );
};

export default React.forwardRef(SetupAutoRecommendation);

import { Form, Input, Modal, Select, Space } from 'antd';
import CloseIcon from 'assets/icons/CloseIcon';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import { watchlist_permission } from 'constants/permission_key';
import { hsxCompanyList } from 'data/hsxCompanyList';
import {
  createWatchlist,
  editWatchlist,
  watchlistsSelector,
} from 'features/watchlist/watchlistSlice';
import usePermission from 'hooks/usePermission';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WatchlistModalContainer } from './styles';

const WatchlistModal = ({ visible, onCancel, watchlist, type }) => {
  /* ---------------------------- TYPE CREATE/EDIT ---------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation('watchlistScreen');

  const { data: watchlists } = useSelector(watchlistsSelector);
  const [disabled, setDisabled] = useState(true);

  const [handleOk] = usePermission({
    key: watchlist_permission[type],
    handle: async () => {
      try {
        const values = await form.validateFields();
        const { watchlist_name } = values;

        /* -------------------------------------------------------------------------- */
        /*                            CHECK DUPLICATE NAME                            */
        /* -------------------------------------------------------------------------- */
        const duplicate = watchlists.find(
          (watchlist) => watchlist.name === watchlist_name
        );

        if (
          (type === 'create' && duplicate) ||
          (type === 'edit' && duplicate && duplicate._id !== watchlist._id)
        ) {
          form.setFields([
            {
              name: 'watchlist_name',
              errors: [
                `${t(
                  'watchlistSelectorSection.watchlistModal.form.field1.validateHelper2'
                )}`,
              ],
            },
          ]);
          return;
        }
        /* -------------------------------------------------------------------------- */
        /*                                   ******                                   */
        /* -------------------------------------------------------------------------- */

        if (type === 'edit') {
          values.watchlist_id = watchlist._id;
          await dispatch(editWatchlist(values)).unwrap();
        } else {
          await dispatch(createWatchlist(values)).unwrap();
        }
        onCancel();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal
      centered
      maskClosable={false}
      width="70rem"
      visible={visible}
      onCancel={onCancel}
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
      title={
        <div style={{ maxWidth: '66%', margin: 'auto', fontSize: '2rem' }}>
          {type === 'create'
            ? `${t('watchlistSelectorSection.watchlistModal.createWatchlist')}`
            : `${t(
                'watchlistSelectorSection.watchlistModal.updateWatchlist'
              )} ${watchlist?.name}`}
        </div>
      }
      footer={
        <Space>
          <SecondaryButton
            size="large"
            onClick={onCancel}
            textBtn={`${t(
              'watchlistSelectorSection.watchlistModal.form.textBtn1'
            )}`}
            large
          />
          <PrimaryButton
            large
            disabled={disabled}
            onClick={handleOk}
            textBtn={
              type === 'create'
                ? `${t(
                    'watchlistSelectorSection.watchlistModal.form.textBtn2'
                  )}`
                : `${t(
                    'watchlistSelectorSection.watchlistModal.form.textBtn3'
                  )}`
            }
          />
        </Space>
      }
    >
      <WatchlistModalContainer>
        <Form
          initialValues={{
            watchlist_name: watchlist ? watchlist.name : '',
            stocks: watchlist ? watchlist.stocks : [],
          }}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          onFieldsChange={() => {
            if (disabled) setDisabled(false);
          }}
        >
          <Form.Item
            name="watchlist_name"
            label={t(
              'watchlistSelectorSection.watchlistModal.form.field1.label'
            )}
            rules={[
              {
                required: true,
                message: `${t(
                  'watchlistSelectorSection.watchlistModal.form.field1.validateHelper1'
                )}`,
              },
              {
                //eslint-disable-next-line
                pattern: /^[^`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]+$/,
                message: `${t(
                  'watchlistSelectorSection.watchlistModal.form.field1.validateHelper3'
                )}`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="stocks"
            label={t(
              'watchlistSelectorSection.watchlistModal.form.field2.label'
            )}
          >
            <Select
              placeholder={t(
                'watchlistSelectorSection.watchlistModal.form.field2.placeholder'
              )}
              mode="multiple"
              style={{
                border: '1px solid var(--border-color)',
                borderRadius: '4px',
                padding: '0.4rem',
              }}
            >
              {hsxCompanyList.map((company) => (
                <Select.Option key={company.Code} value={company.Code}>
                  {company.Code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </WatchlistModalContainer>
    </Modal>
  );
};

export default WatchlistModal;

import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Select as BaseSelect } from 'antd';
import { Typography } from 'antd';

export default function Select({
  title,
  active,
  setActive,
  options,
  ...props
}) {
  return (
    <div className="w-full h-[40px] flex items-center justify-between pl-5 rounded-lg bg-custom-darkBlue600">
      <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">
        {title || 'Danh mục'}
      </Typography.Text>
      <BaseSelect
        {...props}
        suffixIcon={
          <KeyboardArrowDownIcon className="!w-10 !h-10 text-custom-gray300 mt-3" />
        }
        value={active}
        style={{
          fontSize: 16,
          textAlign: 'center',
          minWidth: 128,
          marginTop: -5,
        }}
        bordered={false}
        onChange={(value) => setActive(value)}
      >
        {options.map((item) => (
          <BaseSelect.Option key={item.key} value={item.key}>
            {item.label}
          </BaseSelect.Option>
        ))}
      </BaseSelect>
    </div>
  );
}

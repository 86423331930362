const EditIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93225 12.2501H13.75V13.7501H0.25V10.5678L7.675 3.14281L10.8565 6.32581L4.9315 12.2501H4.93225ZM8.73475 2.08306L10.3263 0.491563C10.4669 0.350959 10.6576 0.271973 10.8565 0.271973C11.0554 0.271973 11.2461 0.350959 11.3868 0.491563L13.5085 2.61331C13.6491 2.75396 13.7281 2.94469 13.7281 3.14356C13.7281 3.34244 13.6491 3.53317 13.5085 3.67381L11.917 5.26456L8.7355 2.08306H8.73475Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;

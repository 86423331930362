import { useEffect, useRef, useState, useMemo } from 'react';
import _sortBy from 'lodash/sortBy';
import { renderToStaticMarkup } from 'react-dom/server';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import moment from 'moment-timezone';
import React from 'react';
import { Button } from 'antd';
import { convertRemToPx, formatCurrency } from 'utils';
import {
  getInvestmentPerformance,
  investmentPerformanceSelector,
  leadersSelector,
} from 'features/copyTrade/copyTradeSlice';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';

if (typeof Highcharts === 'object') {
  HighchartsBoost(Highcharts);
  HighchartsExporting(Highcharts);
  Highcharts.setOptions({
    time: {
      getTimezoneOffset: function (timestamp) {
        const zone = 'Asia/Ho_Chi_Minh';
        const timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

        return timezoneOffset;
      },
    },
  });
}

export const FilterType = {
  LAST_MONTH: '1M',
  LAST_3_MONTHS: '3M',
  LAST_6_MONTHS: '6M',
  LAST_1_YEAR: '1Y',
};

export const filterOptions = [
  {
    name: '1M',
    type: FilterType.LAST_MONTH,
  },
  {
    name: '3M',
    type: FilterType.LAST_3_MONTHS,
  },
  {
    name: '6M',
    type: FilterType.LAST_6_MONTHS,
  },
  {
    name: '1Y',
    type: FilterType.LAST_1_YEAR,
  },
];

const NAVChart = () => {
  const [chartHeight, setChartHeight] = useState(340);
  const dispatch = useDispatch();
  const { data: leaders } = useSelector(leadersSelector);
  const investmentPerformance = useSelector(investmentPerformanceSelector);

  const [filterType, setFilterType] = useState(FilterType.LAST_MONTH);
  const chartRef = useRef(null);

  const data = useMemo(() => {
    const getData = investmentPerformance || {
      copierSummaryChart: [],
    };
    const calData = {};
    getData.copierSummaryChart?.forEach((i) => {
      if (!calData[i.date]) {
        calData[i.date] = {
          d: moment(i.date, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
          time: moment(i.date, 'YYYY-MM-DD').valueOf(),
          // pi: 0,
          // pic: 0,
          // vni: 0,
          // vnic: 0,
          ci: i.nav,
        };
      }

      calData[i.date].ci = i.nav;
    });
    return _sortBy(Object.values(calData), 'time');
  }, [investmentPerformance]);

  const options = useMemo(
    () => ({
      time: {
        useUTC: false,
      },
      chart: {
        height: chartHeight,
        zoomType: 'xy',
        backgroundColor: 'transparent',
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 10,
        spacingTop: 25,
        responsive: true,
      },
      title: {
        text: '',
      },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            radius: 4,
          },
          turboThreshold: 3000,
        },
      },
      tooltip: {
        shared: true,
        split: false,
        backgroundColor: `#343957`,
        borderColor: `transparent`,
        borderRadius: 4,
        xDateFormat: '%d-%m-%y %H:%M',
        enabled: true,
        useHTML: true,
        formatter: function () {
          const points = this.points;
          const picPoint = points[0]?.point?.options; // pi

          return renderToStaticMarkup(
            <div>
              <p style={{ color: '#B0B0B0', fontSize: '12px', marginBottom: 10 }}>
                Ngày {moment.unix(picPoint?.x / 1000).format('DD/MM/YY')}
              </p>

              {picPoint?.y && (
                <p className="text-white text-level_2">Tổng số NAV: {formatCurrency(Math.round(picPoint?.y))}</p>
              )}
            </div>,
          );
        },
      },
      xAxis: {
        showLastLabel: true,
        labels: {
          enable: false,
          style: {
            color: '#FFFFFF',
            fontSize: '1.25rem',
          },
          formatter: function () {
            return Highcharts.dateFormat('%d %b', this.value);
          },
        },
        crosshair: {
          width: 1,
          color: '#fff',
          dashStyle: 'dash',
        },
        type: 'datetime',
        ordinal: false,
        startOnTick: false,
        endOnTick: false,
        minPadding: 0,
        maxPadding: 0,
        lineColor: `#959CA9`,
        tickColor: `transparent`,
        gridLineDashStyle: 'longdash',
        gridLineWidth: 0,
        arrow: true,
      },
      yAxis: [
        {
          id: 'y_axis_0',
          title: {
            text: '%',
            align: 'high',
            style: {
              color: 'white',
              fontSize: '1.35rem',
            },
            rotation: 0,
            y: -15,
            offset: -5,
          },
          crosshair: {
            width: 1,
            color: '#fff',
            dashStyle: 'dash',
          },
          labels: {
            style: {
              color: '#FFFFFF',
              fontSize: '1.25rem',
            },
          },
          startOnTick: true,
          endOnTick: true,
          lineWidth: 1,
          lineColor: '#959CA9',
          tickColor: 'transparent',
          gridLineWidth: 0,
          arrow: true,
        },
      ],
      series: [
        {
          yAxis: 'y_axis_0',
          type: 'line',
          color: `#1FCD45`,
          data: data.map((item) => ({
            x: moment(item.d).unix() * 1000,
            y: item.ci,
            percentage: item.cic,
          })),

          cursor: 'pointer',
          lineWidth: 1,
          marker: {
            enabled: false,
          },
        },
      ],
    }),
    [chartHeight, data],
  );

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(convertRemToPx(50));
    };
    window.addEventListener('resize', handleResize);

    setChartHeight(convertRemToPx(50));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    !isEmpty(leaders[0]) &&
      dispatch(
        getInvestmentPerformance({
          id: leaders[0]?.subAccountId,
          timeRange: filterType,
          source: leaders[0]?.source?.toUpperCase(),
        }),
      );
  }, [dispatch, filterType, leaders]);

  return (
    <div className="flex flex-col w-full bg-[#25283B] rounded-b-xl">
      <div className="w-full bg-[#353955] p-4 rounded-t-xl mb-6">
        <h4 className="text-4xl mb-2">NAV sao chép</h4>
        <span className="text-custom-darkBlue200">NAV được ghi nhận vào lúc 22h đối với ngày T-1  </span>
      </div>
      <div className="flex items-center overflow-x-auto w-full p-4 gap-5 mb-2 xl:!pl-[46px]">
        {filterOptions.map((item) => (
          <Button
            key={item.name}
            onClick={() => setFilterType(item.type)}
            type={filterType === item.type ? 'primary' : 'default'}
            className={clsx(
              'flex-shrink w-full justify-center text-white rounded-lg',
              filterType === item.type ? 'border-custom-blue500 bg-custom-blue500' : 'border-white',
            )}
          >
            {item.name}
          </Button>
        ))}
      </div>
      <div className="px-4">
        <HighchartsReact
          containerProps={{ style: { height: '100%', width: '100%' } }}
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>

      <ul className="flex w-full py-4 px-5 mx-2 max-w-full gap-5 mb-4 mt-6" style={{ overflowX: 'overlay' }}>
        <li className="text-white flex items-center gap-1 w-1/2">
          <span className="w-8 h-[2px] inline-block bg-custom-green500" />
          <span className="block w-max ml-1">Tổng số NAV copy theo leader</span>
        </li>
      </ul>
    </div>
  );
};

export default React.forwardRef(NAVChart);

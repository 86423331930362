import { Tooltip } from 'antd';
import React from 'react';

const GlobalTooltip = ({ children, title, width, placement }) => {
  return (
    <Tooltip
      placement={placement}
      title={title}
      color="var(--primary-color)"
      overlayInnerStyle={{
        border: '1px solid var(--border-color)',
        color: 'var(--white-color)',
        fontWeight: '400',
        fontSize: '13px',
        borderRadius: '4px',
        lineHeight: '18px',
        paddingLeft: '0.8rem',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        width: width,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default GlobalTooltip;

import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Chart, ChartCanvas } from 'react-stockcharts';
import { Label } from 'react-stockcharts/lib/annotation';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  CurrentCoordinate,
} from 'react-stockcharts/lib/coordinates';
import { fitWidth } from 'react-stockcharts/lib/helper';
import { InteractiveYCoordinate } from 'react-stockcharts/lib/interactive';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { LineSeries } from 'react-stockcharts/lib/series';
import { HoverTooltip } from 'react-stockcharts/lib/tooltip';
import {
  createVerticalLinearGradient,
  hexToRGBA,
  last,
} from 'react-stockcharts/lib/utils';
import { saveInteractiveNodes } from './interactiveutils';

const canvasGradientDATX = createVerticalLinearGradient([
  { stop: 0, color: hexToRGBA('#ffffff', 1) },
  { stop: 0.006, color: hexToRGBA('#1f2336', 1) },
  { stop: 1, color: hexToRGBA('#1f2336', 1) },
]);

const sell = {
  ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
  stroke: '#F2ED49',
  strokeWidth: 1,
  textFill: '#F24B78',
  text: '',
  textBox: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.textBox,
    height: 5,
    padding: 1,
  },
  edge: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
    stroke: 'transparent',
    strokeWidth: 0,
    fill: 'transparent',
    fillOpacity: 0,
  },
};

const sellLabel = {
  ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
  stroke: 'transparent',
  strokeWidth: 1,
  textFill: '#F24B78',
  text: 'Xem xét bán',
  bgFill: 'transparent',
  textBox: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.textBox,
    left: -80,
    height: 50,
  },
  edge: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
    stroke: 'transparent',
    strokeWidth: 0,
    strokeOpacity: 0,
    fill: 'transparent',
    fillOpacity: 0,
  },
};

const tracking = {
  ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
  stroke: 'transparent',
  strokeWidth: 1,
  textFill: '#f2ed49',
  textBox: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.textBox,
    padding: 1,
    height: 5,
    left: -80,
  },
  text: 'Theo dõi',
  edge: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
    stroke: 'transparent',
    strokeWidth: 0,
    fill: 'transparent',
    textFill: 'red',
    fillOpacity: 10,
    strokeOpacity: 0,
  },
};

const buy = {
  ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
  stroke: '#F2ED49',
  strokeWidth: 1,
  textFill: '#1ED994',
  textBox: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.textBox,
    padding: 1,
    height: 5,
  },
  text: '',
  edge: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
    stroke: 'transparent',
    strokeWidth: 0,
    fill: 'transparent',
    fillOpacity: 0,
  },
};

const buyLabel = {
  ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
  stroke: 'transparent',
  strokeWidth: 1,
  textFill: '#1ED994',
  textBox: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.textBox,
    padding: 1,
    height: 5,
    left: -80,
  },
  text: 'Xem xét mua',
  edge: {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
    stroke: 'transparent',
    strokeWidth: 0,
    fill: 'transparent',
    fillOpacity: 0,
  },
};
class LineAndScatterChartGrid extends React.Component {
  constructor(props) {
    super(props);
    this.saveNode = this.saveNode.bind(this);
    this.resetYDomain = this.resetYDomain.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.saveInteractiveNodes = saveInteractiveNodes.bind(this);
    this.chartHeight = props.chartHeight;
    this.buyConsiderValue = props.buyConsiderValue;
    this.sellConsiderValue = props.sellConsiderValue;
  }

  state = {
    suffix: '',
    enableInteractiveObject: false,
    yCoordinateList_1: [
      {
        ...tracking,
        yValue:
          (this.props.buyConsiderValue + this.props.sellConsiderValue) / 2,
        id: 1,
        draggable: false,
      },
      {
        ...buy,
        yValue: this.props.buyConsiderValue,
        id: 2,
        draggable: false,
      },

      {
        ...buyLabel,
        yValue: this.props.buyConsiderValue - 10,
        id: 2,
        draggable: false,
      },
      {
        ...sell,
        yValue: this.props.sellConsiderValue,
        id: 3,
        draggable: false,
      },

      {
        ...sellLabel,
        yValue: this.props.sellConsiderValue + 10,
        id: 4,
        draggable: false,
      },
    ],
    yCoordinateList_3: [],
    showModal: false,
    alertToEdit: {},
  };

  componentWillMount() {
    this.setState({
      suffix: 1,
    });
  }
  saveNode(node) {
    this.node = node;
  }
  resetYDomain() {
    this.node.resetYDomain();
  }
  handleReset() {
    this.setState({
      suffix: this.state.suffix + 1,
    });
  }

  render() {
    const {
      type,
      data: initialData,
      width,
      ratio,
      interpolation,
      marginRight,
      tickStroke,
      tickChart,
      dateFormatState,
    } = this.props;

    const margin = { left: 0, right: 40, top: 15, bottom: 25 };

    const height = 180;

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      (d) => {
        if (!d) {
          return new Date();
        }
        return d.date;
      }
    );

    const { data, xScale, xAccessor, displayXAccessor } =
      xScaleProvider(initialData);

    if (!data) {
      return null;
    }
    if (data.length === 0) {
      return null;
    }

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    const [yAxisLabelX, yAxisLabelY] = [
      width - margin.right - 32,
      (height - margin.top - margin.bottom) / 2,
    ];

    const numberFormat = format(',.2r');

    const tooltipContent1 = this.props.t(
      'stockDetailsSection.tab1.DATXRatingPoint.chart.hoverTooltipText'
    );

    let dateFormat;

    if (dateFormatState === 'days') {
      dateFormat = timeFormat('%Y/%m/%d');
    } else {
      dateFormat = timeFormat('%m/%d %H:%M:%S');
    }

    function tooltipContent(ys) {
      return ({ currentItem, xAccessor }) => {
        return {
          x: dateFormat(xAccessor(currentItem)),
          y: [
            {
              label: tooltipContent1,
              value: currentItem.value && numberFormat(currentItem.value),
            },
          ],
        };
      };
    }

    return (
      <ChartCanvas
        ref={this.saveNode}
        height={this.props.chartHeight}
        ratio={ratio}
        width={width - 20}
        margin={{ left: 20, right: marginRight, top: 10, bottom: 30 }}
        type={type}
        zoomEvent={false}
        panEvent={true}
        seriesName={`MSFT_${this.state.suffix}`}
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart id={1} yExtents={(d) => [d.value, 0, 100]}>
          <XAxis
            axisAt="bottom"
            orient="bottom"
            tickStroke={tickStroke}
            stroke={tickStroke}
            ticks={window.innerWidth > 600 ? tickChart : 4}
          />

          <YAxis
            axisAt="right"
            orient="right"
            tickStroke={tickStroke}
            tickFormat={format('.2s')}
          />

          {/* <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat("%Y-%m-%d")}
          />
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")}
          /> */}

          <CurrentCoordinate yAccessor={(d) => d.value} fill="#1ED994" r={5} />

          <Label
            x={yAxisLabelX}
            y={yAxisLabelY}
            rotate={90}
            fontSize={12}
            text=""
            fill="#FFFFFF"
          />

          <LineSeries
            yAccessor={(d) => d.value}
            interpolation={interpolation}
            stroke="#1ED994"
            strokeWidth={2}
            canvasGradient={canvasGradientDATX}
            highlightOnHover={true}
          />

          {/* <ScatterSeries
            yAccessor={(d) => d.value}
            marker={CircleMarker}
            markerProps={{ r: 3, stroke: "#1ED994", fill: "#1ED994" }}
          /> */}

          {/* <InteractiveYCoordinate
            ref={this.saveInteractiveNodes("InteractiveYCoordinate", 1)}
            enabled={this.state.enableInteractiveObject}
            yCoordinateList={this.state.yCoordinateList_1}
          /> */}

          <HoverTooltip
            bgOpacity={0}
            yAccessor=""
            tooltipContent={tooltipContent([
              {
                label: `test`,
                value: '',
              },
              {
                label: `test2`,
                value: '',
              },
            ])}
            fontSize={15}
          />
          {/* 
          <ZoomButtons
            onReset={this.handleReset}
            style={{ cursor: "pointer" }}
          /> */}

          <CrossHairCursor
            stroke={tickStroke === '#ffffff' ? 'white' : 'black'}
          />
        </Chart>

        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

LineAndScatterChartGrid.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
};

LineAndScatterChartGrid.defaultProps = {
  type: 'hybrid',
};
LineAndScatterChartGrid = fitWidth(LineAndScatterChartGrid);

export default withTranslation('ratingsScreen')(LineAndScatterChartGrid);

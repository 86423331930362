/* -------------------------------------------------------------------------- */
/*                             TRANSACTIONS SLICE                             */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  portfolioTransactions: [],
  startingTime: Date.parse(moment().format('YYYY/MM/DD')),
  endingTime: Date.parse(moment().format('YYYY/MM/DD')),
  currentSelectedDateTime:
    localStorage.getItem('language') === 'vi' ? 'Gần đây' : 'Current',
  total: 0,
  loading: false,
};

const { REACT_APP_URL } = process.env;

export const getPortfolioTransactions = createAsyncThunk(
  'portfolioTransactions/getPortfolioTransactions',

  async (
    { portfolioId, startingTime, endingTime, page },
    { rejectWithValue }
  ) => {
    try {
      const startTime = moment(startingTime).startOf('day').valueOf();
      const endTime = moment(endingTime).endOf('day').valueOf();

      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/transactions`,
        {
          portfolio_id: portfolioId,
          start_time: startTime,
          end_time: endTime,
          limit: 10,
          page: page,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrentPortfolioTransactions = createAsyncThunk(
  'portfolioTransactions/getCurrentPortfolioTransactions ',

  async ({ portfolioId, page }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/transactions`,
        {
          portfolio_id: portfolioId,
          limit: 10,
          page: page,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPortfolioTransactionsSlice = createSlice({
  name: 'portfolioTransactions',
  initialState,
  reducers: {
    handleSelectDateTime: (state, { payload }) => {
      state.startingTime = payload.startingTime;
      state.endingTime = payload.endingTime;
    },

    setCurrentSelectedDateTime: (state, { payload }) => {
      state.currentSelectedDateTime = payload;
    },
  },
  extraReducers: {
    [getPortfolioTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioTransactions = payload?.data.transactions;
    },
    [getPortfolioTransactions.rejected]: (state) => {
      state.loading = false;
    },

    [getCurrentPortfolioTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getCurrentPortfolioTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioTransactions = payload?.data.transactions;
      state.total = payload?.data?.meta_pagination.total;
    },
    [getCurrentPortfolioTransactions.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getPortfolioTransactionsSelector = (state) =>
  state.portfolioTransactions.portfolioTransactions;

export const { handleSelectDateTime, setCurrentSelectedDateTime } =
  getPortfolioTransactionsSlice.actions;

export const portfolioTransactionsReducer =
  getPortfolioTransactionsSlice.reducer;

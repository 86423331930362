import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { listSectors } from 'screens/PortfolioScreen/NewsScreen/data';
import { settingsError, settingsSuccess } from 'utils';
import axiosInstance from 'utils/axiosInstance';

let newsController;
let detailController;

export const defaultPostsFilter = {
  sector: listSectors[0].sector_id,
  stockCodeList: [],
  newWatchlistId: '',
};

export const defaultPostsPagination = {
  page: 1,
  limit: 10,
  isNext: true,
};

const defaultPostsProps = {
  data: [],
  loading: false,
  pagination: defaultPostsPagination,
  filter: defaultPostsFilter,
};

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  list: {
    loading: false,
    data: [],
  },
  detail: {
    loading: false,
    data: null,
  },
  sectors: [],
  stockCodes: [],
  posts: defaultPostsProps,
  currentSelectedWatchlist: '',
  currentSelectedWatchlistName: '',
};

const { REACT_APP_URL } = process.env;

export const getWatchlists = createAsyncThunk(
  'watchlist/getList',

  async (_, { rejectWithValue, dispatch }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/list`
    );

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const getWatchlistDetail = createAsyncThunk(
  'watchlist/detail',
  async ({ watchlist_id }, { rejectWithValue, dispatch }) => {
    detailController && detailController.abort();
    detailController = new AbortController();

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/detail`,
      { watchlist_id },
      { signal: detailController.signal }
    );

    const data = response.data;

    if (data.status) {
      dispatch(
        handleSelectWatchlist({
          _id: data.data._id,
          name: data.data.name,
        })
      );
    }

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }
    console.log('getWatchlistDetail: ', data);

    return data;
  }
);

export const getWatchlistNewsInit = createAsyncThunk(
  'watchlist/newsInit',
  async (
    { filter = defaultPostsFilter, pagination = defaultPostsProps.pagination },
    { rejectWithValue, getState, dispatch }
  ) => {
    newsController && newsController.abort();
    newsController = new AbortController();

    console.time('getWatchlistNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/news`,
      {
        watchlist_id: getState().watchlist.currentSelectedWatchlist,
        page: pagination.page,
        sector: getState().watchlist.sectors,
        limit: pagination.limit,
        stock_code: getState().watchlist.stockCodes,
      },
      { signal: newsController.signal }
    );

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    console.log('getWatchlistNews: ', {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    });

    console.timeEnd('getWatchlistNews');

    return {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const getWatchlistNews = createAsyncThunk(
  'watchlist/news',
  async (
    { filter = defaultPostsFilter, pagination = defaultPostsProps.pagination },
    { rejectWithValue, getState, dispatch }
  ) => {
    newsController && newsController.abort();
    newsController = new AbortController();

    console.time('getWatchlistNews');

    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/news`,
      {
        watchlist_id: getState().watchlist.detail.data._id,
        page: pagination.page,
        sector: getState().watchlist.sectors,
        limit: pagination.limit,
        stock_code: getState().watchlist.stockCodes,
      },
      { signal: newsController.signal }
    );

    if (getState().watchlist.isFirstTimeFetchWatchlistNews) {
      dispatch(clearPosts());
    }

    const data = response.data;

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    console.log('getWatchlistNews: ', {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    });

    console.timeEnd('getWatchlistNews');

    return {
      data: data.data,
      filter,
      pagination: {
        ...pagination,
        isNext: data.data.length >= pagination.limit,
      },
    };
  }
);

export const createWatchlist = createAsyncThunk(
  'watchlist/create',
  async (
    { watchlist_name, stocks },
    { rejectWithValue, dispatch, getState }
  ) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/create`,
      {
        watchlist_name,
        stocks,
      }
    );

    const data = response.data;

    if (data) {
      dispatch(
        handleSelectWatchlist({
          _id: data.data._id,
          name: data.data.name,
        })
      );
    }

    if (!data || data.status === false) {
      if (data.error_code === 160) {
        if (getState().uiHandler.language === 'vi') {
          toast.error(
            'Bạn không thể tạo nhiều hơn 10 danh sách theo dõi!',
            settingsError
          );
        } else {
          toast.error(
            'You cannot create more than 10 watchlists!',
            settingsError
          );
        }
      } else {
        toast.error(data.message, settingsError);
      }

      return rejectWithValue(data);
    }

    const watchlist_id = data.data._id;

    dispatch(getWatchlists());
    dispatch(getWatchlistDetail({ watchlist_id }));

    if (getState().uiHandler.language === 'vi') {
      toast.success('Tạo danh mục theo dõi thành công!', settingsSuccess);
    } else {
      toast(data.message, settingsSuccess);
    }

    return data;
  }
);

export const editWatchlist = createAsyncThunk(
  'watchlist/edit',
  async (
    { watchlist_id, watchlist_name, stocks },
    { rejectWithValue, dispatch, getState }
  ) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/edit`,
      {
        watchlist_id,
        watchlist_name,
        stocks,
      }
    );

    const data = response.data;

    if (!data || data.status === false) {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    dispatch(getWatchlists());
    dispatch(getWatchlistDetail({ watchlist_id }));

    if (getState().uiHandler.language === 'vi') {
      toast.success('Cập nhật danh mục theo dõi thành công!', settingsSuccess);
    } else {
      toast(data.message, settingsSuccess);
    }

    return data;
  }
);

export const addStockToWatchlists = createAsyncThunk(
  'watchlist/addStockToWatchlists',
  async ({ watchListId, stocks }, { rejectWithValue, dispatch, getState }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/edit`,
      { watchlist_id: watchListId, stocks: [stocks] }
    );

    const data = await response.data;

    if (!data || data.status === false) {
      toast.error(data.message, settingsError);

      return rejectWithValue(data.message);
    }

    if (data.status) {
      if (getState().uiHandler.language === 'vi') {
        toast.success(
          'Cập nhật danh mục theo dõi thành công!',
          settingsSuccess
        );
      } else {
        toast(data.message, settingsSuccess);
      }

      dispatch(getWatchlists());

      dispatch(
        getWatchlistDetail({
          watchlist_id: getState().watchlist.detail.data._id,
        })
      );

      dispatch(
        getWatchlistDetail({
          watchlist_id: getState().watchlist.newWatchlistId,
        })
      );

      return data;
    }
  }
);

export const removeWatchlist = createAsyncThunk(
  'watchlist/remove',
  async ({ watchlist_id }, { rejectWithValue, getState }) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/watchlist/remove`,
      {
        watchlist_id,
      }
    );

    const data = response.data;

    if (!data || data.status === false) {
      toast.error(data.message, settingsError);

      return rejectWithValue(data);
    }

    if (getState().uiHandler.language === 'vi') {
      toast.success('Xoá danh mục theo dõi thành công!', settingsSuccess);
    } else {
      toast(data.message, settingsSuccess);
    }

    return data;
  }
);

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    clearPosts: (state) => {
      state.posts.data = [];
      state.posts.pagination = defaultPostsPagination;
    },

    handleResetState: (state) => {
      state.isSuccessCreate = false;
      state.isSuccesAddToWatchlist = false;
    },

    handleSelectWatchlist: (state, { payload }) => {
      state.currentSelectedWatchlist = payload._id;
      state.currentSelectedWatchlistName = payload.name;
    },
    resetOnWatchlistNewsModal: (state) => {
      state.sectors = [];
    },

    handleResetNewWatchlist: (state) => {
      state.newWatchlistId = '';
    },

    handleSelectSectorWatchlistNews: (state, { payload }) => {
      state.sectors = payload.sectors;
      state.stockCodes = payload.stockCodes;
    },

    handleSkeletonWatchlistNews: (state, { payload }) => {
      state.isFirstTimeFetchWatchlistNews = true;
    },
  },
  extraReducers: {
    // ------------------- getWatchlists ------------------- //
    [getWatchlists.pending]: (state) => {
      state.list.loading = true;
    },
    [getWatchlists.fulfilled]: (state, { payload }) => {
      state.list = {
        loading: false,
        data: payload.data,
      };
    },
    [getWatchlists.rejected]: (state) => {
      state.list = {
        loading: false,
        data: [],
      };
    },

    // ------------------- getWatchlistDetail ------------------- //
    [getWatchlistDetail.pending]: (state) => {
      state.detail.loading = true;
      state.posts = {
        ...defaultPostsProps,
        loading: true,
      };
    },
    [getWatchlistDetail.fulfilled]: (state, { payload }) => {
      state.detail = {
        loading: false,
        data: payload.data,
      };
    },
    [getWatchlistDetail.rejected]: (state) => {
      state.detail = {
        loading: false,
        data: null,
      };
    },

    // ------------------- getWatchlistNews ------------------- //
    [getWatchlistNewsInit.pending]: (state) => {
      state.posts.loading = true;
      state.isLoadingSkeletonWatchlistNews = true;
    },
    [getWatchlistNewsInit.fulfilled]: (state, { payload }) => {
      state.posts = {
        data: payload.data,
        pagination: payload.pagination,
        filter: payload.filter,
        loading: false,
      };
      state.isLoadingSkeletonWatchlistNews = false;
      state.isFirstTimeFetchWatchlistNews = false;
    },
    [getWatchlistNewsInit.rejected]: (state) => {
      state.posts.loading = false;
      // state.posts = defaultPostsProps;
      state.isLoadingSkeletonWatchlistNews = false;
      state.isFirstTimeFetchWatchlistNews = false;
    },

    [getWatchlistNews.pending]: (state) => {
      state.posts.loading = true;
    },
    [getWatchlistNews.fulfilled]: (state, { payload }) => {
      state.posts = {
        data: [...state.posts.data, ...payload.data],
        pagination: payload.pagination,
        filter: payload.filter,
        loading: false,
      };
      state.postsOnMainScreen = payload.data;
    },
    [getWatchlistNews.rejected]: (state) => {
      state.posts.loading = false;
      state.isFirstTimeFetchWatchlistNews = false;
    },

    // ------------------- createWatchlist ------------------- //
    [createWatchlist.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [createWatchlist.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.isSuccessCreate = true;
      state.errorMessage = '';
      state.newWatchlistId = payload.data._id;
    },
    [createWatchlist.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },

    // ------------------- editWatchlist ------------------- //
    [editWatchlist.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [editWatchlist.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [editWatchlist.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },

    // ------------------- removeWatchlist ------------------- //
    [removeWatchlist.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.errorMessage = '';
    },
    [removeWatchlist.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = '';
    },
    [removeWatchlist.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload.message;
    },
  },
});

export const loadingSelector = (state) => state.watchlist.loading;
export const getListLoadingSelector = (state) => state.watchlist.listLoading;

export const errorSelector = (state) => state.watchlist.error;
export const errorMessageSelector = (state) => state.watchlist.errorMessage;

export const watchlistsSelector = (state) => state.watchlist.list;
export const watchlistDetailSelector = (state) => state.watchlist.detail;
export const watchlistPostsSelector = (state) => state.watchlist.posts;

export const {
  clearPosts,
  handleResetState,
  handleResetNewWatchlist,
  handleSelectSectorWatchlistNews,
  handleSkeletonWatchlistNews,
  resetOnWatchlistNewsModal,
  handleSelectWatchlist,
} = watchlistSlice.actions;

export default watchlistSlice.reducer;

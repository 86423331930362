import styled from 'styled-components';

export const SettingScreenContainer = styled.div`
  label {
    color: var(--white-color);
  }

  .ant-tabs-tab {
    padding: 0 2rem;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs > .ant-tabs-nav {
    height: 6rem;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    border-bottom: 1px solid var(--border-color);
  }

  .ant-divider {
    border-color: var(--border-color);

    @media only screen and (max-width: 35.9375em) {
      display: none;
    }
  }

  .ant-tabs.ant-tabs-top {
    margin-top: -2rem;
  }

  .ant-tabs-content-holder {
    padding: 0;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    margin: 0;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--light-blue-color-2) !important;
    background-color: transparent !important;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-tab > div {
    border: none;
    color: var(--white-color);
  }

  .anticon {
    color: var(--border-color);
  }
  .ant-input {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);
    width: 36.8rem;
    color: var(--white-color);
    padding: 4px 11px 4px 1px;
    border-radius: 0;

    @media only screen and (max-width: 35.9375em) {
      width: 100%;
    }
  }

  .ant-input-focused,
  .ant-input:focus {
    border-color: var(--light-blue-color-2);
    box-shadow: 0 0 0 2px transparent;
    border-right-width: 1px !important;
    outline: 0;
  }

  .ant-picker {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);
    width: 36.8rem;
    color: var(--white-color);
    padding-inline: 0;

    input {
      color: var(--white-color);
    }

    @media only screen and (max-width: 35.9375em) {
      width: 100%;
    }
  }

  .ant-picker:focus,
  .ant-picker-focused {
    box-shadow: unset !important;
    border-right-width: 0 !important;
  }

  .ant-select {
    width: 36.8rem;
    @media only screen and (max-width: 35.9375em) {
      width: 100%;
    }
  }
  .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid var(--border-color) !important;
    background-color: transparent !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: 0 0 0 2px transparent;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: var(--light-blue-color-2);
    box-shadow: 0 0 0 2px transparent;
    border-right-width: 1px !important;
    outline: 0;
  }

  .ant-input-affix-wrapper {
    padding: 4px 11px 4px 1px;
    border-radius: 0;
    width: 36.8rem;
    @media only screen and (max-width: 35.9375em) {
      width: 100%;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }
`;

export const SettingWrapper = styled.div`
  padding: 2rem 1.6rem;
  position: relative;

  .anticon-edit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
  }

  .ant-form {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
  }
  .ant-form-item {
    margin: 1.2rem 0;
  }
  .ant-row {
    display: flex;
    justify-content: left;
  }
  .ant-col-8 {
    flex: none;
    width: 25rem;
    text-align: left;
  }
`;

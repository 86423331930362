import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { REACT_APP_SERVICE_CODE, REACT_APP_AUTH_URL } = process.env;

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
    }
  });

  return (
    <Route {...restOfProps} render={(props) => <Component {...props} />} />
  );
}

export default ProtectedRoute;

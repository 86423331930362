const PlusIconPortfolioSelector = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 6L12 18"
          stroke="#8E8E93"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M18 12L6 12"
          stroke="#8E8E93"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default PlusIconPortfolioSelector;

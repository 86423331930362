/* -------------------------------------------------------------------------- */
/*                               PORTFOLIO CRUD                               */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { settingsError, settingsSuccess } from 'utils';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  portfolioList: [],
  portfolioDetail: {},
  loading: false,
  isSuccessGet: false,
  isFailed: false,
};

const { REACT_APP_URL } = process.env;

export const getPortfolioList = createAsyncThunk(
  'portfolioList/getPortfolioList',

  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/list`
      );

      const { data } = await response;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPortfolioDetail = createAsyncThunk(
  'portfolioList/getPortfolioDetail',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/detail`,
        {
          portfolio_id: portfolioId,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPortfolio = createAsyncThunk(
  'portfolioList/createPortfolio',

  async (
    {
      portfolioName,
      type,
      userName,
      password,
      pinCode,
      fund,
      stockCompanyName,
      riskLevel,
      blackList,
      tradingType,
      subAccount,
      verificationMethod,
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/create`,
        {
          name: portfolioName,
          type: type,
          account: {
            broker: stockCompanyName,
            type: 'normal',
            number: '1',
            trading: userName,
            pass: password,
            pin: pinCode,
          },
          fund: fund,
          fund_type: 'buy',
          risk_level: riskLevel,
          black_list: blackList,
          trading_type: tradingType,
          sub_account: subAccount,
          verifycation_method: verificationMethod,
        }
      );

      const data = await response.data;

      if (!data.status) {
        if (data.error_code === 100) {
          if (getState().uiHandler.language === 'vi') {
            toast.error('Giá trị quỹ không hợp lệ!', settingsError);
          } else {
            toast.error('Fund value is not valid!', settingsError);
          }
        } else if (data.error_code === 189) {
          if (getState().uiHandler.language === 'vi') {
            toast.error(
              'Mỗi người dùng không thể tạo nhiều hơn 3 danh mục đầu tư giao dịch tự động demo!',
              settingsError
            );
          } else {
            toast.error(
              'Each user cannot create more than 3 demo auto-trading portfolios!',
              settingsError
            );
          }
        } else {
          toast.error(data.message, settingsError);
        }

        return rejectWithValue(data.message);
      }

      if (data.status) {
        if (getState().uiHandler.language === 'vi') {
          toast.success('Thêm danh mục đầu tư thành công!', settingsSuccess);
        } else {
          toast(data.message, settingsSuccess);
        }

        dispatch(getPortfolioList());
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  'portfolioList/updatePortfolio',

  async (
    {
      portfolioId,
      portfolioName,
      userName,
      password,
      pinCode,
      fund,
      fundType,
      type,
      stockCompanyName,
      riskLevel,
      blackList,
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    const response = await axiosInstance.post(
      `${REACT_APP_URL}/api/portfolio/edit`,
      {
        portfolio_id: portfolioId,
        // _datx_port_id: '5fcdbdb802c4f8052267ebe5',
        display_name: portfolioName,
        type: type,
        account: {
          broker: stockCompanyName,
          type: 'normal',
          number: '1',
          trading: userName,
          pass: password,
          pin: pinCode,
        },
        fund: fund, // 10000000 - 100000000
        fund_type: fundType,
        risk_level: riskLevel,
        black_list: blackList,
      }
    );

    try {
      const data = await response.data;

      if (data.status) {
        if (getState().uiHandler.language === 'vi') {
          toast.success(
            'Cập nhật danh mục đầu tư thành công!',
            settingsSuccess
          );
        } else {
          toast(data.message, settingsSuccess);
        }

        dispatch(getPortfolioList());
      }

      if (data.status === false) {
        toast.error(data.message, settingsError);

        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePortfolioDemo = createAsyncThunk(
  'portfolioList/updatePortfolio',

  async (
    { portfolioId, portfolioName, riskLevel, fund, fundType, type },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/edit`,
        {
          portfolio_id: portfolioId,
          display_name: portfolioName,
          risk_level: riskLevel,
          type: type,
          fund: fund,
          fund_type: fundType,
        }
      );

      const data = await response.data;

      if (data.status) {
        if (getState().uiHandler.language === 'vi') {
          toast.success(
            'Cập nhật danh mục đầu tư thành công!',
            settingsSuccess
          );
        } else {
          toast(data.message, settingsSuccess);
        }

        dispatch(getPortfolioList());
      }

      if (data.status) {
        toast.error(data.message, settingsError);

        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removePortfolio = createAsyncThunk(
  'portfolioList/removePortfolio',

  async ({ portfolioId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/remove`,
        {
          portfolio_id: portfolioId,
        }
      );

      const data = await response.data;

      if (data.status) {
        if (getState().uiHandler.language === 'vi') {
          toast.success('Xóa danh mục đầu tư thành công!', settingsSuccess);
        } else {
          toast(data.message, settingsSuccess);
        }

        dispatch(getPortfolioList());
      }

      if (data.status) {
        toast.error(data.message, settingsError);

        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const portfolioListSlice = createSlice({
  name: 'portfolioList',
  initialState,
  reducers: {
    resetPortfolioState: (state) => {
      state.isSuccessGet = false;
      state.isSuccessRemove = false;
      state.isSuccess = false;
      state.isFailed = false;
      state.isSuccessUpdate = false;
    },

    resetFailureCreatePortfolioState: (state) => {
      state.isFailed = false;
    },

    handleSelectPortfolio: (state, portfolioId) => {
      state.portfolioId = portfolioId.payload;
      state.isSelected = true;
    },

    handleSelectPortfolioName: (state, { payload }) => {
      state.portfolioNameRedux = payload;
      state.isSelected = true;
    },

    handleResetPortfolioDetail: (state) => {
      state.portfolioDetail = {};
    },
  },
  extraReducers: {
    [getPortfolioList.pending]: (state) => {
      state.loading = true;
      state.isSuccessGet = false;
    },
    [getPortfolioList.fulfilled]: (state, { payload }) => {
      // let filteredPayload = payload?.data?.filter(
      //   (el) => el.status !== "deleted"
      // );

      state.loading = false;
      state.portfolioList = payload?.data;

      state.isSuccessGet = true;
      state.portfolioDefault = payload?.data[0];
      state.portfolioLast = payload?.data[payload?.data.length - 1];
    },
    [getPortfolioList.rejected]: (state) => {
      state.loading = false;
      state.isSuccessGet = false;
    },

    [getPortfolioDetail.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioDetail = payload?.data;
    },
    [getPortfolioDetail.rejected]: (state) => {
      state.loading = false;
    },

    [createPortfolio.pending]: (state) => {
      state.loading = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [createPortfolio.rejected]: (state) => {
      state.loading = false;
      state.isFailed = true;
    },

    [removePortfolio.pending]: (state) => {
      state.loading = true;
      state.isSuccess = false;
    },
    [removePortfolio.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccessRemove = true;
    },
    [removePortfolio.rejected]: (state) => {
      state.loading = false;
      state.isSuccess = false;
      state.isFailed = true;
    },

    [updatePortfolio.pending]: (state) => {
      state.loading = true;
    },

    [updatePortfolio.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccessUpdate = true;
    },
    [updatePortfolio.rejected]: (state) => {
      state.loading = false;
      state.isFailed = true;
    },

    [updatePortfolioDemo.pending]: (state) => {
      state.loading = true;
    },

    [updatePortfolioDemo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = true;
      state.isSuccessUpdate = true;
    },
    [updatePortfolioDemo.rejected]: (state) => {
      state.loading = false;
      state.isFailed = true;
    },
  },
});

export const getPortfolioListSelector = (state) =>
  state.portfolioList.portfolioList;

export const portfolioDetailSelector = (state) =>
  state.portfolioList.portfolioDetail;

export const {
  resetPortfolioState,
  handleSelectPortfolio,
  handleResetPortfolioDetail,
  handleSelectPortfolioName,
} = portfolioListSlice.actions;

export const portfolioListReducer = portfolioListSlice.reducer;

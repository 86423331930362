import { StyledModal } from './styled';
import CloseIcon from 'assets/icons/CloseIcon';

const Modal = ({ ...props }) => {
  return (
    <StyledModal
      {...props}
      destroyOnClose={true}
      maskClosable={true}
      zIndex={999}
      closeIcon={<CloseIcon size={24} />}
    />
  );
};

export default Modal;

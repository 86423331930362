import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Chart, ChartCanvas, ZoomButtons } from 'react-stockcharts';
import { Label } from 'react-stockcharts/lib/annotation';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';
import { fitWidth } from 'react-stockcharts/lib/helper';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { AreaSeries, LineSeries } from 'react-stockcharts/lib/series';
import { HoverTooltip } from 'react-stockcharts/lib/tooltip';
import {
  createVerticalLinearGradient,
  hexToRGBA,
  last,
} from 'react-stockcharts/lib/utils';

class LineAndScatterChartGrid extends React.Component {
  constructor(props) {
    super(props);
    this.saveNode = this.saveNode.bind(this);
    this.resetYDomain = this.resetYDomain.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.chartHeight = props.chartHeight;
  }

  state = {
    suffix: '',
  };

  componentWillMount() {
    this.setState({
      suffix: 1,
    });
  }

  saveNode(node) {
    this.node = node;
  }

  resetYDomain() {
    this.node.resetYDomain();
  }

  handleReset() {
    this.setState({
      suffix: this.state.suffix + 1,
    });
  }

  render() {
    const {
      type,
      data: initialData,
      width,
      ratio,
      interpolation,
      chartHeight,
      tickStroke,
      gradientColor3,
      gradientColor4,
    } = this.props;

    const canvasGradientDATX = createVerticalLinearGradient([
      { stop: 0, color: hexToRGBA(gradientColor3, 0) },
      { stop: 0.4, color: hexToRGBA(gradientColor3, 0.4) },
      { stop: 1, color: hexToRGBA(gradientColor3, 0.8) },
    ]);

    const canvasGradientVNIndex = createVerticalLinearGradient([
      { stop: 0, color: hexToRGBA(gradientColor4, 0) },
      { stop: 1, color: hexToRGBA(gradientColor4, 0.8) },
    ]);

    const { gridProps, seriesType } = this.props;
    const margin = { left: 0, right: 10, top: 15, bottom: 25 };

    const gridHeight = chartHeight - margin.top - margin.bottom;
    const gridWidth = width - margin.left - margin.right;

    const showGrid = true;
    const yGrid = showGrid ? { innerTickSize: -1 * gridWidth } : {};
    const xGrid = showGrid ? { innerTickSize: -1 * gridHeight } : {};

    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      (d) => {
        if (!d) {
          return new Date();
        }
        return d.date;
      }
    );

    const { data, xScale, xAccessor, displayXAccessor } =
      xScaleProvider(initialData);

    if (!data) {
      return null;
    }
    if (data.length === 0) {
      return null;
    }

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    const [yAxisLabelX, yAxisLabelY] = [
      width - margin.right - 32,
      (chartHeight - margin.top - margin.bottom) / 2,
    ];

    const Series = seriesType === 'line' ? LineSeries : AreaSeries;

    const numberFormat = format('.2f');
    const dateFormat = timeFormat('%Y-%m-%d');

    function tooltipContent(ys) {
      return ({ currentItem, xAccessor }) => {
        return {
          x: dateFormat(xAccessor(currentItem)),
          y: [
            {
              label: 'DAT Index',
              value: currentItem.profit && numberFormat(currentItem.profit),
            },

            {
              label: 'VN Index',
              value: currentItem.hsxIndex && numberFormat(currentItem.hsxIndex),
            },
          ],
        };
      };
    }

    return (
      <ChartCanvas
        ref={this.saveNode}
        height={chartHeight}
        ratio={ratio}
        width={width - 20}
        margin={{ left: 0, right: 30, top: 10, bottom: 30 }}
        type={type}
        zoomEvent={false}
        panEvent={true}
        seriesName={`MSFT_${this.state.suffix}`}
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart id={1} yExtents={(d) => [d.profit, d.hsxIndex]}>
          <linearGradient id="MyGradient" x1="0" y1="100%" x2="0" y2="0%">
            <stop offset="0%" stopColor="#46a9de" stopOpacity={0.2} />
            <stop offset="70%" stopColor="#46a9de" stopOpacity={0.4} />
            <stop offset="100%" stopColor="#002a3f" stopOpacity={0.8} />
          </linearGradient>
          <XAxis
            axisAt="bottom"
            orient="bottom"
            {...gridProps}
            {...xGrid}
            strokeWidth={0.3}
            opacity={0.1}
            stroke="#ffffff"
            tickStroke={tickStroke}
          />
          <YAxis
            axisAt="right"
            orient="right"
            ticks={5}
            {...gridProps}
            {...yGrid}
            strokeWidth={0.3}
            opacity={0.1}
            stroke="#ffffff"
            tickStroke={tickStroke}
          />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat('%Y-%m-%d')}
          />
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format('.2f')}
          />

          <Label
            x={yAxisLabelX}
            y={yAxisLabelY}
            rotate={90}
            fontSize={12}
            text=""
            fill="#FFFFFF"
          />

          <Series
            yAccessor={(d) => d.profit}
            interpolation={interpolation}
            stroke={gradientColor3}
            strokeWidth={2}
            canvasGradient={canvasGradientDATX}
          />
          <Series
            yAccessor={(d) => d.hsxIndex}
            interpolation={interpolation}
            fill={gradientColor4}
            stroke={gradientColor4}
            strokeWidth={2}
            canvasGradient={canvasGradientVNIndex}
          />
          <ZoomButtons
            onReset={this.handleReset}
            style={{ cursor: 'pointer' }}
          />

          <HoverTooltip
            yAccessor=""
            tooltipContent={tooltipContent([
              {
                label: `test`,
                value: '',
              },
              {
                label: `test2`,
                value: '',
              },
            ])}
            fontSize={15}
          />
        </Chart>

        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

LineAndScatterChartGrid.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
};

LineAndScatterChartGrid.defaultProps = {
  type: 'hybrid',
};
LineAndScatterChartGrid = fitWidth(LineAndScatterChartGrid);

export default withTranslation('dashboardScreen')(LineAndScatterChartGrid);

import styled from 'styled-components';

export const SiteHeaderContainer = styled.div`
  height: 6rem;
  background: var(--site-header-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  /* grid-area: 1/1/2/3; */
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;

  margin-left: ${(props) => (props.expandSidebar ? '17rem' : '3.2rem')};

  @media only screen and (max-width: 81.1875em) {
    margin-left: 2.2rem;
  }
`;

export const StyledDATXIndexData = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (max-width: 25em) {
    font-size: 12px;
    line-height: 18px;
  }

  > div:nth-child(2) {
    display: flex;

    @media only screen and (max-width: 25em) {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 25em) {
    > div > div:nth-child(2) {
      display: none;
    }
  }
`;

export const SidebarMobileButton = styled.button`
  @media only screen and (min-width: 81.25em) {
    display: none;
  }
`;

export const StyledLogoImage = styled.div`
  display: flex;

  @media only screen and (max-width: 81.1875em) {
    display: none;
  }
`;

export const StyledBetaText = styled.span`
  padding-left: 1rem;
  color: var(--white-color);
  font-weight: 700;
  margin-bottom: 0.3rem;
`;

export const StyledDrawerComponent = styled.div`
  @media only screen and (min-width: 81.25em) {
    display: none;
  }
`;

export const StyledNotifyIcon = styled.div`
  display: flex;
  padding-left: 2rem;
`;

export const StyledContainer = styled.div`
  display: flex;
`;

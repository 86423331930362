import axiosInstance from './axiosInstance';

const { REACT_APP_COPY_TRADE_URL, REACT_APP_URL } = process.env;

export const getCopiers = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/copiers?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getAIBrokerCopiers = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/my-subscribers?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getFollowers = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/followers?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getNAV = async (copierSubAccountId, params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/subscriber/${copierSubAccountId}/investment-performance?${queryString}`
    )
    .then((res) => res.data.data);
};

export const sendMessage = async (input) => {
  return await axiosInstance
    .post('/api/v2/holding-ratio', input)
    .then((res) => {
      return res.data;
    });
};
export const getProducts = async (page, limit) => {
  let queryString = ``;
  if (page) queryString += `&page=${page}`;
  if (limit) queryString += `&limit=${limit}`;
  return await axiosInstance
    .get(`/api/sale/v1/product/list?type=follow_leader${queryString}`)
    .then((res) => res.data);
};

export const getProductDetail = async (id) => {
  return await axiosInstance
    .get(`/api/sale/v1/product/${id}`)
    .then((res) => res);
};

export const updateProductPackages = async ({ id, data }) => {
  return await axiosInstance
    .post(`/api/sale/v1/product/follow-leader-package/${id}`, data)
    .then((res) => res);
};

export const getTradingContext = async () => {
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/leader-sync-context`
    )
    .then((res) => res.data.data);
};

export const getTradingIndayList = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/in-day-order/list?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getTradingRootList = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/root-order/list?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getTradingNewCopierList = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/new-copier-orders/list?${queryString}`
    )
    .then((res) => res.data.data);
};

export const getIssuedRootOrder = async (id) => {
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/root-order/${id}/issued-order/list`
    )
    .then((res) => res.data.data);
};

export const tradingOrderNewCopier = async (data) => {
  return await axiosInstance.post(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/new-copier-orders`,
    data
  );
};

export const cancelOrderNewCopier = async (id) => {
  return await axiosInstance.put(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/${id}/cancel-new-copier-order`
  );
};

export const editOrderNewCopier = async ({ id, data }) => {
  return await axiosInstance
    .put(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/${id}/modify-new-copier-orders`,
      data
    )
    .then((res) => res);
};

export const tradingOrderLeader = async (data) => {
  return await axiosInstance.post(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/new-leader-order`,
    data
  );
};

export const getTradingLeaderList = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  return await axiosInstance
    .get(
      `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/new-leader-order/config?${queryString}`
    )
    .then((res) => res.data.data);
};

export const verifyTradingLeader = async (data) => {
  return await axiosInstance
    .post(`${REACT_APP_URL}/api/auth/verify-password`, data)
    .then((res) => res.data);
};

export const cancelOrderRoot = async (id) => {
  return await axiosInstance.put(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/root-order/${id}/cancel`
  );
};

export const cancelOrderInDay = async (id) => {
  return await axiosInstance.put(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/in-day-order/${id}/cancel`
  );
};

export const editOrderRoot = async ({ id, data }) => {
  return await axiosInstance.put(
    `${REACT_APP_COPY_TRADE_URL}/api/leader/ai-broker/order/${id}/modify-new-leader-order`,
    data
  );
};

export const registerAutotrade = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_COPY_TRADE_URL}/api/autotrade-subscriber/register-autotrade`,
    data
  );
};

export const getBSCLink = async () => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/oauth/bsc/get-authorize-url`
    )
    .then((res) => res.data);
};

export const getBSCData = async (code) => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/oauth/bsc/callback?code=${code}`
    )
    .then((res) => res.data);
};

export const getInvestmentKind = async ({ source, subAccountId }) => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/subscriber/${subAccountId}/investment-kind?type=STOCK&source=${source}`
    )
    .then((res) => res.data);
};

export const getPortfolioDetail = async ({ source, subAccountId }) => {
  return await axiosInstance
    .get(
      `${process.env.REACT_APP_COPY_TRADE_URL}/api/subscriber/${subAccountId}/portfolio-detail?source=${source}`
    )
    .then((res) => res.data);
};

import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-row.active,
  .ant-table-row.active:hover {
    td {
      background: #454d75 !important;
    }
  }

  .ant-table-cell {
    padding: 6px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    vertical-align: middle;
  }

  .ant-typography {
    font-size: 13px;
    color: #fff;
  }

  .ant-table-thead > tr > th {
    color: var(--white-color) !important;
    border-bottom: 1px solid var(--border-color) !important;

    &.no-border {
      border-bottom: none !important;
    }

    &.border-right {
      border-right: 1px solid var(--border-color) !important;
    }
  }

  .ant-table-row-expand-icon-cell {
    padding: 0 !important;
  }

  .buy {
    color: rgb(58, 214, 5);
  }

  .sell {
    color: #ff0000;
  }

  .sell-price {
    color: rgb(124, 124, 124);
  }

  .keep-space {
    width: 14px;
  }
`;

import styled from 'styled-components';

export const HeaderStyled = styled.div`
  padding: 1.2rem 1.6rem;
  height: 4.4rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--section-header-color);
`;

export const HeaderTitleStyled = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  @media screen and (max-width: 74.99em) {
    display: none;
  }
`;

export const HeaderMobileTitleStyled = styled.button`
  display: flex;
  gap: 1rem;
  align-items: center;

  & > span {
  }
  @media screen and (min-width: 75em) {
    display: none;
  }
`;

export const BodyStyled = styled.div`
  display: grid;
  grid-template-columns: 30.4rem 1fr 31.8rem;
  padding: 2rem;
  background-color: var(--primary-color);
  height: calc(100% - 4.4rem);

  /* &::-webkit-scrollbar {
    display: ${(props) => (props.loading === 'true' ? 'none' : 'block')};
  } */

  @media only screen and (max-width: 75em) {
    grid-template-columns: 1fr 28.8rem;
  }

  @media only screen and (max-width: 56.25em) {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - 6.4rem);
  }
`;

//sidebar left style

export const SidebarLeftDesktopWrapper = styled.div`
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 1rem;
    left: 0;
    height: 1.6rem;
    background-color: var(--primary-color);
  }
  @media only screen and (max-width: 75em) {
    display: none;
  }
`;

export const SiderbarLeftWrapper = styled.div`
  background-color: var(--primary-color);
  overflow: auto;
  max-height: calc(90vh - 4rem);
  max-width: 30.2rem;

  @media only screen and (max-width: 56.25em) {
    max-width: 46.2rem;
    min-width: 36rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SiderbarLeftHeader = styled.div`
  display: flex;
  align-items: 'center';
  gap: 1rem;
  margin-bottom: 1.6rem;

  > div {
    width: 90%;
  }

  span {
    font-weight: 700;
    font-size: 15px;
    color: var(--white-color);
  }
`;

export const SiderbarLeftMobileStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 75em) {
    display: none;
  }
`;

export const StyledCheckboxGroupContainer = styled.div`
  .ant-checkbox-inner {
    background-color: transparent;
    border: 1px solid var(--light-blue-color-2);
    border-radius: 50%;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid var(--light-blue-color-2);
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--light-blue-color-2);
    border-radius: 50%;
  }

  .ant-checkbox-checked::after {
    border: 1px solid transparent;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--light-blue-color-2);
  }
`;

export const MenuStyled = styled.div`
  padding-left: 1.6rem;
  margin-left: 6px;
  border-left: 1px solid var(--border-color);
  margin-top: 1.6rem;

  a:last-child div {
    border-bottom: 1px solid transparent;
  }
`;

export const MenuItemLeftStyled = styled.button`
  font-size: 13px;
  line-height: 20px;
  margin-top: 1.6rem;
  display: block;
  color: ${(props) =>
    props.active ? 'var(--light-blue-color-2)' : 'var(--white-color)'};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  text-align: left;

  &:hover {
    color: var(--light-blue-color-2);
    font-weight: 700;
  }
`;

export const MenuLeftStyled = styled.div`
  padding-left: 1.6rem;
  margin-left: 6px;
  border-left: 1px solid var(--border-color);

  .ant-checkbox-group div:first-child {
    button {
      margin-top: 0;
    }
  }
`;

export const MenuItemStyled = styled.button`
  font-size: 13px;
  line-height: 20px;
  padding: 1.2rem 0;
  display: block;
  color: ${(props) =>
    props.active ? 'var(--light-blue-color-2)' : 'var(--white-color)'};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  text-align: left;

  &:hover {
    color: var(--light-blue-color-2);
    font-weight: 700;
  }
`;

export const StyledImagePost = styled.div`
  img {
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    margin-right: 1rem;
  }
`;

export const StyledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
`;

export const StyledCheckBoxContainer = styled.div`
  label {
    width: 100%;
  }
`;

export const StockCodeStyled = styled.button`
  padding: 0.4rem 0.8rem;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  color: ${(props) =>
    props.active ? 'var(--light-blue-color-2)' : 'var(--white-color)'};
  border: 1px solid
    ${(props) =>
      props.active ? 'var(--light-blue-color-2)' : 'var(--border-color)'};

  &:hover {
    color: ${(props) =>
      props.active ? 'var(--light-blue-color-2)' : 'var(--white-color)'};
    opacity: 1;
    border: 1px solid
      ${(props) =>
        props.active ? 'var(--light-blue-color-2)' : 'var(--white-color)'};
  }
`;

//sidebar right style

export const SiderbarRightStyled = styled.div`
  padding-left: 1rem;
`;

export const SiderbarRightWrapper = styled.div`
  @media only screen and (max-width: 56.25em) {
    order: -1;
  }
`;

//main style

export const MainWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  flex: 1;
  overflow-y: auto;

  @media only screen and (max-width: 75em) {
    width: 100%;
  }

  @media only screen and (max-width: 56.25em) {
    height: calc(100% - 8rem);
    overflow-y: unset;
    position: unset;
  }

  @media only screen and (min-width: 1921px) and (min-height: 1081px) {
    width: 66%;
    max-width: unset;
  }
`;

export const PostListStyled = styled.div`
  height: 100%;
  overflow-y: auto;

  /* &::-webkit-scrollbar {
    display: ${(props) => (props.loading === 'true' ? 'none' : 'block')};
  } */

  @media only screen and (max-width: 56.25em) {
    overflow-y: unset;
    margin-top: 2rem;
  }

  .infinite-scroll-loader-wrapper {
    text-align: center;
    padding: 3rem 0 1rem;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

//pagination style
export const PaginationWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  padding: 1.6rem 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 8rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-pagination-item,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: transparent;
    border-color: var(--border-color);
    color: var(--white-color);

    &:hover,
    &.ant-pagination-item-active {
      color: var(--light-blue-color-2);
      border-color: var(--light-blue-color-2);
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    &.ant-pagination-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .ant-pagination-item-link,
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-link-icon,
    .ant-pagination-item-link-icon {
      position: relative;
      bottom: 4px;
      color: var(--light-blue-color-2);
    }

    .ant-pagination-item-ellipsis {
      color: var(--white-color);
    }
  }
`;

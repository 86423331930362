import { getNewsInit, setFirstMount } from 'features/news/newsSlice';
import {
  getDataAllocationByIndex,
  getDataAllocationBySector,
  getDataAllocationByStock,
} from 'features/portfolio/allocationChartSlice';
import {
  getPortfolioList,
  handleSelectPortfolio,
  handleSelectPortfolioName,
  resetPortfolioState,
} from 'features/portfolio/getPortfolioListSlice';
import { getPortfolioSummary } from 'features/portfolio/getPortfolioSummarySlice';
import {
  getBrokerList,
  getStockCompanyList,
} from 'features/portfolio/getStockCompanyListSlice';
import { getPortfolioOrderPlacements } from 'features/portfolio/orderPlacementsSlice';
import { getPortfolioExchangeFund } from 'features/portfolio/portfolioExchangeFundSlice';
import { getPortfolioNews } from 'features/portfolio/portfolioNewsSlice';
import {
  getCurrentPortfolioTransactions,
  handleSelectDateTime,
} from 'features/portfolio/transactionsSlice';
import { getDATXIndex } from 'features/ratings/DATXIndexSlice';
import { getListSectors } from 'features/ratings/listSectorsSlice';
import { getListStocks } from 'features/ratings/listStocksSlice';
import { getUserInfo } from 'features/user/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/css/variable.css';
import Announcement from './components/GlobalComponents/Announcement';
import NotFound from './components/GlobalComponents/NotFound';
import ProtectedRoute from './components/GlobalComponents/ProtectedRoute';
import SidebarDesktop from './components/GlobalComponents/Sidebar/desktop';
import SiteHeader from './components/GlobalComponents/SiteHeader';
import {
  closeAnnouncementSelector,
  expandPortfolioSelector,
  expandSidebarSelector,
  handleChangeColorDarkTheme,
  handleChangeColorLightTheme,
  handleCollapsePortfolio,
  handleExpandPortfolio,
} from './features/uiHandler/uiHandlerSlice';
import {
  getWatchlistNewsInit,
  getWatchlists,
  watchlistDetailSelector,
} from './features/watchlist/watchlistSlice';
import './responsive.css';
import SettingScreen from './screens/SettingScreen';
import SupportScreen from './screens/SupportScreen';
import WatchlistScreen from './screens/WatchlistScreen';
import RecommendationsPerformanceScreen from './screens/RecommendationPerformance';
import RecommendationsBotScreen from 'screens/RecommendationsBotScreen';
import ManageCustomerFilesScreen from 'screens/ManageCustomerFilesScreen';
import { MainWrapper, ScreenWrapper, StyledGlobalWrapper } from './styles';
import RecommendationPerformanceReport from 'screens/RecommendationPerformanceReport';
import DerivativeRecommendationsScreen from 'screens/DerivativeRecommendationsScreen';
import CopyTrade from 'screens/CopyTrade';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Trading from 'screens/Trading';
import { getStocks } from 'features/stocks/stockSlice';

const queryClient = new QueryClient();

const ignoreAnnouncement = [
  '/bot-recommendations',
  '/recommendation-performance',
  '/derivative-recommendation',
  '/',
];

const Main = ({ children }) => {
  const location = useLocation();
  const closeAnnouncement = useSelector(closeAnnouncementSelector);

  return (
    <MainWrapper>
      {!closeAnnouncement &&
        !ignoreAnnouncement.includes(location.pathname) && <Announcement />}

      <ScreenWrapper
        closeAnnouncement={
          ignoreAnnouncement.includes(location.pathname)
            ? true
            : closeAnnouncement
        }
      >
        {children}
      </ScreenWrapper>
    </MainWrapper>
  );
};

const PrivateApp = (props) => {
  const dispatch = useDispatch();
  const expandSidebar = useSelector(expandSidebarSelector);
  const expandPortfolio = useSelector(expandPortfolioSelector);
  const { userInfo, isGetInfoSuccess } = useSelector((state) => state.user);
  const { pagination } = useSelector((state) => state.news);
  const [tempt, setTempt] = useState(0);
  const { data: currentWatchlist } = useSelector(watchlistDetailSelector);

  const { isSuccessGet, portfolioDefault } = useSelector(
    (state) => state.portfolioList
  );

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isGetInfoSuccess) {
      if (userInfo?.theme === 'dark') {
        dispatch(handleChangeColorDarkTheme());
      } else {
        dispatch(handleChangeColorLightTheme());
      }
    }
  }, [dispatch, isGetInfoSuccess, userInfo?.theme]);

  // if (!isAuthedLocal || isAuthedLocal === "false") {
  //   window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
  // }

  useEffect(() => {
    dispatch(
      getListSectors({
        timeDistance: 86400,
      })
    );

    dispatch(getDATXIndex());

    dispatch(
      getListStocks({
        timeDistance: 86400,
        page: 1,
      })
    );

    dispatch(getStocks());
  }, [dispatch]);

  useEffect(() => {
    document.title = 'DATX AI Broker';
    localStorage.setItem('isAuthed', true);
    dispatch(getWatchlists());
    // dispatch(getWatchlistNewsInit({ watchlist_id: currentSelectedWatchlist }));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentWatchlist) return;

    dispatch(getWatchlistNewsInit({}));
    //eslint-disable-next-line
  }, [currentWatchlist]);

  useEffect(() => {
    if (!userInfo) {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', 'dark');
    } else {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', userInfo.theme);
    }
  }, [userInfo]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('expandTablePortfolio'))) {
      dispatch(handleExpandPortfolio());
    } else {
      dispatch(handleCollapsePortfolio());
    }
  }, [dispatch]);

  const { posts } = useSelector((state) => state.news);

  useEffect(() => {
    if (Array.isArray(posts) && posts.length > 0) return;

    dispatch(setFirstMount(true));
    dispatch(
      getNewsInit({
        pagination: {
          ...pagination,
          page: 1,
        },
      })
    );

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer = window.location.href.includes('callback?') ? 1000 : 0;

    setTimeout(
      () => {
        if (window.location.href.includes('callback?')) {
          dispatch(getUserInfo());
        }
      },

      timer
    );
  }, [dispatch, tempt]);

  useEffect(() => {
    setTempt(tempt + 1);
    dispatch(getPortfolioList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tempt === 1 && isSuccessGet) {
      dispatch(handleSelectPortfolioName(portfolioDefault?.display_name));

      dispatch(handleSelectPortfolio(portfolioDefault?._id));

      dispatch(resetPortfolioState());

      setTempt(10);

      dispatch(
        getPortfolioSummary({
          portfolioId: portfolioDefault?._id,
        })
      );

      dispatch(getStockCompanyList());

      dispatch(getBrokerList());

      dispatch(
        getDataAllocationByStock({
          portfolioId: portfolioDefault?._id,
        })
      );

      dispatch(
        getDataAllocationBySector({
          portfolioId: portfolioDefault?._id,
        })
      );

      dispatch(
        getDataAllocationByIndex({
          portfolioId: portfolioDefault?._id,
        })
      );

      dispatch(
        getPortfolioOrderPlacements({
          portfolioId: portfolioDefault?._id,
        })
      );

      dispatch(handleSelectDateTime({ startingTime: null, endingTime: null }));

      dispatch(
        getCurrentPortfolioTransactions({
          portfolioId: portfolioDefault?._id,
          page: 1,
        })
      );

      dispatch(
        getPortfolioExchangeFund({ portfolioId: portfolioDefault?._id })
      );

      dispatch(
        getPortfolioNews({
          page: 1,
        })
      );

      dispatch(resetPortfolioState());
    }
  }, [
    dispatch,
    isSuccessGet,
    portfolioDefault?._id,
    portfolioDefault?.display_name,
    tempt,
  ]);

  useEffect(() => {
    if (
      localStorage.getItem('newsSreenSectorList') === null ||
      !localStorage.getItem('newsSreenSectorList')
    ) {
      localStorage.setItem('newsSreenSectorList', '');
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledGlobalWrapper
        expandSidebar={expandSidebar}
        expandPortfolio={expandPortfolio}
      >
        <SiteHeader />
        <SidebarDesktop />
        <Main>
          <ProtectedRoute {...props} component={props.component} />
        </Main>

        <ToastContainer />
      </StyledGlobalWrapper>
    </QueryClientProvider>
  );
};

function App() {
  return (
    <Router>
      <Switch>
        <PrivateApp path="/" component={RecommendationsBotScreen} exact />
        <PrivateApp
          path="/recommendation-performance"
          component={RecommendationsPerformanceScreen}
        />
        <PrivateApp
          path="/derivative-recommendation"
          component={DerivativeRecommendationsScreen}
        />
        <PrivateApp
          path="/report-recommendation-performance"
          component={RecommendationPerformanceReport}
        />
        <PrivateApp path="/watchlist" component={WatchlistScreen} />
        <PrivateApp
          path="/manage-customer-files"
          component={ManageCustomerFilesScreen}
        />
        <PrivateApp path="/settings" component={SettingScreen} />
        <PrivateApp path="/support" component={SupportScreen} />
        <PrivateApp path="/copyTrade" component={CopyTrade} />
        <PrivateApp path="/trading" component={Trading} />

        <Route path="/health-check">OK</Route>
        <PrivateApp path="" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;

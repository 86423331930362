import styled from 'styled-components';

export const StyledTransactionsWrapper = styled.div`
  > div:last-child {
    border-bottom: none;
  }
`;

export const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--white-color);
  padding: 2rem 0;
  border-bottom: 1px solid var(--border-color);

  .ant-slider-disabled {
    cursor: pointer;
    width: 20rem;
  }

  .ant-slider-disabled .ant-slider-rail,
  .ant-slider-disabled .ant-slider-track {
    background-color: var(--light-blue-color-2) !important;
  }

  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-handle {
    border-color: var(--always-white-color) !important;
    box-shadow: 0px 0px 8px rgba(43, 157, 232, 0.5);
  }

  .ant-slider-disabled .ant-slider-dot,
  .ant-slider-disabled .ant-slider-handle {
    background-color: var(--light-blue-color-2) !important;
  }

  .ant-slider-with-marks {
    margin-bottom: 0;
  }
`;

export const StyledSlideWrapper = styled.div`
  display: flex;
  width: 28rem;
  position: relative;
  justify-content: space-between;
`;

export const StyledCurrentValue = styled.div`
  position: absolute;
  top: -1.2rem;
  color: var(--light-blue-color-2);
`;

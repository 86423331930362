import Decimal from 'decimal.js';
import numeral from 'numeral';

export const formatVietnameseToEn = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const fixPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const removeAccents = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
};

export const settingsSuccess = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
  type: 'success',
  progressStyle: {
    color: 'var(--light-green-color)',
  },
};

export const settingsError = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
  type: 'error',
};

export const formatPrice = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  return Number(value).toFixed(2);
};

export const removeNumericFormat = (number) => {
  if (!number) {
    return 0;
  }
  const convertedNumber = Number(number.toString().replace(/[,]/g, ''));
  return convertedNumber;
};

export const getFirstAndLastWords = (str) => {
  const words = str?.includes('_') ? str?.split('_') : str?.split(' ');
  const firstWord = words[0]?.charAt(0)?.toUpperCase();
  const lastWord = words[words.length - 1]?.charAt(0)?.toUpperCase();
  return firstWord + lastWord;
};

export const formatCurrency = (number, decimals = 4, isShorty = true, isWithoutComma = false) => {
  const tail = new Array(decimals).fill('0').join('');

  const num = new Decimal(Number(number));
  const numFixed = num.toFixed(decimals, Decimal.ROUND_DOWN);
  if (String(num) === 'NaN') return numFixed;

  const numFormat = numeral(Number(numFixed));

  if (isNaN(Number(numFixed)) || num.isNaN()) return numeral(0).format(`0,0.${tail}`);

  if (isShorty) return numFormat.format(`0,0.[${tail}]`);
  if (isWithoutComma) return numFormat.format(`0.${tail}`);

  return numFormat.format(`0,0.${tail}`);
};

export const convertRemToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize.replace('px', ''));
};

export const formatNumberToBillions = (numberConvert) => {
  const formatString = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ'];
  if (!numberConvert) return '0';
  const number = Math.round(numberConvert);
  const unitIndex = Math.floor((String(number).length - 1) / 3);
  const getDigit = number % Math.pow(1000, unitIndex);
  const formattedNumber = (number / Math.pow(1000, unitIndex)).toFixed(getDigit ? 1 : 0);
  return `${formattedNumber} ${formatString[unitIndex]}`;
};

export const renderCopierStatus = (status) => {
  switch (status) {
    case 'CONNECTED':
    case 'ACTIVE':
      return (
        <div className="rounded-md whitespace-nowrap bg-custom-green500/10 text-custom-green500 flex items-center justify-center px-2">
          Đang sao chép
        </div>
      );
    case 'INACTIVE':
      return (
        <div className="rounded-md whitespace-nowrap bg-[#26273B]/80 text-custom-darkBlue200 flex items-center justify-center px-2">
          Dừng sao chép
        </div>
      );
    default:
      return 'Không có trạng thái';
  }
};

export const renderFollowerStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return (
        <div className="max-w-[148px] rounded-md whitespace-nowrap bg-custom-green500/10 text-custom-green500 flex items-center justify-center px-2">
          Đang theo dõi
        </div>
      );
    case 'INACTIVE':
      return (
        <div className="max-w-[148px] rounded-md whitespace-nowrap bg-[#26273B]/80 text-custom-darkBlue200 flex items-center justify-center px-2">
          Đã hủy
        </div>
      );
    default:
      return 'Không có trạng thái';
  }
};

export const renderProductStatus = (status) => {
  switch (status) {
    case 'active':
      return (
        <div className="rounded-md whitespace-nowrap bg-custom-green500/10 text-custom-green500 flex items-center justify-center px-2">
          Đang bán
        </div>
      );
    case 'pending':
      return (
        <div className="rounded-md whitespace-nowrap bg-[#26273B]/80 text-custom-darkBlue200 flex items-center justify-center px-2">
          Ngừng bán
        </div>
      );
    default:
      return 'Không có trạng thái';
  }
};

export const convertToInternationalCurrency = (labelValue, text) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? formatCurrency(Math.abs(Number(labelValue)) / 1.0e9, 2) + `${text?.ty ? text?.ty : ' tỷ'}`
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? formatCurrency(Math.abs(Number(labelValue)) / 1.0e6, 2) + `${text?.tr ? text?.tr : ' tr'}`
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? formatCurrency(Math.abs(Number(labelValue)) / 1.0e3) + 'k'
    : formatCurrency(Math.abs(Number(labelValue)));
};

export const comparePriceClass = (value, object) => {
  const data = Number(value);

  if (data === object?.refPrice) {
    return '!text-yellow';
  } else if (data === object?.ceiling) {
    return '!text-pink';
  } else if (data === object?.floor) {
    return '!text-cyan';
  } else if (data > object?.refPrice) {
    return '!text-green';
  } else if (data < object?.refPrice) {
    return '!text-red';
  } else {
    return 'text-white';
  }
};

export const priceClass = (key) => {
  switch (key) {
    case 'ceiling':
      return '!text-pink';
    case 'floor':
      return '!text-cyan';
    case 'refPrice':
      return '!text-yellow';
    case 'datxIndex':
      return '!text-blue';
    default:
      return 'text-white';
  }
};

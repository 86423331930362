import { Form, Input } from 'antd';
import { postChangePassword } from 'features/setting/settingSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SettingWrapper } from 'screens/SettingScreen/styles';
import PrimaryButton from 'components/GlobalComponents/GlobalButton/PrimaryButton';
import SecondaryButton from 'components/GlobalComponents/GlobalButton/SecondaryButton';
import * as modal from 'components/GlobalComponents/NotificationModal/NotiModal';
import {
  ButtonGroup,
  PasswordContainer,
  StyledPasswordContent,
  StyledPasswordHeader,
} from './styles';

const Password = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormChange, setIsFormChange] = useState(false);

  const { t } = useTranslation('settingsScreen');

  const { REACT_APP_SERVICE_CODE, REACT_APP_AUTH_URL } = process.env;

  const handleSubmit = async (values) => {
    try {
      await dispatch(postChangePassword(values)).unwrap();
      form.resetFields();
      modal.success({
        message: `${t('notification.updateEmail.reloginInfo2')}`,
        onOk: () => {
          window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
        },
        onCancel: () => {
          window.location.href = `${REACT_APP_AUTH_URL}/?code=${REACT_APP_SERVICE_CODE}`;
        },
      });
    } catch (error) {
      console.log('postChangePassword: ', { error });
    }
  };

  const handeCancel = () => {
    form.resetFields();
    setIsFormChange(false);
  };

  return (
    <PasswordContainer>
      <SettingWrapper>
        <StyledPasswordHeader>{t('tab2.subTitle')}</StyledPasswordHeader>

        <StyledPasswordContent>
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFieldsChange={setIsFormChange.bind(this, true)}
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t('tab2.form.field1.label')}
              name="old_password"
              rules={[
                {
                  required: true,
                  message: `${t('tab2.form.field1.validateHelper1')}`,
                },
                {
                  min: 8,
                  message: `${t('tab2.form.field1.validateHelper2')}`,
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>

            <Form.Item
              label={t('tab2.form.field2.label')}
              name="new_password"
              rules={[
                {
                  required: true,
                  message: `${t('tab2.form.field2.validateHelper1')}`,
                },
                {
                  min: 8,
                  message: `${t('tab2.form.field2.validateHelper2')}`,
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>

            <Form.Item
              label={t('tab2.form.field3.label')}
              name="re_new_password"
              rules={[
                {
                  required: true,
                  message: `${t('tab2.form.field3.validateHelper1')}`,
                },
                {
                  min: 8,
                  message: `${t('tab2.form.field3.validateHelper2')}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`${t('tab2.form.field3.validateHelper3')}`)
                    );
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>

            <ButtonGroup>
              {isFormChange && (
                <SecondaryButton
                  size="large"
                  onHandleClick={handeCancel}
                  textBtn={t('textBtn2')}
                  large
                />
              )}
              <PrimaryButton
                large
                disabled={!isFormChange}
                textBtn={t('textBtn1')}
                htmlType="submit"
              />
            </ButtonGroup>
          </Form>
        </StyledPasswordContent>
      </SettingWrapper>
    </PasswordContainer>
  );
};

export default Password;

import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  .ant-menu-item {
    width: unset;
    color: #6b697a;
    margin: 0 12px;
    padding: 0 12px !important;

    &:hover {
      color: var(--primary-blue);
    }

    &::after {
      border-right: 0;
    }
  }

  .ant-menu-item-selected {
    font-weight: bold;
    color: var(--primary-blue);
    background-color: var(--background-color) !important;
    border-radius: 8px;

    &::after {
      border-right: 0;
    }
  }
`;

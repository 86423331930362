const BotIcon = ({ width, height }) => {
  return (
    <svg
      width={width || 22}
      height={height || 21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bot-icon"
    >
      <path
        d="M10 3.055C14.5 3.552 18 7.367 18 12V21H0V12C0 7.367 3.5 3.552 8 3.055V0H10V3.055ZM9 17C10.3261 17 11.5979 16.4732 12.5355 15.5355C13.4732 14.5979 14 13.3261 14 12C14 10.6739 13.4732 9.40215 12.5355 8.46447C11.5979 7.52678 10.3261 7 9 7C7.67392 7 6.40215 7.52678 5.46447 8.46447C4.52678 9.40215 4 10.6739 4 12C4 13.3261 4.52678 14.5979 5.46447 15.5355C6.40215 16.4732 7.67392 17 9 17ZM9 15C8.20435 15 7.44129 14.6839 6.87868 14.1213C6.31607 13.5587 6 12.7956 6 12C6 11.2044 6.31607 10.4413 6.87868 9.87868C7.44129 9.31607 8.20435 9 9 9C9.79565 9 10.5587 9.31607 11.1213 9.87868C11.6839 10.4413 12 11.2044 12 12C12 12.7956 11.6839 13.5587 11.1213 14.1213C10.5587 14.6839 9.79565 15 9 15ZM9 13C9.26522 13 9.51957 12.8946 9.70711 12.7071C9.89464 12.5196 10 12.2652 10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13Z"
        fill="#fff"
      />
    </svg>
  );
};

export default BotIcon;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Form } from 'antd';
import clsx from 'clsx';
import { StyledModal } from 'components/GlobalComponents/Modal/styled';
import React from 'react';
import { formatCurrency } from 'utils';

const CopierOrderConfirmation = ({
  confirmation,
  setConfirmation,
  onConfirm,
}) => {
  const [form] = Form.useForm();
  const { type, data } = confirmation;

  const onReset = () => {
    setConfirmation({
      type: null,
      data: null,
    });
    form.resetFields();
  };

  return (
    <span>
      <StyledModal
        open={type}
        destroyOnClose={true}
        maskClosable={true}
        className="!w-[580px]"
        title={
          type === 'CANCEL' ? (
            <span>
              <WarningAmberIcon className="!w-10 !h-10 text-red mr-3" />
              <span className="text-xl">Hủy lệnh</span>
            </span>
          ) : (
            <span>
              <WarningAmberIcon className="!w-10 !h-10 text-yellow mr-3" />
              <span className="text-xl">Sửa lệnh</span>
            </span>
          )
        }
        footer={null}
        onCancel={() => setConfirmation((one) => ({ ...one, type: null }))}
        onOk={() => null}
      >
        <Form
          initialValues={{
            price: null,
            proportion: null,
          }}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={() => {}}
          onFinish={() => onConfirm(confirmation)}
        >
          {type === 'CANCEL' && (
            <div className="flex flex-col -mt-5">
              <p className="font-bold">
                Bạn muốn hủy lệnh <span className="text-green"> Mua </span>{' '}
                {data.symbol}
              </p>
              <div className="w-3/5 flex flex-col">
                <div className="flex justify-between">
                  <p>Tỷ trọng đặt:</p>
                  <p>{(data.proportion * 100).toFixed(2) + '%'}</p>
                </div>
                <div className="flex justify-between">
                  <p>KL đặt:</p>
                  <p>{formatCurrency(data.followerQuantity)}</p>
                </div>
                <div className="flex justify-between">
                  <p>Giá đặt:</p>
                  <p>
                    {data?.price > 0 ? (
                      data.price.toFixed(2)
                    ) : (
                      <span>
                        {data?.minPrice.toFixed(2)}
                        <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
                        {data?.maxPrice.toFixed(2)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}

          {type === 'EDIT' && (
            <div className="flex flex-col -mt-5">
              <p className="font-bold">
                Xác nhận sửa lệnh <span className="text-green"> Mua </span>{' '}
                {data.symbol}
              </p>
              <div className="w-3/5 flex flex-col">
                <div className="flex justify-between">
                  <p>Với giá:</p>
                  <p>
                    {data?.price > 0 ? (
                      data.price.toFixed(2)
                    ) : (
                      <span>
                        {data?.minPrice.toFixed(2)}
                        <ArrowForwardIcon className="!w-5 !h-5 mx-1" />
                        {data?.maxPrice.toFixed(2)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end py-4">
            <Button
              className={clsx(
                '!h-16 !px-16 !rounded-lg ',
                type === 'CANCEL'
                  ? '!text-red-500 !border-red-500 hover:!text-red-700 hover:!border-red-500'
                  : '!text-custom-blue500 !border-custom-blue500 hover:!text-blue-500 hover:!border-blue-500'
              )}
              onClick={onReset}
            >
              Từ chối
            </Button>
            <Button
              type="primary"
              className={clsx(
                'ml-6 !h-16 !px-16 !rounded-lg border-0',
                type === 'CANCEL'
                  ? '!bg-red-500 hover:!bg-red-500'
                  : '!bg-custom-blue500 border-0 hover:!bg-blue-500'
              )}
              onClick={() => {
                form.submit();
                // setConfirmation({
                //   type: null,
                //   data: null,
                // });
              }}
            >
              Xác nhận
            </Button>
          </div>
        </Form>
      </StyledModal>
    </span>
  );
};

export default CopierOrderConfirmation;

import { useState } from 'react';
import { FormContainer, StyledInput } from './styled';
import { Button, Typography, Select, notification } from 'antd';
import { Form } from 'antd';
import {
  getConnectList,
  getSubAccounts,
  linkLeaderStockAccount,
} from 'utils/stock-management';

import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@tanstack/react-query';

const Connected = ({ handleCancel, company, onSuccess, typeModal }) => {
  const [account, setAccount] = useState('');
  const [subAccounts, setSubAccounts] = useState([]);
  const [subAccount, setSubAccount] = useState('');
  const [subAccountType, setSubAccountType] = useState('');

  const { data: listStockConnected, isLoading: isLoadingAccount } = useQuery({
    queryKey: ['stock-account-connect-list'],
    queryFn: () => getConnectList(),
  });
  console.log('listStockConnected :', listStockConnected);

  const { mutateAsync: querySub, isLoading: isLoadingSubAccount } = useMutation(
    getSubAccounts,
    {
      onSuccess: ({ data }) => {
        console.log('subAccounts', data);
        setSubAccounts(
          data?.results.map((i) => ({
            value: i.id,
            label: `${i.id} - ${i.name || ''}`,
            type: i.type,
          }))
        );
      },
    }
  );

  const { mutateAsync: linkAccount, isLoading: isLoadingLink } = useMutation(
    linkLeaderStockAccount
  );

  const onChangeCompany = (value) => {
    setAccount(value);
    querySub(value);
  };

  const onSelectAccount = async () => {
    try {
      const response = await linkAccount({
        source: company,
        subAccountId: subAccount,
      });
      console.log('link Stock Account res', response);

      notification.success({
        key: 'connectStock',
        duration: 5,
        message: 'Kết nối TKCK',
        description: `Bạn đã kết nối tài khoản thành công!`,
        placement: 'topRight',
        closeIcon: <CloseOutlined style={{ color: '#fff' }} />,
        className: 'success-noti-copytrade',
      });
      if (onSuccess) {
        onSuccess({
          source: company,
          username: account,
          subAccountId: subAccount,
          accountType: subAccountType,
        });
      }
    } catch (error) {
      notification.warning({
        key: 'copierFailed',
        duration: 5,
        message: 'Kết nối tài khoản lỗi!',
        description: error?.response?.data?.message,
        placement: 'topRight',
        closeIcon: <CloseOutlined style={{ color: '#000' }} />,
      });
    }
  };

  return (
    <FormContainer>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
      >
        <div className="flex flex-col gap-6">
          <Typography.Text className="font-bold text-xl">
            Kết nối tài khoản chứng khoán
          </Typography.Text>
          <Typography.Text level={3} className="font-normal text-gray-400">
            Kết nối tài khoản chứng khoán của bạn để sử dụng tính năng đặt lệnh
            {typeModal} tới từ XWealth.
          </Typography.Text>

          <StyledInput>
            <Typography.Text className="text-[14px] mb-2">
              Chọn số tài khoản
            </Typography.Text>
            <Select
              value={account}
              style={{
                width: '100%',
              }}
              options={listStockConnected
                ?.filter(
                  (i) => i.source === company && i.status === 'CONNECTED'
                )
                .map((i) => ({ value: i.username, label: i.username }))}
              onChange={(e) => onChangeCompany(String(e))}
              loading={isLoadingAccount}
            />
          </StyledInput>

          <StyledInput>
            <Typography.Text className="text-[14px] mb-2">
              Chọn tiểu khoản
            </Typography.Text>
            <Select
              value={subAccount}
              style={{ width: '100%' }}
              options={subAccounts}
              onChange={(e, opt) => {
                setSubAccount(String(opt.value));
                setSubAccountType(String(opt.type));
              }}
              disabled={!account}
              loading={isLoadingSubAccount}
            />
          </StyledInput>

          <div className="flex justify-end gap-2 mb-2">
            <Button
              ghost
              type="primary"
              className="!m-0 !mr-2 !border-none !bg-custom-darkBlue100 px-8 rounded-lg"
              onClick={handleCancel}
            >
              <span className="!text-custom-blue500">Hủy</span>
            </Button>
            <Button
              disabled={!account || !subAccount}
              type="primary"
              loading={isLoadingLink}
              className="!m-0 !text-white !bg-custom-blue500 px-8 !rounded-lg !border-none"
              onClick={onSelectAccount}
            >
              Kết nối
            </Button>
          </div>
        </div>
      </Form>
    </FormContainer>
  );
};

export default Connected;

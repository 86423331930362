import styled from 'styled-components';

export const NewsContainerStyled = styled.div`
  background-color: var(--primary-color);
  border-radius: 4px;
  overflow: auto;
  position: relative;
`;

export const NewsHeaderStyled = styled.div`
  background-color: var(--section-header-color);
  padding: 1.2rem 1.6rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NewsTitleStyled = styled.div`
  font-weight: bold;

  @media only screen and (max-width: 56.24em) {
    display: none;
  }
`;

export const NewsTitleMobileStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > span {
    font-weight: bold;
    font-size: 1.5rem;
  }

  @media only screen and (min-width: 56.25em) {
    display: none;
  }
`;

export const SearchWrapper = styled.div`
  @media screen and (max-width: 37.5em) {
    display: none;
  }
`;

export const SearchMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  padding: 0 1.6rem;
  background-color: var(--section-header-color);

  & > button {
    display: flex;
  }

  @media screen and (min-width: 37.5em) {
    display: none;
  }
`;

export const SearchInputStyled = styled.input`
  background-color: transparent;
  width: 15rem;
  border: none;
  outline: none;
  font-size: 13px;
  padding-left: 8px;
  caret-color: var(--light-blue-color-2);
`;

export const PostListStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  max-height: calc(100% - 4.4rem);
  overflow-y: auto;
  min-height: 200px;
  &::-webkit-scrollbar {
    display: ${(props) => (props.loading === 'true' ? 'none' : 'block')};
  }
  @media only screen and (max-width: 75em) {
    grid-template-columns: 1fr;
    display: block;
  }

  & .infinite-scroll-loader-wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const EmptyWrapper = styled.div`
  position: absolute;
  top: 4.4rem;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  padding: 40px 0 20px;
`;

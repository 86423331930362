/* -------------------------------------------------------------------------- */
/*                              DATX RATING SLICE                             */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  consider: {
    buy: '',
    sell: '',
  },

  pointData: [],
};

const { REACT_APP_URL } = process.env;

export const getDATXRating = createAsyncThunk(
  'DATXRating/getDATXRating',

  async ({ stockCode, timeDistance }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stock-detail/datx-rating`,
        {
          stock_code: stockCode,
          time_distance: timeDistance,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const DATXRatingSlice = createSlice({
  name: 'DATXRating',
  initialState,
  reducers: {},
  extraReducers: {
    [getDATXRating.pending]: (state) => {
      state.loadingChart = true;
    },
    [getDATXRating.fulfilled]: (state, { payload }) => {
      state.loadingChart = false;
      state.dataDATXRating = payload?.data.data_rating;
      state.consider.buy = payload?.data?.data_chart?.consider.buy;
      state.consider.sell = payload?.data?.data_chart?.consider.sell;
      state.pointData = payload?.data?.data_chart?.point_data;
    },
    [getDATXRating.rejected]: (state) => {
      state.loadingChart = false;
    },
  },
});

export const DATXRatingReducer = DATXRatingSlice.reducer;

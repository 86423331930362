import styled from 'styled-components';

export const StyledDATXPoint = styled.div`
  & > div:last-child {
    border-bottom: none;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  padding: 1.2rem 0;
  color: var(--white-color);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
`;

export const StyledTitle = styled.div`
  color: var(--white-color);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
`;

import Modal from 'components/GlobalComponents/Modal';
import Loader from 'components/GlobalComponents/UIHandler/Loader';
import {
  closeEdit,
  editInfo,
  getLeaders,
  getLeaderConfig,
  editLeader,
  postPresignUrl,
  postUpload,
  upload,
  leadersSelector,
  modalSelector,
  leaderConfigSelector,
  openEdit,
  getLeaderOverview,
} from 'features/copyTrade/copyTradeSlice';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeaderChart from './LeaderChart';
import { LeaderHeader } from './LeaderHeader';
import { LeaderInfo } from './LeaderInfo';
import { StyledInput, StyledTextArea } from './styled';
import { settingsError } from 'utils';
import { toast } from 'react-toastify';
import DropBox from 'components/GlobalComponents/UploadImage/DropBox';
import ShowImage from 'components/GlobalComponents/UploadImage/ShowImage';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'antd';
import StockConnectModal from 'components/StockConnect';
import { LeaderPublish } from './LeaderPublish';

export const LeaderDetail = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(leadersSelector);
  const { type, content } = useSelector(modalSelector);
  const { data: leaderConfig } = useSelector(leaderConfigSelector);
  const [image, setImage] = useState([]);
  const [file, setFile] = useState(null);

  const StockConnectModalRef = useRef(null);

  const handleUpload = useCallback(
    async (file) => {
      try {
        const res = await dispatch(
          postPresignUrl({ filename: file.name })
        ).unwrap();
        dispatch(postUpload({ presignedUrl: res.data.presignedUrl, file }));
        const res2 = await dispatch(
          upload({ id: data[0]?.id, s3Filename: res.data.s3Filename })
        ).unwrap();
        if (res2) {
          setImage([]);
          setFile(null);
        }
      } catch (err) {
        console.log('err :', err);
        // handle error here
      }
    },
    [data, dispatch]
  );

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        setImage([{ id: 0, src: e.target.result }]);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  }, []);

  const onEditByType = useCallback(
    (type) => {
      dispatch(
        openEdit({
          type,
          content: data[0][type] || 0,
        })
      );
    },
    [data, dispatch]
  );

  const onPublishProfile = useCallback(
    (value) => {
      dispatch(
        editLeader({ id: data[0]?.id, body: { isPublicProfile: value } })
      );
    },
    [data, dispatch]
  );

  const onStockConnect = () => {
    StockConnectModalRef?.current?.setModalState({
      openModal: true,
      showOption: true,
    });
  };

  const onLinkLeader = (params) => {
    console.log('onLinkLeader', params);
    dispatch(getLeaderConfig());
    dispatch(getLeaders());
  };

  const contents = useMemo(
    () => ({
      yoe: {
        title: 'Số năm kinh nghiệm',
        warning: `Leader được thay đổi số năm kinh nghiệm ${leaderConfig?.validYoeChangeDate} ngày 1 lần.`,
      },
      academicLevel: {
        title: 'Trình độ học vấn',
        warning: `Trình dộ học vấn chỉ được cập nhật sau ${leaderConfig?.validAcademicLevelChangeDate} ngày.`,
      },
      investmentPhilosophyDescription: {
        title: 'Triết lý đầu tư',
        warning: `Triết lý đầu tư chỉ được cập nhật sau ${leaderConfig?.validInvestmentPhilosophyDescriptionChangeDate} ngày.`,
      },
      investmentKindDescription: {
        title: 'Trường phái đầu tư',
        warning: `Trường phái đầu tư chỉ được cập nhật sau ${leaderConfig?.validInvestmentKindDescriptionChangeDate} ngày.`,
      },
      nickname: {
        title: 'Nickname',
        warning: `Bạn chỉ có thể đổi tên sau ${leaderConfig?.validNicknameChangeDate} ngày kể từ ngày đổi tên gần nhất.`,
      },
      expectProfit: {
        title: 'Lợi nhuận kỳ vọng/năm (%)',
        warning: `Bạn chỉ có thể sửa 1 lần sau khi cập nhật LN kỳ vọng thành công. Thời gian chỉnh sửa cách thời gian cập nhật không quá ${leaderConfig?.validExpectProfitChangeDate} ngày giao dịch.`,
      },
      socials: {
        title: 'Mạng xã hội',
        warning: `Bạn chỉ có thể cập nhật mạng xã hội sau ${leaderConfig?.validSocialUrlChangeDate} ngày`,
      },
      facebookUrl: {
        title: 'Link Facebook',
        warning: '',
      },
      telegramUrl: {
        title: 'Link Telegram',
        warning: '',
      },
      zaloUrl: {
        title: 'Link Zalo',
        warning: '',
      },
      avatar: {
        title: 'Ảnh đại diện',
        warning: '',
      },
    }),
    [leaderConfig]
  );

  useEffect(() => {
    dispatch(getLeaderConfig());
    dispatch(getLeaders());
  }, [dispatch]);

  useEffect(() => {
    data[0] &&
      dispatch(
        getLeaderOverview({
          id: data[0]?.subAccountId,
          source: data[0]?.source,
        })
      );
  }, [data, dispatch]);

  if (loading && !data) return <Loader />;

  return (
    <div className="flex flex-col gap-4">
      <LeaderHeader
        loading={loading}
        detail={data[0]}
        onRename={() => onEditByType('nickname')}
        onProfitChange={() => onEditByType('expectProfit')}
        onAvatarChange={() => onEditByType('avatar')}
        onSocialsChange={(content) =>
          dispatch(
            openEdit({
              type: 'socials',
              content,
            })
          )
        }
      />

      <div className="flex flex-col md:flex-row w-full gap-4">
        <div className="flex flex-col w-full md:max-w-[286px] gap-4">
          {data[0]?.subAccountId && (
            <LeaderPublish
              loading={loading}
              data={data[0]?.isPublicProfile}
              onEdit={onPublishProfile}
            />
          )}

          {!data[0]?.subAccountId && !loading && (
            <Button
              type="primary"
              className="!m-0 px-8 rounded-lg"
              onClick={onStockConnect}
            >
              Kết nối TKCK
            </Button>
          )}

          <LeaderInfo
            onEdit={() => onEditByType('yoe')}
            title={contents.yoe.title}
            subTitle={data[0]?.yoe || 0}
          />
          <LeaderInfo
            className="flex-1"
            onEdit={() => onEditByType('academicLevel')}
            title={contents.academicLevel.title}
            content={data[0]?.academicLevel}
          />
          <LeaderInfo
            className="flex-1"
            onEdit={() => onEditByType('investmentPhilosophyDescription')}
            title={contents.investmentPhilosophyDescription.title}
            content={data[0]?.investmentPhilosophyDescription}
          />
          <LeaderInfo
            className="flex-1"
            onEdit={() => onEditByType('investmentKindDescription')}
            title={contents.investmentKindDescription.title}
            content={data[0]?.investmentKindDescription}
          />
        </div>
        <LeaderChart />
      </div>

      <Modal
        open={Boolean(type)}
        title={`Cập nhật ${contents[type]?.title?.toLowerCase()}`}
        okText="Cập nhật"
        cancelText="Hủy"
        centered
        className="w-screen lg:!w-[600px]"
        onCancel={() => {
          dispatch(closeEdit());
          !isEmpty(image) && setImage([]);
          !isEmpty(file) && setFile(null);
        }}
        onOk={() =>
          type === 'avatar'
            ? handleUpload(file)
            : dispatch(
                editLeader({
                  id: data[0]?.id,
                  body:
                    typeof content === 'object' ? content : { [type]: content },
                })
              )
                .unwrap()
                .catch((err) =>
                  toast.error(contents[type]?.warning, settingsError)
                )
        }
      >
        {(() => {
          switch (type) {
            case 'yoe':
            case 'expectProfit':
            case 'nickname':
              return (
                <StyledInput
                  maxLength={20}
                  placeholder={contents[type]?.title}
                  type={type === 'nickname' ? 'text' : 'number'}
                  value={content}
                  onChange={(e) =>
                    type === 'nickname'
                      ? dispatch(editInfo(e.target.value))
                      : dispatch(editInfo(Number(e.target.value)))
                  }
                />
              );

            case 'socials':
              return Object.entries(content).map(([key, value]) => (
                <div className="mb-3" key={key}>
                  <p className="mb-2 text-custom-gray300">
                    {contents[key]?.title}
                  </p>
                  <StyledInput
                    placeholder={contents[key]?.title}
                    value={value}
                    onChange={(e) =>
                      dispatch(editInfo({ ...content, [key]: e.target.value }))
                    }
                  />
                </div>
              ));

            case 'avatar':
              return (
                <div>
                  {!isEmpty(image) ? (
                    <ShowImage images={image} />
                  ) : (
                    <DropBox onDrop={onDrop} />
                  )}
                </div>
              );

            case 'investmentKindDescription':
              return (
                <StyledTextArea
                  placeholder={contents[type]?.title}
                  maxLength={100}
                  showCount
                  value={content}
                  onChange={(e) => dispatch(editInfo(e.target.value))}
                />
              );

            default:
              return (
                <StyledTextArea
                  placeholder={contents[type]?.title}
                  maxLength={150}
                  showCount
                  value={content}
                  onChange={(e) => dispatch(editInfo(e.target.value))}
                />
              );
          }
        })()}
        {contents[type]?.warning && (
          <p className="pt-4 text-custom-darkBlue200">
            {contents[type]?.warning}
          </p>
        )}
      </Modal>
      <StockConnectModal ref={StockConnectModalRef} onSuccess={onLinkLeader} />
    </div>
  );
};

const BlueSearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_255_1310)">
        <path
          d="M12.0207 11.078L14.876 13.9326L13.9327 14.876L11.078 12.0206C10.0158 12.8721 8.69468 13.3352 7.33334 13.3333C4.02134 13.3333 1.33334 10.6453 1.33334 7.33331C1.33334 4.02131 4.02134 1.33331 7.33334 1.33331C10.6453 1.33331 13.3333 4.02131 13.3333 7.33331C13.3353 8.69465 12.8722 10.0158 12.0207 11.078ZM10.6833 10.5833C11.5294 9.71324 12.0019 8.54693 12 7.33331C12 4.75465 9.91134 2.66665 7.33334 2.66665C4.75468 2.66665 2.66668 4.75465 2.66668 7.33331C2.66668 9.91131 4.75468 12 7.33334 12C8.54696 12.0019 9.71327 11.5294 10.5833 10.6833L10.6833 10.5833Z"
          fill="#3EA4CD"
        />
      </g>
      <defs>
        <clipPath id="clip0_255_1310">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlueSearchIcon;

import React from 'react';

const LogoutIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0C15.7956 0 16.5587 0.316071 17.1213 0.87868C17.6839 1.44129 18 2.20435 18 3V15C18 15.7956 17.6839 16.5587 17.1213 17.1213C16.5587 17.6839 15.7956 18 15 18H11C10.2044 18 9.44129 17.6839 8.87868 17.1213C8.31607 16.5587 8 15.7956 8 15V10H3.414L5.707 12.293C5.88916 12.4816 5.98995 12.7342 5.98767 12.9964C5.9854 13.2586 5.88023 13.5094 5.69482 13.6948C5.50941 13.8802 5.2586 13.9854 4.9964 13.9877C4.7342 13.99 4.4816 13.8892 4.293 13.707L0.292999 9.707C0.105528 9.51947 0.000213623 9.26516 0.000213623 9C0.000213623 8.73484 0.105528 8.48053 0.292999 8.293L4.293 4.293C4.38525 4.19749 4.49559 4.12131 4.6176 4.0689C4.7396 4.01649 4.87082 3.9889 5.0036 3.98775C5.13638 3.9866 5.26806 4.0119 5.39095 4.06218C5.51385 4.11246 5.6255 4.18671 5.71939 4.2806C5.81329 4.3745 5.88754 4.48615 5.93782 4.60905C5.9881 4.73194 6.0134 4.86362 6.01225 4.9964C6.0111 5.12918 5.98351 5.2604 5.9311 5.3824C5.87869 5.50441 5.80251 5.61475 5.707 5.707L3.414 8H8V3C8 2.20435 8.31607 1.44129 8.87868 0.87868C9.44129 0.316071 10.2044 0 11 0H15ZM8 8H13C13.2652 8 13.5196 8.10536 13.7071 8.29289C13.8946 8.48043 14 8.73478 14 9C14 9.26522 13.8946 9.51957 13.7071 9.70711C13.5196 9.89464 13.2652 10 13 10H8V8Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default LogoutIcon;

/* -------------------------------------------------------------------------- */
/*                            GET ORDER PLACEMENTS                            */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  portfolioOrderPlacements: [],
};

const { REACT_APP_URL } = process.env;

export const getPortfolioOrderPlacements = createAsyncThunk(
  'portfolioOrderPlacements/getPortfolioOrderPlacements',

  async ({ portfolioId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/portfolio/orders`,
        {
          portfolio_id: portfolioId,
          time_distance: 86400,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPortfolioOrderPlacementsSlice = createSlice({
  name: 'portfolioOrderPlacements',
  initialState,
  reducers: {},
  extraReducers: {
    /* ------------------ GET DATA ON ALLOCATION CHART BY STOCK ----------------- */

    [getPortfolioOrderPlacements.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioOrderPlacements.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.portfolioOrderPlacements = payload?.data;
    },
    [getPortfolioOrderPlacements.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getPortfolioOrderPlacementsSelector = (state) =>
  state.portfolioOrderPlacements.portfolioOrderPlacements;

export const portfolioOrderPlacementsReducer =
  getPortfolioOrderPlacementsSlice.reducer;

import styled from 'styled-components';

// export const SupportScreenWrapper = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: var(--white-color);
// `;

export const SupportScreenWrapper = styled.div`
  height: 100%;
  color: var(--white-color);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 2rem;
  margin-right: 2rem;

  @media screen and (max-width: 56.25em) {
    grid-template-columns: 1fr;
    height: auto;
    margin-right: 0;
    grid-gap: 16rem;
  }

  > div:first-child {
    @media screen and (max-width: 56.25em) {
      grid-area: 2/1/3/2;
    }
  }
`;

const EyeIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.885742 7C1.59074 3.16 4.95599 0.25 8.99999 0.25C13.044 0.25 16.4085 3.16 17.1142 7C16.4092 10.84 13.044 13.75 8.99999 13.75C4.95599 13.75 1.59149 10.84 0.885742 7ZM8.99999 10.75C9.99455 10.75 10.9484 10.3549 11.6516 9.65165C12.3549 8.94839 12.75 7.99456 12.75 7C12.75 6.00544 12.3549 5.05161 11.6516 4.34835C10.9484 3.64509 9.99455 3.25 8.99999 3.25C8.00543 3.25 7.0516 3.64509 6.34834 4.34835C5.64508 5.05161 5.24999 6.00544 5.24999 7C5.24999 7.99456 5.64508 8.94839 6.34834 9.65165C7.0516 10.3549 8.00543 10.75 8.99999 10.75ZM8.99999 9.25C8.40326 9.25 7.83096 9.01295 7.409 8.59099C6.98704 8.16903 6.74999 7.59674 6.74999 7C6.74999 6.40326 6.98704 5.83097 7.409 5.40901C7.83096 4.98705 8.40326 4.75 8.99999 4.75C9.59673 4.75 10.169 4.98705 10.591 5.40901C11.0129 5.83097 11.25 6.40326 11.25 7C11.25 7.59674 11.0129 8.16903 10.591 8.59099C10.169 9.01295 9.59673 9.25 8.99999 9.25Z"
        fill="#828282"
      />
    </svg>
  );
};

export default EyeIcon;

import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  .ant-input {
    border: 1px solid #363953 !important;
    padding: 12px 16px !important;
    border-radius: 8px !important;
    font-size: 1.4rem !important;
  }
`;

export const StyledInput = styled(Input)`
  border: 1px solid #363953 !important;
  padding: 12px 16px !important;
  border-radius: 8px !important;
  font-size: 1.4rem !important;
`;

import styled from 'styled-components';

export const EditModalContainer = styled.div`
  width: 56.8rem;
  padding: 50px 40px 20px 40px;
  max-width: 100%;

  @media screen and (max-width: 35.9375em) {
    padding: 40px 20px 20px;
  }

  .ant-select-selector .ant-select-selection-search-input {
    border: none !important;
  }

  .ant-input-password input {
    border: none !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    box-shadow: unset;
  }

  .ant-form-item-label > label {
    color: var(--white-color);
    font-size: 15px;
  }
  .ant-form-horizontal .ant-form-item-label {
    text-align: left;
  }
  .anticon {
    color: var(--border-color);
    font-size: 16px;
  }

  .ant-form-item {
    margin: 10px 0;
  }
`;

export const AuthModalContainer = styled.div`
  text-align: center;
  padding: 50px 40px 20px 40px;
  .ant-typography {
    color: var(--white-color);
    font-size: 15px;
  }

  .ant-input-number {
    border-bottom: none !important;
    background-color: transparent;
    width: 32px;
  }

  .ant-input-number-focused {
    border: none;
    box-shadow: unset;
  }

  .ant-input-number-input {
    border-bottom: 1px solid var(--border-color);
    color: var(--white-color);
    font-size: 27px;
    padding: 0;
    text-align: center;
  }

  .ant-input-number-input:focus {
    border-bottom: 1px solid var(--light-blue-color);
  }

  #region_code {
    border: none !important;
  }
`;

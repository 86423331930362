import React from 'react';

const FilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.07994 3.3584H19.9199C20.1083 3.35852 20.2928 3.41182 20.4522 3.51218C20.6115 3.61254 20.7393 3.75587 20.8208 3.92567C20.9023 4.09547 20.9342 4.28483 20.9128 4.47196C20.8915 4.65909 20.8177 4.83637 20.6999 4.9834L14.2199 13.0844C14.0777 13.2617 14.0001 13.4821 13.9999 13.7094V18.8584C13.9999 19.0136 13.9638 19.1668 13.8944 19.3056C13.8249 19.4445 13.7241 19.5653 13.5999 19.6584L11.5999 21.1584C11.4514 21.2698 11.2747 21.3377 11.0897 21.3544C10.9048 21.371 10.7188 21.3359 10.5527 21.2528C10.3866 21.1698 10.2469 21.0421 10.1493 20.8841C10.0517 20.7262 9.99994 20.5441 9.99994 20.3584V13.7084C9.99954 13.4815 9.92196 13.2614 9.77994 13.0844L3.29994 4.9834C3.18223 4.83637 3.10842 4.65909 3.08704 4.47196C3.06565 4.28483 3.09754 4.09547 3.17904 3.92567C3.26055 3.75587 3.38835 3.61254 3.54773 3.51218C3.70711 3.41182 3.8916 3.35852 4.07994 3.3584Z"
        fill="var(--white-color)"
      />
    </svg>
  );
};

export default FilterIcon;

const SortByIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_132_3851)">
        <path
          d="M7.96667 5.3L7.024 6.24267L5.33333 4.552V13.3333H4V4.552L2.31 6.24267L1.36667 5.3L4.66667 2L7.96667 5.3ZM14.6333 10.7L11.3333 14L8.03333 10.7L8.976 9.75733L10.6673 11.448L10.6667 2.66667H12V11.448L13.6907 9.75733L14.6333 10.7Z"
          fill="#3EA4CD"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_3851">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SortByIcon;

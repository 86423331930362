import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const DEFAULT_PAGE_SIZE = 1000000;

let controller;

const initialState = {
  loading: false,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  customerFiles: [],
};

const { REACT_APP_RECOMMENDATION_API_URL } = process.env;

export const getCustomerFiles = createAsyncThunk(
  'recommendation/get-customer-files',
  async ({ page, pageSize }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('getCustomerFiles');

    let url = `${REACT_APP_RECOMMENDATION_API_URL}/file-connections?page=${page}&page_size=${pageSize}`;

    const response = await axiosInstance.get(url, {
      signal: controller.signal,
    });

    const data = response.data;

    console.timeEnd('getCustomerFiles');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data.customersets,
      page,
      pageSize,
    };
  }
);

export const sendRecommendation = createAsyncThunk(
  'recommendation/send-recommendation',
  async ({ params }, { rejectWithValue }) => {
    controller && controller.abort();
    controller = new AbortController();

    console.time('sendRecommendation');

    const response = await axiosInstance.post(
      `${REACT_APP_RECOMMENDATION_API_URL}/holding-ratio`,
      params,
      { signal: controller.signal }
    );

    const data = response.data;

    console.timeEnd('sendRecommendation');

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return data;
  }
);

export const customerFileSlice = createSlice({
  name: 'customerFile',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = true;
      state.page = 1;
      state.pageSize = DEFAULT_PAGE_SIZE;
      state.customerFiles = [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getCustomerFiles.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerFiles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerFiles = payload.data;
      state.page = payload.page;
      state.page_size = payload.page_size;
    },
    [getCustomerFiles.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { reset, setLoading } = customerFileSlice.actions;

export const customerFileReducer = customerFileSlice.reducer;

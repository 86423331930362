/* -------------------------------------------------------------------------- */
/*                              LIST STOCK SLICE                              */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  listStocks: [],
  isSuccessGetListStocks: false,
  defaultStock: '',
  total: 0,
  checkedSectorListStr: '',
};

const { REACT_APP_URL } = process.env;

export const getListStocks = createAsyncThunk(
  'listStocks/getListStocks',

  async (
    { page, sortName, sortRank, sortGradeRank, sortTotalPoints, keyword },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stocks`,
        {
          limit: 10,
          page: page,
          sort: {
            name: sortName,
            rank: sortRank,
            grade_rank: sortGradeRank,
            total_points: sortTotalPoints,
          },
          filter: {
            code: keyword,
          },
          is_paginated: false,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListSearchStock = createAsyncThunk(
  'listStocks/getListSearchStock',

  async (
    { keyword, sortName, sortRank, sortGradeRank, sortTotalPoints },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/rating/stocks`,
        {
          sort: {
            name: sortName,
            rank: sortRank,
            grade_rank: sortGradeRank,
            total_points: sortTotalPoints,
          },
          filter: {
            code: keyword,
          },
          is_paginated: false,
        }
      );

      const data = await response.data;

      if (data.status === false) {
        return rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListStocksSlice = createSlice({
  name: 'listStocks',
  initialState,
  reducers: {
    handleResetState: (state) => {
      state.isSuccessGetListStocks = false;
    },

    handleSelectFilterDropdown: (state, { payload }) => {
      state.checkedSectorListStr = payload;
    },
  },
  extraReducers: {
    [getListStocks.pending]: (state) => {
      state.loading = true;
    },
    [getListStocks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listStocks = payload?.data.stocks;
      state.isSuccessGetListStocks = true;
      state.defaultStock = payload?.data.stocks[0].code;
      state.total = payload?.data.meta.total;
    },
    [getListStocks.rejected]: (state) => {
      state.loading = false;
    },

    [getListSearchStock.pending]: (state) => {
      state.loading = true;
    },

    [getListSearchStock.fulfilled]: (state, { payload }) => {
      state.listStocks = payload?.data.stocks;
      state.isSuccessGetListStocks = true;
      state.total = payload?.data.meta.total;
      state.isGetSearch = true;
      state.loading = false;
    },

    [getListSearchStock.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { handleResetState, handleSelectFilterDropdown } =
  getListStocksSlice.actions;
export const listStocksReducer = getListStocksSlice.reducer;

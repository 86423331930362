import { Drawer, Tooltip } from 'antd';
import ArrowDownPortfolioSelector from 'assets/icons/ArrowDownPortfolioSelector';
import { watchlistDetailSelector } from 'features/watchlist/watchlistSlice';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  WatchlistDrawerContainer,
  WatchlistHeaderStyled,
  WatchlistNameStyled,
} from './styles';
import Watchlist from './Watchlist';

const WatchlistDrawer = ({
  modalVisible,
  setModalVisible,
  setWatchlistEditting,
  watchlistEditting,
  setModalType,
  modalType,
  visible,
  setVisible,
  onCloseDrawer,
  onOpenDrawer,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handleCloseDeleteModal,
  deleteModalVisible,
  handleDeleteWatchlist,
}) => {
  const drawerRef = useRef();
  const { data: currentWatchlist } = useSelector(watchlistDetailSelector);
  const { t } = useTranslation(['watchlistScreen']);

  console.log('visible', visible);

  return (
    <WatchlistDrawerContainer ref={drawerRef}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div onClick={onOpenDrawer} style={{ cursor: 'pointer' }}>
          <WatchlistHeaderStyled>
            <span style={{ fontWeight: 'bold' }}>
              {t('watchlistSelectorSection.header.title')}
            </span>
            <button style={{ marginLeft: '1rem' }}>
              <ArrowDownPortfolioSelector />
            </button>
          </WatchlistHeaderStyled>
        </div>
        <div
          style={{
            display: 'flex',
            height: '4.4rem',
            padding: '1.2rem',
            backgroundColor: 'var(--primary-color)',
          }}
        >
          <Tooltip
            title={currentWatchlist?.name}
            color="var(--light-blue-color)"
          >
            <WatchlistNameStyled
              style={{
                margin: 'auto',
                color: 'var(--light-blue-color-2)',
              }}
            >
              {currentWatchlist?.name}
            </WatchlistNameStyled>
          </Tooltip>
        </div>
      </div>
      <Drawer
        getContainer={() => drawerRef.current}
        width="32rem"
        placement="left"
        onClose={onCloseDrawer}
        visible={visible}
        closable={false}
      >
        <Watchlist
          type="drawer"
          onCloseDrawer={onCloseDrawer}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          deleteModalVisible={deleteModalVisible}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setWatchlistEditting={setWatchlistEditting}
          watchlistEditting={watchlistEditting}
          setModalType={setModalType}
          modalType={modalType}
          visible={visible}
          setVisible={setVisible}
          onOpenDrawer={onOpenDrawer}
          handleDeleteWatchlist={handleDeleteWatchlist}
        />
      </Drawer>
    </WatchlistDrawerContainer>
  );
};

export default WatchlistDrawer;

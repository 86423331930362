const PieChartIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="pie-chart-icon"
    >
      <path
        d="M9 2.05003V13H19.95C19.449 18.053 15.185 22 10 22C4.477 22 0 17.523 0 12C0 6.81503 3.947 2.55103 9 2.05003ZM11 0.54303C16.553 1.02003 20.979 5.44703 21.457 11H11V0.54303Z"
        fill="white"
      />
    </svg>
  );
};

export default PieChartIcon;

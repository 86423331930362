import { createSlice } from '@reduxjs/toolkit';

const expandSidebar = localStorage.getItem('expandSidebar');
const themeLocal = localStorage.getItem('theme');

const initialState = {
  expandSidebar: expandSidebar === 'true' || expandSidebar === null,
  expandPortfolio: false,
  closeAnnouncement: false,
  darkTheme: themeLocal === 'light' ? false : true,
  lineAndScatterChart: {
    tickStroke: themeLocal === 'light' ? '#000000' : '#4E5266',
    gradientColor1: themeLocal === 'light' ? '#F5FBFE' : '#002a3f',
    gradientColor2: themeLocal === 'light' ? '#E2F5FD' : '#27526c',
    gradientColor3: themeLocal === 'light' ? '#7ED4F5' : '#49C2F2',
    gradientColor4: themeLocal === 'light' ? '#7DE5C9' : '#EFD618',
  },

  areaChartWithEdge: {
    tickStroke: themeLocal === 'light' ? '#000000' : '#ffffff',
    gradientColor1: themeLocal === 'light' ? '#ffffff' : '#1f3f44',
    gradientColor2: themeLocal === 'light' ? '#28DB99' : '#1eb17f',
  },
  allocationChart: {
    labelColor: themeLocal === 'light' ? '#000000' : '#ffffff',
  },
  language: '',
  isFirstTimeRender: true,
};

export const uiHandlerSlice = createSlice({
  name: 'uiHandler',
  initialState,
  reducers: {
    handleChangeColorLightTheme: (state) => {
      state.darkTheme = false;
      state.lineAndScatterChart.tickStroke = '#000000';
      state.lineAndScatterChart.gradientColor1 = '#ffffff';
      state.lineAndScatterChart.gradientColor2 = '#B8E7FA';
      state.lineAndScatterChart.gradientColor3 = '#46a9de';
      state.lineAndScatterChart.gradientColor4 = '#1fd8d8';
      state.areaChartWithEdge.tickStroke = '#000000';
      state.areaChartWithEdge.gradientColor1 = '#96EDCD';
      state.areaChartWithEdge.gradientColor2 = '#28DB99';
      state.allocationChart.labelColor = '#000000';
    },

    handleChangeColorDarkTheme: (state) => {
      state.darkTheme = true;
      state.lineAndScatterChart.tickStroke = '#ffffff';
      state.lineAndScatterChart.gradientColor1 = '#002a3f';
      state.lineAndScatterChart.gradientColor2 = '#27526c';
      state.lineAndScatterChart.gradientColor3 = '#49C2F2';
      state.lineAndScatterChart.gradientColor4 = '#EFD618';
      state.areaChartWithEdge.tickStroke = '#ffffff';
      state.areaChartWithEdge.gradientColor1 = '#1f3f44';
      state.areaChartWithEdge.gradientColor2 = '#1eb17f';
      state.allocationChart.labelColor = '#ffffff';
    },

    handleChangeLanguage: (state, { payload }) => {
      state.language = payload;
    },

    handleDisplayDataDATXIndex: (state, { payload }) => {
      state.lastValue = payload.lastValue;
      state.differenceValue = payload.differenceValue;
    },

    handleExpandSidebar: (state) => {
      state.expandSidebar = true;
    },

    handleCollapseSidebar: (state) => {
      state.expandSidebar = false;
    },

    handleExpandPortfolio: (state) => {
      state.expandPortfolio = true;
    },

    handleCollapsePortfolio: (state) => {
      state.expandPortfolio = false;
    },
    handleCloseAnnouncement: (state) => {
      state.closeAnnouncement = true;
    },

    handleSetTypeOfNewsModal: (state, { payload }) => {
      state.typeOfNewsModal = payload;
    },

    handleSetFirstTimeRenderApp: (state, { payload }) => {
      state.isFirstTimeRender = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handleExpandSidebar,
  handleCollapseSidebar,
  handleExpandPortfolio,
  handleCollapsePortfolio,
  handleCloseAnnouncement,
  handleChangeColorLightTheme,
  handleChangeColorDarkTheme,
  handleChangeLanguage,
  handleSetTypeOfNewsModal,
  handleSetFirstTimeRenderApp,
} = uiHandlerSlice.actions;
export const expandSidebarSelector = (state) => state.uiHandler.expandSidebar;
export const expandPortfolioSelector = (state) =>
  state.uiHandler.expandPortfolio;

export const closeAnnouncementSelector = (state) =>
  state.uiHandler.closeAnnouncement;

export const uiHandlerReducer = uiHandlerSlice.reducer;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
  label {
    color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  .ant-row {
    padding: 10px 0;
  }
  .ant-input-group-addon {
    background: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid var(--border-color) !important;
  }
  .ant-form-item-explain {
    font-size: 12px;
    padding: 4px 0;
  }
`;

export const Heading = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 60px;
`;

export const SubHeading = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #828282;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const Actions = styled.div`
  text-align: right;
  width: 100%;
  button {
    height: 44px;
    margin-right: 16px;
  }
  button:last-of-type {
    margin-right: 0;
  }
`;

export const AddNewGroupButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #49c2f2;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  padding: 4px 0;
  color: #f24b78;
`;

export const ConnectionName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

import { Popover, Progress, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ArrowDownTable from 'assets/icons/ArrowDownTable';
import ArrowUpTable from 'assets/icons/ArrowUpTable';
import EditIcon from 'assets/icons/EditIcon';
import GreenTickIcon from 'assets/icons/GreenTickIcon';
import SendIcon from 'assets/icons/SendIcon';

import { formatPrice } from 'utils';
import {
  Container,
  HistoryCell,
  HistoryArrow,
  HistoryRecord,
  Actions,
  SentButton,
  TableButton,
} from './styles';
import { Call } from 'constants/broker';
import AbsoluteLoader from 'components/GlobalComponents/UIHandler/AbsoluteLoader';

const TableRecommendations = (props) => {
  const {
    loading,
    data,
    total,
    selectedHistory,
    activeKey,
    onRowClick,
    onEditClick,
    onSendClick,
    onHistoryClick,
    onChange,
  } = props;
  const { t } = useTranslation(['recommendationsScreen']);

  const handleClickSend = async (event, item) => {
    event.stopPropagation();
    if (onSendClick) {
      onSendClick(item);
    }
  };

  const handleClickEdit = (event, item) => {
    event.stopPropagation();
    if (onEditClick) {
      onEditClick(item);
    }
  };

  const handleClickRow = (event, record) => {
    onRowClick(record);
  };

  const handleClickHistory = (event, record) => {
    event.stopPropagation();
    if (onHistoryClick) {
      onHistoryClick(record);
    }
  };

  const renderCall = (value) => {
    let text = value;
    let color = '#1ED994';
    if (text === Call.BUY || text === Call.BUY_MORE) {
      text = t('buyMore');
      color = '#1ED994';
    } else if (text === Call.BUY_TEST) {
      text = t('buyTest');
      color = '#1ED994';
    } else if (text === Call.SELL) {
      text = t('sell');
      color = '#F24B78';
    }
    return (
      <div
        style={{
          color,
          fontWeight: '700',
          fontSize: '12px',
          lineHeight: '18px',
        }}
      >
        {text}
      </div>
    );
  };

  const renderRecord = (record) => {
    return (
      <HistoryRecord>
        <div>
          <span>{record.updatedAt}</span>
          <strong>{renderCall(record.tradingType)}</strong>
        </div>
        <div>
          {t('ratio')}: {record.proportion}%
        </div>
        <div>
          {t('holding')}: {record.hold || 0}%
        </div>
      </HistoryRecord>
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    if (onChange) {
      onChange(pagination, filters, sorter);
    }
  };

  const columns = [
    {
      title: t('recommendationTable.col1'),
      key: 'symbol',
      align: 'left',
      width: 60,
      fixed: 'left',
      render: ({ tradingType, symbol, color }, _, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: color,
            fontWeight: '700',
          }}
        >
          {(tradingType === Call.BUY ||
            tradingType === Call.BUY_MORE ||
            tradingType === Call.BUY_TEST) && <ArrowUpTable />}
          {tradingType === Call.SELL && <ArrowDownTable />}
          <span style={{ marginLeft: 4 }}>{symbol}</span>
        </div>
      ),
    },
    {
      title: t('recommendationTable.col2'),
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      fixed: 'left',
      width: 100,
      render: (value) => formatPrice(value),
    },
    {
      title: t('recommendationTable.col3'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      width: 100,
      render: (data) => (
        <Popover
          content={
            <HistoryRecord>
              <span>{data}</span>
            </HistoryRecord>
          }
        >
          {moment(data, 'HH:mm:ss A').format('HH:mm')}
        </Popover>
      ),
    },
    {
      title: t('recommendationTable.col4'),
      dataIndex: 'histories',
      key: 'histories',
      align: 'left',
      width: 180,
      render: (histories) => (
        <HistoryCell>
          {histories ? (
            histories.slice(0, 6).map((record) => (
              <Popover
                key={record.key}
                content={renderRecord(record)}
                onClick={(event) => handleClickHistory(event, record)}
              >
                {record.tradingType === Call.BUY ||
                record.tradingType === Call.BUY_MORE ||
                record.tradingType === Call.BUY_TEST ? (
                  <HistoryArrow
                    hoverable
                    className={
                      selectedHistory?.key === record.key ? 'active' : ''
                    }
                  >
                    <ArrowUpTable />
                  </HistoryArrow>
                ) : record.tradingType === Call.SELL ? (
                  <HistoryArrow
                    hoverable
                    className={
                      selectedHistory?.key === record.key ? 'active' : ''
                    }
                  >
                    <ArrowDownTable />
                  </HistoryArrow>
                ) : (
                  <HistoryArrow>-</HistoryArrow>
                )}
              </Popover>
            ))
          ) : (
            <>
              <HistoryArrow>-</HistoryArrow>
              <HistoryArrow>-</HistoryArrow>
              <HistoryArrow>-</HistoryArrow>
              <HistoryArrow>-</HistoryArrow>
              <HistoryArrow>-</HistoryArrow>
              <HistoryArrow>-</HistoryArrow>
            </>
          )}
        </HistoryCell>
      ),
    },
    {
      title: t('recommendationTable.col5'),
      dataIndex: 'call',
      align: 'left',
      width: 100,
      render: (_, row) => renderCall(row.tradingType),
    },
    {
      title: t('recommendationTable.col6'),
      dataIndex: 'proportion',
      key: 'proportion',
      align: 'right',
      width: 90,
      render: (value) => <span>{value}%</span>,
    },
    {
      title: <span>{t('recommendationTable.col7')}</span>,
      dataIndex: 'hold',
      key: 'hold',
      align: 'left',
      width: 180,
      render: (value, row) =>
        value === 100 ? (
          <Progress
            percent={100}
            strokeColor="#008CCF"
            trailColor="#D9D9D9"
            strokeWidth={10}
            format={() => '100%'}
            success={{
              strokeColor: '#EBBF43',
            }}
            size="small"
          />
        ) : (
          <Progress
            percent={value || 0}
            strokeColor="#008CCF"
            trailColor="#D9D9D9"
            strokeWidth={10}
            success={{
              strokeColor: '#EBBF43',
            }}
            size="small"
          />
        ),
    },

    {
      title: t('recommendationTable.col8'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (text, row) => (
        <Actions
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {!row.sent ? (
            <>
              <TableButton onClick={(event) => handleClickEdit(event, row)}>
                <EditIcon />
              </TableButton>
              <TableButton onClick={(event) => handleClickSend(event, row)}>
                <SendIcon />
              </TableButton>
            </>
          ) : (
            <SentButton>
              <SendIcon />
              <GreenTickIcon />
            </SentButton>
          )}
        </Actions>
      ),
    },
  ];

  return (
    <Container>
      <Table
        loading={{
          spinning: loading,
          indicator: <AbsoluteLoader size="default" />,
        }}
        size="small"
        className="table-global list-sector-table empty-data-table modal-table"
        scroll={{ y: 'calc(100vh - 39rem - 274px)', x: 1000 }}
        columns={columns}
        dataSource={data}
        pagination={{
          total,
          pageSize: 20,
        }}
        onRow={(record) => {
          return {
            onClick: (event) => handleClickRow(event, record),
          };
        }}
        rowClassName={(record) =>
          record.key === activeKey && !selectedHistory ? 'active' : ''
        }
        onChange={handleChange}
      />
    </Container>
  );
};

export default TableRecommendations;

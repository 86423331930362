const SortIcon = ({ color = 'var(--border-color)', size = 16 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 9"
        fill={color}
      >
        <path
          d="M5.5 8.125C5.5 7.95924 5.56585 7.80027 5.68306 7.68306C5.80027 7.56585 5.95924 7.5 6.125 7.5H9.875C10.0408 7.5 10.1997 7.56585 10.3169 7.68306C10.4342 7.80027 10.5 7.95924 10.5 8.125C10.5 8.29076 10.4342 8.44973 10.3169 8.56694C10.1997 8.68415 10.0408 8.75 9.875 8.75H6.125C5.95924 8.75 5.80027 8.68415 5.68306 8.56694C5.56585 8.44973 5.5 8.29076 5.5 8.125ZM3 4.375C3 4.20924 3.06585 4.05027 3.18306 3.93306C3.30027 3.81585 3.45924 3.75 3.625 3.75H12.375C12.5408 3.75 12.6997 3.81585 12.8169 3.93306C12.9342 4.05027 13 4.20924 13 4.375C13 4.54076 12.9342 4.69973 12.8169 4.81694C12.6997 4.93415 12.5408 5 12.375 5H3.625C3.45924 5 3.30027 4.93415 3.18306 4.81694C3.06585 4.69973 3 4.54076 3 4.375ZM0.5 0.625C0.5 0.45924 0.565848 0.300269 0.683058 0.183058C0.800269 0.0658481 0.95924 0 1.125 0H14.875C15.0408 0 15.1997 0.0658481 15.3169 0.183058C15.4342 0.300269 15.5 0.45924 15.5 0.625C15.5 0.79076 15.4342 0.949731 15.3169 1.06694C15.1997 1.18415 15.0408 1.25 14.875 1.25H1.125C0.95924 1.25 0.800269 1.18415 0.683058 1.06694C0.565848 0.949731 0.5 0.79076 0.5 0.625Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default SortIcon;

import styled from 'styled-components';

export const NewsBoxWrapper = styled.div`
  background: var(--primary-color);
  height: 100%;
  position: relative;
  min-height: 400px;
`;

export const NewsBoxHeaderStyled = styled.div`
  background: var(--section-header-color);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  height: 4.4rem;
`;

export const NewsBoxHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
`;

export const NewsBoxBodyStyled = styled.div`
  min-height: 40rem;
  display: flex;
  flex-direction: column;
`;

export const ViewAllButton = styled.button`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 2.4rem 0 2.4rem 0;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PermissionDeniedWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

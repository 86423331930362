import {
  DownOutlined,
  FullscreenExitOutlined,
  MenuOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { Dropdown, Modal, Tooltip } from 'antd';
import GlobalTooltip from 'components/GlobalComponents/GlobalTooltip';
import InfiniteScroll from 'components/GlobalComponents/InfiniteScroll';
import useDebounce2 from 'hooks/useDebounce2';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import PostItemContainer from '../PostItemPortfolioNews';
import PostItemWatchlistNews from '../PostItemWatchlistNews';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import {
  BodyStyled,
  HeaderMobileTitleStyled,
  HeaderStyled,
  HeaderTitleStyled,
  MainWrapper,
  PostListStyled,
  SidebarLeftDesktopWrapper,
  SiderbarLeftMobileStyled,
  SiderbarRightWrapper,
} from './styles';
import './styles.css';

const NewsModal = ({
  onClose,
  stockCodes,
  sectorList,
  visible,
  next,
  isNext,
  onChangeSector,
  onSelectStockCode,
  filter,
  posts,
  isClickedBtn,
  setIsClickedBtn,
  modalLoading,
}) => {
  const postListRef = useRef();
  const bodyRef = useRef();
  const headerRef = useRef();

  const debounce = useDebounce2();

  const [siderbarMobileVisible, setSiderbarMobileVisible] = useState(false);

  const { typeOfNewsModal } = useSelector((state) => state.uiHandler);

  const sectorListArr = [...sectorList];

  const scrollTop = () => {
    setTimeout(() => {
      const target =
        window.innerWidth < 900 ? bodyRef.current : postListRef.current;
      target?.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  const handleScroll = () => {
    scrollIntoView(document.querySelector('.scroll-row-news'), {
      align: {
        top: 10,
      },
    });
  };

  const { t } = useTranslation(['common']);

  return (
    <div styles={{ position: 'relative' }}>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose
        visible={visible}
        footer={null}
        centered
        width="90vw"
        style={{
          borderRadius: 4,
          overflow: 'hidden',
          maxWidth: '1920px',
        }}
        bodyStyle={{
          height: '90vh',
          maxHeight: '1080px',
        }}
        className="news-modal"
      >
        <HeaderStyled>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <SiderbarLeftMobileStyled>
              <Dropdown
                trigger={['click']}
                getPopupContainer={() => headerRef.current}
                className="sectors-dropdown"
                overlay={
                  <SidebarLeft
                    filter={filter}
                    stockCodes={stockCodes}
                    scrollTop={scrollTop}
                    sectorListArr={sectorListArr}
                    onChangeSector={(params) => {
                      debounce(() => {
                        onChangeSector(params);
                        setSiderbarMobileVisible(false);
                        scrollTop();
                      });
                    }}
                    onSelectStockCode={(params) => {
                      debounce(() => {
                        onSelectStockCode(params);
                        scrollTop();
                      });
                    }}
                  />
                }
                visible={siderbarMobileVisible}
                onVisibleChange={() =>
                  setSiderbarMobileVisible(!siderbarMobileVisible)
                }
                overlayStyle={{
                  backgroundColor: 'var(--primary-color)',
                  padding: 16,
                  border: '1px solid var(--border-color)',
                }}
              >
                <HeaderMobileTitleStyled ref={headerRef}>
                  <MenuOutlined
                    onClick={() => setSiderbarMobileVisible(true)}
                  />
                  <DownOutlined />
                </HeaderMobileTitleStyled>
              </Dropdown>
            </SiderbarLeftMobileStyled>
            <HeaderTitleStyled>{t('newsBox.title')}</HeaderTitleStyled>
          </div>
          <Tooltip
            title={t('newsBox.toolTip2')}
            placement="bottom"
            color="var(--light-blue-color)"
          >
            <span style={{ cursor: 'pointer' }} onClick={onClose}>
              <FullscreenExitOutlined
                style={{ fontSize: 20, color: 'var(--border-color)' }}
              />
            </span>
          </Tooltip>
        </HeaderStyled>

        <BodyStyled ref={bodyRef}>
          <SidebarLeftDesktopWrapper>
            <SidebarLeft
              scrollTop={scrollTop}
              // filter={filter}
              stockCodes={stockCodes}
              sectorListArr={sectorListArr}
              onChangeSector={onChangeSector}
              onSelectStockCode={(params) => {
                debounce(() => {
                  onSelectStockCode(params);
                  scrollTop();
                });
              }}
            />
          </SidebarLeftDesktopWrapper>

          <MainWrapper>
            {(() => {
              switch (true) {
                // case loadingInit:
                //   return (
                //     <PostListStyled ref={postListRef}>
                //       {new Array(10).fill(null).map((_, index) => (
                //         <PostItem.Loading key={index} type="full" />
                //       ))}
                //     </PostListStyled>
                //   );

                // case posts.length === 0:
                //   return (
                //     <div>
                //       <div style={{ width: "70rem", maxWidth: "100%" }}></div>
                //       <EmptyWrapper>
                //         <Empty />
                //       </EmptyWrapper>
                //     </div>
                //   );

                default:
                  return (
                    <>
                      <PostListStyled ref={postListRef}>
                        <InfiniteScroll next={next} isNext={isNext}>
                          {typeOfNewsModal === 'portfolioNewsModal' ? (
                            <PostItemContainer
                              scrollTop={scrollTop}
                              onChangeSector={onChangeSector}
                              isClickedBtn={isClickedBtn}
                              setIsClickedBtn={setIsClickedBtn}
                            />
                          ) : (
                            <PostItemWatchlistNews
                              scrollTop={scrollTop}
                              onChangeSector={onChangeSector}
                              isClickedBtn={isClickedBtn}
                              setIsClickedBtn={setIsClickedBtn}
                            />
                          )}
                        </InfiniteScroll>
                      </PostListStyled>
                      <button
                        className="back-to-top-btn"
                        onClick={handleScroll}
                        style={{
                          position: 'absolute',
                          bottom: '30px',
                          zIndex: '100',
                          right: '2rem',
                        }}
                      >
                        <GlobalTooltip
                          title={t('newsBox.backToTopBtn')}
                          placement="left"
                        >
                          <span>
                            <UpCircleOutlined
                              style={{
                                fontSize: '30px',
                                color: 'rgba(255,255,255,0.3) !important',
                              }}
                            />
                          </span>
                        </GlobalTooltip>
                      </button>
                    </>
                  );
              }
            })()}
          </MainWrapper>

          <SiderbarRightWrapper>
            <SidebarRight data={posts?.slice(0, 5)} />
          </SiderbarRightWrapper>
        </BodyStyled>
      </Modal>
    </div>
  );
};

export default NewsModal;

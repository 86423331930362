import { Empty } from 'antd';
import PermissionDenied from 'components/GlobalComponents/PermissionDenied';
import FormCreateIssue from 'components/SupportScreenComponents/FormCreateIssue';
import IssueList from 'components/SupportScreenComponents/IssueList';
import { useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { SupportScreenWrapper } from './styles';

const SupportScreen = () => {
  const { REACT_APP_URL } = process.env;
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [defaultId, setDefaultId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page) => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(
        `${REACT_APP_URL}/api/issues/me`,
        {
          limit: 8,
          page: page,
          order: {
            created_at: -1,
          },
        }
      );

      const { data } = response.data;

      setIssueList(data?.issues);
      setDefaultId(data?.issues[0]._id);
      setTotalPage(data?.meta.pagination.total);
      setLoading(false);

      if (!data || !data.status) {
        setLoading(false);

        console.log('first', data);
        throw new Error(JSON.stringify(data));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();

    //eslint-disable-next-line
  }, []);

  // if (issueList === undefined) {
  //   return null;
  // }

  const renderData = () => {
    if (issueList === undefined) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PermissionDenied />
        </div>
      );
    } else if (issueList.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 'auto' }}
        />
      );
    } else {
      return (
        <IssueList
          fetchData={fetchData}
          issueList={issueList}
          loading={loading}
          totalPage={totalPage}
          defaultId={defaultId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      );
    }
  };

  return (
    <SupportScreenWrapper>
      {/* <h2 style={{ fontSize: 20, color: "var(--white-color)" }}>
        {t("portfolioSelectorSection.portfolioModal.incomingMessage.message1")}{" "}
        <br />{" "}
        {t("portfolioSelectorSection.portfolioModal.incomingMessage.message2")}
      </h2> */}

      {renderData()}

      <FormCreateIssue
        fetchData={fetchData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </SupportScreenWrapper>
  );
};

export default SupportScreen;

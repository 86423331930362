import { Input } from 'antd';
import SearchIcon from 'assets/icons/SearchIcon';
import React, { useEffect, useRef, useState } from 'react';
import { StyledAutoComplete } from './styled';
import { useSelector } from 'react-redux';
import { stocksSelector } from 'features/stocks/stockSlice';

const AutoCompleteStock = ({
  onChange,
  inputRef,
  keyword,
  setKeyword,
  placement,
}) => {
  const { data: stocks } = useSelector(stocksSelector);
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    setSearchOptions(stocks);
  }, [stocks]);

  const previousRef = useRef('');

  const checkKeywordMapping = (code) => {
    if (!code) return code;

    const replaceStr = `<span style="color: #005dfb;">${keyword.toUpperCase()}</span>`;
    const regx = new RegExp(keyword, 'gi');
    return code.replace(regx, replaceStr);
  };

  const handleUppercaseText = (e) => {
    return (e.target.value = e.target.value.toUpperCase());
  };

  const options = searchOptions?.map((stock) => ({
    value: stock.ticker,
    label: (
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div
            className="text-level_2 text-white font-bold"
            dangerouslySetInnerHTML={{
              __html: checkKeywordMapping(stock.ticker),
            }}
          />
          <div className="text-level_1 text-custom-gray300 font-bold whitespace-pre-line">
            ({stock.exchange})
          </div>
        </div>
        <div className="text-level_1 text-custom-darkBlue200">
          {stock.companyName}
        </div>
      </div>
    ),
  }));

  return (
    <StyledAutoComplete
      popupClassName="search-stock-autocomplete"
      options={options}
      onChange={(value) => {
        previousRef.current = keyword;
        setKeyword(value);
      }}
      onSelect={(value) => {
        if (onChange && stocks.find((a) => a.ticker === value)) {
          previousRef.current = String(value);
          onChange(value);
        }
      }}
      dropdownMatchSelectWidth={300}
      allowClear
      onClear={() => {
        if (onChange) {
          previousRef.current = '';
          onChange('');
        }
      }}
      filterOption={false}
      value={keyword}
      onSearch={(value) => {
        if (value.length > 3) return;
        if (value.length < 3 && value.length > 0) {
          const firstLetter = stocks.filter(
            (a) =>
              a.ticker.toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
              a.ticker.slice(0, value.length) === value
          );

          const secondLetter = stocks.filter(
            (a) =>
              a.ticker.toUpperCase().indexOf(value.toUpperCase()) !== -1 &&
              a.ticker.slice(1, value.length + 1) === value &&
              !firstLetter.find((b) => a.ticker === b.ticker)
          );

          const endLetter =
            value.length === 1
              ? stocks.filter(
                  (a) =>
                    a.ticker.toUpperCase().indexOf(value.toUpperCase()) !==
                      -1 &&
                    a.ticker.slice(2, 3) === value &&
                    !firstLetter.find((b) => a.ticker === b.ticker) &&
                    !secondLetter.find((b) => a.ticker === b.ticker)
                )
              : [];

          setSearchOptions([...firstLetter, ...secondLetter, ...endLetter]);
        } else {
          setSearchOptions(
            stocks.filter(
              (a) => a.ticker.toUpperCase().indexOf(value.toUpperCase()) !== -1
            )
          );
        }
      }}
      onBlur={() => {
        if (previousRef.current !== keyword) {
          if (!keyword && previousRef.current && onChange) onChange('');
          if (
            keyword &&
            previousRef.current &&
            onChange &&
            stocks.find((a) => a.ticker === keyword)
          )
            onChange(keyword);
        }
      }}
      onKeyDown={(e) => {
        const allowedRegex = new RegExp('^[a-zA-Z0-9]*$');
        if (!allowedRegex.test(e.key)) {
          e.preventDefault();
          return;
        }
        if (e.key === 'Enter' && onChange) {
          onChange(keyword);
        }
      }}
      placement={placement || 'bottomLeft'}
    >
      <Input
        disabled
        className="ml-auto"
        bordered={false}
        ref={inputRef}
        onInput={handleUppercaseText}
        placeholder="Tìm kiếm CK"
        prefix={<SearchIcon />}
      />
    </StyledAutoComplete>
  );
};

export default AutoCompleteStock;

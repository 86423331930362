import React, { useImperativeHandle, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { StyledModal, ModalHeader } from './styled';

const TradingViewExchange = (__, ref) => {
  const [state, setState] = useState({ status: false, symbol: '' });

  const onClose = () => {
    setState({ symbol: '', status: false });
  };

  useImperativeHandle(ref, () => ({
    open: (symbol) => {
      setState({ symbol, status: true });
    },
  }));

  return (
    <StyledModal
      visible={state.status}
      closable={false}
      footer={null}
      onCancel={onClose}
    >
      <ModalHeader>
        <CloseCircleOutlined
          style={{ fontSize: 25, cursor: 'pointer' }}
          onClick={onClose}
        />
      </ModalHeader>
      {state.symbol && (
        <iframe
          title="exchangeTradingViewStockDetail"
          src={`${process.env.REACT_APP_TRADING_VIEW_URL}exchange?symbol=${state.symbol}&language=vi&interval=D&theme=classic&target=tradingViewStockDetail&autosave=1`}
        />
      )}
    </StyledModal>
  );
};

export default React.forwardRef(TradingViewExchange);

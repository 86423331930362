import { Empty, Tooltip } from 'antd';
import ExpandFourAnglesIcon from 'assets/icons/ExpandFourAnglesIcon';
import LineAndScatterChartGrid from 'components/ChartComponents/DATXIndexPerformance';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  StyledHeaderIcon,
  StyledHeaderText,
  StyledSectionHeader,
} from 'screens/RatingsScreen/styles';
import { InfoContainer, InfoItem } from 'styles';

const DATXIndexPerformance = ({ portfolioPerformance }) => {
  const { lineAndScatterChart } = useSelector((state) => state.uiHandler);
  const { t } = useTranslation(['ratingsScreen']);

  return (
    <div>
      <StyledSectionHeader>
        <StyledHeaderText>
          {t('DATXIndexPerformanceSection.title')}
        </StyledHeaderText>

        <Tooltip
          title={t('helper.toolTip1')}
          placement="bottom"
          color="var(--light-blue-color)"
        >
          <StyledHeaderIcon className="expand-icon">
            <ExpandFourAnglesIcon />
          </StyledHeaderIcon>
        </Tooltip>
      </StyledSectionHeader>
      <div>
        {portfolioPerformance.length > 0 ? (
          <>
            <InfoContainer>
              <InfoItem>
                <span style={{ backgroundColor: '#46a9de' }}></span>
                <span>DAT Index</span>
              </InfoItem>

              <InfoItem>
                <span style={{ backgroundColor: '#EFD618' }}></span>
                <span>VN Index</span>
              </InfoItem>
            </InfoContainer>

            <LineAndScatterChartGrid
              type="hybrid"
              data={portfolioPerformance}
              chartHeight={400}
              tickStroke={lineAndScatterChart.tickStroke}
              gradientColor1={lineAndScatterChart.gradientColor1}
              gradientColor2={lineAndScatterChart.gradientColor2}
              gradientColor3={lineAndScatterChart.gradientColor3}
              gradientColor4={lineAndScatterChart.gradientColor4}
            />
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ padding: '4rem 0' }}
          />
        )}
      </div>
    </div>
  );
};

export default DATXIndexPerformance;

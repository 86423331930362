import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div`
  width: 100%;
  background: #24283d;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  min-height: calc(100vh - 6rem - 4rem - 58px);
`;

export const Side = styled.div`
  width: 100%;
  height: 100%;
  background: #24283d;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0px 0px;
  overflow: auto;
  overflow-y: overlay;
`;

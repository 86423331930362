export const hsxCompanyList = [
  {
    Code: 'A32',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 32',
    CompanyNameEn: '32 Joint Stock Company',
  },
  {
    Code: 'AAA',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa An Phát Xanh',
    CompanyNameEn: 'An Phat Bioplastics Joint Stock Company',
  },
  {
    Code: 'AAM',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ Phần Thủy Sản MeKong',
    CompanyNameEn: 'Mekong Fisheries Joint Stock Company',
  },
  {
    Code: 'AAS',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán SmartInvest',
    CompanyNameEn: 'SmartInvest Securities Joint Stock Company',
  },
  {
    Code: 'AAT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Tiên Sơn Thanh Hóa',
    CompanyNameEn: 'Tien Son Thanh Hoa Group Joint Stock Company',
  },
  {
    Code: 'AAV',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần AAV Group',
    CompanyNameEn: 'AAV Group joint Stock Company',
  },
  {
    Code: 'ABB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần An Bình',
    CompanyNameEn: 'An Binh Commercial Joint Stock Bank',
  },
  {
    Code: 'ABC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Truyền thông VMG',
    CompanyNameEn: 'VMG Media Joint Stock Company',
  },
  {
    Code: 'ABI',
    Sector: 'insurances',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bảo hiểm Ngân hàng Nông Nghiệp',
    CompanyNameEn: 'Agriculture Bank Insurance Joint Stock Corporation (ABIC)',
  },
  {
    Code: 'ABR',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Nhãn Hiệu Việt',
    CompanyNameEn: 'Viet Brand Invest Joint Stock Company',
  },
  {
    Code: 'ABS',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Nông nghiệp Bình Thuận',
    CompanyNameEn: 'BinhThuan Agriculture Services Joint Stock Company',
  },
  {
    Code: 'ABT',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Bến Tre',
    CompanyNameEn: 'Bentre Aquaproduct Import And Export Joint Stock Company',
  },
  {
    Code: 'ACB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Á Châu',
    CompanyNameEn: 'Asia Commercial Joint Stock Bank',
  },
  {
    Code: 'ACC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Bình Dương ACC',
    CompanyNameEn:
      'ACC Binh Duong Investment and Construction Joint Stock Company',
  },
  {
    Code: 'ACE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bê tông ly tâm An Giang',
    CompanyNameEn: 'An Giang Centrifugal Concrete Joint Stock Company',
  },
  {
    Code: 'ACG',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Gỗ An Cường',
    CompanyNameEn: 'An Cuong Wood - Working Joint Stock Company',
  },
  {
    Code: 'ACL',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Cửu Long An Giang',
    CompanyNameEn: 'Cuu Long An Giang Fish Joint Stock Company',
  },
  {
    Code: 'ACM',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Khoáng sản Á Cường',
    CompanyNameEn: 'A Cuong Mineral Group Joint Stock Company',
  },
  {
    Code: 'ACS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Thương mại 2',
    CompanyNameEn: 'Architects & Construction Service Corporation',
  },
  {
    Code: 'ACV',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cảng Hàng không Việt Nam - CTCP',
    CompanyNameEn: 'Airports Corporation of Vietnam',
  },
  {
    Code: 'ADC',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Mĩ thuật và Truyền thông',
    CompanyNameEn: 'Art Design and Communication Joint Stock Company',
  },
  {
    Code: 'ADG',
    Sector: 'communicationServices',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Clever Group',
    CompanyNameEn: 'Clever Group Corporation',
  },
  {
    Code: 'ADP',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sơn Á Đông',
    CompanyNameEn: 'A Dong Paint Joint Stock Company',
  },
  {
    Code: 'ADS',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần DAMSAN',
    CompanyNameEn: 'Damsan Joint Stock Company',
  },
  {
    Code: 'AFX',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Nông sản Thực phẩm An Giang',
    CompanyNameEn:
      'An Giang Agriculture and Foods Import - Export Joint Stock Company',
  },
  {
    Code: 'AG1',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 28.1',
    CompanyNameEn: '28.1 Joint Stock Company',
  },
  {
    Code: 'AGE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị An Giang',
    CompanyNameEn: 'An Giang Urban Environment Joint Stock Company',
  },
  {
    Code: 'AGF',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản An Giang',
    CompanyNameEn: 'An Giang Fisheries Import and Export Joint Stock Company',
  },
  {
    Code: 'AGG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Bất động sản An Gia',
    CompanyNameEn: 'An Gia Real Estate Investment And Development Corporation',
  },
  {
    Code: 'AGM',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu An Giang',
    CompanyNameEn: 'An Giang Import Export Company',
  },
  {
    Code: 'AGP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm AGIMEXPHARM',
    CompanyNameEn: 'AGIMEXPHARM Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'AGR',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán AGRIBANK',
    CompanyNameEn: 'AgriBank Securities Corporation',
  },
  {
    Code: 'AGX',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Nông sản Xuất khẩu Sài Gòn',
    CompanyNameEn:
      'Sai Gon Export Foodstuffs and Agricultural Products Joint Stock Company',
  },
  {
    Code: 'AIC',
    Sector: 'insurances',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo hiểm Hàng không',
    CompanyNameEn: 'Vietnam National Aviation Insurance Corporation',
  },
  {
    Code: 'ALT',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Văn hóa Tân Bình',
    CompanyNameEn: 'Alta Company',
  },
  {
    Code: 'ALV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng ALVICO',
    CompanyNameEn: 'ALVICO Construction Joint Stock Company',
  },
  {
    Code: 'AMC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Á Châu',
    CompanyNameEn: 'Asia Mineral Joint Stock Company',
  },
  {
    Code: 'AMD',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Khoáng sản FLC Stone',
    CompanyNameEn: 'FLC Stone Mining and Investment Joint Stock Company',
  },
  {
    Code: 'AME',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty cổ phần Alphanam E&C',
    CompanyNameEn: 'Alphanam E&C Joint Stock Company',
  },
  {
    Code: 'AMP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Armephaco',
    CompanyNameEn: 'Armephaco Joint Stock Company',
  },
  {
    Code: 'AMS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Xây dựng AMECC',
    CompanyNameEn: 'AMECC Mechanical Construction Joint Stock Company ',
  },
  {
    Code: 'AMV',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Sản xuất Kinh doanh Dược và Trang thiết bị Y tế Việt Mỹ',
    CompanyNameEn: 'American Vietnamese Biotech Incorporation',
  },
  {
    Code: 'ANT',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Rau quả Thực phẩm An Giang',
    CompanyNameEn:
      'An Giang Fruit-Vegetables &amp; Foodstuff Joint Stock Company',
  },
  {
    Code: 'ANV',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nam Việt',
    CompanyNameEn: 'Nam Viet Corporation',
  },
  {
    Code: 'APC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chiếu xạ An Phú',
    CompanyNameEn: 'An Phu Irradiation Joint Stock Company',
  },
  {
    Code: 'APF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nông sản Thực phẩm Quảng Ngãi',
    CompanyNameEn:
      'Quang Ngai Agricultural Products And Foodstuff Stock Company',
  },
  {
    Code: 'APG',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán APG',
    CompanyNameEn: 'APG Securities Joint Stock Company',
  },
  {
    Code: 'APH',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn An Phát Holdings',
    CompanyNameEn: 'An Phat Holdings Joint Stock Company',
  },
  {
    Code: 'API',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Châu Á - Thái Bình Dương',
    CompanyNameEn: 'Asia - Pacific Investment Joint Stock Company',
  },
  {
    Code: 'APL',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí và Thiết bị áp lực - VVMI',
    CompanyNameEn:
      'VVMI - Mechanical And Pressure Equipment Joint Stock Company',
  },
  {
    Code: 'APP',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Phụ gia và Sản phẩm dầu mỏ',
    CompanyNameEn: 'Additives and Petroleum Products Joint Stock Company',
  },
  {
    Code: 'APS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Châu Á - Thái Bình Dương',
    CompanyNameEn: 'Asia - Pacific Securities Joint Stock Company',
  },
  {
    Code: 'APT',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh thủy hải sản Sài Gòn',
    CompanyNameEn: 'Saigon Aquatic Product Trading Joint Stock Company',
  },
  {
    Code: 'ARM',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Hàng không',
    CompanyNameEn: 'General Aviation Import Export Joint Stock Company',
  },
  {
    Code: 'ART',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán BOS',
    CompanyNameEn: 'BOS Securities Joint Stock Company',
  },
  {
    Code: 'ASA',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần ASA',
    CompanyNameEn: 'ASA Joint Stock Company',
  },
  {
    Code: 'ASG',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn ASG',
    CompanyNameEn: 'ASG Corporation',
  },
  {
    Code: 'ASM',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Sao Mai',
    CompanyNameEn: 'Sao Mai Group Corporation',
  },
  {
    Code: 'ASP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Dầu khí An Pha',
    CompanyNameEn: 'An Pha Petrol Joint Stock Company',
  },
  {
    Code: 'AST',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Hàng không Taseco',
    CompanyNameEn: 'Taseco Air Services Joint Stock Company',
  },
  {
    Code: 'ATA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần NTACO',
    CompanyNameEn: 'NTACO Corporation',
  },
  {
    Code: 'ATB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần An Thịnh',
    CompanyNameEn: 'An Thinh Joint Stock Company',
  },
  {
    Code: 'ATG',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần An Trường An',
    CompanyNameEn: 'An Truong An Joint Stock Company',
  },
  {
    Code: 'ATS',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Dược phẩm Atesco',
    CompanyNameEn: 'Atesco Pharmaceutical Group Joint Stock Company',
  },
  {
    Code: 'AUM',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vinacafe Sơn Thành',
    CompanyNameEn: 'Vinacafe Son Thanh Joint Stock Company',
  },
  {
    Code: 'AVC',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện A Vương',
    CompanyNameEn: 'A Vuong Hydropower Joint Stock Company',
  },
  {
    Code: 'AVF',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Việt An',
    CompanyNameEn: 'Anvifish Joint Stock Company',
  },
  {
    Code: 'B82',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 482',
    CompanyNameEn: 'Joint Stock - Company No 482',
  },
  {
    Code: 'BAB',
    Sector: 'banks',
    Exchange: 'HNX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Bắc Á',
    CompanyNameEn: 'Bac A Commercial Joint Stock Bank',
  },
  {
    Code: 'BAF',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty cổ phần Nông nghiệp BAF Việt Nam',
    CompanyNameEn: 'BAF Vietnam Agriculture Joint Stock Company',
  },
  {
    Code: 'BAL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Bia - Rượu - Nước giải khát',
    CompanyNameEn: 'Beer - Alcohol - Beverage Packaging Joint Stock Company',
  },
  {
    Code: 'BAX',
    Sector: 'industryRealEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thống Nhất',
    CompanyNameEn: 'Thong Nhat Joint Stock Company',
  },
  {
    Code: 'BBC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bibica',
    CompanyNameEn: 'Bibica Corporation',
  },
  {
    Code: 'BBH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Hoàng Thạch',
    CompanyNameEn: 'Hoang Thach Joint Stock Bagging Company',
  },
  {
    Code: 'BBM',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Nam Định',
    CompanyNameEn: 'Ha Noi - Nam Dinh Beer Joint Stock Company',
  },
  {
    Code: 'BBS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VICEM Bao bì Bút Sơn',
    CompanyNameEn: 'VICEM Packing But Son Joint Stock Company',
  },
  {
    Code: 'BBT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bông Bạch Tuyết',
    CompanyNameEn: 'Bach Tuyet Cotton Corporation',
  },
  {
    Code: 'BCA',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần B.C.H',
    CompanyNameEn: 'B.C.H Joint Stock Company',
  },
  {
    Code: 'BCB',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 397',
    CompanyNameEn: '397 Joint Stock Company',
  },
  {
    Code: 'BCC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Bỉm Sơn',
    CompanyNameEn: 'Bim Son Cement Joint Stock Company',
  },
  {
    Code: 'BCE',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Giao thông Bình Dương',
    CompanyNameEn:
      'Binh Duong Construction And Civil Engineering Joint Stock Company',
  },
  {
    Code: 'BCF',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Bích Chi',
    CompanyNameEn: 'Bich Chi Food Company',
  },
  {
    Code: 'BCG',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Bamboo Capital',
    CompanyNameEn: 'Bamboo Capital Group Joint Stock Company',
  },
  {
    Code: 'BCM',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Đầu tư và Phát triển Công nghiệp - CTCP',
    CompanyNameEn:
      'Investment and Industrial Development Joint Stock Corporation',
  },
  {
    Code: 'BCP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Enlie',
    CompanyNameEn: 'Enlie Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'BCV',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty cổ phần Du lịch và Thương mại Bằng Giang Cao Bằng - Vimico',
    CompanyNameEn:
      'Vimico - Cao Bang Bang Giang Travel And Trading Joint Stock Company',
  },
  {
    Code: 'BDB',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị Bình Định',
    CompanyNameEn: 'Binh Dinh Book and Equipment Joint Stock Company',
  },
  {
    Code: 'BDG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May mặc Bình Dương',
    CompanyNameEn: 'Protrade Garment Joint Stock Company',
  },
  {
    Code: 'BDT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp và Vật liệu xây dựng Đồng Tháp',
    CompanyNameEn:
      'DongThap Building Materials & Construction Joint Stock Company',
  },
  {
    Code: 'BDW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Bình Định',
    CompanyNameEn: 'Binh Dinh Water Supply and Sewerage Joint Stock Company',
  },
  {
    Code: 'BED',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị trường học Đà Nẵng',
    CompanyNameEn: 'Da Nang Books & Educational Equipment Joint Stock Company',
  },
  {
    Code: 'BEL',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện tử Biên Hòa',
    CompanyNameEn: 'Viettronics Bien Hoa Joint Stock Company',
  },
  {
    Code: 'BFC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón Bình Điền',
    CompanyNameEn: 'Binh Dien Fertilizer Joint Stock Company',
  },
  {
    Code: 'BGW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Bắc Giang',
    CompanyNameEn: 'Bac Giang Clean Water Joint Stock Company',
  },
  {
    Code: 'BHA',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Thủy điện Bắc Hà',
    CompanyNameEn: 'Bac Ha Hydropower Joint Stock Company',
  },
  {
    Code: 'BHC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bê tông Biên Hòa',
    CompanyNameEn: 'Bien Hoa Concrete Joint Stock Company',
  },
  {
    Code: 'BHG',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chè Biển Hồ',
    CompanyNameEn: 'Bien Ho Tea Joint Stock Company',
  },
  {
    Code: 'BHK',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Kim Bài',
    CompanyNameEn: 'Ha Noi - Kim Bai Beer Joint Stock Company',
  },
  {
    Code: 'BHN',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bia - Rượu - Nước giải khát Hà Nội',
    CompanyNameEn: 'Hanoi Beer Alcohol and Beverage Joint Stock Corporation',
  },
  {
    Code: 'BHP',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Hải Phòng',
    CompanyNameEn: 'Ha Noi - Hai Phong Beer Joint Stock Company',
  },
  {
    Code: 'BHT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Bạch Đằng TMC',
    CompanyNameEn: 'Bach Dang TMC Construction Investment Joint Stock Company',
  },
  {
    Code: 'BIC',
    Sector: 'insurances',
    Exchange: 'HSX',
    CompanyNameVn:
      'Tổng Công ty Cổ phần Bảo hiểm Ngân hàng Đầu tư và Phát triển Việt Nam',
    CompanyNameEn: 'BIDV Insurance Corporation',
  },
  {
    Code: 'BID',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Đầu tư và Phát triển Việt Nam',
    CompanyNameEn:
      'Joint Stock Commercial Bank for Investment and Development of Vietnam',
  },
  {
    Code: 'BIG',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần BIG Invest Group',
    CompanyNameEn: 'BIG Invest Group Joint Stock Company',
  },
  {
    Code: 'BII',
    Sector: 'industryRealEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Công nghiệp Bảo Thư',
    CompanyNameEn:
      'Bao Thu Industrial Development and Investment Joint Stock Company',
  },
  {
    Code: 'BIO',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vắc xin và Sinh phẩm Nha Trang',
    CompanyNameEn:
      'Nha Trang Vaccines and Biological Products Joint Stock Company',
  },
  {
    Code: 'BKC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Bắc Kạn',
    CompanyNameEn: 'Bac Kan Minerals Joint Stock Company',
  },
  {
    Code: 'BKG',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư BKG Việt Nam',
    CompanyNameEn: 'BKG Vietnam Investment Joint Stock Company',
  },
  {
    Code: 'BKH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bánh mứt kẹo Hà Nội',
    CompanyNameEn: 'Hanoi Confectionery Joint Stock Company',
  },
  {
    Code: 'BLF',
    Sector: 'fisheriesIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thủy sản Bạc Liêu',
    CompanyNameEn: 'Bac Lieu Fisheries Joint Stock Company',
  },
  {
    Code: 'BLI',
    Sector: 'insurances',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo hiểm Bảo Long',
    CompanyNameEn: 'Bao Long Insurance Corporation',
  },
  {
    Code: 'BLN',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Liên Ninh',
    CompanyNameEn: 'Lien Ninh Transport and Service Joint Stock Company',
  },
  {
    Code: 'BLT',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lương thực Bình Định',
    CompanyNameEn: 'Binh Dinh Food Joint Stock Company',
  },
  {
    Code: 'BLW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Bạc Liêu',
    CompanyNameEn: 'Bac Lieu Water Supply Joint Stock Company',
  },
  {
    Code: 'BMC',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Bình Định',
    CompanyNameEn: 'Binh Dinh Minerals Joint Stock Company',
  },
  {
    Code: 'BMD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Dịch vụ Đô thị Bình Thuận',
    CompanyNameEn: 'Binh Thuan Environmental Urban Service Joint Stock Company',
  },
  {
    Code: 'BMF',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật liệu Xây dựng và Chất đốt Đồng Nai',
    CompanyNameEn: 'Dong Nai Building Material and Fuel Joint Stock Company',
  },
  {
    Code: 'BMG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Bình Minh',
    CompanyNameEn: 'Binh Minh Garment Joint Stock Company',
  },
  {
    Code: 'BMI',
    Sector: 'insurances',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo Minh',
    CompanyNameEn: 'BaoMinh Insurance Corporation',
  },
  {
    Code: 'BMJ',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Miền Đông AHP',
    CompanyNameEn: 'Easterns AHP Minerals Joint Stock Company',
  },
  {
    Code: 'BMN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 715',
    CompanyNameEn: '715 Joint Stock Company',
  },
  {
    Code: 'BMP',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Bình Minh',
    CompanyNameEn: 'Binh Minh Plastics Joint Stock Company',
  },
  {
    Code: 'BMS',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Bảo Minh',
    CompanyNameEn: 'Bao Minh Securities Company Limited',
  },
  {
    Code: 'BMV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bột mỳ Vinafood 1',
    CompanyNameEn: 'Vinafood 1 Flour Joint Stock Company',
  },
  {
    Code: 'BNA',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Sản xuất Bảo Ngọc',
    CompanyNameEn: 'Bao Ngoc Investment Production Corporation',
  },
  {
    Code: 'BNW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Bắc Ninh',
    CompanyNameEn: 'Bac Ninh Clean Water Joint Stock Company',
  },
  {
    Code: 'BOT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần BOT Cầu Thái Hà',
    CompanyNameEn: 'Thai Ha Bridge BOT Joint Stock Company',
  },
  {
    Code: 'BPC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vicem Bao bì Bỉm Sơn',
    CompanyNameEn: 'Vicem Packaging Bimson Joint Stock Company',
  },
  {
    Code: 'BQB',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Quảng Bình',
    CompanyNameEn: 'Hanoi - Quang Binh Beer Joint Stock Company',
  },
  {
    Code: 'BRC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Bến Thành',
    CompanyNameEn: 'Ben Thanh Rubber Joint Stock Company',
  },
  {
    Code: 'BRR',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cao su Bà Rịa',
    CompanyNameEn: 'BaRia Rubber Joint Stock Company',
  },
  {
    Code: 'BRS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Đô thị Bà Rịa',
    CompanyNameEn: 'Ba Ria Urban Service Joint Stock Company',
  },
  {
    Code: 'BSA',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Buôn Đôn',
    CompanyNameEn: 'Buon Don Hydropower Joint Stock Company',
  },
  {
    Code: 'BSC',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Bến Thành',
    CompanyNameEn: 'BenThanh Service Joint Stock Company',
  },
  {
    Code: 'BSD',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia, Rượu Sài Gòn - Đồng Xuân',
    CompanyNameEn: 'Sai Gon - Dong Xuan Beer Alcohol Joint Stock Company',
  },
  {
    Code: 'BSG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xe khách Sài Gòn',
    CompanyNameEn: 'Saigon Passenger Transport Joint Stock Company',
  },
  {
    Code: 'BSH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Hà Nội',
    CompanyNameEn: 'Sai Gon - Ha Noi Beer Corporation',
  },
  {
    Code: 'BSI',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Chứng khoán Ngân hàng Đầu tư và Phát triển Việt Nam',
    CompanyNameEn:
      'Bank for Investment and Development of Viet Nam Joint Stock Securities Company',
  },
  {
    Code: 'BSL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Sông Lam',
    CompanyNameEn: 'Sai Gon - Song Lam Beer Joint Stock Company',
  },
  {
    Code: 'BSP',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Phú Thọ',
    CompanyNameEn: 'Sai Gon - Phu Tho Beer Joint Stock Company',
  },
  {
    Code: 'BSQ',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Quảng Ngãi',
    CompanyNameEn: 'Sai Gon - Quang Ngai Beer Joint Stock Company',
  },
  {
    Code: 'BSR',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lọc Hóa dầu Bình Sơn',
    CompanyNameEn: 'Binh Son Refining and Petrochemical Joint Stock Company',
  },
  {
    Code: 'BST',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị Bình Thuận',
    CompanyNameEn: 'Binh Thuan Book And Equipment Joint Stock Company',
  },
  {
    Code: 'BT1',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bảo vệ thực vật 1 Trung ương',
    CompanyNameEn: 'Central Plant Protection Joint Stock Company No.1',
  },
  {
    Code: 'BT6',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần BETON 6',
    CompanyNameEn: 'BETON 6 Corporation',
  },
  {
    Code: 'BTB',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Thái Bình',
    CompanyNameEn: 'Ha Noi - Thai Binh Beer Joint Stock Company',
  },
  {
    Code: 'BTD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bê tông ly tâm Thủ Đức',
    CompanyNameEn: 'Thuduc Centrifugal Concrete Joint Stock Company',
  },
  {
    Code: 'BTG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Tiền Giang',
    CompanyNameEn: 'Tien Giang Packaging Joint Stock Company',
  },
  {
    Code: 'BTH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo Biến thế và Vật liệu Điện Hà Nội',
    CompanyNameEn:
      'Ha Noi Transformer Manufacturing And Electric Material Joint Stock Company',
  },
  {
    Code: 'BTN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gạch Tuy Nen Bình Định',
    CompanyNameEn: 'Binh Dinh Tunnel Brick Joint Stock Company',
  },
  {
    Code: 'BTP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhiệt điện Bà Rịa',
    CompanyNameEn: 'Ba Ria Thermal Power Joint Stock Company',
  },
  {
    Code: 'BTS',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng VICEM Bút Sơn',
    CompanyNameEn: 'VICEM But Son Cement Joint Stock Company',
  },
  {
    Code: 'BTT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại - Dịch vụ Bến Thành',
    CompanyNameEn: 'Ben Thanh Trading and Service Joint Stock Company',
  },
  {
    Code: 'BTU',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Đô thị Bến Tre',
    CompanyNameEn: 'Ben Tre Urban Project Joint Stock Company',
  },
  {
    Code: 'BTV',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Du lịch Bến Thành',
    CompanyNameEn: 'Ben Thanh Tourist Service Corporation',
  },
  {
    Code: 'BTW',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Bến Thành',
    CompanyNameEn: 'Ben Thanh Water Supply Joint Stock Company',
  },
  {
    Code: 'BVB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Bản Việt',
    CompanyNameEn: 'Viet Capital Commercial Joint Stock Bank',
  },
  {
    Code: 'BVG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Group Bắc Việt',
    CompanyNameEn: 'Bac Viet Group Joint Stock Company',
  },
  {
    Code: 'BVH',
    Sector: 'insurances',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tập đoàn Bảo Việt',
    CompanyNameEn: 'Bao Viet Holdings',
  },
  {
    Code: 'BVL',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần BV Land',
    CompanyNameEn: 'BV Land Joint Stock Company',
  },
  {
    Code: 'BVN',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bông Việt Nam',
    CompanyNameEn: 'Vietnam Cotton Joint Stock Company',
  },
  {
    Code: 'BVS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Bảo Việt',
    CompanyNameEn: 'Bao Viet Securities Company',
  },
  {
    Code: 'BWA',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước và Xây dựng Bảo Lộc',
    CompanyNameEn:
      'Bao Loc Supply Sewerage and Constructon Joint Stock Company',
  },
  {
    Code: 'BWE',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nước - Môi trường Bình Dương',
    CompanyNameEn: 'Binh Duong Water Environment Joint Stock Company',
  },
  {
    Code: 'BWS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp Nước Bà Rịa - Vũng Tàu',
    CompanyNameEn: 'Ba Ria - Vung Tau Water Supply Joint Stock Company',
  },
  {
    Code: 'BXH',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VICEM Bao bì Hải Phòng',
    CompanyNameEn: 'Haiphong Packing VICEM Joint Stock Company',
  },
  {
    Code: 'C12',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cầu 12',
    CompanyNameEn: 'Bridge Joint Stock Company No.12',
  },
  {
    Code: 'C21',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thế kỷ 21',
    CompanyNameEn: 'Century 21 Joint Stock Company',
  },
  {
    Code: 'C22',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 22',
    CompanyNameEn: '22 Joint Stock Company',
  },
  {
    Code: 'C32',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần CIC39',
    CompanyNameEn: 'CIC39 Corporation',
  },
  {
    Code: 'C47',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng 47',
    CompanyNameEn: 'Construction Joint Stock Company 47',
  },
  {
    Code: 'C4G',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập Đoàn Cienco4',
    CompanyNameEn: 'Cienco4 Group Joint Stock Company',
  },
  {
    Code: 'C69',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng 1369',
    CompanyNameEn: '1369 Construction Joint Stock Company',
  },
  {
    Code: 'C92',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Đầu tư 492',
    CompanyNameEn: 'Engineering Construction Joint Stock Company No 492',
  },
  {
    Code: 'CAB',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Truyền hình Cáp Việt Nam',
    CompanyNameEn: 'Vietnam Television Cable Joint Stock Company',
  },
  {
    Code: 'CAD',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế biến và Xuất khẩu Thủy sản Cadovimex',
    CompanyNameEn:
      'Cadovimex Seafood Import-Export And Processing Joint Stock Company',
  },
  {
    Code: 'CAG',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng An Giang',
    CompanyNameEn: 'An Giang Port Joint Stock Company',
  },
  {
    Code: 'CAN',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đồ hộp Hạ Long',
    CompanyNameEn: 'Halong Canned Food Joint Stock Corporation',
  },
  {
    Code: 'CAP',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lâm Nông sản Thực phẩm Yên Bái',
    CompanyNameEn:
      'Yen Bai Joint Stock Forest Agricultural Products And Foodstuffs Company',
  },
  {
    Code: 'CAR',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Tập đoàn Giáo dục Trí Việt',
    CompanyNameEn: 'Tri Viet Education Group Joint Stock Company',
  },
  {
    Code: 'CAT',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy sản Cà Mau',
    CompanyNameEn: 'Ca Mau Joint Stock Seafoods Company',
  },
  {
    Code: 'CAV',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dây cáp điện Việt Nam',
    CompanyNameEn: 'Vietnam Electric Cable Corporation',
  },
  {
    Code: 'CBI',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gang thép Cao Bằng',
    CompanyNameEn: 'Cao Bang Cast Iron and Steel Joint Stock Company',
  },
  {
    Code: 'CBS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Mía đường Cao Bằng',
    CompanyNameEn: 'Cao Bang Sugar Joint Stock Company',
  },
  {
    Code: 'CC1',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Xây dựng số 1 - CTCP',
    CompanyNameEn: 'Construction Corporation No.1 Joint Stock Company',
  },
  {
    Code: 'CC4',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng số 4',
    CompanyNameEn: 'Investment and Construction Joint Stock Company No.4',
  },
  {
    Code: 'CCA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Cần Thơ',
    CompanyNameEn: 'Can Tho Import Export Seafood Joint Stock Company',
  },
  {
    Code: 'CCI',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Phát triển Công nghiệp Thương mại Củ Chi',
    CompanyNameEn:
      'Cuchi Commercial and Industrial Developing Investment Joint Stock Company',
  },
  {
    Code: 'CCL',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư và Phát triển Đô thị Dầu khí Cửu Long',
    CompanyNameEn:
      'Cuu Long Petro Urban Development And Investment Corporation',
  },
  {
    Code: 'CCM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản và Xi măng Cần Thơ',
    CompanyNameEn: 'Can Tho Mineral And Cement Joint Stock Company',
  },
  {
    Code: 'CCP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Cửa Cấm Hải Phòng',
    CompanyNameEn: 'Hai Phong Cua Cam Port Joint Stock Company',
  },
  {
    Code: 'CCR',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Cam Ranh',
    CompanyNameEn: 'Cam Ranh Port Joint Stock Company',
  },
  {
    Code: 'CCT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Cần Thơ',
    CompanyNameEn: 'Can Tho Port Joint Stock Company',
  },
  {
    Code: 'CCV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Tư vấn Xây dựng Công nghiệp và Đô thị Việt Nam',
    CompanyNameEn: 'VCC Engineering Consultants Joint - Stock Company',
  },
  {
    Code: 'CDC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chương Dương',
    CompanyNameEn: 'Chuong Duong Corporation',
  },
  {
    Code: 'CDG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cầu Đuống',
    CompanyNameEn: 'CauDuong Joint Stock Company',
  },
  {
    Code: 'CDH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Công trình Công cộng và Dịch vụ Du lịch Hải Phòng',
    CompanyNameEn:
      'Hai Phong Public Works and Tourism Services Joint Stock Company',
  },
  {
    Code: 'CDN',
    Sector: 'shippingIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Đà Nẵng',
    CompanyNameEn: 'DaNang Port Joint Stock Company',
  },
  {
    Code: 'CDO',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Thiết kế và Phát triển Đô thị',
    CompanyNameEn:
      'Consultancy Design and Urban Development Joint Stock Company',
  },
  {
    Code: 'CDP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Trung ương Codupha',
    CompanyNameEn: 'Codupha Central Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'CDR',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Cao su Đồng Nai',
    CompanyNameEn: 'Dong Nai Rubber Construction Joint Stock Company',
  },
  {
    Code: 'CE1',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Thiết bị Công nghiệp CIE1',
    CompanyNameEn:
      'Construction And Industry Equipment Joint Stock Company - CIE1',
  },
  {
    Code: 'CEG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Xây dựng và Thiết bị Công nghiệp',
    CompanyNameEn: 'Construction and Industry Equipment Group Corporation',
  },
  {
    Code: 'CEN',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần CENCON Việt Nam',
    CompanyNameEn: 'CENCON Vietnam Joint Stock Company',
  },
  {
    Code: 'CEO',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn C.E.O',
    CompanyNameEn: 'CEO Group Joint Stock Company',
  },
  {
    Code: 'CET',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần HTC Holding',
    CompanyNameEn: 'HTC Holding Joint Stock Company',
  },
  {
    Code: 'CFM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư CFM',
    CompanyNameEn: 'CFM Investment Joint Stock Company',
  },
  {
    Code: 'CFV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà phê Thắng Lợi',
    CompanyNameEn: 'Thang Loi Coffee Joint Stock Company',
  },
  {
    Code: 'CGV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vinaceglass',
    CompanyNameEn: 'Vinaceglass Joint Stock Company',
  },
  {
    Code: 'CH5',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 5 Hà Nội',
    CompanyNameEn: 'Hanoi Construction Joint Stock Company No.5',
  },
  {
    Code: 'CHC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cẩm Hà',
    CompanyNameEn: 'Cam Ha Joint Stock Company',
  },
  {
    Code: 'CHP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Miền Trung',
    CompanyNameEn: 'Central Hydropower Joint Stock Company',
  },
  {
    Code: 'CHS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chiếu sáng Công cộng Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Ho Chi Minh City Public Lighting Joint Stock Company',
  },
  {
    Code: 'CI5',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng số 5',
    CompanyNameEn: 'No.5 Construction Investment Joint Stock Company',
  },
  {
    Code: 'CIA',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ Phần Dịch Vụ Sân Bay Quốc Tế Cam Ranh',
    CompanyNameEn:
      'Cam Ranh International Airport Services Joint Stock Company',
  },
  {
    Code: 'CID',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Phát triển Cơ sở Hạ tầng',
    CompanyNameEn:
      'Contruction and Infrastructure Development Joint Stock Corporation',
  },
  {
    Code: 'CIG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần COMA 18',
    CompanyNameEn: 'COMA 18 Joint Stock Company',
  },
  {
    Code: 'CII',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Hạ tầng Kỹ thuật Thành phố Hồ Chí Minh',
    CompanyNameEn:
      'Ho Chi Minh City Infrastructure Investment Joint Stock Company',
  },
  {
    Code: 'CIP',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp và Sản xuất Công nghiệp',
    CompanyNameEn: 'Construction and Industrial Production Joint Stock Company',
  },
  {
    Code: 'CJC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Miền Trung',
    CompanyNameEn: 'Central Area Electrical Mechanical Joint Stock Company',
  },
  {
    Code: 'CKA',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí An Giang',
    CompanyNameEn: 'An Giang Mechanical Joint Stock Company',
  },
  {
    Code: 'CKD',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Đông Anh Licogi',
    CompanyNameEn: 'Dong Anh Licogi Mechanical Joint Stock Company',
  },
  {
    Code: 'CKG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Tư vấn Đầu tư Xây dựng Kiên Giang',
    CompanyNameEn: 'Kien Giang Construction Investment Consultancy Corporation',
  },
  {
    Code: 'CKV',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần CokyVina',
    CompanyNameEn: 'CokyVina Joint Stock Company',
  },
  {
    Code: 'CLC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cát Lợi',
    CompanyNameEn: 'Cat Loi Joint Stock Company',
  },
  {
    Code: 'CLG',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Nhà đất Cotec',
    CompanyNameEn:
      'Cotec Investment and Land - house Development Joint Stock Company',
  },
  {
    Code: 'CLH',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng La Hiên VVMI',
    CompanyNameEn: 'La Hien Cement Joint Stock Company',
  },
  {
    Code: 'CLL',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Cát Lái',
    CompanyNameEn: 'Cat Lai Port Joint Stock Company',
  },
  {
    Code: 'CLM',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Than - Vinacomin',
    CompanyNameEn: 'Vinacomin - Coal Import Export Joint Stock Company',
  },
  {
    Code: 'CLW',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Chợ Lớn',
    CompanyNameEn: 'ChoLon Water Supply Joint Stock Company',
  },
  {
    Code: 'CLX',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Xuất nhập khẩu và Đầu tư Chợ Lớn (CHOLIMEX)',
    CompanyNameEn:
      'Cho Lon Investment And Import Export Corporation (CHOLIMEX)',
  },
  {
    Code: 'CMC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư CMC',
    CompanyNameEn: 'CMC Investment Joint Stock Company',
  },
  {
    Code: 'CMD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Vật liệu xây dựng và Trang trí nội thất TP Hồ Chí Minh',
    CompanyNameEn:
      'Construction Materials and Interior Decoration Joint Stock Company',
  },
  {
    Code: 'CMF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Cholimex',
    CompanyNameEn: 'Cholimex Food Joint Stock Company',
  },
  {
    Code: 'CMG',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Công nghệ CMC',
    CompanyNameEn: 'CMC Corporation',
  },
  {
    Code: 'CMI',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần CMISTONE Việt Nam',
    CompanyNameEn: 'CMISTONE Vietnam Stock Company',
  },
  {
    Code: 'CMK',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Mạo Khê - Vinacomin',
    CompanyNameEn: 'Mao Khe - Vinacomin Mechanical Joint Stock Company',
  },
  {
    Code: 'CMM',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Camimex',
    CompanyNameEn: 'CAMIMEX JOINT STOCK COMPANY',
  },
  {
    Code: 'CMN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lương thực Thực phẩm Colusa - Miliket',
    CompanyNameEn: 'Colusa - Miliket Foodstuff Joint Stock Company',
  },
  {
    Code: 'CMP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Chân Mây',
    CompanyNameEn: 'ChanMay Port Joint Stock Company',
  },
  {
    Code: 'CMS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn CMH Việt Nam',
    CompanyNameEn: 'CMH Viet Nam Group Joint Stock Company',
  },
  {
    Code: 'CMT',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ mạng và Truyền thông',
    CompanyNameEn: 'Information and Networking technology Joint stock Company',
  },
  {
    Code: 'CMV',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thương nghiệp Cà Mau',
    CompanyNameEn: 'Ca Mau Trading Joint Stock Company',
  },
  {
    Code: 'CMW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Cà Mau',
    CompanyNameEn: 'Ca Mau Water Supply Joint Stock Company',
  },
  {
    Code: 'CMX',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần CAMIMEX Group',
    CompanyNameEn: 'CAMIMEX Group Joint Stock Company',
  },
  {
    Code: 'CNA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng công ty Chè Nghệ An',
    CompanyNameEn: 'Nghe An Tea Corporation Joint Stock Company',
  },
  {
    Code: 'CNC',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ cao Traphaco',
    CompanyNameEn: 'Traphaco High Tech Joint Stock Company',
  },
  {
    Code: 'CNG',
    Sector: 'energy',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần CNG Việt Nam',
    CompanyNameEn: 'Clean Natural Gas Vietnam Joint Stock Company',
  },
  {
    Code: 'CNN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Tư vấn Công nghệ, Thiết bị và Kiểm định Xây dựng - CONINCO',
    CompanyNameEn:
      'Consultant and Inspection Joint Stock Company of Construction Technology and Equipment',
  },
  {
    Code: 'CNT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn CNT',
    CompanyNameEn: 'CNT Group Corporation',
  },
  {
    Code: 'COM',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vật tư Xăng dầu',
    CompanyNameEn: 'Materials - Petroleum Joint Stock Company',
  },
  {
    Code: 'CPA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà phê Phước An',
    CompanyNameEn: 'Phuoc An Coffee Joint Stock Company',
  },
  {
    Code: 'CPC',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thuốc sát trùng Cần Thơ',
    CompanyNameEn: 'Cantho Pesticides Joint Stock Company',
  },
  {
    Code: 'CPH',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phục vụ Mai táng Hải Phòng',
    CompanyNameEn: 'Haiphong Funeral Services Joint Stock Company',
  },
  {
    Code: 'CPI',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Cảng Cái Lân',
    CompanyNameEn: 'Cai Lan Port Investment Joint Stock Company',
  },
  {
    Code: 'CQN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Port Joint Stock Company',
  },
  {
    Code: 'CQT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Quán Triều VVMI',
    CompanyNameEn: 'VVMI Quan Trieu Cement Joint Stock Company',
  },
  {
    Code: 'CRC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Create Capital Việt Nam',
    CompanyNameEn: 'Create Capital Viet Nam Joint Stock Company',
  },
  {
    Code: 'CRE',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản Thế Kỷ',
    CompanyNameEn: 'Century Land Joint Stock Company',
  },
  {
    Code: 'CSC',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn COTANA',
    CompanyNameEn: 'COTANA GROUP Joint Stock Company',
  },
  {
    Code: 'CSI',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Kiến thiết Việt Nam',
    CompanyNameEn: 'Vietnam Construction Securities Joint Stock Company',
  },
  {
    Code: 'CSM',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Cao su Miền Nam',
    CompanyNameEn: 'The Southern Rubber Industry Joint Stock Company',
  },
  {
    Code: 'CST',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Than Cao Sơn - TKV',
    CompanyNameEn: 'Cao Son Coal Joint Stock Company',
  },
  {
    Code: 'CSV',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hóa chất Cơ bản miền Nam',
    CompanyNameEn: 'South Basic Chemicals Joint Stock Company',
  },
  {
    Code: 'CT3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng công trình 3',
    CompanyNameEn: 'Project 3 Construction And Investment Joint Stock Company',
  },
  {
    Code: 'CT6',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình 6',
    CompanyNameEn: 'Construction Joint Stock Company No.6',
  },
  {
    Code: 'CTA',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vinavico',
    CompanyNameEn: 'Vinavico Joint Stock Company',
  },
  {
    Code: 'CTB',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo Bơm Hải Dương',
    CompanyNameEn: 'Hai Duong Pump Manufacturing Joint Stock Company',
  },
  {
    Code: 'CTC',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hoàng Kim Tây Nguyên',
    CompanyNameEn: 'Hoang Kim Tay Nguyen Group Joint Stock Company',
  },
  {
    Code: 'CTD',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Coteccons',
    CompanyNameEn: 'Coteccons Construction Joint Stock Company',
  },
  {
    Code: 'CTF',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần City Auto',
    CompanyNameEn: 'City Auto Corporation',
  },
  {
    Code: 'CTG',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Công thương Việt Nam',
    CompanyNameEn: 'Vietnam Joint Stock Commercial Bank For Industry and Trade',
  },
  {
    Code: 'CTI',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Cường Thuận IDICO',
    CompanyNameEn: 'Cuong Thuan IDICO Development Investment Corporation',
  },
  {
    Code: 'CTN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Công trình ngầm',
    CompanyNameEn:
      'Underground Works Construction Joint Stock Company - VINAVICO',
  },
  {
    Code: 'CTP',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Minh Khang Capital Trading Public',
    CompanyNameEn: 'Minh Khang Capital Trading Public Joint Stock Company',
  },
  {
    Code: 'CTR',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Công trình Viettel',
    CompanyNameEn: 'Viettel Construction Joint Stock Corporation',
  },
  {
    Code: 'CTS',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Ngân hàng Công thương Việt Nam',
    CompanyNameEn:
      'Vietnam Bank For Industry And Trade Securities Joint Stock Company',
  },
  {
    Code: 'CTT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo máy Vinacomin',
    CompanyNameEn: 'Vinacomin-Machinery Joint Stock Company',
  },
  {
    Code: 'CTW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Cần Thơ',
    CompanyNameEn: 'Can Tho Water Supply - Sewerage Joint Stock Company',
  },
  {
    Code: 'CTX',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn:
      'Tổng Công ty Cổ phần Đầu tư Xây dựng và Thương mại Việt Nam',
    CompanyNameEn:
      'Vietnam Investment Construction And Trading Joint Stock Corporation',
  },
  {
    Code: 'CVN',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vinam',
    CompanyNameEn: 'Vinam Joint Stock Company',
  },
  {
    Code: 'CVP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Cảng Cửa Việt',
    CompanyNameEn: 'Cua Viet Port Joint Stock Company',
  },
  {
    Code: 'CVT',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần CMC',
    CompanyNameEn: 'CMC Joint Stock Company',
  },
  {
    Code: 'CX8',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây lắp Constrexim số 8',
    CompanyNameEn:
      'Constrexim No. 8 Investment and Construction Joint Stock Company',
  },
  {
    Code: 'CYC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gạch men Chang Yih',
    CompanyNameEn: 'Chang Yih Ceramic Joint Stock Company',
  },
  {
    Code: 'D11',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Địa ốc 11',
    CompanyNameEn: 'Real Estate 11 Joint Stock Company',
  },
  {
    Code: 'D2D',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Đô thị Công nghiệp số 2',
    CompanyNameEn: 'Industrial Urban Development Joint - Stock Company No.2',
  },
  {
    Code: 'DAC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 382 Đông Anh',
    CompanyNameEn: '382 Dong Anh Joint -Stock Company',
  },
  {
    Code: 'DAD',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Giáo dục Đà Nẵng',
    CompanyNameEn:
      'Danang Education Development And Investment Joint Stock Company',
  },
  {
    Code: 'DAE',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách Giáo dục tại TP.Đà Nẵng',
    CompanyNameEn: 'Educational Book Joint Stock Company in Da Nang City',
  },
  {
    Code: 'DAG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Nhựa Đông Á',
    CompanyNameEn: 'Dong A Plastic Group Joint Stock Company',
  },
  {
    Code: 'DAH',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Khách sạn Đông Á',
    CompanyNameEn: 'Dong A Hotel Group Joint Stock Company',
  },
  {
    Code: 'DAN',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Danapha',
    CompanyNameEn: 'Danapha Pharmaceutical joint stock company',
  },
  {
    Code: 'DAS',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Máy - Thiết bị Dầu khí Đà Nẵng',
    CompanyNameEn:
      'Danang Petroleum Machinery - Technology Joint Stock Company',
  },
  {
    Code: 'DAT',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty cổ phần Đầu tư Du lịch và Phát triển Thủy sản',
    CompanyNameEn: 'Travel Investment and Seafood Development Corporation',
  },
  {
    Code: 'DBC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Dabaco Việt Nam',
    CompanyNameEn: 'DABACO Group',
  },
  {
    Code: 'DBD',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược - Trang thiết bị y tế Bình Định',
    CompanyNameEn:
      'Binh Dinh Pharmaceutical and Medical Equipment Joint Stock Company',
  },
  {
    Code: 'DBM',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược - Vật tư Y tế Đắk Lắk',
    CompanyNameEn:
      'Dak Lak Pharmaceutical Medical Equipment Joint Stock Company',
  },
  {
    Code: 'DBT',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Bến Tre',
    CompanyNameEn: 'Ben Tre Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DBW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Điện Biên',
    CompanyNameEn: 'Dien Bien Water Supply Joint Stock Company',
  },
  {
    Code: 'DC1',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Xây dựng số 1',
    CompanyNameEn:
      'Development Investment Construction Number 1 Joint Stock Company',
  },
  {
    Code: 'DC2',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển - Xây dựng (DIC) số 2',
    CompanyNameEn:
      'Development Investment Construction Number 2 Joint Stock Company',
  },
  {
    Code: 'DC4',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng DIC Holdings',
    CompanyNameEn: 'DIC Holdings Construction Joint Stock Company',
  },
  {
    Code: 'DCF',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Thiết kế số 1',
    CompanyNameEn: 'Design and Construction Joint Stock Company No.1',
  },
  {
    Code: 'DCG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty May Đáp Cầu',
    CompanyNameEn: 'DapCau Garment Corporation Joint Stock Company',
  },
  {
    Code: 'DCH',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Địa chính Hà Nội',
    CompanyNameEn: 'Ha Noi Cadastral Survey Joint Stock Company',
  },
  {
    Code: 'DCL',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Cửu Long',
    CompanyNameEn: 'Cuu Long Pharmaceutical Joint Stock Corporation',
  },
  {
    Code: 'DCM',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón Dầu Khí Cà Mau',
    CompanyNameEn: 'PetroVietnam Camau Fertilizer Joint Stock Company',
  },
  {
    Code: 'DCR',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gạch men COSEVCO',
    CompanyNameEn: 'COSEVCO Ceramic Tiles Joint Stock Company',
  },
  {
    Code: 'DCS',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đại Châu',
    CompanyNameEn: 'Dai Chau Group Joint Stock Company',
  },
  {
    Code: 'DCT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tấm lợp Vật liệu Xây dựng Đồng Nai',
    CompanyNameEn:
      'Dong Nai Roofsheet & Construction Material Joint Stock Company',
  },
  {
    Code: 'DDG',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Công nghiệp Xuất nhập khẩu Đông Dương',
    CompanyNameEn:
      'Indochine Import Export Investment Industrial Joint Stock Company',
  },
  {
    Code: 'DDH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đảm bảo giao thông đường thủy Hải Phòng',
    CompanyNameEn: 'Hai Phong Waterway Traffic Assurance Joint Stock Company',
  },
  {
    Code: 'DDM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hàng hải Đông Đô',
    CompanyNameEn: 'Dong Do Marine Joint Stock Company',
  },
  {
    Code: 'DDN',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược - Thiết bị y tế Đà Nẵng',
    CompanyNameEn:
      'Danang Pharmaceutical Medical Equipment Joint Stock Company',
  },
  {
    Code: 'DDV',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần DAP - Vinachem',
    CompanyNameEn: 'DAP - Vinachem Joint Stock Company',
  },
  {
    Code: 'DFC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xích líp Đông Anh',
    CompanyNameEn: 'Dong Anh C&F Joint Stock Company',
  },
  {
    Code: 'DFF',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đua Fat',
    CompanyNameEn: 'Dua Fat Group Joint Stock Company',
  },
  {
    Code: 'DGC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hóa chất Đức Giang',
    CompanyNameEn: 'Ducgiang Chemicals Group Joint Stock Company',
  },
  {
    Code: 'DGT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Giao thông Đồng Nai',
    CompanyNameEn: 'DONA Transportation Construction Joint Stock Company',
  },
  {
    Code: 'DGW',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thế Giới Số',
    CompanyNameEn: 'Digiworld Corporation',
  },
  {
    Code: 'DHA',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hóa An',
    CompanyNameEn: 'Hoa An Joint Stock Company',
  },
  {
    Code: 'DHB',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phân đạm và Hóa chất Hà Bắc',
    CompanyNameEn:
      'HaBac Nitrogenous Ferlitizer & Chemicals Joint Stock Company',
  },
  {
    Code: 'DHC',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đông Hải Bến Tre',
    CompanyNameEn: 'Dong Hai Joint Stock Company of Bentre',
  },
  {
    Code: 'DHD',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Vật Tư Y Tế Hải Dương',
    CompanyNameEn:
      'Hai Duong Pharmaceutical Medical Material Joint Stock Company',
  },
  {
    Code: 'DHG',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược Hậu Giang',
    CompanyNameEn: 'Hau Giang Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DHM',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Thương mại & Khai thác Khoáng sản Dương Hiếu',
    CompanyNameEn: 'Duong Hieu Trading and Mining Joint Stock Company',
  },
  {
    Code: 'DHN',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Hà Nội',
    CompanyNameEn: 'Ha Noi Pharmar Joint Stock Company',
  },
  {
    Code: 'DHP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Điện cơ Hải Phòng',
    CompanyNameEn: 'Hai Phong Electrical Mechanical Joint Stock Company',
  },
  {
    Code: 'DHT',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Hà Tây',
    CompanyNameEn: 'Hatay Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DIC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Thương mại DIC',
    CompanyNameEn: 'DIC Investment and Trading Joint Stock Company',
  },
  {
    Code: 'DID',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần DIC - Đồng Tiến',
    CompanyNameEn: 'DIC - Dong Tien Joint Stock Company',
  },
  {
    Code: 'DIG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Đầu tư Phát triển Xây dựng',
    CompanyNameEn:
      'Development Investment Construction Joint Stock Corporation',
  },
  {
    Code: 'DIH',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Xây dựng - Hội An',
    CompanyNameEn:
      'Development Investment Construction Hoi An Joint Stock Company',
  },
  {
    Code: 'DKC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chợ Lạng Sơn',
    CompanyNameEn: 'Lang Son Market Joint Stock Company',
  },
  {
    Code: 'DL1',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Alpha Seven',
    CompanyNameEn: 'Alpha Seven Group Joint Stock Company',
  },
  {
    Code: 'DLD',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Đắk Lắk',
    CompanyNameEn: 'DakLak Tourist Joint Stock Company',
  },
  {
    Code: 'DLG',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đức Long Gia Lai',
    CompanyNameEn: 'Duc Long Gia Lai Group Joint Stock Company',
  },
  {
    Code: 'DLR',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Địa ốc Đà Lạt',
    CompanyNameEn: 'Dalat Real Estate Joint Stock Company',
  },
  {
    Code: 'DLT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch và Thương mại - Vinacomin',
    CompanyNameEn: 'Vinacomin - Tourism & Trading Joint Stock Company',
  },
  {
    Code: 'DM7',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dệt may 7',
    CompanyNameEn: 'Det May 7 Joint Stock Company',
  },
  {
    Code: 'DMC',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Y tế DOMESCO',
    CompanyNameEn: 'Domesco Medical Import - Export Joint Stock Corporation',
  },
  {
    Code: 'DMN',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Domenal',
    CompanyNameEn: 'Domenal Joint Stock Company',
  },
  {
    Code: 'DNA',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện nước An Giang',
    CompanyNameEn: 'An Giang Power and Water Supply Joint Stock Company',
  },
  {
    Code: 'DNC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Điện nước Lắp máy Hải Phòng',
    CompanyNameEn:
      'Haiphong Electricity Water Machine Assembly Joint Stock Company',
  },
  {
    Code: 'DND',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng và Vật liệu Đồng Nai',
    CompanyNameEn:
      'Dong Nai Material and Building Investment Joint Stock Company',
  },
  {
    Code: 'DNE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Đà Nẵng',
    CompanyNameEn: 'Danang Urban Environment Joint Stock Company',
  },
  {
    Code: 'DNH',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Đa Nhim - Hàm Thuận - Đa Mi',
    CompanyNameEn:
      'Da Nhim - Ham Thuan - Da Mi Hydro Power Joint Stock Company',
  },
  {
    Code: 'DNL',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Logistics Cảng Đà Nẵng',
    CompanyNameEn: 'Da Nang Port Logistics Joint Stock Company',
  },
  {
    Code: 'DNM',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Y tế Danameco',
    CompanyNameEn: 'Danameco Medical Joint Stock Corporation',
  },
  {
    Code: 'DNN',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Đà Nẵng',
    CompanyNameEn: 'Da Nang Water Supply Joint Stock Company',
  },
  {
    Code: 'DNP',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần DNP Holding',
    CompanyNameEn: 'DNP Holding Joint Stock Company',
  },
  {
    Code: 'DNT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Đồng Nai',
    CompanyNameEn: 'Dong Nai Tourist Joint Stock Company',
  },
  {
    Code: 'DNW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Đồng Nai',
    CompanyNameEn: 'Dong Nai Water Joint Stock Company',
  },
  {
    Code: 'DOC',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật tư nông nghiệp Đồng Nai',
    CompanyNameEn: 'Dong Nai Joint Stock Company of Agricultural Material',
  },
  {
    Code: 'DOP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Xăng dầu Đồng Tháp',
    CompanyNameEn: 'DongThap Petroleum Transportation Joint Stock Company',
  },
  {
    Code: 'DP1',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Trung ương CPC1',
    CompanyNameEn: 'Central Pharmaceutical CPC1. JSC ',
  },
  {
    Code: 'DP2',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Trung ương 2',
    CompanyNameEn: 'Central Phamarceutical Joint Stock Company No 2',
  },
  {
    Code: 'DP3',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ Phần Dược Phẩm Trung Ương 3',
    CompanyNameEn: 'Central Phamarceutical Joint Stock Company No 3',
  },
  {
    Code: 'DPC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Đà Nẵng',
    CompanyNameEn: 'Da Nang Plastic Joint Stock Company',
  },
  {
    Code: 'DPD',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Cao su Đồng Phú - Đắk Nông',
    CompanyNameEn: 'Dong Phu - Dak Nong Rubber Joint Stock Company',
  },
  {
    Code: 'DPG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đạt Phương',
    CompanyNameEn: 'Dat Phuong Group Joint Stock Company',
  },
  {
    Code: 'DPH',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Hải Phòng',
    CompanyNameEn: 'Hai Phong Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DPM',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn:
      'Tổng Công ty Cổ phần Phân bón và Hóa chất Dầu khí - Công ty Cổ phần',
    CompanyNameEn: 'Petrovietnam Fertilizer and Chemicals Corporation',
  },
  {
    Code: 'DPP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Đồng Nai',
    CompanyNameEn: 'Dong Nai Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DPR',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Đồng Phú',
    CompanyNameEn: 'Dong Phu Rubber Joint Stock Company',
  },
  {
    Code: 'DPS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Sóc Sơn',
    CompanyNameEn: 'Soc Son Development Investment Joint Stock Company',
  },
  {
    Code: 'DQC',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bóng đèn Điện Quang',
    CompanyNameEn: 'Dien Quang Joint Stock Company',
  },
  {
    Code: 'DRC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Đà Nẵng',
    CompanyNameEn: 'Da Nang Rubber Joint Stock Company',
  },
  {
    Code: 'DRG',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cao su Đắk Lắk',
    CompanyNameEn: 'Dak Lak Rubber Joint Stock Company',
  },
  {
    Code: 'DRH',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần DRH Holdings',
    CompanyNameEn: 'DRH Holdings JSC',
  },
  {
    Code: 'DRI',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Cao su Đắk Lắk',
    CompanyNameEn: 'DakLak Rubber Investment Joint Stock Company',
  },
  {
    Code: 'DRL',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện - Điện lực 3',
    CompanyNameEn: 'Hydro Power Joint Stock Company - Power No.3',
  },
  {
    Code: 'DS3',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Quản lý Đường sông số 3',
    CompanyNameEn: 'Inland Waterway Management Joint Stock Company No3',
  },
  {
    Code: 'DSC',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán DSC',
    CompanyNameEn: 'DSC Joint Stock Company',
  },
  {
    Code: 'DSD',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần DHC Suối Đôi',
    CompanyNameEn: 'DHC Suoi Doi Corporation',
  },
  {
    Code: 'DSG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kính Đáp Cầu',
    CompanyNameEn: 'Dap Cau Sheet Glass Joint Stock Company',
  },
  {
    Code: 'DSN',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công viên nước Đầm Sen',
    CompanyNameEn: 'Dam Sen Water Park Corporation',
  },
  {
    Code: 'DSP',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Du lịch Phú Thọ',
    CompanyNameEn: 'Phu Tho Tourist Service Joint Stock Company',
  },
  {
    Code: 'DST',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Sao Thăng Long',
    CompanyNameEn: 'Sao Thang Long Investment Joint Stock Company',
  },
  {
    Code: 'DSV',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đường sắt Vĩnh Phú',
    CompanyNameEn: 'Vinh Phu Railway Joint Stock Company',
  },
  {
    Code: 'DTA',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đệ Tam',
    CompanyNameEn: 'De Tam Joint Stock Company',
  },
  {
    Code: 'DTB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Đô thị Bảo Lộc',
    CompanyNameEn: 'Bao Loc Urban Project Joint Stock Company',
  },
  {
    Code: 'DTC',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Viglacera Đông Triều',
    CompanyNameEn: 'Dong Trieu Viglacera Joint Stock Company',
  },
  {
    Code: 'DTD',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Thành Đạt',
    CompanyNameEn: 'Thanh Dat Investment Development Joint Stock Company',
  },
  {
    Code: 'DTE',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Năng lượng Đại Trường Thành Holdings',
    CompanyNameEn:
      'Dai Truong Thanh Holdings Energy Investment Joint Stock Company',
  },
  {
    Code: 'DTG',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Tipharco',
    CompanyNameEn: 'Tipharco Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DTH',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược - Vật tư Y tế Thanh Hóa',
    CompanyNameEn:
      'Thanh Hoa Medical Materials Pharmaceutial Joint Stock Company',
  },
  {
    Code: 'DTI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Đức Trung',
    CompanyNameEn: 'Duc Trung Investment Join Stock Company',
  },
  {
    Code: 'DTK',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Điện lực TKV - Công ty Cổ phần',
    CompanyNameEn: 'Vinacomin - Power Holding Corporation',
  },
  {
    Code: 'DTL',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đại Thiên Lộc',
    CompanyNameEn: 'Dai Thien Loc Corporation',
  },
  {
    Code: 'DTP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm CPC1 Hà Nội',
    CompanyNameEn: 'Ha Noi CPC1 Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'DTT',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Kỹ nghệ Đô Thành',
    CompanyNameEn: 'Do Thanh Technology Corporation',
  },
  {
    Code: 'DTV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển điện Trà Vinh',
    CompanyNameEn: 'Tra Vinh Electric Development Joint Stock Corporation',
  },
  {
    Code: 'DUS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Đô thị Đà Lạt',
    CompanyNameEn: 'Da Lat Urban Server Joint Stock Company',
  },
  {
    Code: 'DVC',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại dịch vụ Tổng Hợp Cảng Hải Phòng',
    CompanyNameEn: 'Hai Phong Port Trading and Service Joint Stock Company',
  },
  {
    Code: 'DVG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Sơn Đại Việt',
    CompanyNameEn: 'Dai Viet Paint Group Joint Stock Company',
  },
  {
    Code: 'DVM',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược liệu Việt Nam',
    CompanyNameEn: 'Vietnam Medicinal Materials Joint Stock Company',
  },
  {
    Code: 'DVN',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Dược Việt Nam - CTCP',
    CompanyNameEn: 'Vietnam Pharmaceutical Corporation (VINAPHARM)',
  },
  {
    Code: 'DVP',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Cảng Đình Vũ',
    CompanyNameEn: 'Dinh Vu Port Investment & Development Joint Stock Company',
  },
  {
    Code: 'DVW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ và Xây dựng cấp nước Đồng Nai',
    CompanyNameEn:
      'Dong Nai Water Supply Construction and Services Joint Stock Company',
  },
  {
    Code: 'DWC',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Đắk Lắk',
    CompanyNameEn: 'Daklak Water Supply Joint Stock Company',
  },
  {
    Code: 'DWS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước và Môi trường Đô thị Đồng Tháp',
    CompanyNameEn:
      'Dong Thap Water Supply &amp; Urban Environment Joint - Stock Company',
  },
  {
    Code: 'DXG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đất Xanh',
    CompanyNameEn: 'Dat Xanh Group Joint Stock Company',
  },
  {
    Code: 'DXL',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch và Xuất nhập khẩu Lạng Sơn',
    CompanyNameEn: 'Lang Son Tourism and Import - Export Joint Stock Company',
  },
  {
    Code: 'DXP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Đoạn Xá',
    CompanyNameEn: 'Doan Xa Port Joint Stock Company',
  },
  {
    Code: 'DXS',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Bất động sản Đất Xanh',
    CompanyNameEn: 'Dat Xanh Real Estate Services Joint Stock Company',
  },
  {
    Code: 'DXV',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần VICEM Vật liệu Xây dựng Đà Nẵng',
    CompanyNameEn: 'Danang Building Material VICEM Joint Stock Company',
  },
  {
    Code: 'DZM',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Dzĩ An',
    CompanyNameEn: 'Dzi An Mechanoelectric Joint Stock Company',
  },
  {
    Code: 'E12',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây Dựng Điện VNECO 12',
    CompanyNameEn: 'VNECO 12 Electricity Construction Joint-stock Company',
  },
  {
    Code: 'E29',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Đầu tư Xây dựng và Kỹ thuật 29',
    CompanyNameEn:
      '29 Investment Construction And Engineering Joint Stcock Company',
  },
  {
    Code: 'EBS',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách Giáo dục tại Thành phố Hà Nội',
    CompanyNameEn: 'Educational Book Joint Stock Company In Hanoi city',
  },
  {
    Code: 'ECI',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn ECI',
    CompanyNameEn: 'ECI Group Joint Stock Company',
  },
  {
    Code: 'EFI',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Tài chính Giáo dục',
    CompanyNameEn: 'Education Financial Investment Joint Stock Company',
  },
  {
    Code: 'EIB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Xuất nhập khẩu Việt Nam',
    CompanyNameEn: 'Vietnam Export Import Commercial Joint Stock Bank',
  },
  {
    Code: 'EIC',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần EVN Quốc Tế',
    CompanyNameEn: 'EVN International Joint Stock Company',
  },
  {
    Code: 'EID',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Giáo dục Hà Nội',
    CompanyNameEn:
      'Hanoi Education Development and Investment Joint Stock Company',
  },
  {
    Code: 'EIN',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư - Thương Mại - Dịch vụ Điện lực',
    CompanyNameEn: 'Power Investment - Trade - Service Joint Stock Company',
  },
  {
    Code: 'ELC',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ - Viễn thông ELCOM',
    CompanyNameEn: 'ELCOM Technology Communications Corporation',
  },
  {
    Code: 'EMC',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Thủ Đức',
    CompanyNameEn: 'Thu Duc Electro Mechanical Joint Stock Company',
  },
  {
    Code: 'EME',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện Cơ',
    CompanyNameEn: 'Electro Mechanical Corporation',
  },
  {
    Code: 'EMG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị Phụ tùng Cơ điện',
    CompanyNameEn:
      'Electrical Mechanical Equipment and Spare Parts Joint Stock Company',
  },
  {
    Code: 'EMS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Chuyển phát nhanh Bưu điện - Công ty Cổ phần',
    CompanyNameEn: 'VN Post Express Joint Stock Corporation',
  },
  {
    Code: 'EPC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà Phê Ea Pốk',
    CompanyNameEn: 'Ea Pok Coffee Joint Stock Company',
  },
  {
    Code: 'EPH',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Xuất bản Giáo dục Hà Nội',
    CompanyNameEn: 'Ha Noi Education Publishing Services Joint Stock Company',
  },
  {
    Code: 'EVE',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Everpia',
    CompanyNameEn: 'Everpia Joint Stock Company',
  },
  {
    Code: 'EVF',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Tài chính Cổ phần Điện Lực',
    CompanyNameEn: 'EVN Finance Joint Stock Company',
  },
  {
    Code: 'EVG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Everland',
    CompanyNameEn: 'Everland Group Joint Stock Company',
  },
  {
    Code: 'EVS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Everest',
    CompanyNameEn: 'Everest Securities Joint Stock Company',
  },
  {
    Code: 'FBA',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Quốc tế FBA',
    CompanyNameEn: 'FBA International Group',
  },
  {
    Code: 'FBC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Phổ Yên',
    CompanyNameEn: 'Pho Yen Mechanical Joint Stock Company',
  },
  {
    Code: 'FCC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Liên hợp Thực phẩm',
    CompanyNameEn: 'Foodstuff Combinatorial Joint Stock Company',
  },
  {
    Code: 'FCM',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản FECON',
    CompanyNameEn: 'FECON Mining Joint Stock Company',
  },
  {
    Code: 'FCN',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần FECON',
    CompanyNameEn: 'FECON Corporation',
  },
  {
    Code: 'FCS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lương thực Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Ho Chi Minh City Food Joint Stock Company',
  },
  {
    Code: 'FDC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Ngoại thương và Phát triển Đầu tư Thành phố Hồ Chí Minh',
    CompanyNameEn:
      'Foreign Trade Development And Investment Corporation of Ho Chi Minh City',
  },
  {
    Code: 'FGL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà phê Gia Lai',
    CompanyNameEn: 'Gia Lai Coffee Joint Stock Company',
  },
  {
    Code: 'FHN',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Xuất nhập khẩu Lương thực - Thực phẩm Hà Nội',
    CompanyNameEn: 'Hanoi Food Import - Export Joint Stock Company',
  },
  {
    Code: 'FHS',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Phát hành sách Thành phố Hồ Chí Minh - FAHASA',
    CompanyNameEn: 'Ho Chi Minh City Book Distribution Corporation',
  },
  {
    Code: 'FIC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Vật liệu Xây dựng số 1 - Công ty Cổ phần',
    CompanyNameEn: 'FICO Corporation - JSC',
  },
  {
    Code: 'FID',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Doanh nghiệp Việt Nam',
    CompanyNameEn:
      'Vietnam Enterprise Investment and Development Joint Stock Company',
  },
  {
    Code: 'FIR',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Địa ốc First Real',
    CompanyNameEn: 'First Real Joint Stock Company',
  },
  {
    Code: 'FIT',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn F.I.T',
    CompanyNameEn: 'F.I.T Group Joint Stock Company',
  },
  {
    Code: 'FLC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn FLC',
    CompanyNameEn: 'FLC Group Joint Stock Company',
  },
  {
    Code: 'FMC',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Sao Ta',
    CompanyNameEn: 'Sao Ta Foods Joint Stock Company',
  },
  {
    Code: 'FOC',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Trực tuyến FPT',
    CompanyNameEn: 'FPT Online Service Joint Stock Company',
  },
  {
    Code: 'FOX',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Viễn thông FPT',
    CompanyNameEn: 'FPT Telecom',
  },
  {
    Code: 'FPT',
    Sector: 'informationTechnology',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần FPT',
    CompanyNameEn: 'FPT Corporation',
  },
  {
    Code: 'FRC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lâm đặc sản xuất khẩu Quảng Nam',
    CompanyNameEn: 'Forest Products Export Joint Stock Company of Quang Nam',
  },
  {
    Code: 'FRM',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lâm nghiệp Sài Gòn',
    CompanyNameEn: 'Saigon Forestry Import Export Joint Stock Company',
  },
  {
    Code: 'FRT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bán lẻ Kỹ thuật số FPT',
    CompanyNameEn: 'FPT Digital Retail Joint Stock Company',
  },
  {
    Code: 'FSO',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí đóng tàu thủy sản Việt Nam',
    CompanyNameEn:
      'Vietnam Fishery Mechanical Shipbuilding Joint Stock Company',
  },
  {
    Code: 'FT1',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phụ tùng máy số 1',
    CompanyNameEn: 'Machinery Spare Parts N01 Joint Stock Company',
  },
  {
    Code: 'FTI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp - Thương mại Hữu Nghị',
    CompanyNameEn: 'Friendship Trading - Industrial Joint Stock Company',
  },
  {
    Code: 'FTM',
    Sector: 'textileIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Đức Quân',
    CompanyNameEn: 'Duc Quan Investment and Development Joint Stock Company',
  },
  {
    Code: 'FTS',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán FPT',
    CompanyNameEn: 'FPT Securities Joint Stock Company',
  },
  {
    Code: 'G20',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Dệt may Vĩnh Phúc',
    CompanyNameEn: 'Vinh Phuc Textile Investment Joint Stock Company',
  },
  {
    Code: 'G36',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty 36 - Công ty Cổ phần',
    CompanyNameEn: '36 Corporation',
  },
  {
    Code: 'GAB',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Khai khoáng & Quản lý Tài sản FLC',
    CompanyNameEn:
      'FLC Mining Investment & Asset Management Joint Stock Company',
  },
  {
    Code: 'GAS',
    Sector: 'utility',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tổng Công ty Khí Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'PetroVietnam Gas Joint Stock Corporation',
  },
  {
    Code: 'GCB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần PETEC Bình Định',
    CompanyNameEn: 'PETEC Binh Dinh Joint Stock Company',
  },
  {
    Code: 'GDT',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chế biến Gỗ Đức Thành',
    CompanyNameEn: 'Duc Thanh Wood Processing Joint Stock Company',
  },
  {
    Code: 'GDW',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Gia Định',
    CompanyNameEn: 'Gia Dinh Wasuco Joint Stock Company',
  },
  {
    Code: 'GE2',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Phát điện 2',
    CompanyNameEn: 'Power Generation Corporation 2',
  },
  {
    Code: 'GEE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện lực Gelex',
    CompanyNameEn: 'GELEX Electricity Joint Stock Company',
  },
  {
    Code: 'GEG',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Điện Gia Lai',
    CompanyNameEn: 'Gia Lai Electricity Joint Stock Company',
  },
  {
    Code: 'GER',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thể thao Ngôi sao Geru',
    CompanyNameEn: 'Geru Star Sport Joint Stock Company',
  },
  {
    Code: 'GEX',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Gelex',
    CompanyNameEn: 'Gelex Group Joint Stock Company',
  },
  {
    Code: 'GGG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Ô tô Giải Phóng',
    CompanyNameEn: 'GiaiPhong Motor Joint Stock Company',
  },
  {
    Code: 'GH3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình giao thông Hà Nội',
    CompanyNameEn: 'Ha Noi Transport Project Joint Stock Company',
  },
  {
    Code: 'GHC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Gia Lai',
    CompanyNameEn: 'Gia Lai Hydropower Joint Stock Company',
  },
  {
    Code: 'GIC',
    Sector: 'shippingIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Dịch vụ và Phát triển Xanh',
    CompanyNameEn:
      'Green Development And Investment Service Joint Stock Company',
  },
  {
    Code: 'GIL',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Sản xuất Kinh doanh Xuất nhập khẩu Bình Thạnh',
    CompanyNameEn:
      'Binh Thanh Import Export Production and Trade Joint Stock Company',
  },
  {
    Code: 'GKM',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Khang Minh Group',
    CompanyNameEn: 'Khang Minh Group Joint Stock Company',
  },
  {
    Code: 'GLC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vàng Lào Cai',
    CompanyNameEn: 'Lao Cai Gold Joint Stock Company',
  },
  {
    Code: 'GLT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kỹ thuật điện Toàn Cầu',
    CompanyNameEn: 'Global Electrical Technology Corporation',
  },
  {
    Code: 'GLW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Gia Lai',
    CompanyNameEn: 'Gia Lai Water Supply Sewerage  Joint Stock Company',
  },
  {
    Code: 'GMA',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần G-Automobile',
    CompanyNameEn: 'G-Automobile Joint Stock Company',
  },
  {
    Code: 'GMC',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Garmex Sài Gòn',
    CompanyNameEn: 'Garmex Saigon Coporation',
  },
  {
    Code: 'GMD',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Gemadept',
    CompanyNameEn: 'Gemadept Corporation',
  },
  {
    Code: 'GMH',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Minh Hưng Quảng Trị',
    CompanyNameEn: 'Quang Tri Minh Hung Joint Stock Company',
  },
  {
    Code: 'GMX',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Gạch ngói Gốm xây dựng Mỹ Xuân',
    CompanyNameEn:
      'My Xuan Brick Tile Pottery and Construction Joint Stock Company',
  },
  {
    Code: 'GND',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gạch ngói Đồng Nai',
    CompanyNameEn: 'Dong Nai Brick and Tile Corporation',
  },
  {
    Code: 'GSM',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Hương Sơn',
    CompanyNameEn: 'Huong Son Hydro Power Joint Stock Company',
  },
  {
    Code: 'GSP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Sản phẩm Khí Quốc tế',
    CompanyNameEn: 'International Gas Product Shipping Joint Stock Company',
  },
  {
    Code: 'GTA',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chế biến Gỗ Thuận An',
    CompanyNameEn: 'Thuan An Wood Processing Joint Stock Company',
  },
  {
    Code: 'GTD',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giầy Thượng Đình',
    CompanyNameEn: 'Thuong Dinh Footwear Joint Stock Company',
  },
  {
    Code: 'GTH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Giao thông Thừa Thiên Huế',
    CompanyNameEn:
      'Thua Thien Hue Construction Transportation Joint Stock Company',
  },
  {
    Code: 'GTS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Giao thông Sài Gòn',
    CompanyNameEn: 'Saigon Traffic Construction Joint Stock Company',
  },
  {
    Code: 'GTT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thuận Thảo',
    CompanyNameEn: 'Thuan Thao Corporation',
  },
  {
    Code: 'GVR',
    Sector: 'chemicals',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tập đoàn Công nghiệp Cao su Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Viet Nam Rubber Group - Joint Stock Company',
  },
  {
    Code: 'GVT',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giấy Việt Trì',
    CompanyNameEn: 'Viettri Paper Joint Stock Company',
  },
  {
    Code: 'H11',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng HUD101',
    CompanyNameEn: 'HUD101 Construction Joint Stock Company',
  },
  {
    Code: 'HAC',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Hải Phòng',
    CompanyNameEn: 'Hai Phong Securities Joint Stock Company',
  },
  {
    Code: 'HAD',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Hải Dương',
    CompanyNameEn: 'Hanoi - Haiduong Beer Joint Stock Company',
  },
  {
    Code: 'HAF',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Hà Nội',
    CompanyNameEn: 'Hanoi Foodstuff Joint Stock Company',
  },
  {
    Code: 'HAG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hoàng Anh Gia Lai',
    CompanyNameEn: 'Hoang Anh Gia Lai Corporation',
  },
  {
    Code: 'HAH',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Xếp dỡ Hải An',
    CompanyNameEn: 'Hai An Transport and Stevedoring Joint Stock Company',
  },
  {
    Code: 'HAI',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nông dược HAI',
    CompanyNameEn: 'HAI AGROCHEM Joint Stock Company',
  },
  {
    Code: 'HAM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật tư Hậu Giang',
    CompanyNameEn: 'Hau Giang Materials Joint Stock Company',
  },
  {
    Code: 'HAN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Xây dựng Hà Nội - CTCP',
    CompanyNameEn: 'Hanoi Construction Corporation',
  },
  {
    Code: 'HAP',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập Đoàn Hapaco',
    CompanyNameEn: 'Hapaco Group Joint Stock Company',
  },
  {
    Code: 'HAR',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Thương mại Bất động sản An Dương Thảo Điền',
    CompanyNameEn: 'An Duong Thao Dien Real Estate Trade Joint Stock Company',
  },
  {
    Code: 'HAS',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hacisco',
    CompanyNameEn: 'Hacisco Joint Stock Company',
  },
  {
    Code: 'HAT',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Bia Hà Nội',
    CompanyNameEn: 'Hanoi Beer Trading Joint Stock Company',
  },
  {
    Code: 'HAV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Rượu Hapro',
    CompanyNameEn: 'Hapro Vodka Joint Stock Company',
  },
  {
    Code: 'HAX',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Ô tô Hàng Xanh',
    CompanyNameEn: 'Hang Xanh Motors Service Joint Stock Company',
  },
  {
    Code: 'HBC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Xây dựng Hòa Bình',
    CompanyNameEn: 'Hoa Binh Construction Group Joint Stock Company',
  },
  {
    Code: 'HBD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì PP Bình Dương',
    CompanyNameEn: 'Binh Duong PP Pack Making Joint Stock Company',
  },
  {
    Code: 'HBH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần HABECO - Hải Phòng',
    CompanyNameEn: 'HABECO - Hai Phong Joint Stock Company',
  },
  {
    Code: 'HBS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Hòa Bình',
    CompanyNameEn: 'Hoa Binh Securities Joint Stock Company',
  },
  {
    Code: 'HC1',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 1 Hà Nội',
    CompanyNameEn: 'Hanoi Construction Joint Stock Company No.1',
  },
  {
    Code: 'HC3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 3 Hải Phòng',
    CompanyNameEn: 'HaiPhong Construction Joint Stock Corporation No3',
  },
  {
    Code: 'HCB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dệt may 29/3',
    CompanyNameEn: 'March 29 Textile Garment Joint Stock Company',
  },
  {
    Code: 'HCC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bê tông Hòa Cầm - Intimex',
    CompanyNameEn: 'Intimex - Hoa Cam Concrete Joint Stock Company',
  },
  {
    Code: 'HCD',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Sản xuất và Thương mại HCD',
    CompanyNameEn: 'HCD Investment Producing And Trading Joint Stock Company',
  },
  {
    Code: 'HCI',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư - Xây dựng Hà Nội',
    CompanyNameEn: 'Ha Noi Construction Investment Joint Stock Company',
  },
  {
    Code: 'HCM',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Ho Chi Minh City Securities Corporation',
  },
  {
    Code: 'HCT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Thương mại - Dịch vụ - Vận tải Xi măng Hải Phòng',
    CompanyNameEn: 'Hai Phong Cement Transport And Trading Joint Stock Company',
  },
  {
    Code: 'HD2',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư phát triển nhà HUD2',
    CompanyNameEn: 'Housing Development Invesment Joint Stock Company HUD2',
  },
  {
    Code: 'HD6',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển nhà số 6 Hà Nội',
    CompanyNameEn:
      'Hanoi Housing Development and Investment Joint Stock Company No. 6',
  },
  {
    Code: 'HD8',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Nhà và Đô thị HUD8',
    CompanyNameEn:
      'HUD8 City and Housing Development Investment Joint Stock Company',
  },
  {
    Code: 'HDA',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Hãng sơn Đông Á',
    CompanyNameEn: 'Dong A Paint Joint Stock Company',
  },
  {
    Code: 'HDB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn:
      'Ngân hàng Thương mại Cổ phần Phát Triển Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Ho Chi Minh City Development Joint Stock Commercial Bank',
  },
  {
    Code: 'HDC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Nhà Bà Rịa Vũng Tàu',
    CompanyNameEn: 'Ba Ria - Vung Tau House Development Joint Stock Company',
  },
  {
    Code: 'HDG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hà Đô',
    CompanyNameEn: 'Ha Do Joint Stock Company',
  },
  {
    Code: 'HDM',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dệt May Huế',
    CompanyNameEn: 'Hue Textile Garment Joint Stock Company',
  },
  {
    Code: 'HDO',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hưng Đạo Container',
    CompanyNameEn: 'Hung Dao Container Joint Stock Company',
  },
  {
    Code: 'HDP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Hà Tĩnh',
    CompanyNameEn: 'HaTinh Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'HDW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh Nước sạch Hải Dương',
    CompanyNameEn: 'Hai Duong Water Joint Stock Company',
  },
  {
    Code: 'HEC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn xây dựng Thủy Lợi II',
    CompanyNameEn: 'Hydraulic Engineering Consultant Corporation II',
  },
  {
    Code: 'HEJ',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Tư vấn Xây dựng Thủy Lợi Việt Nam - CTCP',
    CompanyNameEn:
      'Vietnam Hydraulic Engineering Consultants Corporation - JSC',
  },
  {
    Code: 'HEM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo Điện cơ Hà Nội',
    CompanyNameEn: 'Hanoi Electromechanical Manufacturing Joint Stock Company',
  },
  {
    Code: 'HEP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Công trình đô thị Huế',
    CompanyNameEn: 'Hue Urban Environment and Public Works Joint Stock Company',
  },
  {
    Code: 'HES',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Giải trí Hà Nội',
    CompanyNameEn: 'Ha Noi Entertaiment Services Corporation',
  },
  {
    Code: 'HEV',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách Đại học - Dạy nghề',
    CompanyNameEn: 'Higher Educational And Vocational Book Joint Stock Company',
  },
  {
    Code: 'HFB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Cầu phà Thành phố Hồ Chí Minh',
    CompanyNameEn:
      'Ho Chi Minh City Ferry Bridge Construction Joint Stock Company',
  },
  {
    Code: 'HFC',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xăng dầu HFC',
    CompanyNameEn: 'HFC Petroleum Corporation',
  },
  {
    Code: 'HFX',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất - Xuất nhập khẩu Thanh Hà',
    CompanyNameEn:
      'Thanh Ha Production and Import - Export Joint Stock Company',
  },
  {
    Code: 'HGM',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí và Khoáng sản Hà Giang',
    CompanyNameEn: 'Ha Giang Mineral and Mechanics Joint Stock Company',
  },
  {
    Code: 'HGT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Hương Giang',
    CompanyNameEn: 'Huong Giang Tourist Joint Stock Company',
  },
  {
    Code: 'HGW',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Cấp thoát nước - Công trình Đô thị Hậu Giang',
    CompanyNameEn:
      'Hau Giang Water Supply and Sewerage - Projects Urban Joint Stock Company',
  },
  {
    Code: 'HHC',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bánh kẹo Hải Hà',
    CompanyNameEn: 'Hai Ha Confectionery Joint Stock Company',
  },
  {
    Code: 'HHG',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Hoàng Hà',
    CompanyNameEn: 'Hoang Ha Joint Stock Company',
  },
  {
    Code: 'HHN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Hàng hóa Hà Nội',
    CompanyNameEn: 'Ha Noi Goods Services and Transport Joint Stock Company',
  },
  {
    Code: 'HHP',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Giấy Hoàng Hà Hải Phòng',
    CompanyNameEn: 'Hai Phong Hoang Ha Paper Joint Stock Company',
  },
  {
    Code: 'HHR',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đường sắt Hà Hải',
    CompanyNameEn: 'Ha Hai Railway Joint Stock Company',
  },
  {
    Code: 'HHS',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Dịch vụ Hoàng Huy',
    CompanyNameEn: 'Hoang Huy Investment Services Joint Stock Company',
  },
  {
    Code: 'HHV',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Hạ tầng Giao thông Đèo Cả',
    CompanyNameEn:
      'Deo Ca Traffic Infrastructure Investment Joint Stock Company',
  },
  {
    Code: 'HID',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Halcom Vietnam',
    CompanyNameEn: 'Halcom Vietnam Joint Stock Company',
  },
  {
    Code: 'HIG',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập Đoàn HIPT',
    CompanyNameEn: 'HIPT Group Joint Stock Company',
  },
  {
    Code: 'HII',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần An Tiến Industries',
    CompanyNameEn: 'An Tien Industries Joint Stock Company',
  },
  {
    Code: 'HJC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hòa Việt',
    CompanyNameEn: 'Hoa Viet Joint Stock Company',
  },
  {
    Code: 'HJS',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Nậm Mu',
    CompanyNameEn: 'Nam Mu Hydropower Joint Stock Company',
  },
  {
    Code: 'HKB',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nông nghiệp và Thực phẩm Hà Nội - Kinh Bắc',
    CompanyNameEn: 'Ha Noi - Kinh bac Agriculture and Food Joint Stock Company',
  },
  {
    Code: 'HKP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Hà Tiên',
    CompanyNameEn: 'Hatien Packaging Joint Stock Company',
  },
  {
    Code: 'HKT',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Ego Việt Nam',
    CompanyNameEn: 'Ego Viet Nam Investment Joint Stock Company',
  },
  {
    Code: 'HLA',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hữu Liên Á Châu',
    CompanyNameEn: 'Asia Huu Lien Joint Stock Company',
  },
  {
    Code: 'HLB',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia và Nước giải khát Hạ Long',
    CompanyNameEn: 'Halong Beer and Beverage Joint Stock Company',
  },
  {
    Code: 'HLC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Hà Lầm - Vinacomin',
    CompanyNameEn: 'Ha Lam -  Vinacomin Coal Joint Stock Company',
  },
  {
    Code: 'HLD',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Bất động sản HUDLAND',
    CompanyNameEn: 'HUDLAND Real Estate Investment and Development Joint',
  },
  {
    Code: 'HLG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hoàng Long',
    CompanyNameEn: 'HOANG LONG Group',
  },
  {
    Code: 'HLR',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đường sắt Hà Lạng',
    CompanyNameEn: 'Ha Lang Railways Joint Stock Company',
  },
  {
    Code: 'HLS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sứ kỹ thuật Hoàng Liên Sơn',
    CompanyNameEn: 'Hoang Lien Son Technical Ceramic Joint Stock Company',
  },
  {
    Code: 'HLT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dệt may Hoàng Thị Loan',
    CompanyNameEn: 'Hoang Thi Loan Textile - Garment Joint Stock Company',
  },
  {
    Code: 'HLY',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gốm Xây Dựng Yên Hưng',
    CompanyNameEn: 'Yen Hung Construction Ceramic Joint Stock Company',
  },
  {
    Code: 'HMC',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Kim khí Thành phố Hồ Chí Minh - VNSTEEL',
    CompanyNameEn: 'VNSTEEL - Ho Chi Minh City Metal Corporation',
  },
  {
    Code: 'HMG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kim Khí Hà Nội - VNSTEEL',
    CompanyNameEn: 'VNSTEEL - Hanoi Steel Corporation',
  },
  {
    Code: 'HMH',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Hải Minh',
    CompanyNameEn: 'Hai Minh Corporation',
  },
  {
    Code: 'HMR',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty cổ phần Đá Hoàng Mai',
    CompanyNameEn: 'Hoang Mai Stone Joint Stock Company',
  },
  {
    Code: 'HMS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Bảo tàng Hồ Chí Minh',
    CompanyNameEn: 'Hochiminh Museum Construction Joint Stock Company',
  },
  {
    Code: 'HNA',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Hủa Na',
    CompanyNameEn: 'Hua Na Hydropower Joint Stock Company',
  },
  {
    Code: 'HNB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bến xe Hà Nội',
    CompanyNameEn: 'Ha Noi Transport Station Joint Stock Company',
  },
  {
    Code: 'HND',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nhiệt điện Hải Phòng',
    CompanyNameEn: 'Hai Phong Thermal power Joint Stock Company',
  },
  {
    Code: 'HNF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Hữu Nghị',
    CompanyNameEn: 'Huu Nghi Food Joint Stock Company',
  },
  {
    Code: 'HNG',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nông nghiệp Quốc tế Hoàng Anh Gia Lai',
    CompanyNameEn: 'Hoang Anh Gia Lai Agricultural Joint Stock Company',
  },
  {
    Code: 'HNI',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Hữu Nghị',
    CompanyNameEn: 'Huu Nghi Garment Joint Stock Company',
  },
  {
    Code: 'HNM',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sữa Hà Nội',
    CompanyNameEn: 'Ha Noi Milk Joint Stock Company',
  },
  {
    Code: 'HNP',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hanel Xốp Nhựa',
    CompanyNameEn: 'Hanel Plastics Joint Stock Company',
  },
  {
    Code: 'HNR',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Rượu và Nước Giải Khát Hà Nội',
    CompanyNameEn: 'Hanoi Liquor And Beverage Joint Stock Company',
  },
  {
    Code: 'HOM',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng VICEM Hoàng Mai',
    CompanyNameEn: 'VICEM Hoang Mai Cement Joint Stock Company',
  },
  {
    Code: 'HOT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Du lịch - Dịch vụ Hội An',
    CompanyNameEn: 'Hoi An Tourist Service Joint Stock Company',
  },
  {
    Code: 'HPB',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì PP',
    CompanyNameEn: 'PP Pack Making Joint Stock Company',
  },
  {
    Code: 'HPD',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Đăk Đoa',
    CompanyNameEn: 'Dak Doa Hydropower Joint Stock Company',
  },
  {
    Code: 'HPG',
    Sector: 'materials',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hòa Phát',
    CompanyNameEn: 'Hoa Phat Group Joint Stock Company',
  },
  {
    Code: 'HPH',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hóa Chất Hưng Phát Hà Bắc',
    CompanyNameEn: 'Ha Bac Hung Phat Chemical Joint Stock Company',
  },
  {
    Code: 'HPI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khu công nghiệp Hiệp Phước',
    CompanyNameEn: 'Hiep Phuoc Industrial Park Joint Stock Company',
  },
  {
    Code: 'HPM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Xây dựng Thương mại và Khoáng sản Hoàng Phúc',
    CompanyNameEn:
      'Hoang Phuc Mineral Trading and Contruction Joint Stock Company',
  },
  {
    Code: 'HPP',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sơn Hải Phòng',
    CompanyNameEn: 'Hai Phong Paint Joint Stock Company',
  },
  {
    Code: 'HPT',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Công nghệ Tin học HPT',
    CompanyNameEn: 'HPT Vietnam Corporation',
  },
  {
    Code: 'HPW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Hải Phòng',
    CompanyNameEn: 'Hai Phong Water Joint Stock Company',
  },
  {
    Code: 'HPX',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Hải Phát',
    CompanyNameEn: 'Hai Phat Investment Joint Stock Company',
  },
  {
    Code: 'HQC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Tư vấn - Thương mại - Dịch vụ Địa ốc Hoàng Quân',
    CompanyNameEn:
      'Hoang Quan Consulting Trading Service Real Estate Corporation',
  },
  {
    Code: 'HRB',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Harec Đầu tư và Thương Mại',
    CompanyNameEn: 'Harec Investment and Trade Joint Stock Company',
  },
  {
    Code: 'HRC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Hòa Bình',
    CompanyNameEn: 'Hoa Binh Rubber Joint Stock Company',
  },
  {
    Code: 'HRT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải đường sắt Hà Nội',
    CompanyNameEn: 'Hanoi Railway Transport Joint Stock Company',
  },
  {
    Code: 'HSA',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần HESTIA',
    CompanyNameEn: 'HESTIA Joint Stock Company',
  },
  {
    Code: 'HSG',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Hoa Sen',
    CompanyNameEn: 'Hoa Sen Group',
  },
  {
    Code: 'HSI',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật tư Tổng hợp và Phân bón Hóa sinh',
    CompanyNameEn:
      'General Materials Biochemistry Fertilizer Joint Stock Company',
  },
  {
    Code: 'HSL',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Thực phẩm Hồng Hà',
    CompanyNameEn: 'Hong Ha Food Investment Development Joint Stock Company',
  },
  {
    Code: 'HSM',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Dệt May Hà Nội',
    CompanyNameEn: 'HaNoi Textile And Garment Joint Stock Corporation',
  },
  {
    Code: 'HSP',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sơn Tổng hợp Hà Nội',
    CompanyNameEn: 'Ha Noi Synthetic Paint Joint Stock Company',
  },
  {
    Code: 'HSV',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gang Thép Hà Nội',
    CompanyNameEn: 'Ha Noi Iron And Steel Joint Stock Company',
  },
  {
    Code: 'HT1',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng VICEM Hà Tiên',
    CompanyNameEn: 'VICEM Ha Tien Cement Joint Stock Company',
  },
  {
    Code: 'HTC',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Hóc Môn',
    CompanyNameEn: 'Hocmon Trade Joint Stock Company',
  },
  {
    Code: 'HTE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Kinh doanh Điện lực Thành phố Hồ Chí Minh',
    CompanyNameEn:
      'Ho Chi Minh City Electric Power Trading Investment Corporation',
  },
  {
    Code: 'HTG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Dệt may Hòa Thọ',
    CompanyNameEn: 'Hoa Tho Textile - Garment Joint Sock Corporation',
  },
  {
    Code: 'HTI',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Hạ tầng IDICO',
    CompanyNameEn:
      'IDICO Infrastructure Development Investment Joint Stock Company',
  },
  {
    Code: 'HTL',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Kỹ thuật và Ô tô Trường Long',
    CompanyNameEn: 'Truong Long Engineering and Auto Joint Stock Company',
  },
  {
    Code: 'HTM',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Thương mại Hà Nội – Công ty cổ phần',
    CompanyNameEn: 'Hanoi Trade Joint Stock Corporation',
  },
  {
    Code: 'HTN',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hưng Thịnh Incons',
    CompanyNameEn: 'Hung Thinh Incons Joint Stock Company',
  },
  {
    Code: 'HTP',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần In Sách Giáo Khoa Hòa Phát',
    CompanyNameEn: 'Hoa Phat Textbook Book Joint Stock Company',
  },
  {
    Code: 'HTR',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đường sắt Hà Thái',
    CompanyNameEn: 'Ha Thai Railway Joint Stock Company',
  },
  {
    Code: 'HTT',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Hà Tây',
    CompanyNameEn: 'Ha Tay Trading Joint Stock Company',
  },
  {
    Code: 'HTV',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Logistics Vicem',
    CompanyNameEn: 'Logistics Vicem Joint Stock Company',
  },
  {
    Code: 'HTW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Hà Tĩnh',
    CompanyNameEn: 'Ha Tinh Water Supply Joint Stock Company',
  },
  {
    Code: 'HU1',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng HUD1',
    CompanyNameEn: 'HUD1 Investment And Construction Joint Stock Company',
  },
  {
    Code: 'HU3',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng HUD3',
    CompanyNameEn: 'HUD3 Investment and Construction Joint Stock Company',
  },
  {
    Code: 'HU4',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng HUD4',
    CompanyNameEn: 'HUD4 Investment and Construction Joint Stock Company',
  },
  {
    Code: 'HU6',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Nhà và Đô thị HUD6',
    CompanyNameEn:
      'HUD6 City and Housing Development Investment Joint Stock Company',
  },
  {
    Code: 'HUB',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Thừa Thiên Huế',
    CompanyNameEn: 'Thua Thien Hue Construction Joint Stock Company',
  },
  {
    Code: 'HUG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng công ty May Hưng Yên - Công ty Cổ phần',
    CompanyNameEn: 'Hung Yen Garment Corporation - Joint Stock Company',
  },
  {
    Code: 'HUT',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tasco',
    CompanyNameEn: 'Tasco Joint Stock Company',
  },
  {
    Code: 'HVA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư HVA',
    CompanyNameEn: 'HVA Investment Joint Stock Company',
  },
  {
    Code: 'HVG',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hùng Vương',
    CompanyNameEn: 'Hung Vuong Corporation',
  },
  {
    Code: 'HVH',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Công nghệ HVC',
    CompanyNameEn: 'HVC Investment And Technology Joint Stock Company',
  },
  {
    Code: 'HVN',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Hàng không Việt Nam - CTCP',
    CompanyNameEn: 'Vietnam Airlines Joint Stock Company',
  },
  {
    Code: 'HVT',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Hóa chất Việt Trì',
    CompanyNameEn: 'Viet Tri Chemical Joint Stock Company',
  },
  {
    Code: 'HVX',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Vicem Hải Vân',
    CompanyNameEn: 'Vicem Hai Van Cement Joint Stock Company',
  },
  {
    Code: 'HWS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Thừa Thiên Huế',
    CompanyNameEn: 'Thua Thien Hue Water Supply Joint Stock Company',
  },
  {
    Code: 'IBC',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư APAX Holdings',
    CompanyNameEn: 'APAX Holdings Joint Stock Company',
  },
  {
    Code: 'IBD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần In Tổng hợp Bình Dương',
    CompanyNameEn: 'Binh Duong General Printing Joint Stock Company',
  },
  {
    Code: 'IBN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty TNHH MTV In báo Nghệ An',
    CompanyNameEn: 'Newspaper Nghe An Printing Company Limited',
  },
  {
    Code: 'ICC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng công nghiệp',
    CompanyNameEn: 'Industrial Construction Joint Stock Company',
  },
  {
    Code: 'ICF',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư-Thương mại-Thủy sản',
    CompanyNameEn: 'Incomfish Corp',
  },
  {
    Code: 'ICG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Sông Hồng',
    CompanyNameEn: 'SONG HONG Construction Joint stock Company',
  },
  {
    Code: 'ICI',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Công nghiệp',
    CompanyNameEn: 'Industrial Construction and Investment Joint Stock Company',
  },
  {
    Code: 'ICN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Dầu khí IDICO',
    CompanyNameEn:
      'IDICO Investment Construction Oil and Natural Gas Joint Stock Company',
  },
  {
    Code: 'ICT',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Viễn thông - Tin học Bưu điện',
    CompanyNameEn: 'Joint Stock Company for Telecoms and Informatics',
  },
  {
    Code: 'IDC',
    Sector: 'industryRealEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty IDICO - CTCP',
    CompanyNameEn: 'IDICO Corporation - JSC',
  },
  {
    Code: 'IDI',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Đa Quốc Gia I.D.I',
    CompanyNameEn: 'I.D.I International Development & Investment Corporation',
  },
  {
    Code: 'IDJ',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư IDJ Việt Nam',
    CompanyNameEn: 'IDJ Vietnam Investment Joint Stock Company',
  },
  {
    Code: 'IDP',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sữa Quốc tế',
    CompanyNameEn: 'International Dairy Products Joint Stock Company',
  },
  {
    Code: 'IDV',
    Sector: 'industryRealEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Hạ tầng Vĩnh Phúc',
    CompanyNameEn: 'Vinh Phuc Infrastructure Development Joint Stock Company',
  },
  {
    Code: 'IFS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Quốc tế',
    CompanyNameEn: 'Interfood Shareholding Company',
  },
  {
    Code: 'IHK',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần In Hàng Không',
    CompanyNameEn: 'Aviation Printing Joint Stock Company',
  },
  {
    Code: 'IJC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Hạ tầng Kỹ thuật',
    CompanyNameEn: 'Becamex Infrastructure Development Joint Stock Company',
  },
  {
    Code: 'ILA',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần ILA',
    CompanyNameEn: 'ILA Joint Stock Company',
  },
  {
    Code: 'ILB',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần ICD Tân Cảng - Long Bình',
    CompanyNameEn: 'ICD Tan Cang - Long Binh Joint Stock Company',
  },
  {
    Code: 'ILC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hợp tác lao động với nước ngoài',
    CompanyNameEn: 'International Labour and Services Joint Stock Company',
  },
  {
    Code: 'ILS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Thương mại và Dịch vụ Quốc tế',
    CompanyNameEn:
      'International Investment Trade and Service Joint Stock Company',
  },
  {
    Code: 'IME',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí và Xây lắp Công nghiệp',
    CompanyNameEn: 'Mechanical and Industrial Construction Joint Stock Company',
  },
  {
    Code: 'IMP',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm IMEXPHARM',
    CompanyNameEn: 'Imexpharm Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'IN4',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần In số 4',
    CompanyNameEn: 'No 4 Printing Joint Stock Company',
  },
  {
    Code: 'INC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Đầu tư IDICO',
    CompanyNameEn: 'IDICO Investment Consultancy Joint Stock Company',
  },
  {
    Code: 'INN',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bao bì và In Nông nghiệp',
    CompanyNameEn: 'Agriculture Printing And Packaging Joint Stock Company',
  },
  {
    Code: 'IPA',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đầu tư I.P.A',
    CompanyNameEn: 'I.P.A Investments Group Joint Stock Company',
  },
  {
    Code: 'IRC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cao su Công nghiệp',
    CompanyNameEn: 'Industrial Rubber Joint Stock Company',
  },
  {
    Code: 'ISG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải biển và Hợp tác lao động Quốc Tế',
    CompanyNameEn:
      'International Shipping and Labour Cooperation Joint Stock Company',
  },
  {
    Code: 'ISH',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Srok Phu Miêng IDICO',
    CompanyNameEn: 'IDICO Srok Phu Mieng Hydropower Joint Stock Company',
  },
  {
    Code: 'IST',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần ICD Tân Cảng Sóng Thần',
    CompanyNameEn: 'Tan Cang Song Than ICD Joint Stock Company',
  },
  {
    Code: 'ITA',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Công nghiệp Tân Tạo',
    CompanyNameEn: 'Tan Tao Investment and Industry Corporation',
  },
  {
    Code: 'ITC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư - Kinh doanh nhà',
    CompanyNameEn: 'Investment and Trading of Real Estate Joint stock Company',
  },
  {
    Code: 'ITD',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ Tiên Phong',
    CompanyNameEn: 'Innovative Technology Development Corporation',
  },
  {
    Code: 'ITQ',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thiên Quang',
    CompanyNameEn: 'Thien Quang Group Joint Stock Company',
  },
  {
    Code: 'ITS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư, Thương mại và Dịch vụ - Vinacomin',
    CompanyNameEn:
      'Vinacomin - Investment, Trading and Service Joint Stock Company',
  },
  {
    Code: 'IVS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Goutai Junan (Việt Nam)',
    CompanyNameEn: 'Goutai Junan Securities (Vietnam) Corp.',
  },
  {
    Code: 'JOS',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế biến Thủy sản Xuất khẩu Minh Hải',
    CompanyNameEn:
      'Minh Hai Export Frozen Seafood Processing Joint Stock Company',
  },
  {
    Code: 'JVC',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Y tế Việt Nhật',
    CompanyNameEn:
      'Japan Vietnam Medical Investment and Development Joint Stock Company',
  },
  {
    Code: 'KAC',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Địa ốc Khang An',
    CompanyNameEn: 'Khang An Investment Real Estate Joint Stock Company',
  },
  {
    Code: 'KBC',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Phát triển Đô thị Kinh Bắc',
    CompanyNameEn: 'Kinh Bac City Development Holding Corporation',
  },
  {
    Code: 'KCB',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng Sản và Luyện Kim Cao Bằng',
    CompanyNameEn: 'Cao Bang Mineral and Metallurgical Joint Stock Company',
  },
  {
    Code: 'KCE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bê tông Ly tâm Điện lực Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa Power Centrifugal Concrete Joint Stock Company',
  },
  {
    Code: 'KDC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn KIDO',
    CompanyNameEn: 'KIDO Group Corporation',
  },
  {
    Code: 'KDH',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Kinh doanh nhà Khang Điền',
    CompanyNameEn:
      'Khang Dien House Trading and Investment Joint Stock Company',
  },
  {
    Code: 'KDM',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn GCL',
    CompanyNameEn: 'GCL Group Corporation',
  },
  {
    Code: 'KGM',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Kiên Giang',
    CompanyNameEn: 'Kien Giang Import & Export Joint Stock Company',
  },
  {
    Code: 'KHA',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Dịch vụ Khánh Hội',
    CompanyNameEn: 'Khanh Hoi Investment and Services Corporation',
  },
  {
    Code: 'KHD',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khai thác, Chế biến Khoáng sản Hải Dương',
    CompanyNameEn: 'Hai Duong Mineral Eineal Processing Joint Stock Company',
  },
  {
    Code: 'KHG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Khải Hoàn Land',
    CompanyNameEn: 'Khai Hoan Land Group Joint Stock Company',
  },
  {
    Code: 'KHL',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản và Vật liệu Xây dựng Hưng Long',
    CompanyNameEn:
      'Hung Long Mineral And Building Material Joint Stock Company',
  },
  {
    Code: 'KHP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Điện lực Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa Power Joint Stock Company',
  },
  {
    Code: 'KHS',
    Sector: 'fisheriesIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kiên Hùng',
    CompanyNameEn: 'Kien Hung Joint Stock Company',
  },
  {
    Code: 'KHW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa Water Supply And Sewerage Joint Stock Company',
  },
  {
    Code: 'KIP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần K.I.P Việt Nam',
    CompanyNameEn: 'Vietnam K.I.P Joint Stock Company',
  },
  {
    Code: 'KKC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thành Thái',
    CompanyNameEn: 'Thanh Thai Group Joint Stock Company',
  },
  {
    Code: 'KLB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Kiên Long',
    CompanyNameEn: 'Kien Long Commercial Joint Stock Bank',
  },
  {
    Code: 'KLF',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Thương mại và Xuất nhập khẩu CFS',
    CompanyNameEn:
      'CFS Investment and Import Export Trading Joint Stock Company',
  },
  {
    Code: 'KLM',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kim loại màu Nghệ Tĩnh',
    CompanyNameEn: 'Nghe Tinh Non - Ferous Metal Joint Stock Company',
  },
  {
    Code: 'KMR',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Mirae',
    CompanyNameEn: 'MIRAE Joint Stock Company',
  },
  {
    Code: 'KMT',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kim khí Miền Trung',
    CompanyNameEn: 'Central Vietnam Metal Corporation',
  },
  {
    Code: 'KOS',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần KOSY',
    CompanyNameEn: 'KOSY Joint Stock Company',
  },
  {
    Code: 'KPF',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Tài sản KOJI',
    CompanyNameEn: 'KOJI Asset Investment Joint Stock Company',
  },
  {
    Code: 'KSB',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản và Xây dựng Bình Dương',
    CompanyNameEn: 'Binh Duong Mineral And Construction Joint Stock Company',
  },
  {
    Code: 'KSD',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư DNA',
    CompanyNameEn: 'DNA Investement Joint Stock Company',
  },
  {
    Code: 'KSF',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty cổ phần Tập đoàn KSFinance',
    CompanyNameEn: 'KSFinance Group Joint Stock Company',
  },
  {
    Code: 'KSH',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Damac GLS',
    CompanyNameEn: 'Damac GLS Joint Stock Company',
  },
  {
    Code: 'KSQ',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần CNC Capital Việt Nam',
    CompanyNameEn: 'CNC Capital Viet Nam Joint Stock Company',
  },
  {
    Code: 'KST',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần KASATI',
    CompanyNameEn: 'Kasati Joint Stock Company',
  },
  {
    Code: 'KSV',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Khoáng sản TKV - Công ty Cổ phần',
    CompanyNameEn: 'Vinacomin - Minerals Holding Corporation',
  },
  {
    Code: 'KTC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Kiên Giang',
    CompanyNameEn: 'Kien Giang Trading Joint Stock Company',
  },
  {
    Code: 'KTL',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kim khí Thăng Long',
    CompanyNameEn: 'Thang Long Metal Wares Joint Stock Company',
  },
  {
    Code: 'KTS',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đường Kon Tum',
    CompanyNameEn: 'Kon Tum Sugar Joint Stock Company',
  },
  {
    Code: 'KTT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đầu tư KTT',
    CompanyNameEn: 'KTT Investment Group Joint Stock Company',
  },
  {
    Code: 'KVC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất Xuất nhập khẩu Inox Kim Vĩ',
    CompanyNameEn:
      'Kim Vi Stainless Steel Commercial Producing Joint Stock Company',
  },
  {
    Code: 'L10',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 10',
    CompanyNameEn: 'Lilama Joint Stock Company No10',
  },
  {
    Code: 'L12',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Licogi 12',
    CompanyNameEn: 'Licogi 12 Joint Stock Company',
  },
  {
    Code: 'L14',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Licogi 14',
    CompanyNameEn: 'Licogi 14 Joint Stock Company',
  },
  {
    Code: 'L18',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng số 18',
    CompanyNameEn: 'Construction and Investment Joint Stock Company No18',
  },
  {
    Code: 'L35',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Lắp máy Lilama',
    CompanyNameEn: 'Erection Mechanical Joint Stock Company',
  },
  {
    Code: 'L40',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng 40',
    CompanyNameEn: '40 Investment and Construction Joint Stock Company',
  },
  {
    Code: 'L43',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 45.3',
    CompanyNameEn: 'Lilama 45.3 Joint Stock Company',
  },
  {
    Code: 'L44',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lilama 45.4',
    CompanyNameEn: 'LILAMA 45.4 Joint Stock Company',
  },
  {
    Code: 'L45',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lilama 45.1',
    CompanyNameEn: 'Lilama 45.1 Joint Stock Company',
  },
  {
    Code: 'L61',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 69-1',
    CompanyNameEn: 'Lilama 69-1 Joint Stock Company',
  },
  {
    Code: 'L62',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 69 - 2',
    CompanyNameEn: 'LILAMA 69 - 2 Joint Stock Company',
  },
  {
    Code: 'L63',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lilama 69-3',
    CompanyNameEn: 'Lilama 69-3 Joint Stock Company',
  },
  {
    Code: 'LAF',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chế biến Hàng Xuất khẩu Long An',
    CompanyNameEn: 'Long An Food Processing Export Joint Stock Company',
  },
  {
    Code: 'LAI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Long An IDICO',
    CompanyNameEn: 'IDICO-Long An Investment Construction Joint Stock Company',
  },
  {
    Code: 'LAS',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Supe Phốt phát và Hóa chất Lâm Thao',
    CompanyNameEn: 'Lam Thao Fertilizers &amp; Chemicals Joint Stock Company',
  },
  {
    Code: 'LAW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Long An',
    CompanyNameEn: 'Long An Water Supply Sewerage Joint Stock Company',
  },
  {
    Code: 'LBC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại - Đầu tư Long Biên',
    CompanyNameEn: 'Long Bien Joint Stock Company',
  },
  {
    Code: 'LBE',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị Trường học Long An',
    CompanyNameEn: 'Long An School Book and Equipment Joint Stock Company',
  },
  {
    Code: 'LBM',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản và Vật liệu xây dựng Lâm Đồng',
    CompanyNameEn:
      'Lam Dong Minerals And Building Materials Joint Stock Company',
  },
  {
    Code: 'LCC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Hồng Phong',
    CompanyNameEn: 'Hong Phong Cement Joint Stock Company',
  },
  {
    Code: 'LCD',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lắp máy - Thí nghiệm cơ điện',
    CompanyNameEn: 'Erection - Electromechanics Testing Joint Stock Company',
  },
  {
    Code: 'LCG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Lizen',
    CompanyNameEn: 'Lizen Joint Stock Company',
  },
  {
    Code: 'LCM',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khai thác và Chế biến Khoáng sản Lào Cai',
    CompanyNameEn: 'Lao Cai Mining and Mineral Processing Joint Stock Company',
  },
  {
    Code: 'LCS',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Licogi 16.6',
    CompanyNameEn: 'Licogi 16.6 Joint Stock Company',
  },
  {
    Code: 'LCW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Lai Châu',
    CompanyNameEn: 'Lai Chau Clean Water Joint Stock Company',
  },
  {
    Code: 'LDG',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư LDG',
    CompanyNameEn: 'LDG Investment Joint stock Company',
  },
  {
    Code: 'LDP',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược Lâm Đồng - Ladophar',
    CompanyNameEn: 'Lam Dong Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'LDW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Lâm Đồng',
    CompanyNameEn: 'Lam Dong Water Supply And Sewerage Joint Stock Company',
  },
  {
    Code: 'LEC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản Điện lực Miền Trung',
    CompanyNameEn: 'Central Power Real Estate Joint Stock Company',
  },
  {
    Code: 'LG9',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ giới và Xây lắp số 9',
    CompanyNameEn:
      'Mechanized Construction And Installation Joint Stock Company No9',
  },
  {
    Code: 'LGC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Cầu đường CII',
    CompanyNameEn: 'CII Bridges and Roads Investment Joint Stock Company',
  },
  {
    Code: 'LGL',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Đô thị Long Giang',
    CompanyNameEn:
      'Long Giang Investment and Urban Development Joint-stock Company',
  },
  {
    Code: 'LGM',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giày da và may mặc xuất khẩu (Legamex)',
    CompanyNameEn: 'Leather Footwear and Garment Making Export Corporation',
  },
  {
    Code: 'LHC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Thủy lợi Lâm Đồng',
    CompanyNameEn:
      'Lam Dong Investment Hydraulic Construction Joint Stock Company',
  },
  {
    Code: 'LHG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Long Hậu',
    CompanyNameEn: 'Long Hau Corporation',
  },
  {
    Code: 'LIC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty LICOGI - Công ty Cổ phần',
    CompanyNameEn: 'LICOGI Corporation - JSC',
  },
  {
    Code: 'LIG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Licogi 13',
    CompanyNameEn: 'Licogi 13 Joint Stock Company',
  },
  {
    Code: 'LIX',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bột giặt LIX',
    CompanyNameEn: 'LIX Detergent Joint Stock Company',
  },
  {
    Code: 'LKW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Long Khánh',
    CompanyNameEn: 'Long Khanh Water Supply Joint Stock Company',
  },
  {
    Code: 'LLM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty lắp máy Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Vietnam Machinery Installation Corporation',
  },
  {
    Code: 'LM3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lilama 3',
    CompanyNameEn: 'Lilama 3 Joint Stock Company',
  },
  {
    Code: 'LM7',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 7',
    CompanyNameEn: 'Lilama 7 Joint Stock Company',
  },
  {
    Code: 'LM8',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Lilama 18',
    CompanyNameEn: 'Lilama 18 Joint Stock Company',
  },
  {
    Code: 'LMC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản LATCA',
    CompanyNameEn: 'LATCA Mineral Joint Stock Company',
  },
  {
    Code: 'LMH',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Quốc Tế Holding',
    CompanyNameEn: 'Quoc te Holding Joint Stock Company',
  },
  {
    Code: 'LMI',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Lắp máy IDICO',
    CompanyNameEn:
      'IDICO Machinery Erection Construction Investment Joint Stock Company',
  },
  {
    Code: 'LNC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lệ Ninh',
    CompanyNameEn: 'Le Ninh Joint Stock Company',
  },
  {
    Code: 'LO5',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lilama 5',
    CompanyNameEn: 'Lilama 5 Joint Stock Company',
  },
  {
    Code: 'LPB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Bưu Điện Liên Việt',
    CompanyNameEn: 'Lien Viet Post Joint Stock Commercial Bank',
  },
  {
    Code: 'LPT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại và Sản xuất Lập Phương Thành',
    CompanyNameEn:
      'Lap Phuong Thanh Production And Trading Joint Stock Company',
  },
  {
    Code: 'LQN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Licogi Quảng Ngãi',
    CompanyNameEn: 'Licogi Quang Ngai Joint Stock Company',
  },
  {
    Code: 'LSG',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản Sài Gòn Vi na',
    CompanyNameEn: 'Sai Gon Vina Land Joint Stock Company',
  },
  {
    Code: 'LSS',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Mía đường Lam Sơn',
    CompanyNameEn: 'Lam Son Suger Joint Stock Corporation',
  },
  {
    Code: 'LTC',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện nhẹ Viễn Thông',
    CompanyNameEn: 'Low Current Telecommunication Joint Stock Company',
  },
  {
    Code: 'LTG',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Lộc Trời',
    CompanyNameEn: 'Loc Troi Group Joint Stock Company',
  },
  {
    Code: 'LUT',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Lương Tài',
    CompanyNameEn: 'Luong Tai Investment Construction Joint Stock Company',
  },
  {
    Code: 'LWS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Tỉnh Lào Cai',
    CompanyNameEn: 'Lao Cai Water Supply Joint Stock Company',
  },
  {
    Code: 'LYF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lương Thực Lương Yên',
    CompanyNameEn: 'Luong Yen Food Joint Stock Company',
  },
  {
    Code: 'M10',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng công ty May 10 - Công ty Cổ phần',
    CompanyNameEn: 'Garment 10 Corporation - Joint Stock Company',
  },
  {
    Code: 'MA1',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị',
    CompanyNameEn: 'Machinery Joint Stock Company',
  },
  {
    Code: 'MAC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cung ứng và Dịch vụ Kỹ thuật Hàng Hải',
    CompanyNameEn: 'Marine Supply And Engineering Service Joint Stock Company',
  },
  {
    Code: 'MAS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty cổ phần Dịch vụ Hàng Không Sân Bay Đà Nẵng',
    CompanyNameEn: 'Danang Airport Services Joint Stock Company',
  },
  {
    Code: 'MBB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Quân đội',
    CompanyNameEn: 'Military Commercial Joint Stock Bank',
  },
  {
    Code: 'MBG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn MBG',
    CompanyNameEn: 'MBG Group Joint Stock Company',
  },
  {
    Code: 'MBN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Công trình đô thị Bắc Ninh',
    CompanyNameEn:
      'Bac Ninh Urban Environment and Public Works Joint Stock Company',
  },
  {
    Code: 'MBS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán MB',
    CompanyNameEn: 'MB Securities Joint Stock Company',
  },
  {
    Code: 'MCC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Gạch ngói cao cấp',
    CompanyNameEn: 'High - Grade Brick - Tile Co-opration',
  },
  {
    Code: 'MCD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Công trình Đô thị Đông Hà',
    CompanyNameEn: 'Dong Ha Environment And Urban Works Joint Stock Company',
  },
  {
    Code: 'MCF',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Cơ khí và Lương thực Thực phẩm',
    CompanyNameEn: 'Mechanics Construction And Foodstuff Joint Stock Company',
  },
  {
    Code: 'MCG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Năng lượng và Bất động sản MCG',
    CompanyNameEn: 'MCG Energy and Real Estate Joint Stock Company',
  },
  {
    Code: 'MCH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hàng Tiêu Dùng MaSan',
    CompanyNameEn: 'Masan Consumer Corporation',
  },
  {
    Code: 'MCI',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Xây dựng và Phát triển Vật liệu IDICO',
    CompanyNameEn:
      'IDICO Material Development And Construction Investment Joint Stock Company',
  },
  {
    Code: 'MCM',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giống bò sữa Mộc Châu',
    CompanyNameEn: 'Moc Chau Dairy Cattle Breeding Joint Stock Company',
  },
  {
    Code: 'MCO',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng BDC Việt Nam',
    CompanyNameEn:
      'Investment and Construction BDC Viet Nam Joint Stock Company',
  },
  {
    Code: 'MCP',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần In và Bao bì Mỹ Châu',
    CompanyNameEn: 'My Chau Printing & Packaging Holdings Company',
  },
  {
    Code: 'MDA',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Đông Anh',
    CompanyNameEn: 'Dong Anh Urban Environment Joint Stock Company',
  },
  {
    Code: 'MDC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Mông Dương - Vinacomin',
    CompanyNameEn: 'Vinacomin - Mong Duong Coal Joint Stock Company',
  },
  {
    Code: 'MDF',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gỗ MDF VRG - Quảng Trị',
    CompanyNameEn: 'MDF VRG - Quang Tri Wood Joint Stock Company',
  },
  {
    Code: 'MDG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Miền Đông',
    CompanyNameEn: 'Mien Dong Joint Stock Company',
  },
  {
    Code: 'MEC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí - Lắp máy Sông Đà',
    CompanyNameEn: 'Song Da Mechanical Assembiling Joint Stock Company',
  },
  {
    Code: 'MED',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược Trung ương Mediplantex',
    CompanyNameEn: 'Mediplantex National Phamaceutical Joint Stock Company',
  },
  {
    Code: 'MEF',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần MEINFA',
    CompanyNameEn: 'MEINFA Joint Stock Company',
  },
  {
    Code: 'MEL',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thép Mê Lin',
    CompanyNameEn: 'Me Lin Steel Joint Stock Company',
  },
  {
    Code: 'MES',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Công trình',
    CompanyNameEn: 'Mechanical Engineering Service Joint Stock Company',
  },
  {
    Code: 'MFS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ kỹ thuật Mobifone',
    CompanyNameEn: 'Mobifone Technical Services Joint Stock Company',
  },
  {
    Code: 'MGC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Địa chất mỏ - TKV',
    CompanyNameEn: 'Vinacomin - Mining Geology Joint Stock Comapny',
  },
  {
    Code: 'MGG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Đức Giang - Công ty Cổ phần',
    CompanyNameEn: 'Duc Giang Corporation',
  },
  {
    Code: 'MGR',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Tập đoàn MGROUP',
    CompanyNameEn: 'MGROUP Group Corporation',
  },
  {
    Code: 'MH3',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khu công nghiệp Cao su Bình Long',
    CompanyNameEn: 'Binh Long Rubber Industrial Park Corporation',
  },
  {
    Code: 'MHC',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần MHC',
    CompanyNameEn: 'MHC Joint Stock Company',
  },
  {
    Code: 'MHL',
    Sector: 'textileIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Minh Hữu Liên',
    CompanyNameEn: 'Minh Huu Lien Joint Stock Company',
  },
  {
    Code: 'MIC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kỹ nghệ Khoáng sản Quảng Nam',
    CompanyNameEn: 'Quang Nam Mineral Industry Joint Stock Company',
  },
  {
    Code: 'MIE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Máy và Thiết bị Công nghiệp - CTCP',
    CompanyNameEn: 'Machines and Industrial Equipment Corporation',
  },
  {
    Code: 'MIG',
    Sector: 'insurances',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo hiểm Quân đội',
    CompanyNameEn: 'Military Insurance Corporation',
  },
  {
    Code: 'MIM',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản và Cơ khí',
    CompanyNameEn: 'Mineral and Mechanical Joint Stock Company',
  },
  {
    Code: 'MKP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hóa - Dược phẩm Mekophar',
    CompanyNameEn: 'Mekophar Chemical Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'MKV',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược Thú Y Cai Lậy',
    CompanyNameEn: 'Cai Lay Veterinary Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'MLC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Tỉnh Lào Cai',
    CompanyNameEn: 'Lao Cai Urban Environment Joint Stock Company',
  },
  {
    Code: 'MLS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chăn nuôi - Mitraco',
    CompanyNameEn: 'Mitraco Livestock Joint Stock Company',
  },
  {
    Code: 'MML',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Masan MEATLife',
    CompanyNameEn: 'Masan MEATLife Joint Stock Company',
  },
  {
    Code: 'MNB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty May Nhà Bè - Công ty Cổ phần',
    CompanyNameEn: 'Nha Be Garment Corporation - Joint Stock Company',
  },
  {
    Code: 'MND',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Nam Định',
    CompanyNameEn: 'Nam Dinh Environment Joint Stock Company',
  },
  {
    Code: 'MPC',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thủy sản Minh Phú',
    CompanyNameEn: 'Minh Phu Seafood Joint Stock Company',
  },
  {
    Code: 'MPT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn MPT',
    CompanyNameEn: 'MPT Group Joint Stock Company',
  },
  {
    Code: 'MPY',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường đô thị Phú Yên',
    CompanyNameEn: 'Phu Yen Town Environment Joint Stock Company',
  },
  {
    Code: 'MQB',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Phát triển đô thị Quảng Bình',
    CompanyNameEn:
      'Quang Binh Environment and Urban Development Joint Stock Company',
  },
  {
    Code: 'MQN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường đô thị Quảng Ngãi',
    CompanyNameEn: 'Quang Ngai Urban Environment Joint Stock Company',
  },
  {
    Code: 'MRF',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần MERUFA',
    CompanyNameEn: 'MERUFA Joint Stock Company',
  },
  {
    Code: 'MSB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Hàng Hải Việt Nam',
    CompanyNameEn: 'Vietnam Maritime Commercial Joint Stock Bank',
  },
  {
    Code: 'MSH',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần May Sông Hồng',
    CompanyNameEn: 'Song Hong Garment Joint Stock Company',
  },
  {
    Code: 'MSN',
    Sector: 'agricalture',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Masan',
    CompanyNameEn: 'Masan Group Corporation',
  },
  {
    Code: 'MSR',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Masan High-Tech Materials',
    CompanyNameEn: 'Masan High-Tech Materials Corporation',
  },
  {
    Code: 'MST',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư MST',
    CompanyNameEn: 'MST Investment Joint Stock Company',
  },
  {
    Code: 'MTA',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng Công ty Khoáng sản và Thương mại Hà Tĩnh - Công ty Cổ phần',
    CompanyNameEn: 'Ha Tinh Minerals & Trading Joint Stock Corporation',
  },
  {
    Code: 'MTB',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Môi trường và Công trình đô thị tỉnh Thái Bình',
    CompanyNameEn:
      'Thai Binh Environment And Urban Projects Joint Stock Company',
  },
  {
    Code: 'MTC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Du lịch Mỹ Trà',
    CompanyNameEn: 'My Tra Tourist Joint Stock Company',
  },
  {
    Code: 'MTG',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần MT Gas',
    CompanyNameEn: 'MT Gas JSC',
  },
  {
    Code: 'MTH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Hà Đông',
    CompanyNameEn: 'Ha Dong Environment Public Service Joint Stock Company',
  },
  {
    Code: 'MTL',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Môi trường Đô thị Từ Liêm',
    CompanyNameEn: 'Tu Liem Urban Environment Service Joint Stock Company',
  },
  {
    Code: 'MTP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Medipharco',
    CompanyNameEn: 'Medipharco Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'MTS',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật tư - TKV',
    CompanyNameEn: 'Vinacomin - Materials Trading Joint Stock Company',
  },
  {
    Code: 'MTV',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Dịch vụ Môi trường và Công trình Đô thị Vũng Tàu',
    CompanyNameEn:
      'Vung Tau Environment Services and Urban Project Joint Stock Company ',
  },
  {
    Code: 'MVB',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Công nghiệp mỏ Việt Bắc TKV - Công ty Cổ phần',
    CompanyNameEn: 'Vinacomin - Viet Bac Mining Industry Holding Corporation',
  },
  {
    Code: 'MVC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật liệu và Xây dựng Bình Dương',
    CompanyNameEn: 'Binh Duong Building Materials and Construction Company',
  },
  {
    Code: 'MVN',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Hàng hải Việt Nam - CTCP',
    CompanyNameEn: 'Vietnam Maritime Corporation',
  },
  {
    Code: 'MWG',
    Sector: 'consumers',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Thế Giới Di Động',
    CompanyNameEn: 'Mobile World Investment Corporation',
  },
  {
    Code: 'NAB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Nam Á',
    CompanyNameEn: 'Nam A Commercial Joint Stock Bank',
  },
  {
    Code: 'NAC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Tổng hợp',
    CompanyNameEn:
      'National of General Construction Consultants Joint Stock Company',
  },
  {
    Code: 'NAF',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nafoods Group',
    CompanyNameEn: 'Nafoods Group Joint Stock Company',
  },
  {
    Code: 'NAG',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Nagakawa',
    CompanyNameEn: 'Nagakawa Group Joint Stock Company',
  },
  {
    Code: 'NAP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Nghệ Tĩnh',
    CompanyNameEn: 'Nghe Tinh Port Holding Joint Stock Company',
  },
  {
    Code: 'NAS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Hàng không Sân bay Nội Bài',
    CompanyNameEn: 'Noibai Airport Services Joint Stock Company',
  },
  {
    Code: 'NAU',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Công trình Đô thị Nghệ An',
    CompanyNameEn: 'Nghe An Urban Environment and Works Joint Stock Company',
  },
  {
    Code: 'NAV',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nam Việt',
    CompanyNameEn: 'Nam Viet Joint Stock Company',
  },
  {
    Code: 'NAW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Nghệ An',
    CompanyNameEn: 'Nghe An Water Supply Joint Stock Company',
  },
  {
    Code: 'NBB',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Năm Bảy Bảy',
    CompanyNameEn: '577 Investment Corporation',
  },
  {
    Code: 'NBC',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Núi Béo - Vinacomin',
    CompanyNameEn: 'Vinacomin - Nui Beo Coal Joint Stock Company',
  },
  {
    Code: 'NBE',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị Giáo dục Miền Bắc',
    CompanyNameEn: 'North Books and Educational Equipment Joint Stock Company',
  },
  {
    Code: 'NBP',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhiệt Điện Ninh Bình',
    CompanyNameEn: 'Ninh Binh Thermal Power Joint-Stock Company',
  },
  {
    Code: 'NBT',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Bến Tre',
    CompanyNameEn: 'Ben Tre Water Supply and Sewerage Joint Stock Company',
  },
  {
    Code: 'NBW',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Nhà Bè',
    CompanyNameEn: 'Nha Be Water Supply Joint Stock Company',
  },
  {
    Code: 'NCS',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Suất ăn Hàng không Nội Bài',
    CompanyNameEn: 'Noibai Catering Services Joint Stock Company',
  },
  {
    Code: 'NCT',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Hàng hóa Nội Bài',
    CompanyNameEn: 'Noibai Cargo Terminal Services Joint Stock Company',
  },
  {
    Code: 'ND2',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển điện Miền Bắc 2',
    CompanyNameEn:
      'Northern Electricity Development and Investment Joint Stock Company No. 2',
  },
  {
    Code: 'NDC',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nam Dược',
    CompanyNameEn: 'Nam Duoc Joint Stock Company',
  },
  {
    Code: 'NDF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Chế biến thực phẩm nông sản xuất khẩu Nam Định',
    CompanyNameEn:
      'Nam Dinh Export Foodstuff and Agricultutal Products Processing Joint Stock Company',
  },
  {
    Code: 'NDN',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Nhà Đà Nẵng',
    CompanyNameEn: 'Danang Housing Investment Development Joint Stock Company',
  },
  {
    Code: 'NDP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm 2/9',
    CompanyNameEn: 'National Day Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'NDT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Dệt may Nam Định',
    CompanyNameEn: 'Nam Dinh Textile Garment Joint Stock Corporation',
  },
  {
    Code: 'NDW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Nam Định',
    CompanyNameEn: 'Nam Dinh Water Supply Joint Stock Company',
  },
  {
    Code: 'NDX',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Phát triển Nhà Đà Nẵng',
    CompanyNameEn: 'Danang Housing Development Joint Stock Company',
  },
  {
    Code: 'NED',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Điện Tây Bắc',
    CompanyNameEn:
      'North - West Electric Investment and Development Joint Stock Company',
  },
  {
    Code: 'NET',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bột giặt Net',
    CompanyNameEn: 'Net Detergent Joint Stock Company',
  },
  {
    Code: 'NFC',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phân lân Ninh Bình',
    CompanyNameEn: 'Ninh Binh Phosphate Fertilizer Joint Stock Company',
  },
  {
    Code: 'NGC',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế biến Thủy sản Xuất khẩu Ngô Quyền',
    CompanyNameEn: 'Ngo Quyen Processing Export Joint Stock Company',
  },
  {
    Code: 'NHA',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Đầu tư Phát triển Nhà và Đô thị Nam Hà Nội',
    CompanyNameEn: 'Ha Noi South Housing and Urban Development Corporation',
  },
  {
    Code: 'NHC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Gạch ngói Nhị Hiệp',
    CompanyNameEn: 'Nhi Hiep Brick-Tile Joint Stock Company',
  },
  {
    Code: 'NHH',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Hà Nội',
    CompanyNameEn: 'HaNoi Plastics Joint Stock Company',
  },
  {
    Code: 'NHP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất Xuất nhập khẩu NHP',
    CompanyNameEn: 'NHP Production Import - Export Joint Stock Company',
  },
  {
    Code: 'NHT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất và Thương mại Nam Hoa',
    CompanyNameEn: 'Nam Hoa Trading &amp; Production Corporation',
  },
  {
    Code: 'NHV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sức khỏe Hồi sinh Việt Nam',
    CompanyNameEn: 'Nirvana Health Vietnam Joint Stock Company ',
  },
  {
    Code: 'NJC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Nam Định',
    CompanyNameEn: 'Nam Dinh Garment Joint Stock Company',
  },
  {
    Code: 'NKG',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thép Nam Kim',
    CompanyNameEn: 'Nam Kim Steel Joint Stock Company',
  },
  {
    Code: 'NLG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty cổ phần Đầu tư Nam Long',
    CompanyNameEn: 'Nam Long Investment Corporation',
  },
  {
    Code: 'NLS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Lạng Sơn',
    CompanyNameEn: 'Lang Son Water Supply and Drainage Joint Stock Company',
  },
  {
    Code: 'NNC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đá Núi Nhỏ',
    CompanyNameEn: 'Nui Nho Stone Joint Stock Company',
  },
  {
    Code: 'NNG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Công nghiệp - Dịch vụ - Thương mại Ngọc Nghĩa',
    CompanyNameEn:
      'Ngoc Nghia Industry - Service - Trading Joint Stock Company',
  },
  {
    Code: 'NNT',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Ninh Thuận',
    CompanyNameEn: 'Ninh Thuan Water Supply Joint Stock Company',
  },
  {
    Code: 'NOS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Biển và Thương mại Phương Đông',
    CompanyNameEn: 'Oriental Shipping and Trading Joint Stock Company',
  },
  {
    Code: 'NQB',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Quảng Bình',
    CompanyNameEn: 'Quang Binh Water Supply Joint Stock Company',
  },
  {
    Code: 'NQN',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Clean Water Joint Stock Company',
  },
  {
    Code: 'NQT',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Quảng Trị',
    CompanyNameEn: 'Quang Tri Clean Water Joint Stock Company',
  },
  {
    Code: 'NRC',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Danh Khôi',
    CompanyNameEn: 'Danh Khoi Group Joint Stock Company',
  },
  {
    Code: 'NS2',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch số 2 Hà Nội',
    CompanyNameEn: 'Hanoi Water Supply Number 2 Joint Stock Company',
  },
  {
    Code: 'NSC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Giống cây trồng Việt Nam',
    CompanyNameEn: 'Vietnam National Seed Group Joint Stock Company',
  },
  {
    Code: 'NSG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Sài Gòn',
    CompanyNameEn: 'Sai Gon Plastic Joint Stock Company',
  },
  {
    Code: 'NSH',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Nhôm Sông Hồng Shalumi',
    CompanyNameEn: 'Song Hong Aluminum Shalumi Group Joint Stock Company',
  },
  {
    Code: 'NSL',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Sơn La',
    CompanyNameEn: 'SonLa Water Supply Joint Stock Company',
  },
  {
    Code: 'NSS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nông súc sản Đồng Nai',
    CompanyNameEn:
      'Dong Nai Agricultural Livestock Product Joint Stock Company',
  },
  {
    Code: 'NST',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Ngân Sơn',
    CompanyNameEn: 'Ngan Son Joint Stock Company',
  },
  {
    Code: 'NT2',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Điện lực Dầu khí Nhơn Trạch 2',
    CompanyNameEn: 'PetroVietnam Power Nhon Trach 2 Joint Stock Company',
  },
  {
    Code: 'NTB',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Xây dựng và Khai thác Công trình giao thông 584',
    CompanyNameEn:
      'Transport Engineering Construction And Business Investment Joint Stock Company 584',
  },
  {
    Code: 'NTC',
    Sector: 'industryRealEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khu Công nghiệp Nam Tân Uyên',
    CompanyNameEn: 'Nam Tan Uyen Joint Stock Corporation',
  },
  {
    Code: 'NTF',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược - Vật tư y tế Nghệ An',
    CompanyNameEn:
      'Nghe An Pharmaceutical Medical Material Equipment Joint Stock Company',
  },
  {
    Code: 'NTH',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Nước Trong',
    CompanyNameEn: 'Nuoc trong Thermal Power Joint Stock Company',
  },
  {
    Code: 'NTL',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Đô thị Từ Liêm',
    CompanyNameEn: 'Tu Liem Urban Development Joint Stock Company',
  },
  {
    Code: 'NTP',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Thiếu niên Tiền Phong',
    CompanyNameEn: 'Tien Phong Plastics Joint Stock Company',
  },
  {
    Code: 'NTT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dệt - May Nha Trang',
    CompanyNameEn: 'Nha Trang Textile & Garment Joint Stock Company',
  },
  {
    Code: 'NTW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Nhơn Trạch',
    CompanyNameEn: 'Nhon Trach Water Supply Joint Stock Company',
  },
  {
    Code: 'NUE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Nha Trang',
    CompanyNameEn: 'Nha Trang Urban Environmental Joint Stock Company',
  },
  {
    Code: 'NVB',
    Sector: 'banks',
    Exchange: 'HNX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Quốc Dân',
    CompanyNameEn: 'National Citizen Commercial Joint Stock Bank',
  },
  {
    Code: 'NVL',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đầu tư Địa ốc No Va',
    CompanyNameEn: 'No Va Land Investment Group Corporation',
  },
  {
    Code: 'NVP',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Vĩnh Phúc',
    CompanyNameEn: 'Vinh Phuc Water Supply Joint Stock Company',
  },
  {
    Code: 'NVT',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản Du lịch Ninh Vân Bay',
    CompanyNameEn: 'Ninh Van Bay Travel Real Estate Joint Stock Company',
  },
  {
    Code: 'NWT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Newway',
    CompanyNameEn: 'Newway Transport Joint Stock Company',
  },
  {
    Code: 'NXT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Sản xuất và Cung ứng vật liệu xây dựng Kon Tum',
    CompanyNameEn:
      'Kon Tum Construction Materials Supply And Production Joint Stock Company',
  },
  {
    Code: 'OCB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Phương Đông',
    CompanyNameEn: 'Orient Commercial Joint Stock Bank',
  },
  {
    Code: 'OCH',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần One Capital Hospitality',
    CompanyNameEn: 'One Capital Hospitality Joint Stock Company',
  },
  {
    Code: 'ODE',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Tập đoàn Truyền thông và Giải trí ODE',
    CompanyNameEn: 'ODE Media and Entertainment Group Joint Stock Company',
  },
  {
    Code: 'OGC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đại Dương',
    CompanyNameEn: 'Ocean Group Joint Stock Company',
  },
  {
    Code: 'OIL',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Dầu Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'PetroVietnam Oil Corporation',
  },
  {
    Code: 'ONE',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Truyền thông số 1',
    CompanyNameEn: 'One Communication Technology Corporation',
  },
  {
    Code: 'ONW',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Một Thế giới',
    CompanyNameEn: 'One World Services Joint Stock Company',
  },
  {
    Code: 'OPC',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược Phẩm OPC',
    CompanyNameEn: 'OPC Pharmaceutial Joint Stock Company',
  },
  {
    Code: 'ORS',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Tiên Phong',
    CompanyNameEn: 'Tien Phong Securities Corporation',
  },
  {
    Code: 'PAC',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Pin Ắc quy Miền Nam',
    CompanyNameEn: 'Dry Cell and Storage Battery Joint Stock Company',
  },
  {
    Code: 'PAI',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Công nghê thông tin, Viễn thông và Tự động hóa Dầu khí',
    CompanyNameEn:
      'Petroleum Information Technology Telecom and Automation Joint Stock Company',
  },
  {
    Code: 'PAN',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'CTCP TAP DOAN PAN',
    CompanyNameEn: 'The PAN Group Joint Stock Company',
  },
  {
    Code: 'PAP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dầu khí đầu tư khai thác Cảng Phước An',
    CompanyNameEn:
      'Petro Vietnam Phuoc An Port Investment & Operation Join Stock Company',
  },
  {
    Code: 'PAS',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Quốc tế Phương Anh',
    CompanyNameEn: 'Phuong Anh International Joint Stock Company',
  },
  {
    Code: 'PAT',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phốt pho Apatit Việt Nam',
    CompanyNameEn: 'Vietnam Apatite - Phosphorus Joint Stock Company',
  },
  {
    Code: 'PBC',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Phẩm Trung ương 1 - Pharbaco',
    CompanyNameEn: 'Pharbaco Central Pharmaceutical Joint Stock Company No. 1',
  },
  {
    Code: 'PBP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Dầu khí Việt Nam',
    CompanyNameEn: 'PetroVietnam Packaging Joint Stock Company',
  },
  {
    Code: 'PBT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ Phần Nhà và Thương mại Dầu khí',
    CompanyNameEn: 'Petro Vietnam Building and Commercial Joint Stock Company',
  },
  {
    Code: 'PC1',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn PC1',
    CompanyNameEn: 'PC1 Group Joint Stock Company',
  },
  {
    Code: 'PCC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Xây lắp 1 - Petrolimex',
    CompanyNameEn: 'Petrolimex Construction 1 Joint Stock Company Group',
  },
  {
    Code: 'PCE',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón và hóa chất dầu khí Miền Trung',
    CompanyNameEn:
      'Central Petrovietnam Fertilizer and Chemicals Joint Stock Company',
  },
  {
    Code: 'PCF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà phê PETEC',
    CompanyNameEn: 'PETEC Coffee Joint Stock Company',
  },
  {
    Code: 'PCG',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Gas Đô thị',
    CompanyNameEn:
      'PetroVietnam Gas City Investment and Deverlopment Joint Stock Company',
  },
  {
    Code: 'PCH',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Picomat',
    CompanyNameEn: 'Picomat Plastic Joint Stock Company',
  },
  {
    Code: 'PCM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật liệu Xây dựng Bưu điện',
    CompanyNameEn:
      'Post and Telecommunications Construction Material Joint Stock Company',
  },
  {
    Code: 'PCN',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hóa phẩm dầu khí DMC - Miền Bắc',
    CompanyNameEn: 'DMC - Northern Petroleum Chemicals Joint Stock Company',
  },
  {
    Code: 'PCT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Khí và Hóa chất Việt Nam',
    CompanyNameEn: 'Vietnam Gas and Chemicals Transportation Corporation',
  },
  {
    Code: 'PDB',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đầu tư DIN Capital',
    CompanyNameEn: 'DIN Capital Investment Group Joint Stock Company',
  },
  {
    Code: 'PDC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Dầu khí Phương Đông',
    CompanyNameEn: 'Phuong Dong Petroleum Tourism Joint Stock Company',
  },
  {
    Code: 'PDN',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Đồng Nai',
    CompanyNameEn: 'Dong Nai Port Joint Stock Company',
  },
  {
    Code: 'PDR',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Bất động sản Phát Đạt',
    CompanyNameEn: 'Phat Dat Real Estate Development Corporation',
  },
  {
    Code: 'PDV',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Tiếp vận Phương Đông Việt',
    CompanyNameEn: 'Phuong Dong Viet Shipping and Logistics Corporation',
  },
  {
    Code: 'PEC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Điện lực',
    CompanyNameEn: 'Power Engineering Joint Stock Company',
  },
  {
    Code: 'PEG',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng Công ty Thương mại Kỹ thuật và Đầu tư - Công ty Cổ phần',
    CompanyNameEn: 'PETEC Trading and Investment Corporation',
  },
  {
    Code: 'PEN',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp III Petrolimex',
    CompanyNameEn: 'Petrolimex Installation No.3 joint stock company',
  },
  {
    Code: 'PEQ',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị xăng dầu Petrolimex',
    CompanyNameEn: 'Petrolimex Equipment Joint Stock Company',
  },
  {
    Code: 'PET',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Dịch vụ Tổng hợp Dầu khí',
    CompanyNameEn: 'Petrovietnam General Services Corporation',
  },
  {
    Code: 'PFL',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dầu khí Đông Đô',
    CompanyNameEn: 'Petroleum Dong Do Joint Stock Company',
  },
  {
    Code: 'PGB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Xăng dầu Petrolimex',
    CompanyNameEn: 'Petrolimex Group Commercial Joint Stock Bank',
  },
  {
    Code: 'PGC',
    Sector: 'energy',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Gas Petrolimex - Công ty Cổ phần',
    CompanyNameEn: 'Petrolimex Gas Corporation - Joint Stock Company',
  },
  {
    Code: 'PGD',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phân phối khí thấp áp dầu khí Việt Nam',
    CompanyNameEn:
      'Petro Viet Nam Low Pressure Gas Distribution Joint Stock Company',
  },
  {
    Code: 'PGI',
    Sector: 'insurances',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo hiểm Petrolimex',
    CompanyNameEn: 'Petrolimex Insurance Corporation',
  },
  {
    Code: 'PGN',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phụ Gia Nhựa',
    CompanyNameEn: 'Plastic Additives Joint Stock Company',
  },
  {
    Code: 'PGS',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh Khí Miền Nam',
    CompanyNameEn: 'Southern Gas Trading Joint Stock Company',
  },
  {
    Code: 'PGT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần PGT Holdings',
    CompanyNameEn: 'PGT Holdings Joint Stock Company',
  },
  {
    Code: 'PGV',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Phát điện 3 - Công ty Cổ phần',
    CompanyNameEn: 'Power Generation Joint Stock Corporation 3',
  },
  {
    Code: 'PHC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Phục Hưng Holdings',
    CompanyNameEn: 'Phuc Hung Holdings Construction Joint Stock Company',
  },
  {
    Code: 'PHH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hồng Hà Việt Nam',
    CompanyNameEn: 'Hong Ha Vietnam Joint Stock Company',
  },
  {
    Code: 'PHN',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Pin Hà Nội',
    CompanyNameEn: 'Hanoi Battery Joint Stock Company',
  },
  {
    Code: 'PHP',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Hải Phòng',
    CompanyNameEn: 'Hai Phong Port Joint Stock Company',
  },
  {
    Code: 'PHR',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Phước Hòa',
    CompanyNameEn: 'Phuoc Hoa Rubber Joint Stock Company',
  },
  {
    Code: 'PHS',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Phú Hưng',
    CompanyNameEn: 'Phu Hung Securities Corporation',
  },
  {
    Code: 'PIA',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tin học Viễn thông Petrolimex',
    CompanyNameEn:
      'Petrolimex Information Technology and Telecommunication Joint Stock Company',
  },
  {
    Code: 'PIC',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Điện lực 3',
    CompanyNameEn: 'PC3-Investment Joint Stock Company',
  },
  {
    Code: 'PID',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trang trí nội thất Dầu khí',
    CompanyNameEn: 'Petroleum Interior Decoration Joint Stock Company',
  },
  {
    Code: 'PIS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Pisico Bình Định - Công ty Cổ phần',
    CompanyNameEn: 'PISICO Binh Dinh Corporation Joint Stock Company',
  },
  {
    Code: 'PIT',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Petrolimex',
    CompanyNameEn: 'Petrolimex International Trading Joint Stock Company',
  },
  {
    Code: 'PIV',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần PIV',
    CompanyNameEn: 'PIV Joint Stock Company',
  },
  {
    Code: 'PJC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại và Vận tải Petrolimex Hà Nội',
    CompanyNameEn:
      'Petrolimex Ha Noi Transportation and Trading Joint-Stock Company',
  },
  {
    Code: 'PJS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Phú Hòa Tân',
    CompanyNameEn: 'Phu Hoa Tan Water Supply Joint Stock Company',
  },
  {
    Code: 'PJT',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Xăng dầu đường thủy Petrolimex',
    CompanyNameEn: 'Petrolimex Joint Stock Tanker Company',
  },
  {
    Code: 'PLA',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Dịch vụ hạ tầng Xăng dầu',
    CompanyNameEn:
      'Petroleum Logistic Service and Investment Joint Stock Company',
  },
  {
    Code: 'PLC',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Hóa dầu Petrolimex - Công ty Cổ phần',
    CompanyNameEn: 'Petrolimex Petrochemical Corporation',
  },
  {
    Code: 'PLE',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Petrolimex',
    CompanyNameEn: 'Petrolimex Engineering Joint Stock Company',
  },
  {
    Code: 'PLO',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kho vận Petec',
    CompanyNameEn: 'Petec Logistics Joint Stock Company',
  },
  {
    Code: 'PLP',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất và Công nghệ Nhựa Pha Lê',
    CompanyNameEn:
      'Pha Le Plastics Manufacturing and Technology Joint Stock Company',
  },
  {
    Code: 'PLX',
    Sector: 'energy',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tập đoàn Xăng dầu Việt Nam',
    CompanyNameEn: 'Vietnam National Petroleum Group',
  },
  {
    Code: 'PMB',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón và Hóa chất Dầu khí Miền Bắc',
    CompanyNameEn:
      'North PetroVietnam Fertilizer and Chemicals Joint Stock Company',
  },
  {
    Code: 'PMC',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Dược liệu Pharmedic',
    CompanyNameEn: 'Pharmedic Pharmaceutical Medicinal Joint Stock Company',
  },
  {
    Code: 'PMG',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Sản xuất Petro Miền Trung',
    CompanyNameEn: 'Petro Center Corporation',
  },
  {
    Code: 'PMJ',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Vật tư Bưu điện',
    CompanyNameEn:
      'Posts and Telecommunications Material Supply Joint Stock Company',
  },
  {
    Code: 'PMP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Đạm Phú Mỹ',
    CompanyNameEn: 'Dam Phu My Packing Joint Stock Company',
  },
  {
    Code: 'PMS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Xăng dầu',
    CompanyNameEn: 'Petroleum Mechanical Joint Stock Company',
  },
  {
    Code: 'PMT',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Viễn thông Telvina Việt Nam',
    CompanyNameEn: 'Telvina Vietnam Communication Joint Stock Company',
  },
  {
    Code: 'PMW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp Nước Phú Mỹ',
    CompanyNameEn: 'Phu My Water Supply Joint-Stock Company',
  },
  {
    Code: 'PNC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Văn hóa Phương Nam',
    CompanyNameEn: 'Phuong Nam Cultural Joint Stock Company',
  },
  {
    Code: 'PND',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xăng dầu Dầu khí Nam Định',
    CompanyNameEn: 'PetroVietnam Oil Nam Dinh Joint Stock Company',
  },
  {
    Code: 'PNG',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương Mại Phú Nhuận',
    CompanyNameEn: 'Phu Nhuan Trading Joint Stock Company',
  },
  {
    Code: 'PNJ',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vàng bạc Đá quý Phú Nhuận',
    CompanyNameEn: 'Phu Nhuan Jewelry Joint Stock Company',
  },
  {
    Code: 'PNP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tân Cảng – Phú Hữu',
    CompanyNameEn: 'Huu Phu - Newport Corporation',
  },
  {
    Code: 'PNT',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kỹ thuật Xây dựng Phú Nhuận',
    CompanyNameEn: 'Phu Nhuan Technical Construction Joint Stock Company',
  },
  {
    Code: 'POB',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xăng dầu Dầu khí Thái Bình',
    CompanyNameEn: 'Thai Binh Petrovietnam Oil Joint Stock Company',
  },
  {
    Code: 'POM',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thép Pomina',
    CompanyNameEn: 'Pomina Steel Corporation',
  },
  {
    Code: 'POS',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Dịch vụ lắp đặt, vận hành và bảo dưỡng công trình dầu khí biển PTSC',
    CompanyNameEn: 'PTSC Offshore Services Joint Stock Company',
  },
  {
    Code: 'POT',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị Bưu điện',
    CompanyNameEn: 'Post and Telecommunication Equipment JSC',
  },
  {
    Code: 'POV',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xăng dầu Dầu khí Vũng Áng',
    CompanyNameEn: 'Vung Ang Petroleum Joint Stock Company',
  },
  {
    Code: 'POW',
    Sector: 'utility',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tổng Công ty Điện lực Dầu khí Việt Nam - CTCP',
    CompanyNameEn: 'PetroVietnam Power Corporation',
  },
  {
    Code: 'PPC',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhiệt điện Phả Lại',
    CompanyNameEn: 'Pha Lai Thermal Power Joint Stock Company',
  },
  {
    Code: 'PPE',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Đầu tư PP Enterprise',
    CompanyNameEn: 'PP Enterprise Investment Consultancy Joint Stock Company',
  },
  {
    Code: 'PPH',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Phong Phú',
    CompanyNameEn: 'Phong Phu Corporation',
  },
  {
    Code: 'PPI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư và Phát triển dự án hạ tầng Thái Bình Dương',
    CompanyNameEn:
      'Pacific Infrastructure Project Development And Investment Corporation',
  },
  {
    Code: 'PPP',
    Sector: 'healthcare',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Phong Phú',
    CompanyNameEn: 'Phong Phu Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'PPS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Kỹ thuật Điện lực Dầu khí Việt Nam',
    CompanyNameEn: 'PetroVietnam Power Services Joint Stock Company',
  },
  {
    Code: 'PPT',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty cổ phần Petro Times\nCÔNG TY CỔ PHẦN PETRO TIMES - UPCOM\nCÔNG TY CỔ PHẦN PETRO TIMES - UPCOM\nCÔNG TY CỔ PHẦN PETRO TIMES - UPCOM\n',
    CompanyNameEn: 'Petro Times Joint Stock Company',
  },
  {
    Code: 'PPY',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xăng dầu dầu khí Phú Yên',
    CompanyNameEn: 'Petrovietnam Oil Phu Yen Joint Stock Company',
  },
  {
    Code: 'PQN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Dầu khí Quảng Ngãi PTSC',
    CompanyNameEn: 'PTSC Quang Ngai Joint Stock Company',
  },
  {
    Code: 'PRC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Logistics Portserco',
    CompanyNameEn: 'Portserco Logistics Joint Stock Company',
  },
  {
    Code: 'PRE',
    Sector: 'insurances',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Tái bảo hiểm PVI',
    CompanyNameEn: 'PVI Reinsurance Joint Stock Corporation',
  },
  {
    Code: 'PRO',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Procimex Việt Nam',
    CompanyNameEn: 'Viet Nam  Procimex Joint Stock Company',
  },
  {
    Code: 'PRT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng Công ty Sản xuất - Xuất nhập khẩu Bình Dương - Công ty cổ phần',
    CompanyNameEn: 'Binh Duong Producing and Trading Corporation',
  },
  {
    Code: 'PSB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Dầu khí Sao Mai Bến Đình',
    CompanyNameEn:
      'Sao Mai - Ben Dinh Petroleum Investment Joint Stock Company',
  },
  {
    Code: 'PSC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Sài Gòn',
    CompanyNameEn:
      'Petrolimex Saigon Transportation And Service Joint Stock Company',
  },
  {
    Code: 'PSD',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Phân phối Tổng hợp Dầu khí',
    CompanyNameEn:
      'Petroleum General Distribution Services Joint Stock Company',
  },
  {
    Code: 'PSE',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón và hóa chất dầu khí Đông Nam Bộ',
    CompanyNameEn:
      'South-East PetroVietnam Fertilizer and Chemicals Joint Stock Company',
  },
  {
    Code: 'PSG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây lắp Dầu khí Sài Gòn',
    CompanyNameEn:
      'Saigon Petroleum Construction and Investment Joint Stock Company',
  },
  {
    Code: 'PSH',
    Sector: 'energy',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Đầu tư Dầu khí Nam Sông Hậu',
    CompanyNameEn:
      'Nam Song Hau Trading Investing Petroleum Joint Stock Company',
  },
  {
    Code: 'PSI',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Dầu khí',
    CompanyNameEn: 'Petrovietnam Securities Incorporated',
  },
  {
    Code: 'PSL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chăn nuôi Phú Sơn',
    CompanyNameEn: 'Phu Son LiveStock Joint Stock Company',
  },
  {
    Code: 'PSN',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Kỹ thuật PTSC Thanh Hóa',
    CompanyNameEn: 'PTSC Thanh Hoa Technical Services Company',
  },
  {
    Code: 'PSP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Dịch vụ Dầu khí Đình Vũ',
    CompanyNameEn: 'Dinh Vu Petroleum Service Port Joint Stock Company',
  },
  {
    Code: 'PSW',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón và hóa chất dầu khí Tây Nam Bộ',
    CompanyNameEn:
      'South West PetroVietNam Fertilizer and Chemicals Joint Stock Company',
  },
  {
    Code: 'PTB',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phú Tài',
    CompanyNameEn: 'Phu Tai Joint Stock Company',
  },
  {
    Code: 'PTC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư ICAPITAL',
    CompanyNameEn: 'ICAPITAL Investment Joint Stock Company',
  },
  {
    Code: 'PTD',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Thiết kế - Xây dựng - Thương mại Phúc Thịnh',
    CompanyNameEn: 'Phuc Thinh Design Construction Trading Corporation',
  },
  {
    Code: 'PTE',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Phú Thọ',
    CompanyNameEn: 'Phu Tho Cement Joint Stock Company',
  },
  {
    Code: 'PTG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Xuất Khẩu Phan Thiết',
    CompanyNameEn: 'Phan Thiet Garment Import Export Joint Stock Company',
  },
  {
    Code: 'PTH',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Hà Tây',
    CompanyNameEn: 'Ha Tay Petrolimex Transportation and Service JSC',
  },
  {
    Code: 'PTI',
    Sector: 'insurances',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bảo hiểm Bưu điện',
    CompanyNameEn:
      'Post &amp; Telecommunication Joint Stock Insurance Corporation',
  },
  {
    Code: 'PTL',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Victory Capital',
    CompanyNameEn: 'Victory Capital Joint Stock Company',
  },
  {
    Code: 'PTN',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Nhà Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa House Development Joint Stock Company',
  },
  {
    Code: 'PTO',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ - Xây dựng Công trình Bưu điện',
    CompanyNameEn:
      'Post and Telecommunication Services Construction Work Joint Stock Company',
  },
  {
    Code: 'PTP',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Viễn Thông và In Bưu điện',
    CompanyNameEn:
      'Post Printing And Telecommunication Services Joint Stock Company',
  },
  {
    Code: 'PTS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Hải Phòng',
    CompanyNameEn:
      'Hai Phong Petrolimex Transportation and Services Joint Stock Company',
  },
  {
    Code: 'PTT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Dầu khí Đông Dương',
    CompanyNameEn: 'Indochina Petroleum Transportation Joint Stock Company',
  },
  {
    Code: 'PTV',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Dầu khí',
    CompanyNameEn: 'Petroleum Trading Joint Stock Company',
  },
  {
    Code: 'PTX',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Petrolimex Nghệ Tĩnh',
    CompanyNameEn:
      'Petrolimex Nghe Tinh Transportation and Service Joint Stock Company',
  },
  {
    Code: 'PV2',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư PV2',
    CompanyNameEn: 'PV2 Investment Joint Stock Company',
  },
  {
    Code: 'PVA',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Xây lắp Dầu khí Nghệ An',
    CompanyNameEn:
      'PetroVietnam - Nghe An Construction Joint Stock Corporation',
  },
  {
    Code: 'PVB',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bọc ống Dầu khí Việt Nam',
    CompanyNameEn: 'PetroVietnam Coating Joint Stock Company',
  },
  {
    Code: 'PVC',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Hóa chất và Dịch vụ Dầu khí - Công ty Cổ phần',
    CompanyNameEn: 'Petrovietnam Chemical and Services Corporation',
  },
  {
    Code: 'PVD',
    Sector: 'energy',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Khoan và Dịch vụ khoan Dầu khí',
    CompanyNameEn: 'PetroVietNam Driling &amp; Well Services Corporation',
  },
  {
    Code: 'PVE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Tư vấn Thiết kế Dầu khí - Công ty Cổ phần',
    CompanyNameEn: 'PetroVietnam Engineering Corporation',
  },
  {
    Code: 'PVG',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh LPG Việt Nam',
    CompanyNameEn: 'Petro Vietnam LPG Joint Stock Company',
  },
  {
    Code: 'PVH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Dầu khí Thanh Hóa',
    CompanyNameEn: 'Thanh Hoa Petroleum Construction Joint Stock Company',
  },
  {
    Code: 'PVI',
    Sector: 'insurances',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần PVI',
    CompanyNameEn: 'PVI Holdings',
  },
  {
    Code: 'PVL',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Nhà Đất Việt',
    CompanyNameEn: 'Viet Property Investment Joint Stock Company',
  },
  {
    Code: 'PVM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Máy - Thiết bị Dầu khí',
    CompanyNameEn: 'Petrovietnam Machinery-Technology Joint Stock Company',
  },
  {
    Code: 'PVO',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dầu nhờn PV Oil',
    CompanyNameEn: 'PV Oil Lube Joint Stock Company',
  },
  {
    Code: 'PVP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Dầu khí Thái Bình Dương',
    CompanyNameEn: 'Pacific Petroleum Transportation Joint Stock Company',
  },
  {
    Code: 'PVR',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư PVR Hà Nội',
    CompanyNameEn: 'Ha Noi PVR Investment Joint Stock Company',
  },
  {
    Code: 'PVS',
    Sector: 'energy',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Dịch vụ Kỹ thuật Dầu khí Việt Nam',
    CompanyNameEn: 'PetroVietnam Technical Services Corporation',
  },
  {
    Code: 'PVT',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Vận tải Dầu khí',
    CompanyNameEn: 'PetroVietNam Transportation Corporation',
  },
  {
    Code: 'PVV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vinaconex 39',
    CompanyNameEn: 'Vinaconex 39 Joint Stock Company',
  },
  {
    Code: 'PVX',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Xây lắp Dầu khí Việt Nam',
    CompanyNameEn: 'Petro Vietnam Construction Joint Stock Corporation',
  },
  {
    Code: 'PVY',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo Giàn khoan Dầu khí',
    CompanyNameEn: 'PetroVietnam Marine Shipyard Joint Stock Company',
  },
  {
    Code: 'PWA',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản dầu khí',
    CompanyNameEn: 'Petrowaco Property Joint Stock Company',
  },
  {
    Code: 'PWS',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Phú Yên',
    CompanyNameEn: 'Phu Yen Water Supply and Sewerage Joint Stock Company',
  },
  {
    Code: 'PX1',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Sông Lam 2',
    CompanyNameEn: 'Cement Joint Stock Company Song Lam 2',
  },
  {
    Code: 'PXA',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Thương mại Dầu khí Nghệ An',
    CompanyNameEn:
      'PetroVietnam - Nghe An Investment &amp; Trading Joint Stock Company',
  },
  {
    Code: 'PXC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Đô thị Dầu khí',
    CompanyNameEn: 'Petrovietnam Urban Develoment Joint Stock Company',
  },
  {
    Code: 'PXI',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Công nghiệp và Dân dụng Dầu khí',
    CompanyNameEn:
      'Petroleum Industrial and Civil Construction Joint Stock Company',
  },
  {
    Code: 'PXL',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Khu Công Nghiệp Dầu khí Long Sơn',
    CompanyNameEn:
      'Long Son Petroleum Industrial Zone Investment Joint Stock Company',
  },
  {
    Code: 'PXM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Dầu khí Miền Trung',
    CompanyNameEn: 'Mientrung Petroleum Construction Joint Stock Company',
  },
  {
    Code: 'PXS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kết cấu Kim loại và Lắp máy Dầu khí',
    CompanyNameEn:
      'Petroleum Equipment Assembly And Metal Structure Joint Stock Company',
  },
  {
    Code: 'PXT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Đường ống Bể chứa Dầu khí',
    CompanyNameEn:
      'Petroleum Pipeline And Tank Construction Joint Stock Company',
  },
  {
    Code: 'QBS',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Quảng Bình',
    CompanyNameEn: 'Quang Binh Import And Export Joint Stock Company',
  },
  {
    Code: 'QCC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Xây dựng và Phát triển Hạ tầng Viễn Thông',
    CompanyNameEn:
      'Construction Investment and Telecommunications Infrastructure Development Joint Stock Company',
  },
  {
    Code: 'QCG',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Quốc Cường Gia Lai',
    CompanyNameEn: 'Quoc Cuong Gia Lai Joint Stock Company',
  },
  {
    Code: 'QHD',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Que hàn điện Việt Đức',
    CompanyNameEn: 'Viet-Duc Welding Electrode Joint Stock Company',
  },
  {
    Code: 'QHW',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước khoáng Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Mineral Water Corporation',
  },
  {
    Code: 'QLT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Quản lý Bảo trì Đường thủy Nội địa số 10',
    CompanyNameEn:
      'Inland Waterway Management Maintenance Joint Stock Company No.10',
  },
  {
    Code: 'QNC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng và Xây dựng Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Construction and Cement Joint Stock Company',
  },
  {
    Code: 'QNS',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đường Quảng Ngãi',
    CompanyNameEn: 'Quang Ngai Sugar Joint Stock Company',
  },
  {
    Code: 'QNT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn và Đầu tư Phát triển Quảng Nam',
    CompanyNameEn:
      'Quang Nam Consulting and Investment Development Joint Stock Company',
  },
  {
    Code: 'QNU',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Đô thị Quảng Nam',
    CompanyNameEn: 'Quang Nam Urban Environment Joint Stock Company',
  },
  {
    Code: 'QNW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước và xây dựng Quảng Ngãi',
    CompanyNameEn:
      'Quang Ngai Water Supply Sewerage and Construction Joint Stock Company',
  },
  {
    Code: 'QPH',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Quế Phong',
    CompanyNameEn: 'Que Phong Hydropower Joint Stock Company',
  },
  {
    Code: 'QSP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tân cảng Quy Nhơn',
    CompanyNameEn: 'Quy Nhon New Port Joint Stock Company',
  },
  {
    Code: 'QST',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và Thiết bị trường học Quảng Ninh',
    CompanyNameEn:
      'Quang Ninh Book And Educational Equipment Joint Stock Company',
  },
  {
    Code: 'QTC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Công trình Giao thông Vận tải Quảng Nam',
    CompanyNameEn: 'Quang Nam Transportion Construction Joint Stock Company',
  },
  {
    Code: 'QTP',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nhiệt điện Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Thermal Power Joint Stock Company',
  },
  {
    Code: 'RAL',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bóng đèn Phích nước Rạng Đông',
    CompanyNameEn:
      'Rang Dong Light Sources and Vacuum Flask Joint Stock Company',
  },
  {
    Code: 'RAT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Thương mại Đường sắt',
    CompanyNameEn: 'Railway Transport and Trade Joint Stock Company',
  },
  {
    Code: 'RBC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công Nghiệp và Xuất nhập khẩu Cao Su',
    CompanyNameEn: 'Rubber Industry &amp; Import- Export Joint Stock Company',
  },
  {
    Code: 'RCC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Công trình Đường sắt',
    CompanyNameEn: 'Railway Construction Corporation Joint Stock Company',
  },
  {
    Code: 'RCD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng - Địa ốc Cao su',
    CompanyNameEn: 'Rubber Real Estate Construction Joint Stock Company',
  },
  {
    Code: 'RCL',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Địa ốc Chợ Lớn',
    CompanyNameEn: 'Cho Lon Real Estate Joint Stock Company',
  },
  {
    Code: 'RDP',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Rạng Đông Holding',
    CompanyNameEn: 'Rang Dong Holding Joint-Stock Company',
  },
  {
    Code: 'REE',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Lạnh',
    CompanyNameEn: 'Refrigeration Electrical Engineering Corporation',
  },
  {
    Code: 'RGC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư PV-Inconess',
    CompanyNameEn: 'PV-Inconess Investment Joint Stock Company',
  },
  {
    Code: 'RIC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Quốc Tế Hoàng Gia',
    CompanyNameEn: 'Royal International Corporation',
  },
  {
    Code: 'RTB',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cao su Tân Biên',
    CompanyNameEn: 'Tan Bien Rubber Joint Stock Company',
  },
  {
    Code: 'S12',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 12',
    CompanyNameEn: 'Song Da No 12 Joint Stock Company',
  },
  {
    Code: 'S27',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 27',
    CompanyNameEn: 'Song Da 27 Joint Stock Company',
  },
  {
    Code: 'S4A',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Sê San 4A',
    CompanyNameEn: 'SeSan 4A HydroElectric Joint Stock Company',
  },
  {
    Code: 'S55',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 505',
    CompanyNameEn: 'Song Da 505 Joint Stock Company',
  },
  {
    Code: 'S72',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 7.02',
    CompanyNameEn: 'Song Da 7.02 Hydroelectric Joint Stock Company',
  },
  {
    Code: 'S74',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 7.04',
    CompanyNameEn: 'Song Da 7.04 Joint Stock Company',
  },
  {
    Code: 'S96',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 9.06',
    CompanyNameEn: 'Song Da 9.06 Joint Stock Company',
  },
  {
    Code: 'S99',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần SCI',
    CompanyNameEn: 'SCI Joint Stock Company',
  },
  {
    Code: 'SAB',
    Sector: 'agricalture',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bia - Rượu - Nước Giải khát Sài Gòn',
    CompanyNameEn: 'Saigon Beer - Alcohol - Beverage Corporation',
  },
  {
    Code: 'SAC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xếp dỡ và Dịch vụ cảng Sài Gòn',
    CompanyNameEn: 'Sai Gon Port Stevedoring And Service Joint Stock Company',
  },
  {
    Code: 'SAF',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Lương thực Thực phẩm SAFOCO',
    CompanyNameEn: 'Safoco Foodstuff Joint Stock Company',
  },
  {
    Code: 'SAL',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trục vớt Cứu hộ Việt Nam',
    CompanyNameEn: 'Viet Nam Salvage Joint Stock Company',
  },
  {
    Code: 'SAM',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần SAM Holdings',
    CompanyNameEn: 'SAM Holdings Corporation',
  },
  {
    Code: 'SAP',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần In Sách Giáo khoa TP.Hồ Chí Minh',
    CompanyNameEn: 'Textbook Printing Joint Stock Company in Ho Chi Minh City',
  },
  {
    Code: 'SAS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Hàng không Sân bay Tân Sơn Nhất',
    CompanyNameEn: 'Southern Airports Services Joint Stock Company',
  },
  {
    Code: 'SAV',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hợp tác Kinh tế và Xuất nhập khẩu Savimex',
    CompanyNameEn: 'Savimex Corporation',
  },
  {
    Code: 'SB1',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Nghệ Tĩnh',
    CompanyNameEn: 'Sai Gon - Nghe Tinh Beer Joint Stock Company ',
  },
  {
    Code: 'SBA',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sông Ba',
    CompanyNameEn: 'Song Ba Joint Stock Company',
  },
  {
    Code: 'SBD',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ Sao Bắc Đẩu',
    CompanyNameEn: 'SaoBacDau Technologies Corporation',
  },
  {
    Code: 'SBH',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Sông Ba Hạ',
    CompanyNameEn: 'Song Ba Ha Hydro Power Joint Stock Company',
  },
  {
    Code: 'SBL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Bạc Liêu',
    CompanyNameEn: 'Sai Gon - Bac Lieu Beer Joint Stock Company',
  },
  {
    Code: 'SBM',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư phát triển Bắc Minh',
    CompanyNameEn: 'Bac Minh Development Investment Joint Stock Company',
  },
  {
    Code: 'SBR',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cao su Sông Bé',
    CompanyNameEn: 'Song Be Rubber Joint Stock Company',
  },
  {
    Code: 'SBS',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán SBS',
    CompanyNameEn: 'SBS Securities Joint Stock Company',
  },
  {
    Code: 'SBT',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thành Thành Công - Biên Hòa',
    CompanyNameEn: 'Thanh Thanh Cong - Bien Hoa Joint Stock Company',
  },
  {
    Code: 'SBV',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Siam Brothers Việt Nam',
    CompanyNameEn: 'Siam Brothers Vietnam Joint Stock Company',
  },
  {
    Code: 'SC5',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 5',
    CompanyNameEn: 'Construction Joint Stock Company No 5',
  },
  {
    Code: 'SCC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Đầu tư SHB',
    CompanyNameEn: 'SHB Trading Investment Joint Stock Company',
  },
  {
    Code: 'SCD',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nước giải khát Chương Dương',
    CompanyNameEn: 'Chuong Duong Beverages Joint Stock Company',
  },
  {
    Code: 'SCG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Xây dựng SCG',
    CompanyNameEn: 'SCG Construction Group Joint Stock Company',
  },
  {
    Code: 'SCI',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần SCI E&C',
    CompanyNameEn: 'SCI E&C Joint Stock Company',
  },
  {
    Code: 'SCJ',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Sài Sơn',
    CompanyNameEn: 'Sai Son Cement Joint Stock Company',
  },
  {
    Code: 'SCL',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà Cao Cường',
    CompanyNameEn: 'Song Da Cao Cuong Joint Stock Company',
  },
  {
    Code: 'SCO',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Thủy sản',
    CompanyNameEn: 'Seaproducts Mechnical Shareholding Company',
  },
  {
    Code: 'SCR',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Địa Ốc Sài Gòn Thương Tín',
    CompanyNameEn: 'Sai Gon Thuong Tin Real Estate Joint Stock Company',
  },
  {
    Code: 'SCS',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Hàng hóa Sài Gòn',
    CompanyNameEn: 'Saigon Cargo Service Corporation',
  },
  {
    Code: 'SCV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Muối Việt Nam',
    CompanyNameEn: 'Vietnam National Salt Joint Stock Company',
  },
  {
    Code: 'SCY',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đóng tàu Sông Cấm',
    CompanyNameEn: 'Song Cam Shipbuilding Joint Stock Company',
  },
  {
    Code: 'SD1',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 1',
    CompanyNameEn: 'Song Da 1 Joint Stock Company',
  },
  {
    Code: 'SD2',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 2',
    CompanyNameEn: 'Song Da 2 Joint Stock Company',
  },
  {
    Code: 'SD3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 3',
    CompanyNameEn: 'Song Da 3 Joint Stock Company',
  },
  {
    Code: 'SD4',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 4',
    CompanyNameEn: 'Song Da 4 Joint Stock Company',
  },
  {
    Code: 'SD5',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 5',
    CompanyNameEn: 'Song Da No5 Joint Stock Company',
  },
  {
    Code: 'SD6',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 6',
    CompanyNameEn: 'Song Da Joint Stock Company No 6',
  },
  {
    Code: 'SD7',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 7',
    CompanyNameEn: 'Songda 7 Joint Stock Company',
  },
  {
    Code: 'SD8',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 8',
    CompanyNameEn: 'Song Da Joint Stock Company No 8',
  },
  {
    Code: 'SD9',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 9',
    CompanyNameEn: 'Song Da No9 Joint Stock Company',
  },
  {
    Code: 'SDA',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần SIMCO Sông Đà',
    CompanyNameEn: 'SIMCO Song Da Joint Stock Company',
  },
  {
    Code: 'SDB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 207',
    CompanyNameEn: 'Song Da 207 Joint Stock Company',
  },
  {
    Code: 'SDC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Sông Đà',
    CompanyNameEn: 'Song Da Consulting Joint Stock Company',
  },
  {
    Code: 'SDD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây lắp Sông Đà',
    CompanyNameEn: 'Song Da Investment and Construction Joint Stock Company',
  },
  {
    Code: 'SDG',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sadico Cần Thơ',
    CompanyNameEn: 'Can Tho Sadico Joint Stock Corporation',
  },
  {
    Code: 'SDJ',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 25',
    CompanyNameEn: 'Song Da No. 25 Joint Stock Company',
  },
  {
    Code: 'SDK',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ khí Luyện Kim',
    CompanyNameEn:
      'Mechanical Engineering and Metallurgy Joint - Stock Company',
  },
  {
    Code: 'SDN',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sơn Đồng Nai',
    CompanyNameEn: 'Dong Nai Paint Corporation',
  },
  {
    Code: 'SDP',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần SDP',
    CompanyNameEn: 'SDP Joint Stock Company',
  },
  {
    Code: 'SDT',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 10',
    CompanyNameEn: 'Song Da No 10 Joint Stock Company',
  },
  {
    Code: 'SDU',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Xây dựng và Phát triển Đô thị Sông Đà',
    CompanyNameEn:
      'Song Da Urban Investment Construction And Development Joint Stock Company',
  },
  {
    Code: 'SDV',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Sonadezi',
    CompanyNameEn: 'Sonadezi Service Joint Stock Company',
  },
  {
    Code: 'SDX',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Phòng cháy chữa cháy và Đầu tư Xây dựng Sông Đà',
    CompanyNameEn:
      'Song Da Investment Construction and Fire Prevention Joint Stock Company',
  },
  {
    Code: 'SDY',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Sông Đà Yaly',
    CompanyNameEn: 'Song Da Yaly Cement Joint Stock Company',
  },
  {
    Code: 'SEA',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Thủy sản Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Viet Nam Seaproducts Joint Stock Corporation',
  },
  {
    Code: 'SEB',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Điện Miền Trung',
    CompanyNameEn:
      'Mien Trung Power Investment and Development Joint Stock Company',
  },
  {
    Code: 'SED',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Giáo dục Phương Nam',
    CompanyNameEn:
      'Phuong Nam Education Investment and Development Joint Stock Company',
  },
  {
    Code: 'SEP',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Thương mại Quảng Trị',
    CompanyNameEn: 'Quang Tri General Trading Joint Stock Company',
  },
  {
    Code: 'SFC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhiên liệu Sài Gòn',
    CompanyNameEn: 'Saigon Fuel Joint Stock Company',
  },
  {
    Code: 'SFG',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phân bón Miền Nam',
    CompanyNameEn: 'The Southern Fertilizer Joint Stock Company',
  },
  {
    Code: 'SFI',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đại lý Vận tải SAFI',
    CompanyNameEn: 'Sea And Air Freight International',
  },
  {
    Code: 'SFN',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dệt lưới Sài Gòn',
    CompanyNameEn: 'Saigon Fishing Net Joint Stock Company',
  },
  {
    Code: 'SGB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Sài Gòn Công thương',
    CompanyNameEn: 'Saigon Bank For Industry and Trade Joint Stock Bank',
  },
  {
    Code: 'SGC',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Sa Giang',
    CompanyNameEn: 'Sa Giang Import Export Corporation',
  },
  {
    Code: 'SGD',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách Giáo dục tại Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Educational Book Joint Stock Company in Ho Chi Minh City',
  },
  {
    Code: 'SGH',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Khách sạn Sài Gòn',
    CompanyNameEn: 'Saigon Hotel Corporation',
  },
  {
    Code: 'SGI',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư phát triển Sài Gòn 3 Group',
    CompanyNameEn: 'Sai Gon 3 Group Investment Development Joint Stock Company',
  },
  {
    Code: 'SGN',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phục vụ mặt đất Sài Gòn',
    CompanyNameEn: 'Saigon Ground Services Joint Stock Company',
  },
  {
    Code: 'SGO',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dầu thực vật Sài Gòn',
    CompanyNameEn: 'Saigon Vegetable Oil Joint Stock Company',
  },
  {
    Code: 'SGP',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Sài Gòn',
    CompanyNameEn: 'Sai Gon Port Joint Stock Company',
  },
  {
    Code: 'SGR',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Cổ phần Địa ốc Sài Gòn',
    CompanyNameEn: 'Saigon Real Estate Group Joint Stock Company',
  },
  {
    Code: 'SGS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải biển Sài Gòn',
    CompanyNameEn: 'Saigon Shipping Joint Stock Company',
  },
  {
    Code: 'SGT',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ Viễn thông Sài Gòn',
    CompanyNameEn: 'Saigon Telecommunication And Technologies Corporation',
  },
  {
    Code: 'SHA',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sơn Hà Sài Gòn',
    CompanyNameEn: 'Son Ha Sai Gon Joint Stock Company',
  },
  {
    Code: 'SHB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Sài Gòn – Hà Nội',
    CompanyNameEn: 'Saigon-Hanoi Commercial Joint Stock Bank',
  },
  {
    Code: 'SHC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hàng hải Sài Gòn',
    CompanyNameEn: 'Sai Gon Maritime Joint Stock Company',
  },
  {
    Code: 'SHE',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Năng Lượng Sơn Hà',
    CompanyNameEn: 'Son Ha Development of Renewable Energy Joint Stock Company',
  },
  {
    Code: 'SHG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Sông Hồng',
    CompanyNameEn: 'SongHong Joint Stock Corporation',
  },
  {
    Code: 'SHI',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Quốc tế Sơn Hà',
    CompanyNameEn: 'Sonha International Corporation',
  },
  {
    Code: 'SHN',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Tổng hợp Hà Nội',
    CompanyNameEn: 'Hanoi Investment General Corporation',
  },
  {
    Code: 'SHP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Miền Nam',
    CompanyNameEn: 'Southern Hydropower Joint Stock Company',
  },
  {
    Code: 'SHS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Sài Gòn - Hà Nội',
    CompanyNameEn: 'Sai Gon - Ha Noi Securities Joint Stock Company',
  },
  {
    Code: 'SHX',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sài Gòn Hỏa xa',
    CompanyNameEn: 'Sai gon Train Joint Stock Company',
  },
  {
    Code: 'SIC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần ANI',
    CompanyNameEn: 'ANI Joint Stock Company',
  },
  {
    Code: 'SID',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Sài Gòn Co.op',
    CompanyNameEn: 'Saigon Co.op Investment Development Joint Stock Company',
  },
  {
    Code: 'SIG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Thương mại Sông Đà',
    CompanyNameEn: 'Song Da Investment and Trading Joint Stock Company ',
  },
  {
    Code: 'SII',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Hạ tầng Nước Sài Gòn',
    CompanyNameEn: 'Saigon Water Infrastructure Corporation',
  },
  {
    Code: 'SIP',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Sài Gòn VRG',
    CompanyNameEn: 'Saigon VRG Investment Corporation',
  },
  {
    Code: 'SIV',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần SIVICO',
    CompanyNameEn: 'SIVICO Joint Stock Company',
  },
  {
    Code: 'SJ1',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nông nghiệp Hùng Hậu',
    CompanyNameEn: 'Hung Hau Agricultural Corporation',
  },
  {
    Code: 'SJC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 1.01',
    CompanyNameEn: 'Song Da 1.01 Joint Stock Company',
  },
  {
    Code: 'SJD',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Cần Đơn',
    CompanyNameEn: 'Can Don Hydropower Joint Stock Company',
  },
  {
    Code: 'SJE',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 11',
    CompanyNameEn: 'Song Da No11 Joint Stock Company',
  },
  {
    Code: 'SJF',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Sao Thái Dương',
    CompanyNameEn: 'Sunstar Investment Joint Stock Company',
  },
  {
    Code: 'SJG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Sông Đà',
    CompanyNameEn: 'Song Da Corporation',
  },
  {
    Code: 'SJM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà 19',
    CompanyNameEn: 'Song Da 19 Joint Stock Company',
  },
  {
    Code: 'SJS',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Phát triển Đô thị và Khu công nghiệp Sông Đà',
    CompanyNameEn:
      'Song Da Urban &amp; Industrial Zone Investment and Development Joint Stock Company',
  },
  {
    Code: 'SKG',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tàu Cao tốc Superdong - Kiên Giang',
    CompanyNameEn: 'Superdong - Kien Giang Fast Ferry Joint Stock Company',
  },
  {
    Code: 'SKH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước giải khát Sanest Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa Sanest Soft Drink Joint Stock Company',
  },
  {
    Code: 'SKN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước giải khát Sanna Khánh Hòa',
    CompanyNameEn: 'Sanna Khanh Hoa Beverage Joint Stock Company',
  },
  {
    Code: 'SKV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước Giải khát Yến sào Khánh Hòa',
    CompanyNameEn: 'Khanh Hoa Salanges Nest Soft Drink Joint Stock Company',
  },
  {
    Code: 'SLS',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Mía đường Sơn La',
    CompanyNameEn: 'Son La Sugar Joint Stock Company',
  },
  {
    Code: 'SMA',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị Phụ tùng Sài Gòn',
    CompanyNameEn: 'Saigon Machinery Spare Parts Joint Stock Company',
  },
  {
    Code: 'SMB',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Miền Trung',
    CompanyNameEn: 'Sai Gon - Mien Trung Beer Joint Stock Company',
  },
  {
    Code: 'SMC',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Thương mại SMC',
    CompanyNameEn: 'SMC Investment Trading Joint Stock Company',
  },
  {
    Code: 'SMN',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sách và thiết bị giáo dục Miền Nam',
    CompanyNameEn: 'South Books and Educational Equipment Joint Stock Company',
  },
  {
    Code: 'SMT',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần SAMETEL',
    CompanyNameEn: 'SAMETEL Corporation',
  },
  {
    Code: 'SNC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Năm Căn',
    CompanyNameEn: 'Nam Can Seaproducts Import Export Joint Stock Company',
  },
  {
    Code: 'SNZ',
    Sector: 'industryRealEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Phát triển Khu Công nghiệp',
    CompanyNameEn: 'Sonadezi Corporation',
  },
  {
    Code: 'SP2',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Sử Pán 2',
    CompanyNameEn: 'Su Pan 2 Hydropower Joint Stock Company',
  },
  {
    Code: 'SPB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sợi Phú Bài',
    CompanyNameEn: 'Phu Bai Spinning Joint Stock Company',
  },
  {
    Code: 'SPC',
    Sector: 'chemicals',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bảo vệ Thực vật Sài Gòn',
    CompanyNameEn: 'Saigon Plant Protection Joint Stock Company',
  },
  {
    Code: 'SPD',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Miền Trung',
    CompanyNameEn: 'Danang Seaproducts Import - Export Corporation',
  },
  {
    Code: 'SPH',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất Nhập khẩu Thủy sản Hà Nội',
    CompanyNameEn: 'Ha Noi Seaproducts Import Export Joint Stock Corporation',
  },
  {
    Code: 'SPI',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Spiral Galaxy',
    CompanyNameEn: 'Spiral Galaxy Joint Stock Company',
  },
  {
    Code: 'SPM',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần S.P.M',
    CompanyNameEn: 'S.P.M Corporation',
  },
  {
    Code: 'SPP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Nhựa Sài Gòn',
    CompanyNameEn: 'Saigon Plastic Packaging Joint Stock Company',
  },
  {
    Code: 'SPV',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy Đặc Sản',
    CompanyNameEn: 'Special Aquatic Products Joint Stock Company',
  },
  {
    Code: 'SQC',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Sài Gòn - Quy Nhơn',
    CompanyNameEn: 'Sai Gon - Quy Nhon Mining Corporation',
  },
  {
    Code: 'SRA',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần SARA Việt Nam',
    CompanyNameEn: 'Sara Vietnam Joint Stock Company',
  },
  {
    Code: 'SRB',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Sara',
    CompanyNameEn: 'Sara Joint Stock Company',
  },
  {
    Code: 'SRC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Sao Vàng',
    CompanyNameEn: 'Sao Vang Rubber Joint Stock Company',
  },
  {
    Code: 'SRF',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Searefico',
    CompanyNameEn: 'Searefico Corporation',
  },
  {
    Code: 'SRT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Đường sắt Sài Gòn',
    CompanyNameEn: 'Saigon Railway Transport Joint Stock Company',
  },
  {
    Code: 'SSB',
    Sector: 'banks',
    Exchange: 'HSX',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Đông Nam Á',
    CompanyNameEn: 'Southeast Asia Commercial Joint Stock Bank',
  },
  {
    Code: 'SSC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Giống cây trồng Miền Nam',
    CompanyNameEn: 'Southern Seed Joint-stock Company',
  },
  {
    Code: 'SSF',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giáo dục G Sài Gòn',
    CompanyNameEn: 'G Sai Gon Education Joint Stock Company',
  },
  {
    Code: 'SSG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Biển Hải Âu',
    CompanyNameEn: 'Seagull Shipping Company',
  },
  {
    Code: 'SSH',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Sunshine Homes',
    CompanyNameEn: 'Sunshine Homes Development Joint Stock Company',
  },
  {
    Code: 'SSI',
    Sector: 'financials',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán SSI',
    CompanyNameEn: 'SSI Securities Corporation',
  },
  {
    Code: 'SSM',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chế tạo kết cấu thép VNECO.SSM',
    CompanyNameEn: 'Steel Structure Manufacture Joint Stock Company',
  },
  {
    Code: 'SSN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Thủy sản Sài Gòn',
    CompanyNameEn: 'Saigon Sea Products Import Export Joint Stock Corporation',
  },
  {
    Code: 'ST8',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Siêu Thanh',
    CompanyNameEn: 'Sieu Thanh Joint Stock Corporation',
  },
  {
    Code: 'STB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Sài Gòn Thương Tín',
    CompanyNameEn: 'SaiGon Thuong Tin Commercial Joint Stock Bank',
  },
  {
    Code: 'STC',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Sách và Thiết bị trường học Thành phố Hồ Chí Minh',
    CompanyNameEn:
      'Book And Educational Equipment Joint Stock Company of Ho Chi Minh City',
  },
  {
    Code: 'STG',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Kho Vận Miền Nam',
    CompanyNameEn: 'South Logistics Joint Stock Company',
  },
  {
    Code: 'STH',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát hành sách Thái Nguyên',
    CompanyNameEn: 'Thai Nguyen Book Distribution Joint Stock Company',
  },
  {
    Code: 'STK',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sợi Thế Kỷ',
    CompanyNameEn: 'Century Synthetic Fiber Corporation',
  },
  {
    Code: 'STL',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sông Đà - Thăng Long',
    CompanyNameEn: 'SongDa - ThangLong Joint Stock Company',
  },
  {
    Code: 'STP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Thương mại Sông Đà',
    CompanyNameEn: 'Song Da Industry Trade Joint Stock Company',
  },
  {
    Code: 'STS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ vận tải Sài Gòn',
    CompanyNameEn: 'Saigon Transport Agency Joint Stock Company',
  },
  {
    Code: 'STT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận chuyển Sài Gòn Tourist',
    CompanyNameEn: 'Saigon Tourist Transport Corporation',
  },
  {
    Code: 'STW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Sóc Trăng',
    CompanyNameEn: 'Soc Trang Water Supply Joint Stock Company',
  },
  {
    Code: 'SVC',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ tổng hợp Sài Gòn',
    CompanyNameEn: 'Sai Gon General Service Corporation',
  },
  {
    Code: 'SVD',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư & Thương mại Vũ Đăng',
    CompanyNameEn: 'Vu Dang Investment & Trading Joint Stock Company',
  },
  {
    Code: 'SVG',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Hơi kỹ nghệ Que hàn',
    CompanyNameEn: 'Industrial Gas and Welding Electrode Joint Stock Company',
  },
  {
    Code: 'SVH',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Sông Vàng',
    CompanyNameEn: 'Song Vang HydroPower Joint Stock Company',
  },
  {
    Code: 'SVI',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Biên Hòa',
    CompanyNameEn: 'Bien Hoa Packaging Joint Stock Company',
  },
  {
    Code: 'SVN',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Vexilla Việt Nam',
    CompanyNameEn: 'Vexilla Viet Nam Group Joint Stock Company',
  },
  {
    Code: 'SVT',
    Sector: 'communicationServices',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ Sài Gòn Viễn Đông',
    CompanyNameEn: 'Sai Gon Vien Dong Technology Joint Stock Company',
  },
  {
    Code: 'SWC',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Đường Sông Miền Nam',
    CompanyNameEn: 'Southern Waterborne Transport Corporation',
  },
  {
    Code: 'SZB',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sonadezi Long Bình',
    CompanyNameEn: 'Sonadezi Long Binh Share Holding Company',
  },
  {
    Code: 'SZC',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sonadezi Châu Đức',
    CompanyNameEn: 'Sonadezi Chau Duc Shareholding Company',
  },
  {
    Code: 'SZE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường Sonadezi',
    CompanyNameEn: 'Sonadezi Environment Joint Stock Company',
  },
  {
    Code: 'SZG',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sonadezi Giang Điền',
    CompanyNameEn: 'Sonadezi Giang Dien Shareholding Company',
  },
  {
    Code: 'SZL',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Sonadezi Long Thành',
    CompanyNameEn: 'Sonadezi Long Thanh Shareholding Company',
  },
  {
    Code: 'TA3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Đầu tư và Xây lắp Thành An 386',
    CompanyNameEn:
      '386 Thanh An Construction and Investment Joint Stock Company',
  },
  {
    Code: 'TA6',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây lắp Thành An 665',
    CompanyNameEn:
      'Thanh An 665 Investment, Installation And Construction Joint Stock Company',
  },
  {
    Code: 'TA9',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Thành An 96',
    CompanyNameEn:
      'Thanh An 96 Construction and Installation Joint Stock Company',
  },
  {
    Code: 'TAG',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thế giới số Trần Anh',
    CompanyNameEn: 'Tran Anh Digital World Joint Stock Company',
  },
  {
    Code: 'TAN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cà phê Thuận An',
    CompanyNameEn: 'Thuan An Coffee Joint Stock Company',
  },
  {
    Code: 'TAR',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nông nghiệp Công nghệ cao Trung An',
    CompanyNameEn: 'Trung An Hi-tech Farming Joint Stock Company',
  },
  {
    Code: 'TAW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Trung An',
    CompanyNameEn: 'Trung An Water Supply Joint Stock Company',
  },
  {
    Code: 'TB8',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Sản xuất và Kinh doanh Vật tư Thiết bị - VVMI',
    CompanyNameEn:
      'VVMI - Manufacturing and Materials Equipment Trading Joint Stock Company',
  },
  {
    Code: 'TBC',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Thác Bà',
    CompanyNameEn: 'Thac Ba Hydropower Joint Stock Company',
  },
  {
    Code: 'TBD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng công ty Thiết bị điện Đông Anh - Công ty Cổ phần',
    CompanyNameEn: 'Dong Anh Electrical Equipment Corporation',
  },
  {
    Code: 'TBH',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Bách Hóa',
    CompanyNameEn: 'General Department Store Joint Stock Company',
  },
  {
    Code: 'TBR',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Địa ốc Tân Bình',
    CompanyNameEn: 'Tan Binh Real Estate Joint Stock Company',
  },
  {
    Code: 'TBT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Công trình Giao thông Bến Tre',
    CompanyNameEn:
      'Bentre Transportation Works Contruction Joint Stock Company',
  },
  {
    Code: 'TBX',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Thái Bình',
    CompanyNameEn: 'Thai Binh Cement Joint Stock Company',
  },
  {
    Code: 'TC6',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Cọc Sáu - Vinacomin',
    CompanyNameEn: 'Vinacomin - Coc Sau Coal Joint Stock Company',
  },
  {
    Code: 'TCB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Kỹ thương Việt Nam',
    CompanyNameEn: 'Vietnam Technological And Commercial Joint Stock Bank',
  },
  {
    Code: 'TCD',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Công nghiệp và Vận tải',
    CompanyNameEn:
      'Transport and Industry Development Investment Joint Stock Company',
  },
  {
    Code: 'TCH',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Dịch vụ Tài chính Hoàng Huy',
    CompanyNameEn:
      'Hoang Huy Investment Financial Services Joint Stock Company',
  },
  {
    Code: 'TCI',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Thành Công',
    CompanyNameEn: 'ThanhCong Securities Company',
  },
  {
    Code: 'TCJ',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tô Châu',
    CompanyNameEn: 'To Chau Joint Stock Company',
  },
  {
    Code: 'TCK',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cơ khí Xây dựng - CTCP',
    CompanyNameEn: 'Construction Machinery Corporation - JSC',
  },
  {
    Code: 'TCL',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đại lý Giao nhận Vận tải Xếp dỡ Tân Cảng',
    CompanyNameEn: 'Tan Cang Logistics and Stevedoring Join Stock Company',
  },
  {
    Code: 'TCM',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dệt may - Đầu tư - Thương mại Thành Công',
    CompanyNameEn:
      'Thanh Cong Textile Garment Investment Trading Joint Stock Company',
  },
  {
    Code: 'TCO',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Đa phương thức Duyên Hải',
    CompanyNameEn: 'Duyen Hai Multi Modal Transport Joint Stock Company',
  },
  {
    Code: 'TCR',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Gốm sứ TAICERA',
    CompanyNameEn: 'Taicera Enterprise Company',
  },
  {
    Code: 'TCT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cáp treo Núi Bà Tây Ninh',
    CompanyNameEn: 'Tay Ninh Cable Car Tour Company',
  },
  {
    Code: 'TCW',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kho Vận Tân Cảng',
    CompanyNameEn: 'Tan Cang Warehousing Joint Stock Company',
  },
  {
    Code: 'TDB',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Định Bình',
    CompanyNameEn: 'Dinh Binh Hydropower Joint Stock Company',
  },
  {
    Code: 'TDC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh và Phát triển Bình Dương',
    CompanyNameEn: 'Binhduong Trade And Development Joint Stock Company',
  },
  {
    Code: 'TDF',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trung Đô',
    CompanyNameEn: 'Trung Do Joint Stock Company',
  },
  {
    Code: 'TDG',
    Sector: 'energy',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư TDG Global',
    CompanyNameEn: 'TDG Global Investment Joint Stock Company',
  },
  {
    Code: 'TDH',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Nhà Thủ Đức',
    CompanyNameEn: 'Thu Duc Housing Development Corporation',
  },
  {
    Code: 'TDM',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nước Thủ Dầu Một',
    CompanyNameEn: 'Thu Dau Mot Water Joint Stock Company',
  },
  {
    Code: 'TDN',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Đèo Nai - Vinacomin',
    CompanyNameEn: 'Vinacomin - Deo Nai Coal Joint Stock Company',
  },
  {
    Code: 'TDP',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thuận Đức',
    CompanyNameEn: 'Thuan Duc Joint Stock Company',
  },
  {
    Code: 'TDS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thép Thủ Đức - VNSTEEL',
    CompanyNameEn: 'VNSTEEL - Thu Duc Steel Joint Stock Company',
  },
  {
    Code: 'TDT',
    Sector: 'textileIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển TDT',
    CompanyNameEn: 'TDT Investment And Development Joint Stock Company',
  },
  {
    Code: 'TDW',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Thủ Đức',
    CompanyNameEn: 'Thu Duc Water Supply Joint Stock Company',
  },
  {
    Code: 'TED',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Tư vấn Thiết kế Giao thông Vận tải - CTCP',
    CompanyNameEn: 'Transport Engineering Design InCorporated',
  },
  {
    Code: 'TEG',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Năng Lượng và Bất động sản Trường Thành',
    CompanyNameEn: 'Truong Thanh Energy and Real Estate Joint Stock Company',
  },
  {
    Code: 'TEL',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển công trình viễn thông',
    CompanyNameEn:
      'Telecommunication Project Construction Development Joint Stock Company',
  },
  {
    Code: 'TET',
    Sector: 'textileIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vải sợi May mặc Miền Bắc',
    CompanyNameEn:
      'Northern Textiles And Garments Joint Stock Company (TEXTACO)',
  },
  {
    Code: 'TFC',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Trang',
    CompanyNameEn: 'Trang Corporation',
  },
  {
    Code: 'TGG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Louis Capital',
    CompanyNameEn: 'Louis Capital Joint Stock Company',
  },
  {
    Code: 'TGP',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trường Phú',
    CompanyNameEn: 'Truong Phu Joint Stock Company',
  },
  {
    Code: 'TH1',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Tổng hợp I Việt Nam',
    CompanyNameEn:
      'The Vietnam National General Export - Import Joint Stock Company No.1',
  },
  {
    Code: 'THB',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bia Hà Nội - Thanh Hóa',
    CompanyNameEn: 'Ha Noi - Thanh Hoa Beer Joint Stock Company',
  },
  {
    Code: 'THD',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thaiholdings',
    CompanyNameEn: 'Thaiholdings Joint Stock Company',
  },
  {
    Code: 'THG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Tiền Giang',
    CompanyNameEn: 'Tien Giang Investment and Construction Joint Stock Company',
  },
  {
    Code: 'THI',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thiết bị điện',
    CompanyNameEn: 'Electrical Equipment Joint Stock Company',
  },
  {
    Code: 'THN',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Thanh Hóa',
    CompanyNameEn: 'Thanh Hoa Water Supply Joint Stock Company',
  },
  {
    Code: 'THP',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy sản và Thương mại Thuận Phước',
    CompanyNameEn: 'Thuan Phuoc Seafoods and trading corporation',
  },
  {
    Code: 'THS',
    Sector: 'utility',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thanh Hoa Sông Đà',
    CompanyNameEn: 'Song Da Thanh Hoa Joint Stock Company',
  },
  {
    Code: 'THT',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Hà Tu - Vinacomin',
    CompanyNameEn: 'Vinacomin - Ha Tu Coal Joint Stock Company',
  },
  {
    Code: 'THU',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Môi trường và Công trình đô thị Thanh Hóa',
    CompanyNameEn:
      'Thanh Hoa Urban Construction and Environment Joint Stock Company',
  },
  {
    Code: 'THW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Tân Hòa',
    CompanyNameEn: 'Tan Hoa Water Supply Joint Stock Company',
  },
  {
    Code: 'TID',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng Công ty Tín Nghĩa',
    CompanyNameEn: 'Tin Nghia Corporation',
  },
  {
    Code: 'TIE',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần TIE',
    CompanyNameEn:
      'Telecommunications Industry Electronics Joint Stock Company',
  },
  {
    Code: 'TIG',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Đầu tư Thăng Long',
    CompanyNameEn: 'ThangLong Investment Group Joint Stock Company',
  },
  {
    Code: 'TIN',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Tài chính Cổ phần Tín Việt',
    CompanyNameEn: 'VIETCREDIT Finance Joint Stock Company',
  },
  {
    Code: 'TIP',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Khu công nghiệp Tín Nghĩa',
    CompanyNameEn: 'Tin Nghia Industrial Park Development Joint Stock Company',
  },
  {
    Code: 'TIS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gang thép Thái Nguyên',
    CompanyNameEn: 'Thai Nguyen Iron and Steel Joint Stock Corporation',
  },
  {
    Code: 'TIX',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Sản xuất Kinh doanh Xuất nhập khẩu Dịch vụ và Đầu tư Tân Bình',
    CompanyNameEn: 'Tan Binh Import - Export Joint Stock Corporation',
  },
  {
    Code: 'TJC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Vận tải và Thương mại',
    CompanyNameEn: 'Transport and Trading service Joint Stock Company',
  },
  {
    Code: 'TKA',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Tân Khánh An',
    CompanyNameEn: 'Tan Khanh An Packaging Joint Stock Company',
  },
  {
    Code: 'TKC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Kinh doanh Địa ốc Tân Kỷ',
    CompanyNameEn: 'Tanky Construction Real Estate Trading Corporation',
  },
  {
    Code: 'TKG',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất và Thương mại Tùng Khánh',
    CompanyNameEn: 'Tung Khanh Trading and Manufacturing Joint Stock Company',
  },
  {
    Code: 'TKU',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Tung Kuang',
    CompanyNameEn: 'Tung Kuang Industrial Joint Stock Company',
  },
  {
    Code: 'TL4',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Xây dựng Thủy lợi 4 - Công ty Cổ phần',
    CompanyNameEn:
      'Hydraulics Construction Corporation No.4 Joint Stock Company',
  },
  {
    Code: 'TLD',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Đầu tư Xây dựng và Phát triển Đô thị Thăng Long',
    CompanyNameEn:
      'Thang Long Urban Development and Construction Investment Joint Stock Company',
  },
  {
    Code: 'TLG',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thiên Long',
    CompanyNameEn: 'Thien Long Group Corporation',
  },
  {
    Code: 'TLH',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thép Tiến Lên',
    CompanyNameEn: 'Tienlen Steel Corporation Joint Stock Company',
  },
  {
    Code: 'TLI',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Quốc tế Thắng Lợi',
    CompanyNameEn: 'Thang Loi International Garment Joint Stock Company',
  },
  {
    Code: 'TLP',
    Sector: 'energy',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Thương mại Xuất nhập khẩu Thanh Lễ - CTCP',
    CompanyNameEn: 'Thanh Le Corporation',
  },
  {
    Code: 'TLT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Viglacera Thăng Long',
    CompanyNameEn: 'Viglacera ThangLong Joint Stock Company',
  },
  {
    Code: 'TMB',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Kinh doanh Than Miền Bắc - Vinacomin',
    CompanyNameEn: 'Vinacomin - Northern Coal Trading Joint Stock Company',
  },
  {
    Code: 'TMC',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Xuất nhập khẩu Thủ Đức',
    CompanyNameEn: 'Thu Duc Trading and Import Export Joint Stock Company',
  },
  {
    Code: 'TMG',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kim loại màu Thái Nguyên - Vimico',
    CompanyNameEn: 'ThaiNguyen Non Ferous Metals Joint Stock Company ',
  },
  {
    Code: 'TMP',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Thác Mơ',
    CompanyNameEn: 'Thac Mo Hydropower Joint Stock Company',
  },
  {
    Code: 'TMS',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Transimex',
    CompanyNameEn: 'Transimex Corporation',
  },
  {
    Code: 'TMT',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Ô tô TMT',
    CompanyNameEn: 'TMT Motors Corporation',
  },
  {
    Code: 'TMW',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tổng hợp Gỗ Tân Mai',
    CompanyNameEn: 'Tan Mai General Wood Joint Stock Company',
  },
  {
    Code: 'TMX',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VICEM Thương mại Xi măng',
    CompanyNameEn: 'VICEM Cement Trading Joint Stock Company',
  },
  {
    Code: 'TN1',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Dịch vụ TNS Holdings',
    CompanyNameEn: 'TNS Holdings Joint Stock Company',
  },
  {
    Code: 'TNA',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'CTCP TM XNK THIEN NAM',
    CompanyNameEn: 'Thien Nam Trading Import-Export Joint Stock Company',
  },
  {
    Code: 'TNB',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thép Nhà Bè - VNSTEEL',
    CompanyNameEn: 'VNSTEEL - Nha Be Steel Joint Stock Company',
  },
  {
    Code: 'TNC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Thống Nhất',
    CompanyNameEn: 'Thong Nhat Rubber Joint Stock Company',
  },
  {
    Code: 'TNG',
    Sector: 'textileIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Thương mại TNG',
    CompanyNameEn: 'TNG Investment And Trading Joint Stock Company',
  },
  {
    Code: 'TNH',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bệnh viện Quốc tế Thái Nguyên',
    CompanyNameEn: 'Thai Nguyen International Hospital Joint Stock Company',
  },
  {
    Code: 'TNI',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Thành Nam',
    CompanyNameEn: 'Thanh Nam Group Joint Stock Company',
  },
  {
    Code: 'TNM',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu và Xây dựng Công trình',
    CompanyNameEn:
      'Tranimexco Transportation Import-Export and Construction Joint-Stock Company',
  },
  {
    Code: 'TNP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng Thị Nại',
    CompanyNameEn: 'Thi Nai Port Joint Stock Company',
  },
  {
    Code: 'TNS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thép Tấm Lá Thống Nhất',
    CompanyNameEn: 'Thongnhat Flat Steel Joint Stock Company',
  },
  {
    Code: 'TNT',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn TNT',
    CompanyNameEn: 'TNT Group Joint Stock Company',
  },
  {
    Code: 'TNW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước sạch Thái Nguyên',
    CompanyNameEn: 'Thai Nguyen Water Joint Stock Company',
  },
  {
    Code: 'TOP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phân phối Top One',
    CompanyNameEn: 'Top One Allot Joint Stock Company',
  },
  {
    Code: 'TOS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ biển Tân Cảng',
    CompanyNameEn: 'Tan Cang Offshore Services Joint Stock Company',
  },
  {
    Code: 'TOT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Transimex Logistics',
    CompanyNameEn: 'Transimex Logistics Corporation',
  },
  {
    Code: 'TOW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Trà Nóc - Ô Môn',
    CompanyNameEn: 'Tra Noc - O Mon Water Supply Joint Stock Company',
  },
  {
    Code: 'TPB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Tiên Phong',
    CompanyNameEn: 'Tien Phong Commercial Joint Stock Bank',
  },
  {
    Code: 'TPC',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Tân Đại Hưng',
    CompanyNameEn: 'Tan Dai Hung Plastic Joint Stock Company',
  },
  {
    Code: 'TPH',
    Sector: 'communicationServices',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần In Sách giáo khoa tại Thành phố Hà Nội',
    CompanyNameEn: 'Hanoi Textbooks Printing Joint Stock Company',
  },
  {
    Code: 'TPP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tân Phú Việt Nam',
    CompanyNameEn: 'Tan Phu Viet Nam Joint Stock Company',
  },
  {
    Code: 'TPS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bến bãi vận tải Sài Gòn',
    CompanyNameEn: 'Sai Gon Transporation Parking Joint Stock Company',
  },
  {
    Code: 'TQN',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thông Quảng Ninh',
    CompanyNameEn: 'Quang Ninh Pine Joint Stock Company',
  },
  {
    Code: 'TQW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Tuyên Quang',
    CompanyNameEn: 'Tuyen Quang Water Supply and Sewerage Joint Stock Company',
  },
  {
    Code: 'TR1',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận Tải 1 Traco',
    CompanyNameEn: 'Traco Transport Joint Stock Corporation No.1',
  },
  {
    Code: 'TRA',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Traphaco',
    CompanyNameEn: 'Traphaco Joint Stock Company',
  },
  {
    Code: 'TRC',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Cao su Tây Ninh',
    CompanyNameEn: 'Tay Ninh Rubber Joint Stock Company',
  },
  {
    Code: 'TRS',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Dịch vụ Hàng Hải',
    CompanyNameEn:
      'Tracimexco - Supply Chains and Agency Services Joint Stock Company',
  },
  {
    Code: 'TRT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần RedstarCera',
    CompanyNameEn: 'RedstarCera Joint Stock Company',
  },
  {
    Code: 'TS3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trường Sơn 532',
    CompanyNameEn: 'Truong Son 532 Joint Stock Company',
  },
  {
    Code: 'TS4',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy sản số 4',
    CompanyNameEn: 'Seafood Joint Stock Company No 4',
  },
  {
    Code: 'TSB',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Ắc quy Tia Sáng',
    CompanyNameEn: 'Tia Sang Battery Joint Stock Company',
  },
  {
    Code: 'TSC',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vật tư Kỹ thuật Nông nghiệp Cần Thơ',
    CompanyNameEn: 'Techno - Agricultural Supplying Joint Stock Company',
  },
  {
    Code: 'TSD',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Trường Sơn Coecco',
    CompanyNameEn: 'Truong Son Tourism Joint Stock Company Ceocco',
  },
  {
    Code: 'TSG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thông tin Tín hiệu Đường sắt Sài Gòn',
    CompanyNameEn:
      'Sai Gon Railway of Telecommunication - Signalization Joint Stock Company ',
  },
  {
    Code: 'TSJ',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Dịch vụ Hà Nội',
    CompanyNameEn: 'Hanoi Tourist Service Joint Stock Company',
  },
  {
    Code: 'TST',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Kỹ thuật Viễn thông',
    CompanyNameEn: 'Telecommunication Technical Service Joint Stock Company',
  },
  {
    Code: 'TTA',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'CTCP DTXD&PT TRUONG THANH',
    CompanyNameEn:
      'Truong Thanh Development and Construction Investment Joint Stock Company',
  },
  {
    Code: 'TTB',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Tiến Bộ',
    CompanyNameEn: 'Tien Bo Group Joint Stock Company',
  },
  {
    Code: 'TTC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Gạch men Thanh Thanh',
    CompanyNameEn: 'Thanh Thanh Joint Stock Company',
  },
  {
    Code: 'TTD',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bệnh viện Tim Tâm Đức',
    CompanyNameEn: 'Tam Duc Cardiology Hospital Joint Stock Company',
  },
  {
    Code: 'TTE',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Năng lượng Trường Thịnh',
    CompanyNameEn: 'Truong Thinh Energy Investment Joint Stock Company',
  },
  {
    Code: 'TTF',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Kỹ nghệ Gỗ Trường Thành',
    CompanyNameEn: 'Truong Thanh Furniture Corporation',
  },
  {
    Code: 'TTG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần May Thanh Trì',
    CompanyNameEn: 'Thanh Tri Garment Joint Stock Company',
  },
  {
    Code: 'TTH',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại và Dịch vụ Tiến Thành',
    CompanyNameEn: 'Tien Thanh Service and Trading Joint Stock Company',
  },
  {
    Code: 'TTL',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Thăng Long - Công ty Cổ phần',
    CompanyNameEn: 'Thang Long Joint Stock Corporation',
  },
  {
    Code: 'TTN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ & Truyền thông Việt Nam',
    CompanyNameEn:
      'Viet Nam Technology & Telecommunication Joint Stock Company',
  },
  {
    Code: 'TTP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bao bì Nhựa Tân Tiến',
    CompanyNameEn: 'Tan Tien Plastic Packaging Joint Stock Company',
  },
  {
    Code: 'TTS',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cán thép Thái Trung',
    CompanyNameEn: 'Thai Trung Steel Joint Stock Company',
  },
  {
    Code: 'TTT',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Du Lịch - Thương Mại Tây Ninh',
    CompanyNameEn: 'Tay Ninh Tourist - Trading Joint Stock Company',
  },
  {
    Code: 'TTZ',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng và Công nghệ Tiến Trung',
    CompanyNameEn:
      'Tien Trung Investment Construction and Technology Joint Stock Company',
  },
  {
    Code: 'TUG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lai dắt và Vận tải Cảng Hải Phòng',
    CompanyNameEn: 'Haiphong Tugboat and Transport Joint Stock Company',
  },
  {
    Code: 'TV1',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Điện 1',
    CompanyNameEn: 'Power Engineering Consulting Joint Stock Company 1',
  },
  {
    Code: 'TV2',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Điện 2',
    CompanyNameEn: 'Power Engineering Consulting Joint Stock Company 2',
  },
  {
    Code: 'TV3',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Điện 3',
    CompanyNameEn: 'Power Engineering Consulting Joint Stock Company 3',
  },
  {
    Code: 'TV4',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Điện 4',
    CompanyNameEn: 'Power Engineering Consulting Joint Stock Company 4',
  },
  {
    Code: 'TV6',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại Đầu tư xây lắp điện Thịnh Vượng',
    CompanyNameEn:
      'Thinh Vuong Power Construction Trading and Investment Joint Stock Company',
  },
  {
    Code: 'TVA',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Sứ Viglacera Thanh Trì',
    CompanyNameEn: 'Viglacera Thanh Tri Sanitary Joint Stock Company',
  },
  {
    Code: 'TVB',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Trí Việt',
    CompanyNameEn: 'Tri Viet Securities Joint Stock Corporation',
  },
  {
    Code: 'TVC',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Quản lý Tài sản Trí Việt',
    CompanyNameEn: 'Tri Viet Asset Management Corporation Joint Stock Company',
  },
  {
    Code: 'TVD',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Than Vàng Danh - Vinacomin',
    CompanyNameEn: 'Vang Danh Coal Joint Stock Company',
  },
  {
    Code: 'TVG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Tư vấn Đầu tư và Xây dựng Giao thông Vận tải',
    CompanyNameEn:
      'Transport Investment And Construction Consultant Joint Stock Company',
  },
  {
    Code: 'TVH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng công trình Hàng hải',
    CompanyNameEn:
      'Construction Consultation Joint Stock Company For Maritime Building',
  },
  {
    Code: 'TVM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Tư vấn đầu tư Mỏ và công nghiệp - Vinacomin',
    CompanyNameEn:
      'Vinacomin Industry Investment Consulting Joint Stock Company',
  },
  {
    Code: 'TVN',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Thép Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Viet Nam Steel Corporation',
  },
  {
    Code: 'TVP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm TV.Pharm',
    CompanyNameEn: 'TV.Pharm Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'TVS',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Thiên Việt',
    CompanyNameEn: 'Thien Viet Securities Joint-Stock Company',
  },
  {
    Code: 'TVT',
    Sector: 'textileIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Việt Thắng - CTCP',
    CompanyNameEn: 'Viet Thang Corporation - JSC',
  },
  {
    Code: 'TVW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước Trà Vinh',
    CompanyNameEn: 'TraVinh Water Supply & Drainage Joint Stock Company',
  },
  {
    Code: 'TW3',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược Trung Ương 3',
    CompanyNameEn: 'Central Pharmaceutical Joint Stock Company No.3',
  },
  {
    Code: 'TXM',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VICEM Thạch cao Xi Măng',
    CompanyNameEn: 'VICEM Gypsum and Cement Joint Stock Company',
  },
  {
    Code: 'TYA',
    Sector: 'informationTechnology',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dây và Cáp điện Taya Việt Nam',
    CompanyNameEn:
      'Taya (VIET NAM) Electric Wire and Cable Joint Stock Company',
  },
  {
    Code: 'UCT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đô thị Cần Thơ',
    CompanyNameEn: 'CanTho Urban Joint Stock Company',
  },
  {
    Code: 'UDC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn:
      'Công ty Cổ phần Xây dựng và Phát triển đô thị Tỉnh Bà Rịa - Vũng Tàu',
    CompanyNameEn: 'Urban Development Construction Corporation',
  },
  {
    Code: 'UDJ',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Đô thị',
    CompanyNameEn: 'Becamex Urban Development Joint Stock Company',
  },
  {
    Code: 'UDL',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đô thị và Môi trường Đắk Lắk',
    CompanyNameEn: 'Dak Lak Urban and Enviroment Joint Stock Company',
  },
  {
    Code: 'UEM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cơ điện Uông Bí - Vinacomin',
    CompanyNameEn:
      'Vinacomin - Uong Bi Electric Mechanical Joint Stock Company',
  },
  {
    Code: 'UIC',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Nhà và Đô thị IDICO',
    CompanyNameEn: 'Idico Urban and House Development Joint stock Company',
  },
  {
    Code: 'UMC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình đô thị Nam Định',
    CompanyNameEn:
      'Nam Dinh Urband Construction Management Joint Stock Company',
  },
  {
    Code: 'UNI',
    Sector: 'realEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Sao Mai Việt',
    CompanyNameEn:
      'Sao Mai Viet Investment and Development Joint Stock Company',
  },
  {
    Code: 'UPC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Phát triển Công viên Cây xanh và Đô thị Vũng Tàu',
    CompanyNameEn: 'Vung Tau Urban and Parks Development Joint Stock Company',
  },
  {
    Code: 'UPH',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm TW25',
    CompanyNameEn: 'No 25 Central Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'USC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khảo sát và Xây dựng - USCO',
    CompanyNameEn: 'Union of Survey And Construction Joint Stock Company',
  },
  {
    Code: 'USD',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Đô thị Sóc Trăng',
    CompanyNameEn: 'Soc Trang Public Works Joint Stock Company',
  },
  {
    Code: 'V11',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 11',
    CompanyNameEn: 'Vietnam Construction Joint Stock Company No11',
  },
  {
    Code: 'V12',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 12',
    CompanyNameEn: 'Viet Nam Construction Joint Stock Company No 12',
  },
  {
    Code: 'V15',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 15',
    CompanyNameEn: 'Vinaconex 15 Joint Stock Company',
  },
  {
    Code: 'V21',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vinaconex 21',
    CompanyNameEn: 'Vinaconex 21 Joint Stock Company',
  },
  {
    Code: 'VAB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Việt Á',
    CompanyNameEn: 'Vietnam-Asia Commercial Joint Stock Bank',
  },
  {
    Code: 'VAF',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phân lân nung chảy Văn Điển',
    CompanyNameEn:
      'Van Dien Fused Magnesium Phosphate Fertilizer Joint Stock Company',
  },
  {
    Code: 'VAT',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VT Vạn Xuân',
    CompanyNameEn: 'Van Xuan VT Joint Stock Company',
  },
  {
    Code: 'VAV',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VIWACO',
    CompanyNameEn: 'VIWACO Joint Stock Company',
  },
  {
    Code: 'VBB',
    Sector: 'banks',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Việt Nam Thương Tín',
    CompanyNameEn: 'Vietnam Thuong Tin Commercial Joint Stock Bank',
  },
  {
    Code: 'VBC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhựa, Bao bì Vinh',
    CompanyNameEn: 'Vinh Plastic and Bags Joint Stock Company',
  },
  {
    Code: 'VBG',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Địa chất Việt Bắc - TKV',
    CompanyNameEn: 'Vinacomin - Viet Bac Geology Joint Stock Company',
  },
  {
    Code: 'VBH',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Điện tử Bình Hòa',
    CompanyNameEn: 'Viettronics Binh Hoa Joint Stock Company',
  },
  {
    Code: 'VC1',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 1',
    CompanyNameEn: 'Construction Joint Stock Company No 1',
  },
  {
    Code: 'VC2',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng VINA2',
    CompanyNameEn: 'VINA2 Investment and Construction Joint Stock Company',
  },
  {
    Code: 'VC3',
    Sector: 'industryRealEstate',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Nam Mê Kông',
    CompanyNameEn: 'Nam MeKong Group Joint Stock Company',
  },
  {
    Code: 'VC5',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần xây dựng số 5',
    CompanyNameEn: 'Construction Joint Stock Company - Number 5',
  },
  {
    Code: 'VC6',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty cổ phần Xây dựng và Đầu tư Visicons',
    CompanyNameEn: 'Visicons Construction and Investment Joint Stock Company',
  },
  {
    Code: 'VC7',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn BGI',
    CompanyNameEn: 'BGI Group Joint Stock Company',
  },
  {
    Code: 'VC9',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng số 9 - VC9',
    CompanyNameEn: 'VC9 - No 9 Construction Joint Stock Company ',
  },
  {
    Code: 'VCA',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thép VICASA - VNSTEEL',
    CompanyNameEn: 'VNSTEEL - VICASA Joint Stock Company',
  },
  {
    Code: 'VCB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Ngoại thương Việt Nam',
    CompanyNameEn: 'Joint Stock Commercial Bank For Foreign Trade Of Vietnam',
  },
  {
    Code: 'VCC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vinaconex 25',
    CompanyNameEn: 'Vinaconex 25 Joint Stock Company',
  },
  {
    Code: 'VCE',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp Môi trường',
    CompanyNameEn: 'Construction Environment Joint Stock Company',
  },
  {
    Code: 'VCF',
    Sector: 'agricalture',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vinacafe Biên Hòa',
    CompanyNameEn: 'Vinacafe Bien Hoa Joint Stock Company',
  },
  {
    Code: 'VCG',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Xuất nhập khẩu và Xây dựng Việt Nam',
    CompanyNameEn:
      'Vietnam Construction And Import - Export Joint Stock Corporation',
  },
  {
    Code: 'VCI',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Bản Việt',
    CompanyNameEn: 'Viet Capital Securities Joint Stock Company',
  },
  {
    Code: 'VCM',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Nhân lực và Thương mại Vinaconex',
    CompanyNameEn: 'Vinaconex Trading And Manpower Joint Stock Company',
  },
  {
    Code: 'VCP',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Năng lượng VCP',
    CompanyNameEn: 'VCP Power &amp; Construction Joint Stock Company',
  },
  {
    Code: 'VCR',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Du lịch Vinaconex',
    CompanyNameEn:
      'Vinaconex Investment and Tourism Development Joint Stock Company',
  },
  {
    Code: 'VCS',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VICOSTONE',
    CompanyNameEn: 'VICOSTONE Joint Stock Company',
  },
  {
    Code: 'VCT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn Xây dựng Vinaconex',
    CompanyNameEn: "Vinaconex's Construction Consultant Joint Stock Company",
  },
  {
    Code: 'VCW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Nước sạch Sông Đà',
    CompanyNameEn: 'Song Da Water Investment Joint Stock Company',
  },
  {
    Code: 'VCX',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng Yên Bình',
    CompanyNameEn: 'Yen Binh Cement Joint Stock Company',
  },
  {
    Code: 'VDB',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Chế biến Than Đông Bắc',
    CompanyNameEn:
      'Dong Bac Transport and Processing of Coal Joint Stock Company',
  },
  {
    Code: 'VDL',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thực phẩm Lâm Đồng',
    CompanyNameEn: 'Lam Dong Foodstuffs Joint Stock Company',
  },
  {
    Code: 'VDN',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vinatex Đà Nẵng',
    CompanyNameEn: 'Vinatex Da Nang Joint Stock Company',
  },
  {
    Code: 'VDP',
    Sector: 'healthcare',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Dược phẩm Trung ương VIDIPHA',
    CompanyNameEn: 'VIDIPHA Central Pharmaceutical Joint Stock Company',
  },
  {
    Code: 'VDS',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Rồng Việt',
    CompanyNameEn: 'Viet Dragon Securities Corporation',
  },
  {
    Code: 'VDT',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lưới thép Bình Tây',
    CompanyNameEn: 'Binh Tay Steel Netting Joint Stock Company',
  },
  {
    Code: 'VE1',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng điện VNECO 1',
    CompanyNameEn: 'VNECO 1 Electricity Construction Joint Stock Company',
  },
  {
    Code: 'VE2',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Điện VNECO 2',
    CompanyNameEn: 'VNECO 2 Electricity Construction Joint Stock Company',
  },
  {
    Code: 'VE3',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng điện VNECO 3',
    CompanyNameEn: 'VNECO 3 Electricity Construction Joint Stock Company',
  },
  {
    Code: 'VE4',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng điện VNECO4',
    CompanyNameEn: 'VNECO4 Electricity Construction Joint Stock Company',
  },
  {
    Code: 'VE8',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng Điện Vneco 8',
    CompanyNameEn: 'Vneco 8 Electricity Construction Joint Stock Corporation',
  },
  {
    Code: 'VE9',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng VNECO 9',
    CompanyNameEn: 'VNECO 9 Investment & Construction JSC',
  },
  {
    Code: 'VEA',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng Công ty Máy động lực và Máy nông nghiệp Việt Nam - CTCP',
    CompanyNameEn: 'Vietnam Engine and Agricultural Machinery Corporation',
  },
  {
    Code: 'VEC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Điện tử và Tin học Việt Nam',
    CompanyNameEn:
      'Vietnam Electronics And Informatics Joint Stock Corporation',
  },
  {
    Code: 'VEF',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Trung tâm Hội chợ Triển lãm Việt Nam',
    CompanyNameEn: 'Vietnam Exhibition Fair Centre Joint Stock Company',
  },
  {
    Code: 'VES',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Điện MÊ CA VNECO',
    CompanyNameEn:
      'Meca Vneco Investment And Electricity Construction Joint Stock Company',
  },
  {
    Code: 'VET',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thuốc Thú y Trung ương NAVETCO',
    CompanyNameEn: 'NAVETCO National Veterinary Joint Stock Company',
  },
  {
    Code: 'VFC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VINAFCO',
    CompanyNameEn: 'Vinafco Joint Stock Corporation',
  },
  {
    Code: 'VFG',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khử trùng Việt Nam',
    CompanyNameEn: 'Vietnam Fumigation Joint Stock Company',
  },
  {
    Code: 'VFR',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Thuê tàu',
    CompanyNameEn: 'Transport and Chartering Corporation',
  },
  {
    Code: 'VFS',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Nhất Việt',
    CompanyNameEn: 'Viet First Securities Corporation',
  },
  {
    Code: 'VGC',
    Sector: 'industryRealEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Viglacera - Công ty Cổ phần',
    CompanyNameEn: 'Viglacera Corporation',
  },
  {
    Code: 'VGG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công Ty Cổ phần May Việt Tiến',
    CompanyNameEn: 'Viet Tien Garment Corporation',
  },
  {
    Code: 'VGI',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Đầu tư Quốc tế Viettel',
    CompanyNameEn: 'Viettel Global Investment Joint Stock Company',
  },
  {
    Code: 'VGL',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Mạ kẽm công nghiệp Vingal-Vnsteel',
    CompanyNameEn: 'Vingal Industries Joint Stock Company',
  },
  {
    Code: 'VGP',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Cảng Rau Quả',
    CompanyNameEn: 'The Vegetexco Port Joint Stock Company',
  },
  {
    Code: 'VGR',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cảng xanh VIP',
    CompanyNameEn: 'VIP Green Port Joint Stock Company',
  },
  {
    Code: 'VGS',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Ống thép Việt Đức VG PIPE',
    CompanyNameEn: 'Vietnam-Germany Steel Pipe Joint Stock Company',
  },
  {
    Code: 'VGT',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tập đoàn Dệt May Việt Nam',
    CompanyNameEn: 'Vietnam National Textile And Garment Group',
  },
  {
    Code: 'VGV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Tư vấn Xây dựng Việt Nam - CTCP',
    CompanyNameEn:
      'Vietnam National Construction Consultants Corporation - JSC',
  },
  {
    Code: 'VHC',
    Sector: 'fisheriesIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vĩnh Hoàn',
    CompanyNameEn: 'Vinh Hoan Corporation',
  },
  {
    Code: 'VHD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư phát triển nhà và đô thị VINAHUD',
    CompanyNameEn:
      'VINAHUD Urban and Housing Development Investment Joint Stock Company',
  },
  {
    Code: 'VHE',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dược liệu và Thực phẩm Việt Nam',
    CompanyNameEn: 'Viet Nam Herbs and Foods Joint Stock Company',
  },
  {
    Code: 'VHF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây dựng và Chế biến lương thực Vĩnh Hà',
    CompanyNameEn:
      'Vinh Ha Food Processing And Construction Joint Stock Company',
  },
  {
    Code: 'VHG',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Việt Trung Nam',
    CompanyNameEn:
      'Viet Trung Nam Investment and Development Joint Stock Company',
  },
  {
    Code: 'VHH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Kinh doanh nhà Thành Đạt',
    CompanyNameEn: 'Thanh Dat Investment House Trading Joint Stock Company',
  },
  {
    Code: 'VHL',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Viglacera Hạ Long',
    CompanyNameEn: 'Viglacera Ha Long Joint Stock Company',
  },
  {
    Code: 'VHM',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Vinhomes',
    CompanyNameEn: 'Vinhomes Joint Stock Company',
  },
  {
    Code: 'VIB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Quốc tế Việt Nam',
    CompanyNameEn: 'Vietnam International Commercial Joint Stock Bank',
  },
  {
    Code: 'VIC',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Tập đoàn Vingroup - Công ty Cổ phần',
    CompanyNameEn: 'VinGroup Joint Stock Company',
  },
  {
    Code: 'VID',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Thương mại Viễn Đông',
    CompanyNameEn: 'Vien Dong Investment Development Trading Corporation',
  },
  {
    Code: 'VIE',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghệ Viễn thông VI TE CO',
    CompanyNameEn:
      'VITECO Vietnam Telecommunications Technology Joint Stock Company',
  },
  {
    Code: 'VIF',
    Sector: 'materials',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Lâm nghiệp Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'VietNam Forestry Corporation - Joint Stock Company',
  },
  {
    Code: 'VIG',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn:
      'Công ty Cổ phần Chứng khoán Thương mại và Công nghiệp Việt Nam',
    CompanyNameEn:
      'Vietnam Industrial & Commercial Securities Joint Stock Company',
  },
  {
    Code: 'VIH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Viglacera Hà Nội',
    CompanyNameEn: 'Viglacera Ha Noi Joint Stock Company',
  },
  {
    Code: 'VIM',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Viglacera',
    CompanyNameEn: 'Viglacera Mineral Joint Stock Company',
  },
  {
    Code: 'VIN',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giao nhận Kho vận Ngoại thương Việt Nam',
    CompanyNameEn: 'VINATRANS',
  },
  {
    Code: 'VIP',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Xăng dầu VIPCO',
    CompanyNameEn: 'Vietnam Petroleum Transport Joint Stock Company',
  },
  {
    Code: 'VIR',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch Quốc tế Vũng Tàu',
    CompanyNameEn: 'VungTau Intourco Resort Joint Stock Company',
  },
  {
    Code: 'VIT',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Viglacera Tiên Sơn',
    CompanyNameEn: 'Viglacera Tien Son Joint Stock Company',
  },
  {
    Code: 'VIW',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng công ty Đầu tư Nước và Môi trường Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Vietnam Water and Environment Investment Corporation - JSC',
  },
  {
    Code: 'VIX',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán VIX',
    CompanyNameEn: 'VIX Securities Joint Stock Company',
  },
  {
    Code: 'VJC',
    Sector: 'consumers',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Hàng không Vietjet',
    CompanyNameEn: 'VietJet Aviation Joint Stock Company',
  },
  {
    Code: 'VKC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VKC Holdings',
    CompanyNameEn: 'VKC Holdings Joint Stock Company',
  },
  {
    Code: 'VKP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Tân Hóa',
    CompanyNameEn: 'Viky Plastic Joint Stock Company',
  },
  {
    Code: 'VLA',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Công nghệ Văn Lang',
    CompanyNameEn:
      'Van Lang Technology Development and Investment Joint Stock Company',
  },
  {
    Code: 'VLB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Xây dựng và Sản xuất Vật liệu Xây dựng Biên Hòa',
    CompanyNameEn:
      'Bien Hoa Building Materials Production and Construction Joint Stock Company',
  },
  {
    Code: 'VLC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Chăn nuôi Việt Nam - Công ty Cổ phần',
    CompanyNameEn: 'Vietnam LiveStock Corporation',
  },
  {
    Code: 'VLF',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Lương thực Thực phẩm Vĩnh Long',
    CompanyNameEn: 'VinhLong Cereal and Food Corporation',
  },
  {
    Code: 'VLG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VIMC Logistics',
    CompanyNameEn: 'VIMC Logistics Joint Stock Company',
  },
  {
    Code: 'VLP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công trình Công cộng Vĩnh Long',
    CompanyNameEn: 'Vinh Long Public Works Joint Stock Company',
  },
  {
    Code: 'VLW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp nước Vĩnh Long',
    CompanyNameEn: 'Vinh Long Water Supply Joint Stock Company',
  },
  {
    Code: 'VMA',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Công nghiệp Ô tô Vinacomin',
    CompanyNameEn: 'Vinacomin Motor Industry Joint Stock Company',
  },
  {
    Code: 'VMC',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần VIMECO',
    CompanyNameEn: 'Vimeco Joint Stock Company',
  },
  {
    Code: 'VMD',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Y Dược phẩm Vimedimex',
    CompanyNameEn: 'Vimedimex Medi - Pharma Joint Stock Company',
  },
  {
    Code: 'VMG',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thương mại và Dịch vụ Dầu khí Vũng Tàu',
    CompanyNameEn:
      'Vung Tau Petroleum Trading and Services Joint Stock Company',
  },
  {
    Code: 'VMS',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Hàng Hải',
    CompanyNameEn: 'Vietnam Maritime Development Joint Stock Company',
  },
  {
    Code: 'VNA',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải biển Vinaship',
    CompanyNameEn: 'Vinaship Joint Stock Company',
  },
  {
    Code: 'VNB',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sách Việt Nam',
    CompanyNameEn: 'Vietnam Books Joint Stock Company',
  },
  {
    Code: 'VNC',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Vinacontrol',
    CompanyNameEn: 'Vinacontrol Group Corporation',
  },
  {
    Code: 'VND',
    Sector: 'financials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán VNDIRECT',
    CompanyNameEn: 'VNDIRECT Securities Corporation',
  },
  {
    Code: 'VNE',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Xây dựng điện Việt Nam',
    CompanyNameEn: 'Vietnam Electricity Construction Joint Stock Corporation',
  },
  {
    Code: 'VNF',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vinafreight',
    CompanyNameEn: 'Vinafreight Joint Stock Company',
  },
  {
    Code: 'VNG',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Du Lịch Thành Thành Công',
    CompanyNameEn: 'Thanh Thanh Cong Tourism Joint Stock Company',
  },
  {
    Code: 'VNH',
    Sector: 'fisheriesIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Việt Việt Nhật',
    CompanyNameEn: 'Viet Viet Nhat Investment Corporation',
  },
  {
    Code: 'VNI',
    Sector: 'realEstate',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Bất động sản Việt Nam',
    CompanyNameEn: 'Vietnam Land Investment Corporation',
  },
  {
    Code: 'VNL',
    Sector: 'industrials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Logistics Vinalink',
    CompanyNameEn: 'Vinalink Logistics Corporation',
  },
  {
    Code: 'VNM',
    Sector: 'agricalture',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Sữa Việt Nam',
    CompanyNameEn: 'Vietnam Dairy Products Joint Stock Company',
  },
  {
    Code: 'VNP',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nhựa Việt Nam',
    CompanyNameEn: 'Vietnam Plastic Corporation',
  },
  {
    Code: 'VNR',
    Sector: 'insurances',
    Exchange: 'HNX',
    CompanyNameVn: 'Tổng Công ty Cổ phần Tái bảo hiểm Quốc gia Việt Nam',
    CompanyNameEn: 'Viet Nam National Reinsurance Corporation',
  },
  {
    Code: 'VNS',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Ánh Dương Việt Nam',
    CompanyNameEn: 'Vietnam Sun Corporation',
  },
  {
    Code: 'VNT',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Giao nhận Vận tải Ngoại Thương',
    CompanyNameEn:
      'Foreign Trade Forwarding and Transportation Joint Stock Company',
  },
  {
    Code: 'VNX',
    Sector: 'communicationServices',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Quảng cáo và Hội chợ Thương mại Vinexad',
    CompanyNameEn: 'Vietnam National Trade Fair And Advertising Company',
  },
  {
    Code: 'VNY',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thuốc thú y Trung ương I',
    CompanyNameEn: 'Vietnam Vererinary Products Joint Stock Company',
  },
  {
    Code: 'VOC',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Tổng Công ty Công nghiệp Dầu thực vật Việt Nam - Công ty Cổ phần',
    CompanyNameEn:
      'Vietnam Vegetable Oils Industry Corporation - Joint Stock Company',
  },
  {
    Code: 'VOS',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Biển Việt Nam',
    CompanyNameEn: 'Vietnam Ocean Shipping Joint Stock Company',
  },
  {
    Code: 'VPA',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Hóa dầu VP',
    CompanyNameEn: 'VP Petrochemical Transport Joint Stock Company',
  },
  {
    Code: 'VPB',
    Sector: 'banks',
    Exchange: 'HSX30',
    CompanyNameVn: 'Ngân hàng Thương mại Cổ phần Việt Nam Thịnh Vượng',
    CompanyNameEn: 'Vietnam Prosperity Joint Stock Commercial Bank',
  },
  {
    Code: 'VPC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Phát triển Năng lượng Việt Nam',
    CompanyNameEn:
      'Vietnam Power Investment and Development Joint Stock Company',
  },
  {
    Code: 'VPD',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Phát triển Điện lực Việt Nam',
    CompanyNameEn: 'Vietnam Power Development Joint Stock Company',
  },
  {
    Code: 'VPG',
    Sector: 'materials',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Thương mại Xuất nhập khẩu Việt Phát',
    CompanyNameEn:
      'Viet Phat Import Export Trading Investment Joint Stock Company',
  },
  {
    Code: 'VPH',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vạn Phát Hưng',
    CompanyNameEn: 'Van Phat Hung Corporation',
  },
  {
    Code: 'VPI',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Văn Phú - Invest',
    CompanyNameEn: 'Van Phu - Invest Investment Joint Stock Company',
  },
  {
    Code: 'VPR',
    Sector: 'materials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VINAPRINT',
    CompanyNameEn: 'VINAPRINT Corporation',
  },
  {
    Code: 'VPS',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thuốc sát trùng Việt Nam',
    CompanyNameEn: 'Vietnam Pesticide Joint Stock Company',
  },
  {
    Code: 'VPW',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Cấp thoát nước số 1 Vĩnh Phúc',
    CompanyNameEn: 'Vinhphuc No1 Water Supply and Drainage Joint Stock Company',
  },
  {
    Code: 'VQC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Giám định Vinaconmin',
    CompanyNameEn: 'VINACOMIN QUACONTROL Joint-stock Company',
  },
  {
    Code: 'VRC',
    Sector: 'realEstate',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Bất động sản và Đầu tư VRC',
    CompanyNameEn: 'VRC Real Estate and Investment Joint Stock Company',
  },
  {
    Code: 'VRE',
    Sector: 'realEstate',
    Exchange: 'HSX30',
    CompanyNameVn: 'Công ty Cổ phần Vincom Retail',
    CompanyNameEn: 'Vincom Retail Joint Stock Company',
  },
  {
    Code: 'VRG',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Phát triển Đô thị và Khu Công nghiệp Cao Su Việt Nam',
    CompanyNameEn:
      'Viet Nam Rubber Urban and Industrial Zone development Joint Stock Company',
  },
  {
    Code: 'VSA',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Đại lý Hàng hải Việt Nam',
    CompanyNameEn: 'Vietnam Ocean Shipping Agency Corporation',
  },
  {
    Code: 'VSC',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Container Việt Nam',
    CompanyNameEn: 'Viet Nam Container Shipping Joint Stock Company',
  },
  {
    Code: 'VSE',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Dịch vụ Đường cao tốc Việt Nam',
    CompanyNameEn: 'Vietnam Expressway Services Joint Stock Company',
  },
  {
    Code: 'VSF',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Lương thực Miền Nam - Công ty Cổ phần',
    CompanyNameEn: 'Vietnam Southern Food Corporation - Joint Stock Company',
  },
  {
    Code: 'VSG',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Container Phía Nam',
    CompanyNameEn: 'Southern Vietnam Container Shipping Joint Stock Company',
  },
  {
    Code: 'VSH',
    Sector: 'utility',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Vĩnh Sơn - Sông Hinh',
    CompanyNameEn: 'Vinh Son - Song Hinh Hydropower Joint Stock Company',
  },
  {
    Code: 'VSI',
    Sector: 'constructions',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư và Xây dựng Cấp thoát nước',
    CompanyNameEn:
      'Water Supply Sewerage Construction and Investment Joint Stock Company',
  },
  {
    Code: 'VSM',
    Sector: 'industrials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Container Miền Trung',
    CompanyNameEn: 'Central Container Joint Stock Company',
  },
  {
    Code: 'VSN',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Việt Nam Kỹ Nghệ Súc Sản',
    CompanyNameEn: 'VISSAN Joint Stock Company',
  },
  {
    Code: 'VSP',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'VSP',
    CompanyNameEn: 'VSP',
  },
  {
    Code: 'VST',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Thuê Tàu biển Việt Nam',
    CompanyNameEn: 'Viet Nam Sea Transport and Chartering Joint Stock Company',
  },
  {
    Code: 'VTA',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VITALY',
    CompanyNameEn: 'Vitaly Joint Stock Company',
  },
  {
    Code: 'VTB',
    Sector: 'consumers',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Viettronics Tân Bình',
    CompanyNameEn: 'Viettronics Tan Binh Joint Stock Company',
  },
  {
    Code: 'VTC',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Viễn Thông VTC',
    CompanyNameEn: 'VTC Telecommunications Joint Stock Company',
  },
  {
    Code: 'VTD',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vietourist Holdings',
    CompanyNameEn: 'Vietourist Holdings Joint Stock Company',
  },
  {
    Code: 'VTE',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VINACAP Kim Long',
    CompanyNameEn: 'VINACAP Kim Long Joint Stock Company',
  },
  {
    Code: 'VTG',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Du lịch tỉnh Bà Rịa - Vũng Tàu',
    CompanyNameEn: 'Ba Ria - Vung tau Tourist Joint Stock Company',
  },
  {
    Code: 'VTH',
    Sector: 'informationTechnology',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Dây cáp điện Việt Thái',
    CompanyNameEn: 'Viet Thai Electric Cable Corporation',
  },
  {
    Code: 'VTI',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất - Xuất nhập khẩu Dệt may',
    CompanyNameEn:
      'Textile - Garment Import - Export and Production Joint Stock Company',
  },
  {
    Code: 'VTJ',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Thương mại và Đầu tư VI NA TA BA',
    CompanyNameEn: 'VI NA TA BA Trading & Investment Joint Stock Company',
  },
  {
    Code: 'VTK',
    Sector: 'informationTechnology',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Tư vấn và Dịch vụ Viettel',
    CompanyNameEn: 'Viettel Consultancy and Services Joint Stock Company',
  },
  {
    Code: 'VTL',
    Sector: 'agricalture',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Vang Thăng Long',
    CompanyNameEn: 'Thang Long Wine Joint Stock Company',
  },
  {
    Code: 'VTM',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải và Đưa đón thợ mỏ - Vinacomin',
    CompanyNameEn:
      'Vinacomin - Transportation and Miner Commuting Service Joint Stock Company',
  },
  {
    Code: 'VTO',
    Sector: 'shippingIndustry',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Xăng dầu VITACO',
    CompanyNameEn: 'Vietnam Tanker Joint Stock Company',
  },
  {
    Code: 'VTP',
    Sector: 'shippingIndustry',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Bưu chính Viettel',
    CompanyNameEn: 'Viettel Post Joint Stock Corporation',
  },
  {
    Code: 'VTQ',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Việt Trung Quảng Bình',
    CompanyNameEn: 'Viet Trung Quang Binh Joint Stock Company',
  },
  {
    Code: 'VTR',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn:
      'Công ty Cổ phần Du lịch và tiếp thị giao thông vận tải Việt Nam - Vietravel',
    CompanyNameEn: 'Vietnam Travel and Marketing Transport Joint-stock Company',
  },
  {
    Code: 'VTS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Gạch Ngói Từ Sơn',
    CompanyNameEn: 'Tu Son Brick Tile Joint Stock Company',
  },
  {
    Code: 'VTV',
    Sector: 'constructions',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Năng lượng và Môi trường VICEM',
    CompanyNameEn: 'VICEM Energy And Environment Joint Stock Company',
  },
  {
    Code: 'VTX',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải Đa phương thức VIETRANSTIMEX',
    CompanyNameEn: 'VIETRANSTIMEX Multimodal Transport Holding Company',
  },
  {
    Code: 'VTZ',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Sản xuất và Thương mại Nhựa Việt Thành',
    CompanyNameEn:
      'Viet Thanh Plastic Trading and Manufacturing Joint Stock Company',
  },
  {
    Code: 'VUA',
    Sector: 'financials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Stanley Brothers',
    CompanyNameEn: 'Stanley Brothers Securities Incorporation',
  },
  {
    Code: 'VVN',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Tổng Công ty Cổ phần Xây dựng Công nghiệp Việt Nam',
    CompanyNameEn: 'Vietnam Industrial Construction Corporation',
  },
  {
    Code: 'VVS',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Phát triển Máy Việt Nam',
    CompanyNameEn: 'Vietnam Machine Investment Development Joint Stock Company',
  },
  {
    Code: 'VW3',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần VIWASEEN3',
    CompanyNameEn: 'VIWASEEN 3 Joint Stock Company',
  },
  {
    Code: 'VWS',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Nước và Môi trường Việt Nam',
    CompanyNameEn:
      'Vietnam Water, Sanitation and Environment Joint Stock Company',
  },
  {
    Code: 'VXB',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vật liệu xây dựng Bến Tre',
    CompanyNameEn: 'Ben Tre Construction Material Joint Stock Company',
  },
  {
    Code: 'VXP',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thuốc Thú y Trung ương VETVACO',
    CompanyNameEn: 'VETVACO National Veterinary Joint Stock Company',
  },
  {
    Code: 'VXT',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Kho vận và dịch vụ thương mại',
    CompanyNameEn:
      'Transport Warehousing and Trade Service Joint Stock Company',
  },
  {
    Code: 'WCS',
    Sector: 'consumers',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Bến xe Miền Tây',
    CompanyNameEn: 'West Coach Station Joint Stock Company',
  },
  {
    Code: 'WSB',
    Sector: 'agricalture',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Bia Sài Gòn - Miền Tây',
    CompanyNameEn: 'Western - Sai Gon Beer Joint Stock Company',
  },
  {
    Code: 'WSS',
    Sector: 'financials',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần Chứng khoán Phố Wall',
    CompanyNameEn: 'Wall Street Securities Company',
  },
  {
    Code: 'WTC',
    Sector: 'industrials',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Vận tải thủy - Vinacomin',
    CompanyNameEn: 'Vinacomin - Waterway Transport Joint Stock Company',
  },
  {
    Code: 'X20',
    Sector: 'textileIndustry',
    Exchange: 'HNX',
    CompanyNameVn: 'Công ty Cổ phần X20',
    CompanyNameEn: 'X20 Joint Stock Company',
  },
  {
    Code: 'X26',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần 26',
    CompanyNameEn: '26 Joint Stock Company',
  },
  {
    Code: 'X77',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thành An 77',
    CompanyNameEn: 'Thanh An 77 Joint Stock Company',
  },
  {
    Code: 'XDH',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Đầu tư Xây dựng Dân dụng Hà Nội',
    CompanyNameEn: 'Hanoi Civil Construction Investment Joint Stock Company',
  },
  {
    Code: 'XHC',
    Sector: 'consumers',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuân Hòa Việt Nam',
    CompanyNameEn: 'XuanHoa Viet Nam Joint Stock Company',
  },
  {
    Code: 'XLV',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xây lắp và Dịch vụ Sông Đà',
    CompanyNameEn: 'Song Da Construction and Service Joint Stock Company',
  },
  {
    Code: 'XMC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty cổ phần Đầu tư và Xây dựng Xuân Mai',
    CompanyNameEn: 'Xuan Mai Investment and Construction Corporation',
  },
  {
    Code: 'XMD',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuân Mai - Đạo Tú',
    CompanyNameEn: 'Xuan Mai - Dao Tu Joint Stock Company',
  },
  {
    Code: 'XMP',
    Sector: 'utility',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Thủy điện Xuân Minh',
    CompanyNameEn: 'Xuan Minh Hydro Power Joint Stock Company',
  },
  {
    Code: 'XPH',
    Sector: 'chemicals',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xà phòng Hà Nội',
    CompanyNameEn: 'Hanoi Soap Joint Stock Company',
  },
  {
    Code: 'YBC',
    Sector: 'constructions',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xi măng và Khoáng sản Yên Bái',
    CompanyNameEn: 'YenBai Cement and Minerals Joint Stock Company',
  },
  {
    Code: 'YBM',
    Sector: 'chemicals',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Khoáng sản Công nghiệp Yên Bái',
    CompanyNameEn: 'Yen Bai Industry Mineral Joint Stock Company',
  },
  {
    Code: 'YEG',
    Sector: 'communicationServices',
    Exchange: 'HSX',
    CompanyNameVn: 'Công ty Cổ phần Tập đoàn Yeah1',
    CompanyNameEn: 'Yeah1 Group Corporation',
  },
  {
    Code: 'YTC',
    Sector: 'healthcare',
    Exchange: 'UPCOM',
    CompanyNameVn: 'Công ty Cổ phần Xuất nhập khẩu Y tế Thành phố Hồ Chí Minh',
    CompanyNameEn: 'Ho Chi Minh City Medical Import Export Joint Stock Company',
  },
];

import { gql } from '@apollo/client';

export const SUB_STOCK_PRICE = gql`
  subscription StockPrice($symbols: [String!]!) {
    stockPrice(symbols: $symbols) {
      lastPrice
      ratioChange
      change
      refPrice
      ceiling
      floor
      low
      high
      totalVol
      totalVal
      exchange
    }
  }
`;

import CopyrightIcon from '@mui/icons-material/Copyright';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Avatar, Badge, Card, List, Skeleton, Space, Tooltip, Typography } from 'antd';
import EditOutlineIcon from 'assets/icons/EditOutlineIcon';
import FacebookIcon from 'assets/icons/FacebookIcon';
import TelegramIcon from 'assets/icons/TelegramIcon';
import ZaloIcon from 'assets/icons/ZaloIcon';
import { avatarSelector, getAvatarUrl } from 'features/copyTrade/copyTradeSlice';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency, formatNumberToBillions, getFirstAndLastWords } from 'utils';

export const LeaderHeader = ({
  detail,
  onRename,
  onSocialsChange,
  onProfitChange,
  onAvatarChange,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const avatar = useSelector(avatarSelector);
  const SOCIALS = useMemo(
    () => [
      {
        icon: <FacebookIcon />,
        url: detail?.facebookUrl,
      },
      {
        icon: <TelegramIcon />,
        url: detail?.telegramUrl,
      },
      {
        icon: <ZaloIcon />,
        url: detail?.zaloUrl,
      },
    ],
    [detail],
  );

  useEffect(() => {
    detail?.avatar && dispatch(getAvatarUrl({ filename: detail?.avatar }));
  }, [detail?.avatar, dispatch]);

  const skeletonData = Array.from({
    length: 1,
  }).map((_, i) => ({
    href: '',
    title: `DATX Technologies part ${i + 1}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description: 'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content: 'Data placeholder for DATX Technologies',
  }));

  return (
    <Card className="w-full !bg-custom-darkBlue600 border-0 !rounded-xl lg:h-[250px]">
      {!loading && (
        <div className="flex flex-col lg:flex-row gap-8 max-w-[1080px] py-4">
          <div>
            <div>
              <Card.Meta
                avatar={
                  avatar ? (
                    <Avatar src={avatar} size={128} className="mr-8 cursor-pointer" onClick={onAvatarChange} />
                  ) : (
                    <div
                      className="w-56 h-56 rounded-full bg-custom-darkBlue400 flex items-center justify-center mr-8 cursor-pointer"
                      onClick={onAvatarChange}
                    >
                      {loading && (
                        <Typography.Text className="text-6xl lg:text-7xl">
                          {detail?.nickname
                            ? getFirstAndLastWords(detail?.nickname)
                            : detail?.name
                            ? getFirstAndLastWords(detail?.name)
                            : ''}
                        </Typography.Text>
                      )}
                    </div>
                  )
                }
                title={
                  <div className="flex items-center gap-4">
                    <Typography.Text className="text-5xl lg:text-6xl text-left">
                      {detail?.nickname || detail?.name}
                    </Typography.Text>

                    <div className="w-8 h-8 cursor-pointer fill-[#908F99] hover:fill-gray-500" onClick={onRename}>
                      <EditOutlineIcon />
                    </div>
                  </div>
                }
                description={
                  <>
                    <div className="flex flex-col md:flex-row md:items-center text-white gap-2">
                      <div>
                        <VerifiedIcon className="text-custom-yellow500 mr-3" />
                        Bạn đang là leader
                      </div>
                      {detail?.subAccountId && (
                        <div className="flex">
                          <Badge className="!pl-3 !hidden md:!block" color="gray" />
                          <div>{detail.subAccountId}</div>
                        </div>
                      )}
                      {detail?.source && (
                        <div className="flex">
                          <Badge className="!pl-3 !hidden md:!block" color="gray" />
                          <div>{detail.source}</div>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-6 mt-4 !text-custom-darkBlue200">
                      <Space
                        direction="vertical"
                        size=""
                        className="pr-4 border-custom-darkBlue200"
                        style={{ borderRight: '1px solid' }}
                      >
                        <Space>
                          <SupervisorAccountIcon className="!w-8 !h-8" />
                          Followers
                        </Space>
                        <Typography.Text className="text-3xl font-bold text-white mt-1 block">
                          {detail?.followerCount || 0}
                        </Typography.Text>
                      </Space>
                      <Space direction="vertical" size="">
                        <Space>
                          <CopyrightIcon className="!w-8 !h-8" />
                          Copiers
                        </Space>
                        <Typography.Text className="text-3xl font-bold text-white mt-1 block">
                          {detail?.copierCount || 0}
                        </Typography.Text>
                      </Space>
                    </div>
                    <div className="flex items-center mt-4 gap-4">
                      {SOCIALS.map((social, index) => (
                        <a key={index} href={social?.url} target="_blank" rel="noreferrer">
                          {social?.icon}
                        </a>
                      ))}
                      <div
                        className="w-7 h-7 cursor-pointer fill-[#908F99] hover:fill-gray-500 ml-2"
                        onClick={() =>
                          onSocialsChange({
                            facebookUrl: detail?.facebookUrl,
                            telegramUrl: detail?.telegramUrl,
                            zaloUrl: detail?.zaloUrl,
                          })
                        }
                      >
                        <EditOutlineIcon />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-grow lg:pl-24 gap-3">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div className="text-custom-gray300">Lợi nhuận kỳ vọng/năm</div>
                <div
                  className="w-7 h-7 cursor-pointer fill-[#908F99] hover:fill-gray-500 ml-2"
                  onClick={onProfitChange}
                >
                  <Tooltip
                    color="var(--light-blue-color)"
                    placement="bottom"
                    title={
                      <div>
                        <h3>Cập nhật lợi nhuận kỳ vọng</h3>
                        <p>Hãy cập nhật lợi nhuận kỳ vọng đạt được trong 1 năm của</p>
                        <p>Đây sẽ là cơ sở để DATX tính phí hiệu quả đầu tư cho leader.</p>
                      </div>
                    }
                    trigger={['hover', 'click']}
                  >
                    <EditOutlineIcon />
                  </Tooltip>
                </div>
              </div>
              <div className="font-bold text-custom-green500">{detail?.expectProfit}%</div>
            </div>
            <div className="flex justify-between">
              <div className="text-custom-gray300">Tổng NAV sao chép</div>
              <div className="font-bold text-custom-green500">
                {formatNumberToBillions(detail?.stockData?.totalFollowerNav * 1000)} VND
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-custom-gray300">NAV</div>
              <div className="font-bold">{formatNumberToBillions(detail?.nav)} VND</div>
            </div>
            <div className="flex justify-between">
              <div className="text-custom-gray300">Tổng phí sao chép tạm tính </div>
              <div className="font-bold">{formatCurrency(0)} VND</div>
            </div>
            <div className="flex justify-between">
              <div className="text-custom-gray300">Tổng phí sao chép đã nhận</div>
              <div className="font-bold">{formatCurrency(0)} VND</div>
            </div>
            <div className="flex justify-between">
              <div className="text-custom-gray300">Tổng phí hiệu quả đầu tư đã nhận</div>
              <div className="font-bold">{formatCurrency(0)} VND</div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <List
          itemLayout="vertical"
          size="large"
          dataSource={skeletonData}
          renderItem={(item) => (
            <List.Item key={item.title} actions={null}>
              <Skeleton loading={loading} active avatar={{ size: 126 }}>
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={<a href={item.href}>{item.title}</a>}
                  description={item.description}
                />
                {item.content}
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

import React from 'react';

const FailureIconInNotiDialog = ({ color }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="38"
        viewBox="0 0 41 38"
        fill="none"
      >
        <path
          d="M22.768 1.06401C22.076 -0.243992 19.924 -0.243992 19.232 1.06401L1.23199 35.064C1.06996 35.3687 0.989632 35.7103 0.998834 36.0553C1.00804 36.4003 1.10645 36.737 1.2845 37.0327C1.46255 37.3284 1.71415 37.5729 2.01479 37.7424C2.31543 37.9119 2.65486 38.0007 2.99999 38H39C39.3449 38.0007 39.6841 37.9121 39.9845 37.7426C40.285 37.5732 40.5364 37.3288 40.7142 37.0333C40.8921 36.7378 40.9904 36.4013 40.9994 36.0565C41.0085 35.7117 40.9281 35.3705 40.766 35.066L22.768 1.06401ZM23 32H19V28H23V32ZM19 24V14H23L23.002 24H19Z"
          fill="var(--light-red-color)"
        />
      </svg>
    </>
  );
};

export default FailureIconInNotiDialog;

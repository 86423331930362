import styled from 'styled-components';

export const AnnouncementWrapper = styled.div`
  background: var(--primary-color);
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  height: var(--announcement-height);
  width: 100%;
  flex-flow: row;
  position: relative;
  display: flex;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: 2rem;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    padding: 4px;
  }

  .anticon {
    & > svg > path {
      fill: var(--border-color) !important;
    }
    &:hover {
      svg > path {
        fill: var(--light-blue-color-2) !important;
      }
    }
  }
`;

export const AnnouncementContent = styled.div`
  color: var(--white-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  flex: 1;

  display: flex;
  align-items: center;
  width: calc(100% - 200px);
`;

export const AnnouncementNumberSlider = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const AnnouncementListWrapper = styled.div`
  padding: 1.2rem;
  color: var(--white-color);
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 66vh;
  overflow: auto;

  > div {
    padding: 0.6rem 0;
  }

  > div:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
`;

export const CurrentAnnouncementWrapper = styled.div`
  margin-left: 0.4rem;
  flex: 1;
  width: inherit;

  .announcement-time {
    display: none;
  }

  .announcement-title {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .announcement-link-1 {
    display: none;
  }

  .announcement-link-2 {
    display: unset !important;
  }
`;

export const AnnouncementItemWrapper = styled.div`
  display: flex;
  align-items: start;
`;

export const AnnouncementTimeStyled = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8e8e93;
  padding-right: 1.2rem;
  white-space: nowrap;
`;

export const AnnouncementTitleStyled = styled.span`
  font-size: 14px;
  line-height: 20px;

  > div:nth-child(2) {
    display: none;
  }
`;

export const AnnouncementLinkStyled = styled.a`
  text-decoration: underline;
  margin-left: 8px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

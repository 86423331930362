import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

let controller;

const initialState = {
  loading: false,
  data: [],
  summary: {
    efficiency_closed: 0,
    efficiency_temporary: 0,
  },
  paging: {
    page: 1,
    page_size: 30,
    total: 0,
  },
};

export const getRecommendationPerformance = createAsyncThunk(
  'recommendationPerformance/get-data',
  async (
    { symbol, startDate, endDate, page, limit = 30, polling },
    { rejectWithValue }
  ) => {
    controller && controller.abort();
    controller = new AbortController();

    let url = `${process.env.REACT_APP_RECOMMENDATION_API_URL}/positions?symbol=${symbol}&page=${page}&page_size=${limit}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    const response = await axiosInstance.get(url, {
      signal: controller.signal,
    });

    const data = response.data;

    const params = new URLSearchParams();
    if (startDate) {
      params.append('startDate', startDate);
    }
    if (endDate) {
      params.append('endDate', endDate);
    }
    const seUrl = `${
      process.env.REACT_APP_RECOMMENDATION_API_URL
    }/summary-efficiency?${params.toString()}`;
    const seResponse = await axiosInstance.get(seUrl);

    if (!data || data.status === false) {
      return rejectWithValue(data);
    }

    return {
      data: data.data.positions,
      summary: seResponse.data.data,
      paging: data.data.paging,
      polling: polling,
    };
  }
);

export const slice = createSlice({
  name: 'recommendationPerformance',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    getHistory: (state, { payload }) => {
      state.symbol = payload;
    },
  },
  extraReducers: {
    [getRecommendationPerformance.pending]: (state, action) => {
      if (!action?.meta?.arg?.polling) {
        state.loading = true;
      }
    },
    [getRecommendationPerformance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.summary.efficiency_closed =
        payload.summary?.efficiency_closed?.toFixed(2);
      state.summary.efficiency_temporary =
        payload.summary?.efficiency_temporary?.toFixed(2);
      state.paging = payload.paging;
    },
    [getRecommendationPerformance.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoading } = slice.actions;

export const recommendationPerformanceReducer = slice.reducer;

const ZaloIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#0068FF"
        fillRule="evenodd"
        d="M22.782.166016h4.417c6.0663 0 9.6113.890994 12.7582 2.578194 3.1469 1.68719 5.6303 4.15164 7.2985 7.29859 1.6872 3.1469 2.5782 6.6919 2.5782 12.7582v4.3981c0 6.0663-.891 9.6113-2.5782 12.7582-1.6872 3.1469-4.1516 5.6304-7.2985 7.2986-3.1469 1.6872-6.6919 2.5782-12.7582 2.5782h-4.3981c-6.0663 0-9.6113-.891-12.7582-2.5782-3.14687-1.6872-5.63027-4.1517-7.2985-7.2986C1.057 36.8104.166016 33.2654.166016 27.1991V22.801c0-6.0663.890984-9.6113 2.578184-12.7582 1.68719-3.14695 4.15163-5.63035 7.2985-7.29859C13.1707 1.05701 16.7346.166016 22.782.166016Z"
        clipRule="evenodd"
      />
      <path
        fill="#001A33"
        fillRule="evenodd"
        d="M49.8336 26.4736v.7258c0 6.0663-.8909 9.6113-2.5781 12.7582-1.6872 3.1469-4.1517 5.6303-7.2986 7.2986-3.1469 1.6872-6.6919 2.5782-12.7582 2.5782h-4.398c-4.9638 0-8.2395-.5966-10.9903-1.7378l-4.53501-4.6699L49.8336 26.4736Z"
        clipRule="evenodd"
        opacity=".12"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.779 43.5892c2.3229.2568 5.2271-.4056 7.2892-1.4067 8.9543 4.9493 22.9515 4.7129 31.4241-.7093.3286-.4929.6356-1.0055.9205-1.5369 1.6934-3.1584 2.5876-6.7163 2.5876-12.8047v-4.4141c0-6.0885-.8942-9.6464-2.5876-12.80477-1.6743-3.15837-4.1667-5.6318-7.3251-7.32515C36.9293.894239 33.3714 0 27.283 0h-4.4331c-5.1855 0-8.5517.652754-11.38 1.89893-.1546.13844-.3063.27925-.4548.42242C2.71734 10.3203 2.08658 27.6593 9.12279 37.0782c.00785.0139.01654.0279.0261.0421 1.08451 1.5982.03805 4.3951-1.59821 6.0313-.26637.2474-.17124.3996.22832.4376Z"
        clipRule="evenodd"
      />
      <path
        fill="#0068FF"
        d="M20.5632 17h-9.725v2.0853h6.7487l-6.654 8.2464c-.2085.3033-.3601.5877-.3601 1.2322v.5308h9.1752c.455 0 .8342-.3791.8342-.8341v-1.1185h-7.09l6.2558-7.8483c.0948-.1137.2654-.3222.3413-.417l.0379-.0569c.3602-.5308.436-.9858.436-1.5355V17ZM32.9416 29.0947h1.3839V17h-2.0853v11.3933c0 .3792.3033.7014.7014.7014ZM25.814 19.6924c-2.6161 0-4.7393 2.1232-4.7393 4.7393s2.1232 4.7393 4.7393 4.7393 4.7393-2.1232 4.7393-4.7393c.019-2.6161-2.1042-4.7393-4.7393-4.7393Zm0 7.526c-1.5355 0-2.7867-1.2512-2.7867-2.7867s1.2512-2.7867 2.7867-2.7867 2.7867 1.2512 2.7867 2.7867-1.2322 2.7867-2.7867 2.7867ZM40.4867 19.6162c-2.6351 0-4.7772 2.1422-4.7772 4.7772 0 2.6351 2.1421 4.7773 4.7772 4.7773 2.635 0 4.7772-2.1422 4.7772-4.7773 0-2.635-2.1422-4.7772-4.7772-4.7772Zm0 7.6019c-1.5545 0-2.8057-1.2512-2.8057-2.8057 0-1.5545 1.2512-2.8057 2.8057-2.8057 1.5545 0 2.8057 1.2512 2.8057 2.8057 0 1.5545-1.2512 2.8057-2.8057 2.8057Z"
      />
      <path
        fill="#0068FF"
        d="M29.4562 29.0944h1.1185V19.957h-1.9526v8.3223c0 .436.3791.8151.8341.8151Z"
      />
    </svg>
  );
};

export default ZaloIcon;

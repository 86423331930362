const GreenTickIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_134_4129)">
        <path
          d="M5 9.16659C2.69875 9.16659 0.833332 7.30117 0.833332 4.99992C0.833332 2.69867 2.69875 0.833252 5 0.833252C7.30125 0.833252 9.16667 2.69867 9.16667 4.99992C9.16667 7.30117 7.30125 9.16659 5 9.16659ZM4.58458 6.66659L7.53042 3.72034L6.94125 3.13117L4.58458 5.48825L3.40583 4.3095L2.81667 4.89867L4.58458 6.66659Z"
          fill="#1ED994"
        />
      </g>
      <defs>
        <clipPath id="clip0_134_4129">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenTickIcon;

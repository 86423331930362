import { Button, Divider, Typography } from 'antd';
import Select from 'components/GlobalComponents/Select';
import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EXCHANGES } from 'constants/commons';
import { NumberInput } from 'components/GlobalComponents/NumberInput';

export default function Price({ max = 900, min = 0, base, data, setData }) {
  const [type, setType] = useState('single');
  const [step, setStep] = useState(0);
  const { refPrice, exchange } = base || {
    refPrice: 0,
    exchange: EXCHANGES.HSX,
  };

  const single = (
    <div className="w-full max-w-[148px] flex gap-3 justify-center">
      <Button
        icon={<RemoveIcon className="!w-9 !h-9" />}
        onClick={() =>
          setData((one) => ({
            ...one,
            price:
              one.price - step >= min
                ? Number((one.price - step).toFixed(2))
                : min,
          }))
        }
        type="text"
      />
      <NumberInput
        value={data.price}
        setValue={(value) => setData((one) => ({ ...one, price: value }))}
        min={min}
        max={max}
      />
      <Button
        icon={<AddIcon className="!w-9 !h-9" />}
        onClick={() =>
          setData((one) => ({
            ...one,
            price:
              one.price + step <= max
                ? Number((one.price + step).toFixed(2))
                : max,
          }))
        }
        type="text"
      />
    </div>
  );

  const range = (
    <div className="w-full max-w-[168px] flex justify-center items-center gap-2">
      <NumberInput
        value={data.minPrice}
        setValue={(value) =>
          setData((one) => ({ ...one, price: 0, minPrice: value }))
        }
        min={min}
        max={max}
      />
      <ArrowForwardIcon className="!w-8 !h-8" />
      <NumberInput
        value={data.maxPrice}
        setValue={(value) =>
          setData((one) => ({ ...one, price: 0, maxPrice: value }))
        }
        min={min}
        max={max}
      />
    </div>
  );

  useEffect(() => {
    switch (exchange.substring(0, 3)) {
      case EXCHANGES.HSX:
      case EXCHANGES.HOSE.substring(0, 3): {
        if (refPrice < 9500) setStep(0.01);
        else if (refPrice >= 50000) setStep(0.1);
        else setStep(0.05);
        break;
      }

      case EXCHANGES.HNX:
      case EXCHANGES.UPCOM.substring(0, 3): {
        setStep(0.1);
        break;
      }
      default:
        setStep(0.01);
        break;
    }
  }, [refPrice, exchange]);

  return (
    <div className="w-full flex flex-col rounded-lg bg-custom-darkBlue600">
      <Select
        title="Nhập theo"
        active={type}
        setActive={setType}
        onSelect={() =>
          setData((one) => ({ ...one, price: 0, minPrice: 0, maxPrice: 0 }))
        }
        options={[
          { key: 'single', label: 'Giá' },
          { key: 'range', label: 'Khoảng giá' },
        ]}
      />

      <Divider className="my-2 !border-custom-darkBlue500" />

      <div className="w-full h-[40px] flex items-center justify-between pl-5 border-t border-white">
        <Typography.Text className="!text-custom-gray300 mr-3 whitespace-nowrap">
          Giá
        </Typography.Text>
        {type === 'single' ? single : range}
      </div>
    </div>
  );
}

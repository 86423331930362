import React from 'react';

const ListSectorsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 9H8.6C8.048 9 8 9.447 8 10C8 10.553 8.048 11 8.6 11H14.4C14.952 11 15 10.553 15 10C15 9.447 14.952 9 14.4 9ZM16.4 14H8.6C8.048 14 8 14.447 8 15C8 15.553 8.048 16 8.6 16H16.4C16.952 16 17 15.553 17 15C17 14.447 16.952 14 16.4 14ZM8.6 6H16.4C16.952 6 17 5.553 17 5C17 4.447 16.952 4 16.4 4H8.6C8.048 4 8 4.447 8 5C8 5.553 8.048 6 8.6 6ZM5.4 9H3.6C3.048 9 3 9.447 3 10C3 10.553 3.048 11 3.6 11H5.4C5.952 11 6 10.553 6 10C6 9.447 5.952 9 5.4 9ZM5.4 14H3.6C3.048 14 3 14.447 3 15C3 15.553 3.048 16 3.6 16H5.4C5.952 16 6 15.553 6 15C6 14.447 5.952 14 5.4 14ZM5.4 4H3.6C3.048 4 3 4.447 3 5C3 5.553 3.048 6 3.6 6H5.4C5.952 6 6 5.553 6 5C6 4.447 5.952 4 5.4 4Z"
        fill="var(--white-color)"
      />
    </svg>
  );
};

export default ListSectorsIcon;

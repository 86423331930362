import React from 'react';
import { StyledSecondaryButton } from './styles';

const SecondaryButton = ({ textBtn, onHandleClick, size, ...props }) => {
  return (
    <StyledSecondaryButton onClick={onHandleClick} size={size} {...props}>
      {textBtn}
    </StyledSecondaryButton>
  );
};

export default SecondaryButton;

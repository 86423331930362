import React from 'react';
import CopierList from './CopierList';
import FollowerList from './FollowerList';
import NAVChart from './NAVChart';
import { StyledTabs } from 'components/GlobalComponents/styled';

export const CopierManagement = () => {
  const items = [
    { label: 'Danh sách Copier', key: 'item-1', children: <CopierList /> },
    { label: 'Danh sách Follower', key: 'item-2', children: <FollowerList /> },
    { label: 'NAV sao chép', key: 'item-3', children: <NAVChart /> },
  ];

  return (
    <section>
      <h1 className="text-2xl">Quản lý Copier</h1>
      <StyledTabs items={items} />
    </section>
  );
};

import { DatePicker, Input, Select, Table, Tabs } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: var(--primary-blue);
  }

  .ant-tabs-ink-bar {
    background-color: var(--primary-blue);
  }
`;

export const StyledTable = styled(Table)`
  .ant-table {
    background: var(--background-color);
  }

  .ant-table-thead {
    .ant-table-cell {
      color: var(--text-color);
      font-weight: 400;
    }
  }

  .ant-table-thead > tr > th {
    border-top: 1px solid #303030;
    background: var(--background-color);
  }

  .ant-table-cell::before {
    display: none;
  }

  .ant-table-tbody > tr {
    &:nth-child(2n + 1) {
      background: #222234;
    }
    > td {
      border: 0;
    }
  }

  .ant-table-expanded-row-fixed {
    // min-height: 68vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-pagination {
    .ant-pagination-item {
      color: #aaa;
      border: 0;
    }

    .ant-pagination-item-active {
      color: var(--primary-blue);
      background: #1b2542;
      border-radius: 4px;
    }

    .ant-pagination-item-link {
      border: 0;
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: var(--background-color);
  }

  .ant-table-row:nth-child(2n + 1) {
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: #222234;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    border: 1px solid #4e5264 !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
  }
`;

export const StyledInput = styled(Input)`
  height: 40px !important;
  border: 1px solid #4e5264 !important;
  padding: 4px 8px !important;
  border-radius: 8px !important;
  font-size: 1.6rem !important;
`;

export const StyledRangePicker = styled(RangePicker)`
  height: 40px !important;
  border: 1px solid #4e5264 !important;
  padding: 4px 8px !important;
  border-radius: 8px !important;
  font-size: 1.6rem !important;

  .ant-picker-active-bar {
    display: none;
  }
`;



export const TableButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: #5b5b5b90;
  }
`;
import styled from 'styled-components';
import { Form, Input } from 'antd';

export const GeneralContainer = styled.div`
  display: flex;
  flex-flow: column;
  background: var(--primary-color);
  border-radius: 4px;
  overflow-y: scroll;

  @media screen and (max-width: 37.5em) {
    height: calc(
      100vh - ${(props) => (props.closeAnnouncement ? '16rem' : '23.2rem')}
    );
    overflow-y: auto;
  }

  .react-tel-input {
    width: auto;
    .form-control {
      background: transparent;
      border: none;
      width: 90px;
      color: var(--white-color);
      border-radius: 0 !important;
    }
  }

  input#phone {
    @media screen and (max-width: 576px) {
      width: calc(100vw - 8rem);
    }
  }

  .country-list {
    border: 1px solid var(--border-color) !important;
    background: var(--primary-color);
    border: none;
    color: var(--white-color);
    width: auto;
    width: 234px;
    border-radius: 4px;

    @media screen and (max-width: 35.9375em) {
      width: max-content;
    }

    .country {
      &:hover,
      &.highlight {
        background: rgba(73, 194, 242, 0.08);
      }
    }
  }

  .flag-dropdown {
    background: transparent;
    border: none;

    &:hover,
    & > div:hover,
    & > div:focus {
      background: transparent;
      border: none;
    }
  }

  .react-tel-input .flag-dropdown.open .selected-flag,
  .react-tel-input .flag-dropdown.open {
    background: transparent;
  }

  .react-tel-input .country-list .search {
    background-color: var(--primary-color);
    display: flex;

    input {
      background-color: var(--primary-color);
      border: none;
      padding-left: 0;
    }
  }
`;

export const StyledGeneralHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
  color: var(--white-color);
`;

export const StyledGeneralContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;

  .ant-switch {
    &.ant-switch-checked {
      background-color: var(--white-color) !important;
      .ant-switch-handle::before {
        background-color: var(--background-color);
      }
    }
  }
`;

export const FormItemCustom = styled(Form.Item)`
  .ant-row {
    margin: 0;

    &:last-child {
      width: 100%;
    }
  }
`;

export const PhoneInputStyled = styled(Input)`
  padding-left: 90px !important;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
  margin-top: 2rem;
`;

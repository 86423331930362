import { getAllUserInfo } from 'features/user/userSlice';
import { useSelector } from 'react-redux';
import * as modal from 'components/GlobalComponents/NotificationModal/NotiModal';
import { useMemo } from 'react';

const usePermission = ({ key, handle, invalid }) => {
  const userInfo = useSelector(getAllUserInfo);

  const permission = useMemo(() => {
    if (!userInfo) return true;
    return userInfo.permissions.includes(key);
  }, [userInfo, key]);

  const handler = (...params) => {
    if (!permission) {
      if (invalid && typeof invalid === 'function') {
        invalid(...params);
        return;
      }
      modal.warning({
        message: 'PERMISSION_DENIED',
      });
      return;
    }
    if (handle && typeof handle === 'function') {
      handle(...params);
    }
  };

  return [handler, permission];
};

export default usePermission;

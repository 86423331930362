import React from 'react';

const SupportIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM17.46 7.12L14.68 8.27C14.4295 7.59612 14.0362 6.98443 13.527 6.47694C13.0177 5.96945 12.4047 5.57818 11.73 5.33L12.88 2.55C14.98 3.35 16.65 5.02 17.46 7.12ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13ZM7.13 2.54L8.3 5.32C7.61732 5.56879 6.99706 5.96338 6.48241 6.4763C5.96776 6.98923 5.57108 7.60815 5.32 8.29L2.54 7.13C2.94099 6.08615 3.55677 5.13817 4.34747 4.34747C5.13817 3.55677 6.08615 2.94099 7.13 2.54ZM2.54 12.87L5.32 11.72C5.56934 12.4001 5.96436 13.0174 6.47741 13.5287C6.99045 14.04 7.6091 14.433 8.29 14.68L7.12 17.46C6.07838 17.0572 5.13269 16.4406 4.34385 15.6501C3.55502 14.8595 2.94054 13.9125 2.54 12.87ZM12.88 17.46L11.73 14.68C12.4079 14.4288 13.0229 14.0331 13.5324 13.5202C14.0418 13.0073 14.4334 12.3896 14.68 11.71L17.46 12.88C17.0577 13.9203 16.4425 14.8651 15.6538 15.6538C14.8651 16.4425 13.9203 17.0577 12.88 17.46Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default SupportIcon;

import { Spin } from 'antd';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const InfiniteScroll = ({ children, next, isNext, loader }) => {
  const { ref, entry } = useInView({ threshold: 0 });

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      if (next && typeof next === 'function') {
        next();
      }
    }
    //eslint-disable-next-line
  }, [entry]);

  const _loader = (
    <div className="infinite-scroll-loader-wrapper" ref={ref}>
      {loader || <Spin size="large" />}
    </div>
  );

  return (
    <>
      {children}
      {isNext && _loader}
    </>
  );
};

export default InfiniteScroll;

import React from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberInput = ({
  value,
  setValue,
  max = 999,
  min = 0,
  setIsFocused,
}) => (
  <NumericFormat
    className="w-[68px] text-lg text-center rounded-none border-none bg-transparent text-white focus:outline-none relative"
    value={(Math.round(value * 100) / 100).toFixed(2)}
    isAllowed={(values) => {
      const { value } = values;
      return Number(value) <= max && Number(value) >= min;
    }}
    onValueChange={(values) => {
      setValue(Number(values.value));
    }}
    decimalScale={2}
    allowNegative={false}
    thousandSeparator=","
    onFocus={(e) => {
      e.target.select();
      setIsFocused(true);
    }}
    onBlur={() => setIsFocused(false)}
  />
);

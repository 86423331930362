import styled from 'styled-components';

export const TabItemTitle = styled.div`
  padding-left: 1.6rem;
  padding-bottom: 2rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.408px;
  color: var(--white-color);
`;

export const StyledTopTab = styled.div`
  display: flex;
  justify-content: space-between;

  &.chart-modal {
    @media only screen and (max-width: 30em) {
      padding: 2rem !important;
    }
  }
`;

export const MarketStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);

  .react-stockchart {
    margin-left: 0 !important;
  }
`;

export const StyledRightText = styled.div`
  display: flex;
  text-align: center;
  height: 14rem;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #1ed994;
  }

  & > div:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f2ed49;
  }

  & > div:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f2ed49;
  }

  & > div:nth-child(3) {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #f24b78;
  }
`;

export const StyledTopTabItem = styled.div`
  color: var(--white-color);
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid var(--light-blue-color-2);
    transition: cubic-bezier(0.1, 0.7, 1, 0.1);
  }
`;

export const StyledHeaderIcon = styled.div`
  cursor: pointer;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
